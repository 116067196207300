import * as R from 'ramda';
import React from 'react';
import { compose, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { SelectLocation } from '../../../forms/forms/select-location';
// ui
import { Box, Flex } from '../../../ui';
// feature new-do
import SaveLocationTmpl from './save-location-tmpl';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const blueColor = G.getTheme('colors.dark.blue');

const enhance = compose(
  withHandlers({
    handleClickGetGeo: (props: Object) => async (location: Object) => {
      const { values, openModal, setValues, closeModal } = props;

      const saveTemplateRequest = (location: Object) => {
        setValues(R.mergeRight(values, location));

        closeModal();
      };

      const address = G.concatLocationFields(
        location,
        [GC.FIELD_ADDRESS_1, GC.FIELD_CITY, GC.FIELD_STATE, GC.FIELD_ZIP, GC.FIELD_COUNTRY],
      );

      if (G.isNilOrEmpty(address)) {
        const modalContent = (
          <SelectLocation
            closeModal={closeModal}
            templateParamName={GC.FIELD_TEMPLATE_ID}
            searchTemplateRequest={saveTemplateRequest}
          />
        );

        const modal = G.createCommonModalOptions(modalContent);

        return openModal(modal);
      }

      const { latitude, longitude } = await G.geocodeByPlaceAddress(address, 'new-do -> handleClickGetGeo');

      const initialValues = R.mergeRight(location, { latitude, longitude });

      const modalContent = (
        <SelectLocation
          closeModal={closeModal}
          initialValues={initialValues}
          templateParamName={GC.FIELD_TEMPLATE_ID}
          searchTemplateRequest={saveTemplateRequest}
        />
      );

      const modal = G.createCommonModalOptions(modalContent);

      openModal(modal);
    },
  }),
);

const FormHeader = (props: Object) => (
  <Flex
    p='7px 10px'
    justifyContent='space-between'
    color={G.getTheme('colors.black')}
    bg={G.getTheme('colors.light.teaGreen')}
  >
    <TextComponent
      display='block'
      whiteSpace='normal'
      wordBreak='break-word'
      title={props.children}
    >
      {props.children}
    </TextComponent>
    <Box ml='auto'>
      <SaveLocationTmpl
        bg={whiteColor}
        color={blueColor}
        location={props.values}
        border={`1px solid ${blueColor}`}
        currentBranch={props.currentBranch}
        setFieldValue={props.setFieldValue}
        saveBtnText={props.saveLocationBtnText}
        initialValue={R.path(['values', 'externalId'], props)}
      />
    </Box>
    <Flex>
      <TextComponent
        mx='5px'
        p='3px 8px'
        bg={whiteColor}
        cursor='pointer'
        color={blueColor}
        borderRadius='4px'
        border={`1px solid ${blueColor}`}
        onClick={() => props.handleClickGetGeo(props.values)}
      >
        {G.getWindowLocale('titles:get-geodata', 'Get Geodata')}
      </TextComponent>
      {
        props.options &&
        props.options.map((item: Object, index: number) => (
          <Box key={index} mx='7px' title={item.title} onClick={item.action} cursor='pointer'>
            {item.Icon(blueColor)}
          </Box>
        ))
      }
    </Flex>
  </Flex>
);

export const QuoteFormHeader = enhance((props: Object) => (
  <Flex
    p='7px 10px'
    justifyContent='space-between'
    color={G.getTheme('colors.black')}
    bg={G.getTheme('colors.light.teaGreen')}
  >
    <TextComponent
      display='block'
      whiteSpace='normal'
      wordBreak='break-word'
      title={props.children}
    >
      {props.children}
    </TextComponent>
    <Flex>
      {
        props.options &&
        props.options.map((item: Object, index: number) => (
          <Box key={index} mx='7px' title={item.title} onClick={item.action} cursor='pointer'>
            {item.Icon(blueColor)}
          </Box>
        ))
      }
    </Flex>
  </Flex>
));

export default enhance(FormHeader);
