import * as R from 'ramda';
import { delay } from 'redux-saga';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature new-import
import * as A from './actions';
import * as C from './constants';
import { makeLocationArray } from './helpers';
import {
  makeSelectRolesGuids,
  makeSelectImportType,
  makeSelectSourceType,
  makeSelectImportList,
  makeSelectContractGuid,
  makeSelectConfigOptions,
  makeSelectCleanExistentRates,
} from './selectors';
//////////////////////////////////////////////////

function* calculateImportCLOLocations({ payload }: Object) {
  const { dataToSave, locationsToCalculate } = payload;

  const locations = locationsToCalculate;
  let count = R.compose(
    R.length,
    R.filter(({ calculated }: Object) => calculated),
  )(locations);

  try {
    yield put(openLoader());

    while (count < R.length(locations)) {
      const currentLocation = R.prop(count, locations);

      const { location, objectType, templateId } = currentLocation;

      if (G.isNilOrEmpty(templateId)) {
        G.logToSystem(GC.LOG_MESSAGE_TYPE_GOOGLE_GEOCODE_API, { from: 'new-import calculateImportCLOLocations' });

        const {
          zip,
          country,
          latitude,
          longitude,
        } = yield G.geocodeByPlaceAddress(location, 'new-import calculateImportCLOLocations');

        locations[count] = {
          objectType,
          calculated: true,
          [`${objectType}Latitude`]: latitude,
          [`${objectType}Longitude`]: longitude,
          [`${objectType}Zip`]: R.pathOr(zip, [GC.FIELD_ZIP], currentLocation),
          [`${objectType}Country`]: R.pathOr(country, [GC.FIELD_COUNTRY], currentLocation),
        };
      } else {
        locations[count] = { objectType, calculated: true };
      }

      count = R.inc(count);
    }

    const { origin, destination } = R.groupBy(R.prop('objectType'), locations);

    const calculatedData = G.mapIndexed((item: Object, index: number) => {
      let originItem = {};

      if (G.isNilOrEmpty(R.prop('originTemplateId', item))) {
        originItem = R.compose(
          R.pick([
            C.FIELD_IMPORT_TYPE_CLO_ORIGIN_ZIP,
            C.FIELD_IMPORT_TYPE_CLO_ORIGIN_COUNTRY,
            C.FIELD_IMPORT_TYPE_CLO_ORIGIN_LATITUDE,
            C.FIELD_IMPORT_TYPE_CLO_ORIGIN_LONGITUDE,
          ]),
          R.pathOr({}, [index]),
        )(origin);
      }

      let destinationItem = {};
      if (G.isNilOrEmpty(R.prop('destinationTemplateId', item))) {
        destinationItem = R.compose(
          R.pick([
            C.FIELD_IMPORT_TYPE_CLO_DESTINATION_ZIP,
            C.FIELD_IMPORT_TYPE_CLO_DESTINATION_COUNTRY,
            C.FIELD_IMPORT_TYPE_CLO_DESTINATION_LATITUDE,
            C.FIELD_IMPORT_TYPE_CLO_DESTINATION_LONGITUDE,
          ]),
          R.pathOr({}, [index]),
        )(destination);
      }

      return { ...item, ...originItem, ...destinationItem };
    }, dataToSave);

    yield put(A.saveImportListRequest({
      shouldCloseModal: true,
      ignoreImportList: true,
      dataFromFile: calculatedData,
      shouldCalculateLocations: false,
    }));

    yield put(closeLoader());
  } catch (error) {
    if (R.includes(error, ['INVALID_REQUEST', 'ZERO_RESULTS'])) {
      const { objectType } = R.prop(count, locations);

      locations[count] = { objectType, calculated: true };
      const payload = { dataToSave, locationsToCalculate: locations };

      yield call(calculateImportCLOLocations, { payload });
    }

    if (R.equals(error, 'OVER_QUERY_LIMIT')) {
      yield delay(1000);
      const payload = { dataToSave, locationsToCalculate: locations };

      yield call(calculateImportCLOLocations, { payload });
    }

    yield put(closeLoader());
  }
}

function* createImportFromFileRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const formData = new window.FormData();
    formData.append('file', payload.file);
    formData.append('data', JSON.stringify(payload));
    const options = { data: formData };
    const res = yield call(sendRequest, 'post', endpointsMap.importerImportFromFile, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createImportFromFileRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createImportFromFileRequest fail');
  }
}

function* saveImportListRequest({ payload = {} }: Object) {
  try {
    const {
      dataFromFile,
      additionalFields,
      shouldCloseModal,
      ignoreImportList,
      shouldCalculateLocations,
    } = payload;

    yield put(openLoader());
    const type = yield select(makeSelectImportType());
    const importList = yield select(makeSelectImportList());
    const sourceType = yield select(makeSelectSourceType());
    const rolesGuids = yield select(makeSelectRolesGuids());
    const contractGuid = yield select(makeSelectContractGuid());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const cleanExistentRates = yield select(makeSelectCleanExistentRates());
    const importListForApi = R.compose(
      R.filter(G.isNotNilAndNotEmpty),
      R.map(R.compose(
        R.filter(G.isNotNilAndNotEmpty),
        R.map(R.prop(GC.FIELD_VALUE)),
      )),
    )(R.or(importList, []));
    let options = {
      data: {
        type,
        data: importListForApi,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    if (G.isNotNilAndNotEmpty(additionalFields)) {
      yield put(A.setAdditionalFields(additionalFields));
    }

    if (G.isNotNilAndNotEmpty(dataFromFile)) {
      const dataFromFileForApi = R.compose(
        R.filter(G.isNotNilAndNotEmpty),
        R.map(R.filter(G.isNotNilAndNotEmpty)),
      )(dataFromFile);
      yield put(A.getImportFromFileDataSuccess(dataFromFileForApi));
      const dataToSave = G.ifElse(
        G.isTrue(ignoreImportList),
        dataFromFileForApi,
        R.concat(R.or(importListForApi, []), dataFromFileForApi),
      );

      if (G.isTrue(shouldCalculateLocations)) {
        const origin = makeLocationArray(dataToSave, GC.FIELD_ORIGIN);
        const destination = makeLocationArray(dataToSave, GC.FIELD_DESTINATION);
        const locationsToCalculate = [...origin, ...destination];

        yield put(closeLoader());

        return yield put(A.calculateImportCLOLocations({ dataToSave, locationsToCalculate }));
      }

      options = R.assocPath(['data', 'data'], dataToSave, options);
    }

    if (G.isNilOrEmpty(R.path(['data', 'data'], options))) {
      yield put(closeLoader());
      yield call(G.showToastrMessage, 'info', 'messages:valid-data');

      return false;
    }

    if (R.equals(type, C.IMPORT_TYPE_DRIVER)) {
      options = R.assocPath(['data', 'parameters', 'rolesGuids'], rolesGuids, options);
    }

    if (R.includes(type, [C.IMPORT_TYPE_TOLL_CHARGE, C.IMPORT_TYPE_FUEL_CARD_TRANSACTION])) {
      options = R.assocPath(['data', 'parameters', GC.FIELD_SOURCE], sourceType, options);
    }

    if (R.equals(type, C.IMPORT_TYPE_CARRIER_RATE_PRICE)) {
      options = R.assocPath(['data', 'parameters'], { contractGuid, cleanExistentRates }, options);
    }

    const res = yield call(sendRequest, 'post', endpointsMap.importerSave, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      if (shouldCloseModal) yield put(closeModal());

      if (R.or(G.isNotNilAndNotEmpty(importList), G.isNotNilAndNotEmpty(dataFromFile))) yield put(A.setImportId(data));
    } else {
      yield call(G.handleFailResponse, res, 'saveImportListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'saveImportListRequest fail');
  }
}

function* createMapAndImportFromFileRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { uri, mapper, matchFields } = payload;

    const importType = yield select(makeSelectImportType());
    const options = {
      data: {
        uri,
        mapper,
        matchFields,
        [GC.BRANCH_GUID]: G.getAmousCurrentBranchGuidFromWindow(),
      },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.importerData, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.saveImportListRequest({
        shouldCloseModal: true,
        dataFromFile: G.getPropFromObject('data', data),
        additionalFields: R.pathOr([], ['additionalFields'], data),
        shouldCalculateLocations: R.equals(importType, C.IMPORT_TYPE_CLO),
      }));
    } else {
      yield call(G.handleFailResponse, res, 'createMapAndImportFromFileRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createMapAndImportFromFileRequest exception');
  }
}

function* removeMapAndImportFileRequest({ payload }: Object) {
  try {
    const { uri } = payload;

    yield put(openLoader());
    const options = { params: { uri } };
    const res = yield call(sendRequest, 'delete', endpointsMap.importerUpload, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
    } else {
      yield put(closeModal());
      yield call(G.handleFailResponse, res, 'removeMapAndImportFileRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeMapAndImportFileRequest exception');
  }
}

function* getBranchConfigsRequest({ payload }: Object) {
  try {
    const { type, names } = payload;

    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        names,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getBranchConfigsSuccess({ ...data, type }));
    } else {
      yield call(G.handleFailResponse, res, 'getBranchConfigsRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getBranchConfigsRequest exception');
  }
}

function* getImportTemplateRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const type = payload;
    const options = {
      params: { type },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.importerTemplate, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getImportTemplateSuccess({ data, type }));
      const dropDownNames = R.compose(
        R.join(', '),
        R.filter(G.isNotNilAndNotEmpty),
        R.map(R.prop('dropDownName')),
      )(data);
      if (G.isNotNilAndNotEmpty(dropDownNames)) {
        const configOptions = yield select(makeSelectConfigOptions());
        if (G.isNilOrEmpty(G.getPropFromObject(type, configOptions))) {
          yield put(A.getBranchConfigsRequest({ type, names: dropDownNames }));
        }
      }
    } else {
      yield call(G.handleFailResponse, res, 'getImportTemplateRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getImportTemplateRequest exception');
  }
}

function* getAvailableImportMappersRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.availableBranchImportMappers, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getAvailableImportMappersSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getAvailableImportMappersRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getAvailableImportMappersRequest exception');
  }
}

function* uploadCLODocumentRequest({ payload }: Object) {
  try {
    const { file, mapper } = payload;

    yield put(openLoader());
    const formData = new window.FormData();
    formData.append('file', file);
    const params = { [GC.FIELD_TYPE]: C.IMPORT_TYPE_CLO };
    const options = { params, data: formData };
    const endpoint = endpointsMap.importerUpload;
    const res = yield call(sendRequest, 'post', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      // yield call(createImportCLOFromFileRequest, { payload: R.assoc('mapper', mapper, data) });
      yield put(A.createMapAndImportFromFileRequest(R.assoc('mapper', mapper, data)));
    } else {
      yield call(G.handleFailResponse, res, 'uploadCLODocumentRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'uploadCLODocumentRequest exception');
  }
}

function* visitImportPage({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_IMPORT_PAGE);
    yield call(getAvailableImportMappersRequest);
    break;
  }
}

function* importWatcherSaga() {
  yield takeLatest(GC.VISIT_IMPORT_PAGE, visitImportPage);
  yield takeLatest(A.saveImportListRequest, saveImportListRequest);
  yield takeLatest(A.getBranchConfigsRequest, getBranchConfigsRequest);
  yield takeLatest(A.getImportTemplateRequest, getImportTemplateRequest);
  yield takeLatest(A.uploadCLODocumentRequest, uploadCLODocumentRequest);
  yield takeLatest(A.createImportFromFileRequest, createImportFromFileRequest);
  yield takeLatest(A.calculateImportCLOLocations, calculateImportCLOLocations);
  yield takeLatest(A.removeMapAndImportFileRequest, removeMapAndImportFileRequest);
  yield takeLatest(A.createMapAndImportFromFileRequest, createMapAndImportFromFileRequest);
}

export default importWatcherSaga;
