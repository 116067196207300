import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../report-common';
//////////////////////////////////////////////////

const {
  setReports,
  selectItem,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
} = getReportActions();

const toggleExcelResults = createAction('toggleExcelResults');
const importFromFileRequest = createAction('importFromFileRequest');

// item
const removeExcelMassRatingSummaryRequest = createAction('removeExcelMassRatingSummaryRequest');
const removeExcelMassRatingSummarySuccess = createAction('removeExcelMassRatingSummarySuccess');
// excel results
const updatePricesByRowGuidRequest = createAction('updatePricesByRowGuidRequest');
const exportExcelResultReportRequest = createAction('exportExcelResultReportRequest');
const updateAllPricesByRowGuidRequest = createAction('updateAllPricesByRowGuidRequest');
const getExcelResultsByRowGuidSuccess = createAction('getExcelResultsByRowGuidSuccess');
const getExcelResultsByRowGuidRequest = createAction('getExcelResultsByRowGuidRequest');
const resetExcelResultsListAndPaginationByRowGuid = createAction('resetExcelResultsListAndPaginationByRowGuid');

export {
  // report
  setReports,
  selectItem,
  setReportType,
  setUsedReport,
  setFilterProps,
  setListLoading,
  setInitialState,
  cleanQuickFilter,
  setReportPending,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
  // report
  //item
  removeExcelMassRatingSummaryRequest,
  removeExcelMassRatingSummarySuccess,
  //item
  // excel results
  updatePricesByRowGuidRequest,
  exportExcelResultReportRequest,
  updateAllPricesByRowGuidRequest,
  getExcelResultsByRowGuidSuccess,
  getExcelResultsByRowGuidRequest,
  resetExcelResultsListAndPaginationByRowGuid,
  // excel results
  toggleExcelResults,
  importFromFileRequest,
};
