import React from 'react';
import * as R from 'ramda';
import {
  pure,
  compose,
  lifecycle,
  withState,
  withHandlers,
} from 'react-recompose';
// components
import { DateRangeMui } from '../../../components/date-range';
import { LocalLoader } from '../../../components/local-loader';
import { FormGroupTitleMultiple } from '../../../components/form-group-title-multiple';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// feature payroll
import PayrollInvoicesTable from './payroll-invoices-table';
import { withDataFilterHoc } from '../hocs/with-data-filter';
//////////////////////////////////////////////////

const enhance = compose(
  withDataFilterHoc,
  withState('filterDates', 'setFilterDates', (props: Object) => {
    const { initialFilterDays } = props;

    const dateTo = G.getCurrentDateWithFormat(GC.DEFAULT_DATE_FORMAT);

    return {
      dateTo,
      dateFrom: G.subtractMomentTimeWithFormat(
        dateTo,
        initialFilterDays,
        'days',
        GC.DEFAULT_DATE_FORMAT,
      ),
      quickDaysValue: initialFilterDays,
    };
  }),
  withHandlers({
    handleSetDates: (props: Object) => (dates: Object) => {
      const { payroll, setLoading, setFilterDates, getFilteredDataRequest } = props;

      setFilterDates(dates);
      const options = {
        data: R.assoc(GC.FIELD_PAYROLL_GUID, G.getGuidFromObject(payroll), dates),
      };

      setLoading();

      getFilteredDataRequest(options);
    },
    handleSetFilterQuickDays: (props: Object) => (value: number) => {
      const { payroll, setLoading, filterDates, setFilterDates, getFilteredDataRequest } = props;

      const dates = {
        dateFrom: G.subtractMomentTimeWithFormat(
          filterDates.dateTo,
          R.dec(value),
          'days',
          GC.DEFAULT_DATE_FORMAT,
        ),
        dateTo: filterDates.dateTo,
      };

      setFilterDates(R.assoc('quickDaysValue', value, dates));

      const options = {
        data: R.assoc(GC.FIELD_PAYROLL_GUID, G.getGuidFromObject(payroll), dates,
        ),
      };

      setLoading();
      getFilteredDataRequest(options);
    },
  }),
  lifecycle({
    componentDidMount() {
      const options = {
        data: R.mergeRight(
          { [GC.FIELD_PAYROLL_GUID]: this.props.payroll.guid },
          this.props.filterDates,
        ),
      };

      this.props.getFilteredDataRequest(options);
    },
  }),
  pure,
);

const UpdatePayrollInvoices = (props: Object) => {
  const {
    payroll,
    isVendor,
    asyncData,
    closeModal,
    branchGuid,
    filterDates,
    handleSetDates,
    updatePayrollInvoices,
    handleSetFilterQuickDays,
  } = props;

  const { data, loading } = asyncData;

  return (
    <Box bg='white' width='95vw' maxWidth={1200} zIndex='unset'>
      <FormGroupTitleMultiple
        mb='0'
        showArrowToggle={false}
        title={G.getWindowLocale('titles:update-payroll-invoices', 'Update Payroll Invoices')}
      />
      <Flex p={15}>
        <DateRangeMui
          {...filterDates}
          width={80}
          useNewMuiInputField={true}
          popperPlacement='bottom-end'
          quickDays={GC.QUICK_DAYS_FILTER}
          onSelectDateRange={handleSetDates}
          setQuickDays={handleSetFilterQuickDays}
          maxDate={G.momentAddYearsFromCurrent(100)}
          labelTo={G.getWindowLocale('titles:to', 'To')}
          minDate={G.momentSubtractYearsFromCurrent(100)}
          labelFrom={G.getWindowLocale('titles:from', 'From')}
          label={G.getWindowLocale('titles:invoices-date-range', 'Driver Invoices Date Range')}
        />
      </Flex>
      <LocalLoader localLoaderOpen={loading}>
        <PayrollInvoicesTable
          data={payroll}
          invoices={data}
          isVendor={isVendor}
          closeModal={closeModal}
          branchGuid={branchGuid}
          updatePayrollInvoices={updatePayrollInvoices}
        />
      </LocalLoader>
    </Box>
  );
};

export default enhance(UpdatePayrollInvoices);

