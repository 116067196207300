import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
// ui
import {
  Box,
  Flex,
  FixedFlex,
  PageWrapper,
  ActionButton,
  CancelButton,
} from '../../../ui';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');
const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

const buttonStyles = {
  height: 32,
  width: 150,
  p: '4px 8px',
  fontSize: 14,
  bgColor: 'none',
  background: 'none',
  border: '1px solid',
  borderRadius: '5px',
  textTransform: 'uppercase',
};

const ProfileWrapper = (props: Object) => {
  const { children, setUpdate, handleClose, setSaveAndClose } = props;

  return (
    <PageWrapper
      p={20}
      pl={75}
      pb='0px'
      height='calc(100vh - 60px)'
      bgColor={G.getTheme('colors.bgGrey')}
    >
      <Box mx='auto' maxWidth={1650}>{children}</Box>
      <FixedFlex
        pl={70}
        right='0px'
        zIndex={12}
        bottom='0px'
        p='14px 20px'
        boxShadow='0 0 8px 0 rgb(0 0 0 / 20%)'
        width='calc(100% - 60px)'
        justifyContent='space-between'
        background={G.getTheme('colors.white')}
      >
        <CancelButton
          {...buttonStyles}
          mr={20}
          onClick={handleClose}
          textColor={greyMatterhornColor}
          borderColor={greyMatterhornColor}
        >
          {G.getWindowLocale('actions:close', 'Close')}
        </CancelButton>
        <Flex gap={25}>
          <ActionButton
            {...buttonStyles}
            textColor={darkBlueColor}
            borderColor={darkBlueColor}
            onClick={() => setUpdate(true)}
          >
            {G.getWindowLocale('actions:update', 'Update')}
          </ActionButton>
          <ActionButton
            {...buttonStyles}
            textColor={darkBlueColor}
            borderColor={darkBlueColor}
            onClick={() => setSaveAndClose(true)}
          >
            {G.getWindowLocale('actions:save-and-close', 'Save and Close')}
          </ActionButton>
        </Flex>
      </FixedFlex>
    </PageWrapper>
  );
};

export default ProfileWrapper;
