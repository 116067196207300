import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose, withState, withProps, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
import { FormFooter } from '../../../components/form-footer';
// common
import { openModal, closeModal } from '../../../components/modal/actions';
// forms
import { FieldsetComponent } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncRequest, withAsyncSequence, withComponentDidUpdatePropCallback } from '../../../hocs';
// ui
import { Box } from '../../../ui';
// utilities
import endpointsMap from '../../../utilities/endpoints';
//////////////////////////////////////////////////

const templateIdField = {
  width: 300,
  fontSize: 12,
  labelPl: '0px',
  autofocus: true,
  errorTop: '103%',
  isRequired: true,
  labelWidth: '100%',
  flexDirection: 'column',
  inputWrapMargin: '4px 0',
  errorPosition: 'absolute',
  loc: 'titles:template-id',
  name: GC.FIELD_TEMPLATE_ID,
  fieldName: GC.FIELD_TEMPLATE_ID,
};

const validationSchema = {
  [GC.FIELD_TEMPLATE_ID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
};

const formikEnhancer = compose(
  withState('origSequence', 'setOrigSequence', null),
  withFormik({
    mapPropsToValues: ({ initialValue }: Object) => ({ [GC.FIELD_TEMPLATE_ID]: R.or(initialValue, null) }),
    validationSchema: () => Yup.object().shape(validationSchema),
    handleSubmit: (values: Object, { props }: Object) => {
      const {
        location,
        origSequence,
        setFieldValue,
        currentBranch,
        makeAsyncRequest,
      } = props;

      const templateId = G.ifElse(
        R.propEq(origSequence, GC.FIELD_TEMPLATE_ID, values),
        null,
        R.prop(GC.FIELD_TEMPLATE_ID, values),
      );

      const contacts = R.filter(
        (item: Object) => G.isOneNotNilOrNotEmpty(R.values(item)),
        R.pathOr([], [GC.FIELD_CONTACTS], location),
      );

      const operationHours = R.compose(
        R.map(R.dissoc(GC.FIELD_GUID)),
        G.convertOperationHoursToDefaultFormat,
        R.pathOr([], [GC.FIELD_OPERATION_HOUR]),
      )(location);

      const locationToUse = R.assoc(GC.FIELD_CONTACTS, contacts, location);

      const data = {
        ...locationToUse,
        templateId,
        [GC.FIELD_OPERATION_HOUR]: operationHours,
        [GC.FIELD_BRANCH_GUID]: G.getGuidFromObject(currentBranch),
      };

      const options = {
        data: R.omit(
          [GC.FIELD_STOP_ITEMS, GC.FIELD_STOP_PICKED_UP_CONTAINERS, GC.FIELD_STOP_DROPPED_CONTAINERS],
          G.omitObjectChildArrayItemSystemFields(
            GC.FIELD_CONTACTS,
            G.omitObjectSystemFields(G.mapObjectEmptyStringFieldsToNull(data)),
          ),
        ),
      };

      setFieldValue(GC.FIELD_TEMPLATE_ID, R.prop(GC.FIELD_TEMPLATE_ID, values));

      makeAsyncRequest(endpointsMap.location, options);
    },
  }),
  withProps(() => ({
    sequenceConfigName: GC.TEMPLATES_LOCATION_TEMPLATE_ID_SEQUENCE,
    autogenerateConfigName: GC.TEMPLATES_LOCATION_TEMPLATE_ID_AUTOGENERATED,
    configsNamesArray: [
      GC.TEMPLATES_LOCATION_TEMPLATE_ID_SEQUENCE,
      GC.TEMPLATES_LOCATION_TEMPLATE_ID_AUTOGENERATED,
    ],
  })),
  withAsyncSequence,
  withHandlers({
    handleSetLocationSequence: (props: Object) => () => {
      const { setFieldValue, asyncSequence, setOrigSequence } = props;

      if (G.isNotNil(asyncSequence)) {
        setOrigSequence(asyncSequence);
        setFieldValue(GC.FIELD_TEMPLATE_ID, asyncSequence);
      }
    },
    onEnterKeyPressHandler: (props: Object) => (e: Object) => {
      const { handleSubmit } = props;

      handleSubmit(e);
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'asyncSequence',
    callbackName: 'handleSetLocationSequence',
  }),
  pure,
);

const enhance = compose(
  withAsyncRequest,
  withHandlers({
    handleOpenSaveLocationTemplate: (props: Object) => (event: Event) => {
      G.stopPropagation(event);

      const { openModal, closeModal } = props;

      const component = (
        <TemplateIdPopperContent
          {...props}
          openModal={openModal}
          closeModal={closeModal}
          branchGuid={R.path(['currentBranch', GC.FIELD_GUID], props)}
        />
      );

      const modal = {
        p: '15px',
        component,
        options: {
          height: 'auto',
          overflow: 'auto',
          maxHeight: '88vh',
          width: 'max-content',
          title: G.getWindowLocale('titles:save-location', 'Save Location'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const TemplateIdPopperContent = formikEnhancer((props: Object) => (
  <Box>
    <form onSubmit={props.handleSubmit}>
      <FieldsetComponent
        {...G.getFormikProps(props)}
        fields={R.of(Array, templateIdField)}
        onEnterKeyPressHandler={props.onEnterKeyPressHandler}
      />
      <FormFooter closeModal={props.closeModal} boxStyles={{ mt: '10px' }} />
    </form>
  </Box>
));

const SaveLocationTmpl = (props: Object) => {
  const { bg, color, border, saveBtnText, handleOpenSaveLocationTemplate } = props;

  const setBg = R.or(bg, G.getTheme('colors.white'));
  const setColor = R.or(color, G.getTheme('colors.light.mainRed'));

  return (
    <TextComponent
      mx='5px'
      bg={setBg}
      p='3px 8px'
      border={border}
      cursor='pointer'
      color={setColor}
      borderRadius='4px'
      onClick={(event: Object) => handleOpenSaveLocationTemplate(event)}
    >
      {R.or(saveBtnText, G.getWindowLocale('actions:save-location', 'Save Location'))}
    </TextComponent>
  );
};

export default connect(null, { openModal, closeModal })(enhance(SaveLocationTmpl));
