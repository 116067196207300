import * as R from 'ramda';
import React from 'react';
import { FieldArray } from 'formik';
// features
import PC from '../../../permission/role-permission';
import { Fieldset } from '../../../new-do/forms/formik/fieldset';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex } from '../../../../ui';
// load-references-form
import { getFields, getNewReferenceFields } from './settings';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.dark.blue');

const Header = ({ push, title, loadGuid, useSingleReference }: Object) => (
  <Flex px={10} height={30} bg={G.getTheme('colors.bgGrey')}>
    <Box fontWeight={600} color={G.getTheme('colors.greyMatterhorn')}>
      {R.or(title, G.getWindowLocale('titles:references', 'References'))}
    </Box>
    {
      R.not(useSingleReference) &&
      <Box
        mx={10}
        height={15}
        cursor='pointer'
        title='Add new item'
        onClick={() => push(getNewReferenceFields(loadGuid))}
      >
        {I.plusRound(blueColor)}
      </Box>
    }
  </Flex>
);

const getAllowedValues = (props: Object) => {
  const { values, itemIndex, scopeRefTypes } = props;

  const itemRefTypeGuid = R.path([GC.FIELD_LOAD_REFERENCES, itemIndex, GC.FIELD_REFERENCE_TYPE_GUID], values);

  return R.path(['allowedValues'], R.find(R.propEq(itemRefTypeGuid, GC.FIELD_GUID), scopeRefTypes));
};

const getAllowedOptions = (props: Object) => {
  const allowedValues = getAllowedValues(props);

  if (G.isNotNilAndNotEmpty(allowedValues)) {
    return R.map(
      (item: string) => ({ label: item, value: item }),
      getAllowedValues(props),
    );
  }

  return [];
};

const getReferenceFields = (props: Object, primaryReferencePermission: boolean) => {
  const { item, referenceTypes } = props;

  const isInvoiceReference = G.isNotNilAndNotEmpty(G.getPropFromObject('invoiceGuid', item));
  const isEventReference = G.isNotNilAndNotEmpty(G.getPropFromObject(GC.FIELD_EVENT_GUID, item));
  const isCopy = G.isNotNilAndNotEmpty(G.getPropFromObject(GC.FIELD_PARENT_REFERENCE_GUID, item));

  const isFromCurrentScopeOrNewValue = R.or(
    G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_NAME, item)),
    R.any(
      R.propEq(G.getPropFromObject(GC.FIELD_REFERENCE_TYPE_GUID, item), GC.FIELD_VALUE),
      referenceTypes,
    ),
  );

  const disabled = R.or(R.and(
    R.not(primaryReferencePermission),
    G.getPropFromObject(GC.FIELD_PRIMARY, item),
  ), isCopy);

  const allowedValues = getAllowedValues(props);

  const fieldsParams = {
    index: 1,
    disabled,
    isInvoiceReference,
    isFromCurrentScopeOrNewValue,
  };

  let fields;

  if (G.isNotNilAndNotEmpty(allowedValues)) {
    fields = getFields(fieldsParams);
  } else {
    fields = getFields({ ...fieldsParams, index: 0 });
  }

  if (G.isNotNil(G.getGuidFromObject(item))) {
    fields = R.assocPath([0, 'disabled'], true, fields);
  }

  if (R.or(
    R.or(isInvoiceReference, isEventReference),
    R.or(isCopy, R.not(primaryReferencePermission)),
  )) {
    fields = R.assocPath([2, 'disabled'], true, fields);
  }

  return fields;
};

const ItemRow = (props: Object) => {
  const {
    item,
    remove,
    loadType,
    itemIndex,
    arrayLength,
    handleChangeInput,
    useSingleReference,
  } = props;

  const primaryReferencePermission = G.ifElse(
    G.isLoadTypeClo(loadType),
    G.getHasAmousCurrentUserPermissionFromWindow(PC.CLO_CHANGE_PRIMARY_REFERENCE_EXECUTE),
    G.getHasAmousCurrentUserPermissionFromWindow(PC.TEL_CHANGE_PRIMARY_REFERENCE_EXECUTE),
  );
  const allowDelete = R.and(R.not(useSingleReference), R.not(R.path([GC.FIELD_PRIMARY], item)));

  return (
    <Flex mt={25} pl={G.ifElse(G.isTrue(useSingleReference), 10)}>
      <Box width={35}>
        {
          allowDelete &&
          <Flex
            mx={10}
            display='flex'
            cursor='pointer'
            alignItems='center'
            onClick={() => remove(itemIndex)}
            title={G.getWindowLocale('titles:remove', 'Remove')}
          >
            {I.trash(blueColor)}
          </Flex>
        }
      </Box>
      <Fieldset
        {...props}
        calcZIndex={true}
        fieldsetType='array'
        rowMapIndex={itemIndex}
        arrayLength={arrayLength}
        arrayName={GC.FIELD_LOAD_REFERENCES}
        handleChangeInput={handleChangeInput}
        allowedValues={getAllowedOptions(props)}
        fields={getReferenceFields(props, primaryReferencePermission)}
      />
    </Flex>
  );
};

const ReferencesArray = (props: Object) => {
  const { values, useSingleReference } = props;

  const items = R.pathOr([], [GC.FIELD_REFERENCES], values);
  const arrayLength = R.length(items);

  return (
    <Box>
      <FieldArray
        name={GC.FIELD_LOAD_REFERENCES}
        render={(arrayHelpers: Object) => (
          <Box>
            <Header {...props} push={arrayHelpers.push} />
            <Box
              height='auto'
              maxHeight='calc(80vh - 150px)'
              pb={G.ifElse(R.not(useSingleReference), 15, '0px')}
              overflow={G.ifElse(G.isTrue(useSingleReference), 'unset', 'auto')}
            >
              {
                R.gt(arrayLength, 0) &&
                items.map((item: string, i: number) => (
                  <ItemRow
                    {...props}
                    {...arrayHelpers}
                    key={i}
                    item={item}
                    itemIndex={i}
                    arrayLength={arrayLength}
                  />
                ))
              }
            </Box>
          </Box>
        )}
      />
    </Box>
  );
};

export default ReferencesArray;
