import styled from 'styled-components';
import { space, maxHeight } from 'styled-system';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { RelativeBox } from '../../../ui';
//////////////////////////////////////////////////

export const MenuWrapper = styled.div`
  ${space}

  top: 0;
  width: 60px;
  z-index: 11;
  display: flex;
  height: 100dvh;
  position: fixed;
  user-select: none;
  flex-direction: column;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
  background-color: ${({ bg }: Object) => bg || G.getTheme('colors.light.navbarBackground')};
`;

export const LogoMenuBox = styled(RelativeBox)`
  & > *:first-child {
    left: -60px;
    position: absolute;
    transition: 0.3s left linear;
  }
  & > *:last-child {
    left: 0px;
    position: absolute;
    transition: 0.3s left linear;
  }
  &:hover {
    & > *:first-child {
      left: 0px;
    }
    & > *:last-child {
      left: 60px;
    }
  }
`;

export const Logo = styled.img`
  ${maxHeight}

  max-width: 100%;
  vertical-align: middle;
`;

Logo.defaultProps = {
  maxHeight: 50,
};

export const BottomPart = styled.div`
  gap: 10px;
  width: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

export const PanelToggle = styled.div`
  width: 60px;
  height: 24px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: ${G.getTheme('colors.light.navbarToggle')};
`;
