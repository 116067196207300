import * as R from 'ramda';
import React from 'react';
import { getIn } from 'formik';
import { pure } from 'react-recompose';
import Dropzone from 'react-dropzone';
import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
// components
import { TextComponent } from '../../../../components/text';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import { PHONE_COUNTRIES } from '../../../../helpers/options';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex, ReactAsyncSelect } from '../../../../ui';
// forms
import MultiEmailInputComponent from '../../../../forms/formik/email';
import { DateTimeInput } from '../../../../forms/formik/fieldset2/date-time-input';
// feature new-do
import CountrySelect from './country-select';
import ReactSelectInput from './react-select-input';
import PlaceAutocomplete from './place-autocomplete';
import { CalendarInput, MaskedTimeInput } from './calendar-time-input';
import {
  InputWrapper,
  CheckboxWrapper,
  InputWrapperWithClickZIndex,
} from './input-wrapper';
import {
  Input,
  Checkbox,
  Textarea,
  InputSelect,
  SelectWrapper,
  CalendarWrapper,
  renderBorderColor,
  CalendarIconWrapper,
  CountrySelectWrapper,
  PhoneNumberInputWrapper,
} from '../../ui';
//////////////////////////////////////////////////

// TODO: check all ui/components and move to global forms as Fieldset2

const renderOptions = (options: Array) => {
  if (R.isNil(options)) return;

  return options.map((option: string, index: number) => {
    if (R.is(String, option)) {
      return <option key={index} value={option}>{option}</option>;
    }

    return (
      <option key={index} disabled={option.disabled} value={R.or(option.guid, option.value)}>
        {R.or(option.name, option.label)}
      </option>
    );
  });
};

const getInputWrapperVisibility = (props: any) => {
  const visibility = R.path(['inputWrapperStyles', 'visibility'], props);

  if (G.isBoolean(visibility)) return visibility;

  if (G.isFunction(visibility)) return visibility(props);

  return true;
};

const setInputWrapperVisibilityAndDisplay = (props: any) => {
  const { inputWrapperStyles } = props;

  const visibility = getInputWrapperVisibility(props);
  const wrapperDisplay = R.path(['inputWrapperStyles', 'display'], props);

  if (G.isFunction(wrapperDisplay)) {
    const display = wrapperDisplay(props);

    return {
      ...inputWrapperStyles,
      display,
      visibility,
    };
  }

  return {
    ...inputWrapperStyles,
    visibility,
  };
};

const getInputWrapperStyles = (props: Object) => {
  const { calcZIndex, arrayLength, rowMapIndex, fieldsetType } = props;

  if (R.and(G.isTrue(calcZIndex), R.equals(fieldsetType, 'array'))) {
    const zIndex = R.subtract(R.add(11, arrayLength), rowMapIndex);

    return {
      ...setInputWrapperVisibilityAndDisplay(props),
      zIndex,
    };
  }

  return setInputWrapperVisibilityAndDisplay(props);
};

export const FieldComponent = pure((props: Object) => {
  const {
    name,
    type,
    label,
    value,
    width,
    error,
    options,
    errorTop,
    hasError,
    isRequired,
    inputStyles,
    customInput,
    setFieldValue,
    showFieldConfig,
    setFieldTouched,
    withClickZIndex,
    reactSelectStyles,
    useMultiSelectMaxHeight,
  } = props;

  if (G.isNotNil(showFieldConfig)) {
    const showField = G.getAmousConfigByNameFromWindow(showFieldConfig);

    if (G.isFalse(showField)) return null;
  }

  const wrapperStyles = {
    width,
    error,
    label,
    errorTop,
    hasError,
    isRequired,
    inputWrapperStyles: getInputWrapperStyles(props),
  };

  if (R.equals(type, 'textarea')) {
    return (
      <InputWrapper {...wrapperStyles}>
        <Textarea {...props} />
      </InputWrapper>
    );
  }

  if (R.equals(type, 'checkbox')) {
    return (
      <CheckboxWrapper {...wrapperStyles}>
        <Checkbox {...props} checked={value} />
      </CheckboxWrapper>
    );
  }

  if (R.equals(type, 'select')) {
    return (
      <InputWrapper {...wrapperStyles}>
        <SelectWrapper>
          <InputSelect {...props}>
            {renderOptions(options)}
          </InputSelect>
        </SelectWrapper>
      </InputWrapper>
    );
  }

  if (R.equals(type, 'reactSelect')) {
    if (G.isTrue(withClickZIndex)) {
      return (
        <InputWrapperWithClickZIndex {...wrapperStyles}>
          <Box
            {...G.spreadUiStyles(reactSelectStyles)}
            width={width}
          >
            <ReactSelectInput {...props} hasError={hasError} useMaxHeight={useMultiSelectMaxHeight} />
          </Box>
        </InputWrapperWithClickZIndex>
      );
    }

    return (
      <InputWrapper {...wrapperStyles}>
        <Box
          {...G.spreadUiStyles(reactSelectStyles)}
          width={width}
        >
          <ReactSelectInput {...props} hasError={hasError} useMaxHeight={useMultiSelectMaxHeight} />
        </Box>
      </InputWrapper>
    );
  }

  if (R.equals(type, 'asyncSearch')) {
    return (
      <InputWrapper {...wrapperStyles}>
        <ReactAsyncSelect {...props} />
      </InputWrapper>
    );
  }

  if (R.equals(type, 'multiEmail')) {
    return (
      <InputWrapper {...wrapperStyles}>
        <MultiEmailInputComponent
          {...props}
          minHeight={36}
          borderRadius='4px'
          borderColor={G.getTheme('colors.dark.grey')}
        />
      </InputWrapper>
    );
  }

  if (R.equals(type, 'addressAutocomplete')) {
    return (
      <InputWrapper {...wrapperStyles}>
        <PlaceAutocomplete {...props} width={width} />
      </InputWrapper>
    );
  }

  if (R.equals(type, 'countrySelect')) {
    return (
      <InputWrapper {...wrapperStyles}>
        <CountrySelectWrapper width={width}>
          <CountrySelect {...props} />
        </CountrySelectWrapper>
      </InputWrapper>
    );
  }

  if (R.equals(type, 'calendar')) {
    const Wrapper = G.ifElse(
      G.isTrue(customInput),
      CalendarIconWrapper,
      CalendarWrapper,
    );

    const configDateFormat = G.getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DATE_TIME_FORMAT);
    const timeIntervals = R.or(G.getCalendarTimeIntervalConfigFromWindow(), 15);

    if (G.isTrue(withClickZIndex)) {
      return (
        <InputWrapperWithClickZIndex {...wrapperStyles}>
          <Wrapper width={width}>
            <CalendarInput {...props} timeIntervals={timeIntervals} format={configDateFormat} />
          </Wrapper>
        </InputWrapperWithClickZIndex>
      );
    }

    return (
      <InputWrapper {...wrapperStyles}>
        <Wrapper width={width}>
          <CalendarInput {...props} timeIntervals={timeIntervals} format={configDateFormat} />
        </Wrapper>
      </InputWrapper>
    );
  }

  if (R.equals(type, 'datePicker')) {
    const configTimeIntervals = R.or(G.getCalendarTimeIntervalConfigFromWindow(), 15);
    const configUseMuiCalendar = G.getAmousConfigByNameFromWindow(GC.UI_USE_MUI_CALENDAR);
    const configDateFormat = G.getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DATE_TIME_FORMAT);

    if (G.isFalse(configUseMuiCalendar)) {
      return (
        <InputWrapperWithClickZIndex {...wrapperStyles}>
          <CalendarIconWrapper width={width}>
            <CalendarInput
              {...props}
              width={width}
              customInput={true}
              format={configDateFormat}
              timeIntervals={configTimeIntervals}
            />
          </CalendarIconWrapper>
        </InputWrapperWithClickZIndex>
      );
    }

    return (
      <InputWrapper {...wrapperStyles}>
        <DateTimeInput
          {...props}
          format={configDateFormat}
          timeIntervals={configTimeIntervals}
        />
      </InputWrapper>
    );
  }

  if (R.equals(type, 'timeInput')) {
    const configDateFormat = G.getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DATE_TIME_FORMAT);

    return <MaskedTimeInput {...props} wrapperStyles={wrapperStyles} format={configDateFormat} />;
  }

  if (R.equals(type, 'phoneNumber')) {
    return (
      <InputWrapper {...wrapperStyles}>
        <PhoneNumberInputWrapper
          width={width}
          hasError={hasError}
          phoneInputBorder={props.phoneInputBorder}
          phoneInputPaddingLeft={props.phoneInputPaddingLeft}
          phoneInputBorderRadius={props.phoneInputBorderRadius}
        >
          <PhoneInput
            {...props}
            country='US'
            error={null}
            flags={flags}
            value={value}
            countries={PHONE_COUNTRIES}
            onBlur={() => setFieldTouched(name, true)}
            onChange={(value: Object) => setFieldValue(name, R.or(value, ''))}
            placeholder={G.getWindowLocale('titles:enter-phone-number', 'Enter phone number')}
          />
        </PhoneNumberInputWrapper>
      </InputWrapper>
    );
  }

  if (R.equals(type, 'file')) {
    let fileName;

    if (G.isNotNilAndNotEmpty(value)) fileName = R.pathOr(value, [GC.FIELD_NAME], value);

    return (
      <InputWrapper
        {...wrapperStyles}
        border='1px dotted'
        errorTop={R.or(errorTop, 61)}
      >
        <Dropzone className='drop-zone' onDrop={(files: Array) => setFieldValue(name, R.head(files))}>
          {({ getRootProps, getInputProps }: Object) => (
            <Flex
              {...getRootProps()}
              px={15}
              cursor='pointer'
              borderRadius='4px'
              textAlign='center'
              width={props.width}
              border='1px dashed'
              wordBreak='break-word'
              justifyContent='center'
              borderColor={renderBorderColor(props)}
              height={R.pathOr(60, ['height'], props)}
            >
              <input {...getInputProps()} />
              Drag and Drop or select file from your Computer
            </Flex>
          )}
        </Dropzone>
        {
          G.isNotNilAndNotEmpty(fileName) &&
          <Flex px={15} mt={10} position='relative' width={props.width}>
            <TextComponent
              display='block'
              title={fileName}
              withEllipsis={true}
              maxWidth='calc(100% - 20px)'
            >
              {fileName}
            </TextComponent>
            <Box
              ml='7px'
              cursor='pointer'
              onClick={(event: Object) => {
                event.preventDefault();
                setFieldValue(name, null);
              }}
            >
              {I.trash(G.getTheme('colors.dark.blue'))}
            </Box>
          </Flex>
        }
      </InputWrapper>
    );
  }

  return (
    <InputWrapper {...wrapperStyles}>
      <Input {...props} {...G.spreadUiStyles(inputStyles)} />
    </InputWrapper>
  );
});

const setOptions = (item: Object, props: Object) => {
  const { options } = item;
  const { branchConfigs } = props;

  if (R.is(Array, options)) {
    return options;
  }

  if (R.is(Object, options)) {
    const { configName } = options;

    return G.createOptionsFromDropdownConfigWithGuidOrParentGuid(branchConfigs, configName, true);
  }

  if (R.is(String, options)) {
    return R.prop(options, props);
  }

  return null;
};

const setDisabled = (field: any, values: Object, handlers: Object) => {
  let disabled = field.disabled;

  if (G.isNotNilAndNotEmpty(field.customDisabledFunction)) {
    disabled = handlers[field.customDisabledFunction](values);
  }

  if (G.isFunction(field.disabled)) {
    disabled = field.disabled(field, values);
  }

  return disabled;
};

const getFieldName = (item: Object, props: Object) => {
  const { arrayName, itemIndex, fieldsetType } = props;
  const { fieldName } = item;

  if (R.equals(fieldsetType, 'array')) {
    return `${arrayName}.${itemIndex}.${fieldName}`;
  }

  return fieldName;
};

const getFieldKey = (item: Object, props: Object, index: number) => {
  const { itemIndex, fieldsetType } = props;
  const { fieldName } = item;

  if (R.equals(fieldsetType, 'array')) {
    return `${index}.${itemIndex}.${fieldName}`;
  }

  return `${index}${fieldName}`;
};

const getFieldError = (errors: Object, name: string) => getIn(errors, name);

const checkHasFieldError = (name: string, errors: Object, touched: Object) => {
  const error = getFieldError(errors, name);
  const touch = getIn(touched, name);

  return R.and(G.isNotNilAndNotEmpty(error), R.or(G.isTrue(touch), R.equals(touch, [])));
};

const getChangeHandler = (item: Object, props: Object) => {
  const { handleChange, handleCustomChange } = props;
  const { shouldCustomChange, customChangeHandler } = item;

  if (R.and(G.isTrue(shouldCustomChange), G.isFunction(customChangeHandler))) {
    return (event: Object) => customChangeHandler(event, item, props);
  }

  if (R.and(G.isTrue(shouldCustomChange), G.isFunction(handleCustomChange))) {
    return handleCustomChange;
  }

  return handleChange;
};

const getBlurHandler = (item: Object, props: Object) => {
  const { handleBlur } = props;
  const { shouldCustomBlur, customBlurHandler } = item;

  if (R.and(G.isTrue(shouldCustomBlur), G.isFunction(customBlurHandler))) {
    return (event: Object) => customBlurHandler(event, item, props);
  }

  return handleBlur;
};

export const Fieldset = (props: Object) => {
  const {
    values,
    errors,
    grouped,
    touched,
    handlers,
    fromPage,
    setErrors,
    setValues,
    calcZIndex,
    arrayLength,
    rowMapIndex,
    fieldsetType,
    setFieldError,
    setFieldValue,
    branchConfigs,
    getLoadOptions,
    setFieldTouched,
    handleChangeInput,
    fieldsWrapperStyles,
    customSelectLocationFunction,
    handleAsyncSearchSelectChange,
  } = props;

  if (G.isTrue(grouped)) {
    return (
      <Box>
        {
          props.fields.map((item: Object, index: number) => (
            <Flex key={index} px='10px' flexWrap='wrap' {...G.spreadUiStyles(fieldsWrapperStyles)}>
              {
                item.fields.map((item: Object, index: number) => {
                  const fieldName = getFieldName(item, props);
                  const fieldError = getFieldError(errors, fieldName);
                  const hasError = checkHasFieldError(fieldName, errors, touched);
                  const key = getFieldKey(item, props, index);
                  const changeHandler = getChangeHandler(item, props);
                  const blurHandler = getBlurHandler(item, props);

                  return (
                    <FieldComponent
                      {...item}
                      key={key}
                      id={fieldName}
                      errors={errors}
                      values={values}
                      name={fieldName}
                      error={fieldError}
                      handler={handlers}
                      hasError={hasError}
                      fromPage={fromPage}
                      onBlur={blurHandler}
                      setErrors={setErrors}
                      setValues={setValues}
                      calcZIndex={calcZIndex}
                      onChange={changeHandler}
                      arrayLength={arrayLength}
                      rowMapIndex={rowMapIndex}
                      fieldsetType={fieldsetType}
                      setFieldError={setFieldError}
                      setFieldValue={setFieldValue}
                      branchConfigs={branchConfigs}
                      getLoadOptions={getLoadOptions}
                      setFieldTouched={setFieldTouched}
                      options={setOptions(item, props)}
                      handleChangeInput={handleChangeInput}
                      disabled={setDisabled(item, values, handlers)}
                      value={R.path(R.split('.', fieldName), values)}
                      customSelectLocationFunction={customSelectLocationFunction}
                      handleAsyncSearchSelectChange={handleAsyncSearchSelectChange}
                    />
                  );
                })
              }
            </Flex>
          ))
        }
      </Box>
    );
  }

  return (
    <Flex px='10px' flexWrap='wrap' {...G.spreadUiStyles(fieldsWrapperStyles)}>
      {
        props.fields.map((item: Object, index: number) => {
          const fieldName = getFieldName(item, props);
          const fieldError = getFieldError(errors, fieldName);
          const hasError = checkHasFieldError(fieldName, errors, touched);
          const key = getFieldKey(item, props, index);
          const changeHandler = getChangeHandler(item, props);
          const blurHandler = getBlurHandler(item, props);

          return (
            <FieldComponent
              {...item}
              key={key}
              id={fieldName}
              errors={errors}
              values={values}
              name={fieldName}
              error={fieldError}
              handler={handlers}
              hasError={hasError}
              fromPage={fromPage}
              onBlur={blurHandler}
              setErrors={setErrors}
              setValues={setValues}
              calcZIndex={calcZIndex}
              onChange={changeHandler}
              arrayLength={arrayLength}
              rowMapIndex={rowMapIndex}
              fieldsetType={fieldsetType}
              setFieldError={setFieldError}
              setFieldValue={setFieldValue}
              branchConfigs={branchConfigs}
              getLoadOptions={getLoadOptions}
              setFieldTouched={setFieldTouched}
              options={setOptions(item, props)}
              handleChangeInput={handleChangeInput}
              disabled={setDisabled(item, values, handlers)}
              value={R.path(R.split('.', fieldName), values)}
              customSelectLocationFunction={customSelectLocationFunction}
              handleAsyncSearchSelectChange={handleAsyncSearchSelectChange}
            />
          );
        })
      }
    </Flex>
  );
};
