import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setActiveTab = createAction('setActiveTab');
export const setUsedReport = createAction('setUsedReport');
export const setListLoading = createAction('setListLoading');
export const setInitialState = createAction('setInitialState');
export const getReportListSuccess = createAction('getReportListSuccess');
export const getReportListRequest = createAction('getReportListRequest');
export const resetListAndPagination = createAction('resetListAndPagination');
export const removeCustomExchangeRateRequest = createAction('removeCustomExchangeRateRequest');
export const createOrUpdateCustomExchangeRateRequest = createAction('createOrUpdateCustomExchangeRateRequest');
export const getItemListRequestAndUpdateExchangeRateRequest =
  createAction('getItemListRequestAndUpdateExchangeRateRequest');
