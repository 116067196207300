import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withHandlers, renderNothing } from 'react-recompose';
// components
import { Table } from '../../components/table';
import { TitlePanel } from '../../components/title-panel';
import { PageActions } from '../../components/page-actions';
import { closeModal, openModal } from '../../components/modal/actions';
// features
import { AuthWrapper } from '../permission';
import PC from '../permission/role-permission';
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { withFixedPopover } from '../../hocs/with-fixed-popover';
// icons
import * as I from '../../svgs';
// settings
import { tableSettings } from './settings/table-settings';
// ui
import { IconWrapper, ListWrapper, ZOrderWrapper } from '../../ui';
// feature reference
import ElementActions from './components/element-actions';
import ReferenceTypeFormComponent from './components/reference-type';
import { report, referencesSettings } from './settings/column-settings';
import { LIST_OWN, LIST_AVAILABLE, LIST_OWN_AND_DOWN } from './constants';
import {
  setInitialState,
  changeActiveList,
  cleanQuickFilter,
  setQuickFilterParams,
  resetListAndPagination,
  getReferenceListRequest,
  updateReferenceTypeRequest,
  createNewReferenceTypeRequest,
  clearReferenceTypesFilterParams,
  setReferenceTypeGrantToBranchesRequest,
} from './actions';
import {
  makeSelectActiveList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectListLoading,
  makeSelectFilterProps,
  makeSelectFilterParams,
  makeSelectItemListSorted,
  makeSelectAllReferenceTypesList,
  makeSelectOwnedReferenceTypesList,
  makeSelectAllAvailableReferenceTypesList,
} from './selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withFixedPopover,
  withHandlers({
    handleAddReferenceType: ({ openModal, createNewReferenceTypeRequest }: Object) => () => {
      const component = <ReferenceTypeFormComponent submitAction={createNewReferenceTypeRequest} />;
      const modal = {
        p: 15,
        component,
        options: {
          width: 340,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '80vh',
          title: G.getWindowLocale('actions:add-reference'),
        },
      };
      openModal(modal);
    },
    handleSetActiveList: (props: Object) => (type: string) => (
      props.changeActiveList(type)
    ),
    handleClickEditIcon: (props: Object) => (e: Object, item: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: (
          <ElementActions
            prefix='list'
            parent={item}
            openModal={props.openModal}
            closeModal={props.closeModal}
            closeFixedPopup={props.closeFixedPopup}
            resetListAndPagination={props.resetListAndPagination}
            updateReferenceTypeRequest={props.updateReferenceTypeRequest}
          />
        ),
      })
    ),
    handleCleanFilter: (props: Object) => () => {
      props.cleanQuickFilter();
      props.resetListAndPagination();
      props.getReferenceListRequest(true);
    },
  }),
  branch(
    (props: Object) => R.not(props.initialDataLoaded),
    renderNothing,
  ),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    pagination,
    filterParams,
    handleClickEditIcon,
    getReferenceListRequest,
  } = props;

  const elementActionsComponent = (item: Object) => (
    <AuthWrapper has={[PC.REFERENCE_TYPE_WRITE]}>
      <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, item)}>
        {I.threeDots()}
      </IconWrapper>
    </AuthWrapper>
  );

  const maxHeight = G.ifElse(
    G.hasSearchCriteria(null, filterParams),
    'calc(100vh - 160px)',
    'calc(100vh - 125px)',
  );

  const data = {
    report,
    loading,
    totalCount,
    pagination,
    itemList: R.values(itemList),
    columnSettings: referencesSettings,
    renderRightStickedComponent: elementActionsComponent,
    tableSettings: R.assoc('maxHeight', maxHeight, tableSettings),
    handleLoadMoreEntities: G.ifElse(
      loading,
      () => {},
      getReferenceListRequest,
    ),
  };

  return <Table {...data} />;
};

const multiswitchOptions = [
  {
    width: 100,
    value: LIST_AVAILABLE,
    name: G.getWindowLocale('titles:show-available', 'Show Available'),
  },
  {
    width: 150,
    value: LIST_OWN_AND_DOWN,
    name: G.getWindowLocale('titles:show-own-and-down', 'Show Own and Down'),
  },
  {
    width: 100,
    value: LIST_OWN,
    name: G.getWindowLocale('titles:show-own', 'Show Own'),
  },
];

const ReferenceList = (props: Object) => {
  const { activeList, handleSetActiveList, handleAddReferenceType } = props;

  return (
    <ListWrapper p={15} bgColor={G.getTheme('pages.layOutBgColor')}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          withCount={true}
          noExportable={true}
          noEditReport={true}
          withoutReport={true}
          popperWithCount={true}
          noSelectDropdown={true}
          type={GC.REFERENCE_REPORT}
          options={multiswitchOptions}
          hiddenRightFilterInfo={false}
          useMultiswitchComponent={true}
          onSwitch={handleSetActiveList}
          withoutAddingReportFilter={true}
          totalCount={R.path([activeList, 'totalCount'], props)}
          title={G.getWindowLocale('titles:references', 'References')}
          selectedOptionIndex={G.ifElse(R.equals(activeList, LIST_OWN_AND_DOWN), 1, 1)}
          getQuickFilteredListRequest={() => {
            props.resetListAndPagination();
            props.getReferenceListRequest({ type: 'filtered' });
          }}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        {renderTable(props)}
      </ZOrderWrapper>
      <AuthWrapper has={[PC.ITEM_TEMPLATE_WRITE]}>
        <PageActions
          shadowColor={G.getTheme('createButton.shadowColor')}
          mainAction={{
            action: handleAddReferenceType,
            text: G.getWindowLocale('actions:add-reference', 'Add Reference'),
          }}
        />
      </AuthWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  loading: makeSelectListLoading(state),
  activeList: makeSelectActiveList(state),
  pagination: makeSelectPagination(state),
  totalCount: makeSelectTotalCount(state),
  filterProps: makeSelectFilterProps(state),
  itemList: makeSelectItemListSorted(state),
  filterParams: makeSelectFilterParams(state),
  [LIST_OWN]: makeSelectOwnedReferenceTypesList(state),
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  [LIST_OWN_AND_DOWN]: makeSelectAllReferenceTypesList(state),
  [LIST_AVAILABLE]: makeSelectAllAvailableReferenceTypesList(state),
}));

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  setInitialState,
  changeActiveList,
  cleanQuickFilter,
  setQuickFilterParams,
  resetListAndPagination,
  getReferenceListRequest,
  updateReferenceTypeRequest,
  createNewReferenceTypeRequest,
  clearReferenceTypesFilterParams,
  setReferenceTypeGrantToBranchesRequest,
})(enhance(ReferenceList));
