import * as R from 'ramda';
import Dropzone from 'react-dropzone';
import React, { Fragment } from 'react';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { Tabs, withTabs } from '../../../components/tabs';
// forms
import { ToggleRed } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// hocs
import { withBooleanStatus } from '../../../hocs';
// feature new-do
import PdfPreview from './pdf-preview';
import ErrorsComponent from './errors';
//////////////////////////////////////////////////

const renderModeSettings = [
  {
    mode: 'iframe',
    title: G.getWindowLocale('titles:iframe', 'Iframe'),
  },
  {
    mode: 'canvas',
    title: G.getWindowLocale('titles:canvas', 'Canvas'),
  },
  {
    mode: 'svg',
    title: G.getWindowLocale('titles:svg', 'Svg'),
  },
];

const getModeTextDecoration = (itemMode: string, renderMode: string) => G.ifElse(
  R.equals(itemMode, renderMode),
  'underline',
  'none',
);

const RatePreview = (props: Object) => {
  const {
    activeTab,
    renderMode,
    aiRecognize,
    branchConfigs,
    setRenderMode,
    handleSetFile,
    booleanStatus,
    masterSettings,
    setValueToStore,
    ratePreviewFiles,
    handleSetActiveTab,
    setRatePreviewFiles,
    handleSetRateConfirmation,
    handleToggleBooleanStatus,
  } = props;

  const files = R.or(ratePreviewFiles, []);
  const filePath = R.path([activeTab, 'preview'], files);
  const type = R.path([activeTab, 'type'], files);

  const tabsOptions = R.map((file: Object) => ({ text: G.getPropFromObject('name', file) }), files);

  const documentType = G.getConfigValueFromStore(GC.CLO_GENERAL_CONFIRMATION_DOCUMENT_TYPE, branchConfigs);

  const aiEnabled = R.pathOr(false, [GC.FIELD_BRANCH_SETTINGS_AI_ENABLED], masterSettings);

  const parseDocument = R.path(['parseDocument'], aiRecognize);

  const parseDocumentChecked = G.isBoolean(parseDocument) ? parseDocument : aiEnabled;

  const aiParseColor = G.ifElse(
    G.isTrue(parseDocument),
    G.getTheme('colors.light.mainRed'),
    G.getTheme('colors.light.darkGrey'),
  );

  return (
    <Box>
      {
        G.isNilOrEmpty(filePath) &&
        <Flex minHeight='200px' justifyContent='center' flexDirection='column'>
          <Flex mb={15}>
            {
              aiEnabled && (
                <Fragment>
                  <ToggleRed
                    icons={false}
                    checked={parseDocumentChecked}
                    onChange={(e: Object) => {
                      setValueToStore({
                        value: G.getEventTargetChecked(e),
                        path: 'aiRecognize.parseDocument',
                      });
                    }}
                  />
                  <Box ml={10} color={aiParseColor}>
                    {G.getWindowLocale('titles:parse-with-open-ai', 'Parse with Open AI')}
                  </Box>
                </Fragment>
              )
            }
          </Flex>
          <Dropzone className='drop-zone' onDrop={handleSetRateConfirmation}>
            {({ getRootProps, getInputProps }: Object) => (
              <Flex
                {...getRootProps()}
                p={15}
                cursor='pointer'
                border='1px dotted'
                justifyContent='center'
                color={G.getTheme('colors.greyMatterhorn')}
              >
                <input {...getInputProps()} />
                {/* // TODO: check title with Mark ;) */}
                Drag and Drop or select files from your Computer
              </Flex>
            )}
          </Dropzone>
        </Flex>
      }
      {
        filePath &&
        <Flex
          p={20}
          fontSize={14}
          justifyContent='space-between'
        >
          <Flex>
            <Box mr={10}>
              {`${G.getWindowLocale('titles:file-render-mode', 'File Render Mode')}:`}
            </Box>
            {
              renderModeSettings.map(({ mode, title }: Object) => (
                <Box
                  key={mode}
                  mr={10}
                  cursor='pointer'
                  onClick={() => setRenderMode(mode)}
                  color={G.getTheme('colors.dark.blue')}
                  textDecoration={getModeTextDecoration(mode, renderMode)}
                >
                  {title}
                </Box>
              ))
            }
          </Flex>
          <Box
            cursor='pointer'
            textDecoration='underline'
            color={G.getTheme('colors.dark.blue')}
            onClick={() => setRatePreviewFiles(null)}
          >
            {G.getWindowLocale('actions:remove-files', 'Remove Files')}
          </Box>
          <Dropzone className='drop-zone' onDrop={handleSetFile}>
            {({ getRootProps, getInputProps }: Object) => (
              <Box
                {...getRootProps()}
                cursor='pointer'
                textDecoration='underline'
                color={G.getTheme('colors.dark.blue')}
              >
                <input {...getInputProps()} />
                {G.getWindowLocale('titles:select-files', 'Select Files')}
              </Box>
            )}
          </Dropzone>
        </Flex>
      }
      {
        G.isNilOrEmpty(documentType) && G.isTrue(booleanStatus) && (
        // TODO: check locales with Mark ;)
        <ErrorsComponent
          type='error'
          remove={handleToggleBooleanStatus}
          title='Please, check Order general configurations:'
          errors={[
            'To upload documents you have to set the default document type config',
          ]}
        />
        )
      }
      {
        R.gt(R.length(files), 1) &&
        <Box with='100%' overflow='auto'>
          <Tabs
            tabs={tabsOptions}
            activeTab={activeTab}
            handleClickTab={handleSetActiveTab}
          />
        </Box>
      }
      { filePath && <PdfPreview type={type} file={filePath} renderMode={renderMode} /> }
    </Box>
  );
};

const enhance = compose(
  withBooleanStatus(true),
  withTabs,
  withState('renderMode', 'setRenderMode', 'iframe'),
  withHandlers({
    handleSetFile: (props: Object) => (files: Object) => {
      const { setRatePreviewFiles } = props;

      setRatePreviewFiles(files);
    },
    handleSetRateConfirmation: (props: Object) => (files: Object) => {
      const { aiRecognizeRequest, setRatePreviewFiles } = props;

      setRatePreviewFiles(files);

      aiRecognizeRequest(R.head(files || []));
    },
  }),
  pure,
);

export default enhance(RatePreview);
