import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

export const rowHeight = 30;
export const tableMaxHeight = 'calc(100vh - 300px)';

export const report = {
  fields: [
    { freezed: false, name: 'invoiceNumber', sequence: 1 },
    { freezed: false, name: 'invoiceDate', sequence: 2 },
    { freezed: false, name: 'status', sequence: 3 },
    { freezed: false, name: 'total', sequence: 4 },
    { freezed: false, name: 'totalTripDistance', sequence: 5 },
    { freezed: false, name: 'totalTripWeight', sequence: 6 },
    { freezed: false, name: 'transportationMode', sequence: 7 },
    { freezed: false, name: 'firstEvent', sequence: 8 },
    { freezed: false, name: 'numberOfEvents', sequence: 9 },
    { freezed: false, name: 'lastEvent', sequence: 10 },
    { freezed: false, name: 'comments', sequence: 11 },
  ],
};

export const tableSettings = {
  allowEditBtn: true,
  useMainColors: true,
  checkBoxCellWidth: 40,
  maxHeight: tableMaxHeight,
  titleRowHeight: rowHeight,
  tableRowHeight: rowHeight,
};

export const columnSettings = {
  invoiceNumber: {
    width: 100,
    name: 'titles:invoice-number',
  },
  invoiceDate: {
    width: 100,
    name: 'titles:invoice-date',
  },
  status: {
    width: 100,
    name: 'titles:invoice-status',
  },
  total: {
    width: 100,
    name: 'titles:total',
    customComponent: ({ data }: Object) => (
      <TextComponent
        maxWidth={84}
        withEllipsis={true}
        title={`${data.currency} ${data.total}`}
      >{`${G.getCurrencySymbol(data.currency)} ${G.toFixed(data.total)}`}</TextComponent>
    ),
  },
  totalTripDistance: {
    width: 100,
    name: 'titles:total-distance',
    customComponent: ({ data }: Object) => {
      const {
        deadHeadDistance,
        totalTripDistance,
        deadHeadDistanceUom,
        totalTripDistanceUom,
      } = data;

      if (R.and(R.isNil(deadHeadDistance), R.isNil(totalTripDistance))) return null;

      let displayValue = R.or(totalTripDistance, 0);

      if (G.isNotNil(deadHeadDistance)) {
        const deadHeadDistanceToUse = G.ifElse(
          R.equals(deadHeadDistanceUom, totalTripDistanceUom),
          deadHeadDistance,
          G.convertDistanceAccordingUom(deadHeadDistance, totalTripDistanceUom),
        );
        displayValue = G.mathRoundNumber(R.add(totalTripDistance, deadHeadDistanceToUse));
      }

      return (
        <TextComponent
          maxWidth={84}
          withEllipsis={true}
          title={`${displayValue} ${totalTripDistanceUom}`}
        >
          {displayValue} {totalTripDistanceUom}
        </TextComponent>
      );
    },
  },
  totalTripWeight: {
    width: 100,
    name: 'titles:total-weight',
    customComponent: ({ data }: Object) => (
      <TextComponent
        maxWidth={84}
        withEllipsis={true}
        title={`${data.totalTripWeight} ${data.totalTripWeightUom}`}
      >{
        G.ifElse(
          G.isNotNilAndNotEmpty(data.totalTripWeight),
          `${G.toFixed(data.totalTripWeight)} ${data.totalTripWeightUom}`,
          null,
        )
      }</TextComponent>
    ),
  },
  transportationMode: {
    width: 100,
    name: 'titles:mode',
    customComponent: ({ data }: Object) => (
      <TextComponent
        maxWidth={84}
        withEllipsis={true}
        title={data.transportationMode}
      >{data.transportationMode}</TextComponent>
    ),
  },
  firstEvent: {
    width: 100,
    name: 'titles:first-event',
    customComponent: ({ data }: Object) => (
      <TextComponent
        maxWidth={84}
        withEllipsis={true}
        title={`${data.firstEventCity}, ${data.firstEventState}`}
      >{
        `${data.firstEventCity}, ${data.firstEventState}`
      }</TextComponent>
    ),
  },
  numberOfEvents: {
    width: 100,
    name: 'titles:stops-number',
  },
  lastEvent: {
    width: 100,
    name: 'titles:last-event',
    customComponent: ({ data }: Object) => (
      <TextComponent
        maxWidth={104}
        withEllipsis={true}
        title={`${data.lastEventCity}, ${data.lastEventState}`}
      >{
        `${data.lastEventCity}, ${data.lastEventState}`
      }</TextComponent>
    ),
  },
  comments: {
    width: 100,
    name: 'titles:comments',
  },
};
