import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature public-clo
import * as A from './actions';
//////////////////////////////////////////////////

const initialState = {
  totalCount: 0,
  loading: false,
  itemList: null,
  filterProps: [],
  usedReport: null,
  filterParams: {},
  reportPending: false,
  globalFilterValue: '',
  availableReports: null,
  pagination: {
    limit: 15,
    offset: 0,
  },
};

const setInitialState = () => initialState;

const setReportPending = (state: Object) => P.$set('reportPending', true, state);

const setFilterProps = (state: Object, data: Array) => P.$set('filterProps', data, state);

const setListLoading = (state: Object, loading: boolean) => P.$set('loading', loading, state);

const setReports = (state: Object, reports: Array) => P.$set('availableReports', reports, state);

const resetListAndPagination = (state: Object) => (
  P.$all(
    P.$set('totalCount', 0),
    P.$set('itemList', null),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const setUsedReport = (state: Object, data: Object) => (
  P.$all(
    P.$set('totalCount', 0),
    P.$set('itemList', null),
    P.$set('usedReport', data),
    P.$set('reportPending', false),
    P.$set('globalFilterValue', ''),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const setUsedReportOnly = (state: Object, data: Object) => (
  P.$all(
    P.$set('usedReport', data),
    P.$set('globalFilterValue', ''),
    state,
  )
);

const getReportListSuccess = (state: Object, data: Object) => {
  if (R.equals(data.totalCount, 0)) return state;
  const { itemList, pagination } = state;
  const indexAdditional = R.length(R.values(itemList));
  const newItems = data.results.map((item: Object, i: number) => R.mergeRight(
    item,
    {
      selected: false,
      index: R.add(i, indexAdditional),
    },
  ));
  const list = R.mergeRight(itemList, R.indexBy(R.prop(GC.FIELD_GUID), newItems));
  const newOffset = R.add(pagination.offset, pagination.limit);

  return P.$all(
    P.$set('itemList', list),
    P.$set('totalCount', data.totalCount),
    P.$set(
      'pagination.offset',
      G.ifElse(
        R.gt(data.totalCount, newOffset),
        newOffset,
        data.totalCount,
      ),
    ),
    state,
  );
};

const selectItem = (state: Object, data: Object) => {
  const { itemList } = state;
  if (R.equals(data, 'all')) {
    const value = R.not(R.all(
      (item: Object) => item.selected,
      R.values(itemList),
    ));

    return P.$set(
      'itemList',
      R.map(
        (item: Object) => R.assoc('selected', value, item),
        itemList,
      ),
      state,
    );
  }

  return P.$toggle(`itemList.${data}.selected`, state);
};

const cleanQuickFilter = (state: Object) => (
  P.$all(
    P.$set('itemList', null),
    P.$set('filterParams', {}),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const setQuickFilterParams = (state: Object, data: Object) => (
  P.$set('filterParams', data, state)
);

const setGlobalFilterValue = (state: Object, data: string) => (
  P.$set('globalFilterValue', data, state)
);

const setCloLoadFromEDIStatus = (state: Object, { transactionGuid, status }: Object) => (
  P.$set(`itemList.${transactionGuid}.${GC.FIELD_CLO}.${GC.FIELD_STATUS}`, status, state)
);

export default createReducer({
  [A.setReports]: setReports,
  [A.selectItem]: selectItem,
  [A.setUsedReport]: setUsedReport,
  [A.setFilterProps]: setFilterProps,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setUsedReportOnly]: setUsedReportOnly,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.setGlobalFilterValue]: setGlobalFilterValue,
  [A.getReportListSuccess]: getReportListSuccess,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.setCloLoadFromEDIStatus]: setCloLoadFromEDIStatus,
}, initialState);
