import React from 'react';
import * as R from 'ramda';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { ReactSelect } from '../../../../ui';
// feature rate
import {
  asyncSearch,
  getFleetTrailerList,
  loadFleetTrailerOptions,
  mapFleetTrailersToOptions,
} from './helpers';
//////////////////////////////////////////////////

const enchance = compose(
  withState('searchText', 'setSearchText', null),
  withState('totalCount', 'setTotalCount', null),
  withState('isLoading', 'setLoadingStatus', false),
  withState('fleetTrailers', 'setFleetTrailers', []),
  withHandlers({
    handleChange: (props: Object) => (value: Object) => {
      const {
        fieldName,
        setTotalCount,
        setFieldValue,
        setSearchText,
        setSelectedFleetTrailer,
      } = props;

      if (R.isNil(value)) return setFieldValue(fieldName, null);

      setSearchText(null);
      setTotalCount(null);
      setSelectedFleetTrailer(value);
      setFieldValue(fieldName, R.prop(GC.FIELD_VALUE, value));
    },
    handleScroll: (props: Object) => () => {
      const { fleetTrailers, totalCount, setLoadingStatus } = props;

      if (R.gt(totalCount, R.length(fleetTrailers))) {
        setLoadingStatus(true);
        getFleetTrailerList(props);
      }
    },
    handleSearch: (props: Object) => (search: string) => {
      const { searchText, setSearchText, setLoadingStatus } = props;

      if (R.not(R.and(G.isNilOrEmpty(searchText), R.isEmpty(search)))) {
        setLoadingStatus(true);
        asyncSearch(props, search);
        setSearchText(search);
      }
    },
    handleGetFleetTrailerOptions: (props: Object) => () => {
      const { totalCount, setLoadingStatus } = props;

      if (R.isNil(totalCount)) {
        setLoadingStatus(true);
        loadFleetTrailerOptions(props);
      }
    },
  }),
  pure,
);


const SelectFleetTrailer = (props: Object) => {
  const {
    value,
    fieldName,
    isLoading,
    handleChange,
    handleScroll,
    handleSearch,
    fleetTrailers,
    selectedFleetTrailer,
    handleGetFleetTrailerOptions,
  } = props;

  let options = mapFleetTrailersToOptions(fleetTrailers);

  if (G.isNotNilAndNotEmpty(selectedFleetTrailer)) {
    options = R.prepend(selectedFleetTrailer, options);
  }

  return (
    <ReactSelect
      value={value}
      id={fieldName}
      isClearable={true}
      isLoading={isLoading}
      onChange={handleChange}
      onMenuScrollToBottom={handleScroll}
      onMenuOpen={handleGetFleetTrailerOptions}
      onInputChange={G.setDebounce(handleSearch, 500)}
      options={R.uniqBy(R.prop(GC.FIELD_VALUE), options)}
    />
  );
};

export default enchance(SelectFleetTrailer);
