import React from 'react';
import * as R from 'ramda';
import * as P from 'plow-js';
import {
  pure,
  compose,
  withState,
  lifecycle,
  withHandlers,
} from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncEditDriverInvoice } from '../../../hocs/with-async-edit-driver-invoice';
// ui
import { RelativeBox } from '../../../ui';
// feature payroll
import * as H from '../helpers';
import { TableInner, TableWrapper2 } from '../ui';
import PayrollTableRow, { PayrollTableRowHeader } from './driver-table-row';
//////////////////////////////////////////////////

const initialDriverProps = [
  { name: 'invoices', value: null },
  { name: 'selected', value: true },
  { name: 'expanded', value: false },
  { name: 'allSelected', value: true },
  { name: 'payrollNumber', value: '' },
  { name: 'invoicesError', value: false },
  { name: 'invoicesLoading', value: false },
  { name: 'willNotBeAddedText', value: null },
];

const enhance = compose(
  withAsyncEditDriverInvoice({ updateFrom: 'addPayroll'}),
  withState(
    'drivers',
    'updateDrivers',
    ({ initialData, payrollDates }: Object) => R.indexBy(
      R.prop('driverGuid'),
      G.setPropsWithValuesToArrayItems(
        initialData.map((driver: Object) => (
          R.mergeRight(
            driver,
            {
              dateTo: payrollDates.dateTo,
              dateFrom: payrollDates.dateFrom,
              fullName: `${driver.firstName} ${driver.lastName}`,
            },
          )
        )),
        initialDriverProps,
      ),
    ),
  ),
  withState('checkedAllPayroll', 'setCheckedAllPayroll', ({ drivers }: Object) => G.isAllChecked(R.values(drivers))),
  withState('submitValidation', 'setSubmitValidation', false),
  withHandlers({
    handleClickPayrollCheckbox: (props: Object) => (driver: string) => {
      const { drivers, updateDrivers, setCheckedAllPayroll } = props;

      const updatedDrivers = H.getPayrollsOnTogglePayrollSelect(driver, drivers, GC.FIELD_DRIVER_GUID);

      setCheckedAllPayroll(G.isAllChecked(R.values(updatedDrivers)));

      updateDrivers(updatedDrivers);
    },
    handleClickAllPayrollCheckbox: (props: Object) => () => {
      const { drivers, updateDrivers, setCheckedAllPayroll, checkedAllPayroll } = props;

      setCheckedAllPayroll(R.not(checkedAllPayroll));

      updateDrivers(H.getPayrollsOnAllTogglePayrollSelect(
        drivers,
        checkedAllPayroll,
        GC.FIELD_DRIVER_GUID,
      ));
    },
    handleTogglePayrollDetails: (props: Object) => (driver: string) => {
      const { drivers, updateDrivers } = props;

      const driverState = R.mergeRight(
        driver,
        {
          invoicesError: false,
          expanded: R.not(driver.expanded),
          invoicesLoading: G.isNilOrEmpty(driver.invoices),
        },
      );

      updateDrivers(R.assoc(driver.driverGuid, driverState, drivers));

      if (R.and(
        G.isNilOrEmpty(driver.invoices),
        R.not(driver.expanded),
      )) H.loadInvoices(props, driverState);
    },
    handleChangePayrollNumber: (props: Object) => (value: string, driver: Object) => {
      const { drivers, updateDrivers } = props;

      updateDrivers(R.assocPath(
        [driver.driverGuid, 'payrollNumber'],
        value,
        drivers,
      ));
    },
    handleChangePayrollDateRange: (props: Object) => (dates: string, guid: string) => {
      const { drivers, updateDrivers } = props;

      updateDrivers(P.$all(
        P.$set(`${guid}.dateTo`, dates.dateTo),
        P.$set(`${guid}.dateFrom`, dates.dateFrom),
        drivers,
      ));
    },
    handleClickInvoiceCheckbox: ({ drivers, updateDrivers }: Object) => (driver: Object) => {
      updateDrivers(R.assoc(driver.driverGuid, driver, drivers));
    },
    handleSave: (props: Object) => () => {
      const { drivers, closeModal, addPayrolls, setSubmitValidation } = props;

      const driversToUse = R.filter(
        (driver: Object) => driver.selected,
        R.values(drivers),
      );

      if (R.equals(driversToUse.length, 0)) return closeModal();

      setSubmitValidation(true);

      addPayrolls(driversToUse);
    },
  }),
  lifecycle({
    componentWillUpdate(nextProps: Object) {
      if (G.notEquals(this.props.payrollDates, nextProps.payrollDates)) {
        return this.props.updateDrivers(R.map(
          (driver: Object) => R.mergeRight(
            driver,
            {
              dateTo: nextProps.payrollDates.dateTo,
              dateFrom: nextProps.payrollDates.dateFrom,
            },
          ),
          this.props.drivers,
        ));
      }

      if (R.and(
        G.notEquals(this.props.updatedInvoice, nextProps.updatedInvoice),
        G.isNotNil(nextProps.updatedInvoice),
      )) {
        return this.props.updateDrivers(
          H.recalculateDriverInvoices(this.props.drivers, nextProps.updatedInvoice),
        );
      }
    },
  }),
  pure,
);

const DriverWithInvoicesTable = (props: Object) => {
  const {
    drivers,
    handleSave,
    branchGuid,
    openFixedPopup,
    closeFixedPopup,
    submitValidation,
    checkedAllPayroll,
    autoPayrollNumber,
    handleEditDriverInvoice,
    handleChangePayrollNumber,
    updateDriverInvoiceRequest,
    handleClickInvoiceCheckbox,
    handleClickPayrollCheckbox,
    handleTogglePayrollDetails,
    handleChangePayrollDateRange,
    handleClickAllPayrollCheckbox,
  } = props;

  const empty = R.not(R.any(
    (entity: Object) => entity.selected,
    R.values(drivers),
  ));

  return (
    <RelativeBox width='100%' zIndex='0'>
      <TableWrapper2 maxH='calc(100vh - 400px)'>
        <TableInner>
          {
            G.isNotNilAndNotEmpty(drivers) && (
              <PayrollTableRowHeader
                checkedAllPayroll={checkedAllPayroll}
                handleClickAllPayrollCheckbox={handleClickAllPayrollCheckbox}
              />
            )
          }
          {
            R.values(drivers).map((driver: Object, index: number) => (
              <PayrollTableRow
                data={driver}
                branchGuid={branchGuid}
                openFixedPopup={openFixedPopup}
                closeFixedPopup={closeFixedPopup}
                key={`add-driver-payroll-${index}`}
                toggle={handleTogglePayrollDetails}
                submitValidation={submitValidation}
                autoPayrollNumber={autoPayrollNumber}
                onDateChange={handleChangePayrollDateRange}
                onCheckboxClick={handleClickPayrollCheckbox}
                handleEditDriverInvoice={handleEditDriverInvoice}
                onPayrollNumberChange={handleChangePayrollNumber}
                onInvoiceCheckboxClick={handleClickInvoiceCheckbox}
                updateDriverInvoiceRequest={updateDriverInvoiceRequest}
              />
            ))
          }
        </TableInner>
      </TableWrapper2>
      <FormFooter2
        boxStyles={{ p: 15 }}
        submitDisabled={empty}
        submitAction={G.ifElse(empty, () => {}, handleSave)}
        submitBtnText={G.getWindowLocale('actions:save', 'Save')}
      />
    </RelativeBox>
  );
};

export default enhance(DriverWithInvoicesTable);
