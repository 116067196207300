import { createAction } from 'redux-act';
//////////////////////////////////////////////////

const loginLoadBoard123 = createAction('loginLoadBoard123');
const loginLoadBoardUber = createAction('loginLoadBoardUber');
const loginLoadBoardError = createAction('loginLoadBoardError');
const loginLoadBoardWithCode = createAction('loginLoadBoardWithCode');

export {
  loginLoadBoard123,
  loginLoadBoardUber,
  loginLoadBoardError,
  loginLoadBoardWithCode,
};
