import React from 'react';
import * as R from 'ramda';
import { useWindowSize } from 'react-use';
// components
import { Table } from '../../../../components/table';
// features
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import { Box } from '../../../../ui';
// feature payroll
import PaymentInfo from '../../components/payment-info';
import FuelCardsTab from '../../components/fuel-cards-tab';
import TollChargesTab from '../../components/toll-charges-tab';
import PayrollChargesNew from '../../components/payroll-charges';
import { VendorPayrollStatisticTab } from '../../components/payroll-statistic';
import {
  expensesReport,
  tollTableSettings,
  driverPayrollReport,
  expensesColumnSettings,
  driverPayrollColumnSettings,
  payrollInvoicesTableSettings,
  payrollInvoicesColumnSettings,
  payrollStatisticTableSettings,
} from '../../settings/charges-table-settings';
//////////////////////////////////////////////////

const ActiveTab = (props: Object) => {
  const {
    activeTab,
    collapsedView,
    handleOpenTrip,
    selectChargeListItem,
  } = props;

  const { height } = useWindowSize();

  const maxHeight = collapsedView ? R.subtract(height, 550) : R.subtract(height, 220);

  if (R.equals(activeTab, 0)) {
    return <PayrollChargesNew {...props} withoutTotals={true} />;
  } else if (R.equals(activeTab, 1)) {
    return <FuelCardsTab {...props} maxHeight={maxHeight} />;
  } else if (R.equals(activeTab, 2)) {
    return <TollChargesTab {...props} maxHeight={maxHeight} />;
  } else if (R.equals(activeTab, 3)) {
    const { driverPayrolls } = props;

    const allChecked = G.isAllChecked(driverPayrolls);

    const tableSettings = R.assoc('maxHeight', maxHeight, tollTableSettings);

    return (
      <Table
        allChecked={allChecked}
        itemList={driverPayrolls}
        report={driverPayrollReport}
        tableSettings={tableSettings}
        columnSettings={driverPayrollColumnSettings}
        onOptionClick={(guid: string) => selectChargeListItem(guid, 'driverPayrolls')}
      />
    );
  } else if (R.equals(activeTab, 4)) {
    const { expenses, removeOrRestoreChargeListItem } = props;

    const columnSettings = R.assoc(
      'removeRestoreColumn',
      {
        width: 60,
        customComponent: ({ data }: Object) => {
          const text = G.ifElse(
            data.removed,
            G.getWindowLocale('actions:restore', 'RESTORE'),
            G.getWindowLocale('actions:remove', 'REMOVE'),
          );

          const color = G.getTheme(G.ifElse(
            data.removed,
            'colors.dark.blue',
            'colors.light.mainRed',
          ));

          return (
            <Box
              color={color}
              cursor='pointer'
              onClick={() => removeOrRestoreChargeListItem(G.getGuidFromObject(data), 'expenses')}
            >
              {text}
            </Box>
          );
        },
      },
      expensesColumnSettings,
    );

    const allChecked = G.isAllChecked(expenses);

    const tableSettings = R.assoc('maxHeight', maxHeight, tollTableSettings);

    return (
      <Table
        itemList={expenses}
        allChecked={allChecked}
        report={expensesReport}
        tableSettings={tableSettings}
        columnSettings={columnSettings}
        onOptionClick={(guid: string) => selectChargeListItem(guid, 'expenses')}
      />
    );
  } else if (R.equals(activeTab, 6)) {
    const { payrollInvoices, handleEditDriverInvoice, handleRemoveInvoiceFromPayroll } = props;

    const getPermissions = ({ completed }: Object) => G.ifElse(
      G.isTrue(completed),
      [PC.TEL_INVOICE_OVERWRITE_EXECUTE],
      [PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
    );

    const actionButtons = [
      {
        getPermissions,
        iconName: 'pencil',
        action: handleEditDriverInvoice,
      },
      {
        getPermissions,
        iconName: 'trash',
        action: handleRemoveInvoiceFromPayroll,
      },
    ];

    const report = G.getReportFromColumnSettings(payrollInvoicesColumnSettings);

    const tableSettings = R.assoc('maxHeight', maxHeight, payrollInvoicesTableSettings);

    return (
      <Table
        report={report}
        itemList={payrollInvoices}
        actionButtons={actionButtons}
        tableSettings={tableSettings}
        callbackData={{ handleOpenTrip }}
        columnSettings={payrollInvoicesColumnSettings}
      />
    );
  } else if (R.equals(activeTab, 7)) {
    const { statistic, payrollGuid, statisticCurrency, exportStatisticRequest } = props;

    const tableSettings = R.assoc('maxHeight', maxHeight, payrollStatisticTableSettings);

    return (
      <VendorPayrollStatisticTab
        statistic={statistic}
        payrollGuid={payrollGuid}
        tableSettings={tableSettings}
        statisticCurrency={statisticCurrency}
        exportStatisticRequest={exportStatisticRequest}
      />
    );
  }

  return <PaymentInfo {...props} />;
};

export default ActiveTab;
