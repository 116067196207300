import * as R from 'ramda';
import { createLogic } from 'redux-logic';
// common
import { globalCleanReports } from '../../common/actions';
// features
import { sendLogOutRequest } from '../auth/actions';
import { receivedSwitchBranchSuccess } from '../branch/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature payroll
import { validateCreatePayroll } from './helpers';
import { addPayrolls, setInitialState, createDriversPayrollRequest } from './actions';
import { addPayrolls as addVendorPayrolls, createVendorPayrollsRequest } from './vendor-payroll/actions';
//////////////////////////////////////////////////

const handleAddPayrolls = createLogic({
  type: addPayrolls,
  validate({ getState, action }: Object, allow: void, reject: void) {
    const drivers = action.payload;
    const state = getState();
    const configs = R.path(['payrolls', 'currentBranchConfigs'], state);
    const autoPayrollNumber = G.getConfigValueFromStore(
      GC.INVOICE_TEL_PAYROLL_NUMBER_AUTOGENERATED,
      configs,
    );
    if (R.not(validateCreatePayroll(drivers, autoPayrollNumber))) {
      G.showToastrMessageSimple(
        'info',
        G.getWindowLocale('messages:payroll-number-required', 'Payroll number is required'),
      );
      return reject();
    }
    allow(action);
  },
});

const handleCreateDriverPayrolls = createLogic({
  type: createDriversPayrollRequest,
  validate({ getState, action }: Object, allow: void, reject: void) {
    if (G.isNilOrEmpty(R.path(['payload', 'driverGuids'], action))) {
      G.showToastrMessageSimple(
        'info',
        G.getWindowLocale('messages:select-drivers', 'Please, select at least one driver'),
      );
      return reject();
    }
    allow(action);
  },
});

const handleAddVendorPayrolls = createLogic({
  type: addVendorPayrolls,
  validate({ getState, action }: Object, allow: void, reject: void) {
    const drivers = action.payload;
    const state = getState();
    const configs = R.path(['payrolls', 'currentBranchConfigs'], state);
    const autoPayrollNumber = G.getConfigValueFromStore(
      GC.INVOICE_FLEET_VENDOR_PAYROLL_NUMBER_AUTOGENERATED,
      configs,
    );
    if (R.not(validateCreatePayroll(drivers, autoPayrollNumber))) {
      G.showToastrMessageSimple(
        'info',
        G.getWindowLocale('messages:payroll-number-required', 'Payroll number is required'),
      );
      return reject();
    }
    allow(action);
  },
});

const handleCreateVendorPayrolls = createLogic({
  type: createVendorPayrollsRequest,
  validate({ getState, action }: Object, allow: void, reject: void) {
    if (G.isNilOrEmpty(R.path(['payload', 'fleetVendorGuids'], action))) {
      G.showToastrMessageSimple(
        'info',
        G.getWindowLocale('messages:select-vendors', 'Please, select at least one vendor'),
      );
      return reject();
    }
    allow(action);
  },
});

const handleSwitchBranchOrChangeLocationLogic = createLogic({
  type: [
    sendLogOutRequest,
    globalCleanReports,
    GC.LOCATION_CHANGE_ACTION,
    receivedSwitchBranchSuccess,
  ],
  validate({ getState, action }: Object, allow: void) {
    allow(action);
  },
  process({ getState, action }: Object, dispatch: void, done: void) {
    dispatch(setInitialState());
    done();
  },
});

export default [
  handleAddPayrolls,
  handleAddVendorPayrolls,
  handleCreateDriverPayrolls,
  handleCreateVendorPayrolls,
  handleSwitchBranchOrChangeLocationLogic,
];
