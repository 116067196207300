import React from 'react';
import * as R from 'ramda';
import {
  pure,
  compose,
  withState,
  withHandlers,
} from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
// feature payroll
import * as H from '../helpers';
import { TableInner, TableWrapper2 } from '../ui';
import { PayrollTableRowEdit } from './driver-table-row';
//////////////////////////////////////////////////

const enhance = compose(
  withState('payroll', 'updatePayroll', ({ data, invoices, isVendor }: Object) => (
    R.mergeRight(
      data,
      {
        selected: true,
        expanded: true,
        fullName: G.ifElse(
          isVendor,
          R.pathOr('', [GC.GRC.FLEET_VENDOR_NAME], data),
          `${R.pathOr('', [GC.GRC.DRIVER_FIRST_NAME], data)} ${
            R.pathOr('', [GC.GRC.DRIVER_LAST_NAME], data)}`,
        ),
        invoices: R.indexBy(
          R.prop(GC.FIELD_GUID),
          R.map(
            (invoice: Object) => R.assoc(
              'selected',
              G.isNotNil(R.prop(
                G.ifElse(isVendor, GC.FIELD_FLEET_VENDOR_PAYROLL_GUID, GC.FIELD_INVOICE_DRIVER_PAYROLL_GUID),
                invoice,
              )),
              invoice,
            ),
            invoices,
          ),
        ),
      },
    )
  )),
  withHandlers({
    handleSetDates: (props: Object) => (dates: Object) => {
      const { setLoading, branchGuid, setFilterDates, getFilteredDataRequest } = props;

      setFilterDates(dates);

      const options = {
        data: R.assoc(
          GC.FIELD_BRANCH_GUID,
          branchGuid,
          dates,
        ),
      };

      setLoading();

      getFilteredDataRequest(options);
    },
    handleClickInvoiceCheckbox: ({ updatePayroll }: Object) => (payroll: Object) => updatePayroll(payroll),
    handleSave: ({ payroll, updatePayrollInvoices }: Object) => () => updatePayrollInvoices(H.getUpdatePayrollRequestData(payroll)),
  }),
  pure,
);

const PayrollInvoicesTable = ({
  payroll,
  handleSave,
  handleClickInvoiceCheckbox,
}: Object) => (
  <Box width='100%'>
    <TableWrapper2>
      <TableInner>
        <PayrollTableRowEdit
          data={payroll}
          editMode={true}
          onInvoiceCheckboxClick={handleClickInvoiceCheckbox}
        />
      </TableInner>
    </TableWrapper2>
    <FormFooter2
      boxStyles={{ p: 15 }}
      submitAction={handleSave}
      submitBtnText={G.getWindowLocale('actions:save', 'Save')}
    />
  </Box>
);

export default enhance(PayrollInvoicesTable);
