import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../../helpers';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex } from '../../../../ui';
// feature navbar-menu
import { Logo, LogoMenuBox } from '../ui';
//////////////////////////////////////////////////

export default ({
  styling,
  iconsColor,
  toggleNavbar,
  unreadNotificationsCount,
}: Object) => (
  <LogoMenuBox
    width={60}
    height={50}
    cursor='pointer'
    overflow='hidden'
    onClick={toggleNavbar}
  >
    <Flex
      width={60}
      height={50}
      alignItems='center'
      justifyContent='center'
    >
      {I.menu(iconsColor)}
    </Flex>
    <Flex
      width={60}
      height={50}
      p='5px 10px'
      position='relative'
      alignItems='center'
      justifyContent='center'
      bg={R.or(styling.logoBgColor, 'transparent')}
    >
      <Logo
        alt='logo'
        maxHeight={40}
        src={R.or(styling.logoUrl, G.getLogoLink())}
        title={G.getWindowLocale('menu:menu', 'Control Tower')}
      />
      { R.gt(unreadNotificationsCount, 0) &&
        <Box
          top='6px'
          right='6px'
          zIndex={10}
          width='14px'
          height='14px'
          borderRadius='50%'
          position='absolute'
          border={`2px solid ${G.getTheme('colors.light.mainLight')}`}
          background={G.getTheme('colors.light.notificationsBackground')}
        />
      }
    </Flex>
  </LogoMenuBox>
);

