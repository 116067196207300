import React from 'react';
// components
import { Tabs } from '../../../components/tabs';
// features
import { getTabs } from '../../dispatch-board-new/helpers';
import { tabStyles, countStyles, activeTabStyles } from '../../dispatch-board-new/constants';
// ui
import { Box, Flex, PageHeaderWrapper } from '../../../ui';
/////////////////////////////////////////////////

const TitleTabs = ({ totalCount }: Object) => (
  <PageHeaderWrapper justify='space-between'>
    <Flex>
      <Box mt='auto'>
        <Tabs
          count={totalCount}
          tabStyles={tabStyles}
          countStyles={countStyles}
          tabs={getTabs('publicCLO')}
          activeTabStyles={activeTabStyles} />
      </Box>
    </Flex>
  </PageHeaderWrapper>
);

export default TitleTabs;
