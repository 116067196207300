import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// schemes
import * as VS from '../../schemes';
//////////////////////////////////////////////////

const getStartPointLocationValidSchema = () => {
  const show = G.getAmousBranchConfigFromWindow(GC.TEL_DRIVER_RATE_SHOW_START_FINISH_POINT);
  const required = G.getAmousBranchConfigFromWindow(GC.TEL_DRIVER_RATE_START_POINT_REQUIRED);

  if (R.and(G.isTrue(show), G.isTrue(required))) {
    return {
      [GC.FIELD_START_POINT_LOCATION]: Yup.object()
        .default(null)
        .nullable(true)
        .required(G.getRequiredLocaleTxt())
        .typeError(G.getSelectLocationLocaleTxt()),
    };
  }

  return {
    [GC.FIELD_START_POINT_LOCATION]: Yup.object()
      .nullable(true)
      .typeError(G.getSelectLocationLocaleTxt()),
  };
};

const getFinishPointLocationValidSchema = () => {
  const show = G.getAmousBranchConfigFromWindow(GC.TEL_DRIVER_RATE_SHOW_START_FINISH_POINT);
  const required = G.getAmousBranchConfigFromWindow(GC.TEL_DRIVER_RATE_FINISH_POINT_REQUIRED);

  if (R.and(G.isTrue(show), G.isTrue(required))) {
    return {
      [GC.FIELD_FINISH_POINT_LOCATION]: Yup.object()
        .default(null)
        .nullable(true)
        .required(G.getRequiredLocaleTxt())
        .typeError(G.getSelectLocationLocaleTxt()),
    };
  }

  return {
    [GC.FIELD_FINISH_POINT_LOCATION]: Yup.object()
      .nullable(true)
      .typeError(G.getSelectLocationLocaleTxt()),
  };
};

const getTrailersValidSchema = () => {
  const required = G.getAmousBranchConfigFromWindow(GC.TEL_DRIVER_RATE_TRAILER_REQUIRED);

  if (G.isTrue(required)) {
    return {
      [GC.FIELD_TRAILER_GUIDS]: G.yupArrayRequired,
    };
  }

  return {
    [GC.FIELD_TRAILER_GUIDS]: G.yupArrayNotRequired,
  };
};

const getDriverRateValidationSchema = (values: Object) => {
  const TrailersValidSchema = getTrailersValidSchema();
  const StartPointLocationValidSchema = getStartPointLocationValidSchema();
  const FinishPointLocationValidSchema = getFinishPointLocationValidSchema();

  return Yup.object()
  .shape(TrailersValidSchema)
  .shape(StartPointLocationValidSchema)
  .shape(FinishPointLocationValidSchema)
  .shape(VS.validationSchemaCommentObject)
  .shape(VS.validationSchemaTripServiceObject)
  .shape(VS.validationSchemaMarginViolationObject)
  .shape(VS.getValidationSchemaTripTotalObject(values))
  .shape(VS.validationSchemaFleetWithoutTrailersObject);
};

export {
  getDriverRateValidationSchema,
};
