import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { DEFAULT_QUANTITY_RATE_UNIT_OPTIONS } from '../../../helpers/options';
// feature payroll
import { FIELD_MAIN_CHARGES } from '../constants';
//////////////////////////////////////////////////

const tabsOptions = [
  {
    value: 0,
    text: G.getWindowLocale('titles:additional-deductions', 'Additional/Deductions'),
  },
  {
    value: 1,
    text: G.getWindowLocale('titles:fuel-transactions', 'Fuel Transactions'),
  },
  {
    value: 2,
    text: G.getWindowLocale('titles:vendor-fuel', 'Vendor Fuel'),
  },
  {
    value: 3,
    text: G.getWindowLocale('titles:toll-charges', 'Toll Charges'),
  },
  {
    value: 4,
    text: G.getWindowLocale('titles:vendor-tolls', 'Vendor Tolls'),
  },
  {
    value: 5,
    text: G.getWindowLocale('titles:advance-payment', 'Advance Payments'),
  },
  {
    value: 6,
    text: G.getWindowLocale('titles:expenses', 'Expenses'),
  },
  {
    value: 7,
    text: G.getWindowLocale('titles:driver-log', 'Driver Log'),
  },
  {
    value: 8,
    text: G.getWindowLocale('titles:trip', 'Trip'),
  },
  {
    value: 9,
    text: G.getWindowLocale('titles:payment-info', 'Payment Info'),
  },
];

const vendorTabsOptions = [
  {
    value: 0,
    text: G.getWindowLocale('titles:additional-deductions', 'Additional/Deductions'),
  },
  {
    value: 1,
    text: G.getWindowLocale('titles:fuel-transactions', 'Fuel Transactions'),
  },
  {
    value: 2,
    text: G.getWindowLocale('titles:toll-charges', 'Toll Charges'),
  },
  {
    value: 3,
    text: G.getWindowLocale('titles:driver-payrolls', 'Driver Payrolls'),
  },
  {
    value: 4,
    text: G.getWindowLocale('titles:expenses', 'Expenses'),
  },
  {
    value: 5,
    text: G.getWindowLocale('titles:payment-info', 'Payment Info'),
  },
  {
    value: 6,
    text: G.getWindowLocale('titles:trip', 'Trip'),
  },
  {
    value: 7,
    text: G.getWindowLocale('titles:statistic', 'Statistic'),
  },
];

const rateTypeOptions = [
  GC.EMPTY_OPTION_NULLABLE_OBJECT,
  { label: G.getWindowLocale('titles:flat', 'Flat'), value: GC.CHARGE_RATE_TYPE_FLAT },
  { label: G.getWindowLocale('titles:distance', 'Distance'), value: GC.CHARGE_RATE_TYPE_DISTANCE },
  { label: G.getWindowLocale('titles:weight', 'Weight'), value: GC.CHARGE_RATE_TYPE_WEIGHT },
  { label: G.getWindowLocale('titles:volume', 'Volume'), value: GC.CHARGE_RATE_TYPE_VOLUME },
  { label: G.getWindowLocale('titles:quantity', 'Quantity'), value: GC.CHARGE_RATE_TYPE_QUANTITY },
  { label: G.getWindowLocale('titles:time', 'Time'), value: GC.CHARGE_RATE_TYPE_TIME },
  { label: G.getWindowLocale('titles:stop', 'Stop'), value: GC.CHARGE_RATE_TYPE_STOP },
];

const rateUnitOptionsGroup = {
  [GC.CHARGE_RATE_TYPE_WEIGHT]: [
    { label: 'Kilograms', value: GC.UOM_KILOGRAM },
    { label: 'Pounds', value: GC.UOM_POUND },
  ],
  [GC.CHARGE_RATE_TYPE_VOLUME]: [
    { label: '', value: '' },
    { label: 'Cubic Metre', value: GC.UOM_CUBIC_M },
    { label: 'Cubic Foot', value: GC.UOM_CUBIC_FT },
    { label: 'Gallon Liquid', value: GC.UOM_GALLON_LIQUID },
  ],
  [GC.CHARGE_RATE_TYPE_QUANTITY]: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  [GC.CHARGE_RATE_TYPE_DISTANCE]: [
    { label: 'Kilometers', value: GC.UOM_KILOMETER },
    { label: 'Miles', value: GC.UOM_MILE },
  ],
  [GC.CHARGE_RATE_TYPE_TIME]: [
    { label: 'Per Day', value: GC.CHARGE_RATE_UNIT_TYPE_DAY },
    { label: 'Per Hour', value: GC.CHARGE_RATE_UNIT_TYPE_HOUR },
  ],
  [GC.CHARGE_RATE_TYPE_FLAT]: [],
  [GC.CHARGE_RATE_TYPE_STOP]: [],
};

const payrollInitFields = {
  [FIELD_MAIN_CHARGES]: null,
  [GC.FIELD_PAYROLL_NUMBER]: null,
  [GC.FIELD_PAYROLL_STATUS]: null,
  [GC.FIELD_PAYROLL_CHARGES]: null,
  [GC.FIELD_PAYROLL_DATE_TO]: null,
  [GC.FIELD_PAYROLL_NET_DAYS]: null,
  [GC.FIELD_PAYROLL_DUE_DATE]: null,
  [GC.FIELD_PAYROLL_CURRENCY]: null,
  [GC.FIELD_PAYROLL_DATE_FROM]: null,
  [GC.FIELD_PAYROLL_CHECK_DATE]: null,
  [GC.FIELD_PAYROLL_CHECK_NUMBER]: null,
  [GC.FIELD_PAYROLL_CHECK_AMOUNT]: null,
  [GC.FIELD_PAYROLL_DEPOSIT_DATE]: null,
};

const addDriversPayrollInitFields = R.omit(
  GC.FIELD_PAYROLL_NUMBER,
  payrollInitFields,
);

const chargeInitFields = {
  [GC.FIELD_CHARGE_RATE]: '',
  [GC.FIELD_CHARGE_TOTAL]: 0,
  [GC.FIELD_CHARGE_TYPE]: null,
  [GC.FIELD_CHARGE_QUANTITY]: 1,
  [GC.FIELD_CHARGE_RATE_NAME]: null,
  [GC.FIELD_CHARGE_RATE_UNIT]: null,
  [GC.FIELD_CHARGE_DEDUCTION]: false,
  [GC.FIELD_CHARGE_NON_TAXABLE]: null,
  [GC.FIELD_CHARGE_SEQUENCE_NUM]: null,
  [GC.FIELD_CHARGE_RATE_NAME_STORED]: null,
  [GC.FIELD_CHARGE_RATE_TYPE]: GC.CHARGE_RATE_TYPE_FLAT,
};

const commonProps = {
  labelPl: '0',
  afterTop: 15,
  errorLeft: 5,
  afterRight: 15,
  errorTop: '110%',
  isRequired: true,
  labelWidth: '100%',
  inputWrapMargin: '0',
  flexDirection: 'column',
  errorPosition: 'absolute',
  inputsFlexDirection: 'column',
};

const getFirstFieldset = (glDisabled: boolean) => ({
  fields: [
    {
      ...commonProps,
      width: 250,
      type: 'text',
      loc: 'titles:payroll-number',
      fieldName: GC.FIELD_PAYROLL_NUMBER,
    },
    {
      ...commonProps,
      width: 250,
      type: 'select',
      isRequired: false,
      loc: 'titles:payroll-status',
      options: GC.FIELD_PAYROLL_STATUS,
      fieldName: GC.FIELD_PAYROLL_STATUS,
    },
    {
      ...commonProps,
      width: 250,
      type: 'select',
      isRequired: false,
      disabled: glDisabled,
      loc: 'titles:gl-code',
      options: GC.FIELD_GL_CODE,
      fieldName: GC.FIELD_GL_CODE,
    },
    {
      ...commonProps,
      width: 250,
      type: 'select',
      loc: 'titles:currency',
      options: GC.CURRENCY_OPTIONS,
      fieldName: GC.FIELD_PAYROLL_CURRENCY,
    },
  ],
});

const secondFieldSetCommonProps = R.mergeRight(
  commonProps,
  {
    width: 120,
    errorTitle: true,
    errorWidth: '100%',
    errorOverflow: 'hidden',
    errorWhiteSpace: 'nowrap',
    errorTextOverflow: 'ellipsis',
  },
);

const secondFieldset = {
  fields: [
    {
      ...secondFieldSetCommonProps,
      zIndex: 16,
      width: 156,
      hideIcon: true,
      type: 'calendar',
      isClearable: true,
      loc: 'titles:date-from',
      placeholder: 'Select a day',
      fieldName: GC.FIELD_PAYROLL_DATE_FROM,
    },
    {
      ...secondFieldSetCommonProps,
      zIndex: 15,
      width: 156,
      hideIcon: true,
      type: 'calendar',
      isClearable: true,
      loc: 'titles:date-to',
      placeholder: 'Select a day',
      fieldName: GC.FIELD_PAYROLL_DATE_TO,
    },
    {
      ...secondFieldSetCommonProps,
      zIndex: 14,
      width: 156,
      hideIcon: true,
      type: 'calendar',
      isClearable: true,
      isRequired: false,
      loc: 'titles:payment-due-date',
      placeholder: 'Select a day',
      fieldName: GC.FIELD_PAYROLL_DUE_DATE,
    },
    {
      ...secondFieldSetCommonProps,
      type: 'number',
      isRequired: false,
      loc: 'titles:net-days',
      fieldName: GC.FIELD_PAYROLL_NET_DAYS,
    },
    {
      ...secondFieldSetCommonProps,
      type: 'text',
      isRequired: false,
      loc: 'titles:check-number',
      fieldName: GC.FIELD_PAYROLL_CHECK_NUMBER,
    },
    {
      ...secondFieldSetCommonProps,
      type: 'number',
      isRequired: false,
      loc: 'titles:check-amount',
      fieldName: GC.FIELD_PAYROLL_CHECK_AMOUNT,
    },
    {
      ...secondFieldSetCommonProps,
      zIndex: 13,
      width: 156,
      hideIcon: true,
      type: 'calendar',
      isClearable: true,
      isRequired: false,
      loc: 'titles:check-date',
      placeholder: 'Select a day',
      fieldName: GC.FIELD_PAYROLL_CHECK_DATE,
    },
    {
      ...secondFieldSetCommonProps,
      zIndex: 12,
      width: 156,
      hideIcon: true,
      type: 'calendar',
      isClearable: true,
      isRequired: false,
      loc: 'titles:deposit-date',
      placeholder: 'Select a day',
      fieldName: GC.FIELD_PAYROLL_DEPOSIT_DATE,
    },
  ],
};

const commentsFieldset = {
  fields: [
    {
      ...commonProps,
      width: 540,
      height: 50,
      type: 'textarea',
      isRequired: false,
      groupMargin: '0px',
      loc: 'titles:comments',
      justifyContent: 'center',
      fieldName: GC.FIELD_COMMENTS,
      labelStyles: {
        width: 'auto',
      },
      errorStyles: {
        errorTop: 120,
        errorLeft: '0px',
      },
    },
  ],
};

const getPayrollSectionSettings = (glDisabled: boolean = false) => [
  getFirstFieldset(glDisabled),
  secondFieldset,
  commentsFieldset,
];

const payrollMainChargeFieldSettings = [
  {
    type: 'text',
    fieldWidth: 200,
    disabled: true,
    loc: 'titles:charge-name',
    fieldName: GC.FIELD_DISPLAYED_VALUE,
  },
  {
    type: 'text',
    fieldWidth: 100,
    isRequired: true,
    loc: 'titles:rate',
    fieldName: GC.FIELD_RATE,
  },
  {
    type: 'text',
    disabled: true,
    fieldWidth: 150,
    loc: 'titles:rate-type',
    fieldName: GC.FIELD_RATE_TYPE,
  },
  {
    type: 'text',
    disabled: true,
    fieldWidth: 150,
    loc: 'titles:rate-unit',
    fieldName: GC.FIELD_RATE_UNIT,
  },
  {
    type: 'text',
    fieldWidth: 100,
    isRequired: true,
    loc: 'titles:quantity',
    fieldName: GC.FIELD_QUANTITY,
  },
];

const fieldsWrapperStyles = { mb: 30, width: '100%' };

const inputWrapperStyles = { mr: 15, flexGrow: 1, flexBasis: 60 };

const inputWrapperStyles2 = { flexGrow: 1, flexBasis: 60 };

const payrollWithChargesFieldsetSettings = [
  {
    fieldsWrapperStyles,
    fields: [
      {
        type: 'text',
        isRequired: true,
        inputStyles: { pr: 15 },
        fieldName: GC.FIELD_PAYROLL_NUMBER,
        label: ['titles:payroll-number', 'Payroll #'],
        inputWrapperStyles: { mr: 15, flexGrow: 0, flexBasis: 110 },
      },
      {
        type: 'select',
        isRequired: true,
        options: GC.CURRENCY_OPTIONS_2,
        fieldName: GC.FIELD_PAYROLL_CURRENCY,
        label: ['titles:currency', 'Currency'],
        inputWrapperStyles: { mr: 15, flexGrow: 0, flexBasis: 65 },
      },
      {
        type: 'select',
        options: GC.FIELD_PAYROLL_STATUS,
        fieldName: GC.FIELD_PAYROLL_STATUS,
        inputWrapperStyles: inputWrapperStyles2,
        label: ['titles:payroll-status', 'Payroll Status'],
      },
    ],
  },
  {
    fieldsWrapperStyles,
    fields: [
      {
        isRequired: true,
        type: 'datePicker',
        inputWrapperStyles,
        fieldName: GC.FIELD_PAYROLL_DATE_FROM,
        label: ['titles:date-from', 'Date From'],
      },
      {
        isRequired: true,
        type: 'datePicker',
        fieldName: GC.FIELD_PAYROLL_DATE_TO,
        label: ['titles:date-to', 'Date To'],
        inputWrapperStyles: inputWrapperStyles2,
      },
    ],
  },
  {
    fieldsWrapperStyles: { width: '100%' },
    fields: [
      {
        type: 'textarea',
        inputStyles: {
          p: 15,
          height: 131,
        },
        inputWrapperStyles: {
          flexGrow: 1,
          flexBasis: 300,
        },
        fieldName: GC.FIELD_COMMENTS,
        label: ['titles:comments', 'Comments'],
      },
    ],
  },
];

const payrollInfoFieldsetSettings = [
  {
    type: 'select',
    inputWrapperStyles,
    options: GC.FIELD_GL_CODE,
    fieldName: GC.FIELD_GL_CODE,
    label: ['titles:gl-code', 'GL Code'],
    customDisabledFunction: 'handleDisableGlCode',
  },
  {
    type: 'datePicker',
    inputWrapperStyles,
    fieldName: GC.FIELD_PAYROLL_DUE_DATE,
    label: ['titles:payment-due-date', 'Payment Due Date'],
  },
  {
    type: 'number',
    inputWrapperStyles,
    fieldName: GC.FIELD_PAYROLL_NET_DAYS,
    label: ['titles:net-days', 'Net Days'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_PAYROLL_CHECK_NUMBER,
    label: ['titles:check-number', 'Check Number'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_PAYROLL_CHECK_AMOUNT,
    label: ['titles:check-amount', 'Check Amount'],
  },
  {
    type: 'datePicker',
    inputWrapperStyles,
    fieldName: GC.FIELD_PAYROLL_CHECK_DATE,
    label: ['titles:check-date', 'Check Date'],
  },
  {
    type: 'datePicker',
    inputWrapperStyles: inputWrapperStyles2,
    fieldName: GC.FIELD_PAYROLL_DEPOSIT_DATE,
    label: ['titles:deposit-date', 'Deposit Date'],
  },
];

export {
  tabsOptions,
  rateTypeOptions,
  chargeInitFields,
  payrollInitFields,
  vendorTabsOptions,
  rateUnitOptionsGroup,
  getPayrollSectionSettings,
  payrollInfoFieldsetSettings,
  addDriversPayrollInitFields,
  payrollMainChargeFieldSettings,
  payrollWithChargesFieldsetSettings,
};
