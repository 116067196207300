import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { connect } from 'react-redux';
import { withFormik, FieldArray } from 'formik';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
import { openModal, closeModal } from '../../../components/modal/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature new-do
import { Fieldset } from './formik/fieldset';
import {
  refFieldsForNextStartDate1,
  refFieldsForNextStartDate2,
  containerFieldsForNextStartDate,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const fieldSettings = (index: number) => [
  {
    width: 250,
    isRequired: true,
    timeIntervals: 1,
    type: 'datePicker',
    timeSelection: true,
    inputWrapperStyles: { mt: 20 },
    placeholder: GC.DEFAULT_DATE_TIME_PLACEHOLDER,
    label: `${G.getWindowLocale('titles:clo', 'CLO')} ${R.inc(index)}`,
    fieldName: `${GC.FIELD_CLO_MULTI_CREATE_DATA}.${index}.${GC.FIELD_START_DATE}`,
  },
];

const blueColor = G.getTheme('colors.dark.blue');
const textColor = G.getTheme('colors.greyMatterhorn');
const borderColor = G.getTheme('colors.light.darkGrey');

const validationSchema = () => Yup.object().shape({
  [GC.FIELD_CLO_MULTI_CREATE_DATA]: Yup.array().of(Yup.object().shape({
    [GC.FIELD_START_DATE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
    [GC.FIELD_REFERENCES]: Yup.array().notRequired().of(Yup.object().shape({
      [GC.FIELD_VALUE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
      [GC.FIELD_REFERENCE_TYPE_GUID]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
    })),
    [GC.FIELD_CONTAINERS]: Yup.array().of(Yup.object().shape({
      [GC.FIELD_CONTAINER_NUMBER]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
      [GC.FIELD_CONTAINER_INITIAL]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
    })),
  })),
});

const getInitialValues = (numberOfLoads: number, references: Array = [], containers: Array = [], configs: Object) => {
  const val = G.toNumber(numberOfLoads);
  const config = G.getConfigValueFromStore(GC.CLO_UI_MULTI_CREATE_FIELDS, configs, []);

  const mapReferences = () => {
    let data = [];

    if (R.includes(GC.CONFIG_FIELD_REFERENCE, config)) {
      data = R.map(
        ({ referenceTypeGuid }: Object) => ({
          referenceTypeGuid,
          [GC.FIELD_VALUE]: null,
        }),
        references,
      );
    }

    return data;
  };

  const mapContainers = () => {
    let data = [];

    if (R.includes(GC.CONFIG_FIELD_CONTAINER, config)) {
      data = R.map(
        ({ weightUom, containerNumber, fullContainerWeight, containerInternalId }: Object) => ({
          containerNumber: '',
          containerInternalId,
          containerInitial: '',
          label: `${R.or(containerNumber, '')} ${R.or(fullContainerWeight, '')} ${R.or(weightUom, '')}`,
        }),
        containers,
      );
    }

    return data;
  };

  const ordersQty = G.ifElse(
    G.isNumber(val),
    R.dec(val),
    0,
  );

  return {
    [GC.FIELD_CLO_MULTI_CREATE_DATA]: R.times(R.identity, ordersQty).map(() => ({
      [GC.FIELD_START_DATE]: null,
      [GC.FIELD_REFERENCES]: mapReferences(),
      [GC.FIELD_CONTAINERS]: mapContainers(),
    })),
  };
};

const getAllowedValues = (props: Object, prefix: string) => {
  const { form, referenceTypes } = props;

  const path = R.split('.', `values.${prefix}.${GC.FIELD_REFERENCE_TYPE_GUID}`);
  const itemRefTypeGuid = R.path(path, form);
  const allowedValues = R.path(['allowedValues'], R.find(R.propEq(itemRefTypeGuid, GC.FIELD_VALUE), referenceTypes));

  if (G.isNotNilAndNotEmpty(allowedValues)) return allowedValues;

  return [];
};

const getAllowedOptions = (props: Object, prefix: string) => {
  const allowedValues = getAllowedValues(props, prefix);

  if (G.isNotNilAndNotEmpty(allowedValues)) {
    return G.addEmptyOptionToDropDown(R.map(
      (item: string) => ({ label: item, value: item }),
      allowedValues,
    ));
  }

  return [];
};

const getReferenceFields = (props: Object, prefix: string) => {
  const allowedValues = getAllowedValues(props, prefix);

  let fields;

  if (G.isNotNilAndNotEmpty(allowedValues)) {
    fields = refFieldsForNextStartDate2(prefix);
  } else {
    fields = refFieldsForNextStartDate1(prefix);
  }

  if (G.isNotNil(R.path(['item', GC.FIELD_GUID], props))) {
    fields = R.assocPath([0, 'disabled'], true, fields);
  }

  return fields;
};

const Sections = (props: Object) => {
  const {
    form,
    branchConfigs,
    referenceTypes,
    showNumberOfLoadMessage,
    handleRemoveReferenceRow,
  } = props;

  const cloMultiCreateData = R.pathOr([], ['values', GC.FIELD_CLO_MULTI_CREATE_DATA], form);

  const numberOfLoadMessage = G.getWindowLocale(
    'titles:you-can-create-up-to-10-orders',
    'You can create up to 10 orders');

  return (
    <Box>
      <Flex fontWeight='bold' color={textColor} width='max-content'>
        {G.getWindowLocale('titles:next-start-dates', 'Next Start Dates')}
        {showNumberOfLoadMessage && ` (${numberOfLoadMessage})`}
      </Flex>
      {
        cloMultiCreateData.map((_: any, index: number) => {
          const references = R.path(['values', GC.FIELD_CLO_MULTI_CREATE_DATA, index, GC.FIELD_REFERENCES], form);
          const containers = R.path(['values', GC.FIELD_CLO_MULTI_CREATE_DATA, index, GC.FIELD_CONTAINERS], form);

          return (
            <Flex key={index}>
              <Box
                mr={12}
                cursor='pointer'
                onClick={() => props.remove(index)}
              >
                {I.trash(blueColor)}
              </Box>
              <Flex
                p={15}
                mt={10}
                width='100%'
                borderRadius='4px'
                border='1px solid'
                flexDirection='column'
                alignItems='flex-start'
                borderColor={borderColor}
              >
                <Flex>
                  <Fieldset
                    {...form}
                    branchConfigs={branchConfigs}
                    fields={fieldSettings(index)}
                    fieldsWrapperStyles={{ p: '10px 0, 15px, 0' }}
                  />
                  {
                    G.isNotNilAndNotEmpty(references) &&
                    <Box ml={20}>
                      <Box color={textColor} fontWeight='bold' textAlign='center'>
                        {G.getWindowLocale('titles:references', 'References')}
                      </Box>
                      {
                        references.map((_: any, refIndex: number) => {
                          const prefix = `${GC.FIELD_CLO_MULTI_CREATE_DATA}.${
                            index}.${GC.FIELD_REFERENCES}.${refIndex}`;

                          return (
                            <Flex pt={15} key={refIndex}>
                              <Fieldset
                                {...form}
                                fieldsWrapperStyles={{ px: 0, pb: 15 }}
                                fields={getReferenceFields(props, prefix)}
                                allowedValues={getAllowedOptions(props, prefix)}
                                referenceTypes={G.addEmptyOptionToDropDown(referenceTypes)}
                              />
                              <Box cursor='pointer' onClick={() => handleRemoveReferenceRow(prefix)}>
                                {I.trash(blueColor)}
                              </Box>
                            </Flex>
                          );
                        })
                      }
                    </Box>
                  }
                </Flex>
                {
                  G.isNotNilAndNotEmpty(containers) &&
                  <Box ml={10} minWidth={680} width='calc(100% - 33px)'>
                    <Box color={textColor} fontWeight='bold' textAlign='center'>
                      {G.getWindowLocale('titles:containers', 'Containers')}
                    </Box>
                    {
                      containers.map(({ label }: any, contIndex: number) => {
                        const prefix = `${GC.FIELD_CLO_MULTI_CREATE_DATA}.${index}.${GC.FIELD_CONTAINERS}.${contIndex}`;

                        return (
                          <Flex pt={15} key={contIndex}>
                            <Box mb={12} fontSize={12} color={textColor}>{label}</Box>
                            <Fieldset
                              {...form}
                              fields={containerFieldsForNextStartDate(prefix)}
                              fieldsWrapperStyles={{ px: 0, pb: 15, ml: 'auto' }}
                            />
                          </Flex>
                        );
                      })
                    }
                  </Box>
                }
              </Flex>
            </Flex>
          );
        })
      }
    </Box>
  );
};

const formEnhance = compose(
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props }: Object) => {
      const { closeModal, sendDataToApiRequest } = props;

      const cloMultiCreateData = R.compose(
        R.map(R.pick([GC.FIELD_REFERENCES, GC.FIELD_START_DATE, GC.FIELD_CONTAINERS])),
        R.pathOr([], [GC.FIELD_CLO_MULTI_CREATE_DATA]),
      )(values);

      sendDataToApiRequest({ cloMultiCreateData });
      closeModal();
    },
    mapPropsToValues: ({ initialValues }: Object) => initialValues,
  }),
  withHandlers({
    handleRemoveReferenceRow: ({ values, setValues }: Object) => (prefix: string) => {
      const path = R.compose(
        R.map((item: string) => {
          if (G.isNaN(G.toNumber(item))) return item;

          return G.toNumber(item);
        }),
        R.split('.'),
      )(prefix);
      const newValues = R.dissocPath(path, values);

      setValues(newValues);
    },
  }),
  pure,
);

const NextStartDatesForm = formEnhance((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldArray
      name={GC.FIELD_CLO_MULTI_CREATE_DATA}
      render={(arrayHelpers: Object) =>
        <Sections
          {...arrayHelpers}
          branchConfigs={props.branchConfigs}
          referenceTypes={props.referenceTypes}
          showNumberOfLoadMessage={props.showNumberOfLoadMessage}
          handleRemoveReferenceRow={props.handleRemoveReferenceRow}
        />
      }
    />
    <FormFooter2 boxStyles={{ pt: 20 }} closeModal={props.closeModal} />
  </form>
));

export const withNextStartDatesForm = compose(
  connect(null, { openModal, closeModal }),
  withHandlers({
    handleOpenNextStartDatesForm: (props: Object) => () => {
      const {
        stops,
        openModal,
        closeModal,
        numberOfLoads,
        branchConfigs,
        branchRefTypes,
        sendDataToApiRequest,
      } = props;

      const numberOfLoadsToUse = G.ifElse(R.lte(numberOfLoads, 10), numberOfLoads, 10);
      const containers = R.compose(
        G.mapArrayObjectEmptyStringFieldsToNull,
        R.reduce(
          (acc: Array, stop: Object) => R.concat(
            acc,
            R.pathOr([], ['formData', GC.FIELD_STOP_PICKED_UP_CONTAINERS], stop),
          ),
          [],
        ),
        R.values,
      )(stops);
      const initialValues = getInitialValues(
        numberOfLoadsToUse,
        R.pathOr([], ['referenceFormData', GC.FIELD_REFERENCES], props),
        containers,
        branchConfigs,
      );
      const referenceTypes = R.compose(
        R.map((item: Object) => ({
          value: G.getGuidFromObject(item),
          label: G.getPropFromObject(GC.FIELD_NAME, item),
          allowedValues: G.getPropFromObject(GC.FIELD_REFERENCE_ALLOWED_VALUES, item),
        })),
        R.pathOr([], [GC.REF_SCOPE_NAME_CLO]),
      )(branchRefTypes);

      const component = (
        <NextStartDatesForm
          closeModal={closeModal}
          branchConfigs={branchConfigs}
          initialValues={initialValues}
          referenceTypes={referenceTypes}
          numberOfLoads={numberOfLoadsToUse}
          sendDataToApiRequest={sendDataToApiRequest}
          showNumberOfLoadMessage={R.gt(numberOfLoads, 10)}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 'auto',
          height: 'auto',
          overflow: 'auto',
          maxHeight: '88vh',
        },
      };

      openModal(modal);
    },
  }),
  pure,
);
