import * as R from 'ramda';
import styled from 'styled-components';
import { space, borderColor } from 'styled-system';
// helpers/constants
import * as G from '../../../../helpers';
//////////////////////////////////////////////////

export const IconWrapper = styled.span`
  width: 24px;
`;

export const LgMenuWrapper = styled.div`
  top: 0;
  width: 265px;
  display: flex;
  height: 100dvh;
  position: fixed;
  flex-direction: column;
  transition: left .2s linear;
  justify-content: space-between;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  left: ${({ isMenuOpen }: Object) => G.ifElse(isMenuOpen, '60px', '-265px')};
  background: ${({ bg }: Object) => bg || G.getTheme('colors.light.mainLight')};

  ${({ glassEffect }: Object) =>
    glassEffect &&
    `
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
    `
  };
`;

export const BranchDropDownWrapper = styled.div`
  width: 100%;
  height: 94px;
  overflow-y: auto;
  padding: 5px 10px 0;
  color: ${({ color }: Object) => color || G.getTheme('header.textColor')};
  & > div {
    display: flex;
    padding: 4px 0;
    align-items: center;
    &:hover {
      cursor: pointer;
      font-weight: bold;
      color: ${({ color }: Object) => color || G.getTheme('colors.light.darkRed')};
    }
    & svg {
      width: 18px;
      height: 18px;
    }
    & > span:last-child {
      padding: 0 5px;
    }
  }
`;

export const HrDivider = styled.hr`
  ${space}
  ${borderColor}

  opacity: 0.2;
  border-top: '2px solid';
`;

HrDivider.defaultProps = {
  borderColor: 'darkGrey',
};

export const ReleaseVersionText = styled.div`
  ${space}

  &:hover {
    font-weight: ${({ withHover }: Object) => R.and(withHover, 'bold')};
    cursor: ${({ withHover }: Object) => G.ifElse(withHover, 'pointer', 'unset')};
  }
`;

export const ReleaseVersionLink = styled.a`
  ${space}

  color: inherit;
  text-decoration: inherit;

  &:hover {
    font-weight: ${({ withHover }: Object) => R.and(withHover, 'bold')};
    cursor: ${({ withHover }: Object) => G.ifElse(withHover, 'pointer', 'unset')};
  }
`;

export const SidebarGroupWrapper = styled.div`
  overflow: hidden;
  transition: 0.5s;
  max-height: ${({ opened }: Object) => G.ifElse(opened, '1500px', '40px')};
`;

export const SidebarItem = styled.div`
  height: 40px;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  padding: ${({ isSubmenuItem }: Object) => (
    G.ifElse(
      G.isTrue(isSubmenuItem),
      '0 10px 0 20px',
      '0 10px',
    ))
  };
  ${({ color, active }: Object) => (
    R.and(
      active,
      `&:before {
        left: 0;
        width: 3px;
        content: '';
        height: 100%;
        position: absolute;
        background-color: ${color || G.getTheme('menus.sidebar.itemTextColor')};
      }`,
    )
  )}
  border-left: ${({ opened }: Object) => (
    G.ifElse(
      G.isTrue(opened),
      `2px solid  ${G.getTheme('menus.sidebar.itemTextColorHovered')}`,
      G.getTheme('menus.sidebar.itemTextColor'),
    )
  )};
  font-weight: ${({ opened, active }: boolean) => R.and(opened || active, 'bold')};
  color: ${({ color }: Object) => color || G.getTheme('menus.sidebar.itemTextColor')};
  & > span {
    margin: 5px;
    display: inline-flex;
  }
  &:hover {
    font-weight: bold;
  }
`;

export const SidebarItemRightPlusIcon = styled.div`
  right: 0;
  top: 30%;
  font-weight: bold;
  margin-right: 12px;
  position: absolute;
`;

export const SidebarItemRightArrowIcon = styled.div`
  right: 0;
  top: 30%;
  margin-right: 10px;
  position: absolute;
`;

