import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withHandlers, renderNothing } from 'react-recompose';
// components
import { PageActions } from '../../components';
import { Table } from '../../components/table';
import { Tabs2 } from '../../components/tabs-mui';
import { TitlePanel } from '../../components/title-panel';
import { openModal } from '../../components/modal/actions';
import { ConfirmComponent } from '../../components/confirm';
// features
import { AuthWrapper } from '../permission';
import PC from '../permission/role-permission';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// ui
import { ListWrapper, ZOrderWrapper } from '../../ui';
// feature normalize-currency
import { tableSettings } from './settings/table-settings';
import { getReport, columnSettings } from './settings/column-settings';
import CustomExchangeRateForm from './components/custom-exchange-rate-form';
import {
  setActiveTab,
  setUsedReport,
  getReportListRequest,
  resetListAndPagination,
  removeCustomExchangeRateRequest,
  createOrUpdateCustomExchangeRateRequest,
} from './actions';
import {
  makeSelectItemList,
  makeSelectActiveTab,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectListLoading,
} from './selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleListRequest: ({ getReportListRequest }: Object) => (isInitial: boolean = false) =>
      getReportListRequest(isInitial),
    handleCreateOrUpdateCustomExchangeRate: (props: Object) => (guid: string, entity: Object) => {
      const { openModal, createOrUpdateCustomExchangeRateRequest } = props;

      const component = (
        <CustomExchangeRateForm
          initialValues={entity}
          submitAction={createOrUpdateCustomExchangeRateRequest}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title: G.ifElse(
            R.isNotNil(G.getGuidFromObject(entity)),
            G.getEditTitle,
            G.getAddTitle,
          )(['titles:custom-exchange-rate', 'Custom Exchange Rate']),
        },
      };

      openModal(modal);
    },
    handleRemoveCustomExchangeRate: (props: Object) => (guid: string) => {
      const {
        openModal,
        removeCustomExchangeRateRequest,
      } = props;

      const component = (
        <ConfirmComponent
          name={G.getWindowLocale('titles:custom-exchange-rate', 'Custom Exchange Rate')}
          textLocale={G.getWindowLocale(
            'messages:delete-confirmation-text-double',
            'Are you sure you want to delete',
          )}
        />
      );

      const modal = {
        component,
        options: {
          width: 600,
          height: 'auto',
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => removeCustomExchangeRateRequest(guid),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  branch(
    ({ initialDataLoaded }: Object) => R.not(initialDataLoaded),
    renderNothing,
  ),
  pure,
);

const FILTER_PARAMS = [
  {
    type: 'string',
    value: GC.FIELD_DATE,
    name: G.getWindowLocale('titles:date', 'Date'),
  },
];

export const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    activeTab,
    totalCount,
    pagination,
    getReportListRequest,
    handleRemoveCustomExchangeRate,
    handleCreateOrUpdateCustomExchangeRate,
  } = props;

  const actionButtons = G.ifElse(
    R.equals(activeTab, 1),
    [
      {
        iconName: 'pencil',
        permissions: [PC.CUSTOM_EXCHANGE_RATE_WRITE],
        action: handleCreateOrUpdateCustomExchangeRate,
      },
      {
        iconName: 'trash',
        action: handleRemoveCustomExchangeRate,
        permissions: [PC.CUSTOM_EXCHANGE_RATE_WRITE],
      },
    ],
  );

  const data = {
    loading,
    totalCount,
    pagination,
    actionButtons,
    columnSettings,
    itemList: R.values(itemList),
    report: getReport(itemList, activeTab),
    handleLoadMoreEntities: G.ifElse(loading, () => {}, getReportListRequest),
    tableSettings: R.assoc('checkBoxCellWidth', G.ifElse(R.equals(activeTab, 1), 60, 0), tableSettings),
  };

  return <Table {...data} />;
};

const renderCustomTitleComponent = (props: Object) => {
  if (G.hasNotAmousCurrentUserPermissions([
    PC.CUSTOM_EXCHANGE_RATE_READ,
    PC.CUSTOM_EXCHANGE_RATE_WRITE,
  ])) return null;

  const { activeTab, totalCount, setActiveTab, getReportListRequest, resetListAndPagination } = props;

  const tabs = [
    {
      value: 0,
      withCount: R.equals(activeTab, 0),
      text: G.getWindowLocale('titles:currency-exchange-rate', 'Currency Exchange Rate'),
    },
    {
      value: 1,
      withCount: R.equals(activeTab, 1),
      text: G.getWindowLocale('titles:custom-currency-exchange-rate', 'Custom Currency Exchange Rate'),
    },
  ];

  const handleSetActiveTab = (value: number) => {
    setActiveTab(value);
    resetListAndPagination();
    getReportListRequest();
  };

  return (
    <Tabs2
      tabs={tabs}
      count={totalCount}
      activeTab={activeTab}
      setActiveTab={handleSetActiveTab}
      tabStyles={GC.COMMON_MUI_TAB_STYLES}
      tabsStyles={GC.COMMON_MUI_TABS_STYLES}
    />
  );
};

const NormalizeCurrencyList = (props: Object) => (
  <ListWrapper p={15}>
    <ZOrderWrapper zIndex={2}>
      <TitlePanel
        {...props}
        version={2}
        withCount={true}
        noExportable={true}
        popperWithCount={true}
        filterProps={FILTER_PARAMS}
        hiddenRightFilterInfo={true}
        usedReport={props.usedReport}
        setUsedReport={props.setUsedReport}
        customTitleComponent={renderCustomTitleComponent(props)}
        title={G.getWindowLocale('titles:currency-exchange-rate', 'Currency Exchange Rate')}
      />
    </ZOrderWrapper>
    <ZOrderWrapper zIndex='1'>
      {renderTable(props)}
    </ZOrderWrapper>
    {
      R.propEq(1, 'activeTab', props) &&
      <AuthWrapper has={[PC.CUSTOM_EXCHANGE_RATE_WRITE]}>
        <PageActions
          shadowColor={G.getTheme('colors.light.grey')}
          mainAction={{
            action: props.handleCreateOrUpdateCustomExchangeRate,
            text: G.getAddTitle(['titles:custom-exchange-rate', 'Custom Exchange Rate']),
          }}
        />
      </AuthWrapper>
    }
  </ListWrapper>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  activeTab: makeSelectActiveTab(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  usedReport: makeSelectUsedReport(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  setActiveTab,
  setUsedReport,
  getReportListRequest,
  resetListAndPagination,
  removeCustomExchangeRateRequest,
  createOrUpdateCustomExchangeRateRequest,
})(enhance(NormalizeCurrencyList));
