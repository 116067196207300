import * as R from 'ramda';
import React from 'react';
// components
import { withTabs2 } from '../../components/tabs-mui';
// feature payroll
import DriverPayrolls from './driver-payroll';
import VendorPayrolls from './vendor-payroll';
import PivotPayrolls from './components/pivot-payrolls';
//////////////////////////////////////////////////

const PayrollsWrapper = withTabs2((props: Object) => {
  const { activeTab, handleSetActiveTab } = props;

  if (R.equals(activeTab, 0)) {
    return <DriverPayrolls activeTab={activeTab} handleSetActiveTab={handleSetActiveTab} />;
  } else if (R.equals(activeTab, 3)) {
    return <VendorPayrolls activeTab={activeTab} handleSetActiveTab={handleSetActiveTab} />;
  }

  return <PivotPayrolls {...props} />;
});

export default PayrollsWrapper;
