// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

export const getActiveText = (active: boolean) => G.getWindowLocale(...G.ifElse(
  active,
  ['titles:active', 'Active'],
  ['titles:suspended', 'Suspended'],
));

