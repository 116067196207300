import * as R from 'ramda';
import { createLogic } from 'redux-logic';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature new-do
import * as A from './actions';
import {
  getStopTitle,
  isPageCreateDO,
  getDataFromStops,
  getValidationWarnings,
  isStopsQuantityLessOrEqualTwo,
} from './helpers';
import {
  isDropFormValid,
  isPickupFormValid,
  isValidBillToForm,
  isValidReferencesForm,
  isValidCustomerRateForm,
  isValidStopReferencesForm,
} from './validation';
//////////////////////////////////////////////////

const handleGetBranchConfigsSuccess = createLogic({
  type: [
    A.getBranchConfigsSuccess,
    A.getBranchConfigsOnQuoteSuccess,
    A.getBranchConfigsWithTemplateSuccess,
    A.getBranchConfigsOnDuplicateDOSuccess,
  ],
  validate({ getState, action }: Object, allow: void) {
    allow(action);
  },
  process({ getState, action }: Object, dispatch: void, done: void) {
    const { branchConfigs } = action.payload;
    const primaryReferenceSequenceGuid = G.getConfigValueFromStore(
      GC.CLO_PRIMARY_REFERENCE_SEQUENCE, branchConfigs,
    );

    if (G.shouldGetPrimaryRefSequenceFromBranchConfigs(branchConfigs)) {
      dispatch(A.getPrimaryRefSequenceRequest(primaryReferenceSequenceGuid));
    }

    done();
  },
});

const handleRemoveStopFromStore = createLogic({
  type: A.removeStopFromStore,
  validate({ getState, action }: Object, allow: void, reject: void) {
    const order = action.payload;
    const state = getState();
    const store = state.newDO;
    const stops = store.stops;
    const { firstStop } = getDataFromStops(stops);

    if (isStopsQuantityLessOrEqualTwo(stops)) {
      G.showToastrMessageFromLocale('info', 'messages:min-two-stops-to-create-clo');

      return reject();
    }

    if (R.and(R.equals(order, 1), G.isStopPickup(firstStop))) {
      G.showToastrMessageFromLocale('info', 'messages:cant-delete-first-pickup');

      return reject();
    }
    allow(action);
  },
});

const isValidCustomerReference = (store: Object) => {
  const { pageType, branchConfigs, customerReferenceValue } = store;

  if (R.not(isPageCreateDO(pageType))) return true;

  const customerRefTypeGuid = G.getConfigValueFromStore(
    GC.CLO_GENERAL_CUSTOMER_REFERENCE_TYPE,
    branchConfigs,
  );

  if (G.isNotNilAndNotEmpty(customerRefTypeGuid)) {
    return G.isNotNilAndNotEmpty(customerReferenceValue);
  }

  return true;
};

const getInvalidNames = (invalidName: string, isObjectValid: Object, invalidNames: Array) => {
  if (G.isTrue(isObjectValid)) {
    return invalidNames;
  }

  return R.append(invalidName, invalidNames);
};

const validateCustomerRate = (rate: Object) => {
  const isValid = G.getPropFromObject('isValid', rate);

  return R.and(isValidCustomerRateForm(rate), isValid);
};

const getInvalidFormsMsg = (store: Object) => {
  const { stops, billTo, rateBackup, referenceFormData } = store;

  let errorNames = [];
  const { sortedStops, stopReferences } = getDataFromStops(stops);

  const isValidBillTo = isValidBillToForm(billTo);
  const isValidRate = validateCustomerRate(rateBackup);
  const isValidCustomerRef = isValidCustomerReference(store);
  const isValidReferences = isValidReferencesForm(referenceFormData);
  const isValidStopReferences = isValidStopReferencesForm(stopReferences);
  errorNames = getInvalidNames('Bill To', isValidBillTo, errorNames);
  errorNames = getInvalidNames('Rate Fields', isValidRate, errorNames);
  errorNames = getInvalidNames('Additional Info', isValidReferences, errorNames);
  errorNames = getInvalidNames('Stop References', isValidStopReferences, errorNames);
  errorNames = getInvalidNames('Customer Reference', isValidCustomerRef, errorNames);

  R.forEach((stop: Object) => {
    const { formData, eventType } = stop;

    const title = getStopTitle(stop);
    const validationFunc = G.ifElse(
      G.isEventTypePickup(eventType),
      isPickupFormValid,
      isDropFormValid,
    );
    const isValid = validationFunc(formData);
    errorNames = getInvalidNames(title, isValid, errorNames);
  }, sortedStops);

  return errorNames;
};

const handleSendDataToApiRequest = createLogic({
  type: A.sendDataToApiRequest,
  validate({ getState, action }: Object, allow: void, reject: void) {
    const state = getState();
    const store = state.newDO;
    const { stops } = store;
    const errors = getInvalidFormsMsg(store);
    const warnings = getValidationWarnings(store);

    if (R.or(G.isNotNilAndNotEmpty(errors), G.isNotNilAndNotEmpty(warnings))) {
      return reject(A.setValidationErrorsAndWarnings({ errors, warnings }));
    }

    const { lastStop, firstStop } = getDataFromStops(stops);

    if (G.isStopDrop(firstStop)) {
      G.showToastrMessageFromLocale('info', 'messages:cant-delete-first-pickup');

      return reject();
    }

    if (G.isStopPickup(lastStop)) {
      G.showToastrMessageFromLocale('info', 'messages:last-stop-should-be-drop');

      return reject();
    }
    allow(action);
  },
});

const getLoadStopsEditInvalidFormsMsg = (store: Object) => {
  const { stops } = store;
  const { sortedStops } = getDataFromStops(stops);
  let errorNames = [];
  R.forEach((stop: Object) => {
    const { formData, eventType } = stop;
    const title = getStopTitle(stop);
    const validationFunc = G.ifElse(
      G.isEventTypePickup(eventType),
      isPickupFormValid,
      isDropFormValid,
    );
    const isValid = validationFunc(formData);
    errorNames = getInvalidNames(title, isValid, errorNames);
  }, sortedStops);

  return errorNames;
};

const handleSendLoadStopsEditDataToApiRequest = createLogic({
  type: A.sendLoadStopsEditDataToApiRequest,
  validate({ getState, action }: Object, allow: void, reject: void) {
    const state = getState();
    const store = state.newDO;
    const { stops } = store;
    const errors = getLoadStopsEditInvalidFormsMsg(store);

    if (G.isNotNilAndNotEmpty(errors)) {
      return reject(A.setValidationErrors(errors));
    }

    const { lastStop, firstStop } = getDataFromStops(stops);

    if (G.isStopDrop(firstStop)) {
      G.showToastrMessageFromLocale('info', 'messages:cant-delete-first-pickup');

      return reject();
    }

    if (G.isStopPickup(lastStop)) {
      G.showToastrMessageFromLocale('info', 'messages:last-stop-should-be-drop');

      return reject();
    }
    allow(action);
  },
});

export default [
  handleRemoveStopFromStore,
  handleSendDataToApiRequest,
  handleGetBranchConfigsSuccess,
  handleSendLoadStopsEditDataToApiRequest,
];
