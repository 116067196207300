import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { AbsoluteBox } from '../../../ui';
//////////////////////////////////////////////////

const NotAllowedCustomerRate = (props: Object) => {
  const { notAllowedCustomerRate } = props;

  if (G.isTrue(notAllowedCustomerRate)) {
    return (
      <AbsoluteBox
        left={200}
        bottom={15}
        fontSize={13}
        maxWidth={600}
        color={G.getTheme('colors.light.mainRed')}
      >
        {G.getWindowLocale(
          'messages:save-route-before-assign-driver-with-customer-rate',
          'Please, save the route before assigning a driver/carrier with a Customer Rate or Line Haul charge',
        )}
      </AbsoluteBox>
    );
  }

  return null;
};

export {
  NotAllowedCustomerRate,
};
