import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../components/text';
import { FormSectionHeader } from '../../../components/form-section-header';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, IconWrapper } from '../../../ui';
// feature reference
import { enhanceReferences } from '../hocs';
//////////////////////////////////////////////////

const headerStyles = {
  width: 320,
  fontSize: 13,
  p: '10px 20px',
  color: G.getTheme('colors.darkGrey'),
};

const Header = () => (
  <Flex >
    <Box {...headerStyles}>
      {G.getWindowLocale('titles:value', 'Value')}
    </Box>
    <Box {...headerStyles}>
      {G.getWindowLocale('titles:name', 'Name')}
    </Box>
  </Flex>
);

const renderReferences = (props: Array) => {
  const { references, handleRemoveItem, handleOpenReference } = props;

  const darkBlue = G.getTheme('colors.dark.blue');

  return R.values(references).map((item: Object, i: number) => {
    const { guid, name, value } = item;

    return (
      <Flex key={i}>
        <Flex width={320} p='10px 20px'>
          <IconWrapper
            pr={15}
            cursor='pointer'
            onClick={() => handleRemoveItem(guid)}
          >
            {I.trash(darkBlue)}
          </IconWrapper>
          <IconWrapper
            pr={15}
            cursor='pointer'
            onClick={() => handleOpenReference(item)}
          >
            {I.pencil(darkBlue)}
          </IconWrapper>
          <TextComponent
            width={200}
            fontSize={14}
            title={value}
            display='block'
            withEllipsis={true}
          >
            {value}
          </TextComponent>
        </Flex>
        <TextComponent
          pl={20}
          width={360}
          title={name}
          fontSize={14}
          display='block'
          withEllipsis={true}
        >
          {name}
        </TextComponent>
      </Flex>
    );
  });
};

export const ReferenceSection = enhanceReferences((props: Object) => (
  <FormSectionHeader
    expanded={false}
    action={props.handleOpenReference}
    title={G.getWindowLocale('titles:references', 'References')}
  >
    <Box>
      <Header />
      {renderReferences(props)}
    </Box>
  </FormSectionHeader>
));
