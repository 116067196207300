import React from 'react';
import * as R from 'ramda';
import {
  pure,
  compose,
  withState,
  withHandlers,
} from 'react-recompose';
// components
import { Table } from '../../../../components/table';
import { FormFooter } from '../../../../components/form-footer';
import { DateRangeMui } from '../../../../components/date-range';
import { LocalLoader } from '../../../../components/local-loader';
// features
import { columnSettings as tollColumnSettings } from '../../../toll-charges/settings/column-settings';
import { columnSettings as fuelCardsColumnSettings } from '../../../fuel-cards/settings/column-settings';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withAsyncInitialDataOnDidMount } from '../../../../hocs';
// ui
import { Box } from '../../../../ui';
// feature payroll
import {
  tollReport,
  expensesReport,
  fuelCardsReport,
  tollTableSettings,
  addDriverPayrollReport,
  expensesColumnSettings,
  driverPayrollColumnSettings,
} from '../../settings/charges-table-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withAsyncInitialDataOnDidMount,
  withState('selectedList', 'setSelectedList', []),
  withState('filterDates', 'setFilterDates', ({ initialFilterDates }: Object) => initialFilterDates),
  withHandlers({
    handleSelectChargeListItem: (props: Object) => (guid: string, { selected }: Object={}) => {
      const { existedList, selectedList, setSelectedList, asyncInitialData } = props;
      const { data } = asyncInitialData;
      const charges = R.compose(
        R.values,
        R.omit(existedList),
        R.indexBy(R.prop(GC.FIELD_GUID)),
      )(R.or(data, []));
      let newList;
      if (R.equals(guid, 'all')) {
        newList = G.ifElse(
          R.eqProps('length', charges, selectedList),
          [],
          R.map(G.getGuidFromObject, charges),
        );
      } else {
        newList = G.ifElse(
          selected,
          R.without(R.of(Array, guid), selectedList),
          R.append(guid, selectedList),
        );
      }
      setSelectedList(newList);
    },
    handleSetFilterDates: (props: Object) => (dates: Object) => {
      const { branchGuid, vendorGuid, setLoading, setFilterDates, getInitialDataRequest } = props;
      setFilterDates(dates);
      const options = {
        data: {
          ...dates,
          vendorGuid,
          [GC.FIELD_BRANCH_GUID]: branchGuid,
        },
      };
      setLoading();
      getInitialDataRequest(null, options);
    },
    handleSubmitCharges: (props: Object) => () => {
      const { selectedList, addChargesToList, asyncInitialData } = props;
      const { data } = asyncInitialData;
      const charges = R.compose(
        R.values,
        R.pick(selectedList),
        R.indexBy(R.prop(GC.FIELD_GUID)),
      )(data);
      addChargesToList(charges);
    },
  }),
  pure,
);

const List = (props: Object) => {
  const { charges, activeTab, selectedList, handleSelectChargeListItem } = props;

  const listsData = {
    2: { report: tollReport, columnSettings: tollColumnSettings },
    4: { report: expensesReport, columnSettings: expensesColumnSettings },
    1: { report: fuelCardsReport, columnSettings: fuelCardsColumnSettings },
    3: { report: addDriverPayrollReport, columnSettings: driverPayrollColumnSettings },
  };

  const report = {
    fields: R.compose(
      R.tail,
      R.path([activeTab, 'report', 'fields']),
    )(listsData),
  };

  const itemList = R.map(
    (entity: Object) => R.assoc(
      'selected',
      R.includes(G.getGuidFromObject(entity), selectedList),
      entity,
    ),
    charges,
  );

  const allChecked = R.eqProps('length', itemList, selectedList);

  return (
    <Table
      report={report}
      itemList={itemList}
      allChecked={allChecked}
      tableSettings={tollTableSettings}
      onOptionClick={handleSelectChargeListItem}
      columnSettings={R.path([activeTab, 'columnSettings'], listsData)}
    />
  );
};

const AddAdditionalCharges = enhance((props: Object) => {
  const {
    closeModal,
    filterDates,
    existedList,
    selectedList,
    asyncInitialData,
    handleSubmitCharges,
    handleSetFilterDates,
  } = props;

  const { data, loading } = asyncInitialData;

  const charges = R.compose(
    R.values,
    R.omit(existedList),
    R.indexBy(R.prop(GC.FIELD_GUID)),
  )(R.or(data, []));

  return (
    <Box width='100%' zIndex='unset'>
      <DateRangeMui
        {...filterDates}
        width={80}
        flexBoxStyles={{ m: 15 }}
        useNewMuiInputField={true}
        popperPlacement='bottom-end'
        onSelectDateRange={handleSetFilterDates}
        maxDate={G.momentAddYearsFromCurrent(100)}
        labelTo={G.getWindowLocale('titles:to', 'To')}
        minDate={G.momentSubtractYearsFromCurrent(100)}
        labelFrom={G.getWindowLocale('titles:from', 'From')}
        label={G.getWindowLocale('titles:date-range', 'Date Range')}
      />
      <LocalLoader width='100%' localLoaderOpen={loading} />
      {R.not(loading) && <List {...props} charges={charges} />}
      <FormFooter
        closeModal={closeModal}
        boxStyles={{ p: '10px 25px' }}
        submitAction={handleSubmitCharges}
        submitDisabled={R.isEmpty(selectedList)}
      />
    </Box>
  );
});

export default AddAdditionalCharges;
