import * as R from 'ramda';
import React from 'react';
import { pure, compose } from 'react-recompose';
// components
import { Table } from '../../../components/table';
import { LocalLoader } from '../../../components/local-loader';
// helpers/constants
import * as G from '../../../helpers';
// hocs
import { withAsyncInitialDataOnDidMount } from '../../../hocs';
// ui
import { Box, Flex, BoxWithChildDiv } from '../../../ui';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// feature payroll
import AsyncPayrollInvoices from './async-payroll-invoices';
import { report, columnSettings, tableSettingsAsyncPayroll } from '../settings/payroll-list-table-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withAsyncInitialDataOnDidMount,
  pure,
);

export const AsyncPayroll = (props: Object) => {
  const { data, loading } = props.asyncInitialData;
  const { type, payrollGuid } = props;

  const asyncEndpoint = G.ifElse(
    G.isInvoiceTypeFleetVendorInvoice(type),
    endpointsMap.vendorPayrollInvoices,
    endpointsMap.driverPayrollInvoices,
  );
  const asyncInvoicesOptions = {
    params: {
      payrollGuid,
    },
  };

  return (
    <LocalLoader localLoaderOpen={loading}>
      <Flex flexDirection='column' alignItems='flex-start' width='100%'>
        <Box width='100%' overflow='auto'>
          <Table
            report={report}
            itemList={R.of(Array, data)}
            columnSettings={columnSettings}
            tableSettings={tableSettingsAsyncPayroll}
          />
        </Box>
        <BoxWithChildDiv width='100%' childWidth='100%'>
          <AsyncPayrollInvoices
            asyncEndpoint={asyncEndpoint}
            asyncOptions={asyncInvoicesOptions}
          />
        </BoxWithChildDiv>
      </Flex>
    </LocalLoader>
  );
};

export default enhance(AsyncPayroll);

