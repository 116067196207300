import * as R from 'ramda';
import React from 'react';
// components
import { Table } from '../../../components/table';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const report = {
  fields: [
    { sequence: 1, name: GC.FIELD_INTEGRATION_TYPE },
    { sequence: 2, name: GC.FIELD_ERRORS },
  ],
};

const columnSettings = {
  [GC.FIELD_INTEGRATION_TYPE]: {
    width: 200,
    name: 'titles:integration-name',
  },
  [GC.FIELD_ERRORS]: {
    width: 600,
    name: 'titles:error-message',
  },
};

export const tableSettings = {
  tableRowHeight: 30,
  titleRowHeight: 30,
  allowEditBtn: false,
  useMainColors: true,
  allowSelectAll: false,
  checkBoxCellWidth: 27,
  expandableItems: false,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 140px)',
};

const IntegrationErrors = (props: Object) => {
  const errors = R.path(['rateInfo', 'ratingIntegrationErrors'], props);
  const keys = R.keys(errors);
  const itemList = R.map(
    (integrationType: string) => ({
      [GC.FIELD_ERRORS]: R.path([integrationType], errors),
      [GC.FIELD_INTEGRATION_TYPE]: G.getEnumLocale(integrationType),
    }),
    keys,
  );
  const tableData = {
    report,
    itemList,
    tableSettings,
    columnSettings,
  };

  return <Table {...tableData} />;
};

export default IntegrationErrors;
