import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const updateReferenceFail = createAction('updateReferenceFail');
export const addNewReferenceFail = createAction('addNewReferenceFail');
export const updateReferenceRequest = createAction('updateReferenceRequest');
export const updateReferenceSuccess = createAction('updateReferenceSuccess');
export const addNewReferenceRequest = createAction('addNewReferenceRequest');
export const addNewReferenceSuccess = createAction('addNewReferenceSuccess');
export const deleteReferenceRequest = createAction('deleteReferenceRequest');
export const deleteReferenceSuccess = createAction('deleteReferenceSuccess');
export const getReferenceListRequest = createAction('getReferenceListRequest');
export const getReferenceScopesRequest = createAction('getReferenceScopesRequest');
export const getReferenceScopesSuccess = createAction('getReferenceScopesSuccess');
export const deleteReferenceTypeRequest = createAction('deleteReferenceTypeRequest');
export const deleteReferenceTypeSuccess = createAction('deleteReferenceTypeSuccess');
export const updateReferenceTypeRequest = createAction('updateReferenceTypeRequest');
export const updateReferenceTypeSuccess = createAction('updateReferenceTypeSuccess');
export const updateLoadReferencesRequest = createAction('updateLoadReferencesRequest');
export const createNewReferenceTypeRequest = createAction('createNewReferenceTypeRequest');
export const createNewReferenceTypeSuccess = createAction('createNewReferenceTypeSuccess');
export const getCurrentReferenceTypeRequest = createAction('getCurrentReferenceTypeRequest');
export const getCurrentReferenceTypeSuccess = createAction('getCurrentReferenceTypeSuccess');
export const changeReferenceTypeOwnerRequest = createAction('changeReferenceTypeOwnerRequest');
export const changeReferenceTypeOwnerSuccess = createAction('changeReferenceTypeOwnerSuccess');
export const changeReferenceTypeGrantRequest = createAction('changeReferenceTypeGrantRequest');
export const changeReferenceTypeGrantSuccess = createAction('changeReferenceTypeGrantSuccess');
export const getAllAvailableCloRefTypesRequest = createAction('getAllAvailableCloRefTypesRequest');
export const getAllAvailableTelRefTypesRequest = createAction('getAllAvailableTelRefTypesRequest');
export const setAllAvailableRefTypesByScopeName = createAction('setAllAvailableRefTypesByScopeName');
export const getAllAvailableRefTypesByScopeRequest = createAction('getAllAvailableRefTypesByScopeRequest');
export const setReferenceTypeGrantToBranchesRequest = createAction('setReferenceTypeGrantToBranchesRequest');

export const setReferenceTypeGrantToBranchesSuccess =
    createAction('setReferenceTypeGrantToBranchesSuccess');
export const getAllAvailableRefTypesByScopeRequestSuccess =
    createAction('getAllAvailableRefTypesByScopeRequestSuccess');

export const selectItem = createAction('selectItem');
export const setUsedReport = createAction('setUsedReport');
export const setActiveList = createAction('setActiveList');
export const setFilterProps = createAction('setFilterProps');
export const setListLoading = createAction('setListLoading');
export const setInitialState = createAction('setInitialState');
export const cleanQuickFilter = createAction('cleanQuickFilter');
export const changeActiveList = createAction('changeActiveList');
export const getItemListSuccess = createAction('getItemListSuccess');
export const deleteReferenceFail = createAction('deleteReferenceFail');
export const setQuickFilterParams = createAction('setQuickFilterParams');
export const resetListAndPagination = createAction('resetListAndPagination');
export const exportReportDataRequest = createAction('exportReportDataRequest');
export const setReferenceTypesFilterParams = createAction('setReferenceTypesFilterParams');
export const clearReferenceTypesFilterParams = createAction('clearReferenceTypesFilterParams');
