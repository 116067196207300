import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { ReactSelect } from '../../../../ui';
//////////////////////////////////////////////////

class ReactSelectInput extends React.Component {
  componentDidMount = () => {
    const { id, shouldValidate, setFieldTouched } = this.props;
    if (G.isNotNilAndNotEmpty(shouldValidate)) {
      const validate = G.ifElse(shouldValidate, shouldValidate, false);
      setFieldTouched(id, true, validate);
    }
  };

  handleBlur = () => {
    const { id, shouldValidate, setFieldTouched } = this.props;
    const validate = G.ifElse(G.isNotNilAndNotEmpty(shouldValidate), shouldValidate, true);
    setFieldTouched(id, true, validate);
  };

  handleOpen = () => {
    const { id, shoouldScrollIntoView = true } = this.props;
    const el = document.getElementById(id);
    el.scrollIntoView();
  };

  handleChange = (value: any) => {
    const inputValue = G.ifElse(
      R.isNil(value),
      {
        value: null,
        label: null,
      },
      value,
    );

    const { id, isMulti, setFieldValue } = this.props;

    if (G.isTrue(isMulti)) {
      const data = R.map(
        (item: Object) => R.prop('value', item),
        inputValue,
      );
      setFieldValue(id, data);
    } else {
      setFieldValue(id, R.prop('value', inputValue));
    }
  };

  render() {
    return (
      <ReactSelect
        id={this.props.id}
        value={this.props.value}
        onBlur={this.handleBlur}
        onKeyDown={this.onKeyDown}
        onChange={this.handleChange}
        isMulti={this.props.isMulti}
        options={this.props.options}
        hasError={this.props.hasError}
        placeholder={this.props.placeholder}
        useMaxHeight={this.props.useMaxHeight}
        isDisabled={this.props.disabled || false}
        closeMenuOnScroll={this.props.closeMenuOnScroll}
        useMenuPortalTarget={this.props.useMenuPortalTarget}
        closeMenuOnSelect={this.props.closeMenuOnSelect || true}
      />
    );
  }
}

export default ReactSelectInput;
