import React from 'react';
import * as R from 'ramda';
import { Field } from 'formik';
import {
  pure,
  compose,
  lifecycle,
  withState,
  withHandlers,
  withPropsOnChange,
} from 'react-recompose';
// components
import { InfoPair } from '../../../components/info-pair';
// common
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { FormGroup } from '../../../forms';
// forms
import { Label, Error } from '../../../forms/ui';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, Text, Span, ReactSelect } from '../../../ui';
// feature rate
import { FormSection } from '../ui';
import { mapDispatchersToOptions } from '../helpers';
import { loadTerminalOptions, withAsyncCarrierSelect } from '../hocs';
import { getCarrierSectionFields, carrierAssignmentInitFields } from './settings';
//////////////////////////////////////////////////

const getErrorTouched = (values: Object, field: Object = {}) => {
  const { type } = field;

  let fieldToUse = field;

  if (R.equals(type, 'multi')) fieldToUse = R.path(['fields', 0], field);

  const { fieldName, fieldPrefix } = fieldToUse;

  return G.ifElse(
    R.isNil(fieldPrefix),
    R.path(R.split('.', R.or(fieldName, '')), values),
    R.pathOr(null, [fieldPrefix, fieldName], values),
  );
};

const renderError = (errors: Object, touched: Object, item: Object) => {
  const error = getErrorTouched(errors, item);
  const touch = getErrorTouched(touched, item);

  if (R.and(G.isNotNilAndNotEmpty(error), G.isTrue(touch))) {
    const {
      errWidth,
      errorTop,
      errorLeft,
      errorPosition,
    } = item;

    return (
      <Error
        fontSize={11}
        errorTop={errorTop}
        errorWidth={errWidth}
        errorLeft={errorLeft}
        errorPosition={errorPosition}
      >
        {error}
      </Error>
    );
  }

  return null;
};

const renderFieldCondition = (isEditMode: string, fieldName: string) => R.and(
  isEditMode,
  G.notEquals(fieldName, `${GC.FIELD_PREFIX_CARRIER_ASSIGNMENT}.${GC.FIELD_CARRIER_TERMINAL_GUID}`),
);

const getFieldValue = (fieldName: string, fieldValue: string, carrierGuid: string) => G.ifElse(
  R.equals(fieldName, `${GC.FIELD_PREFIX_CARRIER_ASSIGNMENT}.${GC.FIELD_CARRIER_TERMINAL_GUID}`),
  fieldValue,
  carrierGuid,
);

const renderField = ({
  form,
  item,
  field,
  width,
  options,
  disabled,
  isLoading,
  isEditMode,
  handleScroll,
  optionGetter,
  handleSearch,
  handlerSelect,
  selectedEntity,
  ...props
}: Object) => {
  const { name, value } = field;

  if (renderFieldCondition(isEditMode, name)) {
    return <Text pl='5px' width='100%' fontWeight='bold'>{`${R.or(value, '-')}`}</Text>;
  }

  return (
    <Box width='100%'>
      <ReactSelect
        id={name}
        name={name}
        options={options}
        disabled={disabled}
        isLoading={isLoading}
        onChange={handlerSelect}
        onBlurResetsInput={false}
        onCloseResetsInput={false}
        onMenuOpen={props[optionGetter]}
        onMenuScrollToBottom={handleScroll}
        components={{ IndicatorSeparator: () => null }}
        onInputChange={G.setDebounce(handleSearch, 500)}
        isClearable={R.pathOr(false, ['isClearable'], props)}
        value={getFieldValue(name, value, R.prop(GC.FIELD_CARRIER_GUID, selectedEntity), options)}
      />
      {renderError(form.errors, form.touched, item)}
    </Box>
  );
};

const renderAsyncSelect = withAsyncCarrierSelect(({
  label,
  width,
  fieldClass,
  additionClass,
  inputWrapperStyles,
  ...props
}: Object) => (
  <FormGroup
    align='start'
    display='flex'
    direction='column'
    additionClass={additionClass}
    {...G.spreadUiStyles(inputWrapperStyles)}
  >
    <Label
      ml='5px'
      fontSize={11}
      fieldClass={fieldClass}
      color={G.getTheme('colors.darkGrey')}
      className={G.ifElse(G.isString(fieldClass), fieldClass)}
    >
      {label}
    </Label>
    {renderField(props)}
  </FormGroup>
));

const setCarrierOptions = ({ carriers, selectedCarrier }: Object, field: string) => {
  if (G.isNilOrEmpty(carriers)) return [];

  const options = [];

  carriers.forEach((param: Object) => {
    if (G.isNotNilAndNotEmpty(R.prop(field, param))) {
      options.push({
        value: R.prop(GC.FIELD_GUID, param),
        label: R.prop(field, param),
      });
    }
  });

  const notSelectedCarrier = R.not(R.any(R.propEq(R.prop(GC.FIELD_GUID, selectedCarrier), GC.FIELD_GUID), carriers));

  if (R.and(G.isNotEmpty(selectedCarrier), notSelectedCarrier)) {
    return R.concat(options, R.of(Array, {
      label: R.prop(field, selectedCarrier),
      value: R.prop(GC.FIELD_CARRIER_GUID, selectedCarrier),
    }));
  }

  return options;
};

const setDisabled = (selectedCarrier: Object, field: Object) => (
  R.and(
    R.equals(field.fieldName, GC.FIELD_CARRIER_TERMINAL_GUID),
    G.isNilOrEmpty(R.prop(GC.FIELD_CARRIER_GUID, selectedCarrier)),
  )
);

const setFieldName = (field: Object) => (
  G.ifElse(
    R.isNil(field.fieldPrefix),
    field.fieldName,
    `${field.fieldPrefix}.${field.fieldName}`,
  )
);

const enhance = compose(
  withState('carriers', 'setCarriers', []),
  withState('terminals', 'setTerminals', []),
  withState('dispatchers', 'setDispatchers', []),
  withState('totalCount', 'setTotalCount', null),
  withState('dispatcherTotalCount', 'setDispatcherTotalCount', null),
  withState('selectedCarrier', 'setSelectedCarrier', ({ initialValues, isEditMode }: Object) => (
    G.ifElse(
      isEditMode,
      initialValues.carrierAssignment,
      {},
    )
  )),
  withHandlers({
    handleCarrierFilter: (props: Object) => ({ value }: Object) => {
      const {
        values,
        carriers,
        rateInfo,
        setValues,
        setSelectedCarrier,
        sharedAccessorialsApplyTo,
        defaultCarrierAccessorials,
        handleSetRecalculateAllChargesId,
      } = props;

      const carrier = R.find(R.propEq(value, GC.FIELD_GUID), carriers);

      if (R.isNil(carrier)) {
        setSelectedCarrier({});

        setValues({
          ...values,
          [GC.FIELD_CARRIER_RATE_CHARGES]: null,
          [GC.FIELD_PREFIX_CARRIER_ASSIGNMENT]: carrierAssignmentInitFields,
        });

        G.callFunction(handleSetRecalculateAllChargesId);

        return;
      }

      const carrierGuid = G.getGuidFromObject(carrier);
      const cloRateAdjustments = R.path([GC.FIELD_CLO_RATE_ADJUSTMENTS], rateInfo);
      const carrierAssignment = G.getPropFromObject(GC.FIELD_PREFIX_CARRIER_ASSIGNMENT, values);
      const defaultAccessorials = R.concat(R.or(sharedAccessorialsApplyTo, []), R.or(defaultCarrierAccessorials, []));
      const charges = G.addMainDiscountFuelChargeToCharges(defaultAccessorials);

      const newValues = {
        ...values,
        [GC.FIELD_CARRIER_RATE_CHARGES]: charges,
        [GC.FIELD_CLO_RATE_ADJUSTMENTS]: cloRateAdjustments,
        [GC.FIELD_PREFIX_CARRIER_ASSIGNMENT]: {
          ...carrierAssignment,
          carrierGuid,
          [GC.FIELD_CARRIER_TERMINAL_GUID]: null,
          ...R.omit([GC.FIELD_GUID], carrier),
        },
      };

      setSelectedCarrier({ ...carrier, carrierGuid });
      setValues(newValues);

      G.callFunction(handleSetRecalculateAllChargesId);
    },
    handleFieldValue: ({ setFieldValue }: Object) => ({ value }: Object, fieldName: string) => (
      setFieldValue(fieldName, value)
    ),
  }),
  lifecycle({
    componentDidMount() {
      const carrierGuid = R.path(['selectedCarrier', GC.FIELD_CARRIER_GUID], this.props);

      if (G.isNotNilAndNotEmpty(carrierGuid)) {
        loadTerminalOptions(
          this.props.setTerminals,
          carrierGuid,
          () => {},
          () => {},
        );
      }
    },
  }),
  // TODO: refactor with proper solution on initialCarrier
  withPropsOnChange(['initialCarrier'], (props: Object) => {
    const carrierGuid = R.path(['initialCarrier', GC.FIELD_GUID], props);

    if (G.isNotNilAndNotEmpty(carrierGuid)) {
      if (R.not(R.any(R.propEq(carrierGuid, GC.FIELD_GUID), props.carriers))) {
        props.setCarriers(R.append(props.initialCarrier, props.carriers));
        loadTerminalOptions(
          props.setTerminals,
          carrierGuid,
          () => {},
          props.setFieldValue,
        );
      }
      props.setSelectedCarrier({ ...props.initialCarrier, carrierGuid });
    }
  }),
  pure,
);

const setTerminalOptions = ({ terminals }: Object) => (
  R.map(
    ({ guid, name, city, state }: Object) => ({
      value: guid,
      label: `${name} (${city}, ${state})`,
    }),
    terminals,
  )
);

const setOptions = (props: Object, fieldName: string) => {
  if (R.equals(fieldName, GC.FIELD_CARRIER_TERMINAL_GUID)) {
    return setTerminalOptions(props);
  }

  if (R.equals(fieldName, GC.FIELD_DISPATCHED_BY_LOGIN)) {
    return mapDispatchersToOptions(R.prop('dispatchers', props));
  }

  return setCarrierOptions(props, fieldName);
};

const renderTerminalLocation = ({ values, terminals }: Object) => {
  const terminal = R.find(
    R.propEq(R.path([GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT, GC.FIELD_CARRIER_TERMINAL_GUID], values), GC.FIELD_GUID),
    terminals,
  );

  if (G.isNilOrEmpty(terminal)) return null;

  const { city, state, country, address } = terminal;

  return (
    <span>
      {I.onMap(G.getTheme('icons.iconColor'))}
      <Span ml={15}>{`${address}, ${city}, ${state}, ${country}`}</Span>
    </span>
  );
};

const getIntegrationCarrierNameByPropName = (props: Object, propName: string) => R.or(
  R.path(['selectedCarrier', propName], props),
  R.path(['initialValues', GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT, propName], props),
);

const integrationCarrierStyles = {
  py: '0px',
  textColor: G.getTheme('colors.darkGrey'),
  actionTextColor: G.getTheme('colors.black'),
};

const CarrierSection = (props: Object) => {
  const {
    fields,
    carriers,
    totalCount,
    isEditMode,
    handleBlur,
    branchGuid,
    setCarriers,
    dispatchers,
    handleChange,
    setTerminals,
    setTotalCount,
    setFieldValue,
    setDispatchers,
    selectedCarrier,
    dispatcherTotalCount,
    setDispatcherTotalCount,
  } = props;

  return (
    <FormSection p={15}>
      {
        fields.map((field: Object, index: number) => (
          <Field
            key={index}
            item={field}
            entityList={carriers}
            isEditMode={isEditMode}
            totalCount={totalCount}
            handleBlur={handleBlur}
            branchGuid={branchGuid}
            name={setFieldName(field)}
            handleChange={handleChange}
            dispatcherList={dispatchers}
            setTotalCount={setTotalCount}
            setFieldValue={setFieldValue}
            component={renderAsyncSelect}
            handler={props[field.handler]}
            setCarrierOptions={setCarriers}
            selectedEntity={selectedCarrier}
            optionGetter={field.optionGetter}
            setTerminalOptions={setTerminals}
            setDispatcherOptions={setDispatchers}
            label={G.getWindowLocaleArr(field.label)}
            options={setOptions(props, field.fieldName)}
            dispatcherTotalCount={dispatcherTotalCount}
            inputWrapperStyles={field.inputWrapperStyles}
            disabled={setDisabled(selectedCarrier, field)}
            setDispatcherTotalCount={setDispatcherTotalCount}
            isClearable={R.pathOr(false, ['isClearable'], props)}
          />
        ))
      }
      <Box mt={35} width={490}>
        {renderTerminalLocation(props)}
      </Box>
      <Flex mt={15} flexWrap='wrap'>
        <InfoPair
          {...integrationCarrierStyles}
          label={G.getWindowLocale('titles:transporting-carrier', 'Transporting Carrier')}
          text={getIntegrationCarrierNameByPropName(props, GC.FIELD_TRANSPORTING_CARRIER_NAME)}
        />
        <InfoPair
          {...integrationCarrierStyles}
          textMaxWidth='unset'
          label={G.getWindowLocale('titles:origin-carrier-name', 'Origin Carrier Name')}
          text={getIntegrationCarrierNameByPropName(props, GC.FIELD_ORIGIN_CARRIER_NAME)}
        />
        <InfoPair
          {...integrationCarrierStyles}
          textMaxWidth='unset'
          text={getIntegrationCarrierNameByPropName(props, GC.FIELD_DESTINATION_CARRIER_NAME)}
          label={G.getWindowLocale('titles:destination-carrier-name', 'Destination Carrier Name')}
        />
      </Flex>
    </FormSection>
  );
};

const FullCarrierSection = enhance((props: Object) => (
  <CarrierSection {...props} fields={getCarrierSectionFields(props.isEditMode)} />
));

export const CarrierNameSection = enhance((props: Object) => (
  <CarrierSection
    {...props}
    isClearable={true}
    fields={R.of(Array, R.head(getCarrierSectionFields(props.isEditMode)))}
  />
));

export default enhance(FullCarrierSection);
