import * as R from 'ramda';
import React from 'react';
import { compose, withState, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
import { FormGroupTitleComponent } from '../../../components/form-group-title';
// forms
import { FormGroupWrapper } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Flex, IconWrapper } from '../../../ui';
// feature reference
import {
  SectionHeader,
  SectionWrapper,
  SectionLeftItem,
  SectionRightItem } from '../ui';
import { enhanceReferences } from '../hocs';
//////////////////////////////////////////////////

const enhance = compose(
  enhanceReferences,
  withState('isOpened', 'toggleOpened', true),
  withHandlers({
    onToggleSection: (props: Object) => () => {
      props.toggleOpened(R.not(props.isOpened));
    },
  }),
);

const Header = () => (
  <SectionHeader>
    <SectionLeftItem>
      {G.getWindowLocale('titles:value', 'Value')}
    </SectionLeftItem>
    <SectionRightItem>
      {G.getWindowLocale('titles:name', 'Name')}
    </SectionRightItem>
  </SectionHeader>
);

const renderReferences = (props: Array) => {
  const { references, handleRemoveItem, handleOpenReference } = props;

  const darkBlue = G.getTheme('colors.dark.blue');

  return R.values(references).map((item: Object, i: number) => (
    <SectionHeader key={i}>
      <Flex width={320} p='10px 20px'>
        <IconWrapper
          pr={15}
          cursor='pointer'
          onClick={() => handleRemoveItem(item.guid)}
        >
          {I.trash(darkBlue)}
        </IconWrapper>
        <IconWrapper
          pr={15}
          cursor='pointer'
          onClick={() => handleOpenReference(item)}
        >
          {I.pencil(darkBlue)}
        </IconWrapper>
        <TextComponent
          width={200}
          display='block'
          title={item.value}
          withEllipsis={true}
        >
          {item.value}
        </TextComponent>
      </Flex>
      <SectionRightItem>
        <TextComponent
          width={360}
          display='block'
          title={item.value}
          withEllipsis={true}
        >
          {item.name}
        </TextComponent>
      </SectionRightItem>
    </SectionHeader>
  ));
};

export const ReferencesComponent = (props: Object) => (
  <FormGroupWrapper isOpened={props.isOpened}>
    <FormGroupTitleComponent
      withArrowDown={true}
      isOpened={props.isOpened}
      onClickLabel={props.handleOpenReference}
      onToggleFormGroup={props.onToggleSection}
      endIcon={I.plusRound(G.getTheme('icons.iconColorWhite'))}
      text={G.getWindowLocale('titles:references', 'References')}
    />
    <SectionWrapper>
      <Header />
      {renderReferences(props)}
    </SectionWrapper>
  </FormGroupWrapper>
);

export default enhance(ReferencesComponent);
