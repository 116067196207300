import React from 'react';
import * as R from 'ramda';
import titleCase from 'voca/title_case';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Checkbox } from '../../../forms';
// utilities
import routesMap from '../../../utilities/routes';
// ui
import { Box, Flex, Badge } from '../../../ui';
// feature notification
import { HoverWrapper } from './ui';
//////////////////////////////////////////////////

const lightBlack = G.getTheme('colors.light.black');

export const IconStatusComponent = (props: Object) => {
  const { warningType, type } = props;

  const icons = {
    INFO: 'default',
    CRITICAL: 'red',
    WARNING: 'yellow',
  };

  if (R.equals(type, 'DRIVER')) {
    return (
      <Flex
        width={32}
        height={32}
        fontWeight='bold'
        borderRadius='50%'
        border='1px solid'
        color={lightBlack}
        justifyContent='center'
        borderColor={lightBlack}
      >
        {'AK'}
      </Flex>
    );
  }

  return <Badge variant={icons[warningType]}>{titleCase(warningType)}</Badge>;
};

const hidePopup = (action: any, e: Object) => {
  if (G.isFunction(action)) {
    return action(e);
  }
};

const getItemRoute = (type: string, objectGuid: string, typeGroup: Object) => {
  const route = R.path([type, 'route'], typeGroup);

  if (G.isString(route)) return route;

  if (G.isFunction(route)) return route(objectGuid);

  return GC.ROUTE_PATH_HOME;
};

export const GroupComponent = (props: Object) => {
  const { name, type, objectGuid, actionHide, setExternalIdFilter } = props;

  const greenColor = G.getTheme('colors.light.green');
  const blackColor = G.getTheme('colors.light.black');

  const typeGroup = {
    [GC.NOTIFICATION_OBJECT_TYPE_TEL]: {
      locale: ['titles:tel', 'TEL'],
      route: routesMap.dispatchDetailsLoad,
      color: G.getTheme('colors.light.red'),
    },
    [GC.NOTIFICATION_OBJECT_TYPE_CLO]: {
      color: greenColor,
      locale: ['titles:clo', 'CLO'],
      route: routesMap.dispatchDetailsOrder,
    },
    [GC.NOTIFICATION_OBJECT_TYPE_EDI]: {
      color: greenColor,
      route: routesMap.ediList,
      locale: ['titles:edi', 'EDI'],
    },
    [GC.NOTIFICATION_OBJECT_TYPE_DRIVER]: {
      color: blackColor,
      locale: ['titles:driver', 'Driver'],
      route: routesMap[G.getFleetProfileRoutePathNameByFleetType()],
    },
    [GC.NOTIFICATION_OBJECT_TYPE_IFTA]: {
      color: blackColor,
      route: routesMap.iftaReport,
      locale: ['titles:ifta', 'IFTA'],
    },
    [GC.NOTIFICATION_OBJECT_TYPE_TRUCK]: {
      color: blackColor,
      locale: ['titles:truck', 'Truck'],
      route: routesMap[G.getFleetProfileRoutePathNameByFleetType('truck')],
    },
    [GC.NOTIFICATION_OBJECT_TYPE_TRAILER]: {
      color: blackColor,
      locale: ['titles:trailer', 'Trailer'],
      route: routesMap[G.getFleetProfileRoutePathNameByFleetType('trailer')],
    },
    [GC.NOTIFICATION_OBJECT_TYPE_CARRIER]: {
      color: blackColor,
      route: G.getCarrierProfileRoute,
      locale: ['titles:carrier', 'CARRIER'],
    },
    [GC.NOTIFICATION_OBJECT_TYPE_ORDER_QUOTE]: {
      color: blackColor,
      route: routesMap.orderQuotes,
      locale: ['titles:order-quote', 'Order Quote'],
    },
  };

  const groupLocale = R.pathOr([], [type, 'locale'], typeGroup);
  const groupText = G.getWindowLocale(...groupLocale);

  const handleClick = (e: Object) => {
    if (R.equals(type, GC.NOTIFICATION_OBJECT_TYPE_EDI)) setExternalIdFilter(name);

    hidePopup(actionHide, e);
    G.goToRoute(getItemRoute(type, objectGuid, typeGroup));
  };

  return (
    <Flex flexDirection='column'>
      <HoverWrapper bg='#d6d6d6'>
        <TextComponent
          title={name}
          p='4px 10px'
          width='150px'
          display='block'
          fontSize='14px'
          minWidth='150px'
          cursor='pointer'
          textAlign='center'
          borderRadius='30px'
          withEllipsis={true}
          onClick={handleClick}
          color={R.pathOr(greenColor, [type, 'color'], typeGroup)}
        >
          {`${groupText}: ${name}`}
        </TextComponent>
      </HoverWrapper>
    </Flex>
  );
};

export const NotificationItem = (props: Object) => {
  const {
    guid,
    read,
    check,
    message,
    selected,
    displayId,
    actionHide,
    objectGuid,
    readAction,
    objectType,
    createdDate,
    withoutGroup,
    warningLevel,
    withCheckbox,
    setExternalIdFilter,
  } = props;

  return (
    <Flex
      p='18px 15px'
      minHeight={60}
      borderBottom='1px solid'
      width={G.ifElse(G.isTrue(withoutGroup), 540, 690)}
      borderColor={G.getTheme('colors.light.middleGrey')}
      bg={G.getThemeByCond(G.isFalse(read), 'colors.bgLightBlue', 'colors.white')}
    >
      <Flex
        mr={10}
        width={80}
        minWidth={80}
        flexDirection='column'
        alignItems='flex-start'
        color={G.getTheme('colors.light.darkGrey')}
      >
        <IconStatusComponent warningType={warningLevel} type={objectType} />
        <TextComponent fontSize={10} whiteSpace='normal'>
          {createdDate}
        </TextComponent>
      </Flex>
      {
        R.not(withoutGroup) &&
        <GroupComponent
          name={displayId}
          type={objectType}
          driverGuid={false}
          actionHide={actionHide}
          objectGuid={objectGuid}
          setExternalIdFilter={setExternalIdFilter}
        />
      }
      <Box
        width='1px'
        height={40}
        background={G.getTheme('colors.light.middleGrey')}
      />
      <TextComponent
        mx={10}
        maxWidth={385}
        display='block'
        title={message}
        cursor='pointer'
        withEllipsis={true}
        color={G.getTheme('colors.light.black')}
        onClick={() => G.callFunctionWithArgs(readAction, props)}
      >
        {message}
      </TextComponent>
      {
        withCheckbox &&
        <Checkbox
          ml='auto'
          type='checkbox'
          checked={selected}
          onChange={() => check(guid)}
        />
      }
    </Flex>
  );
};

export default NotificationItem;
