import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// features
import { AuthWrapper } from '../../../permission';
import PC from '../../../permission/role-permission';
import { makeSelectBranchesTree } from '../../../branch/selectors';
import { makeSelectAuthorities } from '../../../permission/selectors';
import { makeSelectPinnedBranches, makeSelectPinnedMenuItems } from '../../../profile/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import { getCurrentMenuItem } from '../../../../helpers/menu';
// ui
import { Box } from '../../../../ui';
// feature navbar-menu
import * as H from '../../helpers';
import TopSection from './top-section';
import VersionRelease from './version-release';
import { HrDivider, LgMenuWrapper } from './ui';
import SidebarItemComponent from './sidebar-item';
import BranchesDropdown from './branches-dropdown';
import { toggleSubmenus, addPinnedItemToList } from '../../actions';
import { makeSelectSidebar, makeSelectSubmenusStatus } from '../../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleClickPlusIcon: ({ addPinnedItemToList }: Object) =>
      (item: Object) => addPinnedItemToList(item.key),
  }),
);

const SidebarComponent = ({
  sidebar,
  styling,
  branches,
  iconsColor,
  branchTree,
  authorities,
  isSidebarOpen,
  subMenusStatus,
  pinnedBranches,
  toggleSubmenus,
  pinnedMenuItems,
  handleOpenEntTree,
  handleSwitchBranch,
  handleClickNavItem,
  handleClickPlusIcon,
}: Object) => {
  const sidebarIconColor = R.pathOr(G.getTheme('colors.light.darkRed'), ['textColor'], styling);

  const branchDropdownProps = {
    branches,
    branchTree,
    pinnedBranches,
    handleOpenEntTree,
    handleSwitchBranch,
    iconsColor: sidebarIconColor,
  };

  const subItemsProps = {
    styling,
    authorities,
    subMenusStatus,
    toggleSubmenus,
    pinnedMenuItems,
    handleClickNavItem,
    handleClickPlusIcon,
    iconsColor: sidebarIconColor,
    currentItem: getCurrentMenuItem(),
  };

  const { glassEffect, backgroundColor } = styling;

  return (
    <LgMenuWrapper
      glassEffect={glassEffect}
      isMenuOpen={isSidebarOpen}
      bg={glassEffect ? H.hexToRgbA(backgroundColor, 0.8) : backgroundColor}
    >
      <TopSection />
      <AuthWrapper has={[PC.BRANCH_WRITE]}>
        <BranchesDropdown {...branchDropdownProps} />
      </AuthWrapper>
      <HrDivider m={8} />
      <Box overflowY='auto' height='calc(100vh - 200px)'>
        {
          G.isNotEmpty(sidebar)
          && sidebar.map((item: Object, i: number) => <SidebarItemComponent {...subItemsProps} key={i} item={item} />)
        }
      </Box>
      <VersionRelease iconsColor={iconsColor} />
    </LgMenuWrapper>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  sidebar: makeSelectSidebar(state),
  authorities: makeSelectAuthorities(state),
  branchTree: makeSelectBranchesTree(state),
  subMenusStatus: makeSelectSubmenusStatus(state),
  pinnedBranches: makeSelectPinnedBranches(state),
  pinnedMenuItems: makeSelectPinnedMenuItems(state),
}));

export default connect(mapStateToProps, {
  toggleSubmenus,
  addPinnedItemToList,
})(enhance(SidebarComponent));
