import React from 'react';
import { pure, compose } from 'react-recompose';
// components
import { Tabs2, withTabs2 } from '../../../components/tabs-mui';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
/////////////////////////////////////////////

export const tabsGroupWrapperEnhance = compose(withTabs2, pure);

export const TabsGroupWrapper = (props: Object) => {
  const { tabs, children, activeTab, permissions, handleSetActiveTab } = props;

  return G.getAccessForAmousCurrentUserByPermissions(permissions) ? (
    <Box
      mt={15}
      minWidth={325}
      overflow='hidden'
      border='1px solid #E0E0E0'
      borderRadius='8px 8px 0px 0px'
      bg={G.getTheme('colors.white')}
    >
      <Tabs2
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={handleSetActiveTab}
        tabStyles={GC.COMMON_MUI_TAB_STYLES}
        tabsStyles={GC.COMMON_MUI_TABS_STYLES}
      />
      {children}
    </Box>
  ) : null;
};
