import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setBranchInfo = createAction('setBranchInfo');
export const toggleHotOrder = createAction('toggleHotOrder');
export const setDivisionGuid = createAction('setDivisionGuid');
export const cleanNewDOStore = createAction('cleanNewDOStore');
export const setBranchBillTo = createAction('setBranchBillTo');
export const setBranchShipTo = createAction('setBranchShipTo');
export const setValueToStore = createAction('setValueToStore');
export const setActiveLeftTab = createAction('setActiveLeftTab');
export const setNumberOfLoads = createAction('setNumberOfLoads');
export const setEdiFileToStore = createAction('setEdiFileToStore');
export const setActiveRightTab = createAction('setActiveRightTab');
export const setBranchShipFrom = createAction('setBranchShipFrom');
export const setReorderedStops = createAction('setReorderedStops');
export const setFormDataToStop = createAction('setFormDataToStop');
export const addNewStopToStore = createAction('addNewStopToStore');
export const setIgnoreWarnings = createAction('setIgnoreWarnings');
export const setPageTypeToStore = createAction('setPageTypeToStore');
export const setTelCreationMode = createAction('setTelCreationMode');
export const setFormDataToStore = createAction('setFormDataToStore');
export const setRatePreviewFiles = createAction('setRatePreviewFiles');
export const removeItemFromStore = createAction('removeItemFromStore');
export const setValidationErrors = createAction('setValidationErrors');
export const removeStopFromStore = createAction('removeStopFromStore');
export const setPrimaryReference = createAction('setPrimaryReference');
export const setDriverRateToStore = createAction('setDriverRateToStore');
export const setQuotesDataToStore = createAction('setQuotesDataToStore');
export const setBranchDefaultItem = createAction('setBranchDefaultItem');
export const getBranchDataRequest = createAction('getBranchDataRequest');
export const getBranchListRequest = createAction('getBranchListRequest');
export const getBranchListSuccess = createAction('getBranchListSuccess');
export const sendDataToApiRequest = createAction('sendDataToApiRequest');
export const saveAsTemplateRequest = createAction('saveAsTemplateRequest');
export const setCustomerLoadNumber = createAction('setCustomerLoadNumber');
export const setCarrierRateToStore = createAction('setCarrierRateToStore');
export const setInitialStateToStore = createAction('setInitialStateToStore');
export const removeValidationErrors = createAction('removeValidationErrors');
export const setCustomerRateToStore = createAction('setCustomerRateToStore');
export const saveAndDuplicateSuccess = createAction('saveAndDuplicateSuccess');
export const copyTemplateDataToStore = createAction('copyTemplateDataToStore');
export const getBranchConfigsRequest = createAction('getBranchConfigsRequest');
export const getBranchConfigsSuccess = createAction('getBranchConfigsSuccess');
export const getBranchStylingRequest = createAction('getBranchStylingRequest');
export const getBranchStylingSuccess = createAction('getBranchStylingSuccess');
export const getEdiTransactionRequest = createAction('getEdiTransactionRequest');
export const removeValidationWarnings = createAction('removeValidationWarnings');
export const setCustomerReferenceValue = createAction('setCustomerReferenceValue');
export const getBranchAndEdiDataRequest = createAction('getBranchAndEdiDataRequest');
export const applyOrderRateUpliftRequest = createAction('applyOrderRateUpliftRequest');
export const getBranchDataOnQuoteRequest = createAction('getBranchDataOnQuoteRequest');
export const getPrimaryRefSequenceRequest = createAction('getPrimaryRefSequenceRequest');
export const getPrimaryRefSequenceSuccess = createAction('getPrimaryRefSequenceSuccess');
export const setCustomerRateChargesToStore = createAction('setCustomerRateChargesToStore');
export const getAllAvBranchRefTypesRequest = createAction('getAllAvBranchRefTypesRequest');
export const getAllAvBranchRefTypesSuccess = createAction('getAllAvBranchRefTypesSuccess');
export const getBranchConfigsOnQuoteSuccess = createAction('getBranchConfigsOnQuoteSuccess');
export const getBranchConfigsWithEdiRequest = createAction('getBranchConfigsWithEdiRequest');
export const getBranchConfigsWithEdiSuccess = createAction('getBranchConfigsWithEdiSuccess');
export const setValidationErrorsAndWarnings = createAction('setValidationErrorsAndWarnings');
export const getBranchAndTemplateDataRequest = createAction('getBranchAndTemplateDataRequest');
export const recalculateLoadDistancesRequest = createAction('recalculateLoadDistancesRequest');
export const recalculateLoadDistancesSuccess = createAction('recalculateLoadDistancesSuccess');
export const getBranchConfigsWithQuoteRequest = createAction('getBranchConfigsWithQuoteRequest');
export const getBranchConfigsWithQuoteSuccess = createAction('getBranchConfigsWithQuoteSuccess');
export const sendLoadStopsEditDataToApiRequest = createAction('sendLoadStopsEditDataToApiRequest');
export const getBranchConfigsWithTemplateRequest = createAction('getBranchConfigsWithTemplateRequest');
export const getBranchConfigsWithTemplateSuccess = createAction('getBranchConfigsWithTemplateSuccess');
export const getBranchConfigsWithLoadStopsRequest = createAction('getBranchConfigsWithLoadStopsRequest');
export const getBranchConfigsWithLoadStopsSuccess = createAction('getBranchConfigsWithLoadStopsSuccess');
export const getBranchSharedAccessorialListSuccess = createAction('getBranchSharedAccessorialListSuccess');
// public clo
export const getPublicCloRequest = createAction('getPublicCloRequest');
export const getBranchConfigsWithPublicCloDataSuccess = createAction('getBranchConfigsWithPublicCloDataSuccess');
export const getBranchConfigsWithPublicCloDataRequest = createAction('getBranchConfigsWithPublicCloDataRequest');
// quote
export const getQuoteRequest = createAction('getQuoteRequest');
// duplicate order
export const getOrderRequest = createAction('getOrderRequest');
export const getBranchConfigsOnDuplicateDOSuccess = createAction('getBranchConfigsOnDuplicateDOSuccess');
export const getBranchConfigsWithDuplicateDORequest = createAction('getBranchConfigsWithDuplicateDORequest');
// create from template
export const getTemplateDataRequest = createAction('getTemplateDataRequest');
// order quotes
export const getOrderQuoteDataRequest = createAction('getOrderQuoteDataRequest');
export const getBranchConfigsWithOrderQuoteDataRequest = createAction('getBranchConfigsWithOrderQuoteDataRequest');
export const getBranchConfigsWithOrderQuoteDataSuccess = createAction('getBranchConfigsWithOrderQuoteDataSuccess');
// transportation mode grouping
export const getTransportationModeGroupingListRequest = createAction('getTransportationModeGroupingListRequest');
export const getTransportationModeGroupingListSuccess = createAction('getTransportationModeGroupingListSuccess');
// ai recognize
export const aiRecognizeRequest = createAction('aiRecognizeRequest');
export const aiRecognizeSuccess = createAction('aiRecognizeSuccess');
export const fetchStopLatLonRequest = createAction('fetchStopLatLonRequest');
export const fetchStopLatLonSuccess = createAction('fetchStopLatLonSuccess');
export const fetchStopLatLonFailure = createAction('fetchStopLatLonFailure');
