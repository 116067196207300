import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { OuterClick } from 'react-outer-click';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
// feature new-do
import FormTab from '../forms/form-tab';
import { enhanceQuoteStopForm } from '../hocs';
import ContactsArray from '../forms/contacts-array';
import { Fieldset } from '../forms/formik/fieldset';
import PrevNextAction from '../forms/validate-action';
import { QuoteFormHeader } from '../forms/form-header';
import SectionDivider from '../components/section-divider';
import { FORM_BOX_SHADOW, TAB_NAME_REFERENCE } from '../constants';
import { getValidationSchemaQuoteDropFormLazy } from '../validation';
import { getStopTitle, getStopHeaderActionOptions } from '../helpers';
import {
  mainStopFields,
  dropItemStopFields,
  dropInfoStopFields,
  quoteAddressStopFields,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const QuoteDropForm = (props: Object) => {
  const {
    stop,
    values,
    openModal,
    setValues,
    submitForm,
    closeModal,
    branchGuid,
    setFieldValue,
    handleClickPrev,
    handleOuterClick,
    handleGetLoadOptions,
  } = props;

  const addressFields = G.ifElse(
    G.isTrue(G.getNewDoBranchConfigFromWindow(GC.CLO_QUOTE_HIDE_ADDRESS_FIELDS)),
    R.drop(3, quoteAddressStopFields),
    quoteAddressStopFields,
  );

  return (
    <OuterClick onOuterClick={handleOuterClick}>
      <form>
        <FormTab {...props} />
        <Box boxShadow={FORM_BOX_SHADOW}>
          <QuoteFormHeader
            values={values}
            setValues={setValues}
            openModal={openModal}
            closeModal={closeModal}
            setFieldValue={setFieldValue}
            options={getStopHeaderActionOptions(props)}
            currentBranch={{ [GC.FIELD_GUID]: branchGuid }}
          >
            {getStopTitle(stop, values)}
          </QuoteFormHeader>
          <Box pt={20}>
            <Fieldset
              {...props}
              fields={mainStopFields}
              getLoadOptions={handleGetLoadOptions}
            />
            <SectionDivider
              text={G.getWindowLocale('titles:address', 'Address', { caseAction: 'upperCase' })}
            />
            <Fieldset
              {...props}
              fields={addressFields}
            />
            <SectionDivider
              text={G.getWindowLocale('titles:drop-info', 'Drop Info', { caseAction: 'upperCase' })}
            />
            <Fieldset
              {...props}
              fields={dropInfoStopFields}
            />
            <ContactsArray contacts={R.pathOr([], [GC.FIELD_CONTACTS], values)} />
            <SectionDivider
              text={G.getWindowLocale('titles:drop-items', 'Drop Items', { caseAction: 'upperCase' })}
            />
            <Fieldset
              {...props}
              fields={dropItemStopFields}
            />
            <Box my={20} />
            <PrevNextAction
              showNext={true}
              submitForm={submitForm}
              handleClickPrev={handleClickPrev}
            />
          </Box>
        </Box>
      </form>
    </OuterClick>
  );
};

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: () => getValidationSchemaQuoteDropFormLazy(),
    mapPropsToValues: ({ initialValues }: Object) => G.setEventEarlyLateTimeFromEarlyLateDate(initialValues),
    handleSubmit: (values: Object, { props }: Object) => {
      const { stops, stopOrder, setActiveLeftTab, setFormDataToStop } = props;

      setFormDataToStop({ stopOrder, isValid: true, formData: values });

      if (R.equals(R.length(R.values(stops)), stopOrder)) {
        return setActiveLeftTab(TAB_NAME_REFERENCE);
      }

      setActiveLeftTab(R.inc(stopOrder));
    },
    displayName: 'QuoteDropForm',
  }),
  withHandlers({
    handleOuterClick: (props: Object) => () => {
      const { values, stopOrder, setFormDataToStop } = props;

      setFormDataToStop({ stopOrder, isValid: true, formData: values });
    },
  }),
  enhanceQuoteStopForm,
  pure,
);

export default enhance(QuoteDropForm);
