import React from 'react';
import * as R from 'ramda';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
// components
import { Switcher } from '../../../components/switcher';
import { TextComponent } from '../../../components/text';
import { FormGroupTitleMultiple } from '../../../components/form-group-title-multiple';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import {
  Box,
  Flex,
  StickedBox,
  SimpleWrapper,
} from '../../../ui';
// features rate/driver
import { getTotalDistanceFromValues } from '../helpers';
//////////////////////////////////////////////////

const indexOptionsWithFieldKey = (key: string, obj: Object) => (
  R.compose(
    R.indexBy(R.prop('value')),
    R.pathOr([], [key]),
  )(obj)
);

const getFirstDriverInfoFromOptions = ({ values, fleetOptionsForSelect }: Object) => {
  const indexed = indexOptionsWithFieldKey('primaryDriversOptions', fleetOptionsForSelect);
  const guid = R.pathOr(null, [GC.FIELD_PRIMARY_DRIVER_GUID], values);

  if (R.and(G.isNotNilAndNotEmpty(indexed), G.isNotNilAndNotEmpty(guid))) {
    return R.pathOr('', [guid, 'label'], indexed);
  }

  return '';
};

const getTeamDriverInfoFromOptions = ({ values, fleetOptionsForSelect }: Object) => {
  const indexed = indexOptionsWithFieldKey('secondaryDriversOptions', fleetOptionsForSelect);
  const guid = R.pathOr(null, [GC.FIELD_SECONDARY_DRIVER_GUID], values);

  if (R.and(G.isNotNilAndNotEmpty(indexed), G.isNotNilAndNotEmpty(guid))) {
    return R.pathOr('', [guid, 'label'], indexed);
  }

  return '';
};

const calcRateTotal = (rate: Object, activeDriver: string) => {
  if (G.isNilOrEmpty(rate)) return 0;

  const {
    currency,
    fleetVendorCharges,
    primaryDriverCharges,
    secondaryDriverCharges,
  } = rate;

  const options = { rateCurrency: currency, omitAdvancePayment: true };

  const vendorTotal = G.calcChargesTotal(fleetVendorCharges, options);

  if (R.and(
    G.isNotNilAndNotEmpty(fleetVendorCharges),
    R.equals(activeDriver, 'vendor'),
  )) return vendorTotal;

  const primaryTotal = G.calcChargesTotal(primaryDriverCharges, options);

  if (R.and(
    G.isNotNilAndNotEmpty(primaryDriverCharges),
    R.equals(activeDriver, 'primary'),
  )) return primaryTotal;

  const secondaryTotal = G.calcChargesTotal(secondaryDriverCharges, options);

  if (R.and(
    G.isNotNilAndNotEmpty(secondaryDriverCharges),
    R.equals(activeDriver, 'team'),
  )) return secondaryTotal;

  return 0;
};

const getRateTotalInfo = (props: Object) => {
  const { values, activeDriver } = props;

  const { totalTripDistanceUom } = values;

  const totalDistance = getTotalDistanceFromValues(values);

  const currencySymbol = G.getCurrencySymbolFromRate(values);

  const total = calcRateTotal(values, activeDriver);

  const rpm = G.mathRoundNumber(R.divide(total, totalDistance));

  const rpmText = G.getRpmText(totalTripDistanceUom);

  return `${currencySymbol} ${G.mathRoundNumber(total)} (${rpmText}: ${currencySymbol} ${rpm})`;
};

const getChargesTabTotalInfo = (props: Object, chargesProp: string) => {
  const { values } = props;

  const currency = G.getCurrencyFromObject(values);
  const currencySymbol = G.getCurrencySymbolFromRate(values);

  const charges = R.path([chargesProp], values);
  const total = G.getCalcTelRateChargesTotalPay(charges, currency);

  return `${currencySymbol} ${total}`;
};

const AutoAssign = (props: Object) => {
  const assignAvailable = G.isTrue(R.pathOr(false, ['rateInfo', 'autoAssignAvailable'], props));

  return (
    <Box
      mx={10}
      p='3px 10px'
      fontSize={11}
      cursor='pointer'
      fontWeight='bold'
      borderRadius='2px'
      color={G.getTheme('colors.light.darkRed')}
      opacity={G.ifElse(assignAvailable, 1, 0.5)}
      onClick={props.handleClickTelRateAutoAssign}
      bg={G.getTheme(G.ifElse(assignAvailable, 'colors.light.mainLight', 'colors.light.lightGrey'))}
    >
      {G.getWindowLocale('titles:auto-assign', 'Auto Assign')}
    </Box>
  );
};

const infoCommonProps = {
  mr: 20,
  p: '5px',
  minWidth: 200,
  maxWidth: 250,
  borderRadius: '2px',
  bg: G.getTheme('colors.dark.lightGrey'),
};

const DriverInfo = ({ title, children }: Object) => (
  <Box {...infoCommonProps}>
    <TextComponent
      minWidth='100%'
      maxWidth='100%'
      withEllipsis={true}
      whiteSpace='pre-line'
      wordBreak='break-all'
    >
      <Flex justifyContent='spaceBetween'>
        <Box fontSize='14px' fontWeight='600' textTransform='uppercase'>{`${title}:`}</Box>
      </Flex>
      {children}
    </TextComponent>
  </Box>
);

const DriversTotalRate = ({ totalInfo }: Object) => (
  <Box {...infoCommonProps}>
    <Flex justifyContent='spaceBetween'>
      <Box fontSize={14} fontWeight={600} textTransform='uppercase'>
        {G.getWindowLocale('titles:total-rate', 'Total Rate')}
        {totalInfo}
      </Box>
    </Flex>
  </Box>
);

const SWITCH_RATE_OPTIONS = [
  {
    width: 100,
    value: 'primary',
    name: G.getWindowLocale('titles:primary-driver', 'Primary Driver'),
  },
  {
    width: 100,
    value: 'team',
    name: G.getWindowLocale('titles:team-driver', 'Team Driver'),
  },
  {
    width: 100,
    value: 'vendor',
    name: G.getWindowLocale('titles:vendor', 'Vendor'),
  },
];

const renderDriversSwitch = (props: Object) => {
  if (G.isNilOrEmpty(R.path(['values', GC.FIELD_SECONDARY_DRIVER_GUID], props))) return null;

  return (
    <Box ml='auto'>
      <Switcher
        selectedOptionIndex={0}
        options={SWITCH_RATE_OPTIONS}
        onSwitch={props.handleChangeDriver}
      />
    </Box>
  );
};

const RateHeader = (props: Object) => (
  <StickedBox
    top='0px'
    zIndex={13}
    borderBottom='1px solid'
    borderColor={G.getTheme('colors.black')}
  >
    {
      R.equals(props.mode, 'create') && (
        <FormGroupTitleMultiple
          mb='0'
          jc='space-between'
          showArrowToggle={false}
          title={G.getWindowLocale('titles:add-rate', 'Add Rate')}
        >
          <SimpleWrapper>
            <AutoAssign {...props} />
          </SimpleWrapper>
        </FormGroupTitleMultiple>
      )
    }
    {
      R.equals(props.mode, 'edit') && (
        <FormGroupTitleMultiple
          mb='0'
          showArrowToggle={false}
          title={G.getWindowLocale('titles:edit-rate', 'Edit Rate')}
        />
      )
    }
    <Flex
      p={20}
      alignItems='center'
      justifyContent='center'
      bg={G.getTheme('modal.bgColor')}
    >
      <DriverInfo title={G.getWindowLocale('titles:primary-driver', 'Primary Driver')}>
        {getFirstDriverInfoFromOptions(props)}
      </DriverInfo>
      <DriverInfo title={G.getWindowLocale('titles:team-driver', 'Team Driver')}>
        {getTeamDriverInfoFromOptions(props)}
      </DriverInfo>
      <DriversTotalRate totalInfo={getRateTotalInfo(props)} />
      {renderDriversSwitch(props)}
    </Flex>
  </StickedBox>
);

const hasSecondaryDriver = (props: Object) => G.isNotNilAndNotEmpty(
  R.path(['values', GC.FIELD_SECONDARY_DRIVER_GUID], props),
);

const hasVendor = (props: Object) => R.or(
  G.isNotNilAndNotEmpty(R.path(['values', 'fleetVendorCharges'], props)),
  G.isNotNilAndNotEmpty(props.truckFleetVendor),
);

export const RateHeaderAuto = (props: Object) => {
  const {
    mode,
    activeDriver,
    handleChangeDriver,
  } = props;

  const handleChangeTab = (event: Object, newValue: string) => handleChangeDriver(newValue);

  const title = G.ifElse(
    R.equals(mode, 'create'),
    G.getWindowLocale('titles:add-rate', 'Add Rate'),
    G.getWindowLocale('titles:edit-rate', 'Edit Rate'),
  );

  return (
    <StickedBox top='0px' zIndex={15}>
      <FormGroupTitleMultiple
        mb='0'
        height={35}
        title={title}
        jc='space-between'
        showArrowToggle={false}
      />
      <Flex
        height={45}
        p='7px 15px'
        alignItems='center'
        justifyContent='space-between'
        bg={G.getTheme('modal.bgColor')}
      >
        <Tabs value={activeDriver} onChange={handleChangeTab}>
          <Tab
            value='primary'
            label={`
              ${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}:
              ${getChargesTabTotalInfo(props, GC.FIELD_PRIMARY_DRIVER_CHARGES)}
            `}
          />
          {
            hasSecondaryDriver(props) &&
            <Tab
              value='team'
              label={`
                ${G.getWindowLocale('titles:team-driver', 'Team Driver')}:
                ${getChargesTabTotalInfo(props, GC.FIELD_SECONDARY_DRIVER_CHARGES)}
              `}
            />
          }
          {
            hasVendor(props) &&
            <Tab
              value='vendor'
              label={`
                ${G.getWindowLocale('titles:vendor', 'Vendor')}:
                ${getChargesTabTotalInfo(props, GC.FIELD_FLEET_VENDOR_CHARGES)}
              `}
            />
          }
        </Tabs>
        <Flex>
          <Box
            mr={15}
            p='5px'
            fontSize={12}
            fontWeight={600}
            bg='titleDarkBlue'
            borderRadius='4px'
            color={G.getTheme('colors.white')}
          >
            {/* TODO: with RPM */}
            {`${G.getWindowLocale('titles:total-trip-rate', 'Total Trip Rate')}: ${getRateTotalInfo(props)}`}
          </Box>
          {/* TODO: check if we need it after testing */}
          {/* {
            R.equals(mode, 'create') &&
            <ActionButton
              width={150}
              height={30}
              bgColor='none'
              background='none'
              border='1px solid'
              borderRadius='5px'
              textTransform='uppercase'
              textColor={darkBlueColor}
              borderColor={darkBlueColor}
              onClick={handleClickTelRateAutoAssign}
              cursor={G.ifElse(R.path(['rateInfo', 'autoAssignAvailable'], props), 'pointer', 'not-allowed')}
            >
              {G.getWindowLocale('titles:auto-assign', 'Auto Assign')}
            </ActionButton>
          } */}
        </Flex>
      </Flex>
    </StickedBox>
  );
};

export default RateHeader;
