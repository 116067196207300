import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// features
import { AuthWrapper } from '../../permission';
// components
import { Table } from '../../../components/table';
import { PageTitle } from '../../../components/page-title';
import { HoveringTitle } from '../../../components/hovering-title';
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withFixedPopover } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, ActionButton, RelativeBox, ZOrderWrapper } from '../../../ui';
// feature rate-shop
import { makeSelectUsedReport } from '../selectors';
import { ExcelResultRowActions } from './excel-results-row-actions';
import ExportExcelResultsReportForm from './export-excel-results-report';
import { excelResultSettings, EXCEL_RESULTS_FILTER_PARAMS } from '../settings';
import {
  exportExcelResultReportRequest,
  getExcelResultsByRowGuidRequest,
  updateAllPricesByRowGuidRequest,
  resetExcelResultsListAndPaginationByRowGuid,
} from '../actions';
//////////////////////////////////////////////////

export const tableSettings = {
  minHeight: 320,
  cellFontSize: 12,
  titleFontSize: 12,
  titleRowHeight: 45,
  allowEditBtn: true,
  useMainColors: true,
  allowSelectAll: true,
  checkBoxCellWidth: 70,
  expandableItems: true,
  searchableTitles: true,
  tableRowHeight: 'max-content',
  expandedDetailsComponent: ({ parentProps }: Object = {}) => (
    <ExcelResultsReport
      {...R.pathOr({}, ['excelResults'], parentProps)}
      status={G.getPropFromObject(GC.FIELD_STATUS, parentProps)}
      excelMassRatingSummaryGuid={G.getGuidFromObject(parentProps)}
    />
  ),
};

export const excelResultsTableSettings = R.mergeRight(
  R.dissoc('expandedDetailsComponent', tableSettings),
  {
    maxHeight: '400px',
    allowSelectAll: false,
    expandableItems: false,
    checkBoxCellWidth: '0',
    allowSelectItems: false,
  },
);

const enhance = compose(
  withFixedPopover,
  withState('titleSortValues', 'setTitleSortValues', {}),
  withState('tableTitleFilters', 'setTableTitleFilters', {}),
  withHandlers({
    setTableTitleSort: ({ titleSortValues, setTitleSortValues }: Object) => (data: Object) =>
      setTitleSortValues(G.getTableTitleSortValues(titleSortValues, data)),
    setTableTitleFilter: ({ tableTitleFilters, setTableTitleFilters }: Object) => (data: Object) =>
      setTableTitleFilters(G.getTableTitleFilterValues(tableTitleFilters, data)),
    resetListAndPagination: (props: Object) => () => {
      const { excelMassRatingSummaryGuid, resetExcelResultsListAndPaginationByRowGuid } = props;

      resetExcelResultsListAndPaginationByRowGuid(excelMassRatingSummaryGuid);
    },
    handleUpdateAllPricesByRowGuidRequest: ({ excelMassRatingSummaryGuid, updateAllPricesByRowGuidRequest }: Object) => () =>
      updateAllPricesByRowGuidRequest(excelMassRatingSummaryGuid),
    handleExportReportDataRequest: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        selectedReport,
        excelMassRatingSummaryGuid,
        exportExcelResultReportRequest,
      } = props;

      const filterProps = R.indexBy(R.prop(GC.FIELD_VALUE), EXCEL_RESULTS_FILTER_PARAMS);

      const submitAction = (showAllPrices: boolean) => exportExcelResultReportRequest({
        ...selectedReport,
        showAllPrices,
        excelMassRatingSummaryGuid,
        fields: R.map((item: string) => {
          const { name } = item;

          const displayName = R.path([name, GC.FIELD_NAME], filterProps);

          return R.assoc('displayName', displayName, item);
        }, R.pathOr([], ['fields'], selectedReport)),
      });

      const component = (
        <ExportExcelResultsReportForm closeModal={closeModal} submitAction={submitAction} />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title: G.getWindowLocale('actions:export-exl', 'Generate Excel'),
        },
      };

      openModal(modal);
    },
    getItemListRequest: (props: Object) => () => {
      const {
        titleSortValues,
        tableTitleFilters,
        excelMassRatingSummaryGuid,
        getExcelResultsByRowGuidRequest,
      } = props;

      getExcelResultsByRowGuidRequest({
        excelMassRatingSummaryGuid,
        titleOrderFields: titleSortValues,
        titleFilterParams: tableTitleFilters,
        pagination: R.pathOr({ offset: 0, limit: 20 }, ['pagination'], props),
      });
    },
    handleClickEditIcon: (props: Object) => (e: Object, data: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: (
          <ExcelResultRowActions
            openModal={props.openModal}
            closeModal={props.closeModal}
            openLoader={props.openLoader}
            closeLoader={props.closeLoader}
            closeFixedPopup={props.closeFixedPopup}
            excelRatingResultGuid={G.getGuidFromObject(data)}
            excelMassRatingSummaryGuid={G.getPropFromObject(GC.FIELD_EXCEL_MASS_RATING_SUMMARY_GUID, props)}
          />
        ),
      })
    ),
  }),
  withHandlers({
    handleTableTitleFilter: G.handleTableTitleFilter,
    reloadAction: ({ getItemListRequest, resetListAndPagination }: Object) => () => {
      resetListAndPagination();
      setTimeout(() => getItemListRequest(), 100);
    },
  }),
  pure,
);

// TODO: check permissions
const renderRowActions = (data: Object, handleClickEditIcon: Function) => (
  <AuthWrapper has={null}>
    <Box px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, data)}>
      {I.threeDots()}
    </Box>
  </AuthWrapper>
);

export const renderTable = (props: Object) => {
  const {
    itemList,
    totalCount,
    pagination,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleClickEditIcon,
    handleTableTitleFilter,
  } = props;

  if (R.not(selectedReport)) return null;

  const data = {
    totalCount,
    pagination,
    loading: false,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: false,
    report: selectedReport,
    useSearchableColumns: true,
    withResizableColumns: true,
    itemList: R.values(itemList),
    useNewTitleFilterInputs: true,
    columnSettings: excelResultSettings,
    tableSettings: excelResultsTableSettings,
    handleLoadMoreEntities: getItemListRequest,
    handleTableTitleFilter: G.setDebounce(handleTableTitleFilter, 0),
    renderRightStickedComponent: (data: Object) => renderRowActions(data, handleClickEditIcon),
    filterProps: R.indexBy(
      R.prop(GC.FIELD_VALUE),
      transformPropDataFromSelectToString(EXCEL_RESULTS_FILTER_PARAMS),
    ),
  };

  return <Table {...data} />;
};

const ExcelResultsReport = (props: Object) => (
  <RelativeBox p={20} zIndex='0' maxWidth='calc(100vw - 110px)'>
    <ZOrderWrapper zIndex={2}>
      <Flex alignItems='flex-end' justifyContent='space-between'>
        <Flex>
          <PageTitle
            display='flex'
            withCount={true}
            count={props.totalCount}
            title={G.getWindowLocale('titles:rate-results', 'Rate Results')}
            label={<Box ml='6px' cursor='pointer' onClick={props.reloadAction}>{I.reloadCircle()}</Box>}
          />
          {
            G.notContain(R.prop(GC.FIELD_STATUS, props), [GC.STATUS_PENDING, GC.STATUS_IN_PROGRESS]) &&
            <ActionButton
              ml={20}
              height={30}
              width={150}
              borderRadius='3px'
              onClick={props.handleUpdateAllPricesByRowGuidRequest}
            >
              {G.getWindowLocale('actions:update-all-prices', 'Update All Prices')}
            </ActionButton>
          }
        </Flex>
        <HoveringTitle
          title={G.getWindowLocale('titles:download-file', 'Download File')}
          positionConfig={{
            zIndex: 14,
            right: '70%',
            bottom: '110%',
            width: 'max-content',
          }}
        >
          <Box pb='5px' cursor='pointer' onClick={props.handleExportReportDataRequest}>
            {I.downloadDocument(G.getTheme('colors.light.black'), 21, 24)}
          </Box>
        </HoveringTitle>
      </Flex>
    </ZOrderWrapper>
    <ZOrderWrapper zIndex='1'>
      {renderTable(props)}
    </ZOrderWrapper>
  </RelativeBox>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  selectedReport: makeSelectUsedReport(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  openLoader,
  closeLoader,
  exportExcelResultReportRequest,
  updateAllPricesByRowGuidRequest,
  getExcelResultsByRowGuidRequest,
  resetExcelResultsListAndPaginationByRowGuid,
})(enhance(ExcelResultsReport));
