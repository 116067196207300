import * as R from 'ramda';
import React, { Fragment, useState, useCallback } from 'react';
// components
import ToggleBtn from '../../../components/toggle-btn';
import { Table, TextComponent, InputWithHandlers } from '../../../components';
// helpers/constants
import * as G from '../../../helpers';
// hocs
import { withFilterTableList } from '../../../hocs';
// ui
import { Box, Flex } from '../../../ui';
// feature payroll
import { driversListReport, tableSettings, columnSettings } from '../settings/drivers-report-settings';
//////////////////////////////////////////////////

const renderTable = (props: Object) => {
  const {
    loading,
    totalCount,
    selectDriverItem,
    filteredTableList,
    getItemListRequest,
  } = props;

  const allChecked = G.isAllChecked(filteredTableList);

  const pagination = {
    limit: 10,
    offset: 0,
  };

  const data = {
    allChecked,
    totalCount,
    pagination,
    tableSettings,
    columnSettings,
    hasSelectable: true,
    report: driversListReport,
    itemList: filteredTableList,
    onOptionClick: selectDriverItem,
    handleLoadMoreEntities: getItemListRequest,
    loading: R.and(loading, G.isNilOrEmpty(filteredTableList)),
  };

  return <Table {...data} />;
};

const ReportDriversList = withFilterTableList(
  'searchString',
  'asyncReportListState',
  ['asyncReportListState', 'data'],
  {
    joiner: '',
    omitFields: ['guid', 'selected'],
    withoutValues: [null, undefined, ''],
  },
)((props: Object) => {
  const {
    filterString,
    asyncReportOptions,
    getReportListRequest,
    handleChangeFilterInput,
  } = props;

  const [showInactive, setShowInactive] = useState(false);

  const handleToggleShowInactive = useCallback((value: boolean) => {
    setShowInactive(value);

    if (G.isTrue(value)) return getReportListRequest(R.assocPath(['data', 'searchCriteria'], [], asyncReportOptions));

    getReportListRequest(asyncReportOptions);
  });

  return (
    <Fragment>
      <Flex px={15} alignItems='center' justifyContent='space-between'>
        <TextComponent p={15} display='block'>
          {G.getWindowLocale('titles:select-drivers-for-payroll', 'Please, select drivers for payroll')}
        </TextComponent>
        <Box>
          <ToggleBtn
            mr={15}
            checked={showInactive}
            onToggleHandler={handleToggleShowInactive}
            title={G.getWindowLocale('titles:show-inactive', 'Show Inactive')}
          />
          <TextComponent mr={15} display='inline'>
            {`${G.getWindowLocale('titles:filter', 'Filter')}:`}
          </TextComponent>
          <InputWithHandlers
            styles={{
              width: 120,
              p: '0 8px',
              border: '1px solid',
              borderRadius: '4px',
            }}
            value={filterString}
            inputBorderColor={G.getTheme('colors.dark.grey')}
            onChange={(value: string) => handleChangeFilterInput(value)}
          />
        </Box>
      </Flex>
      {renderTable(props)}
    </Fragment>
  );
});

export {
  renderTable,
  ReportDriversList,
};
