import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  lifecycle,
  withState,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// components
import { Table } from '../../components/table';
import { TitlePanel } from '../../components/title-panel';
import { EditReport } from '../../components/edit-report';
import { getConfirmModal } from '../../components/confirm';
import { PageActions } from '../../components/page-actions';
import { withPromptModal } from '../../components/edit-report/hocs';
import { openModal, closeModal } from '../../components/modal/actions';
// features
import { AuthWrapper } from '../permission';
import PC from '../permission/role-permission';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
import DispatchBoardTileTabs from '../dispatch-board-new/components/title-tabs';
import { makeSelectAvailableReferenceTypesByScope } from '../reference/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withFixedPopover } from '../../hocs';
// icons
import * as I from '../../svgs';
// ui
import { ListWrapper, Box, ZOrderWrapper } from '../../ui';
// utilities
import routesMap from '../../utilities/routes';
// feature EDI Integration
import TitleTabs from './components/title-tabs';
import { RowActions } from './components/row-actions';
import { FILTER_PARAMS } from './settings/filter-params';
import { tableSettings } from './settings/table-settings';
import { columnSettings } from './settings/column-settings';
import {
  selectItem,
  setReports,
  setUsedReport,
  cleanQuickFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  getReportListRequest,
  deleteListItemRequest,
  resetListAndPagination,
  changeDefaultReportRequest,
} from './actions';
import {
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectFilterProps,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectAvailableReports,
} from './selectors';
//////////////////////////////////////////////////

const deleteConfirmationLocaleTxt = G.getWindowLocale(
  'messages:delete-confirmation-text-double',
  'Are you sure you want to delete',
);

const getListActions = (props: Object) => {
  const { itemList, deleteListItemRequest } = props;

  const selectedList = R.compose(
    R.filter(R.prop('selected')),
    R.values,
  )(itemList);

  return [
    {
      type: 'massAction',
      permissions: [PC.PUBLIC_API_ORDER_WRITE],
      text: G.getWindowLocale('actions:delete', 'Delete'),
      icon: I.trash(G.getTheme('colors.light.mainLight'), 22, 22),
      action: () => {
        const guids = R.map(G.getGuidFromObject, selectedList);
        if (R.isEmpty(guids)) return;
        deleteListItemRequest(guids);
      },
    },
  ];
};

const renderElementActions = (data: Object, handleClickEditIcon: Function) => (
  <AuthWrapper has={[PC.PUBLIC_API_ORDER_WRITE]}>
    <Box px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, data)}>
      {I.threeDots()}
    </Box>
  </AuthWrapper>
);

const withActions = compose(
  withHandlers({
    handleDeleteListItem: (props: Object) => (template: Object) => {
      const { openModal, closeModal, deleteListItemRequest } = props;

      const guid = R.prop(GC.FIELD_GUID, template);
      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        text: deleteConfirmationLocaleTxt,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        name: G.getWindowLocale('titles:containers', 'Container(s)'),
        submitAction: () => {
          deleteListItemRequest(R.of(Array, guid));
          closeModal();
        },
      });

      return openModal(modalContent);
    },
  }),
  withHandlers({
    handleClickEditIcon: (props: Object) => (e: Object, data: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: <RowActions entity={data} closeFixedPopup={props.closeFixedPopup} />,
      })
    ),
  }),
  pure,
);

const enhance = compose(
  withFixedPopover,
  withState('originalReport', 'setOriginalReport', null),
  withState('reportFromPrompt', 'setReportFromPrompt', null),
  withActions,
  withHandlers({
    handleListRequest: ({ getReportListRequest }: Object) => (isInitial: boolean = false) =>
      getReportListRequest(isInitial),
    getQuickFilteredListRequest: (props: Object) => () => {
      props.resetListAndPagination();
      props.getReportListRequest(true);
    },
    handleSetActiveList: ({ changeActiveList }: Object) => (type: string) => changeActiveList(type),
    handleSelectReport: (props: Object) => (reportGuid: string) => {
      const { reportList, setUsedReport, getReportListRequest } = props;

      const selectedReport = R.find(R.propEq(reportGuid, GC.FIELD_GUID), reportList);
      setUsedReport(selectedReport);
      getReportListRequest(true);
    },
    handleChangeReportParams: ({ setReportFromPrompt }: Object) => (data: Object) => setReportFromPrompt(data),
    handleSetUsedReport: (props: Object) => () => {
      const {
        setUsedReport,
        selectedReport,
        setPromptStatus,
        reportFromPrompt,
        setOriginalReport,
        getReportListRequest,
      } = props;

      if (R.and(
        G.isNotNilAndNotEmpty(reportFromPrompt),
        G.notEquals(selectedReport, reportFromPrompt),
      )) {
        setOriginalReport(selectedReport);
        setUsedReport(reportFromPrompt);
        getReportListRequest(true);
      }

      setPromptStatus(false);
    },
    handleCleanFilter: (props: Object) => () => {
      const { cleanQuickFilter, getReportListRequest, resetListAndPagination } = props;

      cleanQuickFilter();
      resetListAndPagination();
      getReportListRequest(true);
    },
  }),
  withPromptModal(FILTER_PARAMS),
  withHandlers({
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        createReportRequest,
        updateReportRequest,
        getReportListRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          disableSortByFields={true}
          usedReport={selectedReport}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getReportListRequest(true)}
        />
      );

      const modal = G.getDefaultReportModal(component, 1150);

      openModal(modal);
    },
  }),
  branch(
    (props: Object) => R.or(
      R.not(props.initialDataLoaded),
      G.isNilOrEmpty(props.selectedReport),
    ),
    renderNothing,
  ),
  lifecycle({
    componentWillUnmount() {
      const { originalReport, setUsedReport, selectedReport } = this.props;

      if (R.equals(R.path([GC.FIELD_GUID], originalReport), R.path([GC.FIELD_GUID], selectedReport))) {
        setUsedReport(originalReport);
      }
    },
  }),
  pure,
);


export const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    pagination,
    selectItem,
    selectedReport,
    handleClickEditIcon,
    getReportListRequest,
  } = props;

  if (R.not(selectedReport)) return null;

  const freezedField = {
    sequence: 1,
    freezed: true,
    reference: false,
    name: 'requestErrors',
  };
  const reportToUse = G.prependFieldToTableReportFields(selectedReport, freezedField);
  const allChecked = R.all(
    (item: Object) => item.selected,
    itemList,
  );
  const enhancedTableSettings = G.ifElse(
    R.gt(R.length(itemList), 0),
    tableSettings,
    R.mergeRight(tableSettings, { allowSelectAll: false }),
  );
  const omitRenderFields = [
    GC.FIELD_EDI_FILE_URI,
  ];
  const data = {
    loading,
    allChecked,
    totalCount,
    pagination,
    columnSettings,
    omitRenderFields,
    hasSelectable: true,
    report: reportToUse,
    onOptionClick: selectItem,
    itemList: R.values(itemList),
    tableSettings: enhancedTableSettings,
    handleLoadMoreEntities: G.ifElse(loading, () => {}, getReportListRequest),
    renderRightStickedComponent: (data: Object) => renderElementActions(data, handleClickEditIcon),
  };

  return <Table {...data} />;
};

const PublicCLOList = (props: Object) => {
  const {
    itemList,
    totalCount,
    reportList,
    filterParams,
    selectedReport,
  } = props;

  const hasPinned = G.hasPinnedReports(reportList);
  const hasFilters = G.hasSearchCriteria(selectedReport, filterParams);
  const hasBorderBottom = R.or(hasPinned, hasFilters);

  const customTitleComponent = (
    // <TitleTabs totalCount={totalCount} />
    <DispatchBoardTileTabs
      totalCount={totalCount}
      hasBorderBottom={hasBorderBottom}
      marginLeft={G.ifElse(hasBorderBottom, '0px', '1px')}
    />
  );

  return (
    <ListWrapper p={15}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          version={2}
          noExportable={true}
          popperWithCount={true}
          hiddenRightFilterInfo={false}
          type={GC.ORDER_REQUEST_REPORT}
          customTitleComponent={customTitleComponent}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex='1'>
        {renderTable(props)}
      </ZOrderWrapper>
      <AuthWrapper has={[PC.CLO_WRITE]}>
        <PageActions
          listActions={getListActions(props)}
          shadowColor={G.getTheme('createButton.shadowColor')}
          count={R.length(R.filter((item: Object) => item.selected, R.or(itemList, [])))}
          mainAction={{
            permissions: [PC.CLO_WRITE],
            action: () => G.goToRoute(routesMap.newDO),
            text: G.getWindowLocale('actions:create-clo', 'Create CLO'),
          }}
        />
      </AuthWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  filterProps: makeSelectFilterProps(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  refList: makeSelectAvailableReferenceTypesByScope(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  setReports,
  selectItem,
  setUsedReport,
  cleanQuickFilter,
  createReportRequest,
  updateReportRequest,
  getReportListRequest,
  setQuickFilterParams,
  deleteListItemRequest,
  resetListAndPagination,
  changeDefaultReportRequest,
})(enhance(PublicCLOList));
