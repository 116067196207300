import * as R from 'ramda';
import React from 'react';
import { compose, lifecycle } from 'react-recompose';
// components
import { Table } from '../../../components/table';
import { FormGroupTitleMultiple } from '../../../components/form-group-title-multiple';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// feature rate
import { PulseButton } from '../ui';
import { availableRatesAndCarriersTableSettings } from '../settings';
import { carrierRateReport, carrierRateSettings } from '../carrier/settings';
import { withAsyncCarrierRates, withShowIntegrationErrorsOnAvailableRates } from '../hocs';
//////////////////////////////////////////////////

export const renderTable = (props: Object) => {
  const { loading, onSelectRate, carrierRates } = props;

  const fields = R.reject(({ name }: Object) => {
    if (R.equals(name, GC.FIELD_TRANSPORTING_CARRIER)) return R.not(G.showTransportingCarrier());

    if (R.equals(name, 'configAccountNumber')) {
      return G.hasNotAmousCurrentUserPermissions(PC.CARRIER_INTEGRATION_ACCOUNT_NUMBER_READ);
    }

    return false;
  }, carrierRateReport.fields);

  const data = {
    loading,
    allChecked: false,
    report: { fields },
    hasSelectable: true,
    totalCount: carrierRates.length,
    columnSettings: carrierRateSettings,
    pagination: { limit: 10, offset: 0 },
    tableSettings: availableRatesAndCarriersTableSettings,
    itemList: R.map(R.assoc('selected', false), carrierRates),
    onOptionClick: (guid: string, rate: Object) => onSelectRate(rate),
  };

  return <Table {...data} />;
};

const AvailableRates = (props: Object) => (
  <Box>
    <FormGroupTitleMultiple
      mb='0'
      jc='space-between'
      showArrowToggle={false}
      title={G.getWindowLocale('titles:carrier-rate', 'Carrier Rate')}
    >
      <Flex>
        {
          G.isNotNilAndNotEmpty(R.path(['rateInfo', 'ratingIntegrationErrors'], props)) &&
          <Box
            mx='10px'
            p='3px 15px'
            fontSize='11px'
            cursor='pointer'
            fontWeight='bold'
            borderRadius='2px'
            bg={G.getTheme('colors.white')}
            color={G.getTheme('colors.light.darkRed')}
            onClick={props.handleShowIntegrationErrors}
          >
            <PulseButton animationDuration={props.animationDuration}>
              {G.getWindowLocale('actions:show-integration-errors', 'Show Integration Errors')}
            </PulseButton>
          </Box>
        }
        <Box
          mx='10px'
          p='3px 10px'
          fontSize='11px'
          cursor='pointer'
          fontWeight='bold'
          borderRadius='2px'
          onClick={props.closeModal}
          bg={G.getTheme('colors.white')}
          color={G.getTheme('colors.light.darkRed')}
        >
          {G.getWindowLocale('actions:close', 'Close')}
        </Box>
      </Flex>
    </FormGroupTitleMultiple>
    {renderTable(props)}
  </Box>
);

const enhance = compose(
  withAsyncCarrierRates,
  withShowIntegrationErrorsOnAvailableRates,
  lifecycle({
    componentDidMount() {
      const { getAutoRatesRequest } = this.props;
      getAutoRatesRequest(this.props);
    },
  }),
);

export default enhance(AvailableRates);
