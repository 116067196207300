import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// features
import DriverRateForm, { EditDriverRateForm } from '../../rate/driver';
import CarrierRateForm, { EditCarrierRateForm } from '../../rate/carrier';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withConnectModalAndLoaderActions } from '../../../hocs';
//////////////////////////////////////////////////

export const getOrderRateInfo = (rate: Object) => {
  const rateCurrency = G.getCurrencyFromObject(rate);
  const charges = G.getChargesFromObject(rate);

  return {
    currency: rateCurrency,
    totalCustomerRate: G.getCalcRateChargesTotal(charges, rateCurrency),
    customerLineHaul: G.calcChargesTotalByType(charges, GC.CHARGE_TYPE_MAIN, { rateCurrency }),
  };
};

const withTripRates = compose(
  withConnectModalAndLoaderActions,
  withHandlers({
    handleSaveCarrierRate: (props: Object) => (values: Object) => {
      const { closeModal, setCarrierRateToStore } = props;

      setCarrierRateToStore(values);
      closeModal();
    },
    handleSaveDriverRate: (props: Object) => (values: Object) => {
      const { closeModal, setDriverRateToStore } = props;

      setDriverRateToStore(values);
      closeModal();
    },
  }),
  withHandlers({
    handleAddCarrierRate: (props: Object) => () => {
      const {
        rate,
        loadData,
        openModal,
        loadTotals,
        branchGuid,
        divisionGuid,
        totalPickupQuantity,
        handleSaveCarrierRate,
      } = props;

      const stopsForLaneHistory = R.compose(
        R.values,
        R.map(({ formData }: Object) => ({
          [GC.SYSTEM_OBJECT_LOCATION]: R.pick(GC.GROUPED_FIELDS.EVENT_LOCATION_ARR, formData),
        })),
        R.propOr({}, GC.FIELD_STOPS),
      )(props);

      const modalContent = (
        <CarrierRateForm
          telGuid={null}
          loadData={loadData}
          branchGuid={divisionGuid}
          stops={stopsForLaneHistory}
          customerEnterpriseGuid={branchGuid}
          orderRateInfo={getOrderRateInfo(rate)}
          stopCount={R.length(stopsForLaneHistory)}
          handleSendTelRate={handleSaveCarrierRate}
          mockRateInfo={{ ...loadTotals, totalPickupQuantity }}
          configsNamesArray={GC.ADD_CARRIER_RATE_CONFIGS_ARRAY}
          equipmentGuid={R.prop(GC.FIELD_LOAD_EQUIPMENT, rate)}
        />
      );

      const modal = G.getRateModalWithContent(modalContent);

      openModal(modal);
    },
    handleEditCarrierRate: (props: Object) => () => {
      const {
        rate,
        loadData,
        openModal,
        branchGuid,
        carrierRate,
        divisionGuid,
        handleSaveCarrierRate,
      } = props;

      const modalContent = (
        <EditCarrierRateForm
          telGuid={null}
          loadData={loadData}
          branchGuid={divisionGuid}
          initialValues={carrierRate}
          customerEnterpriseGuid={branchGuid}
          orderRateInfo={getOrderRateInfo(rate)}
          handleSendTelRate={handleSaveCarrierRate}
          configsNamesArray={GC.EDIT_RATE_CONFIGS_ARRAY}
        />
      );

      const modal = G.getRateModalWithContent(modalContent);

      openModal(modal);
    },
    handleAddDriverRate: (props: Object) => () => {
      const {
        rate,
        loadData,
        openModal,
        branchGuid,
        divisionGuid,
        handleSaveDriverRate,
      } = props;

      const modalContent = (
        <DriverRateForm
          telGuid={null}
          isFromOrder={true}
          loadData={loadData}
          branchGuid={divisionGuid}
          customerEnterpriseGuid={branchGuid}
          orderRateInfo={getOrderRateInfo(rate)}
          handleSendTelRate={handleSaveDriverRate}
          configsNamesArray={GC.ADD_RATE_CONFIGS_ARRAY}
          stopCount={R.length(R.values(R.pathOr({}, [GC.FIELD_STOPS], props)))}
        />
      );

      const modal = G.getRateModalWithContent(modalContent);

      openModal(modal);
    },
    handleEditDriverRate: (props: Object) => () => {
      const {
        rate,
        loadData,
        openModal,
        branchGuid,
        driverRate,
        divisionGuid,
        handleSaveDriverRate,
      } = props;

      const modalContent = (
        <EditDriverRateForm
          telGuid={null}
          isFromOrder={true}
          loadData={loadData}
          branchGuid={divisionGuid}
          initialValues={driverRate}
          customerEnterpriseGuid={branchGuid}
          orderRateInfo={getOrderRateInfo(rate)}
          handleSendTelRate={handleSaveDriverRate}
          configsNamesArray={GC.EDIT_RATE_CONFIGS_ARRAY}
        />
      );

      const modal = G.getRateModalWithContent(modalContent);

      openModal(modal);
    },
  }),
  pure,
);

export default withTripRates;
