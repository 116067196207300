import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React, { useState, useEffect, useCallback } from 'react';
import {
  pure,
  compose,
  lifecycle,
  withState,
  withHandlers,
} from 'react-recompose';
import { differenceInDays } from 'date-fns';
// components
import ToggleBtn from '../../../components/toggle-btn';
import { DateRangeMui } from '../../../components/date-range';
import { LocalLoader } from '../../../components/local-loader';
import { FormGroupTitleMultiple } from '../../../components/form-group-title-multiple';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { ENUMS } from '../../../constants/enums';
// hocs
import { withFixedPopover, withAsyncInvoiceStatusConfigs } from '../../../hocs';
// ui
import { Box, Flex, ReactSelect } from '../../../ui';
// feature payroll
import { withDataFilterHoc } from '../hocs/with-data-filter';
import DriverWithInvoicesTable from './driver-with-invoices-table';
import { makeSelectUpdatedInvoice, makeSelectPayrollConfigs } from '../selectors';
import VendorWithInvoicesTable from '../vendor-payroll/components/vendor-with-invoices-table';
//////////////////////////////////////////////////

const getDateFilters = (payrollDayFrom: string, payrollDayTo: string) => {
  const dateFrom = G.convertInstanceToDefaultDateFormat(G.getDayOfPreviousWeekByDay(payrollDayFrom));

  let dateTo = G.convertInstanceToDefaultDateFormat(G.getDayOfThisWeekByDay(payrollDayTo));

  if (R.equals(payrollDayTo, ENUMS.ENUM_SUNDAY)) {
    dateTo = G.convertInstanceToDefaultDateFormat(G.getDayOfPreviousWeekByDay(payrollDayTo));
  }

  const quickDaysValue = differenceInDays(dateTo, dateFrom);

  return {
    dateTo,
    dateFrom,
    quickDaysValue,
  };
};


const enhance = compose(
  withFixedPopover,
  withState('invoiceStatusOptions', 'setInvoiceStatusOptions', []),
  withState('statuses', 'setStatuses', []),
  withAsyncInvoiceStatusConfigs,
  withDataFilterHoc,
  withState('filterDates', 'setFilterDates', (props: Object) => {
    const { payrollDayTo, payrollDayFrom, initialFilterDays } = props;

    const dateTo = G.getCurrentDateWithFormat(GC.DEFAULT_DATE_FORMAT);

    if (R.and(G.isNotNilAndNotEmpty(payrollDayTo), G.isNotNilAndNotEmpty(payrollDayFrom))) {
      const { dateTo, dateFrom, quickDaysValue } = getDateFilters(payrollDayFrom, payrollDayTo);

      return { dateTo, dateFrom, quickDaysValue };
    }

    return {
      dateTo,
      dateFrom: G.subtractMomentTimeWithFormat(
        dateTo,
        initialFilterDays,
        'days',
        GC.DEFAULT_DATE_FORMAT,
      ),
      quickDaysValue: initialFilterDays,
    };
  }),
  withState('payrollDates', 'setPayrollDates', (props: Object) => {
    const { payrollDayTo, payrollDayFrom, initialPayrollDays } = props;

    const dateTo = G.getCurrentDateWithFormat(GC.DEFAULT_DATE_FORMAT);

    if (R.and(G.isNotNilAndNotEmpty(payrollDayTo), G.isNotNilAndNotEmpty(payrollDayFrom))) {
      const { dateTo, dateFrom, quickDaysValue } = getDateFilters(payrollDayFrom, payrollDayTo);

      return { dateTo, dateFrom, quickDaysValue };
    }

    return {
      dateTo,
      dateFrom: G.subtractMomentTimeWithFormat(
        dateTo,
        initialPayrollDays,
        'days',
        GC.DEFAULT_DATE_FORMAT,
      ),
      quickDaysValue: initialPayrollDays,
    };
  }),
  withHandlers({
    handleSetFilterDates: (props: Object) => (dates: Object) => {
      const { statuses, branchGuid, setLoading, setFilterDates, getFilteredDataRequest } = props;

      setFilterDates(dates);

      const statusConfigGuids = R.map(({ value }: Object) => value, statuses);

      const options = {
        data: {
          ...dates,
          statusConfigGuids,
          [GC.FIELD_BRANCH_GUID]: branchGuid,
        },
      };

      setLoading();

      getFilteredDataRequest(options);
    },
    handleSetFilterQuickDays: (props: Object) => (value: number) => {
      const { statuses, branchGuid, setLoading, filterDates, setFilterDates, getFilteredDataRequest } = props;

      const dates = {
        dateTo: filterDates.dateTo,
        dateFrom: G.subtractMomentTimeWithFormat(
          filterDates.dateTo,
          R.dec(value),
          'days',
          GC.DEFAULT_DATE_FORMAT,
        ),
      };

      setFilterDates(R.assoc('quickDaysValue', value, dates));

      const statusConfigGuids = R.map(({ value }: Object) => value, statuses);

      const options = {
        data: {
          ...dates,
          statusConfigGuids,
          [GC.FIELD_BRANCH_GUID]: branchGuid,
        },
      };

      setLoading();

      getFilteredDataRequest(options);
    },
  }),
  withHandlers({
    handleSetPayrollDates: (props: Object) => (dates: Object) => {
      const { setPayrollDates, handleSetFilterDates } = props;

      handleSetFilterDates(dates);

      setPayrollDates(dates);
    },
    handleSetPayrollQuickDays: (props: Object) => (value: number) => {
      const { payrollDates, setPayrollDates } = props;

      setPayrollDates({
        quickDaysValue: value,
        dateTo: payrollDates.dateTo,
        dateFrom: G.subtractMomentTimeWithFormat(
          payrollDates.dateTo,
          R.dec(value),
          'days',
          GC.DEFAULT_DATE_FORMAT,
        ),
      });
    },
    handleChangeStatusFilter: (props: Object) => (statuses: Array) => {
      const {
        branchGuid,
        setLoading,
        filterDates,
        setStatuses,
        getFilteredDataRequest,
      } = props;

      setStatuses(statuses);

      const { dateTo, dateFrom } = filterDates;

      const statusConfigGuids = R.map(({ value }: Object) => value, statuses);

      const options = {
        data: {
          dateTo,
          dateFrom,
          statusConfigGuids,
          [GC.FIELD_BRANCH_GUID]: branchGuid,
        },
      };

      setLoading();

      getFilteredDataRequest(options);
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps: Object) {
      if (G.checkPropNotEqualInObjects('DPStatusConfig', this.props, prevProps)) {
        const statusConfig = R.pathOr([], ['DPStatusConfig'], this.props);

        const invoiceStatusOptions = statusConfig.map(({ guid, parentGuid, displayedValue }: Object) => ({
          label: displayedValue,
          value: R.or(parentGuid, guid),
        }));

        const setStatuses = () => (
          this.props.handleChangeStatusFilter(R.filter(
            ({ value }: Object) => (
              R.includes(value, R.pathOr([], ['configs', 'defaultInvoiceStatusGuids'], this.props))
            ),
            invoiceStatusOptions,
          ))
        );

        this.props.setInvoiceStatusOptions(invoiceStatusOptions, setStatuses);
      }
    },
  }),
  pure,
);

export const AddPayroll = (props: Object) => {
  const {
    statuses,
    isVendor,
    asyncData,
    openModal,
    closeModal,
    branchGuid,
    filterDates,
    addPayrolls,
    payrollDates,
    updatedInvoice,
    openFixedPopup,
    closeFixedPopup,
    autoPayrollNumber,
    invoiceStatusOptions,
    handleSetFilterDates,
    handleSetPayrollDates,
    handleChangeStatusFilter,
    handleSetFilterQuickDays,
    updateDriverInvoiceRequest,
    updateVendorInvoiceRequest,
  } = props;

  const { data, loading } = asyncData;

  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      const setting = await G.getIndexedDBItem(GC.AmousUIIdbProps, GC.payrollAddPayrollShowFiltersIdbId);

      if (setting) {
        const { value } = setting;

        setShowFilters(value);
      }
    };

    fetchSettings();
  }, []);

  const handleToggleFilters = useCallback((value: boolean) => {
    setShowFilters(value);

    const saveSettingsToDB = async () => {
      await G.saveIndexedDBItem(GC.AmousUIIdbProps, { value, id: GC.payrollAddPayrollShowFiltersIdbId });
    };

    saveSettingsToDB();
  });

  return (
    <Box bg='white' width='95vw' maxWidth={1200} zIndex='unset'>
      <FormGroupTitleMultiple
        mb='0'
        showArrowToggle={false}
        title={G.getWindowLocale('titles:add-payroll', 'Add Payroll')}
      />
      <Flex p={15}>
        <DateRangeMui
          {...payrollDates}
          width={80}
          useNewMuiInputField={true}
          popperPlacement='bottom-end'
          onSelectDateRange={handleSetPayrollDates}
          maxDate={G.momentAddYearsFromCurrent(100)}
          labelTo={G.getWindowLocale('titles:to', 'To')}
          minDate={G.momentSubtractYearsFromCurrent(100)}
          labelFrom={G.getWindowLocale('titles:from', 'From')}
          label={G.getWindowLocale('titles:set-payrolls-date-range', 'Set Payrolls Date Range')}
        />
        <ToggleBtn
          mx={15}
          checked={showFilters}
          onToggleHandler={handleToggleFilters}
          title={G.getWindowLocale('titles:show-filters', 'Show Filters')}
        />
        {
          showFilters &&
          <DateRangeMui
            {...filterDates}
            width={80}
            useNewMuiInputField={true}
            popperPlacement='bottom-end'
            quickDays={GC.QUICK_DAYS_FILTER}
            setQuickDays={handleSetFilterQuickDays}
            onSelectDateRange={handleSetFilterDates}
            maxDate={G.momentAddYearsFromCurrent(100)}
            labelTo={G.getWindowLocale('titles:to', 'To')}
            minDate={G.momentSubtractYearsFromCurrent(100)}
            labelFrom={G.getWindowLocale('titles:from', 'From')}
            label={G.getWindowLocale('titles:trip-range', 'Trip Range')}
          />
        }
        {
          showFilters &&
          <ReactSelect
            height={30}
            isMulti={true}
            value={statuses}
            isClearable={false}
            openMenuOnFocus={true}
            options={invoiceStatusOptions}
            onChange={handleChangeStatusFilter}
            shouldNotGetValueFromOptions={true}
            placeholder={G.getWindowLocale('titles:invoice-status', 'Invoice Status')}
            additionalStyles={{
              container: (baseStyles: Object) => ({
                ...baseStyles,
                width: 180,
                margin: '6px 0',
              }),
            }}
          />
        }
      </Flex>
      <LocalLoader width='100%' localLoaderOpen={loading}>
        {
          isVendor &&
          <VendorWithInvoicesTable
            initialData={data}
            openModal={openModal}
            closeModal={closeModal}
            branchGuid={branchGuid}
            addPayrolls={addPayrolls}
            payrollDates={payrollDates}
            updatedInvoice={updatedInvoice}
            openFixedPopup={openFixedPopup}
            closeFixedPopup={closeFixedPopup}
            autoPayrollNumber={autoPayrollNumber}
            updateVendorInvoiceRequest={updateVendorInvoiceRequest}
          />
        }
        {
          R.not(isVendor) &&
          <DriverWithInvoicesTable
            initialData={data}
            openModal={openModal}
            closeModal={closeModal}
            branchGuid={branchGuid}
            addPayrolls={addPayrolls}
            payrollDates={payrollDates}
            updatedInvoice={updatedInvoice}
            openFixedPopup={openFixedPopup}
            closeFixedPopup={closeFixedPopup}
            autoPayrollNumber={autoPayrollNumber}
            updateDriverInvoiceRequest={updateDriverInvoiceRequest}
          />
        }
      </LocalLoader>
    </Box>
  );
};

export default connect((state: Object) => createStructuredSelector({
  configs: makeSelectPayrollConfigs(state),
  updatedInvoice: makeSelectUpdatedInvoice(state),
}), {})(enhance(AddPayroll));
