import * as R from 'ramda';
import React, { Fragment } from 'react';
// components
import { TextComponent } from '../../../components/text';
// icons
import * as I from '../../../svgs';
// ui
import { Flex, Text, Span } from '../../../ui';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');

const ChargeItemTotal = (props: Object) => {
  const { arrayItem, payrollCurrency } = props;

  const deduction = G.getPropFromObject(GC.FIELD_DEDUCTION, arrayItem);
  const total = G.NaNToZero(G.toFixed(G.getTotalFromCharge(arrayItem), 2));
  const currencySymbol = G.getCurrencySymbol(R.pathOr(payrollCurrency, [GC.FIELD_CHARGE_CURRENCY], arrayItem));

  const text = G.ifElse(
    G.isTrue(deduction),
    `${currencySymbol} -${total}`,
    `${currencySymbol} ${total}`,
  );

  return (
    <Flex mr='3px'>
      <Text
        fontSize={14}
        fontWeight='bold'
        wordBreak='normal'
        color={darkBlueColor}
        textTransform='uppercase'
      >
        {`${G.getWindowLocale('titles:total', 'Total')}:`}
      </Text>
      <TextComponent
        ml={10}
        title={text}
        fontSize={14}
        fontWeight='bold'
        withEllipsis={true}
        color={darkBlueColor}
      >
        {text}
      </TextComponent>
    </Flex>
  );
};

const ChargeInfo = (props: Object) => {
  const {
    charge,
    flexDirection,
    showAllFields,
    setShowAllFields,
    handleRemoveCharge,
  } = props;

  return (
    <Flex>
      <Flex flexGrow={1} flexDirection={flexDirection}>
        <Span
          mr={10}
          cursor='pointer'
          onClick={() => handleRemoveCharge(charge)}
          title={G.getWindowLocale('titles:remove-charge', 'Remove Charge')}
        >
          {I.trash(darkBlueColor)}
        </Span>
        <Span
          mr={10}
          cursor='pointer'
          onClick={() => setShowAllFields(R.not(showAllFields))}
          title={G.getWindowLocale('titles:show-all-fields', 'Show All Fields')}
        >
          {I.pencil(darkBlueColor)}
        </Span>
      </Flex>
    </Flex>
  );
};

const PayedFor = (props: Object) => {
  const { values, chargeIndex } = props;

  const payedForTruck = R.path(
    [GC.FIELD_PAYROLL_CHARGES, chargeIndex, GC.FIELD_PAYED_FOR_TRUCK],
    values,
  );

  const payedForDriver = R.path(
    [GC.FIELD_PAYROLL_CHARGES, chargeIndex, GC.FIELD_PAYED_FOR_DRIVER],
    values,
  );

  if (R.and(G.isNilOrEmpty(payedForTruck), G.isNilOrEmpty(payedForDriver))) return null;

  const { fullText } = G.getUserInfo(payedForDriver);

  const payedText = G.ifElse(
    G.isNotNilAndNotEmpty(fullText),
    fullText,
    G.getPropFromObject(GC.FIELD_UNIT_ID, payedForTruck),
  );

  const text = `${G.getWindowLocale('titles:payed-for', 'Payed For')} - ${payedText}`;

  return (
    <TextComponent
      mx={10}
      title={text}
      fontSize={12}
      minWidth={250}
      maxWidth={250}
      withEllipsis={true}
    >
      {text}
    </TextComponent>
  );
};

const getChargeCommentText = (charge: Object) => R.pathOr(
  G.getWindowLocale('actions:add-comments', 'Add Comments'),
  [GC.FIELD_CHARGE_COMMENTS],
  charge,
);

const ChargeAdditionInfo = (props: Object) => {
  const {
    charge,
    values,
    chargeIndex,
    handleOpenChargeComment,
  } = props;

  const commentText = getChargeCommentText(charge);

  return (
    <Fragment>
      <PayedFor values={values} chargeIndex={chargeIndex} />
      <Flex cursor='pointer'>
        <span
          onClick={() => handleOpenChargeComment(charge)}
          title={G.getWindowLocale('actions:add-comments', 'Add Comments')}
        >
          {I.comment2()}
        </span>
        <TextComponent
          px='5px'
          fontSize={12}
          minWidth={250}
          maxWidth={250}
          display='block'
          withEllipsis={true}
          title={commentText}
          color={darkBlueColor}
          onClick={() => handleOpenChargeComment(charge)}
        >
          {commentText}
        </TextComponent>
      </Flex>
    </Fragment>
  );
};

export {
  ChargeInfo,
  ChargeItemTotal,
  ChargeAdditionInfo,
};
