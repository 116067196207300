import * as Yup from 'yup';
import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncConfigs } from '../../../hocs';
// forms
import { FieldsetComponent } from '../../../forms';
//////////////////////////////////////////////////

const defaultFieldStyles = {
  width: 350,
  errorTop: '110%',
  afterTop: '14px',
  errorTitle: true,
  errorLeft: '10px',
  labelWidth: '100%',
  errorWidth: '100%',
  errorOverflow: 'hidden',
  flexDirection: 'column',
  inputWrapMargin: '5px 0',
  errorWhiteSpace: 'nowrap',
  errorPosition: 'absolute',
  errorTextOverflow: 'ellipsis',
};

const getFields = (title: string) => [
  {
    ...defaultFieldStyles,
    loc: title,
    type: 'select',
    options: 'declineReasonOptions',
    fieldName: 'statusReasonCodeGuid',
  },
  {
    ...defaultFieldStyles,
    height: 150,
    type: 'textarea',
    loc: 'titles:comments',
    fieldName: GC.FIELD_COMMENT,
  },
];

const enhance = compose(
  withAsyncConfigs,
  withFormik({
    handleSubmit: (values: Object, { props }: Object) => {
      const { statusReasonCodeGuid } = values;
      const { submitAction, asyncConfigs, statusReasonCodeConfigName } = props;

      const statusReasonCodeDisplayedValue = R.compose(
        R.prop(GC.FIELD_DISPLAYED_VALUE),
        R.find((item: Object) => R.equals(G.getParentGuidOrGuidFromObject(item), statusReasonCodeGuid)),
        R.pathOr([], ['dropdowns', statusReasonCodeConfigName, 'options']),
      )(asyncConfigs);

      submitAction(R.assoc('statusReasonCodeDisplayedValue', statusReasonCodeDisplayedValue, values));
    },
    validationSchema: Yup.object().shape({
      [GC.FIELD_STATUS_REASON_CODE_GUID]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
      [GC.FIELD_COMMENTS]: Yup.string().nullable(true).max(2000, G.getShouldBeFromToCharLocaleTxt(0, 2000)),
    }),
    mapPropsToValues: ({ guid }: Object) => ({
      guid,
      [GC.FIELD_COMMENT]: null,
      [GC.FIELD_STATUS_REASON_CODE_GUID]: null,
    }),
  }),
  pure,
);

const CancelOrDeclineOrderQuoteForm = enhance((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent
      {...G.getFormikProps(props)}
      flexDirection='column'
      fields={getFields(G.ifElse(
        R.propEq(GC.CLO_QUOTE_CANCEL_REASON_CODE, 'statusReasonCodeConfigName', props),
        'titles:cancel-reason',
        'titles:decline-reason',
      ))}
      optionsForSelect={{
        declineReasonOptions: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
          props.asyncConfigs,
          props.statusReasonCodeConfigName,
          true,
        ),
      }}
    />
    <FormFooter boxStyles={{ pt: 10 }} closeModal={props.closeModal} />
  </form>
));

export default CancelOrDeclineOrderQuoteForm;
