import { createLogic } from 'redux-logic';
// helpers/constants
import * as GC from '../../constants';
// feature new-import
import * as A from './actions';
//////////////////////////////////////////////////

const handleLocationChangeLogic = createLogic({
  type: GC.LOCATION_CHANGE_ACTION,
  validate({ getState, action }: Object, allow: void) {
    allow(action);
  },
  process({ getState, action }: Object, dispatch: void, done: void) {
    dispatch(A.setInitialState());
    done();
  },
});

export default [handleLocationChangeLogic];
