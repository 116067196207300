import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { compose, withState, withHandlers } from 'react-recompose';
// common
import { makeSelectDocumentTemplates, makeSelectMailIntegrationType } from '../../../common/selectors';
import {
  createQBIIFImportByInvoiceTypeRequest,
  sendInvoiceToSageByInvoiceTypeRequest,
} from '../../../common/actions';
// components
import { Table } from '../../../components/table';
import { Tabs2 } from '../../../components/tabs-mui';
import { TitlePanel } from '../../../components/title-panel';
import { EditReport } from '../../../components/edit-report';
import { getConfirmModal } from '../../../components/confirm';
import { PageActions } from '../../../components/page-actions';
import { withPromptModal } from '../../../components/edit-report/hocs';
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import { withShowDocuments } from '../../../components/async-documents/with-show-documents';
// features
import Audit2 from '../../audit2';
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
import { startPivotTable } from '../../pivot-table/actions';
import { makeSelectOpenedSidebar } from '../../navbar-menu/selectors';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
import { makeSelectBranchesTree, makeSelectCurrentBranchGuid } from '../../branch/selectors';
// forms
import { SelectDropdownForm } from '../../../forms/forms/select-dropdown-form';
import SelectPrintTemplateForm from '../../../forms/forms/select-print-template-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withFixedPopover } from '../../../hocs';
import { withMassActionSubmit } from '../../../hocs/with-mass-action-submit';
import { withAsyncEditDriverInvoice } from '../../../hocs/with-async-edit-driver-invoice';
// report-common
import { reportEnhancer, reportBranchEnhancer } from '../../../report-common';
// icons
import * as I from '../../../svgs';
// ui
import { IconWrapper, ListWrapper, ZOrderWrapper } from '../../../ui';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// feature payroll
import * as H from '../helpers';
import AddPayroll from '../components/add-payroll';
import { FilterByTELReference } from '../driver-payroll';
import PayrollActions from '../components/payroll-actions';
import { VENDOR_FILTER_PARAMS } from '../settings/filter-params';
import PayrollWithCharges from './components/payroll-with-charges';
import { vendorsListReport } from '../settings/drivers-report-settings';
import UpdatePayrollInvoices from '../components/update-payroll-invoices';
import CreatePayrollsWithoutTrips from '../components/create-payroll-without-trips';
import { columnSettings, getTableSettings } from '../settings/payroll-list-table-settings';
import {
  selectItem,
  setReports,
  addPayrolls,
  setUsedReport,
  cleanQuickFilter,
  getPayrollDetails,
  setTableTitleSort,
  getItemListRequest,
  getPayrollStatistic,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  printPayrollRequest,
  getPayrollXMLRequest,
  setQuickFilterParams,
  printByReportRequest,
  updatePayrollInvoices,
  chaseCSVExportRequest,
  getXMLByReportRequest,
  exportStatisticRequest,
  resetListAndPagination,
  sendToQuickBookRequest,
  exportReportDataRequest,
  removeInvoiceFromPayroll,
  updateVendorInvoiceRequest,
  changeDefaultReportRequest,
  handleUpdatePayrollRequest,
  deleteVendorPayrollsRequest,
  createVendorPayrollsRequest,
  getDriverRatesXmlFileRequest,
  getPayrollByReportAndUpdateOnListRequest,
} from './actions';
import {
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectPayrollConfigs,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withFixedPopover,
  withMassActionSubmit,
  withAsyncEditDriverInvoice({ updateFrom: 'payrolls' }),
  withShowDocuments,
  reportEnhancer,
  withPromptModal(VENDOR_FILTER_PARAMS),
  withHandlers({
    handleOpenUpdatePayrollInvoices: (props: Object) => (payroll: Object) => {
      const { configs, openModal, closeModal, updatePayrollInvoices } = props;

      const component = (
        <UpdatePayrollInvoices
          isVendor={true}
          payroll={payroll}
          asyncMethod='post'
          closeModal={closeModal}
          updatePayrollInvoices={updatePayrollInvoices}
          initialFilterDays={configs.invoiceFilterDateRange}
          asyncEndpoint={endpointsMap.getInvoicesToUpdateVendorPayroll}
        />
      );

      const modal = H.getUpdatePayrollInvoicesModalWithContent(component);

      openModal(modal);
    },
    handleShowAudit: (props: Object) => ({ guid, payrollNumber }: Object) => {
      const { openModal, closeFixedPopup } = props;

      closeFixedPopup();

      const options = {
        [GC.FIELD_OBJECT_GUID]: guid,
        [GC.FIELD_TYPE]: GC.AUDIT_TYPE_VENDOR_PAYROLL,
      };

      const component = <Audit2 requestOptions={options} />;

      const modal = {
        p: '0',
        component,
        options: {
          width: '100%',
          boxShadow: 'none',
          withCloseIcon: true,
          withBorderRadius: true,
          backgroundColor: 'transparent',
          title: `${G.getWindowLocale('titles:audit', 'Audit')}: ${payrollNumber}`,
        },
      };

      openModal(modal);
    },
    handlePrintPayroll: (props: Object) => (payroll: Object) => {
      const {
        itemList,
        openModal,
        closeModal,
        documentTemplates,
        printPayrollRequest,
        printByReportRequest,
      } = props;

      const driverPayrollDocumentTemplates = G.getPropFromObject(
        GC.DOCUMENT_PRINTABLE_SECTION_FLEET_VENDOR_PAYROLL,
        documentTemplates,
      );

      if (G.isNilOrEmpty(driverPayrollDocumentTemplates)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:no-configured-document-templates', 'There are no configured document templates'),
        );
      }

      let component;
      let payrollGuids;

      if (R.isNil(payroll)) {
        const selectedList = R.filter(R.prop('selected'), itemList);

        if (G.isNilOrEmpty(selectedList)) {
          component = (
            <SelectPrintTemplateForm
              submitAction={(values: Object) => printByReportRequest(values)}
              printableSection={GC.DOCUMENT_PRINTABLE_SECTION_FLEET_VENDOR_PAYROLL_REPORT}
            />
          );
        } else {
          payrollGuids = R.map(({ guid }: Object) => guid, selectedList);
        }
      } else {
        payrollGuids = R.of(Array, payroll.guid);
      }

      if (G.isNotNilAndNotEmpty(payrollGuids)) {
        const options = R.map(
          (template: Object) => ({ value: template.guid, label: template.name }),
          driverPayrollDocumentTemplates,
        );

        const submitAction = (value: string) => {
          const dataSource = R.compose(
            R.path([value, GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_DATA_SOURCE]),
            R.indexBy(R.prop(GC.FIELD_GUID)),
          )(driverPayrollDocumentTemplates);

          let isSingleVendor;

          if (R.equals(R.length(payrollGuids), 1)) {
            isSingleVendor = true;
          } else {
            isSingleVendor = R.compose(
              R.equals(1),
              R.length,
              R.uniq,
              R.map(R.path([GC.SYSTEM_OBJECT_FLEET_VENDOR, GC.FIELD_GUID])),
              R.filter(R.prop('selected')),
            )(itemList);
          }

          const condition = R.and(
            R.not(isSingleVendor),
            R.equals(dataSource, GC.DOCUMENT_PRINTABLE_SECTION_VENDOR_DRIVER_RATE_LIST),
          );

          if (condition) {
            return G.showToastrMessageSimple(
              'info',
              G.getWindowLocale(
                'messages:select-payrolls-with-same-vendor',
                'Please, select payrolls with the same vendor',
              ),
            );
          }

          printPayrollRequest({
            payrollGuids,
            templateGuid: value,
          });
        };

        component = (
          <SelectDropdownForm
            fieldLabel=' '
            options={options}
            cancelAction={closeModal}
            submitAction={submitAction}
          />
        );
      }

      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          maxWidth: '95vw',
          maxHeight: '95vh',
          width: 'fit-content',
          title: G.getWindowLocale('titles:select-template', 'Select Template'),
        },
      };

      openModal(modal);
    },
    handleSendPayrollsToSageIntacct: (props: Object) => (payrollGuid: string) => {
      const {
        itemList,
        closeFixedPopup,
        getItemListRequest,
        resetListAndPagination,
        sendInvoiceToSageByInvoiceTypeRequest,
        getPayrollByReportAndUpdateOnListRequest,
      } = props;

      G.callFunction(closeFixedPopup);

      if (G.isString(payrollGuid)) {
        return sendInvoiceToSageByInvoiceTypeRequest({
          notCloseModal: true,
          invoiceType: 'vendorPayroll',
          guids: R.of(Array, payrollGuid),
          currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
          callback: () => getPayrollByReportAndUpdateOnListRequest(payrollGuid),
        });
      }

      const guids = R.compose(
        R.map(G.getGuidFromObject),
        R.filter(R.prop('selected')),
      )(itemList);

      if (R.isEmpty(guids)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      const callback = () => {
        resetListAndPagination();
        getItemListRequest(true);
      };

      sendInvoiceToSageByInvoiceTypeRequest({
        guids,
        callback,
        invoiceType: 'vendorPayroll',
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      });
    },
  }),
  withState('nextPayrollGuid', 'setNextPayrollGuid', null),
  withState('prevPayrollGuid', 'setPrevPayrollGuid', null),
  withHandlers({
    handleToggleDetails: ({ getPayrollDetails, getPayrollStatistic }: Object) => (payroll: Object) => {
      getPayrollDetails(payroll);
      getPayrollStatistic(payroll);
    },
    handleQBIIFImport: (props: Object) => (guids: Array) => {
      const {
        getItemListRequest,
        resetListAndPagination,
        createQBIIFImportByInvoiceTypeRequest,
      } = props;

      const callback = () => {
        resetListAndPagination();
        getItemListRequest(true);
      };

      createQBIIFImportByInvoiceTypeRequest({ guids, callback, type: 'vendorPayroll' });
    },
    handleOpenEditPayrollForm: (props: Object) => (payrollGuid: string, payroll: Object) => {
      const {
        itemList,
        openModal,
        closeModal,
        branchGuid,
        openLoader,
        closeLoader,
        handleShowAudit,
        setPrevPayrollGuid,
        setNextPayrollGuid,
        handlePrintPayroll,
        getItemListRequest,
        handleShowDocuments,
        mailIntegrationType,
        getPayrollStatistic,
        getPayrollXMLRequest,
        resetListAndPagination,
        exportStatisticRequest,
        sendToQuickBookRequest,
        handleUpdatePayrollRequest,
        getDriverRatesXmlFileRequest,
        handleSendPayrollsToSageIntacct,
        handleOpenUpdatePayrollInvoices,
      } = props;

      const nextPayrollGuid = R.path([R.inc(payroll.index), GC.FIELD_GUID], itemList);

      const prevPayrollGuid = R.path([R.dec(payroll.index), GC.FIELD_GUID], itemList);

      const fleetVendorGuid = R.or(
        G.getPropFromObject('fleetVendorGuid', payroll),
        R.prop('fleetVendor.guid', payroll),
      );

      const vendorName = R.prop('fleetVendor.name', payroll);

      const endpoint = endpointsMap.getVendorPayrollDetailsEndpoint(payrollGuid);

      const initialFilterDates = R.pick([GC.FIELD_PAYROLL_DATE_TO, GC.FIELD_PAYROLL_DATE_FROM], payroll);

      const quickBookData = {
        guids: R.of(Array, payrollGuid),
        [GC.FIELD_BRANCH_GUID]: payroll[GC.FIELD_BRANCH_GUID],
      };

      const component = (
        <PayrollWithCharges
          editMode={true}
          openModal={openModal}
          vendorName={vendorName}
          closeModal={closeModal}
          openLoader={openLoader}
          branchGuid={branchGuid}
          asyncEndpoint={endpoint}
          payrollGuid={payrollGuid}
          closeLoader={closeLoader}
          fleetVendorGuid={fleetVendorGuid}
          initialFilterDates={initialFilterDates}
          getItemListRequest={getItemListRequest}
          mailIntegrationType={mailIntegrationType}
          exportStatisticRequest={exportStatisticRequest}
          resetListAndPagination={resetListAndPagination}
          handleUpdatePayroll={handleUpdatePayrollRequest}
          getPayrollStatistic={() => getPayrollStatistic(payroll)}
          setNextPayrollGuid={() => setNextPayrollGuid(nextPayrollGuid)}
          setPrevPayrollGuid={() => setPrevPayrollGuid(prevPayrollGuid)}
          // actions
          handleShowDocuments={() => handleShowDocuments(
            G.getGuidFromObject(payroll),
            GC.DOCUMENTS_TYPE_VENDOR_PAYROLL,
            fleetVendorGuid,
          )}
          handleShowAudit={() => handleShowAudit(payroll)}
          handlePrintPayroll={() => handlePrintPayroll(payroll)}
          getPayrollXMLRequest={() => getPayrollXMLRequest(payroll)}
          handleSendToQuickBook={() => sendToQuickBookRequest(quickBookData)}
          getDriverRatesXmlFileRequest={() => getDriverRatesXmlFileRequest(payroll)}
          handleOpenUpdatePayrollInvoices={() => handleOpenUpdatePayrollInvoices(payroll)}
          handleSendPayrollsToSageIntacct={() => handleSendPayrollsToSageIntacct(payrollGuid)}
          getPayrollNewXMLRequest={() => getPayrollXMLRequest(R.assoc('isNew', true, payroll))}
        />
      );

      const modal = G.getFullWindowModalWithContent(component);

      openModal(modal);
    },
    handleOpenAddVendorsPayrollForm: (props: Object) => () => {
      const {
        configs,
        openModal,
        closeModal,
        branchGuid,
        openLoader,
        closeLoader,
        createVendorPayrollsRequest,
      } = props;

      if (G.isFalse(configs.autoPayrollNumber)) {
        const modalContent = getConfirmModal({
          cancelAction: closeModal,
          cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
          submitText: G.getWindowLocale('actions:go-to-config', 'Go To Config'),
          text: G.getWindowLocale(
            'messages:setup-payroll-number-autogenerated',
            'You need to setup autogenerated payroll number',
          ),
          submitAction: () => {
            G.goToRoute(GC.ROUTE_PATH_CONFIG_INVOICES);
            closeModal();
          },
        });

        return openModal(modalContent);
      }

      const asyncOptions = {
        data: {
          pageable: false,
          fields: vendorsListReport.fields,
          [GC.FIELD_CURRENT_BRANCH]: branchGuid,
        },
      };

      const component = (
        <CreatePayrollsWithoutTrips
          isVendor={true}
          editMode={false}
          openModal={openModal}
          closeModal={closeModal}
          openLoader={openLoader}
          branchGuid={branchGuid}
          closeLoader={closeLoader}
          asyncReportOptions={asyncOptions}
          asyncReportEndpoint={endpointsMap.fleetVendorList}
          handleCreateDriversPayroll={createVendorPayrollsRequest}
        />
      );

      const modal = {
        p: '0',
        component,
        options: {
          width: 900,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '95vh',
          title: G.getWindowLocale('titles:add-payroll', 'Add Payroll'),
        },
        title: G.getWindowLocale('titles:add-payroll', 'Add Payroll'),
      };

      openModal(modal);
    },
    handleOpenSubmitDeletePayroll: (props: Object) => (guid: string, payroll: Object) => {
      const {
        openModal,
        closeModal,
        deleteVendorPayrollsRequest,
      } = props;

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        name: payroll.payrollNumber,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: G.getWindowLocale('messages:delete-confirmation-text-double', 'Are you sure you want to delete'),
        submitAction: () => {
          deleteVendorPayrollsRequest(R.of(Array, guid));
          closeModal();
        },
      });

      openModal(modalContent);
    },
    handleOpenSubmitDeletePayrolls: (props: Object) => () => {
      const {
        itemList,
        openModal,
        closeModal,
        deleteVendorPayrollsRequest,
      } = props;

      const selectedList = R.filter(
        R.prop('selected'),
        itemList,
      );

      if (R.propEq(0, 'length', selectedList)) return;

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        name: R.join(', ', R.map(({ payrollNumber }: Object) => payrollNumber, selectedList)),
        text: G.getWindowLocale('messages:delete-confirmation-text-double', 'Are you sure you want to delete'),
        submitAction: () => {
          deleteVendorPayrollsRequest(R.map(
            ({ guid }: Object) => guid,
            selectedList,
          ));
          closeModal();
        },
      });

      openModal(modalContent);
    },
    handleExportPayrollsToQuickBook: (props: Object) => () => {
      const { itemList, sendToQuickBookRequest } = props;

      const selectedList = R.filter(R.prop('selected'), itemList);

      if (R.propEq(0, 'length', selectedList)) return;

      const fileName = R.join('_', R.map(({ payrollNumber }: Object) => payrollNumber, selectedList));
      const fileGuids = R.map(({ guid }: Object) => guid, selectedList);

      sendToQuickBookRequest({ fileName, fileGuids });
    },
  }),
  withHandlers({
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getItemListRequest(true)}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
    handleChangeFilterByTELReference: (props: Object) => (filterString: string) => {
      const { setQuickFilterParams, getItemListRequest, resetListAndPagination } = props;

      let quickFilterParams = {};

      if (G.isNotNilAndNotEmpty(filterString)) {
        quickFilterParams = {
          dataType: 'string',
          dateRelative: false,
          operation: 'contain',
          stringValue: filterString,
          propertyName: GC.GRC.INVOICES_TEL_PRIMARY_REFERENCE_VALUE,
        };
      }

      setQuickFilterParams(quickFilterParams);
      resetListAndPagination();
      getItemListRequest(true);
    },
    handleClickEditIcon: (props: Object) => (e: Object, payroll: Object) => {
      const {
        openFixedPopup,
        handleShowAudit,
        closeFixedPopup,
        handleQBIIFImport,
        handlePrintPayroll,
        handleShowDocuments,
        getPayrollXMLRequest,
        chaseCSVExportRequest,
        sendToQuickBookRequest,
        getDriverRatesXmlFileRequest,
        handleOpenUpdatePayrollInvoices,
        handleSendPayrollsToSageIntacct,
      } = props;

      const dataToSend = {
        guids: R.of(Array, payroll.guid),
        [GC.FIELD_BRANCH_GUID]: payroll[GC.FIELD_BRANCH_GUID],
      };

      openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: (
          <PayrollActions
            isVendor={true}
            payroll={payroll}
            closeFixedPopup={closeFixedPopup}
            qbIIFImportRequest={handleQBIIFImport}
            chaseCSVExportRequest={chaseCSVExportRequest}
            handleShowAudit={() => handleShowAudit(payroll)}
            handlePrintPayroll={() => handlePrintPayroll(payroll)}
            handleGetPayrollXMLRequest={() => getPayrollXMLRequest(payroll)}
            handleSendToQuickBookRequest={() => sendToQuickBookRequest(dataToSend)}
            getDriverRatesXmlFileRequest={() => getDriverRatesXmlFileRequest(payroll)}
            openUpdatePayrollInvoices={() => handleOpenUpdatePayrollInvoices(payroll)}
            handleGetPayrollNewXMLRequest={() => getPayrollXMLRequest(R.assoc('isNew', true, payroll))}
            sendPayrollsToSageIntacct={() => handleSendPayrollsToSageIntacct(G.getGuidFromObject(payroll))}
            handleShowStatement={() => handleShowDocuments(
              G.getGuidFromObject(payroll),
              GC.DOCUMENTS_TYPE_VENDOR_PAYROLL,
              R.prop(GC.GRC.FLEET_VENDOR_GUID, payroll),
            )}
          />
        ),
      });
    },
    handleOpenAddPayrolls: (props: Object) => () => {
      const {
        configs,
        openModal,
        closeModal,
        branchGuid,
        addPayrolls,
        openFixedPopup,
        closeFixedPopup,
        updateVendorInvoiceRequest,
      } = props;

      const component = (
        <AddPayroll
          isVendor={true}
          asyncMethod='post'
          openModal={openModal}
          closeModal={closeModal}
          branchGuid={branchGuid}
          addPayrolls={addPayrolls}
          openFixedPopup={openFixedPopup}
          closeFixedPopup={closeFixedPopup}
          initialPayrollDays={configs.payrollDateRange}
          autoPayrollNumber={configs.autoPayrollNumber}
          initialFilterDays={configs.invoiceFilterDateRange}
          asyncEndpoint={endpointsMap.vendorPayrollVendorList}
          updateVendorInvoiceRequest={updateVendorInvoiceRequest}
        />
      );

      const modal = H.getPayrollModalWithContent(component);

      openModal(modal);
    },
    handleRemoveInvoiceFromPayroll: (props: Object) => (
      invoiceGuid: string,
      invoiceNumber: string,
      fleetVendorPayrollGuid: string,
    ) => {
      const { openModal, closeModal, removeInvoiceFromPayroll } = props;

      const modalContent = getConfirmModal({
        name: invoiceNumber,
        cancelAction: props.closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: G.getWindowLocale('messages:delete-confirmation-text-double', 'Are you sure you want to delete'),
        submitAction: () => {
          removeInvoiceFromPayroll({ invoiceGuid, fleetVendorPayrollGuid });
          closeModal();
        },
      });

      openModal(modalContent);
    },
    handleQBIIFImportList: (props: Object) => () => {
      const {
        itemList,
        getItemListRequest,
        resetListAndPagination,
        createQBIIFImportByInvoiceTypeRequest,
      } = props;

      const guids = R.compose(
        R.map(R.prop(GC.FIELD_GUID)),
        R.filter(R.prop('selected')),
      )(itemList);

      if (R.isEmpty(guids)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      const callback = () => {
        resetListAndPagination();
        getItemListRequest(true);
      };

      createQBIIFImportByInvoiceTypeRequest({ guids, callback, type: 'vendorPayroll' });
    },
    handleChaseCSVExport: (props: Object) => (invoiceGuid: string) => {
      const { itemList, chaseCSVExportRequest } = props;

      const guids = G.getGuidsFromStringOrSelectedList(invoiceGuid, itemList);

      if (R.isEmpty(guids)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      chaseCSVExportRequest(guids);
    },
    handleExportStatistic: (props: Object) => (invoiceGuid: string) => {
      const { itemList, exportStatisticRequest } = props;

      const guids = G.getGuidsFromStringOrSelectedList(invoiceGuid, itemList);

      if (R.isEmpty(guids)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      exportStatisticRequest(guids);
    },
  }),
  reportBranchEnhancer,
);

const PayrollTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    selectItem,
    pagination,
    reportList,
    filterParams,
    openedSidebar,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleClickEditIcon,
    handleToggleDetails,
    exportStatisticRequest,
    handleTableTitleFilter,
    handleEditDriverInvoice,
    handleOpenEditPayrollForm,
    handleOpenSubmitDeletePayroll,
    handleRemoveInvoiceFromPayroll,
  } = props;

  const elementActionsComponent = (data: Object) => (
    <AuthWrapper has={[PC.FLEET_VENDOR_PAYROLL_READ, PC.FLEET_VENDOR_PAYROLL_WRITE]}>
      <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, data)}>
        {I.threeDots()}
      </IconWrapper>
    </AuthWrapper>
  );

  const allChecked = G.isAllChecked(itemList);

  let enhancedItemList = itemList;

  if (G.isNotNil(enhancedItemList)) {
    enhancedItemList = R.map(
      (entity: Object) => R.mergeRight(
        entity,
        {
          detailsWidth: `calc(100vw - ${
            G.ifElse(
              openedSidebar,
              R.add(GC.SIDEBAR_CONTAINER_WIDTH, 32),
              32,
            )
          }px)`,
          editInvoice: handleEditDriverInvoice,
          removeInvoice: handleRemoveInvoiceFromPayroll,
        },
      ),
      enhancedItemList,
    );
  }

  const columnSettingsToUse = R.compose(
    R.assocPath(['invoicesTotal', 'name'], 'titles:total-vendor-trip-charges'),
    R.dissoc(GC.FIELD_MAIN_CHARGES_TOTAL),
  )(columnSettings);

  const getPermissions = ({ completed }: Object) => G.ifElse(
    G.isTrue(completed),
    [PC.FLEET_VENDOR_PAYROLL_OVERWRITE_EXECUTE],
    [PC.FLEET_VENDOR_PAYROLL_WRITE, PC.FLEET_VENDOR_PAYROLL_OVERWRITE_EXECUTE],
  );

  const actionButtons = [
    {
      getPermissions,
      iconName: 'pencil',
      action: handleOpenEditPayrollForm,
    },
    {
      iconName: 'trash',
      action: handleOpenSubmitDeletePayroll,
      permissions: [PC.FLEET_VENDOR_PAYROLL_DELETE_EXECUTE],
    },
  ];

  const data = {
    loading,
    allChecked,
    totalCount,
    pagination,
    actionButtons,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    report: selectedReport,
    handleTableTitleFilter,
    onOptionClick: selectItem,
    itemList: enhancedItemList,
    withResizableColumns: true,
    toggle: handleToggleDetails,
    useNewTitleFilterInputs: true,
    columnSettings: columnSettingsToUse,
    callbackData: { exportStatisticRequest },
    handleLoadMoreEntities: getItemListRequest,
    renderRightStickedComponent: elementActionsComponent,
    filterProps: R.indexBy(R.prop(GC.FIELD_VALUE), VENDOR_FILTER_PARAMS),
    tableSettings: getTableSettings({ reportList, filterParams, selectedReport }),
  };

  return <Table {...data} />;
};

const getListActionsOpt = (props: Object) => {
  const mainLightColor = G.getTheme('colors.light.mainLight');

  return [
    {
      action: props.getXMLByReportRequest,
      icon: I.downloadDocument(mainLightColor, 25, 25),
      text: G.getWindowLocale('actions:get-xml-file', 'Get XML File'),
    },
    {
      type: 'massAction',
      action: props.handleExportStatistic,
      icon: I.downloadDocument(mainLightColor, 25, 25),
      text: G.getWindowLocale('actions:download-statistic', 'Download Statistic'),
    },
    {
      type: 'massAction',
      icon: I.quickbook('transparent', 25, 25),
      action: props.handleExportPayrollsToQuickBook,
      text: G.getWindowLocale('actions:export-to-QB', 'Export to QuickBooks'),
      permissions: [PC.FLEET_VENDOR_PAYROLL_WRITE, PC.FLEET_VENDOR_PAYROLL_OVERWRITE_EXECUTE],
    },
    {
      type: 'massAction',
      icon: I.gear(mainLightColor, 25, 25),
      action: props.handleSendPayrollsToSageIntacct,
      text: G.getWindowLocale('actions:send-to-sage-intacct', 'Send To Sage Intacct'),
      permissions: [PC.FLEET_VENDOR_PAYROLL_WRITE, PC.FLEET_VENDOR_PAYROLL_OVERWRITE_EXECUTE],
    },
    {
      type: 'massAction',
      action: props.handleQBIIFImportList,
      icon: I.quickbook('transparent', 25, 25),
      text: G.getWindowLocale('actions:qb-iif-export', 'QuickBooks IIF Export'),
      permissions: [
        PC.FLEET_VENDOR_PAYROLL_READ,
        PC.FLEET_VENDOR_PAYROLL_WRITE,
        PC.FLEET_VENDOR_PAYROLL_OVERWRITE_EXECUTE,
      ],
    },
    {
      type: 'massAction',
      action: props.handleChaseCSVExport,
      icon: I.downloadDocument(mainLightColor, 25, 25),
      text: G.getWindowLocale('actions:chase-csv-export', 'Chase CSV Export'),
      permissions: [
        PC.FLEET_VENDOR_PAYROLL_READ,
        PC.FLEET_VENDOR_PAYROLL_WRITE,
        PC.FLEET_VENDOR_PAYROLL_OVERWRITE_EXECUTE,
      ],
    },
    {
      type: 'massAction',
      icon: I.printer(mainLightColor, 20, 20),
      action: () => props.handlePrintPayroll(null),
      text: G.getWindowLocale('actions:print', 'Print'),
    },
    {
      type: 'massAction',
      icon: I.trash(mainLightColor, 20, 20),
      action: props.handleOpenSubmitDeletePayrolls,
      text: G.getWindowLocale('actions:delete', 'Delete'),
      permissions: [PC.FLEET_VENDOR_PAYROLL_DELETE_EXECUTE],
    },
    {
      action: props.handleOpenAddVendorsPayrollForm,
      icon: I.createCloIcon(mainLightColor, 40, 40, 'transparent'),
      text: G.getWindowLocale('actions:create-payrolls-without-trips', 'Create Payrolls Without Trips'),
    },
  ];
};

const VendorPayrolls = (props: Object) => {
  const {
    itemList,
    activeTab,
    totalCount,
    prevPayrollGuid,
    nextPayrollGuid,
    startPivotTable,
    handleSetActiveTab,
    handleOpenAddPayrolls,
    handleOpenEditPayrollForm,
  } = props;

  useEffect(() => {
    if (G.isNilOrEmpty(prevPayrollGuid)) return;

    const payroll = R.find(R.propEq(prevPayrollGuid, GC.FIELD_GUID), itemList);

    handleOpenEditPayrollForm(prevPayrollGuid, payroll);
  }, [prevPayrollGuid]);

  useEffect(() => {
    if (G.isNilOrEmpty(nextPayrollGuid)) return;

    const payroll = R.find(R.propEq(nextPayrollGuid, GC.FIELD_GUID), itemList);

    handleOpenEditPayrollForm(nextPayrollGuid, payroll);
  }, [nextPayrollGuid]);

  const handleClickTab = (i: number) => {
    if (R.includes(i, [1, 2, 4, 5])) {
      const reportType = {
        1: GC.PIVOT_DRIVER_PAYROLL_REPORT,
        4: GC.PIVOT_VENDOR_PAYROLL_REPORT,
        2: GC.PIVOT_PAYROLL_CHARGES_REPORT,
        5: GC.PIVOT_VENDOR_PAYROLL_CHARGES_REPORT,
      }[i];
      startPivotTable(reportType);
    }

    handleSetActiveTab(i);
  };

  const customTitleComponent = (
    <Tabs2
      count={totalCount}
      activeTab={activeTab}
      tabs={H.getTabs(activeTab)}
      setActiveTab={handleClickTab}
      tabStyles={GC.COMMON_MUI_TAB_STYLES}
      tabsStyles={GC.COMMON_MUI_TABS_STYLES}
    />
  );

  return (
    <ListWrapper p={15} bgColor={G.getTheme('pages.layOutBgColor')}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          pt='5px'
          withCount={true}
          alignItems='flex-end'
          popperWithCount={true}
          hiddenRightFilterInfo={false}
          type={GC.VENDOR_PAYROLL_REPORT}
          usedReport={props.selectedReport}
          filterProps={VENDOR_FILTER_PARAMS}
          customTitleComponent={customTitleComponent}
          additionalComponent={<FilterByTELReference {...props} />}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        <PayrollTable {...props} />
      </ZOrderWrapper>
      <AuthWrapper has={[PC.FLEET_VENDOR_PAYROLL_WRITE]}>
        <PageActions
          listActions={getListActionsOpt(props)}
          shadowColor={G.getTheme('colors.light.darkGrey')}
          count={R.filter((item: Object) => item.selected, R.or(itemList, [])).length}
          mainAction={{
            action: handleOpenAddPayrolls,
            text: G.getWindowLocale('actions:create-payrolls-with-trips', 'Create Payrolls With Trips'),
          }}
        />
      </AuthWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  configs: makeSelectPayrollConfigs(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  branchesTree: makeSelectBranchesTree(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  openedSidebar: makeSelectOpenedSidebar(state),
  branchGuid: makeSelectCurrentBranchGuid(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  documentTemplates: makeSelectDocumentTemplates(state),
  mailIntegrationType: makeSelectMailIntegrationType(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  openLoader,
  closeModal,
  selectItem,
  setReports,
  closeLoader,
  addPayrolls,
  setUsedReport,
  startPivotTable,
  cleanQuickFilter,
  getPayrollDetails,
  setTableTitleSort,
  getItemListRequest,
  getPayrollStatistic,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  printPayrollRequest,
  getPayrollXMLRequest,
  setQuickFilterParams,
  printByReportRequest,
  updatePayrollInvoices,
  getXMLByReportRequest,
  chaseCSVExportRequest,
  exportStatisticRequest,
  sendToQuickBookRequest,
  resetListAndPagination,
  exportReportDataRequest,
  removeInvoiceFromPayroll,
  updateVendorInvoiceRequest,
  changeDefaultReportRequest,
  handleUpdatePayrollRequest,
  deleteVendorPayrollsRequest,
  createVendorPayrollsRequest,
  getDriverRatesXmlFileRequest,
  createQBIIFImportByInvoiceTypeRequest,
  sendInvoiceToSageByInvoiceTypeRequest,
  getPayrollByReportAndUpdateOnListRequest,
  updateDriverInvoiceRequest: updateVendorInvoiceRequest,
})(enhance(VendorPayrolls));
