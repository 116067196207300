import * as R from 'ramda';
import React from 'react';
// components
import { Table } from '../../../components/table';
import { rateTypeOptions, rateUnitOptionsGroup } from '../../../components/charge/settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const chargesColumnSettings = {
  [GC.FIELD_DISPLAYED_VALUE]: {
    width: 180,
    name: 'titles:charge-description',
  },
  [GC.FIELD_RATE]: {
    width: 100,
    name: 'titles:rate',
  },
  [GC.FIELD_RATE_TYPE]: {
    width: 180,
    name: 'titles:type',
    customComponent: ({ data }: Object) => {
      const { type, rateType } = data;

      return R.compose(
        R.path([GC.FIELD_LABEL]),
        R.find(R.propEq(rateType, GC.FIELD_VALUE)),
        R.pathOr([], [type, GC.FIELD_TEL]),
      )(rateTypeOptions);
    },
  },
  [GC.FIELD_RATE_UNIT]: {
    width: 140,
    name: 'titles:unit',
    customComponent: ({ data }: Object) => {
      const { rateUnit, rateType } = data;

      return R.compose(
        R.path([GC.FIELD_LABEL]),
        R.find(R.propEq(rateUnit, GC.FIELD_VALUE)),
        R.pathOr([], [rateType]),
      )(rateUnitOptionsGroup);
    },
  },
  [GC.FIELD_QUANTITY]: {
    width: 100,
    name: 'titles:qty',
  },
  [GC.FIELD_TOTAL]: {
    width: 140,
    name: 'titles:total',
  },
};

const chargesReport = {
  fields: [
    { sequence: 1, name: GC.FIELD_DISPLAYED_VALUE },
    { sequence: 2, name: GC.FIELD_RATE },
    { sequence: 3, name: GC.FIELD_RATE_TYPE },
    { sequence: 4, name: GC.FIELD_RATE_UNIT },
    { sequence: 5, name: GC.FIELD_QUANTITY },
    { sequence: 5, name: GC.FIELD_TOTAL },
  ],
};

export const tableSettings = {
  rowHeight: 40,
  cellFontSize: 11,
  titleFontSize: 11,
  maxHeight: '400px',
  titleRowHeight: 32,
  tableRowHeight: 40,
  allowEditBtn: true,
  useMainColors: true,
  checkBoxCellWidth: 50,
  expandableItems: false,
  allowSelectItems: false,
  additionalTitleBgColor: G.getTheme('colors.lightGrey'),
};

const ChargesSection = ({ currency, groupedCharges, useChargeCurrency }: Object) => {
  const titlesMap = {
    [GC.CHARGE_TYPE_FUEL]: ['titles:fuel', 'Fuel'],
    [GC.CHARGE_TYPE_MAIN]: ['titles:linehaul', 'Linehaul'],
    [GC.CHARGE_TYPE_DISCOUNT]: ['titles:discount', 'Discount'],
    [GC.CHARGE_TYPE_ADDITIONAL]: ['titles:additional-charges', 'Additional Charges'],
  };

  return (
    <Box mt={10}>
      {
        R.keys(groupedCharges).map((group: string, index: number) => {
          const itemList = R.compose(
            R.map((item: Object) => {
              const { rate, total } = item;

              const currencyToUse = G.ifElse(
                G.isTrue(useChargeCurrency),
                R.pathOr(currency, [GC.FIELD_CURRENCY], item),
                currency,
              );
              const totalStr = `${R.or(rate, total)} ${G.getCurrencySymbol(currencyToUse)}`;

              return R.assoc(GC.FIELD_TOTAL, totalStr, item);
            }),
            R.pathOr([], [group]),
          )(groupedCharges);

          return (
            <div key={index}>
              <Flex
                p='5px'
                fontWeight='bold'
                justifyContent='center'
                textTransform='uppercase'
                color={G.getTheme('colors.dark.blue')}
              >
                {G.getWindowLocale(...R.pathOr([], [group], titlesMap))}
              </Flex>
              <Table
                itemList={itemList}
                report={chargesReport}
                tableSettings={tableSettings}
                columnSettings={chargesColumnSettings}
              />
            </div>
          );
        })
      }
    </Box>
  );
};

export default ChargesSection;
