import * as R from 'ramda';
import {
  put,
  call,
  take,
  race,
  select,
  takeLatest } from 'redux-saga/effects';
// common
import { initialDataLoadFail, initialDataLoadSuccess } from '../../common/actions';
// components
import { closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
import { transformSearchCriteriaBeforeReportPost } from '../../components/edit-report/helpers';
// features
import { cleanQuickFilter } from '../report-format/actions';
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { getLoadDetailsRequest } from '../dispatch-board-new/load/actions';
import { getOrderDetailsRequest } from '../dispatch-board-new/order/actions';
import { makeSelectAccessToPagesList, makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature reference
import * as A from './actions';
import { getFilterParams } from './settings/filter-params';
import {
  makeSelectActiveList,
  makeSelectPagination,
  makeSelectScopeByName,
  makeSelectFilterParams } from './selectors';
//////////////////////////////////////////////////

export function* handleChangeActiveListSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    yield put(A.resetListAndPagination());
    yield put(A.setActiveList(payload));
    yield put(A.getReferenceListRequest(true));
    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());
    yield call(G.handleException, err, 'handleChangeActiveListSaga exception');
  }
}

export function* handleSetFilterParams() {
  yield put(A.setFilterProps(getFilterParams));
}

export function* getReferenceTypesListRequestSaga({ payload }: boolean) {
  try {
    yield put(A.setListLoading(true));

    const activeList = yield select(makeSelectActiveList());
    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const searchCriteria = G.ifElse(
      G.isNilOrEmpty(filterParams),
      [],
      R.of(Array, filterParams),
    );

    const { limit, offset } = pagination;

    const options = {
      data: {
        limit,
        offset,
        [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
        searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap[activeList], options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({results: data, [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid}));
      yield put(A.setListLoading(false));
    } else {
      yield call(G.handleFailResponse, res, 'getReferenceTypesListRequestSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getReferenceTypesListRequestSaga exception');
  }
}

export function* getAllAvailableRefTypesByScopeRequestSaga({ payload }: Object) {
  try {
    let scope;
    let branchGuid;
    if (R.is(Object, payload)) {
      branchGuid = R.prop(GC.FIELD_BRANCH_GUID, payload);
      scope = yield select(makeSelectScopeByName, R.prop('scopeName', payload));
    } else {
      scope = yield select(makeSelectScopeByName, payload);
      branchGuid = yield select(makeSelectCurrentBranchGuid());
    }
    const scopeGuid = G.getOrElse(scope, 'guid', '');
    const scopeName = G.getOrElse(scope, 'name', '');
    const options = { params: { scopeGuid, [GC.FIELD_BRANCH_GUID]: branchGuid } };
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.availableReferenceTypesForScope,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getAllAvailableRefTypesByScopeRequestSuccess(data));
      yield put(A.setAllAvailableRefTypesByScopeName({ data, scopeName }));
    } else {
      yield call(G.handleFailResponse, res, 'getAllAvailableRefTypesByScopeRequestSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getAllAvailableRefTypesByScopeRequestSaga exception');
  }
}

export function* getAllAvailableCloRefTypesSaga() {
  try {
    const scope = yield select(makeSelectScopeByName, GC.REF_SCOPE_NAME_CLO);
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const scopeGuid = G.getOrElse(scope, 'guid', '');
    const scopeName = G.getOrElse(scope, 'name', '');
    const options = { params: { scopeGuid, [GC.FIELD_BRANCH_GUID]: branchGuid } };
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.availableReferenceTypesForScope,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.setAllAvailableRefTypesByScopeName({ data, scopeName }));
    } else {
      yield call(G.handleFailResponse, res, 'getAllAvailableCloRefTypes fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getAllAvailableCloRefTypes exception');
  }
}

export function* getAllAvailableTelRefTypesSaga() {
  try {
    const scope = yield select(makeSelectScopeByName, GC.REF_SCOPE_NAME_TEL);
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const scopeGuid = G.getOrElse(scope, 'guid', '');
    const scopeName = G.getOrElse(scope, 'name', '');
    const options = { params: { scopeGuid, [GC.FIELD_BRANCH_GUID]: branchGuid } };
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.availableReferenceTypesForScope,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.setAllAvailableRefTypesByScopeName({ data, scopeName }));
    } else {
      yield call(G.handleFailResponse, res, 'getAllAvailableTelRefTypes fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getAllAvailableTelRefTypes exception');
  }
}

export function* getReferenceScopesSaga() {
  try {
    const res = yield call(sendRequest, 'get', endpointsMap.referenceTypeScopes);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getReferenceScopesSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getReferenceScopesSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getReferenceScopesSaga exception');
  }
}

export function* handleCreateNewReferenceTypeRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const ownerBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        ...payload,
        [GC.FIELD_BRANCH_GUID]: ownerBranchGuid,
      },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.referenceType, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.resetListAndPagination());
      yield put(A.getReferenceListRequest(true));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateNewReferenceTypeRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateNewReferenceTypeRequest exception');
  }
}

export function* handleUpdateReferenceTypeRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      data: { ...payload },
    };
    const res = yield call(sendRequest, 'put', endpointsMap.referenceType, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.resetListAndPagination());
      yield put(A.getReferenceListRequest(true));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateReferenceTypeRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateReferenceTypeRequest exception');
  }
}

export function* handleDeleteReferenceTypeRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const guid = payload;
    const endpoint = yield call(endpointsMap.getCurrentReferenceTypeEndpoint, guid);
    const res = yield call(sendRequest, 'delete', endpoint);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.resetListAndPagination());
      yield put(A.getReferenceListRequest(true));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteReferenceTypeRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteReferenceTypeRequest exception');
  }
}

export function* handleChangeReferenceTypeOwnerRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { guid, entGuid } = payload;
    const options = {
      data: {
        referenceTypeGuid: guid,
        [GC.FIELD_BRANCH_GUID]: entGuid,
      },
    };
    const res = yield call(sendRequest, 'put', endpointsMap.referenceTypeChangeOwner, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.resetListAndPagination());
      yield put(A.getReferenceListRequest(true));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeReferenceTypeOwnerRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleChangeReferenceTypeOwnerRequest exception');
  }
}

export function* handlesetReferenceTypeGrantToBranchesRequest({ payload }: Object) {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        referenceTypeGuids: payload.guids,
        [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
        [GC.FIELD_BRANCH_GUIDS]: payload.entGuids,
      },
    };
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.grantToEnterpricesReferenceTypes,
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.resetListAndPagination());
      yield put(A.getReferenceListRequest(true));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handlesetReferenceTypeGrantToBranchesRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handlesetReferenceTypeGrantToBranchesRequest exception');
  }
}

export function* handleChangeReferenceTypeGrantRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { guid, entGuids } = payload;
    const endpoint = endpointsMap.getUngrantToEnterpricesReferenceTypeEndpoint(guid);
    const options = {
      data: { elements: entGuids },
    };
    const res = yield call(sendRequest, 'put', endpoint, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.resetListAndPagination());
      yield put(A.getReferenceListRequest(true));
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeReferenceTypeGrantRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleChangeReferenceTypeGrantRequest exception');
  }
}

function* handleUpdateLoadReferencesSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const { data, loadType, fromPage } = payload;
    const endpoint = G.ifElse(
      G.isLoadTypeClo(loadType),
      endpointsMap.saveCloReferences,
      endpointsMap.saveTelReferences,
    );
    const res = yield call(sendRequest, 'put', endpoint, { data });
    const { status } = res;
    if (G.isPageDispatchBoardNew(fromPage)) {
      const action = G.ifElse(
        G.isLoadTypeClo(loadType),
        getOrderDetailsRequest,
        getLoadDetailsRequest,
      );
      yield put(action(data.loadGuid));
    }
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateLoadReferencesSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateLoadReferencesSaga exception');
  }
}

export function* handleVisitReferencesListPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    const route = payload;
    const accessToPagesList = yield select(makeSelectAccessToPagesList());
    const isInitialDataLoaded = yield select(makeSelectInitialDataLoadedStatus());
    const isAllowed = R.includes(route, accessToPagesList);
    if (R.not(isInitialDataLoaded)) {
      yield race({
        fail: take(initialDataLoadFail),
        success: take(initialDataLoadSuccess),
      });
    }
    if (R.not(isAllowed)) {
      yield put({ type: GC.CHECK_VISIT_REFERENCE_TYPES_LIST_PAGE, payload: route });
      yield take(GC.CHECK_VISIT_REFERENCE_TYPES_LIST_PAGE_ALLOWED);
    }
    yield put(openLoader({ showDimmer: true }));
    yield put(A.setInitialState());
    yield call(getReferenceTypesListRequestSaga, { payload: true });
    yield call(getReferenceScopesSaga);
    yield put(cleanQuickFilter());
    yield call(handleSetFilterParams);
    yield put(closeLoader());
    break;
  }
}

export function* ReferenceWatcherSaga() {
  yield takeLatest(A.changeActiveList, handleChangeActiveListSaga);
  yield takeLatest(A.getReferenceListRequest, getReferenceTypesListRequestSaga);
  yield takeLatest(A.updateLoadReferencesRequest, handleUpdateLoadReferencesSaga);
  yield takeLatest(A.deleteReferenceTypeRequest, handleDeleteReferenceTypeRequest);
  yield takeLatest(A.updateReferenceTypeRequest, handleUpdateReferenceTypeRequest);
  yield takeLatest(A.getAllAvailableCloRefTypesRequest, getAllAvailableCloRefTypesSaga);
  yield takeLatest(A.getAllAvailableTelRefTypesRequest, getAllAvailableTelRefTypesSaga);
  yield takeLatest(A.createNewReferenceTypeRequest, handleCreateNewReferenceTypeRequest);
  yield takeLatest(GC.VISIT_REFERENCE_TYPES_LIST_PAGE, handleVisitReferencesListPageSaga);
  yield takeLatest(A.changeReferenceTypeGrantRequest, handleChangeReferenceTypeGrantRequest);
  yield takeLatest(A.changeReferenceTypeOwnerRequest, handleChangeReferenceTypeOwnerRequest);
  yield takeLatest(A.getAllAvailableRefTypesByScopeRequest, getAllAvailableRefTypesByScopeRequestSaga);
  yield takeLatest(A.setReferenceTypeGrantToBranchesRequest, handlesetReferenceTypeGrantToBranchesRequest);
}

export default ReferenceWatcherSaga;
