import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// icons
import * as I from '../../../svgs';
//////////////////////////////////////////////////

const EdiText = ({ ediFile }: Object) => {
  if (G.isString(ediFile)) {
    return (
      <Box width='100%'>{ediFile}</Box>
    );
  }
  if (G.isArray(ediFile)) {
    return (
      <Flex flexDirection='column'>
        {
          ediFile.map((item: string, index: number) => (
            <Box key={index} width='100%'>{item}</Box>
          ))
        }
      </Flex>
    );
  }
  return null;
};

const EdiContent = (props: Object) => {
  const { ediFile, ediData, branchGuid } = props;
  const sender = R.prop(GC.FIELD_EDI_SENDER, ediData);
  const fileUri = R.prop([GC.FIELD_EDI_FILE_URI], ediData);
  const receivedDate = R.prop(GC.FIELD_EDI_RECEIVED_DATE, ediData);
  const originalFileName = R.prop(GC.FIELD_EDI_ORIGINAL_FILE_NAME, ediData);

  return (
    <Box p={10}>
      <Flex mb={10}>
        <Flex mr={10}>
          <Box color={G.getTheme('colors.titleGrey')}>
            {`${G.getWindowLocale('titles:sender', 'Sender')}:`}
          </Box>
          <Box px='5px'>{sender}</Box>
        </Flex>
        <Flex mr={10}>
          <Box color={G.getTheme('colors.titleGrey')}>
            {`${G.getWindowLocale('titles:filename', 'Filename')}:`}
          </Box>
          <Box px='5px'>{originalFileName}</Box>
          <Box
            cursor='pointer'
            onClick={() => props.downloadEdiFileRequest({
              fileUri,
              branchGuid,
              action: 'download',
              fileName: originalFileName,
            })}
          >
            {I.downloadDocument(G.getTheme('colors.light.black'))}
          </Box>
        </Flex>
        <Flex>
          <Box color={G.getTheme('colors.titleGrey')}>
            {`${G.getWindowLocale('titles:received-date', 'Received Date')}:`}
          </Box>
          <Box px='5px'>{receivedDate}</Box>
        </Flex>
      </Flex>
      {
        ediFile && <EdiText ediFile={ediFile} />
      }
    </Box>
  );
};

export default EdiContent;
