import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
// utilities
import routeMap from '../../../utilities/routes';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const FooterComponent = (props: Object) => (
  <Flex
    py='18px'
    width='100%'
    flexDirection='column'
    justifyContent='center'
    border='none !important'
  >
    <Box
      cursor='pointer'
      color={G.getTheme('colors.light.blue')}
      onClick={(e: Object) => {
        G.goToRoute(routeMap.notificationList);
        props.actionHide(e);
      }}
    >
      {G.getWindowLocale('actions:view-all-notifications', 'View all notifications')}
    </Box>
  </Flex>
);

export default FooterComponent;
