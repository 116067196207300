import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature rate-shop
import ExcelResultsReport from '../components/excel-results';
//////////////////////////////////////////////////

const tableSettings = {
  minHeight: 320,
  cellFontSize: 12,
  titleFontSize: 12,
  titleRowHeight: 50,
  allowEditBtn: true,
  useMainColors: true,
  allowSelectAll: true,
  checkBoxCellWidth: 90,
  expandableItems: true,
  searchableTitles: true,
  tableRowHeight: 'max-content',
  checkBoxCellJustifyContent: 'unset',
  expandedDetailsComponent: ({ parentProps }: Object = {}) => (
    <ExcelResultsReport
      {...R.pathOr({}, ['excelResults'], parentProps)}
      status={G.getPropFromObject(GC.FIELD_STATUS, parentProps)}
      excelMassRatingSummaryGuid={G.getGuidFromObject(parentProps)}
    />
  ),
};

const excelResultsTableSettings = R.mergeRight(
  R.dissoc('expandedDetailsComponent', tableSettings),
  {
    maxHeight: '400px',
    allowSelectAll: false,
    expandableItems: false,
    checkBoxCellWidth: '0',
    allowSelectItems: false,
  },
);

const priceListTableSettings = {
  minHeight: 320,
  allowEditBtn: true,
  titleRowHeight: 30,
  tableRowHeight: 30,
  useMainColors: true,
  moreBtnCellWidth: 50,
  checkBoxCellWidth: 30,
  allowSelectAll: false,
  expandableItems: false,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 145px)',
};

export {
  tableSettings,
  priceListTableSettings,
  excelResultsTableSettings,
};
