import * as R from 'ramda';
import * as Yup from 'yup';
// features
import { validationSchemaCustomerRate } from '../rate/customer';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// schemes
import * as VS from '../../schemes';
//////////////////////////////////////////////////

// TODO: with full validation
const validationSchemaPickedUpContainerObject = {
  [GC.FIELD_CONTAINER_INITIAL]: Yup.string()
    .nullable(true)
    .max(40, G.getShouldBeFromToLocaleTxt(0, 40)),
  [GC.FIELD_CONTAINER_NUMBER]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(1, G.getShouldBeFromToLocaleTxt(1, 40))
    .max(40, G.getShouldBeFromToLocaleTxt(1, 40)),
  [GC.FIELD_FULL_CONTAINER_WEIGHT]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .typeError(G.getShouldBeNumericLocaleTxt())
    .positive(G.getShouldBePositiveLocaleTxt()),
  [GC.FIELD_WEIGHT_UOM]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
};

const validationSchemaItemBaseObject = {
  [GC.FIELD_ITEM_ID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(1, G.getShouldBeFromToLocaleTxt(1, 40))
    .max(40, G.getShouldBeFromToLocaleTxt(1, 40)),
  [GC.FIELD_ITEM_WEIGHT]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(0, G.getShouldBePositiveLocaleTxt())
    .typeError(G.getShouldBeNumericLocaleTxt())
    .lessThan(1000000, G.getShouldBeFromToLocaleTxt(0, 1000000)),
  [GC.FIELD_DESCRIPTION]: Yup.string()
    .nullable(true)
    .notRequired()
    // TODO: check why not required not working
    // .min(3, G.getShouldBeFromToLocaleTxt(3, 250))
    .max(250, G.getShouldBeFromToLocaleTxt(3, 250)),
  [GC.FIELD_ITEM_WEIGHT_TYPE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  // [GC.FIELD_ITEM_QUANTITY]: Yup.number()
  //   .nullable(true)
  //   .notRequired()
  //   .positive(G.getShouldBePositiveLocaleTxt())
  //   .lessThan(999999999, G.getShouldBeFromToLocaleTxt(1, 999999999)),
  // [GC.FIELD_ITEM_PACKAGE_TYPE]: Yup.string()
  //   .nullable(true)
  //   .notRequired(),
  // [GC.FIELD_ITEM_VOLUME]: Yup.number()
  //   .nullable(true)
  //   .notRequired()
  //   .positive(G.getShouldBePositiveLocaleTxt())
  //   .lessThan(99999, G.getShouldBeFromToLocaleTxt(1, 99999)),
  // [GC.FIELD_ITEM_VOLUME_UOM]: Yup.string()
  //   .nullable(true)
  //   .notRequired(),
  // [GC.FIELD_ITEM_DIMENSIONS_LENGTH]: Yup.number()
  //   .nullable(true)
  //   .notRequired()
  //   .positive(G.getShouldBePositiveLocaleTxt())
  //   .lessThan(99999, G.getShouldBeFromToLocaleTxt(1, 99999)),
  // [GC.FIELD_ITEM_DIMENSIONS_WIDTH]: Yup.number()
  //   .nullable(true)
  //   .notRequired()
  //   .positive(G.getShouldBePositiveLocaleTxt())
  //   .lessThan(99999, G.getShouldBeFromToLocaleTxt(1, 99999)),
  // [GC.FIELD_ITEM_DIMENSIONS_HEIGHT]: Yup.number()
  //   .nullable(true)
  //   .notRequired()
  //   .positive(G.getShouldBePositiveLocaleTxt())
  //   .lessThan(99999, G.getShouldBeFromToLocaleTxt(1, 99999)),
  // [GC.FIELD_ITEM_DIMENSIONS_UOM]: Yup.string()
  //   .nullable(true)
  //   .notRequired(),
  // [GC.FIELD_ITEM_MONETARY_VALUE]: Yup.number()
  //   .nullable(true)
  //   .notRequired()
  //   .positive(G.getShouldBePositiveLocaleTxt())
  //   .lessThan(9999999999, G.getShouldBeFromToLocaleTxt(1, 9999999999)),
  // [GC.FIELD_ITEM_CUSTOMER_PART_NUMBER]: Yup.string()
  //   .nullable(true)
  //   .notRequired()
  //   .min(3, G.getShouldBeFromToLocaleTxt(3, 40))
  //   .max(40, G.getShouldBeFromToLocaleTxt(3, 40)),
  // [GC.FIELD_ITEM_CUSTOMER_PART_NUMBER]: Yup.string()
  //   .nullable(true)
  //   .notRequired()
  //   .min(3, G.getShouldBeFromToLocaleTxt(3, 250))
  //   .max(250, G.getShouldBeFromToLocaleTxt(3, 250)),
};

const stringRequired = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

const itemRequiredObjectValidationSchemaMap = {
  [GC.FIELD_DESCRIPTION]: stringRequired,
  [GC.FIELD_ITEM_N_M_F_C]: stringRequired,
  [GC.FIELD_ITEM_COMMODITY]: stringRequired,
  [GC.FIELD_ORIGIN_OF_GOODS]: stringRequired,
  [GC.FIELD_ITEM_FREIGHT_CLASS]: stringRequired,
  [GC.FIELD_ITEM_TEMPERATURE_TYPE]: stringRequired,
  [GC.FIELD_ITEM_CUSTOMER_PART_NUMBER]: stringRequired,
  // volume
  [GC.FIELD_ITEM_VOLUME]: stringRequired,
  [GC.FIELD_ITEM_VOLUME_UOM]: stringRequired,
  // quantity
  [GC.FIELD_ITEM_QUANTITY]: stringRequired,
  [GC.FIELD_ITEM_PACKAGE_TYPE]: stringRequired,
  // stackability
  [GC.FIELD_ITEM_STACKABILITY]: stringRequired,
  [GC.FIELD_ITEM_STACKABILITY_CONDITION]: stringRequired,
  // temperature
  [GC.FIELD_ITEM_TEMPERATURE_LOW]: stringRequired,
  [GC.FIELD_ITEM_TEMPERATURE_UOM]: stringRequired,
  [GC.FIELD_ITEM_TEMPERATURE_HIGH]: stringRequired,
  [GC.FIELD_ITEM_TEMPERATURE_SENSOR]: stringRequired,
  // dimensions
  [GC.FIELD_ITEM_DIMENSIONS_UOM]: stringRequired,
  [GC.FIELD_ITEM_DIMENSIONS_WIDTH]: stringRequired,
  [GC.FIELD_ITEM_DIMENSIONS_LENGTH]: stringRequired,
  [GC.FIELD_ITEM_DIMENSIONS_HEIGHT]: stringRequired,
  // declared value
  [GC.FIELD_ITEM_DECLARED_VALUE]: stringRequired,
  [GC.FIELD_ITEM_DECLARED_CURRENCY]: stringRequired,
  // car hauler
  [GC.FIELD_VIN]: stringRequired,
  [GC.FIELD_BAY]: stringRequired,
  [GC.FIELD_YEAR]: stringRequired,
  [GC.FIELD_MAKE]: stringRequired,
  [GC.FIELD_MODEL]: stringRequired,
  [GC.FIELD_COLOR]: stringRequired,
  [GC.FIELD_SUB_MODEL]: stringRequired,
  [GC.FIELD_MANUFACTURER]: stringRequired,
  [GC.FIELD_ORIGIN_STATE]: stringRequired,
  [GC.FIELD_LICENSE_PLATE]: stringRequired,
  [GC.FIELD_ORIGIN_COUNTRY]: stringRequired,
  //
  [GC.FIELD_ODOMETER]: stringRequired,
  [GC.FIELD_ODOMETER_UOM]: stringRequired,
  //
  [GC.FIELD_WHEELBASE]: stringRequired,
  [GC.FIELD_WHEELBASE_UOM]: stringRequired,
};

const itemRequiredMultipleFieldsMap = {
  [GC.FIELD_ITEM_TEMPERATURE_LOW]: [
    GC.FIELD_ITEM_TEMPERATURE_LOW,
    GC.FIELD_ITEM_TEMPERATURE_UOM,
    GC.FIELD_ITEM_TEMPERATURE_HIGH,
  ],
  [GC.FIELD_ITEM_DIMENSIONS_LENGTH]: [
    GC.FIELD_ITEM_DIMENSIONS_UOM,
    GC.FIELD_ITEM_DIMENSIONS_LENGTH,
    GC.FIELD_ITEM_DIMENSIONS_WIDTH,
    GC.FIELD_ITEM_DIMENSIONS_HEIGHT,
  ],
  [GC.FIELD_ITEM_VOLUME]: [GC.FIELD_ITEM_VOLUME, GC.FIELD_ITEM_VOLUME_UOM],
  [GC.FIELD_ITEM_QUANTITY]: [GC.FIELD_ITEM_QUANTITY, GC.FIELD_ITEM_PACKAGE_TYPE],
  [GC.FIELD_ITEM_DECLARED_VALUE]: [GC.FIELD_ITEM_DECLARED_VALUE, GC.FIELD_ITEM_DECLARED_CURRENCY],
  [GC.FIELD_ITEM_STACKABILITY]: [GC.FIELD_ITEM_STACKABILITY, GC.FIELD_ITEM_STACKABILITY_CONDITION],
  [GC.FIELD_ITEM_MONETARY_VALUE]: [GC.FIELD_ITEM_MONETARY_VALUE, GC.FIELD_ITEM_MONETARY_VALUE_CURRENCY],
  // car hauler
  [GC.FIELD_ODOMETER]: [GC.FIELD_ODOMETER, GC.FIELD_ODOMETER_UOM],
  [GC.FIELD_WHEELBASE]: [GC.FIELD_WHEELBASE, GC.FIELD_WHEELBASE_UOM],
};

const getValidationSchemaPickupItems = (props: Object) => {
  const { isOrderQuote, branchConfigs } = props;

  const requiredConfig = G.ifElse(
    G.isTrue(isOrderQuote),
    GC.CLO_QUOTE_ITEM_REQUIRED_FIELDS,
    GC.CLO_ITEM_REQUIRED_FIELDS,
  );
  const requiredFields = G.getConfigValueFromStore(requiredConfig, branchConfigs);

  let schema = validationSchemaItemBaseObject;

  R.forEach((item: Object) => {
    const multipleFields = itemRequiredMultipleFieldsMap[item];

    if (G.isArray(multipleFields)) {
      return R.forEach((item: Object) => {
        const fromMapSchema = itemRequiredObjectValidationSchemaMap[item];

        if (fromMapSchema) schema = R.assoc(item, fromMapSchema, schema);
      }, multipleFields);
    }

    const fromMapSchema = itemRequiredObjectValidationSchemaMap[item];

    if (fromMapSchema) schema = R.assoc(item, fromMapSchema, schema);
  }, requiredFields);

  return {
    [GC.FIELD_LOAD_ITEMS]: Yup.array()
      .of(Yup.object(schema))
      .nullable(true)
      .notRequired(),
  };
};

const validationSchemaPickupItems = {
  [GC.FIELD_LOAD_ITEMS]: Yup.array()
    .of(Yup.object(validationSchemaItemBaseObject))
    .nullable(true)
    .notRequired(),
};

const validationSchemaPickupContainers = {
  [GC.FIELD_STOP_PICKED_UP_CONTAINERS]: Yup.array()
    .of(Yup.object(validationSchemaPickedUpContainerObject))
    .nullable(true)
    .notRequired(),
};

const validationSchemaContacts = {
  [GC.FIELD_CONTACTS]: Yup.array()
    .of(Yup.object(VS.validationSchemaContactObject))
    .nullable(true)
    .notRequired(),
};

export const validationSchemaSingleContainerForm = Yup.object()
  .shape(validationSchemaPickupContainers);

const validationSchemaDropItems = {
  [GC.FIELD_LOAD_ITEMS]: G.yupArrayRequired,
};

const validationSchemaReferenceObject = {
  [GC.FIELD_REFERENCE_TYPE_GUID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_VALUE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(1, G.getShouldBeFromToLocaleTxt(1, 250))
    .max(250, G.getShouldBeFromToLocaleTxt(1, 250)),
};

const validationSchemaReferences = {
  [GC.FIELD_LOAD_REFERENCES]: Yup.array()
    .of(Yup.object(validationSchemaReferenceObject))
    .nullable(true)
    .notRequired(),
};

const getValidationDateTimeObject = (values: Object, branchConfigs: Object) => {
  const separate = G.getConfigValueFromStoreOrWindow(GC.CLO_UI_SEPARATE_EVENT_DATE_AND_TIME, branchConfigs);
  const separateSchema = G.ifElse(
    R.pathEq(true, [GC.FIELD_LOAD_FCFS], values),
    VS.validationEventDateTimeObject5,
    VS.validationEventDateTimeObject4,
  );

  return G.ifElse(
    G.isTrue(separate),
    separateSchema,
    VS.validationEventDateTimeObject2,
  );
};

export const validationSchemaStopForm = Yup.object()
  .shape(VS.validationSchemaLocationTypeObject)
  .shape(VS.validationSchemaLocationObject2)
  .shape(VS.validationSchemaLatLngObject)
  .shape(validationSchemaContacts)
  .shape(VS.validationSchemaCommentObject)
  .shape(VS.validationSchemaFaxObject)
  .shape(validationSchemaReferences)
  .shape(VS.validationEventDateTimeObject2);

export const validationSchemaPickupForm = Yup.object()
  .shape(VS.validationSchemaLocationTypeObject)
  .shape(VS.validationSchemaLocationObject2)
  .shape(VS.validationSchemaLatLngObject)
  .shape(VS.validationSchemaContactObject)
  .shape(VS.validationSchemaCommentObject)
  .shape(VS.validationSchemaFaxObject)
  .shape(VS.validationEventDateTimeObject2)
  .shape(validationSchemaPickupItems)
  .shape(validationSchemaReferences)
  .shape(validationSchemaPickupContainers);

export const getValidationSchemaPickupForm = (values: Object, props: any) => {
  const isOrderQuote = G.getPropFromObject('isOrderQuote', props);
  const branchConfigs = R.or(
    G.getPropFromObject('branchConfigs', props),
    G.getItemFromWindow('amousNewDoBranchConfigs'),
  );

  const validationSchemaPickupItems = getValidationSchemaPickupItems({ isOrderQuote, branchConfigs });
  const dateTimeSchema = getValidationDateTimeObject(values, branchConfigs);

  return Yup.object()
    .shape(dateTimeSchema)
    .shape(VS.validationSchemaLocationTypeObject)
    .shape(VS.validationSchemaLocationObject2)
    .shape(VS.validationSchemaLatLngObject)
    .shape(validationSchemaContacts)
    .shape(VS.validationSchemaCommentObject)
    .shape(VS.validationSchemaFaxObject)
    .shape(validationSchemaPickupItems)
    .shape(validationSchemaReferences)
    .shape(validationSchemaPickupContainers);
};

export const getValidationSchemaQuotePickupForm = (values: Object, props: any) => {
  const isOrderQuote = G.getPropFromObject('isOrderQuote', props);
  const branchConfigs = R.or(
    G.getPropFromObject('branchConfigs', props),
    G.getItemFromWindow('amousNewDoBranchConfigs'),
  );

  const validationSchemaPickupItems = getValidationSchemaPickupItems({ isOrderQuote, branchConfigs });
  const dateTimeSchema = getValidationDateTimeObject(values, branchConfigs);

  return Yup.object()
    .shape(dateTimeSchema)
    .shape(VS.validationSchemaLocationTypeObject)
    .shape(VS.validationSchemaQuoteLocationObject)
    .shape(VS.validationSchemaLatLngObject)
    .shape(validationSchemaContacts)
    .shape(VS.validationSchemaCommentObject)
    .shape(VS.validationSchemaFaxObject)
    .shape(validationSchemaPickupItems);
};

export const getValidationSchemaPickupFormLazy = (props: Object) => Yup.lazy((values: Object) => (
  getValidationSchemaPickupForm(values, props)
));

export const getValidationSchemaQuotePickupFormLazy = (props: Object) => Yup.lazy((values: Object) => (
  getValidationSchemaQuotePickupForm(values, props)
));

export const validationSchemaDropForm = Yup.object()
  .shape(VS.validationSchemaLocationTypeObject)
  .shape(VS.validationSchemaLocationObject2)
  .shape(VS.validationSchemaLatLngObject)
  .shape(VS.validationSchemaContactObject)
  .shape(VS.validationSchemaCommentObject)
  .shape(VS.validationSchemaFaxObject)
  .shape(VS.validationEventDateTimeObject2)
  .shape(validationSchemaReferences)
  .shape(validationSchemaDropItems);

export const validationSchemaDropForm2 = Yup.object()
  .shape(VS.validationSchemaLocationTypeObject)
  .shape(VS.validationSchemaLocationObject2)
  .shape(VS.validationSchemaLatLngObject)
  .shape(VS.validationSchemaContactObject)
  .shape(VS.validationSchemaCommentObject)
  .shape(VS.validationSchemaFaxObject)
  .shape(validationSchemaReferences)
  .shape(VS.validationEventDateTimeObject2);

export const getValidationSchemaDropForm = (values: Object) => {
  const branchConfigs = G.getItemFromWindow('amousNewDoBranchConfigs');
  const useContainers = G.getConfigValueFromStore(GC.CLO_GENERAL_USE_CONTAINERS, branchConfigs);
  const dateTimeSchema = getValidationDateTimeObject(values, branchConfigs);

  if (G.isTrue(useContainers)) {
    return Yup.object()
      .shape(dateTimeSchema)
      .shape(VS.validationSchemaLocationTypeObject)
      .shape(VS.validationSchemaLocationObject2)
      .shape(VS.validationSchemaLatLngObject)
      .shape(validationSchemaContacts)
      .shape(VS.validationSchemaCommentObject)
      .shape(validationSchemaReferences)
      .shape(VS.validationSchemaFaxObject);
  }

  return Yup.object()
    .shape(dateTimeSchema)
    .shape(VS.validationSchemaLocationTypeObject)
    .shape(VS.validationSchemaLocationObject2)
    .shape(VS.validationSchemaLatLngObject)
    .shape(validationSchemaContacts)
    .shape(VS.validationSchemaCommentObject)
    .shape(VS.validationSchemaFaxObject)
    .shape(validationSchemaReferences)
    .shape(validationSchemaDropItems);
};

export const getValidationSchemaQuoteDropForm = (values: Object) => {
  const branchConfigs = G.getItemFromWindow('amousNewDoBranchConfigs');
  const dateTimeSchema = getValidationDateTimeObject(values, branchConfigs);

  return Yup.object()
    .shape(dateTimeSchema)
    .shape(VS.validationSchemaLocationTypeObject)
    .shape(VS.validationSchemaQuoteLocationObject)
    .shape(VS.validationSchemaLatLngObject)
    .shape(validationSchemaContacts)
    .shape(VS.validationSchemaCommentObject)
    .shape(VS.validationSchemaFaxObject)
    .shape(validationSchemaDropItems);
};

export const getValidationSchemaDropFormLazy = () => Yup.lazy((values: Object) => getValidationSchemaDropForm(values));

export const getValidationSchemaQuoteDropFormLazy = () => Yup.lazy(
  (values: Object) => getValidationSchemaQuoteDropForm(values),
);

export const validationSchemaBillToForm = Yup.object()
  .shape(VS.validationSchemaPayTermObject)
  .shape(VS.validationSchemaLocationTypeObject)
  .shape(VS.validationSchemaLocationObject)
  .shape(VS.validationSchemaContactObject)
  .shape(VS.validationSchemaFaxObject);

export const validationSchemaReferencesForm = Yup.object()
  .shape(VS.validationSchemaSpecialInstructionsObject)
  .shape(VS.validationSchemaInternalInstructionsObject)
  .shape(validationSchemaReferences);

export const validationSchemaStopReferencesForm = Yup.object()
  .shape(validationSchemaReferences);

export const isPickupFormValid = (formValues: Object, props: Object) => getValidationSchemaPickupForm(
  formValues, props).isValidSync(G.mapObjectEmptyStringFieldsToNull(formValues),
);

export const isQuotePickupFormValid = (formValues: Object, props: Object) => getValidationSchemaQuotePickupForm(
  formValues, props).isValidSync(G.mapObjectEmptyStringFieldsToNull(formValues),
);

export const isDropFormValid = (formValues: Object, props: Object) => getValidationSchemaDropForm(
  formValues, props).isValidSync(G.mapObjectEmptyStringFieldsToNull(formValues),
);

export const isQuoteDropFormValid = (formValues: Object, props: Object) => getValidationSchemaQuoteDropForm(
  formValues, props).isValidSync(G.mapObjectEmptyStringFieldsToNull(formValues),
);

export const isValidCustomerRateForm = (formValues: Object) => validationSchemaCustomerRate.isValidSync(
  G.mapObjectEmptyStringFieldsToNull(formValues),
);

export const isValidBillToForm = (formValues: Object) => validationSchemaBillToForm.isValidSync(
  G.mapObjectEmptyStringFieldsToNull(formValues),
);

export const isValidReferencesForm = (formValues: Object) => validationSchemaReferencesForm.isValidSync(formValues);

export const isValidStopReferencesForm = (formValues: Object) => validationSchemaStopReferencesForm.isValidSync(
  G.mapObjectEmptyStringFieldsToNull(formValues));
