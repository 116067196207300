import React from 'react';
import * as R from 'ramda';
// components
import { ActionBox } from '../../../components/action-box';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature payroll
import FuelCardDetails from '../components/fuel-card-details';
//////////////////////////////////////////////////

const customDriverComponent = (props: Object) => {
  const { data, callbackData } = props;

  const { driver } = data;

  const { firstLastNames } = G.getUserInfo(data.driver);

  const { handleOpenDriverProfile } = callbackData;

  return (
    <ActionBox
      text={firstLastNames}
      action={() => handleOpenDriverProfile(G.getGuidFromObject(driver))}
    />
  );
};

// fuel

const fuelCardsTableSettings = {
  tableRowHeight: 40,
  titleRowHeight: 45,
  allowEditBtn: false,
  allowSelectAll: true,
  checkBoxCellWidth: 70,
  expandableItems: true,
  searchableTitles: true,
  expandedDetailsComponent: FuelCardDetails,
};

const fuelCardsNoActionsTableSettings = {
  tableRowHeight: 40,
  titleRowHeight: 40,
  checkBoxCellWidth: 25,
  expandableItems: true,
  allowSelectItems: false,
  expandedDetailsComponent: FuelCardDetails,
};

const fuelCardsColumnSettings = {
  [GC.FIELD_FUEL_CARDS_TRANSACTION_DATE]: {
    type: 'date',
    filter: { type: 'date' },
    name: 'titles:transaction-date',
  },
  [GC.FIELD_DRIVER]: {
    width: 200,
    name: 'titles:driver',
    customComponent: customDriverComponent,
  },
  [`${GC.FIELD_TRUCK}.${GC.FIELD_UNIT_ID}`]: {
    width: 150,
    name: ['titles:truck', 'titles:unit-id'],
  },
  [GC.FIELD_TRIP_ID]: {
    width: 150,
    name: 'titles:trip-id',
  },
  [GC.FIELD_TOTAL]: {
    width: 150,
    name: 'titles:total',
    filter: { type: 'number' },
  },
  [GC.FIELD_CURRENCY]: {
    width: 150,
    name: 'titles:currency',
    filter: { type: 'selectMultiple', options: GC.CURRENCY_OPTIONS_2 },
  },
  [`${GC.FIELD_FUEL_CARDS_TRANSACTION_STOP}.${GC.FIELD_LOCATION_NAME}`]: {
    width: 150,
    name: 'titles:location-name',
  },
  [`${GC.FIELD_FUEL_CARDS_TRANSACTION_STOP}.${GC.FIELD_CITY}`]: {
    width: 150,
    name: 'titles:city',
  },
  [`${GC.FIELD_FUEL_CARDS_TRANSACTION_STOP}.${GC.FIELD_STATE}`]: {
    width: 150,
    name: 'titles:state',
  },
  [GC.FIELD_FUEL_CARDS_SOURCE]: {
    width: 150,
    name: 'titles:source',
  },
  [GC.FIELD_FUEL_CARDS_CARD_ID]: {
    width: 150,
    name: 'titles:card-id',
  },
  [GC.FIELD_FUEL_CARDS_TRANSACTION_NUMBER]: {
    width: 200,
    name: 'titles:transaction-number',
  },
};

const fuelCardsReport = {
  fields: [
    { freezed: true, name: 'removeRestoreColumn' },
    ...G.getOnlyReportFieldsFromColumnSettings(fuelCardsColumnSettings),
  ],
};

const getFuelCardsReport = (omitFields: Array = [], noActions: boolean = false) => {
  const fields = G.getOnlyReportFieldsFromColumnSettings(R.omit(omitFields, fuelCardsColumnSettings));

  if (noActions) return { fields };

  return {
    fields: [
      { freezed: true, name: 'removeRestoreColumn' },
      ...fields,
    ],
  };
};

// toll
const tollTableSettings = {
  tableRowHeight: 40,
  titleRowHeight: 45,
  allowSelectAll: true,
  searchableTitles: true,
};

const tableSettingsNoActions = {
  tableRowHeight: 40,
  titleRowHeight: 40,
  checkBoxCellWidth: 0,
  allowSelectItems: false,
};

const tollColumnSettings = {
  [GC.FIELD_TOLL_CHARGES_POSITING_DATE]: {
    filter: { type: 'date' },
    name: 'titles:posting-date',
  },
  [GC.FIELD_TOLL_CHARGES_AMOUNT]: {
    width: 150,
    name: 'titles:amount',
    filter: { type: 'number' },
  },
  [GC.FIELD_CURRENCY]: {
    width: 150,
    name: 'titles:currency',
    options: GC.CURRENCY_OPTIONS_2,
    filter: { type: 'selectMultiple', options: GC.CURRENCY_OPTIONS_2 },
  },
  [GC.FIELD_TOLL_CHARGES_LOCATION_NAME]: {
    width: 200,
    name: 'titles:location-name',
  },
  [GC.FIELD_TOLL_CHARGES_AGENCY]: {
    width: 150,
    name: 'titles:agency',
  },
  [GC.FIELD_TOLL_CHARGES_LICENCE_PLATE]: {
    width: 150,
    name: 'titles:license-plate',
  },
  [`${GC.FIELD_TRUCK}.${GC.FIELD_UNIT_ID}`]: {
    width: 150,
    name: ['titles:truck', 'titles:unit-id'],
  },
  [GC.FIELD_DRIVER]: {
    width: 200,
    name: 'titles:driver',
    customComponent: customDriverComponent,
  },
  [GC.FIELD_TOLL_CHARGES_TRANSPONDER_ID]: {
    width: 150,
    name: 'titles:transponder-id',
  },
  [GC.FIELD_TOLL_CHARGES_TRANSACTION_DATE]: {
    filter: { type: 'date' },
    name: 'titles:transaction-date',
  },
  [GC.FIELD_TOLL_CHARGES_TRANSACTION_TYPE]: {
    width: 150,
    name: 'titles:transaction-type',
  },
  [GC.FIELD_TOLL_CHARGES_SOURCE]: {
    width: 150,
    name: 'titles:source',
  },
};

const tollReport = {
  fields: [
    { freezed: true, name: 'removeRestoreColumn' },
    ...G.getOnlyReportFieldsFromColumnSettings(tollColumnSettings),
  ],
};

const getTollReport = (omitFields: Array = [], noActions: boolean = false) => {
  const fields = G.getOnlyReportFieldsFromColumnSettings(R.omit(omitFields, tollColumnSettings));

  if (noActions) return { fields };

  return {
    fields: [
      { freezed: true, name: 'removeRestoreColumn' },
      ...fields,
    ],
  };
};

export {
  // fuel
  fuelCardsReport,
  getFuelCardsReport,
  fuelCardsTableSettings,
  fuelCardsColumnSettings,
  fuelCardsNoActionsTableSettings,
  // toll
  tollReport,
  getTollReport,
  tollTableSettings,
  tollColumnSettings,
  tableSettingsNoActions,
};
