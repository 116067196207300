import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ActionsElement } from '../../../components/actions-element';
import { openModal, closeModal } from '../../../components/modal/actions';
// features
import PC from '../../permission/role-permission';
import { EmailSendingQuote } from '../../mail-sending';
import { setExpandedContainerOptions } from '../../expanded-container/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// utilities
import routesMap from '../../../utilities/routes';
// feature quotes
import { removeQuotesRequest, sendOrderQuoteToCustomerRequest } from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  connect(
    null,
    {
      openModal,
      closeModal,
      removeQuotesRequest,
      setExpandedContainerOptions,
      sendOrderQuoteToCustomerRequest,
    },
  ),
  withHandlers({
    handleSendToCustomer: (props: Object) => () => {
      const { entity, openModal, closeFixedPopup, sendOrderQuoteToCustomerRequest } = props;

      closeFixedPopup();
      const customHandleSubmit = (values: Object) => {
        sendOrderQuoteToCustomerRequest(R.mergeRight(
          values,
          {
            [GC.FIELD_GUID]: G.getGuidFromObject(entity),
            [GC.FIELD_MAIL_TO]: G.getPropFromObject(GC.FIELD_TO, values),
          },
        ));
      };
      const modal = {
        component: (
          <EmailSendingQuote
            isMulti={false}
            customHandleSubmit={customHandleSubmit}
            branchGuid={G.getAmousCurrentBranchGuidFromWindow()}
            hiddenFields={[GC.FIELD_MAIL_SENDING_ATTACHED_DOCUMENT_GUIDS]}
          />
        ),
        options: {
          height: 'auto',
          boxShadow: 'none',
          width: 'max-content',
          title: G.getWindowLocale('titles:new-message', 'New Message'),
        },
        p: '0px',
      };

      openModal(modal);
    },
    handleEdit: (props: Object) => () => {
      const { entity, closeFixedPopup, setExpandedContainerOptions} = props;

      closeFixedPopup();

      const guid = G.getGuidFromObject(entity);
      const componentType = GC.PAGE_NEW_DO_QUOTE_EDIT;

      return setExpandedContainerOptions({ componentType, opened: true, visitPageGuid: guid });
    },
  }),
  pure,
);

const getOptions = (props: Object) => {
  const {
    entity,
    listType,
    handleEdit,
    handlePrint,
    handleDelete,
    handleGetXmlRequest,
    handleSendToCustomer,
  } = props;

  const quoteGuid = G.getGuidFromObject(entity);
  const iconColor = G.getTheme('icons.iconColor');
  const status = G.getPropFromObject(GC.FIELD_STATUS, entity);
  const writePermission = G.ifElse(R.equals(listType, GC.FIELD_QUOTES), PC.CLO_WRITE, PC.ORDER_QUOTE_WRITE);
  const removeAction = {
    renderAction: true,
    permissions: [writePermission],
    frontIcon: I.trash(iconColor),
    action: () => handleDelete(quoteGuid),
    text: G.getWindowLocale('actions:delete', 'Delete'),
  };
  let options = R.of(Array, removeAction);

  if (R.equals(status, GC.LOAD_STATUS_BOOKED_STATUS)) {
    const createCloAction = {
      permissions: [writePermission],
      frontIcon: I.routesLoads(iconColor),
      text: G.getWindowLocale('actions:create-clo', 'Create CLO'),
      action: () => {
        const options = {
          [GC.FIELD_GUID]: quoteGuid,
          [GC.FIELD_SOURCE_TYPE]: GC.CREATE_DO_SOURCE_TYPE_QUOTE,
        };

        G.goToRoute(routesMap.newDOBySourceType(options));
      },
    };

    options = R.append(createCloAction, options);
  }

  if (R.equals(listType, GC.FIELD_ORDER_QUOTES)) {
    const orderQuoteActions = [
      {
        action: handleSendToCustomer,
        permissions: [writePermission],
        frontIcon: I.renderMailIcon(iconColor),
        text: G.getWindowLocale('actions:send-to-customer', 'Send to Customer'),
        renderAction: R.includes(
          status,
          [GC.ORDER_QUOTE_STATUS_TYPE_QUOTED, GC.ORDER_QUOTE_STATUS_TYPE_SENT_TO_CUSTOMER],
        ),
      },
      {
        frontIcon: I.routesLoads(iconColor),
        permissions: [PC.CLO_WRITE, PC.ORDER_QUOTE_WRITE],
        text: G.getWindowLocale('actions:create-clo', 'Create CLO'),
        renderAction: R.equals(status, GC.ORDER_QUOTE_STATUS_TYPE_ACCEPTED),
        action: () => {
          const options = {
            [GC.FIELD_GUID]: quoteGuid,
            [GC.FIELD_SOURCE_TYPE]: GC.CREATE_DO_SOURCE_TYPE_ORDER_QUOTE,
          };

          G.goToRoute(routesMap.newDOBySourceType(options));
        },
      },
      {
        renderAction: true,
        action: () => handleGetXmlRequest(quoteGuid),
        permissions: [PC.ORDER_QUOTE_READ, PC.ORDER_QUOTE_WRITE],
        text: G.getWindowLocale('actions:get-xml-file', 'Get XML File'),
        frontIcon: I.downloadDocument(iconColor, 15, 15),
      },
      {
        renderAction: true,
        frontIcon: I.printer(iconColor),
        action: () => handlePrint(quoteGuid),
        text: G.getWindowLocale('actions:print', 'Print'),
        permissions: [PC.ORDER_QUOTE_READ, PC.ORDER_QUOTE_WRITE],
      },
      {
        frontIcon: I.pencil(iconColor),
        action: () => handleEdit(props),
        permissions: [PC.ORDER_QUOTE_WRITE],
        text: G.getWindowLocale('actions:edit', 'Edit'),
        renderAction: G.notEquals(status, GC.ORDER_QUOTE_STATUS_TYPE_PROCESSED),
      },
      removeAction,
    ];

    options = R.filter(R.prop('renderAction'), orderQuoteActions);
  }

  return options;
};

export const RowActions = enhance((props: Object) => (
  <ActionsElement entity={props.entity} options={getOptions(props)} />
));
