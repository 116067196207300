import * as R from 'ramda';
import React from 'react';
import { compose, withHandlers } from 'react-recompose';
// components
import { Map } from '../../../components/map';
import { Directions } from '../../../components/map/components/directions';
import { MarkerWithInfo } from '../../../components/map/components/marker-with-info';
import { StopInfo, StopMarker } from '../../../components/map/components/stop-components';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withRerenderComponent } from '../../../hocs';
// ui
import { Box } from '../../../ui';
// feature new-do
import Tabs from '../components/tabs';
import { TAB_NAME_MAP, TAB_NAME_CLO_SUMMARY } from '../constants';
import StopsSummaryWithDnD from '../components/stops-summary-with-dnd';
//////////////////////////////////////////////////

const tabSettings = [
  {
    name: TAB_NAME_CLO_SUMMARY,
    text: G.getWindowLocale('titles:clo-summary', 'CLO Summary'),
  },
  {
    name: TAB_NAME_MAP,
    text: G.getWindowLocale('titles:map', 'Map'),
  },
];

const getLocationsWithContent = (locations: Array) => (
  locations.map((oldLocation: Object) => {
    const location = R.clone(oldLocation);
    const eventEarlyDate = R.path(['stop', 'formData', GC.FIELD_LOAD_EVENT_EARLY_DATE], location);
    location.infoContent = (
      <StopInfo {...location} eventEarlyDate={eventEarlyDate} />
    );
    location.markerContent = <StopMarker {...location} />;
    return location;
  })
);

const enhanceMap = compose(
  withRerenderComponent(['mapLocations']),
);

const MapLocations = enhanceMap((props: Object) => {
  const { mapLocations, showComponent } = props;
  const center = R.path(['latLng'], R.head(mapLocations));

  return (
    <Box>
      <Map
        center={center}
        trafficLayer={true}
        height='calc(100vh - 200px)'
      >
        {
          showComponent &&
          <MarkerWithInfo locations={G.makeLocationsWithTransform(getLocationsWithContent(mapLocations))} />
        }
        {
          showComponent &&
          <Directions locations={mapLocations.map((location: Object) => location.latLng)} />
        }
      </Map>
    </Box>
  );
});

const renderTabContent = (props: Object) => {
  const { rightActiveTad } = props;
  if (R.equals(rightActiveTad, TAB_NAME_CLO_SUMMARY)) {
    return (
      <Box>
        <StopsSummaryWithDnD {...props} />
      </Box>
    );
  }
  if (R.equals(rightActiveTad, TAB_NAME_MAP)) {
    return <MapLocations {...props} />;
  }
  return null;
};

const RightSection = (props: Object) => (
  <Box
    mb='20px'
    width='662px'
    flexShrink={0}
    borderRadius='4px'
    bg={G.getTheme('colors.white')}
  >
    <Tabs
      overflow='auto'
      options={tabSettings}
      activeFromParent={props.rightActiveTad}
      defaultActiveName={TAB_NAME_CLO_SUMMARY}
      onClickTabCallback={props.onClickTabCallback} />
    {renderTabContent(props)}
  </Box>
);

const enhance = compose(
  withHandlers({
    onClickTabCallback: ({ setActiveRightTab }: Object) => (activeName: Object) => {
      setActiveRightTab(activeName);
    },
  }),
);

export default enhance(RightSection);
