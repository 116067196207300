import * as R from 'ramda';
import React, { useState, Fragment } from 'react';
// components
import { Table } from '../../../components/table';
import TextComponent from '../../../components/text';
import ToggleBtn from '../../../components/toggle-btn';
import { LocalLoader } from '../../../components/local-loader';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, StickedBox, MainActionButton } from '../../../ui';
// feature payrolls
import useStatistic from '../hooks/use-statistic';
import { renderInvoices } from '../components/payroll-invoices';
//////////////////////////////////////////////////

const getReport = (charges: Array) => {
  const fields = [
    { sequence: 0, name: GC.FIELD_FLEET_TRUCK_UNIT_ID },
    { sequence: 1, name: GC.FIELD_DRIVERS },
    { sequence: 2, name: GC.FIELD_CUSTOMER_TOTAL },
    { sequence: 3, name: GC.FIELD_MARGIN },
    { sequence: 4, name: GC.FIELD_TRIP_TOTAL },
    { sequence: 5, name: GC.FIELD_TOTAL_DEADHEAD_DISTANCE },
    { sequence: 6, name: GC.FIELD_TOTAL_TRIP_DISTANCE },
    { sequence: 7, name: GC.FIELD_TOTAL_DISTANCE },
    { sequence: 8, name: GC.FIELD_RATE_PER_DISTANCE_UNIT },
    { sequence: 9, name: GC.FIELD_TOLL_TOTAL },
    { sequence: 10, name: GC.FIELD_FUEL_TOTAL },
    { sequence: 11, name: GC.FIELD_DRIVER_PAY },
    { sequence: 12, name: GC.FIELD_PROFIT },
  ];

  const chargeFields = charges.map((charge: string, i: number) => ({
    name: `sumByAssessorial.${charge}`,
    sequence: R.add(R.length(fields), i),
  }));

  return { fields: R.concat(fields, chargeFields) };
};

const getColumnSettings = (charges: Array) => {
  const settings = {
    [GC.FIELD_FLEET_TRUCK_UNIT_ID]: {
      width: 200,
      name: ['titles:truck', 'titles:unit-id'],
    },
    [GC.FIELD_DRIVERS]: {
      width: 300,
      name: 'titles:drivers',
      customComponent: ({ data: { drivers } }: Object) => {
        if (G.isNilOrEmpty(drivers)) return null;

        const text = G.createStringFromArray(
          R.map(({ lastName, firstName }: Object) => `${firstName} ${lastName}`, drivers),
          ', ',
        );

        return (
          <TextComponent title={text} display='block' maxWidth='100%' withEllipsis={true}>
            {text}
          </TextComponent>
        );
      },
    },
    [GC.FIELD_CUSTOMER_TOTAL]: {
      width: 150,
      type: GC.FIELD_CURRENCY,
      name: 'titles:customer-total',
    },
    [GC.FIELD_MARGIN]: {
      width: 150,
      name: 'titles:margin',
      type: GC.FIELD_CURRENCY,
    },
    [GC.FIELD_TRIP_TOTAL]: {
      width: 150,
      type: GC.FIELD_CURRENCY,
      name: 'titles:trip-total',
    },
    [GC.FIELD_TOTAL_DEADHEAD_DISTANCE]: {
      width: 150,
      withoutConversion: true,
      type: GC.FIELD_DISTANCE,
      name: 'titles:deadhead-distance',
    },
    [GC.FIELD_TOTAL_TRIP_DISTANCE]: {
      width: 150,
      withoutConversion: true,
      type: GC.FIELD_DISTANCE,
      name: 'titles:total-trip-distance',
    },
    [GC.FIELD_TOTAL_DISTANCE]: {
      width: 150,
      withoutConversion: true,
      type: GC.FIELD_DISTANCE,
      name: 'titles:total-distance',
    },
    [GC.FIELD_RATE_PER_DISTANCE_UNIT]: {
      width: 150,
      type: GC.FIELD_CURRENCY,
      name: G.ifElse(G.isImperialUomSystem(), 'titles:rate-per-mile', 'titles:rate-per-kilometer'),
    },
    [GC.FIELD_TOLL_TOTAL]: {
      width: 150,
      type: GC.FIELD_CURRENCY,
      name: 'titles:toll-charges',
    },
    [GC.FIELD_FUEL_TOTAL]: {
      width: 150,
      type: GC.FIELD_CURRENCY,
      name: 'titles:fuel-charges',
    },
    [GC.FIELD_DRIVER_PAY]: {
      width: 150,
      type: GC.FIELD_CURRENCY,
      name: 'titles:total-driver-pay',
    },
    [GC.FIELD_PROFIT]: {
      width: 150,
      name: 'titles:profit',
      type: GC.FIELD_CURRENCY,
    },
  };

  const chargeSettings = R.compose(
    R.indexBy(R.prop('charge')),
    R.map((charge: string) => ({
      width: 200,
      useCustomName: true,
      name: `CHRG: ${charge}`,
      type: GC.FIELD_CURRENCY,
      charge: `sumByAssessorial.${charge}`,
    })),
  )(charges);

  return R.mergeRight(settings, chargeSettings);
};

const PayrollStatistic = ({ parentProps: { statistic, statisticCurrency } }: Object) => {
  const charges = R.compose(
    R.uniq,
    R.flatten,
    R.map(({ charges }: Object) => R.keys(charges)),
  )(statistic);

  return (
    <Box pr={50} width='100%'>
      <Table
        itemList={statistic}
        report={getReport(charges)}
        columnSettings={getColumnSettings(charges)}
        callbackData={{ [GC.FIELD_CURRENCY]: statisticCurrency }}
        tableSettings={{ checkBoxCellWidth: 0, allowSelectItems: false }}
      />
    </Box>
  );
};

const PayrollStatisticWithInvoices = (props: Object) => {
  const {
    data,
    callbackData: { exportStatisticRequest },
    parentProps: { guid, statistic, detailsWidth, detailsLoading, statisticLoading },
  } = props;

  const [showInvoices, setShowInvoices] = useState(false);

  const withInvoices = G.isNotNilAndNotEmpty(data);
  const withStatistic = G.isNotNilAndNotEmpty(statistic);

  return (
    <StickedBox left='0px' width={detailsWidth} display='inline-block'>
      <LocalLoader localLoaderOpen={R.or(detailsLoading, statisticLoading)}>
        <Flex
          p={15}
          width='100%'
          flexWrap='wrap'
          alignItems='baseline'
          borderBottom='1px solid'
          borderColor={G.getTheme('tables.rows.borderColor')}
        >
          {withStatistic && <PayrollStatistic {...props} />}
          {
            withStatistic &&
            <Flex mt={10} width='100%' mb={G.ifElse(showInvoices, 15, '0')}>
              <MainActionButton mr={20} height={25} onClick={() => exportStatisticRequest(R.of(Array, guid))}>
                {G.getWindowLocale('actions:download-statistic', 'Download Statistic')}
              </MainActionButton>
              {
                withInvoices &&
                <ToggleBtn
                  height={23}
                  width={100}
                  fontSize={12}
                  checked={showInvoices}
                  onToggleHandler={setShowInvoices}
                  title={G.getWindowLocale('titles:show-invoices', 'Show Invoices')}
                />
              }
            </Flex>
          }
          {R.or(G.isNilOrEmpty(statistic), R.and(withInvoices, showInvoices)) && renderInvoices(props)}
        </Flex>
      </LocalLoader>
    </StickedBox>
  );
};

const VendorPayrollStatisticTab = (props: Object) => {
  const { payrollGuid, tableSettings, exportStatisticRequest } = props;

  const { statistic, statisticCurrency } = useStatistic({ payrollGuid });

  const list = R.compose(
    R.uniq,
    R.flatten,
    R.map(({ charges }: Object) => R.keys(charges)),
  )(statistic);

  return (
    <Fragment>
      <Table
        itemList={statistic}
        report={getReport(list)}
        tableSettings={tableSettings}
        columnSettings={getColumnSettings(list)}
        callbackData={{ [GC.FIELD_CURRENCY]: statisticCurrency }}
      />
      <MainActionButton mt={15} ml={15} type='button' onClick={() => exportStatisticRequest(R.of(Array, payrollGuid))}>
        {G.getWindowLocale('actions:download-statistic', 'Download Statistic')}
      </MainActionButton>
    </Fragment>
  );
};

export {
  PayrollStatistic,
  VendorPayrollStatisticTab,
  PayrollStatisticWithInvoices,
};
