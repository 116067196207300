import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// feature pivot-table
import * as A from './actions';
//////////////////////////////////////////////////

const initialState = {
  totalCount: 0,
  usedReport: null,
  filterParams: {},
  pivotTableData: null,
  pivotTableType: null,
  availableReports: null,
};

const setInitialState = () => initialState;

const getPivotTableDataSuccess = (state: Object, data: Array = {}) => (
  P.$all(
    P.$set('pivotTableData', data),
    P.$set('totalCount', data.totalCount),
    state,
  )
);

const setReports = (state: Object, data: Array) => (
  P.$set('availableReports', data, state)
);

const setUsedReport = (state: Object, data: Object) => (
  P.$all(
    P.$set('usedReport', data),
    P.$set('pivotTableData', null),
    state,
  )
);

const setPivotReportType = (state: Object, data: Array) => (
  P.$set('pivotReportType', data, state)
);

export default createReducer({
  [A.setReports]: setReports,
  [A.setUsedReport]: setUsedReport,
  [A.setInitialState]: setInitialState,
  [A.setPivotReportType]: setPivotReportType,
  [A.getPivotTableDataSuccess]: getPivotTableDataSuccess,
}, initialState);
