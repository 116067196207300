import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const referencesSettings = {
  [GC.FIELD_NAME]: {
    width: 150,
    name: 'titles:name',
  },
  [GC.FIELD_BRANCH_NAME]: {
    width: 170,
    name: 'titles:branch',
  },
  [GC.FIELD_REFERENCE_SCOPES]: {
    width: 200,
    name: 'titles:type',
    customComponent: ({ data }: Object) => {
      const scopeNames = R.compose(
        R.join(', '),
        R.map((item: Object) => G.getEnumLocale(R.path([GC.FIELD_NAME], item))),
        R.path(['scopes']),
      )(data);
      return (
        <TextComponent
          width='100%'
          maxWidth='250px'
          title={scopeNames}
          withEllipsis={true}
          display='inline-block'
        >
          {scopeNames}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_DESCRIPTION]: {
    width: 400,
    name: 'titles:description',
  },
};

export const report = {
  fields: [
    { freezed: false, name: GC.FIELD_NAME, sequence: 1 },
    { freezed: false, name: GC.FIELD_BRANCH_NAME, sequence: 2 },
    { freezed: false, name: GC.FIELD_REFERENCE_SCOPES, sequence: 3 },
    { freezed: false, name: GC.FIELD_DESCRIPTION, sequence: 4 },
  ],
};
