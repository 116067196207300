import React from 'react';
import * as R from 'ramda';
// components
import { LocalLoader } from '../../../components/local-loader';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Flex, StickedBox } from '../../../ui';
// feature
import { EmptyList } from '../ui';
import PayrollInvoiceDetails from './payroll-invoice-details';
//////////////////////////////////////////////////

export const renderInvoices = (props: Object) => {
  const { data, parentProps } = props;

  if (G.isNilOrEmpty(data)) {
    return (
      <EmptyList>
        {G.getWindowLocale('titles:empty-list', 'Empty List')}
      </EmptyList>
    );
  }

  return data.map((invoice: Object, i: number) => (
    <PayrollInvoiceDetails
      {...invoice}
      key={`payroll-invoice-${invoice.guid}-${i}`}
      actionsDisabled={R.pathOr(false, ['actionsDisabled'], props)}
      remove={(
        invoiceGuid: string,
        invoiceNumber: string,
      ) => parentProps.removeInvoice(
        invoiceGuid,
        invoiceNumber,
        parentProps.guid,
      )}
      edit={() => parentProps.editInvoice(invoice, parentProps.guid)}
    />
  ));
};

export const PayrollInvoices = (props: Object) => (
  <StickedBox
    left='0px'
    display='inline-block'
    width={props.parentProps.detailsWidth}
  >
    <LocalLoader localLoaderOpen={props.parentProps.detailsLoading}>
      <Flex
        p={15}
        width='100%'
        flexWrap='wrap'
        alignItems='baseline'
        borderBottom='1px solid'
        borderColor={G.getTheme('tables.rows.borderColor')}
      >
        {renderInvoices(props)}
      </Flex>
    </LocalLoader>
  </StickedBox>
);

export default PayrollInvoices;
