import * as R from 'ramda';
import React from 'react';
// features
import { importStatusesTypes } from '../../new-import/settings';
import { TableField } from '../../dispatch-report/components/table-fields';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { getFreightClassOptions, ITEM_FREIGHT_CLASS_OPTIONS_STANDARD } from '../../../helpers/options';
//////////////////////////////////////////////////

export const priceListTableSettings = {
  minHeight: 320,
  allowEditBtn: true,
  titleRowHeight: 30,
  tableRowHeight: 30,
  useMainColors: true,
  moreBtnCellWidth: 50,
  checkBoxCellWidth: 30,
  allowSelectAll: false,
  expandableItems: false,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 145px)',
};

const statusOptions = [
  {
    value: GC.STATUS_ERROR,
    label: G.getWindowLocale('titles:error', 'Error'),
  },
  {
    value: GC.STATUS_SUCCESS,
    label: G.getWindowLocale('titles:success', 'Success'),
  },
  {
    value: GC.STATUS_PENDING,
    label: G.getWindowLocale('titles:pending', 'Pending'),
  },
];

export const excelRattingSummaryColumnSettings = {
  [GC.FIELD_BRANCH_NAME]: {
    width: 200,
    searchable: true,
    name: 'titles:branch',
  },
  [GC.CUSTOMER_BRANCH_NAME]: {
    width: 200,
    searchable: true,
    name: 'titles:customer',
  },
  [GC.FIELD_IMPORTER_BY_LOGIN_ID]: {
    width: 200,
    searchable: true,
    name: 'titles:importer',
  },
  [GC.FIELD_STATUS]: {
    width: 200,
    type: 'enum',
    name: 'titles:status',
    options: importStatusesTypes,
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 220,
    type: 'date',
    searchable: true,
    filterType: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_START_DATE]: {
    width: 220,
    type: 'date',
    searchable: true,
    filterType: 'date',
    name: 'titles:start-date',
  },
  [GC.FIELD_END_DATE]: {
    width: 220,
    type: 'date',
    searchable: true,
    filterType: 'date',
    name: 'titles:end-date',
  },
  [GC.FIELD_QUOTE_NAME]: {
    width: 200,
    searchable: true,
    name: 'titles:quote-name',
  },
  [GC.FIELD_QUOTE_ID]: {
    width: 200,
    searchable: true,
    name: 'titles:quote-id',
  },
  totalRows: {
    width: 150,
    filterType: 'number',
    name: 'titles:total-rows',
  },
  successTotal: {
    width: 150,
    filterType: 'number',
    name: 'titles:success-total',
  },
  errorsTotal: {
    width: 150,
    filterType: 'number',
    name: 'titles:errors-total',
  },
  processedTotal: {
    width: 150,
    filterType: 'number',
    name: 'titles:processed-total',
  },
};

export const excelRattingSummaryReport = {
  fields: R.compose(
    G.mapIndexed((name: string, sequence: number) => ({ name, sequence })),
    R.keys,
  )(excelRattingSummaryColumnSettings),
};

export const excelResultSettings = {
  [`${GC.FIELD_MODE}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 200,
    searchable: true,
    name: 'titles:mode-of-transportation',
  },
  [`${GC.FIELD_LOAD_EQUIPMENT}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 200,
    searchable: true,
    name: 'titles:equipment',
  },
  [`${GC.FIELD_ORIGIN_LOCATION}.${GC.FIELD_LOCATION_NAME}`]: {
    width: 200,
    searchable: true,
    name: ['titles:origin', 'titles:location-name'],
  },
  [`${GC.FIELD_ORIGIN_LOCATION}.${GC.FIELD_ADDRESS_1}`]: {
    width: 200,
    searchable: true,
    name: ['titles:origin', 'titles:address1'],
  },
  [`${GC.FIELD_ORIGIN_LOCATION}.${GC.FIELD_LOCATION_NAME}`]: {
    width: 200,
    searchable: true,
    name: ['titles:origin', 'titles:terminal-name'],
  },
  [`${GC.FIELD_ORIGIN_LOCATION}.${GC.FIELD_CITY}`]: {
    width: 200,
    searchable: true,
    name: ['titles:origin', 'titles:city'],
  },
  [`${GC.FIELD_ORIGIN_LOCATION}.${GC.FIELD_STATE}`]: {
    width: 200,
    searchable: true,
    name: ['titles:origin', 'titles:state'],
  },
  [`${GC.FIELD_ORIGIN_LOCATION}.${GC.FIELD_ZIP}`]: {
    width: 200,
    searchable: true,
    name: ['titles:origin', 'titles:zip'],
  },
  [`${GC.FIELD_ORIGIN_LOCATION}.${GC.FIELD_LONGITUDE}`]: {
    width: 200,
    searchable: true,
    filterType: 'number',
    name: ['titles:origin', 'titles:longitude'],
  },
  [`${GC.FIELD_ORIGIN_LOCATION}.${GC.FIELD_LATITUDE}`]: {
    width: 200,
    searchable: true,
    filterType: 'number',
    name: ['titles:origin', 'titles:latitude'],
  },
  [`${GC.FIELD_DESTINATION_LOCATION}.${GC.FIELD_LOCATION_NAME}`]: {
    width: 200,
    searchable: true,
    name: ['titles:destination', 'titles:location-name'],
  },
  [`${GC.FIELD_DESTINATION_LOCATION}.${GC.FIELD_ADDRESS_1}`]: {
    width: 200,
    searchable: true,
    name: ['titles:destination', 'titles:address1'],
  },
  [`${GC.FIELD_DESTINATION_LOCATION}.${GC.FIELD_LOCATION_NAME}`]: {
    width: 200,
    searchable: true,
    name: ['titles:destination', 'titles:terminal-name'],
  },
  [`${GC.FIELD_DESTINATION_LOCATION}.${GC.FIELD_CITY}`]: {
    width: 200,
    searchable: true,
    name: ['titles:destination', 'titles:city'],
  },
  [`${GC.FIELD_DESTINATION_LOCATION}.${GC.FIELD_STATE}`]: {
    width: 200,
    searchable: true,
    name: ['titles:destination', 'titles:state'],
  },
  [`${GC.FIELD_DESTINATION_LOCATION}.${GC.FIELD_ZIP}`]: {
    width: 200,
    searchable: true,
    name: ['titles:destination', 'titles:zip'],
  },
  [`${GC.FIELD_ORIGIN_LOCATION}.${GC.FIELD_LONGITUDE}`]: {
    width: 200,
    searchable: true,
    filterType: 'number',
    name: ['titles:destination', 'titles:longitude'],
  },
  [`${GC.FIELD_DESTINATION_LOCATION}.${GC.FIELD_LATITUDE}`]: {
    width: 200,
    searchable: true,
    filterType: 'number',
    name: ['titles:destination', 'titles:latitude'],
  },
  storedTotalTripWeight: {
    width: 200,
    searchable: true,
    filterType: 'number',
    name: 'titles:total-trip-weight',
    customComponent: (data: Object) => <TableField data={data} type={GC.FIELD_WEIGHT} />,
  },
  [GC.FIELD_STORED_TOTAL_TRIP_VOLUME]: {
    width: 200,
    searchable: true,
    filterType: 'number',
    name: 'titles:total-trip-volume',
    customComponent: (data: Object) => <TableField data={data} type={GC.FIELD_ITEM_VOLUME} />,
  },
  [GC.FIELD_TOTAL_PICKUP_QUANTITY]: {
    width: 200,
    searchable: true,
    filterType: 'number',
    name: 'titles:quantity',
  },
  [GC.FIELD_PACKAGE_TYPE]: {
    width: 200,
    searchable: true,
    name: 'titles:package-type',
  },
  [GC.FIELD_ITEM_FREIGHT_CLASS]: {
    width: 200,
    searchable: true,
    filterType: 'selectMultiple',
    name: 'titles:freight-class',
    options: getFreightClassOptions(),
    placeholder: { text: 'Choose', key: 'titles:choose' },
    customComponent: ({ data }: string) => R.compose(
      R.pathOr('', [GC.FIELD_LABEL]),
      R.find(R.propEq(R.prop(GC.FIELD_ITEM_FREIGHT_CLASS, data), GC.FIELD_VALUE)),
    )(ITEM_FREIGHT_CLASS_OPTIONS_STANDARD),
  },
  [`${GC.FIELD_PRICE}.${GC.FIELD_CUSTOMER_TOTAL}`]: {
    width: 200,
    searchable: true,
    filterType: 'number',
    name: ['titles:selected-price', 'titles:customer-total'],
  },
  [`${GC.FIELD_PRICE}.${GC.FIELD_CARRIER_TOTAL}`]: {
    width: 200,
    searchable: true,
    filterType: 'number',
    name: ['titles:selected-price', 'titles:carrier-total'],
  },
  [GC.FIELD_STATUS]: {
    width: 200,
    type: 'enum',
    searchable: true,
    name: 'titles:status',
    options: statusOptions,
    filterType: 'selectMultiple',
  },
  rowNumber: {
    width: 200,
    searchable: true,
    filterType: 'number',
    name: 'titles:row-number',
  },
  [`${GC.FIELD_PRICE}.${GC.FIELD_CARRIER_CONTRACT_NAME}`]: {
    width: 200,
    searchable: true,
    name: ['titles:selected-price', 'titles:contract-name'],
  },
  [`${GC.FIELD_PRICE}.${GC.FIELD_CUSTOMER_CONTRACT_NAME}`]: {
    width: 200,
    searchable: true,
    filterType: 'string',
    name: ['titles:selected-price', 'titles:customer-contract-name'],
  },
  [`${GC.FIELD_PRICE}.${GC.FIELD_SHOWN_ASSIGNMENT_CARRIER_NAME}`]: {
    width: 200,
    searchable: true,
    filterType: 'string',
    name: ['titles:selected-price', 'titles:carrier-name'],
  },
  [`${GC.FIELD_PRICE}.${GC.FIELD_RATE_SHOP_CARRIER_TRANSPORTATION_MODE}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 300,
    searchable: true,
    filterType: 'string',
    name: ['titles:selected-price', 'titles:carrier-mode-of-transportation'],
  },
  [`${GC.FIELD_PRICE}.${GC.FIELD_RATE_SHOP_CARRIER_SERVICE_TYPES}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 200,
    searchable: true,
    filterType: 'string',
    name: ['titles:selected-price', 'titles:carrier-service-type'],
  },
  [`${GC.FIELD_PRICE}.${GC.FIELD_RATE_SHOP_CARRIER_EQUIPMENTS}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 200,
    searchable: true,
    filterType: 'string',
    name: ['titles:selected-price', 'titles:carrier-equipments'],
  },
  [`${GC.FIELD_PRICE}.${GC.FIELD_RATE_SHOP_CARRIER_TRANSIT_DAYS}`]: {
    width: 200,
    searchable: true,
    filterType: 'number',
    name: ['titles:selected-price', 'titles:carrier-transit-days'],
  },
  [GC.FIELD_LAST_UPDATE_DATE]: {
    width: 220,
    searchable: true,
    filterType: 'date',
    name: 'titles:update-on',
  },
  [GC.FIELD_LAST_UPDATE_BY]: {
    width: 200,
    searchable: true,
    name: 'titles:updated-by',
  },
  [GC.FIELD_ERRORS]: {
    width: 400,
    searchable: true,
    name: 'titles:errors',
  },
};

export const priceListColumnSettings = {
  [GC.FIELD_CUSTOMER_TOTAL]: {
    width: 150,
    name: 'titles:customer-total',
  },
  [GC.FIELD_CARRIER_TOTAL]: {
    width: 150,
    name: 'titles:carrier-total',
  },
  [GC.FIELD_CARRIER_CONTRACT_NAME]: {
    width: 150,
    name: 'titles:contract-name',
  },
  [GC.FIELD_CUSTOMER_CONTRACT_NAME]: {
    width: 200,
    name: 'titles:customer-contract-name',
  },
  [GC.FIELD_SHOWN_ASSIGNMENT_CARRIER_NAME]: {
    width: 150,
    name: 'titles:carrier-name',
  },
  [GC.FIELD_RATE_SHOP_CARRIER_TRANSPORTATION_MODE]: {
    width: 250,
    name: 'titles:carrier-mode-of-transportation',
  },
  [GC.FIELD_RATE_SHOP_CARRIER_SERVICE_TYPES]: {
    width: 200,
    name: 'titles:carrier-service-types',
  },
  [GC.FIELD_RATE_SHOP_CARRIER_EQUIPMENTS]: {
    width: 200,
    name: 'titles:carrier-equipments',
  },
  [GC.FIELD_RATE_SHOP_CARRIER_TRANSIT_DAYS]: {
    width: 150,
    name: 'titles:carrier-transit-days',
  },
};

export const priceListReport = {
  fields: R.compose(
    G.mapIndexed((name: string, sequence: number) => ({ name, sequence })),
    R.keys,
  )(priceListColumnSettings),
};

const getFilterParams = R.compose(
  R.values,
  R.mapObjIndexed(({ name, options, placeholder, filterType = 'string' }: Object, value: string) => ({
    value,
    options,
    placeholder,
    [GC.FIELD_TYPE]: filterType,
    [GC.FIELD_NAME]: G.ifElse(
      G.isArray(name),
      `${G.getWindowLocale(name[0])}: ${G.getWindowLocale(name[1])}`,
      G.getWindowLocale(name, '...'),
    ),
  })),
);

export const FILTER_PARAMS = getFilterParams(excelRattingSummaryColumnSettings);

export const EXCEL_RESULTS_FILTER_PARAMS = getFilterParams(excelResultSettings);
