import * as R from 'ramda';
import { withFormik } from 'formik';
import React, { Fragment } from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { PageTitle } from '../../../components/page-title';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Fieldset2 } from '../../../forms';
// hocs
import { withComponentDidUpdatePropCallback } from '../../../hocs';
// ui
import { Flex, Grid, RelativeBox, AbsoluteBox } from '../../../ui';
// feature profile
import * as LC from '../constants';
import ConnectEmail from '../components/connect-email';
import ProfilePhoto from '../components/profile-photo';
import Card, { buttonStyles } from '../components/card';
import ChangePassword from '../components/change-password';
//////////////////////////////////////////////////

const GeneralDetails = (props: Object) => {
  const {
    title,
    values,
    firstFields,
    userPhotoUrl,
    initialValues,
    getFieldsGroups,
    optionsForSelect,
    handleChangeUserSuspendedStatus,
  } = props;

  const fieldsWrapperStyles = { pt: 10, gap: 15 };
  const userGuid = G.getGuidFromObject(initialValues);

  const fieldsGroups = R.compose(
    R.indexBy(R.prop('groupName')),
    getFieldsGroups,
  )(values);

  const generalSettingGroup = G.getPropFromObject(LC.GENERAL_SETTING, fieldsGroups);
  const generalInformationGroup = G.getPropFromObject(LC.GENERAL_INFORMATION, fieldsGroups);
  const contactInformationGroup = G.getPropFromObject(LC.CONTACT_INFORMATION, fieldsGroups);

  return (
    <Fragment>
      <PageTitle
        mr={10}
        title={title}
        display='inline-flex'
        borderTopLeftRadius='8px'
        borderTopRightRadius='8px'
      />
      <form>
        <RelativeBox mb={20}>
          <AbsoluteBox top={-25} right={0}>
            <AuthWrapper has={[PC.SUSPEND_USER_EXECUTE]}>
              <Fieldset2
                {...G.getFormikPropsToFieldset(props)}
                handleCustomChange={handleChangeUserSuspendedStatus}
                fields={R.pathOr([], ['fieldSettings'], firstFields)}
              />
            </AuthWrapper>
          </AbsoluteBox>
          <Grid gridGap={20} gridTemplateColumns='repeat(auto-fill, minmax(420px, 1fr))'>
            <Card
              cardUnderPageTitle={true}
              ActionComponent={ChangePassword}
              actionComponentProps={buttonStyles}
              title={G.getPropFromObject(LC.GENERAL_INFORMATION, LC.GROUP_NAMES_MAP)}
            >
              <Flex gap={15} alignItems='flex-start'>
                {
                  G.isNotNil(userGuid) &&
                  <ProfilePhoto
                    width={80}
                    version={2}
                    height={80}
                    withActions={true}
                    userGuid={userGuid}
                    photoUrl={userPhotoUrl}
                    isProfile={R.pathEq(GC.ROUTE_PATH_PROFILE, ['location', 'pathname'], window)}
                  />
                }
                <Fieldset2
                  {...optionsForSelect}
                  {...G.getFormikPropsToFieldset(props)}
                  fieldsWrapperStyles={fieldsWrapperStyles}
                  fields={generalInformationGroup.fieldSettings}
                />
              </Flex>
            </Card>
            <Card title={G.getPropFromObject(LC.CONTACT_INFORMATION, LC.GROUP_NAMES_MAP)}>
              <Flex gap={10} mb={10}>
                <Fieldset2
                  {...optionsForSelect}
                  {...G.getFormikPropsToFieldset(props)}
                  fieldsWrapperStyles={{ pt: 10, flex: 5 }}
                  fields={R.of([], R.head(contactInformationGroup.fieldSettings))}
                />
                <ConnectEmail
                  {...values}
                  buttonStyles={buttonStyles}
                  initialEmail={G.getPropFromObject(GC.FIELD_EMAIL, initialValues)}
                />
              </Flex>
              <Fieldset2
                {...optionsForSelect}
                {...G.getFormikPropsToFieldset(props)}
                fieldsWrapperStyles={fieldsWrapperStyles}
                fields={R.tail(contactInformationGroup.fieldSettings)}
              />
            </Card>
            <Card title={G.getPropFromObject(LC.GENERAL_SETTING, LC.GROUP_NAMES_MAP)}>
              <Fieldset2
                {...optionsForSelect}
                {...G.getFormikPropsToFieldset(props)}
                fieldsWrapperStyles={fieldsWrapperStyles}
                fields={generalSettingGroup.fieldSettings}
              />
            </Card>
          </Grid>
        </RelativeBox>
      </form>
    </Fragment>
  );
};

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: R.pathOr({}, ['initialValues']),
    validationSchema: R.pathOr({}, ['validationSchema']),
    handleSubmit: (values: Object, { props, setSubmitting }: Object) => {
      const { submitAction } = props;

      submitAction(G.mapObjectEmptyStringFieldsToNull(values), { setSubmitting });
    },
  }),
  withHandlers({
    handleUpdate: (props: Object) => () => {
      const {
        values,
        update,
        setUpdate,
        submitForm,
        isSubmitting,
        initialValues,
      } = props;

      if (R.or(isSubmitting, R.equals(values, initialValues))) {
        setUpdate(false);

        return;
      }

      if (update) {
        submitForm();
        setUpdate(false);
      }
    },
    handleSaveAndClose: (props: Object) => () => {
      const { errors, submitForm, saveAndClose, setSaveAndClose } = props;

      if (saveAndClose) submitForm();

      if (R.and(saveAndClose, G.isNotNilAndNotEmpty(errors))) setSaveAndClose(false);
    },
  }),
  withComponentDidUpdatePropCallback({ propName: 'update', callbackName: 'handleUpdate' }),
  withComponentDidUpdatePropCallback({ propName: 'saveAndClose', callbackName: 'handleSaveAndClose' }),
  pure,
);

export default enhance(GeneralDetails);

