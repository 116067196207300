import * as R from 'ramda';
import React from 'react';
import { compose, withState } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
import { InputWithHandlers } from '../../../components/input-with-handlers';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Flex, IconWrapper, AbsoluteBox, RelativeFlex } from '../../../ui';
//////////////////////////////////////////////////

const enhance = compose(
  withState('active', 'setActive', ''),
);

const textOptions = {
  ml: 15,
  fontSize: 11,
  p: '4px 10px',
  fontWeight: 700,
  borderRadius: 3,
  cursor: 'pointer',
  display: 'inline-block',
};

const renderFilterRead = (props: Object) => {
  const data = ['titles:all', 'titles:read', 'titles:unread'];
  const type = {
    'titles:all': '',
    'titles:read': 'true',
    'titles:unread': 'false',
  };
  return data.map(
    (item: string, index: number) => (
      <TextComponent
        key={index}
        {...textOptions}
        color={G.ifElse(
          R.equals(props.active, type[item]),
          G.getTheme('colors.light.mainLight'),
          G.getTheme('colors.light.blue'),
        )}
        bg={G.ifElse(
          R.equals(props.active, type[item]),
          G.getTheme('colors.light.blue'),
          G.getTheme('colors.light.mainLight'),
        )}
        onClick={() => {
          props.setActive(type[item]);
          props.handleChangeFilter(type[item]);
        }}
      >
        {G.getWindowLocale(item)}
      </TextComponent>
    ),
  );
};

const NotificationHeader = (props: Object) => (
  <RelativeFlex
    p='18px 15px'
    borderBottom='1px solid'
    justifyContent='space-between'
    color={G.getTheme('colors.light.darkGrey')}
    borderColor={G.getTheme('colors.light.middleGrey')}
  >
    <AbsoluteBox
      top='-10px'
      width='20px'
      right='-10px'
      height='20px'
      cursor='pointer'
      borderRadius='50%'
      bg={G.getTheme('colors.light.mainLight')}
      onClick={props.onHidePopup}
    >
      {I.crossInRound('', '20px', '20px')}
    </AbsoluteBox>
    <Flex
      flexDirection='row'
      color={G.getTheme('colors.light.darkRed')}
    >
      <IconWrapper
        mr='5px'
        width='max-content'
      >
        {I.bellIcon()}
      </IconWrapper>
      <TextComponent fontSize='14px'>
        {G.getWindowLocale('titles:notifications', 'Notifications')}
      </TextComponent>
    </Flex>
    <Flex mx='auto'>
      {renderFilterRead(props)}
    </Flex>
    <Flex ml='auto' mr='40px'>
      <InputWithHandlers
        styles={{
          width: 120,
          height: 20,
          p: '0 3px',
          border: 'none',
          background: 'none',
          borderRadius: '0px',
          borderColor: 'grey',
          borderBottom: '1px solid',
        }}
        value={props.filterString}
        onChange={(value: string) => props.handleChangeFilter(value)}
        placeholder={G.getWindowLocale('titles:search', 'Search')}
      />
    </Flex>
    {`${props.unreadCount} of ${props.totalCount}`}
  </RelativeFlex>
);

export default enhance(NotificationHeader);
