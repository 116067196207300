import * as R from 'ramda';
import { createSelector } from 'reselect';
// features
import { makeLocation, getDataFromStops } from '../new-do/helpers';
import { locationFieldsToPick } from '../new-do/settings/fields-settings';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

const selectBranchStore = ({ branch }: object) => branch;
const selectNewDOQuoteStore = ({ newDOQuote }: object) => newDOQuote;

const makeSelectFullDOQuoteStore = () => createSelector(
  selectNewDOQuoteStore,
  (store: Object) => store,
);

const makeSelectPageType = () => createSelector(
  selectNewDOQuoteStore,
  ({ pageType }: Object) => pageType,
);

const makeSelectStops = () => createSelector(
  selectNewDOQuoteStore,
  ({ stops }: Object) => stops,
);

const makeSelectLoadType = () => createSelector(
  selectNewDOQuoteStore,
  ({ loadType }: Object) => loadType,
);

const makeSelectStopsData = () => createSelector(
  selectNewDOQuoteStore,
  ({ stops }: Object) => getDataFromStops(stops),
);

const makeSelectRate = () => createSelector(
  selectNewDOQuoteStore,
  ({ rate }: Object) => rate,
);

const makeSelectStyling = () => createSelector(
  selectNewDOQuoteStore,
  ({ styling }: Object) => styling,
);

const makeSelectBranchList = () => createSelector(
  selectNewDOQuoteStore,
  ({ branchList }: Object) => branchList,
);

const makeSelectBranchInfo = () => createSelector(
  selectNewDOQuoteStore,
  ({ branchInfo }: Object) => branchInfo,
);

const makeSelectBranchGuid = () => createSelector(
  selectNewDOQuoteStore,
  ({ branchInfo }: Object) => R.path([GC.FIELD_VALUE], branchInfo),
);

const makeSelectDivisionGuid = () => createSelector(
  selectNewDOQuoteStore,
  ({ divisionGuid }: Object) => divisionGuid,
);

const makeSelectBranchConfigs = () => createSelector(
  selectNewDOQuoteStore,
  ({ branchConfigs }: Object) => branchConfigs,
);

const makeSelectBranchRefTypes = () => createSelector(
  selectNewDOQuoteStore,
  ({ branchRefTypes }: Object) => branchRefTypes,
);

const makeSelectPrimaryReference = () => createSelector(
  selectNewDOQuoteStore,
  ({ primaryReference }: Object) => primaryReference,
);

const makeSelectQuoteNumber = () => createSelector(
  selectNewDOQuoteStore,
  ({ quoteNumber }: Object) => quoteNumber,
);

const makeSelectExpirationDate = () => createSelector(
  selectNewDOQuoteStore,
  ({ expirationDate }: Object) => expirationDate,
);

const makeSelectLeftActiveTad = () => createSelector(
  selectNewDOQuoteStore,
  ({ leftActiveTad }: Object) => leftActiveTad,
);

const makeSelectRightActiveTad = () => createSelector(
  selectNewDOQuoteStore,
  ({ rightActiveTad }: Object) => rightActiveTad,
);

const makeSelectReferenceFormData = () => createSelector(
  selectNewDOQuoteStore,
  ({ referenceFormData }: Object) => referenceFormData,
);

const makeSelectValidationErrors = () => createSelector(
  selectNewDOQuoteStore,
  ({ validationErrors }: Object) => validationErrors,
);

const getTotalWeight = (stopsData: Object) => {
  const { pickedItems } = stopsData;

  if (G.isNotNilAndNotEmpty(pickedItems)) {
    return G.renameKeys({
      [GC.FIELD_ITEM_WEIGHT]: GC.FIELD_TOTAL_TRIP_WEIGHT,
      [GC.FIELD_ITEM_WEIGHT_TYPE]: GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
    }, G.calcItemsTotalWeightWithoutQty(pickedItems));
  }

  return {
    [GC.FIELD_TOTAL_TRIP_WEIGHT]: '',
    [GC.FIELD_TOTAL_TRIP_WEIGHT_UOM]: '',
  };
};

const makeSelectLoadTotals = () => createSelector(
  selectNewDOQuoteStore,
  ({ stops }: Object) => {
    const stopsData = getDataFromStops(stops);
    const { sortedStops, pickedItems } = stopsData;
    const totalWeight = getTotalWeight(stopsData);
    const totalQuantity = G.calculateTotalQuantity(pickedItems);
    const totalQuantities = {
      [R.prop(GC.FIELD_ITEM_PACKAGE_TYPE, totalQuantity)]: G.mathRoundNumber(
        R.prop(GC.FIELD_ITEM_QUANTITY, totalQuantity),
      ),
    };
    const totalVolume = G.calculateTotalVolumeWithoutQty(pickedItems);
    const totalDistance = G.getLoadTotalDistance({ [GC.FIELD_LOAD_STOPS]: sortedStops }, GC.FIELD_DISTANCE_CLO);

    return {
      totalWeight,
      totalVolume,
      totalDistance,
      [GC.FIELD_TOTAL_QUANTITIES]: totalQuantities,
    };
  },
);

const makeSelectMapLocations = () => createSelector(
  selectNewDOQuoteStore,
  ({ stops }: Object) => {
    const { sortedStops } = getDataFromStops(stops);
    const mappedStops = R.map((stop: Object) => {
      const { id, order, formData, eventType } = stop;
      const location = makeLocation(R.pick(locationFieldsToPick, formData));
      const contact = R.pick(GC.GROUPED_FIELDS.EVENT_CONTACT_ARR, location);
      const hasNotLatLng = R.or(
        G.isNilOrEmpty(R.pathOr(null, [GC.FIELD_LATITUDE], location)),
        G.isNilOrEmpty(R.pathOr(null, [GC.FIELD_LONGITUDE], location)),
      );

      if (hasNotLatLng) return null;

      return {
        stop,
        contact,
        location,
        guid: id,
        title: `${G.toTitleCase(eventType)} ${order}`,
        latLng: { lat: R.prop(GC.FIELD_LATITUDE, location), lng: R.prop(GC.FIELD_LONGITUDE, location) },
      };
    }, sortedStops);

    return R.compose(R.values(), R.filter((stop: Object) => G.isNotNil(stop)))(mappedStops);
  },
);

const makeSelectDivision = () => createSelector(
  selectNewDOQuoteStore,
  selectBranchStore,
  (doStore: Object, branchStore: Object) => {
    const { divisionGuid } = doStore;
    const { treeBranches } = branchStore;

    if (R.or(G.isNilOrEmpty(divisionGuid), G.isNilOrEmpty(treeBranches))) return null;

    return G.getBranchFromTreeByGuid(divisionGuid, treeBranches);
  },
);

const makeSelectIntegrationCarriers = () => createSelector(
  selectNewDOQuoteStore,
  ({ rateBackup }: Object) => ({
    [GC.FIELD_ORIGIN_CARRIER_NAME]: G.getPropFromObject(GC.FIELD_ORIGIN_CARRIER_NAME, rateBackup),
    [GC.FIELD_DESTINATION_CARRIER_NAME]: G.getPropFromObject(GC.FIELD_DESTINATION_CARRIER_NAME, rateBackup),
    [GC.FIELD_TRANSPORTING_CARRIER_NAME]: R.or(
      R.path([GC.FIELD_TRANSPORTING_CARRIER_NAME], rateBackup),
      R.path([GC.FIELD_TRANSPORTING_CARRIER], rateBackup)),
  }),
);

const makeSelectCarrierRate = () => createSelector(
  selectNewDOQuoteStore,
  ({ telCarrierRate }: Object) => telCarrierRate,
);

const makeSelectDriverRate = () => createSelector(
  selectNewDOQuoteStore,
  ({ telDriverRate }: Object) => telDriverRate,
);

const makeSelectTripServiceDays = () => createSelector(
  selectNewDOQuoteStore,
  ({ telDriverRate, telCarrierRate }: Object) => R.or(
    R.path([GC.FIELD_SERVICE_DAYS], telDriverRate),
    R.path([GC.FIELD_SERVICE_DAYS], telCarrierRate)),
);

export {
  makeSelectRate,
  makeSelectStops,
  makeSelectStyling,
  makeSelectDivision,
  makeSelectLoadType,
  makeSelectPageType,
  makeSelectStopsData,
  makeSelectLoadTotals,
  makeSelectBranchList,
  makeSelectBranchInfo,
  makeSelectBranchGuid,
  makeSelectDriverRate,
  makeSelectQuoteNumber,
  selectNewDOQuoteStore,
  makeSelectCarrierRate,
  makeSelectDivisionGuid,
  makeSelectMapLocations,
  makeSelectLeftActiveTad,
  makeSelectBranchConfigs,
  makeSelectExpirationDate,
  makeSelectRightActiveTad,
  makeSelectBranchRefTypes,
  makeSelectTripServiceDays,
  makeSelectValidationErrors,
  makeSelectPrimaryReference,
  makeSelectFullDOQuoteStore,
  makeSelectReferenceFormData,
  makeSelectIntegrationCarriers,
};
