import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const cleanStore = createAction('cleanStore');
export const setBranchInfo = createAction('setBranchInfo');
export const setQuoteNumber = createAction('setQuoteNumber');
export const setDivisionGuid = createAction('setDivisionGuid');
export const setValueToStore = createAction('setValueToStore');
export const getQuoteRequest = createAction('getQuoteRequest');
export const setActiveLeftTab = createAction('setActiveLeftTab');
export const setExpirationDate = createAction('setExpirationDate');
export const setActiveRightTab = createAction('setActiveRightTab');
export const setReorderedStops = createAction('setReorderedStops');
export const setFormDataToStop = createAction('setFormDataToStop');
export const addNewStopToStore = createAction('addNewStopToStore');
export const setPageTypeToStore = createAction('setPageTypeToStore');
export const setFormDataToStore = createAction('setFormDataToStore');
export const removeItemFromStore = createAction('removeItemFromStore');
export const setValidationErrors = createAction('setValidationErrors');
export const removeStopFromStore = createAction('removeStopFromStore');
export const setDriverRateToStore = createAction('setDriverRateToStore');
export const setQuotesDataToStore = createAction('setQuotesDataToStore');
export const setBranchDefaultItem = createAction('setBranchDefaultItem');
export const getBranchDataRequest = createAction('getBranchDataRequest');
export const getBranchListRequest = createAction('getBranchListRequest');
export const getBranchListSuccess = createAction('getBranchListSuccess');
export const sendDataToApiRequest = createAction('sendDataToApiRequest');
export const setCarrierRateToStore = createAction('setCarrierRateToStore');
export const setInitialStateToStore = createAction('setInitialStateToStore');
export const removeValidationErrors = createAction('removeValidationErrors');
export const setCustomerRateToStore = createAction('setCustomerRateToStore');
export const getBranchConfigsRequest = createAction('getBranchConfigsRequest');
export const getBranchConfigsSuccess = createAction('getBranchConfigsSuccess');
export const getBranchStylingRequest = createAction('getBranchStylingRequest');
export const getBranchStylingSuccess = createAction('getBranchStylingSuccess');
export const applyOrderRateUpliftRequest = createAction('applyOrderRateUpliftRequest');
export const getQuoteNumberSequenceRequest = createAction('getQuoteNumberSequenceRequest');
export const getQuoteNumberSequenceSuccess = createAction('getQuoteNumberSequenceSuccess');
export const setCustomerRateChargesToStore = createAction('setCustomerRateChargesToStore');
export const getAllAvBranchRefTypesRequest = createAction('getAllAvBranchRefTypesRequest');
export const getAllAvBranchRefTypesSuccess = createAction('getAllAvBranchRefTypesSuccess');
export const recalculateLoadDistancesRequest = createAction('recalculateLoadDistancesRequest');
export const recalculateLoadDistancesSuccess = createAction('recalculateLoadDistancesSuccess');
// edit quote
export const getOrderQuoteDataRequest = createAction('getOrderQuoteDataRequest');
export const getBranchConfigsWithOrderQuoteDataRequest = createAction('getBranchConfigsWithOrderQuoteDataRequest');
export const getBranchConfigsWithOrderQuoteDataSuccess = createAction('getBranchConfigsWithOrderQuoteDataSuccess');
