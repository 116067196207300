import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
//////////////////////////////////////////////////

const getTelBranchGuid = (props: Object) => R.or(
  R.or(props.branchGuid, R.path([GC.FIELD_TEL, GC.FIELD_BRANCH_GUID], props)),
  R.path([GC.FIELD_TEL, GC.FIELD_BRANCH, GC.FIELD_GUID], props),
);

const getDriverCompensationsReqBody = (driverGuid: string, props: Object) => {
  const { values, telGuid, loadData, telEvents, isFromOrder, isFromPlanner } = props;

  const { serviceType, transportationMode } = values;

  const branchGuid = getTelBranchGuid(props);

  if (G.isAllTrue(
    G.isTrue(isFromPlanner),
    G.isNotNilAndNotEmpty(driverGuid),
    G.isNotNilAndNotEmpty(branchGuid),
    G.isNotNilAndNotEmpty(telEvents),
  )) {
    return {
      telGuid,
      driverGuid,
      serviceType,
      loadGuid: telGuid,
      transportationMode,
      loadData: {
        events: telEvents,
      },
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    };
  }

  if (G.isAllTrue(
    G.isTrue(isFromOrder),
    G.isNotNilAndNotEmpty(driverGuid),
    G.isNotNilAndNotEmpty(branchGuid),
    G.isNotNilAndNotEmpty(loadData),
  )) {
    return {
      loadData,
      driverGuid,
      serviceType,
      transportationMode,
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    };
  }

  if (G.isAllTrue(
    G.isNotNilAndNotEmpty(driverGuid),
    G.isNotNilAndNotEmpty(branchGuid),
    G.isNotNilAndNotEmpty(telGuid),
  )) {
    return {
      telGuid,
      driverGuid,
      serviceType,
      loadGuid: telGuid,
      transportationMode,
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    };
  }
};

const getTeamDriverCompensations = async (driverGuid: string, props: Object) => {
  const { openLoader, closeLoader } = props;

  try {
    G.callFunction(openLoader);

    const reqBody = getDriverCompensationsReqBody(driverGuid, props);

    const res = await sendRequest(
      'post',
      endpointsMap.driverCompensations,
      { data: reqBody },
    );

    const { data, status } = res;

    if (G.isResponseFail(status)) {
      G.callFunction(closeLoader);
      G.handleFailResponseSimple(res, false, 'getTeamDriverCompensations');

      return null;
    }

    G.callFunction(closeLoader);

    return data;
  } catch (error) {
    G.callFunction(closeLoader);
    G.handleException('error', 'getTeamDriverCompensations exception');
  }
};

export {
  getTeamDriverCompensations,
  getDriverCompensationsReqBody,
};
