import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { createStructuredSelector } from 'reselect';
import {
  compose,
  lifecycle,
  withState,
  withHandlers,
} from 'react-recompose';
// components
import { LocalLoader } from '../../../components/local-loader';
import { openModal, closeModal } from '../../../components/modal/actions';
// helpers/constants
import * as G from '../../../helpers';
// features
import { setExternalIdFilter } from '../../edi-integration/actions';
// ui
import { Box, Flex } from '../../../ui';
// hocs
import { withFilterTableList } from '../../../hocs';
// feature notification
import MarkComponent from './mark';
import FooterComponent from './footer';
import NotificationHeader from './header';
import { withDeleteNotifications } from '../hocs';
import NotificationItem from './notification-item';
import {
  selectItem,
  getItemListRequest,
  markAllAsReadRequest,
  deleteAllNotifications,
  deleteSelectedNotifications,
} from '../actions';
import {
  makeSelectWsCount,
  makeSelectItemList,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectUnreadCount,
} from '../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withState('allRead', 'setRead', {}),
  withState('closeLoader', 'showLoader', true),
  withDeleteNotifications,
  withHandlers({
    handlerOnEnter: (props: Object) => () => {
      if (R.gt(props.totalCount, props.pagination.offset)) {
        props.getItemListRequest(true);
      }
    },
    handleRead: (props: Object) => (entity: any) => {
      const data = {
        guids: R.of(Array, entity.mainGuid),
        read: true,
      };
      if (G.isFalse(entity.read)) {
        props.markAllAsReadRequest(data);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      if (G.isNilOrEmpty(this.props.notificationList)) {
        this.props.getItemListRequest(true);
        this.props.showLoader(false);
      }
    },
  }),
);

const renderNotificationItem = (props: Object) => {
  const { handleRead, selectItem, onHidePopup, notificationList, setExternalIdFilter } = props;

  if (G.isNotNilAndNotEmpty(notificationList)) {
    return props.filteredTableList.map(
      (item: Object, key: number) => (
        <NotificationItem
          {...item}
          key={key}
          check={selectItem}
          withCheckbox={true}
          readAction={handleRead}
          actionHide={onHidePopup}
          setExternalIdFilter={setExternalIdFilter}
        />
      ));
  }
};

export const NotificationComponent = withFilterTableList(
  'searchString',
  'notificationList',
  ['notificationList'],
  {
    joiner: '',
    omitFields: [
      'guid',
      'version',
      'mainGuid',
      'selected',
      'objectGuid',
      'alarmEnabled',
      'lastModifiedBy',
      'triggeredValue',
      'lastModifiedDate',
    ],
    withoutValues: [null, undefined, ''],
  },
)((props: Object) => {
  const {
    loading,
    wsCount,
    totalCount,
    pagination,
    selectItem,
    unreadCount,
    onHidePopup,
    handlerOnEnter,
    notificationList,
    markAllAsReadRequest,
    handleChangeFilterInput,
    handleDeleteAllNotifications,
    handleDeleteSelectedNotifications } = props;
  const middleGreyColor = G.getTheme('colors.light.middleGrey');

  return (
    <Box
      top='26px'
      left='50%'
      zIndex={30}
      width='700px'
      position='fixed'
      cursor='default'
      borderRadius='5px'
      border='1px solid'
      height='max-content'
      transform='translate(-50%)'
      boxShadow='2px 3px 30px 0px #545454'
      borderColor={G.getTheme('colors.light.mainRed')}
      background={G.getTheme('colors.light.lightGrey')}
    >
      {
        G.ifElse(
          G.isNilOrEmpty(notificationList),
          <LocalLoader
            width='100%'
            minHeight='60px'
            height='max-content'
            localLoaderOpen={loading}
          >
            <Flex
              width='100%'
              p='50px 0px'
              fontSize='36px'
              justifyContent='center'
              color={middleGreyColor}
            >
              {G.getWindowLocale('titles:empty-list', 'Empty List')}
            </Flex>
          </LocalLoader>,
          <Box width='100%'>
            <NotificationHeader
              totalCount={wsCount}
              unreadCount={unreadCount}
              onHidePopup={onHidePopup}
              handleChangeFilter={handleChangeFilterInput}
            />
            <MarkComponent
              selectAll={selectItem}
              actionRead={markAllAsReadRequest}
              check={G.isAllChecked(R.values(notificationList))}
              handleDeleteAllNotifications={handleDeleteAllNotifications}
              handleDeleteSelectedNotifications={handleDeleteSelectedNotifications}
            />
            <Flex
              bg='white'
              width='100%'
              overflowY='auto'
              maxHeight='300px'
              height='max-content'
              flexDirection='column'
              alignItems='flex-start'
              borderBottom='1px solid'
              borderColor={middleGreyColor}
            >
              {renderNotificationItem(props)}
              {
                R.gt(totalCount, pagination.offset) &&
                <Waypoint onEnter={handlerOnEnter} />
              }
            </Flex>
            <FooterComponent actionHide={onHidePopup} />
          </Box>,
        )
      }
    </Box>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  wsCount: makeSelectWsCount(state),
  loading: makeSelectListLoading(state),
  pagination: makeSelectPagination(state),
  totalCount: makeSelectTotalCount(state),
  unreadCount: makeSelectUnreadCount(state),
  notificationList: makeSelectItemList(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  selectItem,
  getItemListRequest,
  setExternalIdFilter,
  markAllAsReadRequest,
  deleteAllNotifications,
  deleteSelectedNotifications,
})(enhance(NotificationComponent));
