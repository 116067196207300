import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { ReactSelect } from '../../../ui';
// feature new-do
import { HeaderFieldWrapper } from '../ui';
import { isPageCreateFromQuote } from '../helpers';
import { InputWrapper } from '../forms/formik/input-wrapper';
import {
  PAGE_TYPE_CLO_TEMPLATE,
  PAGE_TYPE_CREATE_FROM_EDI,
  PAGE_TYPE_CREATE_FROM_PUBLIC_CLO,
  PAGE_TYPE_CREATE_FROM_ORDER_QUOTE,
} from '../constants';
//////////////////////////////////////////////////

const getSelectHandler = ({ pageType, handleChangeSearchInput, handleChangeSearchInputOnQuote }: Object) => G.ifElse(
  isPageCreateFromQuote(pageType),
  handleChangeSearchInputOnQuote,
  handleChangeSearchInput,
);

const BranchSearch = (props: Object) => {
  const { pageType, branchInfo, branchList } = props;

  const sortByName = R.sortBy(R.compose(R.toLower, R.prop('label')));

  const options = R.compose(
    sortByName,
    R.uniqBy(R.prop(GC.FIELD_VALUE)),
    R.map((item: Object) => (
      {
        value: R.prop(GC.FIELD_GUID, item),
        label: R.prop(GC.FIELD_NAME, item),
        isDisabled: R.not(R.prop(GC.FIELD_ACTIVE, item)),
      }
    )),
  )(branchList);

  const selectHandler = getSelectHandler(props);

  const value = G.ifElse(R.propEq('', GC.FIELD_VALUE, branchInfo), null, branchInfo);

  const disabled = R.includes(
    pageType,
    [
      PAGE_TYPE_CLO_TEMPLATE,
      PAGE_TYPE_CREATE_FROM_EDI,
      PAGE_TYPE_CREATE_FROM_PUBLIC_CLO,
      PAGE_TYPE_CREATE_FROM_ORDER_QUOTE,
    ],
  );

  return (
    <InputWrapper
      inputWrapperStyles={{ zIndex: 16 }}
      label={G.getWindowLocale('titles:select-customer', 'Select Customer')}
    >
      <HeaderFieldWrapper>
        <ReactSelect
          name='branch'
          value={value}
          options={options}
          isClearable={true}
          isDisabled={disabled}
          onChange={selectHandler}
          shouldNotGetValueFromOptions={true}
        />
      </HeaderFieldWrapper>
    </InputWrapper>
  );
};

export default BranchSearch;
