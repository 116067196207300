import * as R from 'ramda';
import React from 'react';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, Flex, SectionsDivider } from '../../../ui';
// feature rate
import { marginViolationFields } from '../settings';
//////////////////////////////////////////////////

const getTitle = (criticalMarginViolated: boolean = false, maxPayViolated: boolean = false) => {
  if (maxPayViolated) {
    return G.getWindowLocale('titles:max-trip-pay-violation', 'Trip Max Pay Violation');
  }

  if (criticalMarginViolated) {
    return G.getWindowLocale('titles:critical-margin-violation', 'Critical Margin Violation');
  }

  return G.getWindowLocale('titles:margin-violation', 'Margin Violation');
};

const MarginViolationSection = (props: Object) => {
  const {
    marginViolated,
    maxPayViolated,
    optionsForSelect,
    criticalMarginViolated,
  } = props;

  const marginViolationReasonOptions = R.path(['marginViolationReasonOptions'], optionsForSelect);

  const violated = G.isAnyTrue(marginViolated, criticalMarginViolated, maxPayViolated);

  return (
    <Box
      overflow='hidden'
      transition='all 0.35s linear'
      height={G.ifElse(violated, 115, 0)}
    >
      {
        violated &&
        <Flex height={114}>
          <Box
            m='0 15px'
            width={130}
            fontWeight='bold'
            color={G.getTheme('colors.light.lightRed')}
          >
            {getTitle(criticalMarginViolated, maxPayViolated)}
          </Box>
          <Fieldset2
            {...G.getFormikProps(props)}
            fields={marginViolationFields}
            marginViolationReasonOptions={marginViolationReasonOptions}
            fieldsWrapperStyles={{
              mt: 10,
              pr: 15,
              width: 800,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          />
        </Flex>
      }
      <SectionsDivider />
    </Box>
  );
};

export default MarginViolationSection;
