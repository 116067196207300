import * as R from 'ramda';
import * as P from 'plow-js';
import React, { Component } from 'react';
// helpers/constants
import * as G from '../../../helpers';
// utilities
import { sendRequest } from '../../../utilities/http';
//////////////////////////////////////////////////

// TODO: refactor with functional component

export class AsyncData extends Component {
  constructor(props: Object) {
    super(props);
    this.state = {
      data: null,
      error: false,
      loading: true,
    };
    this.setLoading = this.setLoading.bind(this);
    this.getFilteredDataRequest = this.getFilteredDataRequest.bind(this);
  }

  setLoading() {
    this.setState({
      loading: true,
    });
  }

  async getFilteredDataRequest(options: Object) {
    const { asyncEndpoint, asyncMethod } = this.props;

    const res = await sendRequest(asyncMethod, asyncEndpoint, options);

    const { status, data } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNil(data)) {
        const newState = P.$all(
          P.$set('data', data),
          P.$set('loading', false),
          this.state,
        );
        this.setState(newState);
      }
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withDataFilter -> getFilteredDataRequest',
      );
    }
  }

  render() {
    return (
      <div>
        {
          this.props.render(
            this.state,
            this.setLoading,
            this.getFilteredDataRequest,
          )
        }
      </div>
    );
  }
}

export const withDataFilterHoc = (Component: any) => (
  class extends React.Component {
    render() {
      return (
        <AsyncData
          asyncEndpoint={this.props.asyncEndpoint}
          asyncMethod={R.or(this.props.asyncMethod, 'get')}
          render={
            (parentState: Object, setLoading: Function, getFilteredDataRequest: Function) => (
              <Component
                {...this.props}
                setLoading={setLoading}
                asyncData={parentState}
                getFilteredDataRequest={getFilteredDataRequest} />
          )} />
      );
    }
  }
);
