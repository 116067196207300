import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  compose,
  withState,
  lifecycle,
} from 'react-recompose';
// components
import { openModal, closeModal } from '../../components/modal/actions';
// features
import { sendLogOutRequest } from '../auth/actions';
import { switchBranchRequest } from '../branch/actions';
import { makeSelectUnreadCount } from '../notification/selectors';
import { makeSelectCurrentUserSettingsFields } from '../profile/selectors';
import { setExpandedContainerOptions } from '../expanded-container/actions';
import { makeSelectStyling, makeSelectMainStyling } from '../styling/selectors';
import { makeSelectCurrentBranch, makeSelectBranchesTree } from '../branch/selectors';
import { makeSelectShipmentList, makeSelectAuthorizedLoadBoards } from '../load-board/selectors';
// helpers/constants
import * as G from '../../helpers';
import { getCurrentMenuItem } from '../../helpers/menu';
// feature navbar-menu
import { clickOnItemAction } from './actions';
import CarrierMenu from './components/carrier-menu';
import RegularMenu from './components/regular-menu';
//////////////////////////////////////////////////

const enhance = compose(
  withState('currentMenuItem', 'setCurrentMenuItem', getCurrentMenuItem()),
  lifecycle({
    componentDidUpdate({currentMenuItem, setCurrentMenuItem}: Object) {
      const current = getCurrentMenuItem();

      if (G.notEquals(current, currentMenuItem)) {
        setCurrentMenuItem(current);
      }
    },
  }),
);

const NavbarMenuComponent = (props: Object) => {
  const isCarrier = G.isCurrentUserTypeCarrier();

  if (isCarrier) return <CarrierMenu {...props} />;

  return <RegularMenu {...props} />;
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  styling: makeSelectStyling(state),
  rootBranch: makeSelectBranchesTree(state),
  mainStyling: makeSelectMainStyling(state),
  currentBranch: makeSelectCurrentBranch(state),
  loadBoardShipments: makeSelectShipmentList(state),
  unreadNotificationsCount: makeSelectUnreadCount(state),
  authorizedLoadBoards: makeSelectAuthorizedLoadBoards(state),
  currentUserSettings: makeSelectCurrentUserSettingsFields(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  clickOnItemAction,
  sendLogOutRequest,
  switchBranchRequest,
  setExpandedContainerOptions,
})(enhance(NavbarMenuComponent));
