import React from 'react';
import * as R from 'ramda';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { ReactSelect } from '../../../ui';
// feature new-do
import { isPageCreateDO } from '../helpers';
import { Input, HeaderFieldWrapper } from '../ui';
import { InputWrapper } from '../forms/formik/input-wrapper';
//////////////////////////////////////////////////

const mapTypes = R.map(
  (item: Object) => ({ label: R.prop(GC.FIELD_NAME, item), value: R.prop(GC.FIELD_GUID, item) }),
);

const CustomerLoadNumber = (props: Object) => {
  const { customerLoadNumber, primaryRefTypeName, handleChangeCustomerLoadNumberInput } = props;

  const disabled = R.not(G.getHasAmousCurrentUserPermissionFromWindow(PC.CLO_CHANGE_PRIMARY_REFERENCE_EXECUTE));

  return (
    <InputWrapper
      withEllipsedLabel={true}
      label={R.or(primaryRefTypeName, G.getWindowLocale('titles:customer-load-#', 'Customer Load #'))}
    >
      <HeaderFieldWrapper>
        <Input
          width={160}
          disabled={disabled}
          name='customerLoadNumber'
          value={customerLoadNumber}
          onChange={(event: Object) => handleChangeCustomerLoadNumberInput(event.target.value)}
        />
      </HeaderFieldWrapper>
    </InputWrapper>
  );
};

const ReferenceType = (props: Object) => {
  const {
    branchRefTypes,
    primaryReference,
    primaryRefTypeGuid,
    primaryRefSequenceValue,
    handleChangeBranchRefTypeInput,
  } = props;

  const options = mapTypes(R.pathOr([], [GC.REF_SCOPE_NAME_CLO], branchRefTypes));

  const value = G.ifElse(R.propEq('', GC.FIELD_VALUE, primaryReference), null, primaryReference);

  if (R.or(
    G.isNilOrEmpty(primaryRefSequenceValue),
    G.isNilOrEmpty(primaryRefTypeGuid),
  )) {
    return (
      <InputWrapper
        inputWrapperStyles={{ zIndex: 16 }}
        label={G.getWindowLocale('titles:reference-type', 'Reference Type')}
      >
        <HeaderFieldWrapper>
          <ReactSelect
            value={value}
            options={options}
            isClearable={true}
            name='referenceType'
            shouldNotGetValueFromOptions={true}
            onChange={handleChangeBranchRefTypeInput}
          />
        </HeaderFieldWrapper>
      </InputWrapper>
    );
  }

  return null;
};

const CustomerReference = (props: Object) => {
  const {
    pageType,
    validationErrors,
    primaryRefTypeName,
    customerRefTypeName,
    customerReferenceValue,
    handleChangeCustomerReferenceValueInput,
  } = props;

  if (R.or(R.not(isPageCreateDO(pageType)), G.isNilOrEmpty(customerRefTypeName))) return null;

  const hasError = R.compose(
    G.isNotNilAndNotEmpty,
    R.find(R.equals('Customer Reference')),
  )(R.or(validationErrors, []));

  const label = G.ifElse(
    R.equals(primaryRefTypeName, customerRefTypeName),
    `${customerRefTypeName} (${
      G.getWindowLocale('titles:customer-reference-type', 'Customer Reference Type')
    })`,
    customerRefTypeName,
  );

  return (
    <InputWrapper label={label} withEllipsedLabel={true}>
      <HeaderFieldWrapper>
        <Input
          width={160}
          hasError={hasError}
          name='customerReferenceValue'
          value={customerReferenceValue}
          onChange={(event: Object) => handleChangeCustomerReferenceValueInput(event.target.value)}
        />
      </HeaderFieldWrapper>
    </InputWrapper>
  );
};

const OrderType = (props: Object) => {
  const { orderType, branchConfigs, setValueToStore } = props;

  const useOrderType = G.getConfigValueFromStore(GC.CLO_GENERAL_USE_ORDER_TYPE, branchConfigs);
  const useRequiredOrderType = G.getConfigValueFromStore(GC.CLO_GENERAL_ORDER_TYPE_REQUIRED, branchConfigs, false);

  const options = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    branchConfigs,
    GC.CLO_ORDER_TYPE_DROPDOWN,
    false,
  );

  if (G.isTrue(useOrderType)) {
    return (
      <InputWrapper
        isRequired={useRequiredOrderType}
        inputWrapperStyles={{ zIndex: 16 }}
        label={G.getWindowLocale('titles:order-type', 'Order Type')}
      >
        <HeaderFieldWrapper>
          <ReactSelect
            name='orderType'
            options={options}
            value={orderType}
            isClearable={true}
            onChange={(item: any) => setValueToStore({ value: R.path(['value'], item), path: 'orderType' })}
          />
        </HeaderFieldWrapper>
      </InputWrapper>
    );
  }

  return null;
};

export {
  OrderType,
  ReferenceType,
  CustomerReference,
  CustomerLoadNumber,
};
