import React from 'react';
// helpers
import * as G from '../../../helpers';
// ui
import { Box, StickedFlex } from '../../../ui';
// feature notification2
import NotificationList from './notification-list';
//////////////////////////////////////////////////

const NotificationGroup = (props: Object) => {
  const {
    groupedList,
    withoutGroup,
    groupedListHeight,
    notificationItemHeight,
    markAsReadNotification,
    notificationTitleGroupHeight,
  } = props;

  return (
    <Box height={groupedListHeight}>
      {groupedList.map((item: Object, i: number) => (
        <Box key={i}>
          <StickedFlex
            px={15}
            top={46}
            color='dark.mainDark'
            height={notificationTitleGroupHeight}
            background={G.getTheme('tables.subTitle.bgColor')}
          >
            {item.title}
          </StickedFlex>
          <NotificationList
            list={item.list}
            withoutGroup={withoutGroup}
            notificationItemHeight={notificationItemHeight}
            markAsReadNotification={markAsReadNotification}
          />
        </Box>
      ))}
    </Box>
  );
};

export default NotificationGroup;
