import React from 'react';
// features
import Audit2 from '../../audit2';
// helpers/constants
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const AuditTab = ({ guid }: Object) => {
  const auditProps = {
    useFullScreen: true,
    maxHeight: 'calc(100vh - 215px)',
    requestOptions: {
      [GC.FIELD_OBJECT_GUID]: guid,
      [GC.FIELD_TYPE]: GC.AUDIT_TYPE_USER,
    },
  };

  return <Audit2 {...auditProps} />;
};

export default AuditTab;
