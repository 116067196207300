import React from 'react';
// features
import NotificationItem from './notification-item';
// feature notification2
import { getBorderStyles } from '../helpers';
/////////////////////////////////////////////////

const NotificationList = (props: Object) => {
  const {
    list,
    withoutGroup,
    markAsReadNotification,
    notificationItemHeight,
  } = props;

  return (
    list.map((item: Object, index: number) => (
      <NotificationItem
        {...item}
        key={index}
        withoutGroup={withoutGroup}
        borderStyle={getBorderStyles(list, index)}
        markAsReadNotification={markAsReadNotification}
        notificationItemHeight={notificationItemHeight}
      />
    ))
  );
};

export default NotificationList;
