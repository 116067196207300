import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { Table } from '../../components/table';
import { TitlePanel } from '../../components/title-panel';
import { EditReport } from '../../components/edit-report';
import { PageActions } from '../../components/page-actions';
import { ConfirmComponent } from '../../components/confirm';
import { withPromptModal } from '../../components/edit-report/hocs';
import { openModal, closeModal } from '../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../components/edit-report/helpers';
// features
import { AuthWrapper } from '../permission';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withFixedPopover } from '../../hocs';
// icons
import * as I from '../../svgs';
// report-common
import { reportEnhancer } from '../../report-common';
// ui
import { Box, ListWrapper, ZOrderWrapper } from '../../ui';
// feature rate-shop
import { tableSettings } from './settings/table';
import { RowActions } from './components/row-actions';
import ImportFromFileSection from './components/title-panel-actions';
import {
  FILTER_PARAMS,
  excelRattingSummaryReport,
  EXCEL_RESULTS_FILTER_PARAMS,
  excelRattingSummaryColumnSettings,
} from './settings';
import {
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
  makeSelectSelectedListGuids,
} from './selectors';
import {
  selectItem,
  setReports,
  setUsedReport,
  setInitialState,
  setTableTitleSort,
  getItemListRequest,
  toggleExcelResults,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  resetListAndPagination,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
  getExcelResultsByRowGuidRequest,
  removeExcelMassRatingSummaryRequest,
} from './actions';
//////////////////////////////////////////////////

const enhance = compose(
  withFixedPopover,
  reportEnhancer,
  withPromptModal(EXCEL_RESULTS_FILTER_PARAMS),
  withHandlers({
    handleToggleExcelResults: ({ toggleExcelResults, getExcelResultsByRowGuidRequest }: Object) => (row: Object) => {
      const excelMassRatingSummaryGuid = G.getGuidFromObject(row);

      toggleExcelResults(excelMassRatingSummaryGuid);

      if (R.propEq(false, 'expanded', row)) {
        getExcelResultsByRowGuidRequest({
          excelMassRatingSummaryGuid,
          pagination: R.pathOr({ limit: 20, offset: 0 }, ['excelResults', 'pagination'], row),
        });
      }
    },
    handleReloadList: ({ getItemListRequest, resetListAndPagination }: Object) => () => {
      resetListAndPagination();
      setTimeout(() => getItemListRequest(), 100);
    },
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          disableSortByFields={true}
          usedReport={selectedReport}
          requestPending={requestPending}
          onReportSet={() => getItemListRequest()}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
        />
      );

      const modal = G.getDefaultReportModal(component, 1150);

      openModal(modal);
    },
    handleDelete: (props: Object) => (guid: string) => {
      const {
        openModal,
        selectedList,
        closeFixedPopup,
        removeExcelMassRatingSummaryRequest,
      } = props;

      G.callFunction(closeFixedPopup);

      if (R.and(R.isEmpty(selectedList), R.not(G.isString(guid)))) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      const guids = G.ifElse(G.isString(guid), R.of(Array, guid), selectedList);
      const component = (
        <ConfirmComponent
          textLocale={G.getWindowLocale(
            'messages:confirm-delete-entities',
            'Are you sure you want to delete these entities?',
          )}
        />
      );
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              version: 2,
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => removeExcelMassRatingSummaryRequest(guids),
            },
          ],
        },
      };
      openModal(modal);
    },
  }),
  withHandlers({
    handleClickEditIcon: (props: Object) => (e: Object, data: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: (
          <RowActions
            openModal={props.openModal}
            closeModal={props.closeModal}
            guid={G.getGuidFromObject(data)}
            handleDelete={props.handleDelete}
            closeFixedPopup={props.closeFixedPopup}
          />
        ),
      })
    ),
  }),
  pure,
);

// TODO: check permissions
const renderRowActions = (data: Object, handleClickEditIcon: Function) => (
  <AuthWrapper has={null}>
    <Box px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, data)}>
      {I.threeDots()}
    </Box>
  </AuthWrapper>
);

export const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    pagination,
    selectItem,
    reportList,
    handleDelete,
    filterParams,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleClickEditIcon,
    handleTableTitleFilter,
    handleToggleExcelResults,
  } = props;

  if (R.not(selectedReport)) return null;

  const allChecked = G.isAllChecked(itemList);

  const actionButtons = [
    {
      iconName: 'trash',
      action: handleDelete,
    },
  ];

  const data = {
    loading,
    allChecked,
    totalCount,
    pagination,
    actionButtons,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    handleTableTitleFilter,
    onOptionClick: selectItem,
    useSearchableColumns: true,
    itemList: R.values(itemList),
    useNewTitleFilterInputs: true,
    toggle: handleToggleExcelResults,
    report: excelRattingSummaryReport,
    columnSettings: excelRattingSummaryColumnSettings,
    filterProps: R.indexBy(
      R.prop(GC.FIELD_VALUE),
      transformPropDataFromSelectToString(FILTER_PARAMS),
    ),
    handleLoadMoreEntities: G.ifElse(
      loading,
      () => {},
      getItemListRequest,
    ),
    renderRightStickedComponent: (data: Object) => renderRowActions(data, handleClickEditIcon),
    tableSettings: G.getTableSettingsWithMaxHeightByConditions({
      reportList,
      filterParams,
      tableSettings,
      selectedReport,
    }),
  };

  return <Table {...data} />;
};

const RateShop = (props: Object) => {
  const { handleDelete, handleReloadList } = props;

  return (
    <ListWrapper p={15} bgColor={G.getTheme('pages.layOutBgColor')}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          version={2}
          display='flex'
          withCount={true}
          withReload={true}
          noExportable={true}
          popperWithCount={true}
          fromDispatchBoard={true}
          withoutQuickFilter={true}
          hiddenRightFilterInfo={false}
          reloadAction={handleReloadList}
          type={GC.EXCEL_RATING_RESULT_REPORT}
          filterProps={EXCEL_RESULTS_FILTER_PARAMS}
          additionalComponent={<ImportFromFileSection />}
          title={G.getWindowLocale('titles:rate-shop', 'Rate Shop')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex='1'>
        {renderTable(props)}
      </ZOrderWrapper>
      <PageActions
        shadowColor={G.getTheme('createButton.shadowColor')}
        count={R.pathOr(0, ['selectedList', 'length'], props)}
        mainAction={{
          showCount: true,
          action: handleDelete,
          color: G.getTheme('colors.light.mainRed'),
          text: G.getWindowLocale('actions:delete', 'Delete'),
        }}
      />
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  pageVisited: makeSelectPageVisited(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  selectedList: makeSelectSelectedListGuids(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  selectItem,
  setReports,
  setUsedReport,
  setInitialState,
  setTableTitleSort,
  getItemListRequest,
  toggleExcelResults,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  resetListAndPagination,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
  getExcelResultsByRowGuidRequest,
  removeExcelMassRatingSummaryRequest,
})(enhance(RateShop));
