import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// features
import { saveImportListSuccess } from '../sockets-v2/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature new-import
import * as A from './actions';
import * as C from './constants';
//////////////////////////////////////////////////

const initialState = {
  errors: [],
  emptyRow: {},
  importList: [],
  importId: null,
  rolesGuids: null,
  importType: null,
  failedFields: [],
  sourceType: null,
  globalErrors: null,
  contractGuid: null,
  additionalFields: [],
  importFromFileData: [],
  cleanExistentRates: true,
  mappers: {
    [C.IMPORT_TYPE_CLO]: {},
  },
  configOptions: {
    [C.IMPORT_TYPE_CLO]: {},
    [C.IMPORT_TYPE_TRUCK]: {},
    [C.IMPORT_TYPE_DRIVER]: {},
    [C.IMPORT_TYPE_BRANCH]: {},
    [C.IMPORT_TYPE_CARRIER]: {},
    [C.IMPORT_TYPE_TRAILER]: {},
    [C.IMPORT_TYPE_LOCATION]: {},
    [C.IMPORT_TYPE_GEO_FENCING_ZONE]: {},
    [C.IMPORT_TYPE_CARRIER_INSURANCE]: {},
  },
};

const setErrors = (state: Object, data: Object) => P.$set('errors', data, state);

const setInitialState = ({ mappers }: Object) => R.assoc('mappers', mappers, initialState);

const toggleCleanExistentRates = (state: Object) => P.$toggle('cleanExistentRates', state);

const setImportId = (state: Object, importId: string) => P.$set('importId', importId, state);

const setGlobalErrors = (state: Object, data: Object) => P.$set('globalErrors', data, state);

const setSourceType = (state: Object, sourceType: string) => P.$set('sourceType', sourceType, state);

const setAdditionalFields = (state: Object, data: string) => P.$set('additionalFields', data, state);

const setRolesGuids = (state: Object, rolesGuids: Object) => P.$set('rolesGuids', rolesGuids, state);

const getAvailableImportMappersSuccess = (state: Object, data: Object) => P.$set('mappers', data, state);

const setContractGuid = (state: Object, contractGuid: string) => P.$set('contractGuid', contractGuid, state);

const getImportFromFileDataSuccess = (state: Object, data: Object) => P.$set('importFromFileData', data, state);

const getImportTemplateSuccess = (state: Object, { data, type }: Object) => {
  const emptyRow = R.compose(
    R.indexBy(R.prop(GC.FIELD_NAME)),
    R.map(({ type, name, index, required, fieldName, dropDownName }: Object) => ({
      name,
      type,
      required,
      fieldName,
      dropDownName,
      readOnly: false,
      index: R.inc(index),
      [GC.FIELD_VALUE]: null,
      [GC.FIELD_LABEL]: null,
    })),
  )(R.or(data, []));

  return P.$all(
    P.$set('importId', null),
    P.$set('importList', []),
    P.$set('importType', type),
    P.$set('emptyRow', emptyRow),
    state,
  );
};

const addTableRows = (state: Object, count: number) => {
  const { emptyRow, importList } = state;

  const rows = R.repeat(emptyRow, count);

  if (G.isNilOrEmpty(importList)) return P.$set('importList', rows, state);

  const newImportList = R.concat(importList, rows);

  return P.$set('importList', newImportList, state);
};

const changeImportList = (state: Object, data: Object) => (
  P.$set('importList', data, state)
);

const removeSelectedRows = (state: Object, data: Object) => {
  const { importList} = state;

  if (G.isNilOrEmpty(data)) return P.$set('importList', R.dropLast(1, importList), state);

  const reduceIndexed = R.addIndex(R.reduce);

  const newList = R.compose(
    R.values,
    R.omit(data),
    reduceIndexed((acc: Object, curr: Object, index: number) => R.assoc(index, curr, acc), {}),
  )(importList);

  return P.$set('importList', newList, state);
};

const saveImportList = (state: Object, { guid, results, globalErrors }: Object) => {
  const { emptyRow, importId, importType, importList, additionalFields, importFromFileData } = state;

  let fullImportList = R.or(importList, []);
  const lastElement = R.inc(R.length(R.values(emptyRow)));
  const rowWithAdditionalFields = R.compose(
    R.mergeRight(emptyRow),
    R.indexBy(R.prop(GC.FIELD_NAME)),
    G.mapIndexed((fieldName: string, index: number) => ({
      fieldName,
      [GC.FIELD_VALUE]: null,
      [GC.FIELD_LABEL]: null,
      [GC.FIELD_NAME]: fieldName,
      index: R.add(index, lastElement),
    })),
  )(R.or(additionalFields, []));

  if (G.isNotNilAndNotEmpty(importFromFileData)) {
    const mappedImportData = R.map((row: Object) => {
      const values = R.compose(
        R.map((item: Object) => {
          const { name } = item;

          const value = R.pathOr(null, [name], row);

          return R.mergeRight(item, { value, [GC.FIELD_LABEL]: value });
        }),
        R.pick(R.keys(row)),
      )(rowWithAdditionalFields);

      return R.mergeRight(rowWithAdditionalFields, values);
    }, importFromFileData);

    if (R.equals(importType, C.IMPORT_TYPE_CLO)) {
      fullImportList = mappedImportData;
    } else {
      fullImportList = R.concat(importList, mappedImportData);
    }
  }

  if (G.isNilOrEmpty(fullImportList)) return state;

  if (G.notEquals(guid, importId)) return state;

  const failedRows = R.compose(
    R.indexBy(R.prop('index')),
    R.map((row: Object) => {
      const invalidFields = R.compose(
        R.indexBy(R.prop('fieldName')),
        R.pathOr([], ['error', 'invalidFields']),
      )(row);

      return R.assoc('invalidFields', invalidFields, row);
    }),
    R.filter(({ importStatus }: Object) => G.notContain(importStatus, ['UPDATED', 'CREATED'])),
  )(R.or(results, []));
  const newList = R.compose(
    G.filterIndexed((_: any, index: number) => R.has(index, failedRows)),
    G.mapIndexed((row: Object, index: number) => {
      const failedCells = R.compose(
        R.map(({ message, fieldName }: Object) => {
          const failedCell = R.pathOr({}, [fieldName], row);

          return R.mergeRight(failedCell, { error: message, className: 'error' });
        }),
        R.pathOr([], [index, 'invalidFields']),
      )(failedRows);

      if (G.isNilOrEmpty(failedCells)) return row;

      return R.mergeRight(row, failedCells);
    }),
  )(fullImportList);
  const errors = R.compose(
    R.map((item: Object) => ({
      index: item.index,
      errorMessage: R.path(['error', GC.FIELD_MESSAGE], item),
    })),
    R.filter(({ importStatus }: Object) => R.equals(importStatus, 'ERROR')),
  )(R.or(results, []));

  return P.$all(
    P.$set('errors', errors),
    P.$set('importList', newList),
    P.$set('globalErrors', globalErrors),
    P.$set('emptyRow', rowWithAdditionalFields),
    state,
  );
};

const changeTableCell = (state: Object, { path, cell }: Object) => P.$set(path, cell, state);

const getBranchConfigsSuccess = (state: Object, { type, dropdowns }: Object) => {
  const dropdownsOptions = R.compose(
    R.map(({ options }: Object) => R.map(({ storedValue, displayedValue }: Object) => ({
      [GC.FIELD_VALUE]: storedValue,
      [GC.FIELD_LABEL]: displayedValue,
    }), options)),
    R.indexBy(R.prop(GC.FIELD_NAME)),
  )(dropdowns);

  return P.$set(`configOptions.${type}`, dropdownsOptions, state);
};

const addAdditionalFieldToTable = (state: Object, { value, prefix }: string) => {
  const { emptyRow, importList } = state;

  const cellName = `${prefix}:${value}`;

  const additionalCell = {
    value: null,
    label: null,
    name: cellName,
    required: false,
    fieldName: cellName,
    className: 'success',
  };

  if (G.isNilOrEmpty(importList)) {
    return P.$set('emptyRow', { ...emptyRow, [cellName]: additionalCell }, state);
  }

  const newList = R.map((row: Object) => ({ ...row, [cellName]: additionalCell }), importList);

  return P.$all(
    P.$set('importList', newList),
    P.$set('emptyRow', { ...emptyRow, [cellName]: additionalCell }),
    state,
  );
};

export default createReducer({
  [A.setErrors]: setErrors,
  [A.setImportId]: setImportId,
  [A.addTableRows]: addTableRows,
  [A.setRolesGuids]: setRolesGuids,
  [A.setSourceType]: setSourceType,
  [A.setInitialState]: setInitialState,
  [A.changeTableCell]: changeTableCell,
  [A.setContractGuid]: setContractGuid,
  [A.setGlobalErrors]: setGlobalErrors,
  [A.changeImportList]: changeImportList,
  [saveImportListSuccess]: saveImportList,
  [A.removeSelectedRows]: removeSelectedRows,
  [A.setAdditionalFields]: setAdditionalFields,
  [A.getBranchConfigsSuccess]: getBranchConfigsSuccess,
  [A.getImportTemplateSuccess]: getImportTemplateSuccess,
  [A.toggleCleanExistentRates]: toggleCleanExistentRates,
  [A.addAdditionalFieldToTable]: addAdditionalFieldToTable,
  [A.getImportFromFileDataSuccess]: getImportFromFileDataSuccess,
  [A.getAvailableImportMappersSuccess]: getAvailableImportMappersSuccess,
}, initialState);
