import * as R from 'ramda';
import React, { useMemo, useCallback } from 'react';
// components
import { Table } from '../../../components/table';
// helpers/constants
import * as G from '../../../helpers';
// feature payroll
import { A } from '../hooks/use-toll-charges';
import { getRemoveRestoreColumn } from './active-tab';
import {
  getTollReport,
  tollTableSettings,
  tollColumnSettings,
  tableSettingsNoActions,
} from '../settings/charges-reports-settings';
//////////////////////////////////////////////////

const TollChargesTab = (props: Object) => {
  const {
    maxHeight,
    noActions,
    selectTollCharge,
    omitReportFields = [],
    handleOpenDriverProfile,
    removeOrRestoreTollCharge,
    // report
    tollCharges,
    tollChargesLoading,
    tollChargesTotalCount,
    tollChargesReportDispatch,
    tollChargesTitleSortValues,
    tollChargesTableTitleFilters,
  } = props;

  const report = useMemo(() =>
    getTollReport(omitReportFields, noActions),
    [noActions, omitReportFields],
  );

  const columnSettings = useMemo(
    () => {
      const settings = R.omit(omitReportFields, tollColumnSettings);

      if (noActions) return settings;

      return R.assoc(
        'removeRestoreColumn',
        getRemoveRestoreColumn('tollCharges', removeOrRestoreTollCharge),
        settings,
      );
    },
    [noActions, removeOrRestoreTollCharge],
  );

  const allChecked = useMemo(() => G.isAllChecked(tollCharges), [tollCharges]);

  const tableSettings = useMemo(() => {
    if (noActions) return R.assoc('maxHeight', maxHeight, tableSettingsNoActions);

    return R.assoc('maxHeight', maxHeight, tollTableSettings);
  }, [maxHeight, noActions]);

  const onOptionClick = useCallback(
    (guid: string) => selectTollCharge(guid),
    [selectTollCharge],
  );

  const handleTableTitleFilter = useCallback(
    (data: Object) => G.handleTableTitleFilter2(data, A, tollChargesReportDispatch),
  );

  return (
    <Table
      report={report}
      itemList={tollCharges}
      allChecked={allChecked}
      useSearchableColumns={true}
      tableSettings={tableSettings}
      onOptionClick={onOptionClick}
      useNewTitleFilterInputs={true}
      columnSettings={columnSettings}
      totalCount={tollChargesTotalCount}
      callbackData={{ handleOpenDriverProfile }}
      titleSortValues={tollChargesTitleSortValues}
      handleTableTitleFilter={handleTableTitleFilter}
      tableTitleFilters={tollChargesTableTitleFilters}
      loading={R.and(tollChargesLoading, G.isNilOrEmpty(tollCharges))}
      filterProps={G.getFilterPropsFromColumnSettings(tollColumnSettings)}
    />
  );
};

export default TollChargesTab;
