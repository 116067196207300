import { space } from 'styled-system';
import styled from 'styled-components';
//////////////////////////////////////////////////

export const Wrapper = styled.div`
  ${space}

  // TODO: remove after testing
  // z-index: 12;
  width: ${({ width }: Object) => width};
  z-index: ${({ zIndex }: Object) => zIndex};
  display: ${({ display }: Object) => display || 'flex'};
  justify-content: ${({ justifyContent }: Object) => justifyContent};
`;
