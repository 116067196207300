import * as R from 'ramda';
import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const store = ({ normalizeCurrency }: Object) => normalizeCurrency;

const makeSelectItemList = () => createSelector(
  store,
  ({ itemList }: Object) => R.sortBy(R.prop('index'), R.values(itemList)),
);

const makeSelectPagination = () => createSelector(
  store,
  ({ pagination }: Object) => pagination,
);

const makeSelectTotalCount = () => createSelector(
  store,
  ({ totalCount }: Object) => totalCount,
);

const makeSelectListLoading = () => createSelector(
  store,
  ({ loading }: Object) => loading,
);

const makeSelectUsedReport = () => createSelector(
  store,
  ({ usedReport }: Object) => usedReport,
);

const makeSelectActiveTab = () => createSelector(
  store,
  ({ activeTab }: Object) => activeTab,
);

export {
  makeSelectItemList,
  makeSelectActiveTab,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectUsedReport,
  makeSelectListLoading,
};
