import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

const apiDomain = process.env.API_DOMAIN;

// popup
const AUTH_POPUP_HEIGHT = 800;
const AUTH_POPUP_WIDTH = 800;
const AMOUS_MAIN_NAME = 'Amous Main';
const OAUTH_POPUP_NAME = 'Amous OAuth Popup';
const OAUTH2_CODE_RESPONSE = 'OAUTH2_CODE_RESPONSE';

// local storage
const authorizationState = 'amous-lb-auth-state';

const getAuthStateFromStorage = () => window.localStorage.getItem(authorizationState);
const removeAuthStateFromStorage = () => window.localStorage.removeItem(authorizationState);
const setAuthStateToStorage = (state: string) => window.localStorage.setItem(authorizationState, state);

// helpers
const openLBLoginPopup = (popupUrl: string) => {
  const top = R.subtract(R.add(R.divide(window.outerHeight, 2), window.screenY), R.divide(AUTH_POPUP_HEIGHT, 2));
  const left = R.subtract(R.add(R.divide(window.outerWidth, 2), window.screenX), R.divide(AUTH_POPUP_WIDTH, 2));

  return window.open(
    popupUrl,
    OAUTH_POPUP_NAME,
    `height=${AUTH_POPUP_HEIGHT},width=${AUTH_POPUP_WIDTH},top=${top},left=${left}`,
  );
};

const getLoadBoardOAuth2PopupPath = (type: string) => {
  const lbAuthData = R.path([type, apiDomain], GC.LOAD_BOARD_OAUTH2_MAP);

  const state = uuidv4();

  const {
    SCOPE,
    RESOURCE,
    CLIENT_ID,
    AUTH_SERVICE,
    REDIRECT_URI,
  } = lbAuthData;

  const path = `
    ${AUTH_SERVICE}?
    client_id=${CLIENT_ID}&
    ${G.ifElse(SCOPE, `scope=${SCOPE}&`, '')}
    redirect_uri=${REDIRECT_URI}&
    ${G.ifElse(RESOURCE, `resource=${RESOURCE}&`, '')}
    response_type=code&
    state=${state}
`.replace(/\s+/gm, ''); // trim all spaces

  return {
    path,
    state,
  };
};

export {
  openLBLoginPopup,
  getLoadBoardOAuth2PopupPath,
  //
  setAuthStateToStorage,
  getAuthStateFromStorage,
  removeAuthStateFromStorage,
  //
  AMOUS_MAIN_NAME,
  OAUTH_POPUP_NAME,
  OAUTH2_CODE_RESPONSE,
};
