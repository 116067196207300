// helpers/constants
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

const orderFields = [
  {
    sequence: 1,
    order: 'ASC',
    reference: false,
    name: GC.FIELD_UNIT_ID,
  },
];

const fields = [
  { name: GC.FIELD_UNIT_ID },
];

const getSearchFilter = (searchText: string) => ([
  {
    dataType: 'string',
    operation: 'contain',
    stringValue: searchText,
    propertyName: GC.FIELD_UNIT_ID,
  },
]);

export {
  fields,
  orderFields,
  getSearchFilter,
};
