import * as R from 'ramda';
import React from 'react';
import {
  pure,
  compose,
  withState,
  withHandlers,
} from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
// forms
import { Checkbox } from '../../../forms';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const updateListItemWithCheckedStatus = (items: Object, checked: boolean, guid: string) => (
  R.map((item: Object) => {
    if (R.equals(item.guid, guid)) {
      return R.assoc('checked', checked, item);
    }

    return item;
  }, items)
);

const updateListItemsWithCheckedStatus = (items: Object, checked: boolean) => (
  R.map((item: Object) => R.assoc('checked', checked, item), items)
);

const enhance = compose(
  withState('items', 'setItems', ({ items }: Object) => (
    updateListItemsWithCheckedStatus(items, false)
  )),
  withHandlers({
    handleChangeInput: (props: Object) => (event: Object) => {
      const { items, setItems } = props;

      const allOrGuid = event.target.name;
      const checked = event.target.checked;

      if (R.equals(allOrGuid, 'all')) {
        setItems(updateListItemsWithCheckedStatus(items, checked));
      } else {
        setItems(updateListItemWithCheckedStatus(items, checked, allOrGuid));
      }
    },
    handleOnSave: (props: Object) => () => {
      const { items, handleSave } = props;

      const clone = R.clone(items);

      handleSave(clone);
    },
  }),
  pure,
);

const SelectItem = ({ item, handleChangeInput }: Object) => (
  <Flex m='10px 0px' justifyContent='space-between'>
    {item.name}
    <Checkbox
      mr={30}
      type='checkbox'
      name={item.guid}
      checked={item.checked}
      onChange={handleChangeInput}
    />
  </Flex>
);

const SelectItems = ({ items, selectedAll, handleOnSave, handleChangeInput }: Object) => (
  <div>
    <Flex
      height={30}
      p='0 23px 0 30px'
      justifyContent='space-between'
      bg={G.getTheme('colors.mainBlue')}
    >
      <Box
        fontSize={14}
        fontWeight={600}
        lineHeight='30px'
        color={G.getTheme('forms.group.title.textColor')}
      >
        {G.getWindowLocale('titles:roles', 'Roles')}
      </Box>
      <Checkbox
        mr={30}
        name='all'
        type='checkbox'
        checked={selectedAll}
        onChange={handleChangeInput}
      />
    </Flex>
    <Box
      minHeight={120}
      maxHeight={170}
      overflowY='scroll'
      m='15px 15px 0 15px'
      p='15px 0 15px 15px'
      bg={G.getTheme('colors.white')}
    >
      {
        items.map((item: Object, index: number) => (
          <SelectItem key={index} item={item} handleChangeInput={handleChangeInput} />
        ))
      }
    </Box>
    <FormFooter2 boxStyles={{ p: 15 }} submitAction={handleOnSave} />
  </div>
);

export default enhance(SelectItems);
