import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../report-common';
//////////////////////////////////////////////////

const {
  selectItem,
  setReports,
  setReportType,
  setUsedReport,
  getXMLRequest,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  setInitialStateOmitReport,
  getAvailableReportsRequest,
  changeDefaultReportRequest,
} = getReportActions();

const setListType = createAction('setListType');

const printQuoteRequest = createAction('printQuoteRequest');
const removeQuotesRequest = createAction('removeQuotesRequest');
const removeQuotesSuccess = createAction('removeQuotesSuccess');
const selectQuoteListItem = createAction('selectQuoteListItem');
const getQuotesListRequest = createAction('getQuotesListRequest');
const receivedQuotesListSuccess = createAction('receivedQuotesListSuccess');
const setCloLoadFromQuotesStatus = createAction('setCloLoadFromQuotesStatus');
const createGetQuotesListRequest = createAction('createGetQuotesListRequest');
const cancelLoadFromQuotesRequest = createAction('cancelLoadFromQuotesRequest');
const removeLoadFromQuotesRequest = createAction('removeLoadFromQuotesRequest');
const removeLoadFromQuotesSuccess = createAction('removeLoadFromQuotesSuccess');
// order quotes
const changeOrderQuoteStatus = createAction('changeOrderQuoteStatus');
const acceptOrderQuoteRequest = createAction('acceptOrderQuoteRequest');
const cancelOrderQuoteRequest = createAction('cancelOrderQuoteRequest');
const declineOrderQuoteRequest = createAction('declineOrderQuoteRequest');
const sendOrderQuoteToCustomerRequest = createAction('sendOrderQuoteToCustomerRequest');

export {
  setListType,
  printQuoteRequest,
  removeQuotesRequest,
  removeQuotesSuccess,
  selectQuoteListItem,
  getQuotesListRequest,
  receivedQuotesListSuccess,
  setCloLoadFromQuotesStatus,
  createGetQuotesListRequest,
  cancelLoadFromQuotesRequest,
  removeLoadFromQuotesRequest,
  removeLoadFromQuotesSuccess,
  // order quotes
  changeOrderQuoteStatus,
  acceptOrderQuoteRequest,
  cancelOrderQuoteRequest,
  declineOrderQuoteRequest,
  sendOrderQuoteToCustomerRequest,
  // order quotes
  // report
  selectItem,
  setReports,
  setReportType,
  setUsedReport,
  getXMLRequest,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  setInitialStateOmitReport,
  getAvailableReportsRequest,
  changeDefaultReportRequest,
  // report
};
