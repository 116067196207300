import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../components/text';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// feature new-do
import Tabs from '../components/tabs';
import { isPageCreateDO, getStopIconColor } from '../helpers';
import {
  TAB_NAME_PRICING,
  TAB_NAME_BILL_TO,
  TAB_NAME_REFERENCE,
  TAB_NAME_TRIP_PRICING,
} from '../constants';
//////////////////////////////////////////////////

const getBillToTab = (billTo: Object) => (
  {
    name: TAB_NAME_BILL_TO,
    text: G.getWindowLocale('titles:bill-to', 'Bill To'),
    textColor: getStopIconColor(R.path(['isValid'], billTo)),
  }
);

const getPricingTab = (rate: Object) => (
  {
    name: TAB_NAME_PRICING,
    text: G.getWindowLocale('titles:pricing', 'Pricing'),
    textColor: getStopIconColor(R.path(['isValid'], rate)),
  }
);

const getTripPricingTab = (props: Object) => {
  const { pageType, telCreationMode } = props;

  const hasPermissions = G.hasAmousCurrentUserPermissions([PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_WRITE]);

  if (G.isFalse(hasPermissions)) return null;

  if (R.and(
        isPageCreateDO(pageType),
        G.notEquals(telCreationMode, GC.TEL_CREATION_MODE_SINGLE_TEL),
      )) return null;

  return ({
    name: TAB_NAME_TRIP_PRICING,
    textColor: getStopIconColor(true),
    text: G.getWindowLocale('titles:trip-pricing', 'Trip Pricing'),
  });
};

const getAdditionalInfoTab = (referenceFormData: Object) => (
  {
    name: TAB_NAME_REFERENCE,
    textColor: getStopIconColor(R.path(['isValid'], referenceFormData)),
    text: G.getWindowLocale('titles:additional-info', 'Additional Info'),
  }
);

const createTabSettings = (props: Array) => {
  const { rate, stops, billTo, branchConfigs, isOrderQuote, referenceFormData } = props;

  const mappedStops = R.map((stop: Object) => ({
    name: R.prop(GC.FIELD_ORDER, stop),
    textColor: getStopIconColor(R.path(['isValid'], stop)),
    text: `${R.head(R.prop(GC.FIELD_EVENT_TYPE, stop))} ${R.prop(GC.FIELD_ORDER, stop)}`,
  }), R.values(stops));

  const tabs = R.reject((item: any) => G.isNilOrEmpty(item), [
    getBillToTab(billTo),
    ...mappedStops,
    getAdditionalInfoTab(referenceFormData),
    getPricingTab(rate),
    getTripPricingTab(props),
  ]);

  if (isOrderQuote) {
    const showTripPricing = G.getConfigValueFromStore(GC.CLO_QUOTE_SHOW_TRIP_PRICING, branchConfigs);

    if (G.isTrue(showTripPricing)) return R.tail(tabs);

    return R.reject((item: any) => G.isNilOrEmpty(item), [
      ...mappedStops,
      getAdditionalInfoTab(referenceFormData),
      getPricingTab(rate),
    ]);
  }

  return tabs;
};

const createStopsTabSettings = (props: Array) => {
  const { stops } = props;
  const mappedStops = R.map((stop: Object) => ({
    name: R.prop(GC.FIELD_ORDER, stop),
    textColor: getStopIconColor(R.path(['isValid'], stop)),
    text: `${R.head(R.prop(GC.FIELD_EVENT_TYPE, stop))} ${R.prop(GC.FIELD_ORDER, stop)}`,
  }), R.values(stops));

  return [
    ...mappedStops,
  ];
};

const StopActions = (props: Object) => {
  const { addNewStopToStore } = props;

  const whiteColor = G.getTheme('colors.white');
  const darkBlueColor = G.getTheme('colors.dark.blue');

  return (
    <Flex>
      <Box flexShrink={0}>
        <Flex>
          <TextComponent
            p='3px 8px'
            minWidth={80}
            fontSize={12}
            bg={whiteColor}
            cursor='pointer'
            borderRadius='4px'
            color={darkBlueColor}
            border={`1px solid ${darkBlueColor}`}
            onClick={() => addNewStopToStore(GC.EVENT_TYPE_PICKUP)}
          >
            {G.getWindowLocale('titles:add-pickup', 'Add Pickup')}
          </TextComponent>
          <TextComponent
            mx='8px'
            p='3px 8px'
            minWidth={80}
            fontSize={12}
            bg={whiteColor}
            cursor='pointer'
            borderRadius='4px'
            color={darkBlueColor}
            border={`1px solid ${darkBlueColor}`}
            onClick={() => addNewStopToStore(GC.EVENT_TYPE_DROP)}
          >
            {G.getWindowLocale('titles:add-drop', 'Add Drop')}
          </TextComponent>
        </Flex>
      </Box>
    </Flex>
  );
};

const FormTab = (props: Object) => (
  <Flex justifyContent='space-between' bg={G.getTheme('colors.bgGrey')}>
    <Tabs
      width={475}
      overflow='auto'
      defaultActiveName={1}
      options={createTabSettings(props)}
      activeFromParent={props.leftActiveTad}
      onClickTabCallback={props.onClickTabCallback} />
    <StopActions {...props} />
  </Flex>
);

export const StopsFormTab = (props: Object) => (
  <Flex justifyContent='space-between' bg={G.getTheme('colors.bgGrey')}>
    <Tabs
      width={500}
      overflow='auto'
      defaultActiveName={1}
      activeFromParent={props.leftActiveTad}
      options={createStopsTabSettings(props)}
      onClickTabCallback={props.onClickTabCallback} />
    <StopActions {...props} />
  </Flex>
);

export default FormTab;
