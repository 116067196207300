import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// sagas
import { crudSaga, visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature public-clo
import * as A from './actions';
import { makeSelectPagination, makeSelectActiveTab } from './selectors';
//////////////////////////////////////////////////

export function* handleGetReportListRequest() {
  try {
    yield put(A.setListLoading(true));

    const activeTab = yield select(makeSelectActiveTab());
    const pagination = yield select(makeSelectPagination());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const reqBody = {
      ...pagination,
      fields: [],
      orderFields: [],
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };

    const endpointNames = ['exchangeRateList', 'customExchangeRateList'];
    const endpoint = endpointsMap[R.prop(activeTab, endpointNames)];

    const res = yield call(sendRequest, 'post', endpoint, { data: reqBody });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getReportListSuccess(data));
      yield put(A.setListLoading(false));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetReportListRequest fail');

      yield put(A.setListLoading(false));
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetReportListRequest exception');

    yield put(A.setListLoading(false));
  }
}

function* createOrUpdateCustomExchangeRateRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const method = G.ifElse(
      G.isNotNilAndNotEmpty(G.getGuidFromObject(payload)),
      'put',
      'post',
    );

    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, R.propOr(branchGuid, GC.BRANCH_GUID, payload), payload),
    };

    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      endpoint: endpointsMap.customExchangeRate,
      successMessage: 'messages:success:200-201',
      parentSagaName: 'createOrUpdateCustomExchangeRateRequest',
      successAction: A.getItemListRequestAndUpdateExchangeRateRequest,
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'createOrUpdateCustomExchangeRateRequest exception');
  }
}

function* removeCustomExchangeRateRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    yield call(crudSaga, {
      payload,
      method: 'delete',
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      parentSagaName: 'removeCustomExchangeRateRequest',
      endpoint: endpointsMap.customExchangeRateByGuid(payload),
      successAction: A.getItemListRequestAndUpdateExchangeRateRequest,
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'removeCustomExchangeRateRequest exception');
  }
}

function* getItemListRequestAndUpdateExchangeRateRequest() {
  try {
    yield put(openLoader());
    yield put(A.resetListAndPagination());
    yield put(A.getReportListRequest());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.exchangeRateMapByDate, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      G.setItemToWindow('amousExchangeRateMapByDate', data);
    } else {
      yield call(G.handleException, 'error', 'getItemListRequestAndUpdateExchangeRateRequest exception');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'getItemListRequestAndUpdateExchangeRateRequest exception');
  }
}

export function* handleVisitPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_NORMALIZE_CURRENCY_LIST_PAGE);
    yield put(openLoader({ showDimmer: true }));
    yield put(A.setInitialState());
    yield call(handleGetReportListRequest, { payload: true });
    yield put(closeLoader());
    break;
  }
}

function* pageWatcherSaga() {
  yield takeLatest(A.getReportListRequest, handleGetReportListRequest);
  yield takeLatest(GC.VISIT_NORMALIZE_CURRENCY_LIST_PAGE, handleVisitPageSaga);
  yield takeLatest(A.removeCustomExchangeRateRequest, removeCustomExchangeRateRequest);
  yield takeLatest(A.createOrUpdateCustomExchangeRateRequest, createOrUpdateCustomExchangeRateRequest);
  yield takeLatest(A.getItemListRequestAndUpdateExchangeRateRequest, getItemListRequestAndUpdateExchangeRateRequest);
}

export default pageWatcherSaga;
