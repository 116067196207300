import React from 'react';
import * as R from 'ramda';
import { getIn, Field, FieldArray } from 'formik';
import { compose, withHandlers } from 'react-recompose';
// components
import { Input } from '../../../components/charge/ui';
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import withChargeComments from '../../../hocs/with-charge-comments';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature payroll
import { FIELD_MAIN_CHARGES } from '../constants';
import { payrollMainChargeFieldSettings } from '../settings/payroll-with-charges-settings';
//////////////////////////////////////////////////

// TODO: with new form UI

const SectionHeader = () => (
  <Flex p='6px 50px' bg={G.getTheme('modal.bgColor')}>
    {payrollMainChargeFieldSettings.map(({ loc, fieldWidth }: Object, i: number) => (
      <Box key={i} mr={10} fontSize={13} color={G.getTheme('colors.light.black')} width={fieldWidth}>
        {G.getWindowLocale(loc, loc)}
      </Box>
    ))}
  </Flex>
);

const checkHasFieldError = (name: string, errors: Object, touched: Object) => {
  const error = getIn(errors, name);
  const touch = getIn(touched, name);

  return G.ifElse(
    R.and(G.isNotNilAndNotEmpty(error), G.isTrue(touch)),
    true,
    false,
  );
};

const recalculateChargesOnChangeRateOrQuantity = ({
  value,
  values,
  rowIndex,
  chargeFieldName,
}: Object) => {
  const prevCharges = R.pathOr([], [FIELD_MAIN_CHARGES], values);

  const currentChargeWithValue = R.assoc(chargeFieldName, value, R.nth(rowIndex, prevCharges));

  const currentChargeWithTotal = R.assoc(
    GC.FIELD_CHARGE_TOTAL,
    R.multiply(
      R.propOr(1, GC.FIELD_CHARGE_RATE, currentChargeWithValue),
      R.propOr(1, GC.FIELD_CHARGE_QUANTITY, currentChargeWithValue),
    ),
    currentChargeWithValue,
  );

  const charges = R.update(rowIndex, currentChargeWithTotal, prevCharges);

  const total = R.compose(
    R.sum,
    R.map(R.prop(GC.FIELD_CHARGE_TOTAL)),
  )(charges);

  return {
    [GC.FIELD_MAIN_CHARGES_TOTAL]: total,
    [FIELD_MAIN_CHARGES]: R.update(rowIndex, currentChargeWithTotal, charges),
  };
};

const addCommentsToMainChargesCharges = ({ value, values, chargeIndex }: Object) => {
  const charges = R.pathOr([], [FIELD_MAIN_CHARGES], values);
  const currentChargeWithComments = R.assoc(GC.FIELD_CHARGE_COMMENTS, value, R.nth(chargeIndex, charges));

  return R.update(chargeIndex, currentChargeWithComments, charges);
};

const enhanceChargeFields = compose(
  withHandlers({
    handleChangeInput: (props: Object) => (event: Object) => {
      const { target: { name, value } } = event;

      const { rowIndex, form: { values, setValues, handleChange } } = props;

      const chargeFieldName = R.compose(
        R.last,
        R.split('.'),
      )(name);

      if (R.or(
        R.equals(GC.FIELD_RATE, chargeFieldName),
        R.equals(GC.FIELD_QUANTITY, chargeFieldName),
      )) {
        return setValues(R.mergeRight(
          values,
          recalculateChargesOnChangeRateOrQuantity({ value, values, rowIndex, chargeFieldName }),
        ));
      }

      handleChange(event);
    },
    handleAddChargeComment: (props: Object) => (charge: Object) => {
      const { chargeIndex, comments } = charge;

      const { form: { values, setFieldValue } } = props;

      setFieldValue(
        FIELD_MAIN_CHARGES,
        addCommentsToMainChargesCharges({ value: comments, values, chargeIndex }),
      );
    },
  }),
  withChargeComments('master'),
);

const renderQuantityForHour = (charge: Object) => {
  if (G.isNilOrEmpty(charge)) return null;

  const { quantity, rateType, rateUnit } = charge;

  if (G.notEquals(rateType, GC.CHARGE_RATE_TYPE_TIME)) return null;

  if (G.notEquals(rateUnit, GC.CHARGE_RATE_UNIT_TYPE_HOUR)) return null;

  const { shortString } = G.convertHoursToHoursAndMinutes(quantity);

  return <Box display='inline' ml='5px'>{shortString}</Box>;
};

const ChargeFields = enhanceChargeFields((props: Object) => {
  const { form, total, comments, values, rowIndex, handleChangeInput, handleOpenChargeComment } = props;

  return (
    <Flex flexWrap='wrap'>
      <Flex
        mr={10}
        display='flex'
        cursor='pointer'
        alignItems='center'
        onClick={() => handleOpenChargeComment(rowIndex, comments)}
        title={R.or(comments, G.getWindowLocale('actions:add-comments', 'Add Comments'))}
      >
        {I.comment(G.getTheme('colors.dark.blue'))}
      </Flex>
      {
        payrollMainChargeFieldSettings.map(({ disabled, fieldName, fieldWidth, placeholder }: Object, i: number) => (
          <Box my='5px' mr={10} key={i}>
            <Field
              name={`${FIELD_MAIN_CHARGES}.${rowIndex}.${fieldName}`}
            >
              {({ field }: Object) => (
                <Input
                  {...field}
                  px={10}
                  disabled={disabled}
                  placeholder={placeholder}
                  onChange={handleChangeInput}
                  width={R.or(fieldWidth, 'auto')}
                  hasError={checkHasFieldError(field.name, form.errors, form.touched)}
                />
              )}
            </Field>
            {
              R.equals(fieldName, GC.FIELD_QUANTITY) && renderQuantityForHour(R.path(['mainCharges', rowIndex], values))
            }
          </Box>
        ))
      }
      <TextComponent
        ml='auto'
        width={100}
        p='2px 10px'
        title={total}
        fontSize={14}
        borderRadius='3px'
        textAlign='center'
        withEllipsis={true}
        display='inline-block'
        bg={G.getTheme('colors.dark.lightGrey')}
        color={G.getTheme('colors.light.black')}
      >
        {total}
      </TextComponent>
    </Flex>
  );
});

const MainChargesSection = (props: Object) => {
  const { minWidth } = props;

  const list = R.pathOr([], ['values', FIELD_MAIN_CHARGES], props);

  const currency = G.getCurrencySymbol(R.pathOr(
    GC.DEFAULT_UI_CURRENCY,
    ['values', GC.FIELD_CURRENCY],
    props,
  ));

  return (
    <Box overflow='auto' maxHeight={400} minWidth={minWidth}>
      <FieldArray
        name={FIELD_MAIN_CHARGES}
        render={(formikArrayProps: Object) => (
          <Box>
            <SectionHeader />
            <Box p='5px 15px 15px'>
              {
                list.map((entity: string, i: number) => (
                  <ChargeFields
                    {...props}
                    {...formikArrayProps}
                    key={i}
                    rowIndex={i}
                    comments={R.prop(GC.FIELD_CHARGE_COMMENTS, entity)}
                    total={`${currency} ${R.prop(GC.FIELD_CHARGE_TOTAL, entity)}`}
                  />
                ))
              }
            </Box>
          </Box>
        )}
      />
    </Box>
  );
};

export default MainChargesSection;
