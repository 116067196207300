import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// features
import { makeSelectStyling } from '../../../styling/selectors';
import { makeSelectCurrentBranch } from '../../../branch/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Flex, Text } from '../../../../ui';
// feature navbar-menu
import Notifications from './regular-notifications';
//////////////////////////////////////////////////

const TopSection = ({
  styling,
  currentBranch,
}: Object) => {
  const iconsColor = R.pathOr(G.getTheme('colors.light.mainLight'), ['textColor'], styling);

  return (
    <Flex
      gap='6px'
      p='8px 12px'
      width='100%'
      flexDirection='column'
      justifyContent='space-between'
    >
      <Text
        fontSize={14}
        color={iconsColor}
      >
        {R.prop(GC.FIELD_BRANCH_NAME, currentBranch)}
      </Text>
      <Notifications iconsColor={iconsColor} />
    </Flex>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  styling: makeSelectStyling(state),
  currentBranch: makeSelectCurrentBranch(state),
}));

export default connect(mapStateToProps)(TopSection);
