import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setErrors = createAction('setErrors');
export const setImportId = createAction('setImportId');
export const addTableRows = createAction('addTableRows');
export const setRolesGuids = createAction('setRolesGuids');
export const setSourceType = createAction('setSourceType');
export const setContractGuid = createAction('setContractGuid');
export const setInitialState = createAction('setInitialState');
export const changeTableCell = createAction('changeTableCell');
export const setGlobalErrors = createAction('setGlobalErrors');
export const changeImportList = createAction('changeImportList');
export const removeSelectedRows = createAction('removeSelectedRows');
export const setAdditionalFields = createAction('setAdditionalFields');
export const saveImportListRequest = createAction('saveImportListRequest');
export const getBranchConfigsRequest = createAction('getBranchConfigsRequest');
export const getBranchConfigsSuccess = createAction('getBranchConfigsSuccess');
export const toggleCleanExistentRates = createAction('toggleCleanExistentRates');
export const uploadCLODocumentRequest = createAction('uploadCLODocumentRequest');
export const getImportTemplateRequest = createAction('getImportTemplateRequest');
export const getImportTemplateSuccess = createAction('getImportTemplateSuccess');
export const addAdditionalFieldToTable = createAction('addAdditionalFieldToTable');
export const calculateImportCLOLocations = createAction('calculateImportCLOLocations');
export const createImportFromFileRequest = createAction('createImportFromFileRequest');
export const getImportFromFileDataSuccess = createAction('getImportFromFileDataSuccess');
export const removeMapAndImportFileRequest = createAction('removeMapAndImportFileRequest');
export const getAvailableImportMappersSuccess = createAction('getAvailableImportMappersSuccess');
export const createMapAndImportFromFileRequest = createAction('createMapAndImportFromFileRequest');
