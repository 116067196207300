import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose } from 'react-recompose';
import { FieldArray, withFormik } from 'formik';
import { createStructuredSelector } from 'reselect';
// components
import { FormFooter2 } from '../../../components/form-footer';
import { closeModal } from '../../../components/modal/actions';
// helpers
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Fieldset2 } from '../../../forms';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, IconWrapper } from '../../../ui';
// feature reference
import { makeSelectReferenceScopes } from '../selectors';
import { setInitialState, createNewReferenceTypeRequest } from '../actions';
import {
  defaultValues,
  validationSchema,
  referenceTypeFields,
  referenceFieldsSetting,
} from '../settings/field-settings';
//////////////////////////////////////////////////

export const enhance = compose(
  withFormik({
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultValues,
      props.initialValues,
    ),
    validationSchema: Yup.object().shape(validationSchema),
    handleSubmit: (values: Object, { props }: Object) => {
      const scopes = R.map((scope: Object) => ({ [GC.FIELD_GUID]: scope }),
        R.pathOr([], [GC.FIELD_REFERENCE_SCOPES], values),
      );
      const allowedValues = R.map(
        R.prop(GC.FIELD_VALUE),
        R.pathOr([], [GC.FIELD_REFERENCE_ALLOWED_VALUES], values),
      );
      const filteredValues = R.filter(G.isNotNilAndNotEmpty, values);
      const data = R.mergeRight(filteredValues, { scopes, allowedValues });

      props.submitAction(data);
    },
  }),
  pure,
);

const darkBlueColor = G.getTheme('colors.dark.blue');

const Header = ({ push }: Object) => (
  <Flex ml={10} mt={15}>
    <Box
      fontSize={13}
      fontWeight={500}
      color={G.getTheme('colors.dark.blue')}
    >
      {G.getWindowLocale('titles:add-new-value', 'Add New Value')}
    </Box>
    <Box
      mx={10}
      height={15}
      cursor='pointer'
      onClick={() => push({
        [GC.FIELD_VALUE]: null,
        [GC.FIELD_GUID]: G.genShortId(),
      })}
    >
      {I.plusRound(darkBlueColor)}
    </Box>
  </Flex>
);

const ReferenceTypeRow = ({ form, remove, itemIndex, arrayLength }: Object) => (
  <Flex>
    <IconWrapper
      cursor='pointer'
      m='15px 10px 0 5px'
      onClick={() => remove(itemIndex)}
    >
      {I.trash(darkBlueColor)}
    </IconWrapper>
    <Fieldset2
      {...form}
      fieldsetType='array'
      itemIndex={itemIndex}
      arrayLength={arrayLength}
      fields={referenceFieldsSetting}
      arrayName={GC.FIELD_REFERENCE_ALLOWED_VALUES}
    />
  </Flex>
);

const ReferenceTypeFormComponent = (props: Object) => {
  const { scopes, handleSubmit } = props;

  const scopesOptions = R.map(({ displayedValue, guid }: Object) => ({
    [GC.FIELD_VALUE]: guid,
    [GC.FIELD_LABEL]: displayedValue,
  }), scopes);

  const allowedValues = R.path(['values', GC.FIELD_REFERENCE_ALLOWED_VALUES], props);

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={referenceTypeFields}
        scopesOptions={scopesOptions}
        fieldsWrapperStyles={{ mt: 15 }}
      />
      <FieldArray
        name={GC.FIELD_REFERENCE_ALLOWED_VALUES}
        render={({ form, push, remove }: Object) => (
          <Box mb={25}>
            <Header push={push} />
            {
              G.isNotNilAndNotEmpty(allowedValues) &&
              allowedValues.map((_: any, index: number) => (
                <ReferenceTypeRow
                  form={form}
                  key={index}
                  remove={remove}
                  itemIndex={index}
                  arrayLength={R.length(allowedValues)}
                />
              ))
            }
          </Box>
        )}
      />
      <FormFooter2 />
    </form>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  scopes: makeSelectReferenceScopes(state),
}));

export default connect(mapStateToProps, {
  closeModal,
  setInitialState,
  createNewReferenceTypeRequest,
})(enhance(ReferenceTypeFormComponent));
