import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { OuterClick } from 'react-outer-click';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { ENUMS } from '../../../constants/enums';
// ui
import { Box } from '../../../ui';
// feature new-do
import FormTab from './form-tab';
import FormHeader from './form-header';
import { enhanceStopForm } from '../hocs';
import { Fieldset } from './formik/fieldset';
import ContactsArray from './contacts-array';
import PrevNextAction from './validate-action';
import ContainersArray from './containers-array';
import ReferencesArray from './references-array';
import SectionDivider from '../components/section-divider';
import { getValidationSchemaDropFormLazy } from '../validation';
import { FORM_BOX_SHADOW, TAB_NAME_REFERENCE } from '../constants';
import { getStopTitle, getStopHeaderActionOptions } from '../helpers';
import BusinessHoursSection from '../components/business-hours-section';
import {
  mainStopFields,
  apptsStopFields,
  addressStopFields,
  dropItemStopFields,
  dropInfoStopFields,
  trailersStopFields,
  dropContainerStopFields,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const DropForm = (props: Object) => {
  const {
    stop,
    values,
    openModal,
    setValues,
    submitForm,
    closeModal,
    branchGuid,
    setFieldValue,
    handleClickPrev,
    handleOuterClick,
    handleGetLoadOptions,
    handleChangeApptTime,
  } = props;

  const branchConfigs = G.getItemFromWindow('amousNewDoBranchConfigs');
  const useContainers = G.getConfigValueFromStore(GC.CLO_GENERAL_USE_CONTAINERS, branchConfigs);
  const manageTrailersFrom = G.getConfigValueFromStore(GC.TRAILER_MANAGE_TRAILERS_FROM, branchConfigs);
  const useStopTrailers = R.equals(manageTrailersFrom, ENUMS.ENUM_EVENTS);

  return (
    <OuterClick onOuterClick={handleOuterClick}>
      <form>
        <FormTab {...props} />
        <Box boxShadow={FORM_BOX_SHADOW}>
          <FormHeader
            values={values}
            setValues={setValues}
            openModal={openModal}
            closeModal={closeModal}
            setFieldValue={setFieldValue}
            options={getStopHeaderActionOptions(props)}
            currentBranch={{ [GC.FIELD_GUID]: branchGuid }}
          >
            {getStopTitle(stop, values)}
          </FormHeader>
          <Box pt={20}>
            <Fieldset
              {...props}
              fields={mainStopFields}
              getLoadOptions={handleGetLoadOptions}
            />
            <SectionDivider
              text={G.getWindowLocale('titles:address', 'Address', { caseAction: 'upperCase' })}
            />
            <Fieldset
              {...props}
              fields={addressStopFields}
            />
            <SectionDivider
              text={G.getWindowLocale('titles:drop-info', 'Drop Info', { caseAction: 'upperCase' })}
            />
            <Fieldset
              {...props}
              fields={dropInfoStopFields}
            />
            <SectionDivider
              text={G.getWindowLocale('titles:appointment-info', 'Appointment Info', { caseAction: 'upperCase' })}
            />
            <Fieldset
              {...props}
              fields={apptsStopFields}
              handleChangeInput={handleChangeApptTime}
            />
            <ContactsArray contacts={R.pathOr([], ['values', GC.FIELD_CONTACTS], props)} />
            <BusinessHoursSection
              {...props}
              useFormikSetFieldValue={true}
              operationHour={R.path(['values', GC.FIELD_OPERATION_HOUR], props)}
            />
            {
              G.isTrue(useContainers) &&
              <SectionDivider
                text={G.getWindowLocale('titles:drop-containers', 'Drop Containers', { caseAction: 'upperCase' })}
              />
            }
            {
              G.isTrue(useContainers) &&
              <Fieldset
                {...props}
                fields={dropContainerStopFields}
              />
            }
            { G.isTrue(useContainers) && <ContainersArray {...props} /> }
            {
              G.isTrue(useStopTrailers) &&
              <SectionDivider
                text={G.getWindowLocale('titles:trailers', 'Trailers', { caseAction: 'upperCase' })}
              />
            }
            {
              G.isTrue(useStopTrailers) &&
              <Fieldset
                {...props}
                fields={trailersStopFields}
              />
            }
            <SectionDivider
              text={G.getWindowLocale('titles:drop-items', 'Drop Items', { caseAction: 'upperCase' })}
            />
            <Fieldset
              {...props}
              fields={dropItemStopFields}
            />
            <ReferencesArray {...props} />
            <PrevNextAction
              showNext={true}
              submitForm={submitForm}
              handleClickPrev={handleClickPrev}
            />
          </Box>
        </Box>
      </form>
    </OuterClick>
  );
};

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: () => getValidationSchemaDropFormLazy(),
    mapPropsToValues: ({ initialValues }: Object) => G.setEventInitialDroppedContainers(
      G.setEventEarlyLateTimeFromEarlyLateDate(initialValues),
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const { stops, stopOrder, setActiveLeftTab, setFormDataToStop } = props;
      setFormDataToStop({ stopOrder, isValid: true, formData: values });
      if (R.equals(R.length(R.values(stops)), stopOrder)) {
        return setActiveLeftTab(TAB_NAME_REFERENCE);
      }
      setActiveLeftTab(R.inc(stopOrder));
    },
    displayName: 'DropForm',
  }),
  withHandlers({
    handleOuterClick: (props: Object) => () => {
      const { values, stopOrder, setFormDataToStop } = props;
      setFormDataToStop({ stopOrder, isValid: true, formData: values });
    },
  }),
  enhanceStopForm,
  pure,
);

export default enhance(DropForm);
