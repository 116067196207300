import * as R from 'ramda';
import {
  pure,
  branch,
  compose,
  withState,
  withHandlers,
  renderNothing,
  withPropsOnChange,
} from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// hocs
import { withAsyncGetDispatchingGroupList } from '../../../hocs';
//////////////////////////////////////////////////

const makeGuidsArray = (items: Array) => (
  R.reduce((acc: Array, item: Object) => (
    G.ifElse(R.propEq(true, 'checked')(item), R.concat(acc, R.of(Array, item.guid)), acc)
  ), [], items)
);

const getUserGuidFromProps = (props: Object) => {
  const { currentUserGuid, match } = props;
  const guidFromParams = match.params.guid;

  return R.or(guidFromParams, currentUserGuid);
};

export const withProfileWrapper = compose(
  withState('update', 'setUpdate', false),
  withState('saveAndClose', 'setSaveAndClose', false),
  withAsyncGetDispatchingGroupList,
  withPropsOnChange(['asyncGetDispatchingGroupList'], (props: Object) => {
    const { asyncGetDispatchingGroupList } = props;

    const options = R.map((item: Object) => {
      const { name } = item;

      const value = G.getGuidFromObject(item);

      return { value, label: name };
    }, asyncGetDispatchingGroupList);

    return {
      'dispatchingGroupOptions': options,
    };
  }),
  withHandlers({
    handleUpdateGeneralDetails: (props: Object) => (values: Object, options: Object = {}) => {
      const {
        handleClose,
        saveAndClose,
        initialValues,
        setSaveAndClose,
        updateGeneralDetailsRequest,
      } = props;

      const { setSubmitting } = options;

      if (R.equals(values, initialValues)) {
        G.callFunctionWithArgs(setSubmitting, false);

        if (saveAndClose) handleClose();

        return;
      }

      const newValues = { ...initialValues, ...values };

      setSaveAndClose(false);
      updateGeneralDetailsRequest({ options, handleClose, saveAndClose, values: newValues });
    },
    handleRevokeUserProfileRoles: (props: Object) => (rolesGuids: string) => {
      const { revokeUserProfileRolesRequest } = props;

      const userGuid = getUserGuidFromProps(props);
      const args = { rolesGuids, usersGuids: R.of(Array, userGuid) };

      revokeUserProfileRolesRequest(args);
    },
    handleAssignUserProfileRoles: (props: Object) => (items: Array) => {
      const { assignUserProfileRolesRequest } = props;

      const userGuid = getUserGuidFromProps(props);
      const args = { rolesGuids: makeGuidsArray(items), usersGuids: R.of(Array, userGuid) };

      assignUserProfileRolesRequest(args);
    },
    handleRemoveReference: ({ deleteUserReferenceRequest }: Object) => (guid: string) =>
      deleteUserReferenceRequest(guid),
    handleCreateReference: (props: Object) => (values: Object) => {
      const { createUserReferenceRequest } = props;

      const userGuid = getUserGuidFromProps(props);
      const args = R.assoc('primaryObjectGuids', R.of(Array, userGuid), values);

      createUserReferenceRequest(args);
    },
    handleUpdateReference: ({ updateUserReferenceRequest }: Object) => (data: Object) =>
      updateUserReferenceRequest(data),
    handleChangeUserSuspendedStatus: (props: Object) => () => {
      const { initialValues, changeUserSuspendedStatusRequest } = props;

      const { guid, active } = initialValues;

      changeUserSuspendedStatusRequest({ guid, active });
    },
  }),
  branch(
    ({ initialDataLoaded }: Object) => R.not(initialDataLoaded),
    renderNothing,
  ),
  pure,
);

