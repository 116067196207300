import * as R from 'ramda';
import React from 'react';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex } from '../../../../ui';
// feature new-do
import { Input, DatePicker } from '../../ui';
import { InputWrapper } from './input-wrapper';
import { CalendarFormGroupWithoutZI } from '../../../../forms/restyled';
//////////////////////////////////////////////////

const militaryFormats = [GC.DATE_TIME_FORMAT_US_24H];

export const getDateTimeFormatForRender = (timeSelection: boolean, format: string) => {
  const formatDataObject = R.pathOr(
    R.prop(GC.DATE_TIME_FORMAT_US, GC.dateTimeFormatMap),
    [format],
    GC.dateTimeFormatMap,
  );
  const propName = G.ifElse(G.isTrue(timeSelection), 'dateTime', 'date');

  return {
    timeFormat: R.path(['time', 'format'], formatDataObject),
    dateTimeFormat: R.path([propName, 'format'], formatDataObject),
  };
};

export const getDateTimeFormatForValue = (timeSelection: boolean) => (
  G.ifElse(
    R.equals(timeSelection, true),
    GC.DEFAULT_DATE_TIME_FORMAT,
    GC.DEFAULT_DATE_FORMAT,
  )
);

export const getMask = (timeSelection: boolean, format: string) => {
  const formatDataObject = R.pathOr(
    R.prop(GC.DATE_TIME_FORMAT_US, GC.dateTimeFormatMap),
    [format],
    GC.dateTimeFormatMap,
  );
  const propName = G.ifElse(G.isTrue(timeSelection), 'dateTime', 'date');

  return R.path([propName, 'mask'], formatDataObject);
};

export const getPipe = (timeSelection: boolean, format: string) => {
  const formatDataObject = R.pathOr(
    R.prop(GC.DATE_TIME_FORMAT_US, GC.dateTimeFormatMap),
    [format],
    GC.dateTimeFormatMap,
  );
  const propName = G.ifElse(G.isTrue(timeSelection), 'dateTime', 'date');

  return R.path([propName, 'pipe'], formatDataObject);
};

export const getCorrectDateTimeString = (value: any, format: string) => {
  if (R.and(G.isNotNilAndNotEmpty(value), G.isValidMoment(value))) {
    const isMilitary = R.includes(format, militaryFormats);
    const valueToUse = moment(value).format(R.path([format, 'dateTime', 'format'], GC.dateTimeFormatMap));
    const splitted = R.split(' ', valueToUse);
    const date = R.head(splitted);
    let time = R.or(R.nth(1, splitted), '00:00');
    const ampm = R.or(R.nth(2, splitted), 'am');
    const splittedTime = R.split(':', time);
    const hour = R.head(splittedTime);
    const hourHead = R.head(hour);
    const hourLength = R.length(hour);
    const min = R.nth(1, splittedTime);
    const hourNumber = G.toNumber(hour);

    if (R.and(R.lt(hourNumber, 10), R.and(R.equals(hourLength, 1), G.isNotZero(hourHead)))) {
      time = `0${hour}:${min}`;
    }

    if (isMilitary) return `${date} ${time}`;

    return R.toUpper(`${date} ${time} ${ampm}`);
  }

  return value;
};

export const getSelected = (value: Object) => {
  if (R.and(G.isNotNilAndNotEmpty(value), G.isValidMoment(value))) {
    return G.makeMomentInstance(value);
  }

  return null;
};

export const MaskedTimeInput = (props: Object) => {
  const { value, format, wrapperStyles, handleChangeInput, calendarInputWrapperStyles } = props;

  const pipe = (value: any) => {
    if (G.isNotNilAndNotEmpty(value)) {
      let newValue = value;
      const splittedHead = R.head(R.split(':', value));
      const splittedHeadNumber = G.toNumber(splittedHead);
      const isMilitary = R.includes(format, militaryFormats);
      const numberToCompare = G.ifElse(isMilitary, 23, 12);

      if (R.gt(splittedHeadNumber, numberToCompare)) {
        const valueToReplace = G.ifElse(isMilitary, '00', 12);
        newValue = R.replace(splittedHead, valueToReplace, value);
      }

      if (G.isFunction(handleChangeInput)) {
        handleChangeInput({ ...props, value: R.toUpper(value) });
      }

      return R.toUpper(newValue);
    }

    return value;
  };

  const initialValue = G.setCorrectTimeInitialValue(value);

  const formatDataObject = R.pathOr(
    R.prop(GC.DATE_TIME_FORMAT_US, GC.dateTimeFormatMap),
    [format],
    GC.dateTimeFormatMap,
  );
  const { mask, placeholder } = formatDataObject.time;

  return (
    <MaskedInput
      {...props}
      pipe={pipe}
      mask={mask}
      value={initialValue}
      render={(ref: Object, props: Object) => (
        <InputWrapper {...props} {...wrapperStyles}>
          <Input ref={ref} {...props} placeholder={placeholder} />
        </InputWrapper>
      )}
    />
  );
};

const MaskedDateInput = (props: Object) => {
  const { format, timeSelection, wrapperStyles, calendarInputWrapperStyles } = props;

  const formatDataObject = R.pathOr(
    R.prop(GC.DATE_TIME_FORMAT_US, GC.dateTimeFormatMap),
    [format],
    GC.dateTimeFormatMap,
  );
  const propName = G.ifElse(G.isTrue(timeSelection), 'dateTime', 'date');
  const placeholder = R.path([propName, 'placeholder'], formatDataObject);

  return (
    <MaskedInput
      {...props}
      pipe={getPipe(timeSelection, format)}
      mask={getMask(timeSelection, format)}
      render={(ref: Object, props: Object) => (
        <InputWrapper {...wrapperStyles} inputWrapperStyles={calendarInputWrapperStyles}>
          <Input ref={ref} {...props} placeholder={placeholder} />
        </InputWrapper>
      )}
    />
  );
};

const CustomInput = ({ onClick }: Object) => (
  <Box
    ml='-20px'
    cursor='pointer'
    onClick={onClick}
  >
    {I.calendar(G.getTheme('colors.dark.blue'), 16, 15)}
  </Box>
);

export class CalendarInput extends React.Component {
  handleBlur = () => {
    const { id, setFieldTouched } = this.props;

    if (G.isFunction(setFieldTouched)) setFieldTouched(id, true);
  };

  handleChange = (value: string) => {
    const props = this.props;
    const { id, fromPage, timeSelection, setFieldValue, shouldCustomChange, customChangeHandler2 } = props;

    if (R.isNil(value)) {
      setFieldValue(id, null);

      return;
    }

    if (R.and(G.isTrue(shouldCustomChange), G.isFunction(customChangeHandler2))) {
      return customChangeHandler2({ value, props });
    }

    const dateTimeFormat = getDateTimeFormatForValue(timeSelection);
    const valueToSet = G.createLocalDateTimeFromInstanceOrISOString(value, dateTimeFormat);
    const isFromPlanner = R.includes(fromPage, [GC.PAGE_DISPATCH_PLANNER, GC.PAGE_DISPATCH_PLANNER_EVENTS]);
    const isEarlyDateField = R.equals(id, GC.FIELD_LOAD_EVENT_EARLY_DATE);

    if (R.and(isFromPlanner, isEarlyDateField)) {
      const { values, setValues } = props;

      return setValues(R.mergeRight(values, {
        [GC.FIELD_LOAD_EVENT_LATE_DATE]: valueToSet,
        [GC.FIELD_LOAD_EVENT_EARLY_DATE]: valueToSet,
      }));
    }

    setFieldValue(id, valueToSet);
  };

  handleOnKeyDown = (e: Object) => {
    const props = this.props;
    const value = e.target.value;
    const { id, setFieldValue, shouldCustomChange, customChangeHandler2 } = props;

    if (R.isNil(value)) {
      return setFieldValue(id, null);
    }

    if (R.and(G.isTrue(shouldCustomChange), G.isFunction(customChangeHandler2))) {
      return customChangeHandler2({ value, props });
    }

    if (G.isValidMoment(value)) {
      const dateTimeFormat = getDateTimeFormatForValue(props.timeSelection);

      return setFieldValue(id, G.createLocalDateTimeFromInstanceOrISOString(value, dateTimeFormat));
    }

    setFieldValue(id, value);
  };

  render() {
    const {
      id,
      value,
      width,
      format,
      minDate,
      maxDate,
      disabled,
      customInput,
      placeholder,
      isClearable,
      timeSelection,
      timeIntervals,
    } = this.props;

    const selected = getSelected(value);

    const configDateFormat = R.or(format, GC.DATE_TIME_FORMAT_US);

    const getCorrectValue = () => {
      if (G.isTrue(timeSelection)) return getCorrectDateTimeString(value, configDateFormat);

      if (R.not(G.isValidMoment(value))) return value;

      return moment(value).format(R.path([configDateFormat, 'date', 'format'], GC.dateTimeFormatMap));
    };

    const newValue = getCorrectValue();

    const setMaxDate = () => {
      if (G.isFunction(maxDate)) return maxDate(this.props);

      if (G.isNotNilAndNotEmpty(maxDate)) return maxDate;

      return moment().add(100, 'years');
    };

    const setMinDate = () => {
      if (G.isFunction(minDate)) return minDate(this.props);

      if (G.isNotNilAndNotEmpty(minDate)) return minDate;

      return moment().subtract(100, 'years');
    };

    const { timeFormat, dateTimeFormat } = getDateTimeFormatForRender(timeSelection, configDateFormat);

    if (G.isTrue(customInput)) {
      return (
        <Flex>
          <MaskedDateInput {...this.props} format={configDateFormat} value={newValue} />
          <CalendarFormGroupWithoutZI direction='row'>
            {
              R.not(disabled) &&
              <DatePicker
                autoComplete='off'
                todayButton='Today'
                selected={selected}
                maxDate={setMaxDate()}
                minDate={setMinDate()}
                timeFormat={timeFormat}
                showYearDropdown={true}
                showMonthDropdown={true}
                onBlur={this.handleBlur}
                dateFormat={dateTimeFormat}
                onChange={this.handleChange}
                customInput={<CustomInput />}
                timeIntervals={R.or(timeIntervals, 15)}
                showTimeSelect={timeSelection || false}
              />
            }
          </CalendarFormGroupWithoutZI>
        </Flex>
      );
    }

    return (
      <DatePicker
        id={id}
        width={width}
        autoComplete='off'
        todayButton='Today'
        selected={selected}
        maxDate={setMaxDate()}
        minDate={setMinDate()}
        timeFormat={timeFormat}
        showYearDropdown={true}
        showMonthDropdown={true}
        onBlur={this.handleBlur}
        dateFormat={dateTimeFormat}
        onChange={this.handleChange}
        isClearable={isClearable || false}
        placeholderText={placeholder || ''}
        showTimeSelect={timeSelection || false}
        onKeyDown={(e: Object) => this.handleOnKeyDown(e)}
      />
    );
  }
}
