import * as R from 'ramda';
import React from 'react';
import {
  pure,
  branch,
  compose,
  renderNothing } from 'react-recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { Table } from '../../components/table';
import { PageTitle } from '../../components/page-title';
import { PageActions } from '../../components/page-actions';
import { openModal, closeModal } from '../../components/modal/actions';
import { InputWithHandlers } from '../../components/input-with-handlers';
// features
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
// hocs
import { withFilterTableList } from '../../hocs';
// icons
import * as I from '../../svgs';
// ui
import {
  Flex,
  ListWrapper,
  ZOrderWrapper } from '../../ui';
// feature notification
import { withDeleteNotifications } from './hocs';
import { tableSettings } from './settings/table-settings';
import { report, columnSettings } from './settings/column-settings';
import {
  selectItem,
  getItemListRequest,
  markAllAsReadRequest,
  resetListAndPagination,
  deleteAllNotifications,
  deleteSelectedNotifications } from './actions';
import {
  makeSelectWsCount,
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectListLoading } from './selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withDeleteNotifications,
  branch(
    (props: Object) => R.not(props.initialDataLoaded),
    renderNothing,
  ),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    totalCount,
    selectItem,
    pagination,
    filteredTableList,
    getItemListRequest,
  } = props;
  const allChecked = G.isAllChecked(filteredTableList);
  const data = {
    report,
    loading,
    allChecked,
    totalCount,
    pagination,
    tableSettings,
    columnSettings,
    hasSelectable: true,
    onOptionClick: selectItem,
    itemList: filteredTableList,
    handleLoadMoreEntities: G.ifElse(
      loading,
      () => {},
      getItemListRequest,
    ),
  };
  return <Table {...data} />;
};

// TODO: add actions when be design
export const NotificationListComponent = withFilterTableList(
  'searchString',
  'itemList',
  ['itemList'],
  {
    joiner: '',
    omitFields: [
      'guid',
      'read',
      'version',
      'mainGuid',
      'selected',
      'objectGuid',
      'lastModifiedBy',
      'triggeredValue',
      'lastModifiedDate',
    ],
    withoutValues: [null, undefined, ''],
  },
)((props: Object) => {
  const {
    itemList,
    filterString,
    markAllAsReadRequest,
    handleChangeFilterInput,
    handleDeleteAllNotifications,
    handleDeleteSelectedNotifications } = props;
  const newList = itemList;
  const notificationsText = G.getWindowLocale('titles:notifications', 'Notifications');
  const whiteColor = G.getTheme('colors.white');
  const actions = [
    {
      type: 'massAction',
      icon: I.trash(whiteColor),
      action: handleDeleteSelectedNotifications,
      text: `${G.getWindowLocale('actions:delete-selected', 'Delete Selected')} ${notificationsText}`,
    },
    {
      icon: I.trash(whiteColor),
      action: handleDeleteAllNotifications,
      text: `${G.getWindowLocale('actions:delete-all', 'Delete All')} ${notificationsText}`,
    },
    {
      type: 'massAction',
      action: () => markAllAsReadRequest(true),
      icon: I.plusRound(whiteColor, '25px', '25px'),
      text: G.getWindowLocale('actions:mark-all-as-read', 'Mark all as read'),
    },
    {
      type: 'massAction',
      action: () => markAllAsReadRequest(false),
      icon: I.plusRound(whiteColor, '25px', '25px'),
      text: G.getWindowLocale('actions:mark-as-unread', 'Mark as unread'),
    },
  ];

  return (
    <ListWrapper p={15} bgColor={G.getTheme('pages.layOutBgColor')}>
      <Flex width='100%'>
        <PageTitle
          withCount={true}
          count={props.wsCount}
          title={notificationsText}
        />
        <Flex
          mr={20}
          ml='auto'
        >
          <InputWithHandlers
            value={filterString}
            placeholder={G.getWindowLocale('titles:search', 'Search')}
            onChange={(value: string) => handleChangeFilterInput(value)}
            styles={{
              width: 120,
              p: '0 3px',
              height: 20,
              border: 'none',
              borderRadius: '0px',
              borderBottom: '1px solid',
            }}
          />
        </Flex>
      </Flex>
      <ZOrderWrapper zIndex='1'>
        {renderTable(props)}
      </ZOrderWrapper>
      <PageActions
        version={2}
        listActions={actions}
        count={R.filter((item: Object) => item.selected, R.or(newList, [])).length} />
    </ListWrapper>
  );
});

const mapStateToProps = (state: Object) => (createStructuredSelector({
  wsCount: makeSelectWsCount(state),
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
}));

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  selectItem,
  getItemListRequest,
  markAllAsReadRequest,
  resetListAndPagination,
  deleteAllNotifications,
  deleteSelectedNotifications,
})(enhance(NotificationListComponent));
