import React from 'react';
import * as R from 'ramda';
import {
  compose,
  withState,
  withHandlers,
  withPropsOnChange,
} from 'react-recompose';
// components
import { Label } from '../../../components/label';
import { FormGroupTitleComponent } from '../../../components/form-group-title';
// helpers/constants
import * as G from '../../../helpers';
// forms
import { FormGroupWrapper } from '../../../forms';
// icons
import * as I from '../../../svgs';
// feature profile
import SelectRoles from './select-roles';
import {
  SectionHeader,
  SectionWrapper,
  SectionLeftItem,
} from '../ui';
//////////////////////////////////////////////////

const enhance = compose(
  withState('isOpened', 'toggleOpened', true),
  withPropsOnChange(['roles'], ({ roles }: Object) => roles),
  withHandlers({
    handleRemoveItem: ({ handleRevokeUserProfileRoles }: Object) => (guid: string) => (
      handleRevokeUserProfileRoles(guid)
    ),
    handleClickAddRole: (props: Object) => () => {
      const {
        closeModal,
        availableByUserTypeRoleList,
        handleAssignUserProfileRoles,
      } = props;

      const rolesAvailableToAssign = R.values(availableByUserTypeRoleList);

      const component = (
        <SelectRoles
          handleCancel={closeModal}
          items={rolesAvailableToAssign}
          handleSave={(items: Array) => {
            closeModal();
            handleAssignUserProfileRoles(items);
          }}
        />
      );
      const modal = {
        p: '0px',
        component,
        options: {
          width: 440,
          controlButtons: [],
        },
      };
      props.openModal(modal);
    },
    onToggleSection: (props: Object) => () => {
      props.toggleOpened(R.not(props.isOpened));
    },
  }),
);

const renderRoles = (props: Array) => (
  R.values(R.map((item: Object) => (
    <SectionLeftItem key={item.guid}>
      <Label
        frontIcon={I.minusInRound(G.getTheme('icons.iconColor'))}
        frontAction={() => props.handleRemoveItem(R.of(Array, item.guid))}
      >
        {item.name}
      </Label>
    </SectionLeftItem>
  ), props.roles))
);

const UserRoles = (props: Objects) => {
  const { isOpened, onToggleSection, handleClickAddRole } = props;

  return (
    <FormGroupWrapper isOpened={isOpened}>
      <FormGroupTitleComponent
        isOpened={isOpened}
        onClickLabel={handleClickAddRole}
        onToggleFormGroup={onToggleSection}
        endIcon={I.plusRound(G.getTheme('icons.iconColorWhite'))}
        text={G.getWindowLocale('titles:user-roles', 'User Roles')}
      />
      <SectionWrapper>
        <SectionHeader>
          <SectionLeftItem>
            {G.getWindowLocale('titles:role-name', 'Role Name')}
          </SectionLeftItem>
        </SectionHeader>
        {renderRoles(props)}
      </SectionWrapper>
    </FormGroupWrapper>
  );
};

export default enhance(UserRoles);

