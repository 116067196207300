import * as R from 'ramda';
import React from 'react';
// features
import BusinessHours, { withBusinessHours } from '../../business-hours';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const Header = ({ operationHour, handleOpenBusinessHours }: Object) => (
  <Flex mb='20px' px='10px' height='30px' bg={G.getTheme('colors.bgGrey')}>
    <Box fontWeight={600} color={G.getTheme('colors.greyMatterhorn')}>
      {G.getWindowLocale('titles:business-hours', 'Business Hours')}
    </Box>
    {
      G.isNilOrEmpty(operationHour) &&
      <Box
        mx='10px'
        height='15px'
        cursor='pointer'
        onClick={handleOpenBusinessHours}
      >
        {I.plusRound(G.getTheme('colors.dark.blue'))}
      </Box>
    }
  </Flex>
);

const BusinessHoursSection = (props: Object) => (
  <Box mt={20}>
    <Header {...props} />
    {
      G.isNotNilAndNotEmpty(props.operationHour) &&
      <BusinessHours {...props} version={2} componentType='event' />
    }
  </Box>
);

export default withBusinessHours(BusinessHoursSection);
