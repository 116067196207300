import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { pure, compose, lifecycle } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { withFormik } from 'formik';
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withReferenceTypes } from '../../../hocs';
//////////////////////////////////////////////////

const validationSchema1 = {
  [GC.FIELD_ROUTE_NAME]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
};

const validationSchema2 = {
  [GC.FIELD_ROUTE_NAME]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_PRIMARY_REFERENCE_VALUE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_PRIMARY_REFERENCE_TYPE_GUID]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
};

const validationSchema = (props: Object) => Yup.lazy(() => {
  const { autoTelPrimaryRef } = props;

  let validationSchema = validationSchema1;

  if (G.isEmptyOrFalse(autoTelPrimaryRef)) validationSchema = validationSchema2;

  return Yup.object().shape(validationSchema);
});

const defaultStyles = {
  mb: 27,
  width: 270,
  labelWidth: 'max-content',
};

const firstFields = [
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_ROUTE_NAME,
    inputWrapperStyles: defaultStyles,
    label: G.getWindowLocale('titles:route-name', 'Route Name'),
  },
];

const secondFields = [
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles: defaultStyles,
    fieldName: GC.FIELD_PRIMARY_REFERENCE_VALUE,
    label: G.getWindowLocale('titles:tel-primary-reference-value', 'Trip Primary Reference Value'),
  },
  {
    type: 'select',
    isRequired: true,
    options: 'referenceTypes',
    inputWrapperStyles: defaultStyles,
    fieldName: GC.FIELD_PRIMARY_REFERENCE_TYPE_GUID,
    label: G.getWindowLocale('titles:tel-primary-reference-type', 'Trip Primary Reference Type'),
  },
];

const enhance = compose(
  withReferenceTypes,
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.createLoadRequest({ [GC.FIELD_TEL]: values }),
    mapPropsToValues: ({ customerLoadNumber }: Object) => ({
      [GC.FIELD_ROUTE_NAME]: '',
      [GC.FIELD_PRIMARY_REFERENCE_TYPE_GUID]: '',
      [GC.FIELD_PRIMARY_REFERENCE_VALUE]: R.or(customerLoadNumber, ''),
    }),
  }),
  lifecycle({
    componentDidMount() {
      const { scopeName, branchGuid, getReferenceTypesByScopeName } = this.props;
      getReferenceTypesByScopeName(scopeName, branchGuid);
    },
  }),
  pure,
);

const RouteTelForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    {
      R.or(G.isEmptyOrFalse(props.autoTelPrimaryRef), G.isEmptyOrFalse(props.autoRouteName)) &&
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={firstFields}
        fieldsWrapperStyles={{ mt: 15 }}
      />
    }
    {
      G.isEmptyOrFalse(props.autoTelPrimaryRef) &&
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={secondFields}
        referenceTypes={G.addEmptyOptionToDropDown(R.pathOr([], ['parentState', 'referenceTypes'], props))}
      />
    }
    <FormFooter2 />
  </form>);

export const getTelRouteModalOptions = (modalContent: Object) => ({
  p: 15,
  component: modalContent,
  options: {
    width: 300,
    height: 'auto',
    title: G.getWindowLocale('titles:tel-route-information', 'TRIP/ROUTE Information'),
  },
});

export default enhance(RouteTelForm);
