import * as R from 'ramda';
import React from 'react';
import {
  compose,
  withState,
  lifecycle,
  withHandlers,
} from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import { getCurrentMenuItem } from '../../../helpers/menu';
// icons
import * as I from '../../../svgs';
// ui
import { Box } from '../../../ui';
// feature navbar-menu
import { BottomPart, MenuWrapper } from './ui';
import UserBox from './left-navigation/user-box';
import PinnedItems from './left-navigation/pinned-items';
import NavbarButton from './left-navigation/navbar-button';
import Notifications from './left-navigation/carrier-notifications';
//////////////////////////////////////////////////

const carrierPinnedItems = [
  'menu:dispatch-board-beta',
  'menu:tel',
  'menu:average-fuel-price',
];

const enhance = compose(
  withState('currentMenuItem', 'setCurrentMenuItem', getCurrentMenuItem()),
  lifecycle({
    componentDidUpdate({currentMenuItem, setCurrentMenuItem}: Object) {
      if (G.notEquals(getCurrentMenuItem(), currentMenuItem)) {
        setCurrentMenuItem(getCurrentMenuItem());
      }
    },
  }),
  withHandlers({
    handleClickNavItem: ({ clickOnItemAction }: Object) => (action: string) => clickOnItemAction(action),
  }),
);

const CarrierMenu = (props: Object) => {
  const {
    styling,
    photoUrl,
    currentMenuItem,
    sendLogOutRequest,
    handleClickNavItem,
    currentUserSettings,
  } = props;

  const iconsColor = R.pathOr(G.getTheme('colors.light.mainLight'), ['textColor'], styling);

  return (
    <Box>
      <MenuWrapper pt='8px' bg={styling.backgroundColor}>
        <PinnedItems
          currentMenuItem={currentMenuItem}
          carrierPinnedItems={carrierPinnedItems}
          handleClickNavItem={handleClickNavItem}
        />
        <BottomPart>
          <Notifications />
          <UserBox photoUrl={photoUrl} iconsColor={iconsColor} currentUserSettings={currentUserSettings} />
          <NavbarButton
            icon={I.goOut}
            iconsColor={iconsColor}
            onClick={sendLogOutRequest}
            text={G.getWindowLocale('actions:logout', 'Logout')}
          />
        </BottomPart>
      </MenuWrapper>
    </Box>
  );
};

export default enhance(CarrierMenu);
