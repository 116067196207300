import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { HighlightedText } from '../../../components/highlighted-text';
// features
import { setExpandedContainerOptions } from '../../expanded-container/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import {
  withAsyncRequest,
  withAsyncInitialDataOnDidMount,
  withComponentDidUpdatePropCallback,
} from '../../../hocs';
// ui
import { Box, Flex, Text } from '../../../ui';
//////////////////////////////////////////////////

const OrderByVinModal = (props: Object) => {
  const { closeModal, originalList, filterString, handleOpenOrder } = props;

  return (
    <Box
      p={15}
      height={250}
      overflow='auto'
      bg={G.getTheme('colors.light.mainLight')}
    >
      {
        G.isNilOrEmpty(originalList) &&
        <Box p={50}>
          <Flex flexDirection='column'>
            <Text fontSize={16} color={G.getTheme('colors.dark.grey')}>
              {
                G.getWindowLocale(
                  'messages:you-do-not-have-any-orders-by-this-vin',
                  "You don't have any orders by this vin",
                )
              }
            </Text>
          </Flex>
        </Box>
      }
      {
        G.isNotNilAndNotEmpty(originalList)
        && (
          originalList.map((item: Object) => (
            <Box
              key={G.getGuidFromObject(item)}
              p='5px 0px'
              cursor='pointer'
              fontWeight={600}
              textDecoration='underline'
              color={G.getTheme('colors.dark.blue')}
              title={G.getWindowLocale('titles:open-order', 'Open Order')}
              onClick={() => {
                closeModal();
                handleOpenOrder(G.getGuidFromObject(item));
              }}
            >
              <HighlightedText
                highlight={filterString}
                text={G.getPrimaryReferenceValueFromObject(item)}
                highlightBgColor={G.getTheme('colors.light.yellow')}
              />
            </Box>
          ))
        )
      }
    </Box>
  );
};

const enhance = compose(
  connect(null, { setExpandedContainerOptions }),
  withState('originalList', 'setOriginalList', null),
  withAsyncRequest,
  withAsyncInitialDataOnDidMount,
  withHandlers({
    handleOpenOrder: (props: Object) => (orderGuid: string) => {
      const { setExpandedContainerOptions } = props;

      return setExpandedContainerOptions({
        opened: true,
        visitPageGuid: orderGuid,
        componentType: GC.PAGE_DISPATCH_DETAILS_NEW_ORDER,
      });
    },
    handleChangeAsyncInitialData: (props: Object) => () => {
      const { setOriginalList, asyncInitialData } = props;

      const data = R.path(['data'], asyncInitialData);

      if (G.isNotNilAndNotEmpty(data)) {
        setOriginalList(data);
      }
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'asyncInitialData',
    callbackName: 'handleChangeAsyncInitialData',
  }),
  pure,
);

export default enhance(OrderByVinModal);
