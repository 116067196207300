import * as R from 'ramda';
import { delay } from 'redux-saga';
import { push } from 'react-router-redux';
import { put, takeLatest } from 'redux-saga/effects';
// components
import { openLoader } from '../../components/loader/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature oauth-popup
import {
  OAUTH_POPUP_NAME,
  OAUTH2_CODE_RESPONSE,
  getAuthStateFromStorage,
  removeAuthStateFromStorage,
} from './helpers';
import { loginLoadBoardWithCode, loginLoadBoardError } from './actions';
//////////////////////////////////////////////////

function* handleVisitOAuthPageSaga() {
  yield put(openLoader({ showDimmer: true }));

  // Navigate to home if not login popup
  if (R.and(
    window.name,
    G.notEquals(window.name, OAUTH_POPUP_NAME),
  )) {
    yield put(push(GC.ROUTE_PATH_HOME));

    window.location.reload();

    return;
  }

  const { code } = G.parseQueryString();

  yield put(loginLoadBoardWithCode(code));
}

function* handleLBCodeToParentWindow({ payload: code }: Object) {
  const storedState = getAuthStateFromStorage();

  const { state } = G.parseQueryString();

  removeAuthStateFromStorage();

  if (G.notEquals(state, storedState)) {
    yield put(loginLoadBoardError('State is not valid'));

    return;
  }

  if (R.not(window.opener)) {
    yield put(loginLoadBoardError('No window opener'));

    return;
  }

  if (window.opener.closed) window.close();

  window.opener.postMessage({
    type: OAUTH2_CODE_RESPONSE,
    code,
  });
}

function* handleErrors({ payload }) {
  console.info('🚀 --------------------------------------------------------------🚀');
  console.info('🚀 ~ file: function* handleErrors ~ error:', payload);
  console.info('🚀 --------------------------------------------------------------🚀');

  yield delay(100);

  if (R.not(window.opener)) window.close();

  throw new Error(payload);
}

function* oAuthPopupWatcherSaga() {
  yield takeLatest(GC.VISIT_ROUTE_OAUTH_POPUP_PAGE, handleVisitOAuthPageSaga);
  //
  yield takeLatest(loginLoadBoardError, handleErrors);
  yield takeLatest(loginLoadBoardWithCode, handleLBCodeToParentWindow);
}

export default oAuthPopupWatcherSaga;
