import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
import DimmerComponent from '../../../components/loader/dimmer';
import { makeSelectLoader } from '../../../components/loader/selectors';
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { getBranchConfigsByNamesRequest } from '../../branch/actions';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
// helpers/constants
import * as G from '../../../helpers';
// hocs
import { withAsyncLoadDivisionGuid } from '../../../hocs';
// ui
import { Box, Flex, StickedBox } from '../../../ui';
// feature new-do
import LeftSection from './left-section';
import RightSection from './right-section';
import ErrorsComponent from '../components/errors';
import {
  cleanNewDOStore,
  setActiveLeftTab,
  setFormDataToStop,
  addNewStopToStore,
  setActiveRightTab,
  setFormDataToStore,
  removeItemFromStore,
  removeStopFromStore,
  sendDataToApiRequest,
  setInitialStateToStore,
  removeValidationErrors,
  recalculateLoadDistancesRequest,
  sendLoadStopsEditDataToApiRequest,
} from '../actions';
import {
  makeSelectStops,
  makeSelectLoadType,
  makeSelectPageType,
  makeSelectBranchGuid,
  makeSelectLoadTotals,
  makeSelectMapLocations,
  makeSelectLeftActiveTad,
  makeSelectBranchConfigs,
  makeSelectRightActiveTad,
  makeSelectBranchRefTypes,
  makeSelectRouteTelConfigs,
  makeSelectValidationErrors,
} from '../selectors';
//////////////////////////////////////////////////

const Body = (props: Object) => (
  <Flex
    p={15}
    flexWrap='wrap'
    alignItems='flex-start'
  >
    <LeftSection {...props} />
    <RightSection {...props} />
  </Flex>
);

const LoadStopsEdit = (props: Object) => (
  <Box
    height='90vh'
    overflow='auto'
    bg={G.getTheme('colors.bgGrey')}
  >
    {props.loader.showDimmer && <DimmerComponent tableCount={3} rectangleCount={4} />}
    <Box>
      {
        props.validationErrors && (
        <ErrorsComponent
          type='error'
          errors={props.validationErrors}
          remove={props.removeValidationErrors}
          // TODO: check title with Mark ;)
          title='Oops! Looks like there are some mistakes, check the following:'
        />
        )
      }
      <Body {...props} />
    </Box>
    <StickedBox bottom='0px' zIndex={22}>
      <FormFooter
        submitAction={props.handleClickSave}
        cancelAction={props.handleClickCancel}
        boxStyles={{ p: 15, bg: G.getTheme('colors.white'), justifyContent: 'space-between' }}
      />
    </StickedBox>
  </Box>
);

const enhance = compose(
  withAsyncLoadDivisionGuid(),
  withHandlers({
    handleClickSave: ({ sendLoadStopsEditDataToApiRequest }: Object) => () => {
      sendLoadStopsEditDataToApiRequest();
    },
    handleClickCancel: (props: Object) => () => {
      const { closeModal, setInitialStateToStore } = props;

      closeModal();
      setInitialStateToStore();
    },
  }),
  branch(
    (props: Object) => R.not(props.initialDataLoaded),
    renderNothing,
  ),
  pure,
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  stops: makeSelectStops(state),
  loader: makeSelectLoader(state),
  loadType: makeSelectLoadType(state),
  pageType: makeSelectPageType(state),
  loadTotals: makeSelectLoadTotals(state),
  branchGuid: makeSelectBranchGuid(state),
  mapLocations: makeSelectMapLocations(state),
  leftActiveTad: makeSelectLeftActiveTad(state),
  branchConfigs: makeSelectBranchConfigs(state),
  rightActiveTad: makeSelectRightActiveTad(state),
  branchRefTypes: makeSelectBranchRefTypes(state),
  routeTelConfigs: makeSelectRouteTelConfigs(state),
  validationErrors: makeSelectValidationErrors(state),
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  openLoader,
  closeLoader,
  cleanNewDOStore,
  setActiveLeftTab,
  setFormDataToStop,
  addNewStopToStore,
  setActiveRightTab,
  setFormDataToStore,
  removeStopFromStore,
  removeItemFromStore,
  sendDataToApiRequest,
  removeValidationErrors,
  setInitialStateToStore,
  getBranchConfigsByNamesRequest,
  recalculateLoadDistancesRequest,
  sendLoadStopsEditDataToApiRequest,
})(enhance(LoadStopsEdit));
