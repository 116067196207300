import * as R from 'ramda';
import React from 'react';
import { compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// hocs
import { withConnectModalAndLoaderActions } from '../../../hocs';
import { handleMultiItemsVinLookup } from '../../../hocs/with-vin-lookup';
// forms
import SingleTextareaForm from '../../../forms/forms/single-textarea-form';
// feature new-do
import { getNewItemFields } from '../settings/fields-settings';
//////////////////////////////////////////////////

const withMultiVin = compose(
  withConnectModalAndLoaderActions,
  withHandlers({
    handleSubmitMultiVin: (props: Object) => async (value: string) => {
      const { closeModal, openLoader, closeLoader } = props;

      const vins = R.compose(
        R.reject((vin: string) => R.gt(R.length(vin), 18)),
        R.reject(G.isNilOrEmpty),
        R.map(R.trim),
        R.reduce(R.concat, []),
        R.map(R.split(' ')),
      )(R.split('\n', value));

      if (R.gte(R.length(vins), 15)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:gte-vin', 'Please, provide less then 15 vins'),
        );
      }

      openLoader();

      await Promise.all(vins.map(async (vin: string) => {
        await handleMultiItemsVinLookup({ ...props, vin, getNewItemFields });
      }));

      closeLoader();
      closeModal();
    },
  }),
  withHandlers({
    handleOpenMultiVin: (props: Object) => () => {
      const { openModal, closeModal, handleSubmitMultiVin } = props;

      const modal = {
        p: 15,
        component: (
          <SingleTextareaForm
            closeModal={closeModal}
            fieldsGroupWidth='min-content'
            labelLocale='messages:separate-vins'
            submitHandler={(values: Object) => handleSubmitMultiVin(values)}
          />
        ),
        options: {
          width: 330,
          height: 'auto',
          maxHeight: '95vh',
          title: G.getWindowLocale('titles:multi-vin', 'Multi Vin'),
        },
      };
      openModal(modal);
    },
  }),
);

export default withMultiVin;
