import styled, { keyframes } from 'styled-components';
import { space, width, fontSize } from 'styled-system';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const FormSection = styled('section')`
  ${space}
  ${width}

  display: flex;
  flex-wrap: wrap;
  position: static;

  & .Select-value-label {
    overflow: hidden;
    max-width: 220px;
    text-overflow: clip;
    word-break: break-word;
  }

  z-index: ${({ zIndex }: Object) => zIndex || 'initial'};
  justify-content: ${({ justifyContent }: Object) => justifyContent};
  flex-direction: ${({ flexDirection }: Object) => flexDirection || 'row'};
`;

export const Button = styled('button')`
  ${space}
  ${width}
  ${fontSize}

  outline: 0;
  border: none;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: middle;
`;

export const DispatchButton = styled(Button)`
  height: 20px;
  min-width: 65px;
  border-radius: 2px;
  color: ${() => G.getTheme('buttons.dispatchBtn.textColor')};
  background-color: ${() => G.getTheme('buttons.dispatchBtn.bgColor')};
`;

export const ChargesWrapper = styled('div')`
  display: ${({ active }: Object) => G.ifElse(G.isTrue(active), 'block', 'none')};
`;

export const AsyncOption = styled.div`
  ${width}
  ${space}

  &:hover {
    cursor: pointer;
    background: ${() => G.getTheme('colors.dark.mainLight')};
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export const PulseButton = styled('div')`
  animation: ${pulse} ease-in-out infinite both;
  animation-duration: ${({ animationDuration }: Object) => animationDuration};

  &:hover {
    animation-duration: 0s;
  }
`;
