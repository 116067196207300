import * as R from 'ramda';
import { createReducer } from 'redux-act';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useCallback, useReducer } from 'react';
// components
import { openLoader, closeLoader } from '../../../components/loader/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../../components/edit-report/helpers';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// report-common
import { getReportActions, getReportReducers } from '../../../report-common';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature payroll
import { fuelCardsColumnSettings } from '../settings/charges-reports-settings';
//////////////////////////////////////////////////

const A = getReportActions();

const {
  setItemList,
  initialState,
  setListLoading,
  setInitialState,
  setTableTitleSort,
  getItemListSuccess2,
  setTableTitleFilter,
  resetListAndPagination,
} = getReportReducers();

const reducer = createReducer({
  [A.setItemList]: setItemList,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.getItemListSuccess]: getItemListSuccess2,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.resetListAndPagination]: resetListAndPagination,
}, initialState);

const useFuelCards = (payload: Object) => {
  const dispatch = useDispatch();

  const [loadMoreId, setLoadMoreId] = useState();

  const [reportState, reportDispatch] = useReducer(reducer, initialState);

  const {
    loading,
    itemList,
    totalCount,
    pagination,
    filterParams,
    titleSortValues,
    tableTitleFilters,
  } = reportState;

  const { payrollGuid, payrollGuidParam } = payload;

  const currentBranchGuid = useSelector(makeSelectCurrentBranchGuid());

  const fetchItemList = async () => {
    try {
      dispatch(openLoader());

      reportDispatch(A.setListLoading(true));

      const orderFields = G.isNotEmpty(titleSortValues) ? R.values(titleSortValues) : [];

      const searchCriteria = G.isNotEmpty(tableTitleFilters) ? R.values(tableTitleFilters) : [];

      const reqBody = {
        orderFields,
        pageable: false,
        [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
        fields: R.concat(
          G.getReportFieldsFromColumnSettings(fuelCardsColumnSettings),
          [
            { sequence: 12, name: GC.FIELD_EXCLUDED },
            { sequence: 13, name: 'lines', collection: true },
          ],
        ),
        searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
      };

      const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

      const options = {
        params: { [payrollGuidParam]: payrollGuid },
        data: G.setSearchCriteria({ reqBody, filterParams: newFilterParams }),
      };

      const res = await sendRequest('post', endpointsMap.fuelCardList, options);

      const { status } = res;

      if (G.isResponseSuccess(status)) {
        const fuelCards = R.map(
          (charge: Object) => {
            const details = R.map(
              (line: Object) => ({
                selected: R.not(R.prop(GC.FIELD_EXCLUDED, line)),
                checkboxDisabled: G.getPropFromObject(GC.FIELD_ADVANCE_PAYMENT, line),
                ...line,
              }),
              charge.lines,
            );

            return {
              ...charge,
              details,
              removed: false,
              expanded: false,
              selected: R.not(R.prop(GC.FIELD_EXCLUDED, charge)),
            };
          },
          R.pathOr([], ['data', 'results'], res),
        );

        reportDispatch(A.getItemListSuccess(
          { results: fuelCards, totalCount: R.pathOr(0, ['data', 'totalCount'], res) },
        ));
      } else {
        await G.handleFailResponseSimple(res, 'useFuelCards/fetchItemList fail');
      }

      dispatch(closeLoader());

      reportDispatch(A.setListLoading(false));
    } catch (error) {
      dispatch(closeLoader());

      reportDispatch(A.setListLoading(false));

      await G.showToastrMessageSimple('error', 'messages:error:unknown');
      await G.handleException(error, 'useFuelCards/fetchItemList exception');
    }
  };

  useEffect(() => {
    fetchItemList();
  }, [
    titleSortValues,
    tableTitleFilters,
  ]);

  useEffect(() => {
    if (G.isNilOrEmpty(loadMoreId)) return;

    fetchItemList();
  }, [loadMoreId]);

  const toggleFuelCardDetails = useCallback(({ guid }: Object) => {
    const newData = R.map((fuelCard: Object) => {
      if (R.equals(G.getGuidFromObject(fuelCard), guid)) {
        return R.assoc('expanded', R.not(fuelCard.expanded), fuelCard);
      }

      return fuelCard;
    }, itemList);

    reportDispatch(A.setItemList(newData));
  }, [itemList]);


  const selectFuelCardLine = useCallback((guid: string, fuelCardGuid: string) => {
    const newData = R.map((fuelCard: Object) => {
      if (R.equals(G.getGuidFromObject(fuelCard), fuelCardGuid)) {
        const newDetails = R.map(
          (line: Object) => {
            if (R.equals(G.getGuidFromObject(line), guid)) {
              return R.assoc('selected', R.not(line.selected), line);
            }

            return line;
          },
          fuelCard.details,
        );

        return {
          ...fuelCard,
          details: newDetails,
          selected: R.any(R.prop('selected'), newDetails),
        };
      }

      return fuelCard;
    }, itemList);

    reportDispatch(A.setItemList(newData));
  }, [itemList]);

  const selectItem = useCallback((guid: string) => {
    const newData = G.selectItem(itemList, guid);

    reportDispatch(A.setItemList(newData));
  }, [itemList]);

  const removeOrRestoreItem = useCallback((guid: string) => {
    const newData = R.map((fuelCard: Object) => {
      if (R.equals(G.getGuidFromObject(fuelCard), guid)) {
        return R.assoc('removed', R.not(fuelCard.removed), fuelCard);
      }

      return fuelCard;
    }, itemList);

    reportDispatch(A.setItemList(newData));
  }, [itemList]);

  const addItems = useCallback((charges: Array) => {
    const newData = R.compose(
      R.concat(itemList),
      R.map(
        (charge: Object) => {
          const details = R.map(
            (line: Object) => ({
              selected: R.not(R.prop(GC.FIELD_EXCLUDED, line)),
              checkboxDisabled: G.getPropFromObject(GC.FIELD_ADVANCE_PAYMENT, line),
              ...line,
            }),
            charge.lines,
          );

          return {
            ...charge,
            details,
            removed: false,
            expanded: false,
            selected: R.not(R.prop(GC.FIELD_EXCLUDED, charge)),
          };
        },
      ),
    )(charges);

    reportDispatch(A.setItemList(newData));
  }, [itemList]);

  return {
    selectFuelCardLine,
    toggleFuelCardDetails,
    selectFuelCard: selectItem,
    addChargesToFuelCards: addItems,
    fuelCardsSetLoadMoreId: setLoadMoreId,
    removeOrRestoreFuelCard: removeOrRestoreItem,
    // report
    fuelCardsLoading: loading,
    fuelCards: R.or(itemList, []),
    fuelCardsTotalCount: totalCount,
    fuelCardsPagination: pagination,
    fuelCardsReportDispatch: reportDispatch,
    fuelCardsTitleSortValues: titleSortValues,
    fuelCardsTableTitleFilters: tableTitleFilters,
  };
};

export default useFuelCards;

const withFuelCards = ({ payrollGuidParam }: Object) => (Component: Function) => (props: Object) => {
  const { payrollGuid } = props;

  const hook = useFuelCards({ payrollGuid, payrollGuidParam });

  return <Component {...props} {...hook} />;
};

export {
  A,
  withFuelCards,
};
