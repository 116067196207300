import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  ITEM_VOLUME_OPTIONS,
  DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
  DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
} from '../../../helpers/options';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, MainActionButton } from '../../../ui';
// feature rate
import { getTotalDistanceFromValues } from '../helpers';
//////////////////////////////////////////////////

const telRateInitFields = {
  [GC.FIELD_MODE]: null,
  [GC.FIELD_CURRENCY]: null,
  [GC.FIELD_COMMENTS]: null,
  [GC.FIELD_TRUCK_GUID]: null,
  [GC.FIELD_SERVICE_TYPE]: null,
  [GC.FIELD_SERVICE_DAYS]: null,
  [GC.FIELD_TRAILER_GUIDS]: null,
  [GC.FIELD_TOTAL_TRIP_VOLUME]: null,
  [GC.FIELD_DEADHEAD_DISTANCE]: null,
  [GC.FIELD_TOTAL_TRIP_WEIGHT]: null,
  [GC.FIELD_TOTAL_TRIP_DISTANCE]: null,
  [GC.FIELD_PRIMARY_DRIVER_GUID]: null,
  [GC.FIELD_TOTAL_TRIP_VOLUME_UOM]: null,
  [GC.FIELD_DEADHEAD_DISTANCE_UOM]: null,
  [GC.FIELD_SECONDARY_DRIVER_GUID]: null,
  [GC.FIELD_MARGIN_VIOLATION_NOTE]: null,
  [GC.FIELD_MARGIN_VIOLATION_REASON]: null,
  [GC.FIELD_TOTAL_TRIP_DISTANCE_UOM]: null,
};

const firstInputWrapperStyles = {
  mr: 20,
  flexGrow: 1,
  flexBasis: 160,
};

const firstFieldset = [
  {
    type: 'select',
    isRequired: true,
    fieldName: GC.FIELD_CURRENCY,
    options: GC.CURRENCY_OPTIONS,
    label: ['titles:currency', 'Currency'],
    inputWrapperStyles: firstInputWrapperStyles,
  },
  {
    type: 'select',
    isRequired: true,
    options: GC.FIELD_MODE,
    fieldName: GC.FIELD_MODE,
    label: ['titles:mode', 'Mode'],
    inputWrapperStyles: firstInputWrapperStyles,
  },
  {
    type: 'select',
    options: GC.FIELD_SERVICE_TYPE,
    fieldName: GC.FIELD_SERVICE_TYPE,
    inputWrapperStyles: firstInputWrapperStyles,
    label: ['titles:service-type', 'Service Type'],
  },
  {
    type: 'number',
    fieldName: GC.FIELD_SERVICE_DAYS,
    inputWrapperStyles: firstInputWrapperStyles,
    label: ['titles:service-days', 'Service Days'],
  },
  {
    type: 'number',
    fieldName: GC.FIELD_CUSTOMER_TOTAL,
    label: ['titles:customer-total', 'Customer Total'],
    inputWrapperStyles: { ...firstInputWrapperStyles, mr: 0 },
  },
];

const secondFieldset = [
  {
    type: 'number',
    isRequired: true,
    fieldName: GC.FIELD_TOTAL_TRIP_DISTANCE,
    inputStyles: { borderRadius: '4px 0 0 4px' },
    label: ['titles:trip-distance', 'Trip Distance'],
    inputWrapperStyles: { flexGrow: 1, flexBasis: 100 },
  },
  {
    type: 'select',
    fieldName: GC.FIELD_TOTAL_TRIP_DISTANCE_UOM,
    options: DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
    inputStyles: { borderRadius: '0 4px 4px 0' },
    inputWrapperStyles: { flexGrow: 1, flexBasis: 90, mr: 20 },
  },
  {
    type: 'number',
    isRequired: false,
    fieldName: GC.FIELD_DEADHEAD_DISTANCE,
    label: ['titles:deadhead', 'Deadhead'],
    inputStyles: { borderRadius: '4px 0 0 4px' },
    inputWrapperStyles: { flexGrow: 1, flexBasis: 100 },
  },
  {
    type: 'select',
    fieldName: GC.FIELD_DEADHEAD_DISTANCE_UOM,
    options: DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
    inputStyles: { borderRadius: '0 4px 4px 0' },
    inputWrapperStyles: { flexGrow: 1, flexBasis: 90, mr: 20 },
  },
  {
    type: 'number',
    isRequired: true,
    fieldName: GC.FIELD_TOTAL_TRIP_WEIGHT,
    inputStyles: { borderRadius: '4px 0 0 4px' },
    label: ['titles:total-weight', 'Total Weight'],
    inputWrapperStyles: { flexGrow: 1, flexBasis: 100 },
  },
  {
    type: 'select',
    options: DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
    fieldName: GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
    inputStyles: { borderRadius: '0 4px 4px 0' },
    inputWrapperStyles: { mr: 20, flexGrow: 1, flexBasis: 90 },
  },
  {
    type: 'number',
    fieldName: GC.FIELD_TOTAL_TRIP_VOLUME,
    inputStyles: { borderRadius: '4px 0 0 4px' },
    label: ['titles:total-volume', 'Total Volume'],
    inputWrapperStyles: { flexGrow: 1, flexBasis: 100 },
  },
  {
    type: 'select',
    options: ITEM_VOLUME_OPTIONS,
    fieldName: GC.FIELD_TOTAL_TRIP_VOLUME_UOM,
    inputStyles: { borderRadius: '0 4px 4px 0' },
    inputWrapperStyles: { flexGrow: 1, flexBasis: 90 },
  },
];

const setDisplayForQuantityFields = (props: Object) => G.ifElse(
  R.pathEq(GC.INVOICE_TYPE_CARRIER_INVOICE, ['values', GC.FIELD_TYPE], props),
  'block',
  'none',
);

const thirdFieldset = [
  {
    type: 'customComponent',
    inputWrapperStyles: { mr: 20, width: 248 },
    Component: (props: Object) => {
      const { values } = props;

      const defaultUomSystem = G.getConfigGeneralUomCalcDefaultUomSystemFromWindow();
      const defaultUom = R.pathOr(GC.UOM_MILE, [defaultUomSystem], GC.uomSystemToDistanceUomMap);
      const defaultUomLocale = G.getUomLocale(defaultUom);

      const totalDistance = getTotalDistanceFromValues(values);

      const text = `${totalDistance} ${defaultUomLocale}`;

      return (
        <Flex>
          <Box
            pl='5px'
            fontSize={11}
            color={G.getTheme('colors.darkGrey')}
          >
            {G.getWindowLocale('titles:total-distance', 'Total Distance')}:
          </Box>
          <TextComponent
            ml='8px'
            title={text}
            maxWidth='100%'
            fontWeight={700}
            withEllipsis={true}
          >
            {text}
          </TextComponent>
        </Flex>
      );
    },
  },
  {
    type: 'number',
    fieldName: GC.FIELD_TOTAL_PICKUP_QUANTITY,
    inputStyles: { borderRadius: '4px 0 0 4px' },
    label: ['titles:pickup-quantity', 'Pickup Quantity'],
    inputWrapperStyles: {
      flexGrow: 1,
      flexBasis: 100,
      display: setDisplayForQuantityFields,
    },
  },
  {
    type: 'select',
    fieldName: GC.FIELD_PACKAGE_TYPE,
    options: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
    inputStyles: { borderRadius: '0 4px 4px 0' },
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 1,
      flexBasis: 90,
      display: setDisplayForQuantityFields,
    },
  },
  {
    type: 'text',
    fieldName: GC.FIELD_COMMENTS,
    label: ['titles:comments', 'Comments'],
    inputWrapperStyles: { flexGrow: 1, flexBasis: 490 },
  },
];

const inputWrapperStyles = {
  mb: 25,
  width: 350,
};

const inputWrapperStyles2 = {
  mb: 25,
  width: 250,
};

const formatOptionLabel = ({ lnl, total, label, distance }: Object) => (
  <Flex alignItems='start' flexDirection='column'>
    <Box>{label}</Box>
    <Box>{distance}</Box>
    <Box>{lnl}</Box>
    <Box>{total}</Box>
  </Flex>
);

const getDriversFieldset = ({
  isCreate,
  isUpdate,
  handleClickShowMap,
  handleClickRefreshDrivers,
  showRefreshAvailableDrivers,
  handleGetSharedAccessorialsApplyTo,
}: Object) => ({
  fields: [
    {
      isRequired: true,
      formatOptionLabel,
      type: 'reactSelect',
      withoutFixedHeight: true,
      shouldCustomChange: true,
      options: 'primaryDriversOptions',
      fieldName: GC.FIELD_PRIMARY_DRIVER_GUID,
      additionalLabelStyles: { top: -33, right: 0 },
      label: ['titles:primary-driver', 'Primary Driver'],
      inputWrapperStyles: { ...inputWrapperStyles, zIndex: 13 },
      additionalLabelComponent: (
        <Flex>
          {
            G.isTrue(isCreate) &&
            <MainActionButton
              mr={15}
              height={26}
              width={100}
              type='button'
              onClick={handleClickShowMap}
            >
              {G.getWindowLocale('titles:show-map', 'Show Map')}
            </MainActionButton>
          }
          {
            G.isTrue(isUpdate) &&
            <MainActionButton
              height={26}
              width={130}
              type='button'
              onClick={handleGetSharedAccessorialsApplyTo}
            >
              {G.getWindowLocale('titles:update-auto-acc', 'Update Auto Acc.')}
            </MainActionButton>
          }
          {
            showRefreshAvailableDrivers &&
            <MainActionButton
              height={26}
              width={100}
              type='button'
              onClick={handleClickRefreshDrivers}
            >
              {G.getWindowLocale('titles:get-rate', 'Get Rate')}
            </MainActionButton>
          }
        </Flex>
      ),
    },
    {
      formatOptionLabel,
      type: 'reactSelect',
      withoutFixedHeight: true,
      shouldCustomChange: true,
      options: 'secondaryDriversOptions',
      fieldName: GC.FIELD_SECONDARY_DRIVER_GUID,
      label: ['titles:team-driver', 'Team Driver'],
      inputWrapperStyles: { ...inputWrapperStyles, zIndex: 12 },
    },
    {
      disabled: isUpdate,
      type: 'reactSelect',
      withoutFixedHeight: true,
      useMenuPortalTarget: true,
      options: 'dispatchedByOptions',
      fieldName: GC.FIELD_DISPATCHED_BY_LOGIN,
      label: ['titles:dispatched-by', 'Dispatched By'],
      inputWrapperStyles: {
        ...inputWrapperStyles,
        display: () => G.ifElse(
          G.hasNotAmousCurrentUserPermissions([PC.TEL_RATE_DISPATCH_BY_OVERWRITE_EXECUTE]),
          'none',
        ),
      },
    },
  ],
});

const truckTrailersFieldset = {
  fields: [
    {
      isRequired: true,
      type: 'reactSelect',
      options: 'trucksOptions',
      withoutFixedHeight: true,
      shouldCustomChange: true,
      fieldName: GC.FIELD_TRUCK_GUID,
      label: ['titles:truck', 'Truck'],
      inputWrapperStyles: { ...inputWrapperStyles2, zIndex: 13 },
    },
    {
      isMulti: true,
      type: 'reactSelect',
      options: 'trailersOptions',
      fieldName: GC.FIELD_TRAILER_GUIDS,
      label: ['titles:trailers', 'Trailers'],
      inputWrapperStyles: { ...inputWrapperStyles2, zIndex: 12 },
      isRequired: () => G.getAmousBranchConfigFromWindow(GC.TEL_DRIVER_RATE_TRAILER_REQUIRED),
    },
  ],
};

const LabelComponent = ({ title, action, fieldName }: Object) => {
  const onClick = () => action({
    fieldName,
    fieldType: 'locationObject',
  });

  return (
    <Box onClick={onClick} title={title} cursor='pointer'>{I.globalSearch2(G.getTheme('colors.dark.blue'))}</Box>
  );
};

const locationInputWrapperStyles = {
  mb: 20,
  width: 350,
  zIndex: 11,
};

const getPointLocationSettings = ({ values, handleSearchLocation }: Object) => {
  const title = G.getWindowLocale('titles:search-location', 'Search Location');
  const placeholder = G.getWindowLocale('titles:search-a-location', 'Search a location...');
  const disabled = G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_PRIMARY_DRIVER_GUID, values));

  return [
    {
      disabled,
      placeholder,
      useFormattedAddress: true,
      type: 'addressAutocomplete',
      fieldType: 'locationObject',
      additionalStyles: { pr: 20 },
      additionalLabelStyles: { top: 10 },
      fieldName: GC.FIELD_START_POINT_LOCATION,
      inputWrapperStyles: locationInputWrapperStyles,
      label: ['titles:start-point-location', 'Start Point Location'],
      isRequired: () => G.getAmousBranchConfigFromWindow(GC.TEL_DRIVER_RATE_START_POINT_REQUIRED),
      additionalLabelComponent: G.isFalse(disabled) && (
        <LabelComponent
          title={title}
          action={handleSearchLocation}
          fieldName={GC.FIELD_START_POINT_LOCATION}
        />
      ),
      infoFunction: ({ values }: Object) => {
        const sourceType = R.path([GC.FIELD_START_POINT_LOCATION, 'sourceType'], values);
        const sourceName = R.path([GC.FIELD_START_POINT_LOCATION, 'sourceName'], values);

        const string = G.createStringFromArray([sourceType, sourceName]);

        if (G.isNotNilAndNotEmpty(string)) {
          const source = G.getWindowLocale('titles:source', 'Source');
          const sourceTypeLocale = G.getEnumLocale(sourceType);

          return `${source}: ${sourceTypeLocale}, ${sourceName}`;
        }

        return null;
      },
    },
    {
      disabled,
      placeholder,
      useFormattedAddress: true,
      type: 'addressAutocomplete',
      fieldType: 'locationObject',
      additionalStyles: { pr: 20 },
      additionalLabelStyles: { top: 10 },
      fieldName: GC.FIELD_FINISH_POINT_LOCATION,
      inputWrapperStyles: locationInputWrapperStyles,
      label: ['titles:finish-point-location', 'Finish Point Location'],
      isRequired: () => G.getAmousBranchConfigFromWindow(GC.TEL_DRIVER_RATE_FINISH_POINT_REQUIRED),
      additionalLabelComponent: G.isFalse(disabled) && (
        <LabelComponent
          title={title}
          action={handleSearchLocation}
          fieldName={GC.FIELD_FINISH_POINT_LOCATION}
        />
      ),
    },
  ];
};

const chassisInputWrapperStyles = {
  mb: 25,
  width: 250,
};

const chassisFields = [
  {
    type: 'text',
    fieldName: GC.FIELD_CHASSIS_NUMBER,
    inputWrapperStyles: chassisInputWrapperStyles,
    label: ['titles:chassis-number', 'Chassis Number'],
  },
  {
    type: 'text',
    inputWrapperStyles: chassisInputWrapperStyles,
    fieldName: GC.FIELD_DRAY_CONTAINER_PICK_UP_NUMBER,
    label: ['titles:container-pick-up-number', 'Container Pick Up Number'],
  },
  {
    type: 'text',
    inputWrapperStyles: chassisInputWrapperStyles,
    fieldName: GC.FIELD_DRAY_CONTAINER_DROP_NUMBER,
    label: ['titles:container-drop-number', 'Container Drop Number'],
  },
];

const generalDMSectionFields = [
  firstFieldset,
  secondFieldset,
  thirdFieldset,
];

const getDriverSectionSettings = ({
  isCreate,
  isUpdate,
  handleClickShowMap,
  handleClickRefreshDrivers,
  showRefreshAvailableDrivers,
  handleGetSharedAccessorialsApplyTo,
}: Object) => [
  getDriversFieldset({
    isCreate,
    isUpdate,
    handleClickShowMap,
    handleClickRefreshDrivers,
    showRefreshAvailableDrivers,
    handleGetSharedAccessorialsApplyTo,
  }),
  truckTrailersFieldset,
];

export {
  chassisFields,
  telRateInitFields,
  generalDMSectionFields,
  getDriverSectionSettings,
  getPointLocationSettings,
};
