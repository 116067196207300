import * as R from 'ramda';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
//////////////////////////////////////////////////

export const withAsyncGetMatchedFields = compose(
  withState('matchedFields', 'setMatchedFields', []),
  withHandlers({
    handleGetMatchedFields: (props: Object) => async ({ type, file }: Object) => {
      const {
        emptyRow,
        openLoader,
        closeLoader,
        matchedFields,
        setDefaultFields,
        setMatchedFields,
        setMatchedOptions } = props;

      openLoader();
      const uri = G.getPropFromObject(GC.FIELD_URI, matchedFields);
      if (G.isNotNilAndNotEmpty(uri)) {
        const options = { params: { uri } };

        await sendRequest('delete', endpointsMap.importerUpload, options);
      }
      const formData = new window.FormData();
      formData.append('file', file);
      const params = { type };
      const asyncOptions = { params, data: formData };
      const res = await sendRequest('post', endpointsMap.importerUpload, asyncOptions);
      const { data, status } = res;
      if (G.isResponseSuccess(status)) {
        const headers = R.compose(
          R.filter(({ name }: Object) => G.isNotNilAndNotEmpty(name)),
          R.pathOr([], ['headers']),
        )(data);
        if (G.isNotNilAndNotEmpty(headers)) {
          const message = G.getWindowLocale('messages:success:200-201', 'The request has succeeded');

          G.showToastrMessageSimple('success', message);

          const defaultMatchedFields = R.reduce((result: Object, item: Object) => {
            const { name, matchedField } = item;

            if (G.isNotNilAndNotEmpty(matchedField)) return R.assoc(name, matchedField, result);

            return R.assoc(item.name, '', result);
          }, {}, headers);
          const matchedOptions = R.compose(
            G.addEmptyOptionToDropDown,
            R.map((value: Object) => (
              {
                value,
                [GC.FIELD_LABEL]: G.getWindowLocale(`titles:${value}`, value),
              }
            )),
            R.keys,
          )(R.or(emptyRow, {}));

          setMatchedOptions(matchedOptions);
          setDefaultFields(defaultMatchedFields);
          setMatchedFields(R.assoc('headers', headers, data));
        } else {
          const message = G.getWindowLocale('messages:valid-data', 'Please, provide valid data');

          G.showToastrMessageSimple('error', message);
        }
      } else {
        G.handleFailResponseSimple(res);
      }

      closeLoader();
    },
  }),
  pure,
);
