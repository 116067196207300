import * as R from 'ramda';
import React from 'react';
import { pure } from 'react-recompose';
// forms
import { Fieldset2 } from '../../forms';
// helpers/constants
import * as G from '../../helpers';
// feature rate
import { FormSection } from './ui';
import { disableDriverField } from './helpers';
import { generalServiceVendorSectionSettings } from './settings';
import {
  chassisFields,
  generalDMSectionFields,
  getDriverSectionSettings,
  getPointLocationSettings,
} from './driver/settings';
//////////////////////////////////////////////////

const fieldsWrapperStyles = { mt: 20, flexDirection: 'column' };

export const GeneralSectionForTel = pure((props: Object) => {
  const { optionsForSelect } = props;

  return (
    <FormSection mt={20} px={20}>
      {generalDMSectionFields.map((fields: Object, index: number) => (
        <Fieldset2
          {...G.getFormikProps(props)}
          {...optionsForSelect}
          key={index}
          fields={fields}
          fieldsWrapperStyles={{
            flexGrow: 1,
            mb: G.ifElse(R.equals(index, R.dec(R.length(generalDMSectionFields))), 0, 25),
          }}
        />
      ))}
    </FormSection>
  );
});

export const GeneralSectionForServiceVendor = pure((props: Object) => {
  const { optionsForSelect } = props;

  return (
    <Fieldset2
      {...optionsForSelect}
      {...G.getFormikProps(props)}
      fieldsWrapperStyles={{ mt: 25, px: 20 }}
      fields={generalServiceVendorSectionSettings}
    />
  );
});

export const DriverSection = pure((props: Object) => {
  const { optionsForSelect, handleCustomChange } = props;

  return (
    <FormSection mt={20} justifyContent='space-around'>
      {getDriverSectionSettings(props).map(({ fields }: Object, index: number) => (
        <Fieldset2
          {...optionsForSelect}
          {...G.getFormikProps(props)}
          key={index}
          fields={fields}
          handleCustomChange={handleCustomChange}
          fieldsWrapperStyles={fieldsWrapperStyles}
        />
      ))}
      {
        G.getShowChassisSectionConfigFromWindow() &&
        <Fieldset2
          {...G.getFormikProps(props)}
          fields={chassisFields}
          fieldsWrapperStyles={fieldsWrapperStyles}
        />
      }
    </FormSection>
  );
});

export const EditDriverSection = pure((props: Object) => {
  const { optionsForSelect, handleCustomChange } = props;

  return (
    <FormSection mt={20} justifyContent='space-around'>
      {getDriverSectionSettings(props).map(({ fields }: Object, index: number) => {
        const fieldsToUse = R.map((field: Object) => disableDriverField(field, props), fields);

        return (
          <Fieldset2
            {...optionsForSelect}
            {...G.getFormikProps(props)}
            key={index}
            fields={fieldsToUse}
            handleCustomChange={handleCustomChange}
            fieldsWrapperStyles={fieldsWrapperStyles}
          />
        );
      })}
      {
        G.getShowChassisSectionConfigFromWindow() &&
        <Fieldset2
          {...G.getFormikProps(props)}
          fields={chassisFields}
          fieldsWrapperStyles={fieldsWrapperStyles}
        />
      }
    </FormSection>
  );
});

export const PointsSection = pure((props: Object) => {
  const { handleSelectLocation, handleChangeSelectLocation } = props;

  return (
    <Fieldset2
      {...G.getFormikProps(props)}
      fields={getPointLocationSettings(props)}
      customSelectLocationFunction={handleSelectLocation}
      handlers={{ customHandleChange: handleChangeSelectLocation }}
      fieldsWrapperStyles={{ mt: 25, justifyContent: 'space-around' }}
    />
  );
});

export const ChassisSection = pure((props: Object) => (
  <Fieldset2
    {...G.getFormikProps(props)}
    fields={chassisFields}
    fieldsWrapperStyles={{ mt: 20, justifyContent: 'space-around' }}
  />
));
