import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose, lifecycle, withHandlers } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withConnectModalAndLoaderActions, withAsyncGetBranchChildrenWithShared } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature rate-shop
import { importFromFileRequest } from '../actions';
//////////////////////////////////////////////////

const blackColor = G.getTheme('colors.light.black');
const lightblueColor = G.getTheme('colors.light.blue');
const stringRequired = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());
const stringNotRequired = Yup.string().notRequired().nullable(true);

const defaultFieldStyles = {
  width: 300,
  errorTop: '110%',
  afterTop: '14px',
  errorTitle: true,
  errorLeft: '10px',
  labelWidth: '100%',
  errorWidth: '100%',
  errorOverflow: 'hidden',
  flexDirection: 'column',
  errorWhiteSpace: 'nowrap',
  errorPosition: 'absolute',
  inputWrapMargin: '5px 0px',
  errorTextOverflow: 'ellipsis',
};

const fieldSettings = [
  {
    ...defaultFieldStyles,
    type: 'searchselect',
    loc: 'titles:customer',
    options: 'customerOptions',
    fieldName: GC.FIELD_CUSTOMER_GUID,
  },
  {
    ...defaultFieldStyles,
    loc: 'titles:quote-name',
    fieldName: GC.FIELD_QUOTE_NAME,
  },
  {
    ...defaultFieldStyles,
    type: 'file',
    loc: 'titles:upload-file',
    fieldName: GC.FIELD_DOCUMENT_UPLOAD,
  },
];

const formEnhance = compose(
  withAsyncGetBranchChildrenWithShared,
  lifecycle({
    componentDidMount() {
      this.props.getBranchChildrenWithShared();
    },
  }),
  withFormik({
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    validationSchema: Yup.object().shape({
      [GC.FIELD_QUOTE_NAME]: stringNotRequired,
      [GC.FIELD_CUSTOMER_GUID]: stringRequired,
      [GC.FIELD_DOCUMENT_UPLOAD]: stringRequired,
    }),
    mapPropsToValues: () => ({
      [GC.FIELD_QUOTE_NAME]: null,
      [GC.FIELD_CUSTOMER_GUID]: null,
      [GC.FIELD_DOCUMENT_UPLOAD]: null,
    }),
  }),
  pure,
);

const ImportFromFileForm = formEnhance((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent
      {...G.getFormikProps(props)}
      fields={fieldSettings}
      flexDirection='column'
      fileName={G.getWindowLocale('titles:select-file', 'Select File')}
      optionsForSelect={{
        customerOptions: R.map(({ name, guid }: Object) => ({
          [GC.FIELD_LABEL]: name,
          [GC.FIELD_VALUE]: guid,
        }), R.pathOr([], ['asyncBranchChildrenWithShared'], props)),
      }}
    />
    <FormFooter boxStyles={{ pt: 10 }} closeModal={props.closeModal} />
  </form>
));

const enhance = compose(
  withConnectModalAndLoaderActions,
  connect(null, { importFromFileRequest }),
  withHandlers({
    handleOpenImportFromFileForm: ({ openModal, closeModal, importFromFileRequest }: Object) => () => {
      const component = <ImportFromFileForm closeModal={closeModal} submitAction={importFromFileRequest} />;
      const modal = {
        p: 15,
        component,
        options: {
          title: G.getWindowLocale('titles:import', 'Import'),
        },
      };

      openModal(modal);
    },
    handleDownloadTemplate: ({ openLoader, closeLoader }: Object) => async () => {
      G.callFunction(openLoader);

      const options = { resType: 'arraybuffer' };

      const res = await sendRequest('get', endpointsMap.excelMassRatingTemplateFile, options);

      const { status } = res;

      if (G.isResponseSuccess(status)) {
        G.saveFileFromResponse(res);
      } else {
        G.handleFailResponseSimple(res);
      }

      G.callFunction(closeLoader);
    },
  }),
  pure,
);

const ImportFromFileSection = (props: Object) => (
  <Flex ml={20}>
    <Flex cursor='pointer' onClick={props.handleOpenImportFromFileForm}>
      <Box>
        {G.getWindowLocale('titles:import', 'Import')}
      </Box>
      <Box ml={10}>
        {I.downloadDocument(blackColor)}
      </Box>
    </Flex>
    <Flex ml={20} cursor='pointer' onClick={props.handleDownloadTemplate}>
      <Box>
        {G.getWindowLocale('titles:download-template', 'Download Template')}
      </Box>
      <Box ml={10}>
        {I.downloadDocument(lightblueColor)}
      </Box>
    </Flex>
  </Flex>
);

export default enhance(ImportFromFileSection);

