import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { generateDefaultReport } from '../../report-common';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature public-clo
import * as A from './actions';
import { FILTER_PARAMS } from './settings/filter-params';
import {
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectFilterParams,
  makeSelectAvailableReports,
} from './selectors';
//////////////////////////////////////////////////

export function* handleGetReportListRequest({ payload }: boolean) {
  try {
    yield put(A.setListLoading(true));
    const availableReports = yield select(makeSelectAvailableReports());
    const reportParams = yield select(makeSelectUsedReport());
    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.equals('itemDefault', R.path(['guid'], reportParams)),
    )) {
      return yield put(A.setListLoading(false));
    }
    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const systemFields = [
      GC.FIELD_GUID,
      GC.FIELD_ERRORS,
      GC.FIELD_STATUS,
      GC.GRC.CLO_GUID,
      GC.FIELD_BRANCH_GUID,
      GC.GRC.EDI_CLO_STATUS,
      GC.GRC.EDI_CREATED_CLO_GUID,
    ];
    const reqBody = {
      systemFields,
      limit: pagination.limit,
      offset: pagination.offset,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      orderFields: G.getOrElse(reportParams, 'orderFields', []),
      searchCriteria: G.getOrElse(reportParams, 'searchCriteria', []),
    };
    const reqData = G.addSystemFieldsToTableReport(G.setSearchCriteria({ filterParams, reqBody }), systemFields);
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.publicCLOList,
      { data: reqData },
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getReportListSuccess(R.assoc('currentBranch', currentBranchGuid, data)));
      yield put(A.setListLoading(false));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetReportListRequest fail');
      yield put(A.setListLoading(false));
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetReportListRequest exception');
    yield put(A.setListLoading(false));
  }
}

export function* handleDeleteListItemRequest({ payload }: Object) {
  try {
    const options = {
      data: payload,
    };
    const res = yield call(sendRequest, 'delete', endpointsMap.publicCLO, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status, data)) {
      yield put(A.resetListAndPagination());
      yield put(A.getReportListRequest(true));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteListItemRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteListItemRequest exception');
  }
}

export function* handleAvailableReportsRequest({ payload, notSetUsedReport }: Object) {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const reportType = payload.reportType;
    const params = {
      reportType,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };
    const res = yield call(sendRequest, 'get', endpointsMap.listReports, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const reports = G.getReportsSortedBySeqFreez(data);
      yield put(A.setReports(reports));
      if (R.not(R.prop('length', reports))) {
        const generatedReport = generateDefaultReport(GC.ORDER_REQUEST_REPORT);

        return yield put(A.setUsedReport(generatedReport));
      }
      if (R.not(notSetUsedReport)) {
        const defaultReport = G.findDefaultReport(reports);
        const usedReport = R.or(defaultReport, generateDefaultReport(GC.ORDER_REQUEST_REPORT));

        yield put(A.setUsedReport(usedReport));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleAvailableReportsRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleAvailableReportsRequest exception');
  }
}

export function* handleCreateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const data = R.assoc(GC.FIELD_BRANCH_GUID, currentBranchGuid, payload);
    const res = yield call(sendRequest, 'post', endpointsMap.report, { data });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.setUsedReport(G.getReportSortedBySeqFreez(res.data)));
      yield call(
        handleAvailableReportsRequest,
        {
          payload: {
            reportType: GC.ORDER_REQUEST_REPORT,
          },
          notSetUsedReport: true,
        },
      );
      yield call(handleGetReportListRequest, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateReportRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateReportRequestSaga exception');
  }
}

export function* handleUpdateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'put', endpointsMap.report, { data: payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.setUsedReport(G.getReportSortedBySeqFreez(data)));
      yield call(
        handleAvailableReportsRequest,
        {
          payload: {
            reportType: GC.ORDER_REQUEST_REPORT,
            pathname: GC.ROUTE_PATH_PUBLIC_CLO_LIST,
          },
          notSetUsedReport: true,
        },
      );
      yield call(handleGetReportListRequest, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateReportRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateReportRequestSaga exception');
  }
}

export function* handleChangeDefaultReportSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'put', endpointsMap.changeDefaultReport, { data: payload });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(
        handleAvailableReportsRequest,
        {
          payload: {
            reportType: GC.ORDER_REQUEST_REPORT,
            pathname: GC.ROUTE_PATH_PUBLIC_CLO_LIST,
          },
        },
      );
      yield put(A.resetListAndPagination());
      yield call(handleGetReportListRequest, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeDefaultReportSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleChangeDefaultReportSaga exception');
  }
}

export function* handleSetFilterParams() {
  yield put(A.setFilterProps(FILTER_PARAMS));
}

export function* handleVisitPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_PUBLIC_CLO_LIST_PAGE);
    yield put(openLoader({ showDimmer: true }));
    yield put(A.setInitialState());
    yield put(A.setIgnorePromptStatus(false));
    yield put(A.setReportPending());
    yield call(handleAvailableReportsRequest, { payload });
    yield call(handleGetReportListRequest, { payload: true });
    yield call(handleSetFilterParams);
    yield put(closeLoader());
    break;
  }
}

function* pageWatcherSaga() {
  yield takeLatest(GC.VISIT_PUBLIC_CLO_LIST_PAGE, handleVisitPageSaga);
  yield takeLatest(A.getReportListRequest, handleGetReportListRequest);
  yield takeLatest(A.deleteListItemRequest, handleDeleteListItemRequest);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
}

export default pageWatcherSaga;
