import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
// features
import {
  makeSelectLoadType as makeSelectLoadTypeLite,
  makeSelectStopsData as makeSelectStopsDataLite,
} from '../../lite-new-do/selectors';
import {
  makeSelectLoadType as makeSelectLoadTypeQuote,
  makeSelectStopsData as makeSelectStopsDataQuote,
} from '../../new-do-quote/selectors';
import {
  setActiveLeftTab as setActiveLeftTabQuote,
  setReorderedStops as setReorderedStopsQuote,
  recalculateLoadDistancesRequest as recalculateLoadDistancesRequestQuote,
} from '../../new-do-quote/actions';
import {
  setActiveLeftTab as setActiveLeftTabLite,
  setReorderedStops as setReorderedStopsLite,
  recalculateLoadDistancesRequest as recalculateLoadDistancesRequestLite,
} from '../../lite-new-do/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// feature new-do
import SummaryStop from './summary-stop';
import { reorderStopsOnDrugEnd} from '../helpers';
import { makeSelectStopsData, makeSelectLoadType } from '../selectors';
import { setActiveLeftTab, setReorderedStops, recalculateLoadDistancesRequest } from '../actions';
//////////////////////////////////////////////////

const Stops = (props: Object) => {
  const { loadType, stopsData, setActiveLeftTab } = props;

  return (
    <Flex flexDirection='column'>
      {
        R.pathOr([], ['sortedStops'], stopsData).map((stop: Object, index: number) => {
          const { id, guid } = stop;

          const stopId = R.or(guid, id);

          return (
            <Draggable key={stopId} index={index} draggableId={stopId}>
              {({ innerRef, placeholder, draggableProps, dragHandleProps }: Object) => (
                <Box width='100%' onClick={() => setActiveLeftTab(R.prop(GC.FIELD_ORDER, stop))}>
                  <div ref={innerRef} {...draggableProps} {...dragHandleProps}>
                    <SummaryStop stop={stop} loadType={loadType} stopsData={stopsData} />
                  </div>
                  {placeholder}
                </Box>
              )}
            </Draggable>
          );
        })
      }
    </Flex>
  );
};

const StopsSummaryWithDnD = (props: Object) => {
  const {
    loadType,
    stopsData,
    handleDragEnd,
    handleSortByDate,
    setActiveLeftTab,
    recalculateLoadDistancesRequest,
  } = props;

  const commonStyles = {
    fontSize: 11,
    cursor: 'pointer',
    color: G.getTheme('colors.dark.blue'),
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Box p={15}>
        <Flex
          pb={10}
          justifyContent='center'
          color={G.getTheme('colors.greyMatterhorn')}
        >
          {/* // TODO: check title with Mark ;) */}
          Click to show stop form or Use drag&drop to move stop sequence
        </Flex>
        <Flex mb='5px' alignItems='flex-start'>
          <Box {...commonStyles} mr={10} onClick={recalculateLoadDistancesRequest}>
            {G.getWindowLocale('titles:calculate-distances', 'Calculate Distances')}
          </Box>
          <Box {...commonStyles} onClick={handleSortByDate}>
            {G.getWindowLocale('titles:sort-by-date', 'Sort By Date')}
          </Box>
        </Flex>
        {
          G.isNotNilAndNotEmpty(stopsData) &&
          <Droppable droppableId='summaryStops'>
            {({ innerRef, placeholder }: Object, snapshot: Object) => (
              <div ref={innerRef}>
                <Stops
                  snapshot={snapshot}
                  loadType={loadType}
                  stopsData={stopsData}
                  setActiveLeftTab={setActiveLeftTab}
                />
                {placeholder}
              </div>
            )}
          </Droppable>
        }
      </Box>
    </DragDropContext>
  );
};

const enhance = compose(
  withHandlers({
    handleDragEnd: ({
      setReorderedStops,
      stopsData: { sortedStops },
      loadTotals: { totalDistance },
      recalculateLoadDistancesRequest,
    }: Object) => (result: Object) => {
      const reorderedStops = reorderStopsOnDrugEnd(sortedStops, result);

      setReorderedStops(reorderedStops);
      recalculateLoadDistancesRequest({ reorderedStops, distance: totalDistance });
    },
    handleSortByDate: (props: Object) => () => {
      const { stops, setReorderedStops, loadTotals: { totalDistance }, recalculateLoadDistancesRequest } = props;

      const reorderedStops = R.compose(
        R.indexBy(R.prop(GC.FIELD_ORDER)),
        G.mapIndexed((item: Object, i: number) => R.assoc(GC.FIELD_ORDER, R.inc(i), item)),
        R.sort(({ formData: prevEventFormData }: Object, { formData }: Object) => (
          G.getDateRange(
            G.getStopEarlyDate(formData),
            G.getStopEarlyDate(prevEventFormData),
            GC.DEFAULT_DATE_TIME_FORMAT,
          )
        )),
        R.values,
      )(stops);

      setReorderedStops(reorderedStops);
      recalculateLoadDistancesRequest({ reorderedStops, distance: totalDistance });
    },
  }),
  pure,
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  loadType: makeSelectLoadType(state),
  stopsData: makeSelectStopsData(state),
});

const mapStateToPropsQuote = (state: Object) => createStructuredSelector({
  loadType: makeSelectLoadTypeQuote(state),
  stopsData: makeSelectStopsDataQuote(state),
});

const mapStateToPropsLite = (state: Object) => createStructuredSelector({
  loadType: makeSelectLoadTypeLite(state),
  stopsData: makeSelectStopsDataLite(state),
});

export const QuoteStopsSummaryWithDnD = connect(mapStateToPropsQuote, {
  setActiveLeftTab: setActiveLeftTabQuote,
  setReorderedStops: setReorderedStopsQuote,
  recalculateLoadDistancesRequest: recalculateLoadDistancesRequestQuote,
})(enhance(StopsSummaryWithDnD));

export const LiteStopsSummaryWithDnD = connect(mapStateToPropsLite, {
  setActiveLeftTab: setActiveLeftTabLite,
  setReorderedStops: setReorderedStopsLite,
  recalculateLoadDistancesRequest: recalculateLoadDistancesRequestLite,
})(enhance(StopsSummaryWithDnD));

export default connect(mapStateToProps, {
  setActiveLeftTab,
  setReorderedStops,
  recalculateLoadDistancesRequest,
})(enhance(StopsSummaryWithDnD));
