import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
import { ActionsElement } from '../../../components/actions-element';
// features
import Audit2 from '../../audit2';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// feature reference
import ReferenceTypeFormComponent from './reference-type';
import {
  deleteReferenceTypeRequest,
  changeReferenceTypeOwnerRequest,
  changeReferenceTypeGrantRequest,
} from '../actions';
//////////////////////////////////////////////////

export const enhance = compose(
  withHandlers({
    handleDelete: (props: Object) => () => {
      const { parent, openModal, closeFixedPopup, deleteReferenceTypeRequest } = props;

      closeFixedPopup();
      const confirmationContent = (
        <ConfirmComponent
          name={R.prop(GC.FIELD_NAME, parent)}
          textLocale={
            G.getWindowLocale('messages:delete-confirmation-text-double', 'Are you sure you want to delete')
          }
        />
      );
      const modal = {
        component: confirmationContent,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => deleteReferenceTypeRequest(G.getGuidFromObject(parent)),
            },
          ],
        },
      };
      openModal(modal);
    },
    handleEditReferenceType: (props: Object) => () => {
      const {
        parent,
        openModal,
        closeFixedPopup,
        resetListAndPagination,
        updateReferenceTypeRequest,
      } = props;

      closeFixedPopup();
      const submitAction = (data: Object) => {
        resetListAndPagination();
        updateReferenceTypeRequest(data);
      };
      const scopes = R.map(
        (scope: Object) => R.path([GC.FIELD_GUID], scope),
        R.pathOr([], [GC.FIELD_REFERENCE_SCOPES], parent),
      );
      const allowedValues = R.map(
        (value: Object) => ({ [GC.FIELD_VALUE]: value }),
        R.pathOr([], [GC.FIELD_REFERENCE_ALLOWED_VALUES], parent),
      );
      const filteredValues = R.filter(G.isNotNilAndNotEmpty, parent);
      const initialValues = R.mergeRight(filteredValues, { scopes, allowedValues });
      const referenceTypeProps = {
        submitAction,
        initialValues,
      };
      const component = <ReferenceTypeFormComponent {...referenceTypeProps} />;
      const modal = {
        p: 15,
        component,
        options: {
          width: 340,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '80vh',
          title: G.getWindowLocale('titles:reference-edit', 'Edit Reference Type'),
        },
      };
      openModal(modal);
    },
    handleShowAudit: (props: Object) => () => {
      const { parent, openModal, closeFixedPopup } = props;

      closeFixedPopup();

      const requestOptions = {
        [GC.FIELD_TYPE]: GC.AUDIT_TYPE_REFERENCE_TYPE,
        [GC.FIELD_OBJECT_GUID]: G.getGuidFromObject(parent),
      };

      const component = <Audit2 requestOptions={requestOptions} />;

      const modal = {
        p: '0',
        component,
        options: {
          boxShadow: 'none',
          withCloseIcon: true,
          withBorderRadius: true,
          backgroundColor: 'transparent',
          title: `${G.getWindowLocale('titles:audit', 'Audit')}: ${R.prop(GC.FIELD_NAME, parent)}`,
        },
      };
      openModal(modal);
    },
  }),
);

const getOptions = ({ handleDelete, handleShowAudit, handleEditReferenceType }: Object) => ([
  {
    action: handleEditReferenceType,
    permissions: [PC.REFERENCE_TYPE_WRITE],
    text: G.getWindowLocale('actions:edit', 'Edit'),
    frontIcon: I.pencil(G.getTheme('icons.iconColor')),
  },
  {
    action: handleDelete,
    permissions: [PC.REFERENCE_TYPE_WRITE],
    frontIcon: I.trash(G.getTheme('icons.iconColor')),
    text: G.getWindowLocale('actions:delete', 'Delete'),
  },
  {
    action: handleShowAudit,
    text: G.getWindowLocale('titles:audit', 'Audit'),
    frontIcon: I.renderAuditHistoryIcon(G.getTheme('icons.iconColor'), 15, 15),
  },
]);

const ElementActionsComponent = (props: Object) => (
  <ActionsElement options={getOptions(props)} />
);

export default connect(null, {
  deleteReferenceTypeRequest,
  changeReferenceTypeOwnerRequest,
  changeReferenceTypeGrantRequest,
})(enhance(ElementActionsComponent));
