import React from 'react';
import * as R from 'ramda';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
// feature profile
import AuditTab from './audit-tab';
import GeneralTab from './general-tab';
import { TabsGroupWrapper, tabsGroupWrapperEnhance } from './tabs-group-wrapper';
/////////////////////////////////////////////

const ActiveTab = (props: Object) => {
  const { activeTab, initialValues } = props;

  const tabsMap = [
    <GeneralTab key={0} {...props} />,
    <AuditTab key={1} guid={G.getGuidFromObject(initialValues)} />,
  ];

  return R.pathOr(null, [activeTab], tabsMap);
};

const tabs = [
  { text: G.getWindowLocale('titles:general', 'General') },
  { text: G.getWindowLocale('titles:audit', 'Audit') },
];

const TabsGroup = (props: Object) => (
  <TabsGroupWrapper
    tabs={tabs}
    activeTab={props.activeTab}
    permissions={[PC.USER_WRITE]}
    handleSetActiveTab={props.setActiveTab}
  >
    <ActiveTab {...props} />
  </TabsGroupWrapper>
);

export default tabsGroupWrapperEnhance(TabsGroup);
