import * as R from 'ramda';
import React from 'react';
import {
  compose,
  withState,
  withHandlers,
} from 'react-recompose';
// helpers/constants
import * as G from '../../../../helpers';
// icons
import * as I from '../../../../svgs';
// feature navbar-menu
import {
  HrDivider,
  SidebarItem,
  IconWrapper,
  SidebarGroupWrapper,
  SidebarItemRightPlusIcon,
  SidebarItemRightArrowIcon,
} from './ui';
//////////////////////////////////////////////////

const renderArrowIcon = (opened: boolean, iconColor: string) => (
  <SidebarItemRightArrowIcon>
    {
      G.ifElse(
        G.isTrue(opened),
        I.arrowUpSimple2,
        I.arrowDownSimple2,
      )(iconColor)
    }
  </SidebarItemRightArrowIcon>
);

const renderPlusIcon = (handleClickPlusIcon: Object, item: Object, iconColor: string) => (
  <SidebarItemRightPlusIcon
    color={iconColor}
    onClick={(event: Event) => {
      G.stopPropagation(event);
      handleClickPlusIcon(item);
    }}
  >
    { I.plusInCircleFilled(iconColor, 14, 14) }
  </SidebarItemRightPlusIcon>
);

const renderSubMenu = (submenu: Object, subMenuProps: Object) => submenu.map((item: Object, index: number) => (
  <SidebarItemComponent {...subMenuProps} key={index} item={item} />
));

const enhance = compose(
  withState('opened', 'toggleItem', false),
  withState('isHovered', 'setHoverStatus', false),
  withHandlers({
    handleToggleItem: ({
      item,
      opened,
      toggleItem,
      subMenusStatus,
      toggleSubmenus,
    }: Object) => () => {
      const {
        key,
        ownerItemGuid,
      } = item;

      if (R.isNil(ownerItemGuid)) {
        const newStatus = R.mapObjIndexed(
          (value: any, oKey: string) => R.and(R.equals(oKey, key), R.not(value)),
          subMenusStatus,
        );

        return toggleSubmenus(newStatus);
      }

      toggleItem(R.not(opened));
    },
    onHidePopup: ({ setShowPopup }: Object) => (e: Event) => {
      G.stopPropagation(e);
      setShowPopup(false);
    },
  }),
);

const SidebarItemComponent = enhance((props: Object) => {
  const {
    item,
    opened,
    styling,
    navItems,
    isHovered,
    iconsColor,
    currentItem,
    authorities,
    setHoverStatus,
    subMenusStatus,
    toggleSubmenus,
    pinnedMenuItems,
    handleToggleItem,
    handleClickNavItem,
    handleClickPlusIcon,
  } = props;

  const {
    key,
    action,
    title,
    submenu,
    iconName,
    permissions,
    andPermissions,
  } = item;

  const hasNotPermissions = R.or(
    G.hasNotAmousCurrentUserPermissions(permissions),
    R.and(G.isNotNilAndNotEmpty(andPermissions), G.hasNotAmousCurrentUserPermissions(andPermissions)),
  );

  if (hasNotPermissions) return null;

  const subMenuProps = {
    styling,
    navItems,
    iconsColor,
    currentItem,
    authorities,
    subMenusStatus,
    toggleSubmenus,
    pinnedMenuItems,
    handleClickNavItem,
    handleClickPlusIcon,
  };

  if (G.isNotEmpty(submenu)) {
    if (R.not(
      R.any(
        R.propSatisfies(G.hasAmousCurrentUserPermissions, 'permissions'),
      )(submenu))
    ) return null;

    return (
      <SidebarGroupWrapper opened={R.or(subMenusStatus[key], opened)}>
        <SidebarItem
          color={iconsColor}
          onClick={handleToggleItem}
          opened={R.or(subMenusStatus[key], opened)}
        >
          <IconWrapper>
            {
              R.pathOr(
                () => {},
                [iconName],
                I,
              )(iconsColor, null, null, styling.backgroundColor)}
          </IconWrapper>
          {title}
          {renderArrowIcon(R.or(subMenusStatus[key], opened), iconsColor)}
        </SidebarItem>
        {renderSubMenu(submenu, subMenuProps)}
        <HrDivider m='8px' borderColor={iconsColor} />
      </SidebarGroupWrapper>
    );
  }

  return (
    <SidebarItem
      color={iconsColor}
      active={R.equals(currentItem, action)}
      onMouseEnter={() => setHoverStatus(true)}
      onMouseLeave={() => setHoverStatus(false)}
      onClick={() => handleClickNavItem(action)}
      isSubmenuItem={R.path(['isSubmenuItem'], item)}
    >
      <IconWrapper>
        {
          R.pathOr(
            () => {},
            [iconName],
            I,
          )(iconsColor, null, null, styling.backgroundColor)}
      </IconWrapper>
      {title}
      {
        G.renderIfAll(
          renderPlusIcon(handleClickPlusIcon, item, iconsColor),
          isHovered,
          G.notInList(pinnedMenuItems, key),
          R.not(R.equals(key, 'menu:dispatch-board-beta')),
        )
      }
    </SidebarItem>
  );
});

export default SidebarItemComponent;
