import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const store = (state: Object) => state.pivotTable;

const makeSelectAvailableReports = () => createSelector(
  store,
  ({ availableReports }: Object) => availableReports,
);

const makeSelectUsedReport = () => createSelector(
  store,
  ({ usedReport }: Object) => usedReport,
);

const makeSelectPivotTableData = () => createSelector(
  store,
  ({ pivotTableData }: Object) => pivotTableData,
);

const makeSelectPivotTableReportType = () => createSelector(
  store,
  ({ pivotReportType }: Object) => pivotReportType,
);

const makeSelectPivotTableTotalCount = () => createSelector(
  store,
  ({ totalCount }: Object) => totalCount,
);

export {
  makeSelectUsedReport,
  makeSelectPivotTableData,
  makeSelectAvailableReports,
  makeSelectPivotTableReportType,
  makeSelectPivotTableTotalCount,
};
