// feature payroll
import { ChargeItemTotal } from '../components/payroll-charges-ui';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const accessorialFieldsToOmit = [
  GC.FIELD_GUID,
  GC.FIELD_VERSION,
  GC.FIELD_CREATED_BY,
  GC.FIELD_CREATED_DATE,
  GC.FIELD_BRANCH_GUID,
  GC.FIELD_LAST_MODIFIED_BY,
  GC.FIELD_ACCESSORIAL_COPY,
  GC.FIELD_LAST_MODIFIED_DATE,
  GC.FIELD_ACCESSORIAL_SERVICE,
  GC.FIELD_ACCESSORIAL_PARENT_GUID,
  GC.FIELD_ACCESSORIAL_FUEL_RELATED,
  GC.FIELD_ACCESSORIAL_TEL_INVOICE_GL,
  GC.FIELD_ACCESSORIAL_CLO_INVOICE_GL,
  GC.FIELD_ACCESSORIAL_ORIGINAL_CONFIG_GUID,
];

const inputWrapperStyles = {
  mr: 15,
  flexGrow: 0,
  flexBasis: 100,
};

const inputWrapperStyles2 = {
  mr: 15,
  flexGrow: 0,
  flexBasis: 250,
};

const inputWrapperStyles3 = {
  mr: 15,
  flexGrow: 0,
  flexBasis: 120,
};

const deductionFromVendorField = {
  type: 'toggle',
  shouldCustomChange: true,
  inputWrapperStyles: { mr: 15 },
  fieldName: GC.FIELD_DEDUCTION_FROM_VENDOR,
  customChangeHandler: 'handleChangeDeduction',
  label: ['titles:deduction-from-vendor', 'Deduction From Vendor'],
};

const initialChargeFieldsetFields = [
  {
    type: 'toggle',
    shouldCustomChange: true,
    inputWrapperStyles: { mr: 15 },
    fieldName: GC.FIELD_CHARGE_DEDUCTION,
    label: ['titles:deduction', 'Deduction'],
    customChangeHandler: 'handleChangeDeduction',
  },
  {
    isRequired: true,
    type: 'reactSelect',
    closeMenuOnScroll: true,
    shouldCustomChange: true,
    useMenuPortalTarget: true,
    options: 'accessorialsOptions',
    label: ['titles:charge', 'Charge'],
    fieldName: GC.FIELD_CHARGE_RATE_NAME,
    inputWrapperStyles: inputWrapperStyles2,
    customChangeHandler: 'handleChangeChargeAccessorial',
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    shouldCustomChange: true,
    label: ['titles:rate', 'Rate'],
    fieldName: GC.FIELD_CHARGE_RATE,
    customChangeHandler: 'handleChangeCharge',
  },
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    shouldCustomChange: true,
    options: 'rateTypeOptions',
    label: ['titles:type', 'Type'],
    fieldName: GC.FIELD_CHARGE_RATE_TYPE,
    customChangeHandler: 'handleChangeCharge',
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    shouldCustomChange: true,
    label: ['titles:qty', 'Qty'],
    fieldName: GC.FIELD_CHARGE_QUANTITY,
    customChangeHandler: 'handleChangeCharge',
  },
  {
    type: 'select',
    shouldCustomChange: true,
    options: 'rateUnitOptions',
    label: ['titles:unit', 'Unit'],
    fieldName: GC.FIELD_CHARGE_RATE_UNIT,
    inputWrapperStyles: inputWrapperStyles3,
    customChangeHandler: 'handleChangeCharge',
  },
  {
    type: 'select',
    inputWrapperStyles,
    shouldCustomChange: true,
    options: 'currencyOptions',
    fieldName: GC.FIELD_CHARGE_CURRENCY,
    label: ['titles:currency', 'Currency'],
    customChangeHandler: 'handleChangeCharge',
  },
  {
    type: 'select',
    inputWrapperStyles,
    options: 'glCodeOptions',
    shouldCustomChange: true,
    label: ['titles:gl', 'GL'],
    fieldName: GC.FIELD_GL_CODE,
    customChangeHandler: 'handleChangeCharge',
    disabled: (_: any, __: any, { glDisabled }: Object) => G.isTrue(glDisabled),
  },
  {
    inputWrapperStyles,
    type: 'customComponent',
    Component: ChargeItemTotal,
  },
];

export {
  accessorialFieldsToOmit,
  deductionFromVendorField,
  initialChargeFieldsetFields,
};
