import * as R from 'ramda';
import * as P from 'plow-js';
import React, { Component } from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
//////////////////////////////////////////////////

export class PayrollCharges extends Component {
  constructor(props: Object) {
    super(props);
    this.state = {
      expenses: [],
      driverPayrolls: [],
      payrollInvoices: [],
    };
    this.getExpenses = this.getExpenses.bind(this);
    this.addChargesToList = this.addChargesToList.bind(this);
    this.getDriverPayrolls = this.getDriverPayrolls.bind(this);
    this.getPayrollInvoices = this.getPayrollInvoices.bind(this);
    this.selectChargeListItem = this.selectChargeListItem.bind(this);
    this.removeOrRestoreChargeListItem = this.removeOrRestoreChargeListItem.bind(this);
  }

  componentDidMount() {
    this.getExpenses(this.props);
    this.getDriverPayrolls(this.props);
    this.getPayrollInvoices(this.props);
  }

  async getExpenses(props: Object) {
    const { payrollGuid } = props;

    if (G.isNilOrEmpty(payrollGuid)) return;

    const params = { [GC.FIELD_VENDOR_PAYROLL_GUID]: payrollGuid };

    const res = await sendRequest('get', endpointsMap.driverExpenseList, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotEmpty(data)) {
        const expenses = R.map(
          (charge: Object) => R.mergeRight(charge, {
            removed: false,
            selected: R.not(R.prop(GC.FIELD_EXCLUDED, charge)),
          }),
          data,
        );

        const newState = P.$set('expenses', expenses, this.state);

        this.setState(newState);
      }
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncVendorPayrollCharges -> getExpenses',
      );
    }
  }

  async getPayrollInvoices(props: Object) {
    const { payrollGuid } = props;

    if (G.isNilOrEmpty(payrollGuid)) return;

    const params = { [GC.FIELD_PAYROLL_GUID]: payrollGuid };

    const res = await sendRequest('get', endpointsMap.vendorPayrollInvoices, { params });

    const { data, status } = res;

    if (R.and(G.isResponseSuccess(status), G.isArray(data))) {
      const newState = P.$set('payrollInvoices', data, this.state);

      this.setState(newState);
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncPayrollCharges -> getPayrollInvoices',
      );
    }
  }

  async getDriverPayrolls(props: Object) {
    const { payrollGuid } = props;

    if (G.isNilOrEmpty(payrollGuid)) return;

    const params = { vendorPayrollGuid: payrollGuid };

    const res = await sendRequest('get', endpointsMap.driverPayrollList, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotEmpty(data)) {
        const driverPayrolls = R.map(
          (charge: Object) => R.mergeRight(charge, {
            removed: false,
            selected: R.not(R.prop(GC.FIELD_EXCLUDED, charge)),
          }),
          data,
        );

        const newState = P.$set('driverPayrolls', driverPayrolls, this.state);

        this.setState(newState);
      }
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncPayrollCharges -> getDriverPayrolls',
      );
    }
  }

  selectChargeListItem(guid: string, listName: string) {
    const newList = G.selectItem(R.prop(listName, this.state), guid);
    const newState = P.$set(listName, newList, this.state);

    this.setState(newState);
  }

  removeOrRestoreChargeListItem(guid: string, listName: string) {
    const newList = R.map((item: Object) => {
      if (R.propEq(guid, GC.FIELD_GUID, item)) {
        return R.assoc('removed', R.not(item.removed), item);
      }

      return item;
    }, this.state[listName]);

    const newState = P.$set(listName, newList, this.state);

    this.setState(newState);
  }

  addChargesToList(charges: Array, listName: string) {
    const newList = R.compose(
        R.concat(this.state[listName]),
        R.map(R.mergeRight({ selected: true, expanded: false })),
      )(charges);

    const newState = P.$set(listName, newList, this.state);

    this.setState(newState);
  }

  render() {
    return (
      <div>
        {this.props.render(
          this.state,
          this.getExpenses,
          this.addChargesToList,
          this.getDriverPayrolls,
          this.getPayrollInvoices,
          this.selectChargeListItem,
          this.removeOrRestoreChargeListItem,
        )}
      </div>
    );
  }
}

export const withAsyncPayrollCharges = (Component: any) => {
  return class extends React.Component {
    render() {
      return (
        <PayrollCharges
          payrollGuid={this.props.payrollGuid}
          render={(
            parentState: Object,
            getExpenses: Function,
            addChargesToList: Function,
            getDriverPayrolls: Function,
            getPayrollInvoices: Function,
            selectChargeListItem: Function,
            removeOrRestoreChargeListItem: Function,
          ) => (
            <Component
              {...this.props}
              getExpenses={getExpenses}
              expenses={parentState.expenses}
              addChargesToList={addChargesToList}
              getDriverPayrolls={getDriverPayrolls}
              getPayrollInvoices={getPayrollInvoices}
              driverPayrolls={parentState.driverPayrolls}
              selectChargeListItem={selectChargeListItem}
              payrollInvoices={parentState.payrollInvoices}
              removeOrRestoreChargeListItem={removeOrRestoreChargeListItem}
            />
          )}
        />
      );
    }
  };
};
