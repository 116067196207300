import * as R from 'ramda';
import React from 'react';
// components
import { Table } from '../../../../components/table';
import { TextComponent } from '../../../../components/text';
import { InputWithHandlers } from '../../../../components/input-with-handlers';
// features
import { getColumnSettingsByReportType } from '../../../fleet-list/settings';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withFilterTableList } from '../../../../hocs';
// ui
import { Box, Flex } from '../../../../ui';
// feature payroll
import { vendorsListReport, tableSettings } from '../../settings/drivers-report-settings';
//////////////////////////////////////////////////

const TableComponent = (props: Object) => {
  const {
    loading,
    totalCount,
    selectDriverItem,
    filteredTableList,
    getItemListRequest } = props;
  const allChecked = G.isAllChecked(filteredTableList);
  const pagination = {
    limit: 10,
    offset: 0,
  };
  const data = {
    allChecked,
    totalCount,
    pagination,
    tableSettings,
    hasSelectable: true,
    report: vendorsListReport,
    itemList: filteredTableList,
    onOptionClick: selectDriverItem,
    handleLoadMoreEntities: getItemListRequest,
    loading: R.and(loading, G.isNilOrEmpty(filteredTableList)),
    columnSettings: getColumnSettingsByReportType(GC.FLEET_VENDOR_REPORT),
  };
  return <Table {...data} />;
};

export const ReportVendorsList = withFilterTableList(
  'searchString',
  'asyncReportListState',
  ['asyncReportListState', 'data'],
  {
    joiner: '',
    withoutValues: [null, undefined, ''],
    omitFields: [GC.FIELD_GUID, 'selected'],
  },
)((props: Object) => (
  <div>
    <Flex alignItems='center' justifyContent='space-between'>
      <TextComponent p={15} display='block'>
        {G.getWindowLocale('titles:select-vendors-for-payroll', 'Please, select vendors for payroll')}
      </TextComponent>
      <Box pr={15}>
        <TextComponent pr={15} display='inline'>
          {`${G.getWindowLocale('titles:filter', 'Filter')}:`}
        </TextComponent>
        <InputWithHandlers
          styles={{
            width: 120,
            p: '0 8px',
            border: '1px solid',
          }}
          value={props.filterString}
          onChange={(value: string) => props.handleChangeFilterInput(value)} />
      </Box>
    </Flex>
    <TableComponent {...props} />
  </div>
));
