import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose } from 'react-recompose';
// components
import { ActionsElement } from '../../../components/actions-element';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// utilities
import routesMap from '../../../utilities/routes';
// feature public-clo
import { deleteListItemRequest } from '../actions';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.light.blue');

const enhance = compose(
  connect(
    null,
    { deleteListItemRequest },
  ),
  pure,
);

const getOptions = (props: Object) => {
  const { entity, closeFixedPopup, deleteListItemRequest } = props;

  const guid = G.getGuidFromObject(entity);
  const options = [
    {
      frontIcon: I.routesLoads(iconColor),
      permissions: [PC.PUBLIC_API_ORDER_WRITE],
      text: G.getWindowLocale('actions:create-clo', 'Create CLO'),
      action: () => {
        const options = {
          guid,
          [GC.FIELD_SOURCE_TYPE]: GC.CREATE_DO_SOURCE_TYPE_API,
        };
        G.goToRoute(routesMap.newDOBySourceType(options));
      },
    },
    {
      frontIcon: I.trash(iconColor),
      permissions: [PC.PUBLIC_API_ORDER_WRITE],
      text: G.getWindowLocale('actions:delete', 'Delete'),
      action: () => {
        closeFixedPopup();
        deleteListItemRequest(R.of(Array, guid));
      },
    },
  ];

  if (R.propEq('CREATED', 'status', entity)) return R.takeLast(1, options);

  return options;
};

export const RowActions = enhance((props: Object) => (
  <ActionsElement
    entity={props.entity}
    options={getOptions(props)} />
));
