import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
// components
import { openLoader, closeLoader } from '../../../components/loader/actions';
// helpers/constants
import * as G from '../../../helpers';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature payroll
import { getPayrollStatisticListAndCurrency } from '../helpers';
//////////////////////////////////////////////////

const useStatistic = (payload: Object) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [statistic, setStatistic] = useState([]);
  const [statisticCurrency, setStatisticCurrency] = useState();

  const { payrollGuid } = payload;

  const fetchItemList = async () => {
    try {
      dispatch(openLoader());

      setLoading(true);

      const res = await sendRequest('get', endpointsMap.getVendorPayrollStatisticEndpoint(payrollGuid));

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const { statisticList, statisticCurrency } = getPayrollStatisticListAndCurrency(data);

        setStatistic(statisticList);
        setStatisticCurrency(statisticCurrency);
      } else {
        await G.handleFailResponseSimple(res, 'useStatistic/fetchItemList fail');
      }

      dispatch(closeLoader());

      setLoading(false);
    } catch (error) {
      dispatch(closeLoader());

      setLoading(false);

      await G.showToastrMessageSimple('error', 'messages:error:unknown');
      await G.handleException(error, 'useStatistic/fetchItemList exception');
    }
  };

  useEffect(() => {
    fetchItemList();
  }, []);

  return {
    statistic,
    statisticCurrency,
    statisticLoading: loading,
  };
};

export default useStatistic;
