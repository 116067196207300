import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setReports = createAction('setReports');
export const setUsedReport = createAction('setUsedReport');
export const startPivotTable = createAction('startPivotTable');
export const setInitialState = createAction('setInitialState');
export const setPivotReportType = createAction('setPivotReportType');
export const createReportRequest = createAction('createReportRequest');
export const updateReportRequest = createAction('updateReportRequest');
export const getPivotTableDataRequest = createAction('getPivotTableDataRequest');
export const getPivotTableDataSuccess = createAction('getPivotTableDataSuccess');
export const changeDefaultReportRequest = createAction('changeDefaultReportRequest');
