import * as R from 'ramda';
import { put, all, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
// common
import { toggleSubmitLoading } from '../../common/actions';
// components
import { closeModal } from '../../components/modal/actions';
import { closeLoader, openLoader } from '../../components/loader/actions';
// features
import PC from '../permission/role-permission';
import { makeSelectScopeByName } from '../reference/selectors';
import { getBranchConfigsByNamesRequest } from '../branch/actions';
import { downloadEdiFileRequest } from '../edi-integration/actions';
import { getServiceMappingListRequest } from '../configurations/actions';
import { setExpandedContainerOptions } from '../expanded-container/actions';
import { getAllAvailableRefTypesByScopeRequest } from '../reference/actions';
import { getOrderDetailsRequest } from '../dispatch-details-new/order/actions';
import { makeSelectExpandedContainerOptions } from '../expanded-container/selectors';
import { getOrderDetailsRequest as getDBOrderDetailsRequest } from '../dispatch-board-new/order/actions';
import {
  makeSelectCurrentBranch,
  makeSelectMasterSettings,
  makeSelectCurrentBranchGuid,
} from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import routesMap from '../../utilities/routes';
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// sagas
import { crudSaga, visitPageSaga } from '../../sagas';
// feature new-do
import * as A from './actions';
import { PAGE_TYPE_CREATE_DO, PAGE_TYPE_CLO_TEMPLATE } from './constants';
import {
  makeSelectPageType,
  makeSelectLoadType,
  makeSelectDivision,
  makeSelectBranchInfo,
  makeSelectFullDOStore,
  makeSelectAiRecognize,
} from './selectors';
import {
  makeReqStops,
  makeReqBillTo,
  getDataFromStops,
  isPageCloTemplate,
  makeReqReferences,
  isPageCreateFromEdi,
  getLoadStopsGeodata,
  mapStopsWithDistances,
  isPageCreateFromQuote,
  makeReqStopsForTemplate,
  isPageCreateFromPublicClo,
  makeLoadStopsEditReqStops,
  isPageCreateFromOrderQuote,
  makeReqEquipmentAndServices,
  getDivisionGuidByBranchConfigs,
  prependCurrentBranchToBranchList,
  isPageCreateDO,
} from './helpers';
//////////////////////////////////////////////////

const loadTypeToScopeMap = {
  [GC.LOAD_TYPE_CLO]: GC.REF_SCOPE_NAME_CLO,
  [GC.LOAD_TYPE_TEL]: GC.REF_SCOPE_NAME_TEL,
};

export function* getBranchSharedAccessorialListSaga({ payload }: Object) {
  try {
    const branchGuid = payload;

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.sharedAccessorialList,
      parentSagaName: 'getBranchSharedAccessorialListSaga',
      successAction: A.getBranchSharedAccessorialListSuccess,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getBranchSharedAccessorialListSaga exception');
  }
}

function* handleGetBranchStylingSaga({ payload }: Object) {
  try {
    const endpoint = endpointsMap.getStylingForBranchEndpoint(payload);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getBranchStylingSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetBranchStylingSaga fail', false);
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetBranchStylingSaga exception');
  }
}

function* getBranchListSaga() {
  try {
    const branch = yield select(makeSelectCurrentBranch());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const endpoint = endpointsMap.getBranchAllChildrenWithShared(branchGuid);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isAllTrue(
        R.equals(R.length(data), 1),
        R.eqProps(GC.FIELD_GUID, R.head(data), branch),
      )) {
        const guid = R.prop(GC.FIELD_GUID, branch);

        yield put(A.getBranchListSuccess(data));

        yield put(A.cleanNewDOStore({
          [GC.FIELD_GUID]: guid,
          [GC.FIELD_BRANCH_NAME]: R.prop(GC.FIELD_BRANCH_NAME, branch),
        }));

        yield put(A.getBranchDataRequest(guid));
      } else {
        const actionData = prependCurrentBranchToBranchList(data, branch);

        yield put(A.getBranchListSuccess(actionData));
      }
    } else {
      yield call(G.handleFailResponse, res, 'getBranchListSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getBranchListSaga exception');
  }
}

function* handleGetAllAvBranchRefTypesSaga({ payload }: Object) {
  try {
    const { scopeName, branchGuid } = payload;

    const scope = yield select(makeSelectScopeByName, scopeName);

    const scopeGuid = G.getOrElse(scope, GC.FIELD_GUID, '');

    const options = { params: { scopeGuid, [GC.FIELD_BRANCH_GUID]: branchGuid } };

    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.availableReferenceTypesForScope,
      options,
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getAllAvBranchRefTypesSuccess({ data, scopeName }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetAllAvBranchRefTypesSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetAllAvBranchRefTypesSaga exception');
  }
}

function* handleGetPrimaryRefSequenceSaga({ payload }: Object) {
  try {
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.getSequenceEndpoint(payload),
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getPrimaryRefSequenceSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetPrimaryRefSequenceSaga fail', false);
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetPrimaryRefSequenceSaga exception');
  }
}

function* getBranchBillToSaga(data: Object) {
  try {
    const { configs, branchGuid } = data;

    const billToTemplateId = G.getConfigValueFromStore(GC.CLO_GENERAL_BILL_TO, configs);
    const useEnterpriseBillTo = G.getConfigValueFromStore(GC.CLO_GENERAL_USE_ENTERPRISE_BILL_TO, configs);

    if (R.and(G.isFalse(useEnterpriseBillTo), G.isNilOrEmpty(billToTemplateId))) {
      return;
    }

    const endpoint = G.ifElse(
      G.isTrue(useEnterpriseBillTo),
      endpointsMap.branchOrParentBillToEndpoint,
      endpointsMap.location,
    );

    const params = G.ifElse(
      G.isTrue(useEnterpriseBillTo),
      { [GC.FIELD_BRANCH_GUID]: branchGuid },
      {
        templateId: billToTemplateId,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    );

    const options = { params };

    yield call(crudSaga, {
      options,
      endpoint,
      method: 'get',
      showFailMsg: false,
      mapper: G.mapSearchedLocation,
      successAction: A.setBranchBillTo,
      parentSagaName: 'getBranchBillToSaga',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getBranchBillToSaga exception');
  }
}

function* getBranchShipFromSaga(data: Object) {
  try {
    const { configs, branchGuid } = data;

    const shipFromTemplateId = G.getConfigValueFromStore(GC.CLO_GENERAL_SHIP_FROM, configs);

    if (G.isNilOrEmpty(shipFromTemplateId)) return;

    const options = {
      params: {
        templateId: shipFromTemplateId,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      showFailMsg: false,
      endpoint: endpointsMap.location,
      successAction: A.setBranchShipFrom,
      parentSagaName: 'getBranchShipFromSaga',
      mapper: G.mapSearchedLocationWithContactsArr,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getBranchShipFromSaga exception');
  }
}

function* getBranchShipToSaga(data: Object) {
  try {
    const { configs, branchGuid } = data;

    const shipFromTemplateId = G.getConfigValueFromStore(GC.CLO_GENERAL_SHIP_TO, configs);

    if (G.isNilOrEmpty(shipFromTemplateId)) return;

    const options = {
      params: {
        templateId: shipFromTemplateId,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      showFailMsg: false,
      endpoint: endpointsMap.location,
      successAction: A.setBranchShipTo,
      parentSagaName: 'getBranchShipToSaga',
      mapper: G.mapSearchedLocationWithContactsArr,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getBranchShipToSaga exception');
  }
}

function* getBranchDefaultItemSaga(data: Object) {
  try {
    const { configs, branchGuid } = data;

    const itemId = G.getConfigValueFromStore(GC.CLO_GENERAL_DEFAULT_ITEM, configs);

    if (G.isNilOrEmpty(itemId)) return;

    const options = {
      params: {
        itemId,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      showFailMsg: false,
      mapper: G.mapSearchedItem,
      endpoint: endpointsMap.item,
      successAction: A.setBranchDefaultItem,
      parentSagaName: 'getBranchDefaultItemSaga',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getBranchDefaultItemSaga exception');
  }
}

const CONFIGS_ARR = [
  // ui
  GC.UI_SHOW_FAST_LOAD_INDICATOR,
  // templates
  GC.TEMPLATES_LOCATION_TYPE,
  // tailer
  GC.TRAILER_MANAGE_TRAILERS_FROM,
  // general
  GC.GENERAL_SERVICES,
  GC.GENERAL_EQUIPMENTS,
  GC.GENERAL_MODE_TRANSPORTATION,
  GC.GENERAL_BRANCH_DEFAULT_CURRENCY,
  GC.GENERAL_BRANCH_DEFAULT_CURRENCY,
  GC.GENERAL_ROUTE_NAME_AUTOGENERATED,
  GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
  GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM,
  // clo
  GC.CLO_ORDER_TYPE_DROPDOWN,
  GC.CLO_GENERAL_PAYMENT_TERMS,
  GC.CLO_GENERAL_USE_CONTAINERS,
  GC.CLO_GENERAL_USE_ORDER_TYPE,
  GC.CLO_UI_MULTI_CREATE_FIELDS,
  GC.CLO_GENERAL_TEL_CREATION_MODE,
  GC.CLO_GENERAL_DEFAULT_EQUIPMENT,
  GC.CLO_GENERAL_RATE_SERVICE_TYPE,
  GC.CLO_GENERAL_DEFAULT_ORDER_TYPE,
  GC.CLO_GENERAL_ORDER_TYPE_REQUIRED,
  GC.CLO_GENERAL_CUSTOMER_REFERENCE_TYPE,
  GC.CLO_GENERAL_RATE_TRANSPORTATION_MODE,
  GC.CLO_GENERAL_AUTO_CREATE_CONTAINER_ITEM,
  GC.CLO_QUOTE_USE_QUOTE_NUMBER_AS_PRIMARY_REFERENCE,
  // clo default
  GC.CLO_DEFAULT_DROP_LOCATION_TYPE,
  GC.CLO_DEFAULT_SPECIAL_INSTRUCTION,
  GC.CLO_DEFAULT_PICKUP_LOCATION_TYPE,
  // TODO: check use this config
  GC.CLO_GENERAL_TIME_INTERVAL,
  GC.CLO_GENERAL_DROP_INTERVAL,
  GC.CLO_GENERAL_EVENTS_INTERVAL,
  GC.CLO_GENERAL_PICKUP_INTERVAL,
  GC.CLO_GENERAL_DROP_EARLY_TIME,
  GC.CLO_GENERAL_PICKUP_EARLY_TIME,
  GC.CLO_GENERAL_SHIP_TO,
  GC.CLO_GENERAL_BILL_TO,
  GC.CLO_GENERAL_SHIP_FROM,
  GC.CLO_ITEM_REQUIRED_FIELDS,
  GC.CLO_GENERAL_DEFAULT_ITEM,
  GC.CLO_PRIMARY_REFERENCE_TYPE,
  GC.CLO_PRIMARY_REFERENCE_PREFIX,
  GC.CLO_UI_ADDITIONAL_ITEM_FIELDS,
  GC.CLO_UI_SINGLE_APPOINTMENT_TIME,
  GC.CLO_PRIMARY_REFERENCE_SEQUENCE,
  GC.CLO_GENERAL_DEFAULT_REFERENCES,
  GC.CLO_GENERAL_USE_ENTERPRISE_BILL_TO,
  GC.CLO_UI_SEPARATE_EVENT_DATE_AND_TIME,
  GC.CLO_PRIMARY_REFERENCE_AUTOGENERATED,
  GC.CLO_PRIMARY_REFERENCE_DIVISION_PREFIX,
  GC.CLO_GENERAL_CONFIRMATION_DOCUMENT_TYPE,
  // clo item
  GC.CLO_ITEM_MAX_WIDTH,
  GC.CLO_ITEM_MAX_LENGTH,
  GC.CLO_ITEM_MAX_HEIGHT,
  GC.CLO_ITEM_DEFAULT_TYPE,
  GC.CLO_ITEM_DIMENSION_UOM,
  GC.CLO_ITEM_DEFAULT_COUNTRY,
  GC.CLO_ITEM_DEFAULT_WEIGHT_UOM,
  GC.CLO_ITEM_USE_DIFFERENT_TYPES,
  GC.CLO_ITEM_DEFAULT_PACKAGE_TYPE,
  GC.CLO_ITEM_DEFAULT_TEMPERATURE_UOM,
  // tel
  GC.TEL_GENERAL_DEFAULT_BRANCH,
  GC.TEL_PRIMARY_REFERENCE_AUTOGENERATED,
  GC.TEL_PRIMARY_REFERENCE_COPY_FROM_CLO,
  // rate
  GC.RATE_ENGINE_USE_TRIP_MAX_PAY,
];

function* handleGetBranchConfigsSaga({ payload }: Object) {
  try {
    const branchGuid = payload;

    const options = {
      params: {
        names: R.join(',', CONFIGS_ARR),
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const mappedConfigs = G.mapConfigValuesByName(data);

      yield call(G.setAmousConfigToWindow, mappedConfigs);

      yield call(handleGetAllAvBranchRefTypesSaga, { payload: { branchGuid, scopeName: GC.REF_SCOPE_NAME_CLO } });

      yield put(A.getTransportationModeGroupingListRequest(branchGuid));
      yield put(A.getBranchConfigsSuccess({ branchGuid, branchConfigs: mappedConfigs }));

      yield call(getBranchBillToSaga, { branchGuid, configs: mappedConfigs });
      yield call(getBranchShipFromSaga, { branchGuid, configs: mappedConfigs });
      yield call(getBranchShipToSaga, { branchGuid, configs: mappedConfigs });
      yield call(getBranchDefaultItemSaga, { branchGuid, configs: mappedConfigs });

      const branchInfo = yield select(makeSelectBranchInfo());

      const divisionGuid = getDivisionGuidByBranchConfigs(mappedConfigs, branchInfo);

      if (G.isNotNilAndNotEmpty(divisionGuid)) {
        yield put(A.setDivisionGuid(divisionGuid));
        yield put(getBranchConfigsByNamesRequest({
          branchGuid: divisionGuid,
          names: GC.CLO_PRIMARY_REFERENCE_DIVISION_PREFIX,
        }));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetBranchConfigsSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetBranchConfigsSaga exception');
  }
}

function* handleGetBranchDataOnQuoteSaga({ payload }: Object) {
  try {
    const branchGuid = payload;
    const options = {
      params: {
        names: R.join(',', CONFIGS_ARR),
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const mappedConfigs = G.mapConfigValuesByName(data);
      yield put(A.getBranchConfigsOnQuoteSuccess({ branchGuid, branchConfigs: mappedConfigs }));
      yield put(A.getAllAvBranchRefTypesRequest({
        branchGuid,
        scopeName: GC.REF_SCOPE_NAME_CLO,
      }));
      yield call(getBranchBillToSaga, { branchGuid, configs: mappedConfigs });
      const branchInfo = yield select(makeSelectBranchInfo());
      const divisionGuid = getDivisionGuidByBranchConfigs(mappedConfigs, branchInfo);
      if (G.isNotNilAndNotEmpty(divisionGuid)) {
        yield put(A.setDivisionGuid(divisionGuid));
        yield put(getBranchConfigsByNamesRequest({
          branchGuid: divisionGuid,
          names: GC.CLO_PRIMARY_REFERENCE_DIVISION_PREFIX,
        }));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetBranchConfigsOnQuoteSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetBranchConfigsOnQuoteSaga exception');
  }
}

function* handleGetBranchConfigsWithEdiSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const branchGuid = R.path([GC.FIELD_CLO, GC.FIELD_BRANCH_GUID], payload);

    yield put(A.getBranchStylingRequest(branchGuid));
    yield put(A.getAllAvBranchRefTypesRequest({
      branchGuid,
      scopeName: GC.REF_SCOPE_NAME_CLO,
    }));

    const options = {
      params: {
        names: R.join(',', CONFIGS_ARR),
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const mappedConfigs = G.mapConfigValuesByName(data);

      yield put(A.getTransportationModeGroupingListRequest(branchGuid));

      yield put(A.getBranchConfigsWithEdiSuccess({
        branchGuid,
        ediData: payload,
        branchConfigs: mappedConfigs,
      }));

      const fileName = R.prop(GC.FIELD_EDI_FILE_NAME, payload);
      const fileUri = R.prop(GC.FIELD_EDI_FILE_URI, payload);
      const billTo = R.path(['clo', 'billTo'], payload);
      const primaryReferenceValue = R.path(['clo', 'primaryReferenceValue'], payload);

      if (G.isNilOrEmpty(billTo)) {
        yield call(getBranchBillToSaga, { branchGuid, configs: mappedConfigs });
      }

      if (G.isNilOrEmpty(primaryReferenceValue)) {
        const primaryReferenceSequenceGuid = G.getConfigValueFromStore(
          GC.CLO_PRIMARY_REFERENCE_SEQUENCE, mappedConfigs,
        );

        if (G.shouldGetPrimaryRefSequenceFromBranchConfigs(mappedConfigs)) {
          yield put(A.getPrimaryRefSequenceRequest(primaryReferenceSequenceGuid));
        }
      }

      yield put(downloadEdiFileRequest({
        fileUri,
        fileName,
        branchGuid,
        action: 'getFile',
      }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetBranchConfigsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetBranchConfigsRequest exception');
  }
}

function* getEdiTransactionRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'get', endpointsMap.getReceivedClo(payload));
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getBranchConfigsWithEdiRequest(data));
    } else {
      yield call(G.handleFailResponse, res, 'getEdiTransactionRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getEdiTransactionRequest exception');
  }
}

function* handleBranchConfigsWithLoadStopsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true}));
    const { branchGuid, refScopeName } = payload;
    const options = {
      params: {
        names: R.join(',', CONFIGS_ARR),
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const mappedConfigs = G.mapConfigValuesByName(data);
      yield put(A.getBranchConfigsWithLoadStopsSuccess({ ...payload, branchConfigs: mappedConfigs }));
      yield put(A.getAllAvBranchRefTypesRequest({
        branchGuid,
        scopeName: refScopeName,
      }));
    } else {
      yield call(G.handleFailResponse, res, 'handleBranchConfigsWithLoadStopsSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleBranchConfigsWithLoadStopsSaga exception');
  }
}

function* handleRecalculateLoadDistancesSaga({ payload }: Object) {
  try {
    const { distance, reorderedStops } = payload;

    const store = yield select(makeSelectFullDOStore());

    const { stops, loadType, branchInfo } = store;

    const branchGuid = R.prop(GC.FIELD_VALUE, branchInfo);

    const { sortedStops } = getDataFromStops(R.or(reorderedStops, stops));

    const stopPoints = getLoadStopsGeodata(sortedStops);

    if (G.isNotNilAndNotEmpty(stopPoints)) {
      const options = {
        data: {
          stopPoints,
          [GC.FIELD_BRANCH_GUID]: branchGuid,
        },
      };

      const res = yield call(sendRequest, 'post', endpointsMap.distanceCalculation, options);

      const { data, status } = res;

      if (R.isNil(R.prop(['stopResults'], data))) return yield put(closeLoader());

      if (G.isResponseSuccess(status)) {
        const mappedStops = mapStopsWithDistances(loadType, sortedStops, data);

        yield put(A.recalculateLoadDistancesSuccess({ distance, stopPoints, mappedStops }));
      }
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleRecalculateLoadDistancesSaga exception');
  }
}

function* createCloDocumentSaga(payload: Object) {
  try {
    const { file, cloGuid, documentType } = payload;

    if (G.isNilOrEmpty(documentType)) return;

    const dataToMake = {
      file,
      documentType,
      [GC.FIELD_PRIMARY_OBJECT_GUID]: cloGuid,
    };

    const reqData = G.makeDataForDocument(dataToMake);

    const options = { data: reqData };

    const res = yield call(sendRequest, 'post', endpointsMap.cloDocument, options);

    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      // TODO: with handling
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCloDocumentRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleCreateCloDocumentRequest exception');
  }
}

export const getRequestOptions = (reqData: Object, store: Object) => {
  const { ediData, quoteData, pageType, orderQuoteData } = store;

  const senderId = R.path(['senderId'], ediData);
  const externalId = R.path(['externalId'], ediData);
  const quoteRequestGuid = R.path([GC.FIELD_GUID], quoteData);
  const senderQualifier = R.path([GC.FIELD_EDI_SENDER_QUALIFIER], ediData);
  const quoteRequestNumber = R.path([GC.FIELD_QUOTES_REQUEST_NUMBER], quoteData);
  const loaderTransactionGuid = R.path([GC.FIELD_GUID], ediData);
  const loaderConfigGuid = R.path(['loaderConfigGuid'], ediData);
  let data = reqData;

  if (isPageCreateFromEdi(pageType)) {
    data = { ...reqData, senderId, externalId, senderQualifier, loaderConfigGuid, loaderTransactionGuid };
  }

  if (isPageCreateFromQuote(pageType)) {
    data = { ...reqData, quoteRequestGuid, quoteRequestNumber };
  }

  if (isPageCreateFromPublicClo(pageType)) {
    data = R.assoc(GC.FIELD_EXTERNAL_ID, R.path(['publicCloData', GC.FIELD_EXTERNAL_ID], store), data);
  }

  if (isPageCreateFromOrderQuote(pageType)) {
    data = R.mergeRight(data, orderQuoteData);
  }

  return { data };
};

function* handleSendDataToApiSaga({ payload: { tel, quote, duplicate, cloMultiCreateData } }: Object) {
  try {
    yield put(openLoader());
    yield put(toggleSubmitLoading());

    const store = yield select(makeSelectFullDOStore());
    const division = yield select(makeSelectDivision());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const expandedContainerOptions = yield select(makeSelectExpandedContainerOptions());

    const {
      stops,
      hotOrder,
      orderType,
      rateBackup,
      branchInfo,
      templateData,
      telDriverRate,
      branchConfigs,
      branchRefTypes,
      telCarrierRate,
      telCreationMode,
      ratePreviewFiles,
      referenceFormData,
      routeTemplateGuid,
    } = store;

    const pickedFields = R.pick([
      GC.FIELD_SOURCE_TYPE,
      GC.FIELD_LOAD_TEL_CREATION_MODE,
    ], store);

    const references = makeReqReferences(store);
    const branchGuid = R.prop(GC.FIELD_VALUE, branchInfo);

    const { pickedItems, pickedUpContainers } = getDataFromStops(stops);

    const containers = G.mapArrayObjectEmptyStringFieldsToNull(pickedUpContainers);
    const billTo = makeReqBillTo(R.prop(GC.SYSTEM_OBJECT_BILL_TO, store));
    const divisionGuid = R.pathOr(currentBranchGuid, [GC.FIELD_GUID], division);
    const fastLoad = R.prop(GC.FIELD_FAST_LOAD, referenceFormData);

    const { services, equipment } = makeReqEquipmentAndServices(branchConfigs, rateBackup);

    const specialInstructions = R.prop(GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS, referenceFormData);
    const internalInstructions = R.prop(GC.FIELD_LOAD_INTERNAL_INSTRUCTIONS, referenceFormData);
    const rate = G.omitEmptyChargesFromData(R.or(rateBackup, null), GC.FIELD_CHARGES);

    const telCarrierRateToSend = G.ifElse(
      R.equals(telCreationMode, GC.TEL_CREATION_MODE_SINGLE_TEL),
      telCarrierRate,
      null,
    );

    const telFleetRate = G.ifElse(
      R.equals(telCreationMode, GC.TEL_CREATION_MODE_SINGLE_TEL),
      G.omitEmptyChargesFromData(telDriverRate, GC.FIELD_PRIMARY_DRIVER_CHARGES),
      null,
    );

    const templateGuid = G.getPropFromObject(GC.FIELD_TEMPLATE_GUID, templateData);
    const templateName = G.getPropFromObject(GC.FIELD_TEMPLATE_NAME, templateData);

    let numberOfLoads = G.toNumber(R.or(G.getPropFromObject(GC.FIELD_LOAD_NUMBER_OF_LOADS, store), 1));

    if (G.isNotNilAndNotEmpty(cloMultiCreateData)) {
      numberOfLoads = R.compose(
        R.inc,
        R.pathOr(0, ['length']),
      )(cloMultiCreateData);
    }

    const reqData = {
      ...pickedFields,
      tel,
      rate,
      quote,
      billTo,
      services,
      fastLoad,
      equipment,
      orderType,
      containers,
      templateGuid,
      templateName,
      telFleetRate,
      hot: hotOrder,
      routeTemplateGuid,
      cloMultiCreateData,
      specialInstructions,
      internalInstructions,
      [GC.FIELD_BRANCH_GUID]: branchGuid,
      telCarrierRate: telCarrierRateToSend,
      [GC.FIELD_LOAD_REFERENCES]: references,
      [GC.FIELD_LOAD_DIVISION_GUID]: divisionGuid,
      [GC.FIELD_LOAD_NUMBER_OF_LOADS]: numberOfLoads,
      [GC.FIELD_LOAD_ITEMS]: G.mapArrayObjectEmptyStringFieldsToNull(pickedItems),
      [GC.FIELD_LOAD_STOPS]: makeReqStops(stops, branchRefTypes, null, containers),
    };

    const options = getRequestOptions(reqData, store);

    const res = yield call(sendRequest, 'post', endpointsMap.createLoad, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const { cloGuids, telGuids, autoDispatchError } = data;

      if (G.isNotNilAndNotEmpty(autoDispatchError)) {
        yield call(G.showToastrMessage, 'error', autoDispatchError, { timeOut: 0 });
      }

      const cloGuid = R.head(cloGuids);

      if (R.and(
        G.isNotNilAndNotEmpty(ratePreviewFiles),
        R.equals(1, G.toNumber(R.prop(GC.FIELD_LOAD_NUMBER_OF_LOADS, store))),
      )) {
        const files = G.getItemFromWindow('amousCreateDoRateConfirmationFiles');
        const documentType = G.getConfigValueFromStore(GC.CLO_GENERAL_CONFIRMATION_DOCUMENT_TYPE, branchConfigs);

        yield all(files.map((file: Object) => call(createCloDocumentSaga, { file, cloGuid, documentType })));
      }

      yield call(G.showToastrMessage, 'success', 'messages:create-load-success');

      const hasTripPermission = G.hasAmousCurrentUserPermissions([PC.TEL_READ, PC.TEL_WRITE]);

      if (G.isTrue(duplicate)) {
        yield put(A.saveAndDuplicateSuccess());
        const primaryReferenceSequenceGuid = G.getConfigValueFromStore(
          GC.CLO_PRIMARY_REFERENCE_SEQUENCE, branchConfigs,
        );

        if (G.shouldGetPrimaryRefSequenceFromBranchConfigs(branchConfigs)) {
          yield put(A.getPrimaryRefSequenceRequest(primaryReferenceSequenceGuid));
        }
      } else if (R.and(hasTripPermission, G.isNotNilAndNotEmpty(telGuids))) {
        const guid = R.head(telGuids);
        const route = routesMap.dispatchDetailsLoad(guid);

        if (R.pathEq(true, ['opened'], expandedContainerOptions)) {
          yield put(setExpandedContainerOptions({
            opened: true,
            visitPageGuid: guid,
            componentType: GC.PAGE_DISPATCH_DETAILS_NEW_LOAD,
          }));
        } else {
          yield call(G.goToRoute, route);
        }
      } else {
        const route = routesMap.dispatchDetailsOrder(cloGuid);

        if (R.pathEq(true, ['opened'], expandedContainerOptions)) {
          yield put(setExpandedContainerOptions({
            opened: true,
            visitPageGuid: cloGuid,
            componentType: GC.PAGE_DISPATCH_DETAILS_NEW_ORDER,
          }));
        } else {
          yield call(G.goToRoute, route);
        }
      }
    } else {
      if (G.isPathNotNilAndNotEmpty(['errors'], data)) {
        yield put(A.setValidationErrors(data.errors));
      }

      yield call(G.handleFailResponse, res, 'handleSendDataToApiSaga fail', true);
    }

    yield put(closeLoader());
    yield put(toggleSubmitLoading());
  } catch (error) {
    yield put(closeLoader());
    yield put(toggleSubmitLoading());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleSendDataToApiSaga exception');
  }
}

function* handleSaveAsTemplateSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const store = yield select(makeSelectFullDOStore());
    const division = yield select(makeSelectDivision());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const {
      stops,
      hotOrder,
      orderType,
      rateBackup,
      branchInfo,
      branchConfigs,
      branchRefTypes,
      referenceFormData,
    } = store;

    const pickedFields = R.pick([
      GC.FIELD_LOAD_NUMBER_OF_LOADS,
      GC.FIELD_LOAD_TEL_CREATION_MODE,
    ], store);

    const references = makeReqReferences(store);

    const branchGuid = R.prop(GC.FIELD_VALUE, branchInfo);
    const templateName = R.prop(GC.FIELD_TEMPLATE_NAME, payload);
    const fastLoad = R.prop(GC.FIELD_FAST_LOAD, referenceFormData);
    const specialInstructions = R.prop(GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS, referenceFormData);
    const internalInstructions = R.prop(GC.FIELD_LOAD_INTERNAL_INSTRUCTIONS, referenceFormData);

    const divisionGuid = R.pathOr(currentBranchGuid, [GC.FIELD_GUID], division);

    const templateGuid = R.path(['templateData', GC.FIELD_TEMPLATE_GUID], store);

    const { pickedItems, pickedUpContainers } = getDataFromStops(stops);

    const billTo = makeReqBillTo(R.prop(GC.SYSTEM_OBJECT_BILL_TO, store));

    const { services, equipment } = makeReqEquipmentAndServices(branchConfigs, rateBackup);

    const events = makeReqStopsForTemplate({ stops, pickedItems, branchConfigs, branchRefTypes, pickedUpContainers });

    const isUpdate = R.equals(R.path(['mode'], payload), 'updateTemplate');

    const method = G.ifElse(
      isUpdate,
      'put',
      'post',
    );

    const reqData = {
      ...pickedFields,
      billTo,
      fastLoad,
      services,
      equipment,
      orderType,
      hot: hotOrder,
      specialInstructions,
      internalInstructions,
      rate: R.or(rateBackup, null),
      [GC.FIELD_LOAD_STOPS]: events,
      containers: pickedUpContainers,
      [GC.FIELD_BRANCH_GUID]: branchGuid,
      [GC.FIELD_LOAD_REFERENCES]: references,
      [GC.FIELD_TEMPLATE_NAME]: templateName,
      templateEnterpriseGuid: currentBranchGuid,
      [GC.FIELD_LOAD_DIVISION_GUID]: divisionGuid,
      [GC.FIELD_TEMPLATE_GUID]: G.ifElse(isUpdate, templateGuid, null),
      [GC.FIELD_LOAD_ITEMS]: G.mapArrayObjectEmptyStringFieldsToNull(pickedItems),
    };

    const res = yield call(sendRequest, method, endpointsMap.cloTemplate, { data: reqData });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      if (isUpdate) {
        yield call(G.showToastrMessage, 'success', 'messages:success:update');
      } else {
        yield call(G.showToastrMessage, 'success', 'messages:success:create');
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleSaveAsTemplateSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleSaveAsTemplateSaga exception');
  }
}

function* handleSendLoadStopsEditDataToApiSaga() {
  try {
    yield put(openLoader());
    const store = yield select(makeSelectFullDOStore());
    const { stops, loadGuid, loadType, fromPage, branchRefTypes } = store;
    const { pickedItems, pickedUpContainers } = getDataFromStops(stops);
    const stopsToRequest = makeLoadStopsEditReqStops({
      stops,
      loadGuid,
      loadType,
      pickedItems,
      branchRefTypes,
      pickedUpContainers,
    });
    const options = {
      data: {
        elements: stopsToRequest,
      },
      params: {
        cloGuid: loadGuid,
      },
    };

    const res = yield call(sendRequest, 'put', endpointsMap.cloEvent, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeValidationErrors());

      if (G.isPageDispatchDetailsNewOrder(fromPage)) {
        yield put(getOrderDetailsRequest(loadGuid));
      }

      if (G.isPageDispatchBoardNew(fromPage)) {
        yield put(getDBOrderDetailsRequest(loadGuid));
      }
    } else {
      if (G.isPathNotNilAndNotEmpty(['errors'], data)) {
        yield put(A.setValidationErrors(data.errors));
      }

      yield call(G.handleFailResponse, res, 'handleSendLoadStopsEditDataToApiSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleSendLoadStopsEditDataToApiSaga exception');
  }
}

function* handleGetBranchDataSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const branchGuid = payload;

    const loadType = yield select(makeSelectLoadType());

    const scopeName = R.prop(loadType, loadTypeToScopeMap);

    yield call(getBranchSharedAccessorialListSaga, { payload });

    yield put(A.getBranchConfigsRequest(branchGuid));
    yield put(A.getBranchStylingRequest(branchGuid));

    if (G.isLoadTypeTel(loadType)) {
      yield put(getAllAvailableRefTypesByScopeRequest({
        scopeName,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      }));
    }

    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'handleGetBranchDataSaga exception');
  }
}

// public clo
function* getPublicCloRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'get', endpointsMap.publicCLOGetByGuid(payload));
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getBranchConfigsWithPublicCloDataRequest(data));
    } else {
      yield call(G.handleFailResponse, res, 'getPublicCloRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getPublicCloRequest exception');
  }
}

function* getBranchConfigsWithPublicCloDataRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const branchGuid = G.getPropFromObject(GC.BRANCH_GUID, payload);

    yield put(A.getBranchStylingRequest(branchGuid));
    yield put(A.getTransportationModeGroupingListRequest(branchGuid));
    yield put(A.getAllAvBranchRefTypesRequest({
      branchGuid,
      scopeName: GC.REF_SCOPE_NAME_CLO,
    }));

    const options = {
      params: {
        names: R.join(',', CONFIGS_ARR),
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const mappedConfigs = G.mapConfigValuesByName(data);

      yield put(A.getBranchConfigsWithPublicCloDataSuccess({
        branchGuid,
        publicCloData: payload,
        branchConfigs: mappedConfigs,
      }));
    } else {
      yield call(G.handleFailResponse, res, 'getBranchConfigsWithPublicCloDataRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getBranchConfigsWithPublicCloDataRequest exception');
  }
}

// order quote
function* getOrderQuoteDataRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'get', endpointsMap.orderQuoteGetByGuid(payload));

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getBranchConfigsWithOrderQuoteDataRequest(data));
    } else {
      yield call(G.handleFailResponse, res, 'getOrderQuoteDataRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getOrderQuoteDataRequest exception');
  }
}

function* getBranchConfigsWithOrderQuoteDataRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const branchGuid = G.getPropFromObject(GC.BRANCH_GUID, payload);
    yield put(A.getBranchStylingRequest(branchGuid));
    yield put(A.getAllAvBranchRefTypesRequest({
      branchGuid,
      scopeName: GC.REF_SCOPE_NAME_CLO,
    }));
    const options = {
      params: {
        names: R.join(',', CONFIGS_ARR),
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const mappedConfigs = G.mapConfigValuesByName(data);

      yield put(A.getTransportationModeGroupingListRequest(branchGuid));
      yield put(A.getBranchConfigsWithOrderQuoteDataSuccess({
        branchGuid,
        orderQuoteData: payload,
        branchConfigs: mappedConfigs,
      }));

      yield call(getBranchBillToSaga, { branchGuid, configs: mappedConfigs });

      const useQuoteNumberAsPrimaryReference = G.getConfigValueFromStore(
        GC.CLO_QUOTE_USE_QUOTE_NUMBER_AS_PRIMARY_REFERENCE,
        mappedConfigs,
      );

      if (G.isFalse(useQuoteNumberAsPrimaryReference)) {
        const primaryReferenceSequenceGuid = G.getConfigValueFromStore(
          GC.CLO_PRIMARY_REFERENCE_SEQUENCE, mappedConfigs,
        );

        if (G.shouldGetPrimaryRefSequenceFromBranchConfigs(mappedConfigs)) {
          yield put(A.getPrimaryRefSequenceRequest(primaryReferenceSequenceGuid));
        }
      } else {
        yield put(A.setCustomerLoadNumber(G.getPropFromObject(GC.FIELD_ORDER_QUOTE_NUMBER, payload)));
      }
    } else {
      yield call(G.handleFailResponse, res, 'getBranchConfigsWithOrderQuoteDataRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getBranchConfigsWithOrderQuoteDataRequest exception');
  }
}

// quote
function* getQuoteRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'get', endpointsMap.getQuoteRequestByGuid(payload));

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getBranchConfigsWithQuoteRequest(data));
    } else {
      yield call(G.handleFailResponse, res, 'getQuoteRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getQuoteRequest exception');
  }
}

function* handleGetBranchConfigsWithQuoteSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const loadType = yield select(makeSelectLoadType());

    const branchGuid = G.getPropFromObject(GC.BRANCH_GUID, payload);
    const scopeName = R.prop(loadType, loadTypeToScopeMap);

    yield put(getAllAvailableRefTypesByScopeRequest({
      scopeName,
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    }));
    yield put(A.getBranchStylingRequest(branchGuid));
    yield put(A.getTransportationModeGroupingListRequest(branchGuid));

    const options = {
      params: {
        names: R.join(',', CONFIGS_ARR),
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const mappedConfigs = G.mapConfigValuesByName(data);

      yield put(A.getBranchConfigsWithQuoteSuccess({ branchGuid, quoteData: payload, branchConfigs: mappedConfigs }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetBranchConfigsWithQuoteSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetBranchConfigsWithQuoteSaga exception');
  }
}

// duplicate clo
function* getOrderRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'get', endpointsMap.getCloEndpoint(payload));
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getBranchConfigsWithDuplicateDORequest(data));
    } else {
      yield call(G.handleFailResponse, res, 'getOrderRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getOrderRequest exception');
  }
}

function* handleGetBranchConfigsWithDuplicateDOSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const loadType = yield select(makeSelectLoadType());

    const scopeName = R.prop(loadType, loadTypeToScopeMap);
    const branchGuid = G.getPropFromObject(GC.BRANCH_GUID, payload);

    yield put(getAllAvailableRefTypesByScopeRequest({
      scopeName,
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    }));
    yield put(A.getBranchStylingRequest(branchGuid));
    yield put(A.getTransportationModeGroupingListRequest(branchGuid));

    const options = {
      params: {
        names: R.join(',', CONFIGS_ARR),
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const mappedConfigs = G.mapConfigValuesByName(data);

      yield put(A.getBranchConfigsOnDuplicateDOSuccess({
        branchGuid,
        duplicateDOData: payload,
        branchConfigs: mappedConfigs,
      }));
      yield put(A.getAllAvBranchRefTypesRequest({
        branchGuid,
        scopeName: GC.REF_SCOPE_NAME_CLO,
      }));

      const branchInfo = yield select(makeSelectBranchInfo());

      const divisionGuid = getDivisionGuidByBranchConfigs(mappedConfigs, branchInfo);

      if (G.isNotNilAndNotEmpty(divisionGuid)) {
        yield put(A.setDivisionGuid(divisionGuid));
        yield put(getBranchConfigsByNamesRequest({
          branchGuid: divisionGuid,
          names: GC.CLO_PRIMARY_REFERENCE_DIVISION_PREFIX,
        }));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetBranchConfigsWithDuplicateDOSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetBranchConfigsWithDuplicateDOSaga exception');
  }
}

// create from template
function* getTemplateDataRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'get', endpointsMap.getCloTemplateByGuid(payload));

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const branchGuid = G.getPropFromObject(GC.BRANCH_GUID, data);

      yield put(A.getTransportationModeGroupingListRequest(branchGuid));
      yield put(A.getBranchAndTemplateDataRequest({ branchGuid, templateData: data }));
    } else {
      yield call(G.handleFailResponse, res, 'getTemplateDataRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getTemplateDataRequest exception');
  }
}

function* handleGetBranchAndTemplateDataSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { branchGuid } = payload;

    const loadType = yield select(makeSelectLoadType());

    const scopeName = R.prop(loadType, loadTypeToScopeMap);

    yield call(getBranchSharedAccessorialListSaga, { payload: branchGuid });

    yield put(getAllAvailableRefTypesByScopeRequest({
      scopeName,
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    }));

    yield put(A.getBranchStylingRequest(branchGuid));
    yield put(A.getBranchConfigsWithTemplateRequest(payload));
    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'handleGetBranchAndTemplateDataSaga exception');
  }
}

function* handleGetBranchConfigsWithTemplateSaga({ payload }: Object) {
  try {
    const pageType = yield select(makeSelectPageType());

    const { branchGuid, templateData } = payload;

    const options = {
      params: {
        names: R.join(',', CONFIGS_ARR),
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const mappedConfigs = G.mapConfigValuesByName(data);
      const divisionGuid = getDivisionGuidByBranchConfigs(mappedConfigs, { [GC.FIELD_VALUE]: branchGuid });

      yield put(A.getBranchConfigsWithTemplateSuccess({ branchGuid, templateData, branchConfigs: mappedConfigs }));
      yield put(A.getAllAvBranchRefTypesRequest({
        branchGuid,
        scopeName: GC.REF_SCOPE_NAME_CLO,
      }));

      if (R.not(isPageCloTemplate(pageType))) {
        yield put(A.getTransportationModeGroupingListRequest(branchGuid));
      }

      if (G.isNotNilAndNotEmpty(divisionGuid)) {
        yield put(getBranchConfigsByNamesRequest({
          branchGuid: divisionGuid,
          names: GC.CLO_PRIMARY_REFERENCE_DIVISION_PREFIX,
        }));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetBranchConfigsWithTemplateSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetBranchConfigsWithTemplateSaga exception');
  }
}

function* getBranchListRequest() {
  try {
    const branch = yield select(makeSelectCurrentBranch());

    const branchGuid = G.getGuidFromObject(branch);

    const endpoint = endpointsMap.getBranchAllChildrenWithShared(branchGuid);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const actionData = prependCurrentBranchToBranchList(data, branch);

      yield put(A.getBranchListSuccess(actionData));
    } else {
      yield call(G.handleFailResponse, res, 'getBranchListSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getBranchListSaga exception');
  }
}

function* applyOrderRateUpliftRequest({ payload }: Object) {
  try {
    const store = yield select(makeSelectFullDOStore());
    const { rate, branchInfo, telDriverRate, telCarrierRate } = store;
    const branchGuid = R.prop(GC.FIELD_VALUE, branchInfo);
    const telRateToUse = R.or(telDriverRate, telCarrierRate);
    const rateType = G.ifElse(
      G.isNotNilAndNotEmpty(telCarrierRate),
      GC.RATE_TYPE_CARRIER_RATE,
      GC.RATE_TYPE_FLEET_RATE,
    );
    const options = {
      data: {
        ...payload,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
        cloRateCurrency: G.getCurrencyFromRate(rate),
        telRateCurrency: G.getCurrencyFromRate(telRateToUse),
        telRateCharges: G.omitEmptyChargesFromCharges(G.getTelRateCharges(telRateToUse, { rateType, concatDriverCharges: true })),
      },
    };
    const res = yield call(sendRequest, 'put', endpointsMap.cloRateSyncOnCreateEndpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.setCustomerRateChargesToStore(data));
    }
  } catch (error) {
    yield call(G.handleException, error, 'applyOrderRateUpliftRequest exception');
  }
}

// transportation mode grouping
function* getTransportationModeGroupingListRequest({ payload }: Object) {
  try {
    const options = {
      params: { [GC.BRANCH_GUID]: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.transportationModeGroupingList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getTransportationModeGroupingListSuccess(data));
    } else {
      yield call(G.handleException, 'error', 'getTransportationModeGroupingListRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getTransportationModeGroupingListRequest exception');
  }
}

// ai recognize
function* fetchStopLatLonRequest({ payload }: Object) {
  const { address, stopIndex } = payload;

  try {
    const data = yield call(G.geocodeByPlaceAddressV2, address, 'new-do -> fetchStopLatLonSaga');

    yield put(A.fetchStopLatLonSuccess({ data, stopIndex }));
  } catch (error) {
    yield put(A.fetchStopLatLonFailure(stopIndex, error.message));
  }
}

function* aiRecognizeRequest({ payload }: Object) {
  try {
    if (G.isNilOrEmpty(payload)) return;

    const pageType = yield select(makeSelectPageType());

    if (R.not(isPageCreateDO(pageType))) return;

    const { parseDocument } = yield select(makeSelectAiRecognize());

    const { aiEnabled } = yield select(makeSelectMasterSettings());

    const parseDocumentEnabled = G.isBoolean(parseDocument) ? parseDocument : aiEnabled;

    if (G.isFalse(parseDocumentEnabled)) return;

    const file = payload;

    if (G.isNilOrEmpty(file)) return;

    yield put(A.setValueToStore({
      value: true,
      path: 'aiRecognize.loading',
    }));

    const reqData = G.makeDataForDocument({ file });

    const options = {
      data: reqData,
      params: {
        type: 'LOAD',
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.aiDocumentRecognize, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.aiRecognizeSuccess(data));

      yield all(data.events.map((stop: Object) => {
        const { location, cloEventIndex } = stop;

        const { address1, city, state, zip } = location;

        const address = `${address1}, ${city}, ${state}, ${zip}`;

        return put(A.fetchStopLatLonRequest({ address, stopIndex: cloEventIndex }));
      }));
    } else {
      yield call(G.handleFailResponse, res, 'aiDocumentRecognize fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'aiDocumentRecognize exception');
  } finally {
    yield put(A.setValueToStore({
      value: false,
      path: 'aiRecognize.loading',
    }));
  }
}

// visit

function* visitNewDOPageBySourceType({ payload }: Object) {
  while (true) { // eslint-disable-line
    const { guid, sourceType } = payload;

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_NEW_DO_PAGE);

    yield put(openLoader({ showDimmer: true }));
    yield put(A.cleanNewDOStore({ sourceType }));

    yield call(getBranchListRequest);

    if (R.equals(sourceType, GC.CREATE_DO_SOURCE_TYPE_TEMPLATE)) {
      yield put(A.setPageTypeToStore(PAGE_TYPE_CREATE_DO));
    }

    const actionsMap = {
      [GC.CREATE_DO_SOURCE_TYPE_COPY]: A.getOrderRequest,
      [GC.CREATE_DO_SOURCE_TYPE_QUOTE]: A.getQuoteRequest,
      [GC.CREATE_DO_SOURCE_TYPE_API]: A.getPublicCloRequest,
      [GC.CREATE_DO_SOURCE_TYPE_EDI]: A.getEdiTransactionRequest,
      [GC.CREATE_DO_SOURCE_TYPE_TEMPLATE]: A.getTemplateDataRequest,
      [GC.CREATE_DO_SOURCE_TYPE_ORDER_QUOTE]: A.getOrderQuoteDataRequest,
    };

    yield put(G.getPropFromObject(sourceType, actionsMap)(guid));
    yield put(getServiceMappingListRequest());
    yield put(closeLoader());

    G.setItemToWindow('amousCreateDoRateConfirmationFiles', null);

    break;
  }
}

function* handleVisitDOTemplateDetailsPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_TEMPLATE_DETAILS_PAGE);

    yield put(openLoader({ showDimmer: true }));
    yield put(A.cleanNewDOStore({ pageType: PAGE_TYPE_CLO_TEMPLATE }));

    yield call(getBranchListRequest);

    yield put(A.getTemplateDataRequest(G.getGuidFromObject(payload)));
    yield put(getServiceMappingListRequest());
    yield put(closeLoader());

    G.setItemToWindow('amousCreateDoRateConfirmationFiles', null);

    break;
  }
}

function* handleVisitNewDOPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_NEW_DO_PAGE);

    yield put(openLoader({ showDimmer: true }));
    yield put(A.cleanNewDOStore({
      pageType: PAGE_TYPE_CREATE_DO,
      [GC.FIELD_SOURCE_TYPE]: GC.CREATE_DO_SOURCE_TYPE_MANUAL,
    }));

    yield call(getBranchListSaga);

    yield put(getServiceMappingListRequest());
    yield put(closeLoader());

    G.setItemToWindow('amousCreateDoRateConfirmationFiles', null);

    break;
  }
}

function* newDOWatcherSaga() {
  yield takeLatest(GC.VISIT_NEW_DO_PAGE, handleVisitNewDOPageSaga);
  yield takeLatest(GC.VISIT_NEW_DO_PAGE_BY_SOURCE_TYPE, visitNewDOPageBySourceType);
  yield takeLatest(GC.VISIT_TEMPLATE_DETAILS_PAGE, handleVisitDOTemplateDetailsPageSaga);
  //
  yield takeLatest(A.getBranchListRequest, getBranchListRequest);
  yield takeLatest(A.getBranchDataRequest, handleGetBranchDataSaga);
  yield takeLatest(A.sendDataToApiRequest, handleSendDataToApiSaga);
  yield takeLatest(A.saveAsTemplateRequest, handleSaveAsTemplateSaga);
  yield takeLatest(A.getBranchStylingRequest, handleGetBranchStylingSaga);
  yield takeLatest(A.getBranchConfigsRequest, handleGetBranchConfigsSaga);
  yield takeLatest(A.getPrimaryRefSequenceRequest, handleGetPrimaryRefSequenceSaga);
  yield takeLatest(A.getAllAvBranchRefTypesRequest, handleGetAllAvBranchRefTypesSaga);
  yield takeLatest(A.getBranchConfigsWithEdiRequest, handleGetBranchConfigsWithEdiSaga);
  yield takeLatest(A.recalculateLoadDistancesRequest, handleRecalculateLoadDistancesSaga);
  // edit stops
  yield takeLatest(A.sendLoadStopsEditDataToApiRequest, handleSendLoadStopsEditDataToApiSaga);
  yield takeLatest(A.getBranchConfigsWithLoadStopsRequest, handleBranchConfigsWithLoadStopsSaga);
  // edi
  yield takeLatest(A.getEdiTransactionRequest, getEdiTransactionRequest);
  // public clo
  yield takeLatest(A.getPublicCloRequest, getPublicCloRequest);
  yield takeLatest(A.getBranchConfigsWithPublicCloDataRequest, getBranchConfigsWithPublicCloDataRequest);
  // quote
  yield takeLatest(A.getQuoteRequest, getQuoteRequest);
  yield takeLatest(A.getBranchDataOnQuoteRequest, handleGetBranchDataOnQuoteSaga);
  yield takeLatest(A.getBranchConfigsWithQuoteRequest, handleGetBranchConfigsWithQuoteSaga);
  // duplicate clo
  yield takeLatest(A.getOrderRequest, getOrderRequest);
  yield takeLatest(A.getBranchConfigsWithDuplicateDORequest, handleGetBranchConfigsWithDuplicateDOSaga);
  // create from template
  yield takeLatest(A.getTemplateDataRequest, getTemplateDataRequest);
  yield takeLatest(A.getBranchAndTemplateDataRequest, handleGetBranchAndTemplateDataSaga);
  yield takeLatest(A.getBranchConfigsWithTemplateRequest, handleGetBranchConfigsWithTemplateSaga);
  // order rate
  yield takeLatest(A.applyOrderRateUpliftRequest, applyOrderRateUpliftRequest);
  // order quote
  yield takeLatest(A.getOrderQuoteDataRequest, getOrderQuoteDataRequest);
  yield takeLatest(A.getBranchConfigsWithOrderQuoteDataRequest, getBranchConfigsWithOrderQuoteDataRequest);
  // transportation mode grouping
  yield takeLatest(A.getTransportationModeGroupingListRequest, getTransportationModeGroupingListRequest);
  //
  yield takeLatest(A.aiRecognizeRequest, aiRecognizeRequest);
  yield takeEvery(A.fetchStopLatLonRequest, fetchStopLatLonRequest);
}

export default newDOWatcherSaga;
