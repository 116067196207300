import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, RelativeBox, AbsoluteBox, ActionButton } from '../../../ui';
//////////////////////////////////////////////////

const warningColor = G.getTheme('colors.warning');
const darkGreyColor = G.getTheme('colors.darkGrey');
const errorColor = G.getTheme('colors.light.mainRed');
const mainLightColor = G.getTheme('colors.light.mainLight');

const colorsMap = {
  error: {
    bg: errorColor,
    text: mainLightColor,
  },
  warning: {
    bg: warningColor,
    text: darkGreyColor,
  },
};

const ErrorsComponent = ({
  type,
  title,
  errors,
  remove,
  actions,
  showActions,
  actionsTextAdditional,
}: Object) => (
  <RelativeBox
    p={15}
    m='25px'
    maxWidth='700px'
    borderRadius='4px'
    justifyContent='center'
    bg={R.pathOr(errorColor, [type, 'bg'], colorsMap)}
  >
    <AbsoluteBox
      top='10px'
      right='10px'
      cursor='pointer'
      onClick={remove}
    >
      {I.closeIcon(R.pathOr(errorColor, [type, 'text'], colorsMap), 15, 15)}
    </AbsoluteBox>
    {
      title &&
      <Box mb='10px' color={R.pathOr(errorColor, [type, 'text'], colorsMap)}>{title}</Box>
    }
    {
      errors.map((error: string, index: number) => (
        <Box pr='30px' key={index} color={R.pathOr(errorColor, [type, 'text'], colorsMap)}>
          {`${R.inc(index)}. ${error}`}
        </Box>
      ))
    }
    {
      R.and(showActions, G.isNotNilAndNotEmpty(actions)) &&
      <Flex mt={16} width='-webkit-fill-available'>
        <Flex ml='auto'>
          {
            G.isNotNilAndNotEmpty(actionsTextAdditional) &&
            <Box color={darkGreyColor}>{actionsTextAdditional}</Box>
          }
          {
            actions.map(({ text, action }: Object, i: number) => (
              <ActionButton
                key={i}
                ml='8px'
                height={32}
                p='4px 8px'
                fontSize={14}
                onClick={action}
                borderRadius='5px'
                bgColor={mainLightColor}
                textColor={darkGreyColor}
                border={`1px solid ${darkGreyColor}`}
              >
                {text}
              </ActionButton>
            ))
          }
        </Flex>
      </Flex>
    }
  </RelativeBox>
);

export default ErrorsComponent;
