import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose, withState } from 'react-recompose';
// components
import { InfoPair } from '../../../components/info-pair';
import { AddressBlock } from '../../../components/address-block';
import { ChargeFormFooter } from '../../../components/form-footer';
import { ChargesTotal } from '../../../components/charge/components';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import { FormGroupTitleMultiple } from '../../../components/form-group-title-multiple';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, StickedBox, SectionsDivider } from '../../../ui';
// schemes
import * as VS from '../../../schemes';
// feature rate
import { withAsyncContacts } from '../hocs';
import DispatcherSection from './dispatcher-section';
import ChargesSection from '../components/charges-section';
//////////////////////////////////////////////////

const validationUpdateSchemaObject = Yup.object()
  .shape(VS.validationSchemaDispatcherInfoObject);

const setInitialValues = ({ rate }: Object) => {
  const { carrierAssignment } = rate;

  return {
    ...R.pickAll(
      [GC.FIELD_DISPATCH_NAME, GC.FIELD_DISPATCH_PHONE, GC.FIELD_DISPATCH_EMAILS],
      rate,
    ),
    carrierAssignment: R.pickAll(
      [
        GC.FIELD_TRUCK,
        GC.FIELD_TRAILER,
        GC.FIELD_PRIMARY_DRIVER,
        GC.FIELD_SECONDARY_DRIVER,
        GC.FIELD_PRIMARY_DRIVER_PHONE,
        GC.FIELD_SECONDARY_DRIVER_PHONE,
      ],
      carrierAssignment,
    ),
  };
};

const enhance = compose(
  connect(null, { openLoader, closeLoader }),
  withAsyncContacts,
  withState('isValidEmails', 'setIsValidEmails', true),
  withFormik({
    mapPropsToValues: setInitialValues,
    validationSchema: validationUpdateSchemaObject,
    handleSubmit: (values: Object, { props }: Object) => {
      const { rate, handleSendTelRate } = props;
      const { dispatchName, dispatchPhone, dispatchEmails, carrierAssignment } = values;

      const data = {
        ...carrierAssignment,
        dispatchName,
        dispatchPhone,
        dispatchEmails,
        [GC.FIELD_GUID]: G.getGuidFromObject(rate),
      };

      handleSendTelRate(data);
    },
  }),
  pure,
);

const isShownDispatcherSection = R.compose(
  R.lt(0),
  R.length,
  R.values,
  R.filter(G.isNotNilAndNotEmpty),
  R.pick([GC.FIELD_DISPATCH_NAME, GC.FIELD_DISPATCH_PHONE, GC.FIELD_DISPATCH_EMAILS]),
);

const infoPairStyles = {
  fontSize: 14,
  margin: '6px 15px',
  width: 'max-content',
};

const TrackingReferenceSection = ({ bolNumber, pickupNumber, trackingNumber }: Object) => {
  if (G.isAllNilOrEmpty([bolNumber, pickupNumber, trackingNumber])) return null;

  return (
    <div>
      <SectionsDivider />
      <Flex flexWrap='wrap'>
        <InfoPair
          {...infoPairStyles}
          text={bolNumber}
          label={G.getWindowLocale('titles:bol-number', 'BOL Number')}
        />
        <InfoPair
          {...infoPairStyles}
          text={trackingNumber}
          label={G.getWindowLocale('titles:tracking-number', 'Tracking Number')}
        />
        <InfoPair
          {...infoPairStyles}
          text={pickupNumber}
          label={G.getWindowLocale('titles:pickup-number', 'Pickup Number')}
        />
      </Flex>
    </div>
  );
};

const CarrierSection = ({ name, scac, mcNumber, usDotNumber, terminalSnapshot }: Object) => (
  <Flex flexWrap='wrap'>
    <InfoPair
      {...infoPairStyles}
      text={name}
      label={G.getWindowLocale('titles:carrier-name', 'Carrier Name')}
    />
    <InfoPair
      {...infoPairStyles}
      text={usDotNumber}
      label={G.getWindowLocale('titles:usdot-number', 'USDOT Number')}
    />
    <InfoPair
      {...infoPairStyles}
      text={scac}
      label={G.getWindowLocale('titles:scac', 'SCAC')}
    />
    <InfoPair
      {...infoPairStyles}
      text={mcNumber}
      label={G.getWindowLocale('titles:mc-number', 'MC Number')}
    />
    {
      G.isNotNilAndNotEmpty(terminalSnapshot) &&
      <Flex m='6px 15px'>
        <Box mr={10} fontSize={14}>
          {G.getWindowLocale('titles:terminal', 'Terminal')}:
        </Box>
        <AddressBlock fontSize={14} location={terminalSnapshot} />
      </Flex>
    }
  </Flex>
);

const CarrierTotalSection = ({
  currency,
  serviceType,
  serviceDays,
  totalTripWeight,
  totalTripDistance,
  transportationMode,
}: Object) => (
  <Flex flexWrap='wrap'>
    <InfoPair
      {...infoPairStyles}
      text={currency}
      label={G.getWindowLocale('titles:currency', 'Carrier Name')}
    />
    <InfoPair
      {...infoPairStyles}
      text={serviceType}
      label={G.getWindowLocale('titles:service-type', 'Service Type')}
    />
    <InfoPair
      {...infoPairStyles}
      text={totalTripDistance}
      label={G.getWindowLocale('titles:total-trip-distance', 'Total Trip Distance')}
    />
    <InfoPair
      {...infoPairStyles}
      text={transportationMode}
      label={G.getWindowLocale('titles:transportation-mode', 'Transportation Mode')}
    />
    <InfoPair
      {...infoPairStyles}
      text={serviceDays}
      label={G.getWindowLocale('titles:service-days', 'Service Days')}
    />
    <InfoPair
      {...infoPairStyles}
      text={totalTripWeight}
      label={G.getWindowLocale('titles:total-trip-weight', 'Total Trip Weight')}
    />
  </Flex>
);

const EditCarrierRateForUserTypeCarrier = (props: Object) => {
  const {
    rate,
    title,
    values,
    openModal,
    closeModal,
    branchGuid,
    contactList,
    handleSubmit,
    isValidEmails,
    setIsValidEmails,
    notAllowedCustomerRate,
  } = props;

  const {
    currency,
    serviceDays,
    totalTripWeight,
    carrierAssignment,
    totalTripDistance,
    carrierRateCharges,
    totalTripWeightUom,
    totalTripDistanceUom,
  } = rate;

  const groupedCharges = R.groupBy(R.prop(GC.FIELD_TYPE), carrierRateCharges);

  const valuesToUse = R.mergeRight(
    values,
    R.pick([GC.FIELD_CURRENCY, GC.FIELD_NORMALIZED_TOTAL], rate),
  );

  return (
    <Box width={1000} bg='white'>
      <StickedBox top='0px' zIndex={15}>
        <FormGroupTitleMultiple
          mb='0'
          jc='space-between'
          title={title}
          showArrowToggle={false}
        />
      </StickedBox>
      <form onSubmit={handleSubmit}>
        <CarrierSection {...carrierAssignment} />
        <DispatcherSection
          {...G.getFormikProps(props)}
          openModal={openModal}
          closeModal={closeModal}
          branchGuid={branchGuid}
          contactList={contactList}
          searchEmailMargin='5px 0px 10px'
          setIsValidEmails={setIsValidEmails}
          isEditMode={isShownDispatcherSection(values)}
          fieldError={R.path(['errors', GC.FIELD_DISPATCH_EMAILS], props)}
          emails={R.pathOr([], ['values', GC.FIELD_DISPATCH_EMAILS], props)}
        />
        <TrackingReferenceSection {...carrierAssignment} />
        <SectionsDivider />
        <CarrierTotalSection
          currency={currency}
          serviceDays={serviceDays}
          totalTripWeight={`${totalTripWeight} ${totalTripWeightUom}`}
          totalTripDistance={`${totalTripDistance} ${totalTripDistanceUom}`}
          transportationMode={R.path([GC.SYSTEM_DROPDOWN_MODE, GC.FIELD_DISPLAYED_VALUE], rate)}
          serviceType={R.path([GC.SYSTEM_DROPDOWN_SERVICE_TYPE, GC.FIELD_DISPLAYED_VALUE], rate)}
        />
        <SectionsDivider />
        <ChargesSection currency={currency} useChargeCurrency={true} groupedCharges={groupedCharges} />
        <ChargesTotal
          {...props}
          isEditMode={true}
          values={valuesToUse}
          charges={carrierRateCharges}
        />
        <StickedBox bottom='0px' zIndex={13}>
          <ChargeFormFooter submitDisabled={R.or(R.not(isValidEmails), notAllowedCustomerRate)} />
        </StickedBox>
      </form>
    </Box>
  );
};

export default enhance(EditCarrierRateForUserTypeCarrier);
