import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { connect } from 'react-redux';
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { PageTitle } from '../../../components/page-title';
import { FormButtons } from '../../../components/form-buttons';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, CancelButton, ActionButton, FormSaveButton } from '../../../ui';
// feature new-import
import * as C from '../constants';
import { TableCellWrapper } from '../ui';
import { importTypes } from '../settings';
import { makeSelectEmptyRow, makeSelectImportList, makeSelectConfigByImportType } from '../selectors';
import {
  setInitialState,
  changeTableCell,
  changeImportList,
  removeSelectedRows,
  saveImportListRequest,
  calculateImportCLOLocations,
} from '../actions';
import {
  renderRow,
  renderTable,
  generateGrid,
  AddTableRows,
  makeLocationArray,
  renderValueViewer,
  AddAdditionalFieldToTable,
} from '../helpers';
//////////////////////////////////////////////////

const stringNotRequired = Yup.string().nullable(true).notRequired();

const stringRequired = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

const numberRequired = Yup.number()
  .nullable(true)
  .typeError('${type}') // eslint-disable-line
  .required(G.getRequiredLocaleTxt());

const numberNotRequired = Yup.number()
  .nullable(true)
  .notRequired()
  .typeError('${type}'); // eslint-disable-line

const dateRequired = Yup.date()
  .nullable(true)
  .typeError('${value}') // eslint-disable-line
  .required(G.getRequiredLocaleTxt());

const dateNotRequired = Yup.date()
  .nullable(true)
  .notRequired()
  .typeError('${value}'); // eslint-disable-line

const ImportTable = (props: Object) => {
  const {
    errors,
    selected,
    emptyRow,
    importList,
    importType,
    handleClose,
    globalErrors,
    configOptions,
    setInitialState,
    handleSelectRow,
    carrierRatePrice,
    handleChangeGrid,
    handleRemoveRows,
    handleValidateList,
    geoFencingZoneList,
    handleSaveImportList,
    saveImportListRequest,
  } = props;

  const gridLength = R.length(importList);
  const tableHeight = G.ifElse(
    G.isTrue(carrierRatePrice),
    'calc(90vh - 225px)',
    G.ifElse(
      R.or(G.isNotNilAndNotEmpty(errors), G.isNotNilAndNotEmpty(globalErrors)),
      'calc(100vh - 480px)',
      'calc(100vh - 250px)',
    ),
  );

  let allChecked = false;
  if (R.gt(gridLength, 0)) {
    allChecked = R.and(R.equals(R.length(selected), gridLength), R.all(G.isTrue, R.values(selected)));
  }

  const importTypeTxtLocale = R.compose(
    R.pathOr(importType, [GC.FIELD_LABEL]),
    R.find(R.propEq(importType, GC.FIELD_VALUE)),
  )(importTypes);

  return (
    <Box mt={50} width='100%'>
      <Flex alignItems='flex-end'>
        <PageTitle
          mr={30}
          withCount={true}
          count={gridLength}
          textTransform='capitalize'
          title={`${G.getWindowLocale('titles:import', 'Import')} ${importTypeTxtLocale}`}
        />
        <AddTableRows gridLength={gridLength} importList={importList} />
        {
          R.equals(importType, C.IMPORT_TYPE_CLO) &&
          <Flex ml={30} width={330} justifyContent='space-between'>
            <AddAdditionalFieldToTable
              prefix={GC.FIELD_REFERENCE}
              label='titles:reference-name'
              text={G.getWindowLocale('titles:add-reference', 'Add Reference')}
            />
            <AddAdditionalFieldToTable
              prefix='chrg'
              label='titles:charge-name'
              text={G.getWindowLocale('titles:add-charge', 'Add Charge')}
            />
          </Flex>
        }
        <ActionButton
          ml={30}
          height={25}
          width={130}
          type='button'
          borderRadius='unset'
          onClick={handleRemoveRows}
          bgColor={G.getTheme('colors.darkRed')}
        >
          {G.getWindowLocale('titles:delete-rows', 'Delete Rows')}
        </ActionButton>
        <ActionButton
          ml={30}
          height={25}
          width={140}
          type='button'
          borderRadius='unset'
          onClick={handleValidateList}
          bgColor={G.getTheme('colors.light.black')}
        >
          {G.getWindowLocale('titles:validate-show-errors', 'Validate/Show Errors')}
        </ActionButton>
      </Flex>
      <Box
        maxWidth='100%'
        overflow='auto'
        border='1px solid'
        height={tableHeight}
        maxHeight={tableHeight}
        borderColor={G.getTheme('colors.lightGrey')}
      >
        <ReactDataSheet
          cellRenderer={TableCellWrapper}
          valueViewer={renderValueViewer}
          onCellsChanged={handleChangeGrid}
          dataRenderer={({ value }: Object) => value}
          valueRenderer={({ value }: Object) => value}
          data={generateGrid({ importList, configOptions, geoFencingZoneList })}
          rowRenderer={({ row, children }: Object) =>
            renderRow(row, selected, children, handleSelectRow)
          }
          sheetRenderer={({ children, className }: Object) =>
            renderTable(children, className, allChecked, handleSelectRow, emptyRow)
          } />
      </Box>
      {
        G.isTrue(carrierRatePrice) &&
        <Flex height={60} width='100%' justifyContent='flex-end'>
          <CancelButton type='button' onClick={handleClose}>
            {G.getWindowLocale('actions:close', 'Close')}
          </CancelButton>
          <FormSaveButton
            ml={20}
            type='button'
            disabled={G.isNilOrEmpty(importList)}
            onClick={() => saveImportListRequest()}
          >
            {G.getWindowLocale('actions:save', 'Save')}
          </FormSaveButton>
        </Flex>
      }
      {
        G.isFalse(carrierRatePrice) &&
        <FormButtons
          handleClickSave={handleSaveImportList}
          handleClickCancel={() => setInitialState()}
        />
      }
    </Box>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  emptyRow: makeSelectEmptyRow(state),
  importList: makeSelectImportList(state),
  configOptions: makeSelectConfigByImportType(state),
});

const enhance = compose(
  connect(
    mapStateToProps,
    {
      setInitialState,
      changeTableCell,
      changeImportList,
      removeSelectedRows,
      saveImportListRequest,
      calculateImportCLOLocations,
    },
  ),
  withState('selected', 'setSelected', {}),
  withHandlers({
    handleSelectRow: (props: Object) => (event: Object) => {
      const { selected, setSelected, importList } = props;

      const name = R.path(['currentTarget', 'name'], event);
      const checked = R.path(['currentTarget', 'checked'], event);

      if (R.equals(name, 'all')) {
        const selectedRows = R.map(() => checked, importList);

        return setSelected(selectedRows);
      }

      setSelected(R.assoc(name, checked, selected));
    },
    handleValidateList: (props: Object) => async () => {
      const { emptyRow, importList, changeImportList } = props;

      let list = importList;
      const schema = R.map(({ value, type, required }: Object) => {
        if (R.and(R.or(R.isEmpty(value), R.equals(type, 'STRING')), G.isFalse(required))) return stringNotRequired;

        if (R.and(R.includes(type, ['STRING', 'BOOLEAN']), G.isTrue(required))) return stringRequired;

        if (R.includes(type, ['DOUBLE', 'INTEGER'])) {
          if (G.isTrue(required)) return numberRequired;

          return numberNotRequired;
        }

        if (R.equals(type, 'DATE')) {
          if (G.isTrue(required)) return dateRequired;

          return dateNotRequired;
        }

        // TODO: add validation for DATE_TIME
        if (R.includes(type, ['DATE_TIME', 'ZONED_DATE_TIME'])) {
          if (G.isTrue(required)) return stringRequired;

          return stringNotRequired;
        }

        return stringNotRequired;
      }, emptyRow);
      const validationSchema = Yup.lazy(() => Yup.array().of(Yup.object().shape(schema)));
      const mapped = R.map(R.map(({ value }: Object) => value), importList);

      await validationSchema
        .validate(mapped, { abortEarly: false })
        .catch(({ inner }: Object) => {
          const pathMatcher = /[^\]\[.]+/g;
          inner.forEach(({ path, message }: Object) => {
            const pathToCell = R.match(pathMatcher, path);

            const failedCell = {
              ...R.pathOr({}, pathToCell, list),
              error: message,
              className: 'error',
            };

            list = R.assocPath(pathToCell, failedCell, list);
          });
        },
      );

      changeImportList(R.values(list));
    },
    handleRemoveRows: ({ selected, importList, setSelected, removeSelectedRows }: Object) => () => {
      if (G.isNilOrEmpty(importList)) return;

      const selectedRows = R.keys(R.filter(G.isTrue, selected));

      removeSelectedRows(selectedRows);
      setSelected(R.omit(selectedRows, selected));
    },
    handleChangeGrid: (props: Object) => (changes: Object) => {
      const { importList, changeTableCell, changeImportList } = props;

      if (R.equals(R.length(changes), 1)) {
        const { row, value, cell } = changes[0];

        const path = ['importList', row, R.prop(GC.FIELD_NAME, cell)];
        const newValue = G.ifElse(R.isEmpty(value), null, value);
        const newCell = R.mergeRight(
          cell,
          {
            error: null,
            className: 'success',
            [GC.FIELD_LABEL]: newValue,
            [GC.FIELD_VALUE]: newValue,
          },
        );

        changeTableCell({ path, cell: newCell });
      } else {
        let newList = importList;
        changes.forEach(({ row, value, cell }: Object) => {
          const cellName = R.prop(GC.FIELD_NAME, cell);
          const newValue = G.ifElse(R.isEmpty(value), null, value);
          const newCell = R.mergeRight(
            cell,
            {
              error: null,
              className: 'success',
              [GC.FIELD_LABEL]: newValue,
              [GC.FIELD_VALUE]: newValue,
            },
          );

          newList = R.assocPath([row, cellName], newCell, newList);
        });

        changeImportList(newList);
      }
    },
    handleClickCancel: ({ setInitialState }: Object) => () => setInitialState(),
    handleSaveImportList: (props: Object) => () => {
      const { importType, importList, saveImportListRequest, calculateImportCLOLocations } = props;

      if (R.equals(importType, C.IMPORT_TYPE_CLO)) {
        const dataToSave = R.map(R.compose(
          R.filter(G.isNotNilAndNotEmpty),
          R.map(R.prop(GC.FIELD_VALUE)),
        ), R.or(importList, []));
        const origin = makeLocationArray(dataToSave, GC.FIELD_ORIGIN);
        const destination = makeLocationArray(dataToSave, GC.FIELD_DESTINATION);
        const locationsToCalculate = [...origin, ...destination];

        return calculateImportCLOLocations({ dataToSave, locationsToCalculate });
      }

      return saveImportListRequest();
    },
  }),
  pure,
);

export default enhance(ImportTable);
