import * as R from 'ramda';
import { takeLatest, put, call } from 'redux-saga/effects';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature permission
import * as A from './actions';
//////////////////////////////////////////////////

export function* getPermissionsListSaga() {
  try {
    const res = yield call(sendRequest, 'get', endpointsMap.permissionsList);
    const { data, status } = res;
    if (R.equals(status, 200)) {
      yield put(A.receivedPermissionsSuccess(data));
      return true;
    }
    if (G.notEquals(data.error, GC.ACCESS_DENIED)) {
      throw Error('getPermissionsListSaga fail');
    }
  } catch (error) {
    return false;
  }
}

function* permissionsWatcherSaga() {
  yield takeLatest(A.getPermissionsRequest, getPermissionsListSaga);
}

export default permissionsWatcherSaga;
