import React from 'react';
import * as R from 'ramda';
import {
  pure,
  compose,
  lifecycle,
  withState,
  withHandlers,
} from 'react-recompose';
// components
import { SearchEmail } from '../../../components/search-email';
import { FormSectionHeader } from '../../../components/form-section-header';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature rate
import {
  dispatcherFields,
  getCarrierFleetFields,
  dispatcherInfoInitFields,
} from './settings';
//////////////////////////////////////////////////

const enhance = compose(
  withState('dispatcherInfo', 'setDispatcherInfo', ({ values }: Object) => R.pick(
    R.keys(dispatcherInfoInitFields),
    values,
  )),
  withHandlers({
    handleToggleDispatcherSection: (props: Object) => (expanded: boolean) => {
      const {
        values,
        setValues,
        dispatcherInfo,
        setDispatcherInfo,
      } = props;

      if (expanded) {
        const dispatcherKeys = R.keys(dispatcherInfoInitFields);

        setValues(R.omit(dispatcherKeys, values));

        setDispatcherInfo(R.pick(dispatcherKeys, values));
      } else {
        setValues(R.mergeRight(values, dispatcherInfo));
      }
    },
    handleSetInitialFleetTrailers: (props: Object) => async () => {
      const { values, useFleetTrailer, setSelectedFleetTrailer } = props;

      const value = R.pathOr(
        null,
        [GC.FIELD_PREFIX_CARRIER_ASSIGNMENT, GC.FIELD_FLEET_TRAILER_GUID],
        values,
      );

      if (R.or(G.isNilOrEmpty(value), G.isFalse(useFleetTrailer))) return;

      try {
        const { data, status } = await sendRequest('get', endpointsMap.getCurrentTrailerEndpoint(value));

        if (G.isResponseSuccess(status)) {
          setSelectedFleetTrailer({
            [GC.FIELD_VALUE]: R.prop(GC.FIELD_GUID, data),
            [GC.FIELD_LABEL]: R.prop(GC.FIELD_UNIT_ID, data),
          });
        }
      } catch (error) {
        G.handleException(error, 'handleSetInitialFleetTrailers');
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleSetInitialFleetTrailers();
    },
  }),
  pure,
);

const DispatcherSection = (props: Object) => {
  const {
    emails,
    isEditMode,
    branchGuid,
    fieldError,
    contactList,
    setFieldError,
    setFieldValue,
    setFieldTouched,
    useFleetTrailer,
    setIsValidEmails,
    searchEmailMargin,
    selectedFleetTrailer,
    setSelectedFleetTrailer,
    handleToggleDispatcherSection,
  } = props;

  return (
    <FormSectionHeader
      expanded={isEditMode}
      handleToggleSection={handleToggleDispatcherSection}
      title={G.getWindowLocale('titles:dispatch-info', 'Dispatch Info')}
    >
      <Box p={15} pt={0}>
        <Flex mb={15}>
          <Fieldset2
            {...G.getFormikProps(props)}
            fields={dispatcherFields}
          />
          <SearchEmail
            width={395}
            labelPl='5px'
            emails={emails}
            borderRadius='4px'
            labelFontSize={11}
            fieldError={fieldError}
            contactList={contactList}
            margin={searchEmailMargin}
            setFieldError={setFieldError}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            setIsValidEmails={setIsValidEmails}
            fieldName={GC.FIELD_DISPATCH_EMAILS}
            labelColor={G.getTheme('colors.darkGrey')}
            borderColor={G.getTheme('colors.veryLightGrey')}
            title={G.getWindowLocale('titles:dispatcher-emails', 'Dispatcher Emails')}
          />
        </Flex>
        <Fieldset2
          {...G.getFormikProps(props)}
          fields={getCarrierFleetFields({
            branchGuid,
            useFleetTrailer,
            selectedFleetTrailer,
            setSelectedFleetTrailer,
          })}
        />
      </Box>
    </FormSectionHeader>
  );
};

export default enhance(DispatcherSection);
