import React from 'react';
import * as R from 'ramda';
// components
import TextComponent from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import routesMap from '../../../utilities/routes';
// ui
import { Flex } from '../../../ui/box';
// ////////////////////////////////////////////

const ClosListBox = ({ data }: Object) => {
  const handleGoToCloDetaild = (guid: string) => {
    G.goToRoute(routesMap.dispatchDetailsOrder(guid));
  };
  return (
    <Flex width='100%' display='inline-flex' flexWrap='wrap'>
      {
        R.pathOr([], ['clos'], data).map((text: object, i: number, list: object) => (
          <TextComponent
            key={i}
            mr='5px'
            display='block'
            maxWidth='100%'
            withEllipsis={true}
            color={G.getTheme('colors.dark.blue')}
            onClick={() => handleGoToCloDetaild(R.prop(GC.FIELD_GUID, text))}
            title={G.getReportColumnPropTextFromData(GC.FIELD_PRIMARY_REFERENCE_VALUE, text)}
          >
            {G.getReportColumnPropTextFromData(GC.FIELD_PRIMARY_REFERENCE_VALUE, text)}
            {G.ifElse(R.equals(R.length(list), R.add(i, 1)), null, ',')}
          </TextComponent>
        ))
      }
    </Flex>
  );
};

export default ClosListBox;
