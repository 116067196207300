// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const FILTER_PARAMS = [
  {
    type: 'string',
    value: GC.FIELD_STATUS,
    name: G.getWindowLocale('titles:status', 'Status'),
  },
  {
    type: 'string',
    value: GC.FIELD_EDI_EXTERNAL_ID,
    name: G.getWindowLocale('titles:external-id', 'External ID'),
  },
  {
    type: 'date',
    value: GC.FIELD_EDI_RECEIVED_DATE,
    name: G.getWindowLocale('titles:received-date', 'Received Date'),
  },
  {
    type: 'string',
    name: G.getWindowLocale('titles:clo', 'CLO'),
    value: GC.GRC.EDI_CLO_PRIMARY_REFERENCE_VALUE,
  },
  {
    type: 'string',
    value: GC.GRC.EDI_CLO_STATUS,
    name: G.getWindowLocale('titles:clo-status', 'CLO Status'),
  },
  {
    type: 'string',
    value: GC.GRC.EDI_CREATED_CLO_PRIMARY_REFERENCE_VALUE,
    name: G.getWindowLocale('titles:created-order', 'Created Order'),
  },
  {
    type: 'string',
    value: GC.GRC.EDI_CREATED_CLO_STATUS,
    name: `${G.getWindowLocale('titles:created-order', 'Created Order')}: ${G.getWindowLocale('titles:status', 'Status')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_LOCATION_NAME,
    name: G.getWindowLocale('titles:bill-to', 'Bill To'),
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_LOCATION_TYPE_DISPLAYED,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:type', 'Type')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_COUNTRY,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_STATE,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_CITY,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_ADDRESS1,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_ZIP,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_PAYMENT_TERM,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:payment-terms', 'Payment Terms')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_CONTACT_NAME,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:contact-name', 'titles:contact-name')}`,
  },
  {
    collection: true,
    type: 'string:array',
    value: GC.GRC.CLO_BILL_TO_EMAILS,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:emails', 'titles:emails')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_PHONE,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:phone', 'titles:phone')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_COMMENTS,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:comments', 'titles:comments')}`,
  },
];
