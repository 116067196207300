import React, {Fragment} from 'react';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
// feature profile
import UserRolesComponent from './user-roles';
import UserReferences from './user-references';
//////////////////////////////////////////////////

const GeneralTab = (props: Object) => (
  <Fragment>
    <AuthWrapper has={[PC.USER_WRITE]}>
      <UserRolesComponent {...props} />
    </AuthWrapper>
    <AuthWrapper has={[PC.USER_REFERENCES_WRITE]}>
      <UserReferences {...props} />
    </AuthWrapper>
  </Fragment>
);

export default GeneralTab;
