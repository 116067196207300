import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
// feature new-do
import DropForm from './drop-form';
import PickupForm from './pickup-form';
import {
  getItemsForDropOptions,
  getContainersForDropOptions,
  getContainersForPickupItemOptions,
} from '../helpers';
//////////////////////////////////////////////////

const renderForm = (props: Object) => {
  const { stops, leftActiveTad, branchRefTypes } = props;

  const stop = stops[leftActiveTad];
  const referenceTypes = G.mapNameGuidObjectPropsToLabelValueObject(
    R.pathOr([], [GC.REF_SCOPE_NAME_TEL], branchRefTypes),
  );

  if (G.isNotNilAndNotEmpty(stop)) {
    const { id, order, formData, eventType } = stop;

    if (R.equals(eventType, GC.EVENT_TYPE_PICKUP)) {
      const dropContainerOptions = getContainersForDropOptions(stops, stop);
      const itemContainerOptions = G.addEmptyOptionToDropDown(getContainersForPickupItemOptions(stops, stop));

      return (
        <PickupForm
          {...props}
          stop={stop}
          stopId={id}
          stopOrder={order}
          eventType={eventType}
          initialValues={formData}
          referenceTypes={referenceTypes}
          dropContainerOptions={dropContainerOptions}
          itemContainerOptions={itemContainerOptions}
        />
      );
    }
    const items = getItemsForDropOptions(stops, formData.items);
    const dropContainerOptions = getContainersForDropOptions(stops, stop);

    return (
      <DropForm
        {...props}
        stop={stop}
        stopId={id}
        items={items}
        stopOrder={order}
        eventType={eventType}
        initialValues={formData}
        referenceTypes={referenceTypes}
        dropContainerOptions={dropContainerOptions}
      />
    );
  }

  return null;
};

const LeftSection = (props: Object) => (
  <Box
    mb={20}
    mr={25}
    width='662px'
    flexShrink={0}
    borderRadius='4px'
    bg={G.getTheme('colors.white')}
  >
    {renderForm(props)}
  </Box>
);

export default LeftSection;
