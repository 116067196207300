// helpers/constants
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const rowHeight = 30;
export const tableMaxHeight = '200px';

export const driversListReport = {
  fields: [
    { name: GC.FIELD_FLEET_LOGIN_ID, sequence: 1 },
    { name: GC.FIELD_FLEET_FIRST_NAME, sequence: 2 },
    { name: GC.FIELD_FLEET_LAST_NAME, sequence: 3 },
    { name: GC.FIELD_FLEET_EMAIL, sequence: 4 },
    { name: GC.FIELD_FLEET_DRIVER_NUMBER, sequence: 6 },
    { name: GC.FIELD_FLEET_PRIMARY_PHONE_NUMBER, sequence: 5 },
  ],
};

export const vendorsListReport = {
  fields: [
    { name: GC.FIELD_NAME, sequence: 1 },
    { name: GC.FIELD_OWNER_NAME, sequence: 2 },
    { name: GC.FIELD_BRANCH_DOT_BRANCH_NAME, sequence: 3 },
  ],
};

export const tableSettings = {
  useMainColors: true,
  allowSelectAll: true,
  checkBoxCellWidth: 40,
  maxHeight: tableMaxHeight,
  titleRowHeight: rowHeight,
  tableRowHeight: rowHeight,
};

export const columnSettings = {
  [GC.FIELD_FLEET_LOGIN_ID]: {
    width: 200,
    name: 'titles:driver-id',
  },
  [GC.FIELD_FLEET_FIRST_NAME]: {
    width: 150,
    name: 'titles:first-name',
  },
  [GC.FIELD_FLEET_LAST_NAME]: {
    width: 150,
    name: 'titles:last-name',
  },
  [GC.FIELD_FLEET_EMAIL]: {
    width: 150,
    name: 'titles:email',
  },
  [GC.FIELD_FLEET_PRIMARY_PHONE_NUMBER]: {
    width: 150,
    name: 'titles:phone-number',
  },
  [GC.FIELD_FLEET_DRIVER_NUMBER]: {
    width: 200,
    name: 'titles:driver-number',
  },
};
