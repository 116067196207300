import React from 'react';
import * as R from 'ramda';
// features
import { orderFilterProps } from '../../dispatch-report/settings/filter-params';
import { orderColumnSettings } from '../../dispatch-report/settings/column-settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { getOrderQuoteStatusTypeOptions } from '../../../helpers/options';
// feature quotes
import ClosListBox from '../components/clos-list';
import FreezedRowActions from '../components/freezed-row-actions';
//////////////////////////////////////////////////

export const rowHeight = 30;
export const tableMaxHeight = 'calc(100vh - 145px)';

export const quotesReport = {
  fields: [
    { name: GC.FIELD_STATUS, sequence: 1 },
    { name: GC.FIELD_CLOS, sequence: 2 },
    { name: GC.FIELD_QUOTES_REQUEST_NUMBER, sequence: 3 },
    { name: GC.FIELD_QUOTES_REQUEST_DATE, sequence: 4 },
    { name: GC.FIELD_TOTAL, sequence: 5 },
    { name: GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS, sequence: 6 },
    { name: GC.FIELD_PHONE, sequence: 7 },
    { name: GC.FIELD_EMAIL, sequence: 8 },
    { name: GC.FIELD_CONTACT_NAME, sequence: 9 },
  ],
};

export const tableSettings = {
  rowHeight: 55,
  cellFontSize: 13,
  titleFontSize: 12,
  titleRowHeight: 45,
  tableRowHeight: 40,
  allowEditBtn: true,
  useMainColors: true,
  moreBtnCellWidth: 50,
  allowSelectAll: true,
  checkBoxCellWidth: 90,
  searchableTitles: true,
  expandableItems: false,
  maxHeight: 'calc(100vh - 145px)',
  checkBoxCellJustifyContent: 'unset',
};

export const quotesColumnSettings = {
  [GC.FIELD_STATUS]: {
    width: 150,
    name: 'titles:status',
  },
  [GC.FIELD_CLOS]: {
    width: 350,
    name: 'titles:clo-primary-reference-value',
    customComponent: ({ data }: Object) => <ClosListBox data={data} />,
  },
  [GC.FIELD_QUOTES_REQUEST_DATE]: {
    width: 200,
    name: 'titles:request-date',
  },
  [GC.FIELD_QUOTES_REQUEST_NUMBER]: {
    width: 200,
    name: 'titles:quote-number',
  },
  [GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS]: {
    width: 150,
    name: 'titles:special-instructions',
  },
  [GC.FIELD_PHONE]: {
    width: 150,
    name: 'titles:phone',
  },
  [GC.FIELD_EMAIL]: {
    width: 150,
    name: 'titles:email',
  },
  [GC.FIELD_CONTACT_NAME]: {
    width: 150,
    name: 'titles:contact-name',
  },
};

export const orderQuotesColumnSettings = {
  actions: {
    width: 150,
    freezed: true,
    name: 'actions',
    customComponent: ({ data }: Object) => <FreezedRowActions entity={data} />,
  },
  [GC.FIELD_ORDER_QUOTE_NUMBER]: {
    width: 200,
    searchable: true,
    name: 'titles:quote-number',
  },
  [GC.FIELD_ACCEPTED_BY]: {
    width: 200,
    searchable: true,
    name: 'titles:accepted-by',
  },
  [GC.FIELD_ACCEPTED_DATE]: {
    width: 200,
    type: 'date',
    searchable: true,
    name: 'titles:accepted-date',
  },
  [GC.ACCEPTED_BY_PHONE_NUMBER]: {
    width: 200,
    searchable: true,
    name: ['titles:accepted-by', 'titles:phone-number'],
  },
  [`${GC.FIELD_DECLINE_REASON_CODE}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 200,
    searchable: true,
    name: 'titles:decline-reason',
  },
  [`${GC.FIELD_CANCEL_REASON_CODE}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 200,
    searchable: true,
    name: 'titles:cancel-reason',
  },
  // TODO: check fields
  // ...orderColumnSettings,
  [GC.FIELD_STATUS]: {
    width: 200,
    name: 'titles:status',
    customComponent: R.compose(
      R.join(' '),
      R.map(G.toTitleCase),
      R.split('_'),
      R.pathOr('', ['data', GC.FIELD_STATUS]),
    ),
  },
  [GC.GRC.TEL_CARRIER_RATE_CARRIER_NAME]: {
    width: 200,
    searchable: true,
    name: 'titles:carrier-name',
  },
  [GC.GRC.FIRST_EVENT_LOCATION_NAME]: {
    width: 250,
    searchable: true,
    name: ['titles:first-event', 'titles:location-name'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_TEMPLATE_ID]: {
    width: 250,
    searchable: true,
    name: ['titles:first-event', 'titles:template-id'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_ADDRESS1]: {
    width: 250,
    searchable: true,
    name: ['titles:first-event', 'titles:address1'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_ADDRESS2]: {
    width: 250,
    searchable: true,
    name: ['titles:first-event', 'titles:address2'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_CITY]: {
    width: 250,
    searchable: true,
    name: ['titles:first-event', 'titles:city'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_STATE]: {
    width: 250,
    searchable: true,
    name: ['titles:first-event', 'titles:state'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_COUNTRY]: {
    width: 250,
    searchable: true,
    name: ['titles:first-event', 'titles:country'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_ZIP]: {
    width: 250,
    searchable: true,
    name: ['titles:first-event', 'titles:zip'],
  },
  [GC.GRC.FIRST_EVENT_COMPLETE_DATE]: {
    width: 250,
    type: 'date',
    searchable: true,
    name: ['titles:first-event', 'titles:complete-date'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_TEMPLATE_ID]: {
    width: 250,
    searchable: true,
    name: ['titles:last-event', 'titles:template-id'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_NAME]: {
    width: 250,
    searchable: true,
    name: ['titles:last-event', 'titles:location-name'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_ADDRESS1]: {
    width: 250,
    searchable: true,
    name: ['titles:last-event', 'titles:address1'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_ADDRESS2]: {
    width: 250,
    searchable: true,
    name: ['titles:last-event', 'titles:address2'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_CITY]: {
    width: 250,
    searchable: true,
    name: ['titles:last-event', 'titles:city'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_STATE]: {
    width: 250,
    searchable: true,
    name: ['titles:last-event', 'titles:state'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_COUNTRY]: {
    width: 250,
    searchable: true,
    name: ['titles:last-event', 'titles:country'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_ZIP]: {
    width: 250,
    searchable: true,
    name: ['titles:last-event', 'titles:zip'],
  },
  [GC.GRC.LAST_EVENT_COMPLETE_DATE]: {
    width: 250,
    type: 'date',
    searchable: true,
    name: ['titles:last-event', 'titles:complete-date'],
  },
  [GC.GRC.FIRST_EVENT_EVENT_EARLY_DATE]: {
    width: 250,
    type: 'date',
    searchable: true,
    name: ['titles:first-event', 'titles:early-date'],
  },
  [GC.GRC.FIRST_EVENT_EVENT_LATE_DATE]: {
    width: 250,
    type: 'date',
    searchable: true,
    name: ['titles:first-event', 'titles:late-date'],
  },
  [GC.GRC.LAST_EVENT_EVENT_EARLY_DATE]: {
    width: 250,
    type: 'date',
    searchable: true,
    name: ['titles:last-event', 'titles:early-date'],
  },
  [GC.GRC.LAST_EVENT_EVENT_LATE_DATE]: {
    width: 250,
    type: 'date',
    searchable: true,
    name: ['titles:last-event', 'titles:late-date'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_COMMENTS]: {
    width: 200,
    searchable: true,
    name: ['titles:first-event', 'titles:comments'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_COMMENTS]: {
    width: 200,
    searchable: true,
    name: ['titles:first-event', 'titles:comments'],
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    type: 'date',
    name: 'titles:created-date',
  },
};

const orderQuoteUniqFilterProps = [
  {
    type: 'selectMultiple',
    value: GC.FIELD_STATUS,
    options: getOrderQuoteStatusTypeOptions(),
    name: G.getWindowLocale('titles:status', 'Status'),
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'string',
    value: GC.FIELD_ORDER_QUOTE_NUMBER,
    name: G.getWindowLocale('titles:quote-number', 'Quote Number'),
  },
  {
    type: 'string',
    value: GC.FIELD_ACCEPTED_BY,
    name: G.getWindowLocale('titles:accepted-by', 'Accepted By'),
  },
  {
    type: 'date',
    value: GC.FIELD_ACCEPTED_DATE,
    name: G.getWindowLocale('titles:accepted-date', 'Accepted Date'),
  },
  {
    type: 'string',
    value: GC.ACCEPTED_BY_PHONE_NUMBER,
    name: `${G.getWindowLocale('titles:accepted-by', 'Accepted By')}: ${
      G.getWindowLocale('titles:phone-number', 'Phone Number')}`,
  },
  {
    type: 'string',
    name: G.getWindowLocale('titles:decline-reason', 'Decline Reason'),
    value: `${GC.FIELD_DECLINE_REASON_CODE}.${GC.FIELD_DISPLAYED_VALUE}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_CARRIER_RATE_CARRIER_NAME,
    name: G.getWindowLocale('titles:carrier-name', 'Carrier Name'),
  },
  {
    type: 'string',
    name: G.getWindowLocale('titles:cancel-reason', 'Cancel Reason'),
    value: `${GC.FIELD_CANCEL_REASON_CODE}.${GC.FIELD_DISPLAYED_VALUE}`,
  },
  {
    type: 'string',
    value: GC.GRC.FIRST_EVENT_LOCATION_TEMPLATE_ID,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:template-id', 'Template ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.FIRST_EVENT_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.FIRST_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: GC.GRC.FIRST_EVENT_LOCATION_ADDRESS2,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:address2', 'Address2')}`,
  },
  {
    type: 'string',
    value: GC.GRC.FIRST_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.GRC.FIRST_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: GC.GRC.FIRST_EVENT_LOCATION_COUNTRY,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: GC.GRC.FIRST_EVENT_LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'string',
    value: GC.GRC.FIRST_EVENT_LOCATION_COMMENTS,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:comments', 'Comments')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_EVENT_LOCATION_TEMPLATE_ID,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:template-id', 'Template ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_EVENT_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_EVENT_LOCATION_ADDRESS2,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:address2', 'Address2')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('ctitles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_EVENT_LOCATION_COUNTRY,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_EVENT_LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_EVENT_LOCATION_COMMENTS,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:comments', 'Comments')}`,
  },
  {
    type: 'date',
    value: GC.GRC.FIRST_EVENT_EVENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.FIRST_EVENT_EVENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.LAST_EVENT_EVENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.LAST_EVENT_EVENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_CREATED_BY,
    name: G.getWindowLocale('titles:created-by', 'Created By'),
  },
  {
    type: 'date',
    value: GC.FIELD_CREATED_DATE,
    name: G.getWindowLocale('titles:created-date', 'Created Date'),
  },
];

// TODO: check fields
// export const ORDER_QUOTES_FILTER_PARAMS = R.compose(
//   R.concat(orderQuoteUniqFilterProps),
//   R.filter(({ value }: Object) => G.notEquals(value, GC.FIELD_STATUS)),
// )(orderFilterProps);

export const ORDER_QUOTES_FILTER_PARAMS = orderQuoteUniqFilterProps;
