import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature new-do
import {
  getStopItemsInfo,
  getStopLocationIcon,
  getStopDistanceInfo,
  getStopTitleFromFormData } from '../helpers';
//////////////////////////////////////////////////

const StopLocation = ({ stop }: Object) => (
  <Flex mb='5px' flexWrap='wrap'>
    {getStopLocationIcon(stop)}
    <Box pl='5px'>{getStopTitleFromFormData(stop)}</Box>
  </Flex>
);

const StopItemDate = ({ stop, stopsData }: Object) => {
  const datesInfo = G.getStopDatesInfo(
    [GC.FIELD_LOAD_EVENT_EARLY_DATE, GC.FIELD_LOAD_EVENT_LATE_DATE],
    stop.formData,
    ' - ',
  );
  const itemsInfo = getStopItemsInfo(stop, stopsData);
  return (
    <Flex mb='5px' flexWrap='wrap'>
      {
        datesInfo &&
        <Flex pr={10}>
          {I.calendarBig2()}
          <Box pl='5px'>{datesInfo}</Box>
        </Flex>
      }
      {
        itemsInfo &&
        <Flex>
          {I.itemBox()}
          <Box pl='5px'>{itemsInfo}</Box>
        </Flex>
      }
    </Flex>
  );
};

const StopData = (props: Object) => {
  const { stop, apptDate, apptNumber, stopNumberInfo } = props;
  const titleGreyColor = G.getTheme('colors.titleGrey');

  return (
    <Flex mb='5px' flexWrap='wrap'>
      {
        apptNumber &&
        <Box
          pr={10}
          wordBreak='break-all'
          color={titleGreyColor}
        >
          {`${G.getWindowLocale('titles:appointment-number', 'Appointment Number')}: ${apptNumber}`}
        </Box>
      }
      {
        apptDate &&
        <Box
          pr={10}
          flexShrink={0}
          color={titleGreyColor}
        >
          {`${G.getWindowLocale('titles:appointment-date', 'Appointment Date')}: ${apptDate}`}
        </Box>
      }
      {
        stopNumberInfo &&
        <Box
          flexShrink={0}
          color={titleGreyColor}
        >
          {`${G.getStopNumberTitle(stop)}: ${stopNumberInfo}`}
        </Box>
      }
    </Flex>
  );
};

const NewDOStopData = ({ stop }: Object) => {
  const stopNumberInfo = G.getStopFieldsInfo(
    [GC.FIELD_STOP_NUMBER],
    stop.formData,
  );
  const apptNumber = G.getStopFieldsInfo(
    [GC.FIELD_LOAD_APPOINTMENT_NUMBER],
    stop.formData,
  );
  const apptDate = G.getStopAppointmentDateTimeInfo(
    [
      GC.FIELD_LOAD_APPOINTMENT_DATE,
      GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME,
      GC.FIELD_LOAD_APPOINTMENT_LATE_TIME,
    ],
    stop.formData,
    ', ',
  );

  return (
    <StopData
      stop={stop}
      apptDate={apptDate}
      apptNumber={apptNumber}
      stopNumberInfo={stopNumberInfo} />
  );
};

const DistanceToNextStop = ({ stop, loadType }: Object) => {
  const distanceObject = R.prop(G.getDistancePropByLoadType(loadType), stop);
  if (R.isNil(distanceObject)) {
    return null;
  }
  const manualDistance = R.prop(GC.FIELD_DISTANCE_MANUAL, distanceObject);
  const distanceInfo = R.trim(getStopDistanceInfo(manualDistance, distanceObject));
  if (G.isNilOrEmpty(distanceInfo)) return null;
  return (
    <Box>
      {`
        ${G.getWindowLocale('titles:distance-to-next-stop', 'Distance to next stop')}:
        ${distanceInfo}
      `}
    </Box>
  );
};

const SummaryStop = ({ stop, loadType, stopsData }: Object) => (
  <Box
    my='5px'
    p='10px'
    stop={stop}
    borderRadius='4px'
    boxShadow='0 0 8px 0 rgba(0, 0, 0, 0.2)'
  >
    <Flex flexDirection='column' alignItems='flex-start'>
      <StopLocation stop={stop} />
      <StopItemDate stop={stop} stopsData={stopsData} />
      <NewDOStopData stop={stop} />
      <DistanceToNextStop stop={stop} loadType={loadType} />
    </Flex>
  </Box>
);

export default SummaryStop;
