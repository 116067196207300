import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { ENUMS } from '../../../constants/enums';
// ui
import { Box } from '../../../ui';
// feature new-do
import { enhanceStopForm } from '../hocs';
import FormHeader from '../forms/form-header';
import { FORM_BOX_SHADOW } from '../constants';
import { StopsFormTab } from '../forms/form-tab';
import { Fieldset } from '../forms/formik/fieldset';
import ContactsArray from '../forms/contacts-array';
import ContainersArray from '../forms/containers-array';
import SectionDivider from '../components/section-divider';
import { getValidationSchemaDropFormLazy } from '../validation';
import {
  getStopTitle,
  isPageLoadStopsEdit,
  getStopHeaderActionOptions,
} from '../helpers';
import {
  mainStopFields,
  apptsStopFields,
  addressStopFields,
  dropItemStopFields,
  dropInfoStopFields,
  trailersStopFields,
  dropContainerStopFields,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const DropForm = (props: Object) => {
  const {
    stop,
    values,
    pageType,
    openModal,
    setValues,
    closeModal,
    branchGuid,
    setFieldValue,
    handleLeaveForm,
    handleGetLoadOptions,
    handleChangeApptTime,
  } = props;

  const branchConfigs = G.getItemFromWindow('amousNewDoBranchConfigs');
  const useContainers = G.getConfigValueFromStore(GC.CLO_GENERAL_USE_CONTAINERS, branchConfigs);
  const manageTrailersFrom = G.getConfigValueFromStore(GC.TRAILER_MANAGE_TRAILERS_FROM, branchConfigs);
  const useStopTrailers = R.equals(manageTrailersFrom, ENUMS.ENUM_EVENTS);

  return (
    <form onMouseLeave={handleLeaveForm}>
      { isPageLoadStopsEdit(pageType) && <StopsFormTab {...props} /> }
      <Box pb={20} boxShadow={FORM_BOX_SHADOW}>
        <FormHeader
          values={values}
          setValues={setValues}
          openModal={openModal}
          closeModal={closeModal}
          setFieldValue={setFieldValue}
          options={getStopHeaderActionOptions(props)}
          currentBranch={{ [GC.FIELD_GUID]: branchGuid }}
        >
          {getStopTitle(stop, values)}
        </FormHeader>
        <Box pt={20}>
          <Fieldset
            {...props}
            fields={mainStopFields}
            getLoadOptions={handleGetLoadOptions}
          />
          <SectionDivider
            text={G.getWindowLocale('titles:address', 'Address', { caseAction: 'upperCase' })}
          />
          <Fieldset
            {...props}
            fields={addressStopFields}
          />
          <SectionDivider
            text={G.getWindowLocale('titles:drop-info', 'Drop Info', { caseAction: 'upperCase' })}
          />
          <Fieldset
            {...props}
            fields={dropInfoStopFields}
          />
          <SectionDivider
            text={G.getWindowLocale('titles:appointment-info', 'Appointment Info', { caseAction: 'upperCase' })}
          />
          <Fieldset
            {...props}
            fields={apptsStopFields}
            handleChangeInput={handleChangeApptTime}
          />
          <ContactsArray contacts={R.pathOr([], [GC.FIELD_CONTACTS], values)} />
          {
            G.isTrue(useContainers) &&
            <SectionDivider
              text={G.getWindowLocale('titles:drop-containers', 'Drop Containers', { caseAction: 'upperCase' })}
            />
          }
          {
            G.isTrue(useContainers) &&
            <Fieldset
              {...props}
              fields={dropContainerStopFields}
            />
          }
          { G.isTrue(useContainers) && <ContainersArray {...props} /> }
          {
            G.isTrue(useStopTrailers) &&
            <SectionDivider
              text={G.getWindowLocale('titles:trailers', 'Trailers', { caseAction: 'upperCase' })}
            />
          }
          {
            G.isTrue(useStopTrailers) &&
            <Fieldset
              {...props}
              fields={trailersStopFields}
            />
          }
          <SectionDivider
            text={G.getWindowLocale('titles:drop-items', 'Drop Items', { caseAction: 'upperCase' })}
          />
          <Fieldset
            {...props}
            fields={dropItemStopFields}
          />
        </Box>
      </Box>
    </form>
  );
};

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: () => getValidationSchemaDropFormLazy(),
    mapPropsToValues: ({ initialValues }: Object) => G.setEventInitialDroppedContainers(
      G.setEventEarlyLateTimeFromEarlyLateDate(initialValues),
    ),
    displayName: 'DropForm',
  }),
  enhanceStopForm,
  pure,
);

export default enhance(DropForm);
