import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose } from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// components
import { FormFooter2 } from '../../../../components/form-footer';
import { LocalLoader } from '../../../../components/local-loader';
// forms
import { Form } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withAsyncLoadReferences } from '../../../../hocs';
// ui
import { Box, Flex } from '../../../../ui';
// feature reference
import ReferencesArray from './references-array';
import { updateLoadReferencesRequest } from '../../actions';
import { defaultValues, validationSchema } from './settings';
import {
  makeSelectAllAvailableTelReferenceTypes,
  makeSelectAllAvailableCloReferenceTypes } from '../../selectors';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  cloRefTypes: makeSelectAllAvailableCloReferenceTypes(state),
  telRefTypes: makeSelectAllAvailableTelReferenceTypes(state),
});

const enhance = compose(
  connect(mapStateToProps, { updateLoadReferencesRequest }),
  withFormik({
    validationSchema,
    enableReinitialize: true,
    displayName: 'LOAD_REFERENCES_FORM',
    handleSubmit: (values: Object, { props }: Object) => {
      const { loadType, loadGuid, fromPage, submitAction, asyncReferences, updateLoadReferencesRequest } = props;

      if (G.isFunction(submitAction)) return submitAction(values);

      const refGuidsFromValues = R.compose(
        R.filter(G.isNotNil),
        R.map(G.getGuidFromObject),
        R.prop(GC.FIELD_REFERENCES),
      )(values);
      const deletedGuids = R.compose(
        R.values,
        R.map(G.getGuidFromObject),
        R.omit(refGuidsFromValues),
        G.indexByGuid,
      )(asyncReferences);
      updateLoadReferencesRequest({ loadType, fromPage, data: { ...values, loadGuid, deletedGuids } });
    },
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultValues,
      props.initialValues,
    ),
  }),
  pure,
);

export const LoadReferencesForm = enhance((props: Object) => {
  const { loadType, cloRefTypes, telRefTypes, handleSubmit } = props;
  const scopeRefTypes = G.ifElse(G.isLoadTypeClo(loadType), cloRefTypes, telRefTypes);
  const referenceTypes = G.getOptionsFromDataArrayByPath([GC.FIELD_NAME], [GC.FIELD_GUID], scopeRefTypes);

  return (
    <Form width='100%' onSubmit={handleSubmit}>
      <ReferencesArray {...props} scopeRefTypes={R.or(scopeRefTypes, [])} referenceTypes={referenceTypes} />
      <FormFooter2
        closeModal={props.closeModal}
        boxStyles={{ py: 15, justifyContent: 'space-around' }}
      />
    </Form>
  );
});

const ReferencesInfo = ({ list }: Object) => (
  <Box p={15} width='100%'>
    {
      list.map((ref: Object, i: number) => (
        <Flex key={i} my='4px'>
          <Box mr='5px' fontWeight='bold'>
            {R.prop(GC.FIELD_NAME, ref)}:
          </Box>
          {R.prop(GC.FIELD_VALUE, ref)}
        </Flex>
      ))
    }
  </Box>
);

export const AsyncLoadReferencesForm = withAsyncLoadReferences((props: Object) => {
  const { writePermission, asyncReferences, asyncRefLoading } = props;
  const initialValues = { [GC.FIELD_LOAD_REFERENCES]: asyncReferences };

  return (
    <LocalLoader width={550} localLoaderOpen={asyncRefLoading}>
      {R.not(writePermission) && <ReferencesInfo list={R.or(asyncReferences, [])} />}
      {writePermission && <LoadReferencesForm {...props} initialValues={initialValues} />}
    </LocalLoader>
  );
});
