import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// feature public-clo
import RequestErrors from '../components/request-errors';
//////////////////////////////////////////////////

export const columnSettings = {
  requestErrors: {
    name: '',
    width: 70,
    customComponent: ({ data }: Object) => <RequestErrors {...data} />,
  },
  [GC.FIELD_STATUS]: {
    width: 150,
    name: 'titles:status',
  },
  [GC.FIELD_EDI_PROCESSED]: {
    width: 150,
    name: 'titles:processed',
    customComponent: ({ data }: Object) => G.ifElse(
      R.prop(GC.FIELD_EDI_PROCESSED, data),
      I.uiTrue,
      I.uiFalse,
    )(),
  },
  [GC.FIELD_EDI_SENDER_ID]: {
    width: 150,
    name: 'titles:sender-id',
  },
  [GC.FIELD_EDI_EXTERNAL_ID]: {
    width: 150,
    name: 'titles:external-id',
  },
  [GC.FIELD_EDI_ORIGINAL_FILE_NAME]: {
    width: 250,
    name: 'titles:file-name',
  },
  [GC.FIELD_EDI_RECEIVED_DATE]: {
    width: 200,
    name: 'titles:received-date',
  },
  [GC.FIELD_EDI_TRANSACTION_DATE]: {
    width: 200,
    name: 'titles:transaction-date',
  },
  [GC.GRC.EDI_CLO_PRIMARY_REFERENCE_VALUE]: {
    width: 200,
    name: 'titles:clo',
  },
  [GC.GRC.EDI_CLO_TRANSACTION_ACTION]: {
    width: 150,
    name: 'titles:transaction-action',
  },
  [GC.GRC.EDI_CLO_STATUS]: {
    width: 150,
    name: 'titles:clo-status',
  },
  [GC.GRC.EDI_CREATED_CLO_PRIMARY_REFERENCE_VALUE]: {
    width: 200,
    name: 'titles:created-order',
    type: GC.PAGE_DISPATCH_DETAILS_NEW_ORDER,
    guidPropName: GC.GRC.EDI_CREATED_CLO_GUID,
  },
  [GC.GRC.EDI_CREATED_CLO_STATUS]: {
    width: 200,
    name: ['titles:created-order', 'titles:status'],
  },
  [GC.GRC.CLO_BILL_TO_LOCATION_NAME]: {
    width: 200,
    name: 'titles:bill-to',
  },
  [GC.GRC.CLO_BILL_TO_LOCATION_TYPE_DISPLAYED]: {
    width: 200,
    name: ['titles:bill-to', 'titles:type'],
  },
  [GC.GRC.CLO_BILL_TO_COUNTRY]: {
    width: 200,
    name: ['titles:bill-to', 'titles:country'],
  },
  [GC.GRC.CLO_BILL_TO_STATE]: {
    width: 200,
    name: ['titles:bill-to', 'titles:state'],
  },
  [GC.GRC.CLO_BILL_TO_CITY]: {
    width: 200,
    name: ['titles:bill-to', 'titles:city'],
  },
  [GC.GRC.CLO_BILL_TO_ADDRESS1]: {
    width: 200,
    name: ['titles:bill-to', 'titles:address1'],
  },
  [GC.GRC.CLO_BILL_TO_ZIP]: {
    width: 200,
    name: ['titles:bill-to', 'titles:zip'],
  },
  [GC.GRC.CLO_BILL_TO_COMMENTS]: {
    width: 200,
    name: ['titles:bill-to', 'titles:comments'],
  },
  [GC.GRC.CLO_BILL_TO_PAYMENT_TERM]: {
    width: 200,
    name: ['titles:bill-to', 'titles:payment-terms'],
  },
  [GC.GRC.CLO_BILL_TO_CONTACT_NAME]: {
    width: 200,
    name: ['titles:bill-to', 'titles:contact-name'],
  },
  [GC.GRC.CLO_BILL_TO_EMAILS]: {
    width: 200,
    name: ['titles:bill-to', 'titles:emails'],
  },
  [GC.GRC.CLO_BILL_TO_PHONE]: {
    width: 200,
    name: ['titles:bill-to', 'titles:phone'],
  },
  [GC.GRC.CLO_BILL_TO_COMMENTS]: {
    width: 200,
    name: ['titles:bill-to', 'titles:comments'],
  },
};
