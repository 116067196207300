import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setInitialState = createAction('setInitialState');
export const pinBranchSuccess = createAction('pinBranchSuccess');
export const getUserSettingsFail = createAction('getUserSettingsFail');
export const sendPinBranchRequest = createAction('sendPinBranchRequest');
export const getUserReferencesFail = createAction('getUserReferencesFail');
export const getUserSettingsRequest = createAction('getUserSettingsRequest');
export const getUserSettingsSuccess = createAction('getUserSettingsSuccess');
export const getUserProfileRolesFail = createAction('getUserProfileRolesFail');
export const getUserReferencesRequest = createAction('getUserReferencesRequest');
export const getUserReferencesSuccess = createAction('getUserReferencesSuccess');
export const updateUserSettingsRequest = createAction('updateUserSettingsRequest');
export const changeUserPasswordRequest = createAction('changeUserPasswordRequest');
export const getUserProfileRolesSuccess = createAction('getUserProfileRolesSuccess');
export const createUserReferenceRequest = createAction('createUserReferenceRequest');
export const createUserReferenceSuccess = createAction('createUserReferenceSuccess');
export const getCurrentUserSettingsFail = createAction('getCurrentUserSettingsFail');
export const updateUserReferenceRequest = createAction('updateUserReferenceRequest');
export const updateUserReferenceSuccess = createAction('updateUserReferenceSuccess');
export const deleteUserReferenceRequest = createAction('deleteUserReferenceRequest');
export const deleteUserReferenceSuccess = createAction('deleteUserReferenceSuccess');
export const changePinnedMenuItemsRequest = createAction('changePinnedMenuItemsRequest');
export const changePinnedMenuItemsSuccess = createAction('changePinnedMenuItemsSuccess');
export const getCurrentUserSettingsSuccess = createAction('getCurrentUserSettingsSuccess');
export const getCurrentUserSettingsRequest = createAction('getCurrentUserSettingsRequest');
export const assignUserProfileRolesRequest = createAction('assignUserProfileRolesRequest');
export const revokeUserProfileRolesRequest = createAction('revokeUserProfileRolesRequest');
export const setCurrentUserProfilePhotoUrl = createAction('setCurrentUserProfilePhotoUrl');
export const changeUserSuspendedStatusRequest = createAction('changeUserSuspendedStatusRequest');
export const changeUserSuspendedStatusSuccess = createAction('changeUserSuspendedStatusSuccess');
export const getAvailableByUserTypeRoleListSuccess = createAction('getAvailableByUserTypeRoleListSuccess');
