import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature
import {
  fields,
  orderFields,
  getSearchFilter,
} from './settings';
//////////////////////////////////////////////////

const mapFleetTrailersToOptions = (fleetTrailers: Array) => {
  if (G.isNilOrEmpty(fleetTrailers)) return [];

  return R.map(({ guid, unitId }: Object) => ({
    [GC.FIELD_VALUE]: guid,
    [GC.FIELD_LABEL]: unitId,
  }), fleetTrailers);
};

const setRequestParams = (
  { branchGuid, fleetTrailers }: Object,
  isPagination: boolean = false,
  searchText: string,
) => ({
  limit: 10,
  orderFields,
  [GC.FIELD_CURRENT_BRANCH]: R.or(branchGuid, G.getAmousCurrentBranchGuidFromWindow()),
  offset: G.ifElse(R.and(isPagination, G.isNotNilAndNotEmpty(fleetTrailers)), R.length(fleetTrailers), 0),
  searchCriteria: G.ifElse(
    G.isNotNil(searchText),
    getSearchFilter(searchText),
    [],
  ),
  fields: fields.map(({ name }: Object, sequence: number) => ({
    name,
    sequence,
    freezed: false,
    reference: false,
    collection: false,
  })),
});

const fleetTrailersRequest = async (props: Object, options: Object, updateFleetTrailers: Function) => {
  const { setTotalCount, setLoadingStatus } = props;

  try {
    const { data } = await sendRequest('post', endpointsMap.listFleetTrailers, options);

    const { results, totalCount } = data;

    updateFleetTrailers(results);

    if (totalCount) setTotalCount(totalCount);
  } catch (error) {
    G.handleException('error', 'fleetTrailersRequest exception');
  } finally {
    setLoadingStatus(false);
  }
};

const loadFleetTrailerOptions = (props: Object) => {
  const { setFleetTrailers } = props;

  const options = { data: setRequestParams(props) };

  return fleetTrailersRequest(
    props,
    options,
    (results: Array) =>
      setFleetTrailers((prevFleetTrailers: Array) =>
        R.concat(prevFleetTrailers, results)),
  );
};

const getFleetTrailerList = (props: Object) => {
  const { fleetTrailers, setFleetTrailers } = props;

  const options = { data: setRequestParams(props, true) };

  return fleetTrailersRequest(
    props,
    options,
    (results: Array) => setFleetTrailers(R.concat(fleetTrailers, R.or(results, []))),
  );
};

const asyncSearch = async (props: Object, searchText: string) => {
  const { fleetTrailers, setFleetTrailers } = props;

  const options = { data: setRequestParams(props, false, searchText) };

  return fleetTrailersRequest(
    props,
    options,
    (results: Array) => setFleetTrailers(R.concat(fleetTrailers, R.or(results, []))),
  );
};

export {
  asyncSearch,
  getFleetTrailerList,
  loadFleetTrailerOptions,
  mapFleetTrailersToOptions,
};
