import React from 'react';
import { pure, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
// feature reference
import ReferenceFormComponent from './components/reference-form';
//////////////////////////////////////////////////

export const enhanceReferences = withHandlers({
  handleRemoveItem: ({ handleRemoveReference }: Object) => (guid: string) => {
    handleRemoveReference(guid);
  },
  handleOpenReference: (props: Object) => (reference: Object) => {
    const { scope, openModal, handleCreateReference, handleUpdateReference } = props;

    const isEditMode = G.isNotNilAndNotEmpty(reference);
    const initialValues = G.ifElse(isEditMode, reference, null);
    const submitAction = G.ifElse(isEditMode, handleUpdateReference, handleCreateReference);

    const modalTitle = G.ifElse(
      isEditMode,
      G.getWindowLocale('titles:edit-reference', 'Edit Reference'),
      G.getWindowLocale('titles:add-reference', ' Add Reference'),
    );

    const modalContent = (
      <ReferenceFormComponent
        scope={scope}
        isEditMode={isEditMode}
        submitAction={submitAction}
        initialValues={initialValues}
      />
    );

    const modal = {
      p: 15,
      component: modalContent,
      options: {
        width: 300,
        height: 'auto',
        title: modalTitle,
      },
    };

    openModal(modal);
  },
  pure,
});
