import * as R from 'ramda';
import React, { Fragment } from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
//components
import { Table } from '../../../components/table';
import { TextComponent } from '../../../components/text';
import { DateRangeMui } from '../../../components/date-range';
import { InputWithHandlers } from '../../../components/input-with-handlers';
// features
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import {
  Box,
  Flex,
  StickedBox,
  IconWrapper,
} from '../../../ui';
// feature payroll
import * as H from '../helpers';
import { Checkbox, TableRow } from '../ui';
import InvoiceActions from './driver-table-invoice-actions';
import { report, tableSettings, columnSettings } from '../settings/invoices-table-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleClickInvoiceCheckbox: (props: Object) => (guid: string, invoice: Object) => {
      const { data, onInvoiceCheckboxClick } = props;

      onInvoiceCheckboxClick(H.getDriverOnToggleInvoiceSelect(data, invoice));
    },
    handleClickEditIcon: (props: Object) => (e: Object, invoice: Object) => {
      const { openFixedPopup, closeFixedPopup, handleEditDriverInvoice } = props;

      openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: <InvoiceActions
          invoice={invoice}
          closeFixedPopup={closeFixedPopup}
          openEditInvoiceForm={() => handleEditDriverInvoice(invoice)}
                 />,
      });
    },
  }),
  pure,
);

const ToggleButton = (props: Object) => {
  const { toggle, data } = props;

  return (
    <Flex
      pr='8px'
      height='100%'
      justify='center'
      onClick={toggle}
      direction='column'
    >
      {
        G.ifElse(
          G.isTrue(data.expanded),
          I.arrowUpSimple,
          I.arrowDownSimple,
        )(G.getTheme('colors.light.green'))
      }
    </Flex>
  );
};

const StickedIcons = ({ data, toggle, onCheckboxClick }: Object) => (
  <StickedBox
    left='0px'
    zIndex={11}
    bg={G.getTheme(G.ifElse(
      data.selected,
      'colors.light.mainLight',
      'tables.rows.notSelectedBgColor',
    ))}
  >
    <Flex p='0 12px' height='100%'>
      {
        G.isNotNilAndNotEmpty(data.invoiceGuids) &&
        <ToggleButton data={data} toggle={toggle} />
      }
      {
        G.isNilOrEmpty(data.invoiceGuids) && <Box width={19} />
      }
      <Checkbox
        type='checkbox'
        name={data.driverGuid}
        checked={data.selected}
        onChange={onCheckboxClick}
      />
    </Flex>
  </StickedBox>
);

const renderInvoicesTable = (props: Object) => {
  const {
    loading,
    invoices,
    editMode,
    isVendor,
    handleClickEditIcon,
    handleClickInvoiceCheckbox,
  } = props;

  const itemList = R.values(invoices);

  const offset = itemList.length;

  const elementActionsComponent = (data: Object) => (
    <AuthWrapper
      has={[
        PC.TEL_FLEET_INVOICE_READ,
        PC.TEL_FLEET_INVOICE_WRITE,
        PC.TEL_INVOICE_OVERWRITE_EXECUTE,
      ]}
    >
      <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, data)}>
        {I.threeDots()}
      </IconWrapper>
    </AuthWrapper>
  );

  const data = {
    report,
    loading,
    itemList,
    columnSettings,
    pagination: {
      offset,
      limit: offset,
    },
    totalCount: offset,
    hasSelectable: true,
    onOptionClick: handleClickInvoiceCheckbox,
    tableSettings: G.ifElse(
      R.or(G.isTrue(editMode), isVendor),
      R.assoc('allowEditBtn', false, tableSettings),
      tableSettings,
    ),
    renderRightStickedComponent: elementActionsComponent,
  };

  return <Box pl={24}><Table {...data} /></Box>;
};

const renderInvoices = ({
  data,
  editMode,
  isVendor,
  handleClickEditIcon,
  handleClickInvoiceCheckbox,
}: Object) => {
  if (G.isTrue(data.invoicesError)) {
    return (
      <TextComponent>
        {G.getWindowLocale('titles:error-loading-invoices', 'Loading Invoices Error')}
      </TextComponent>
    );
  }

  return renderInvoicesTable({
    editMode,
    isVendor,
    handleClickEditIcon,
    invoices: data.invoices,
    handleClickInvoiceCheckbox,
    loading: data.invoicesLoading,
  });
};

const renderPayrollNumber = (props: Object) => {
  const { data, editMode, submitValidation, autoPayrollNumber, onPayrollNumberChange } = props;

  if (R.or(editMode, G.isTrue(autoPayrollNumber))) {
    const displayValue = G.ifElse(
      G.isTrue(autoPayrollNumber),
      G.getWindowLocale('titles:autogenerated', 'Autogenerated'),
      data.payrollNumber,
    );

    return (
      <TextComponent
        width={80}
        withEllipsis={true}
        title={displayValue}
        color={G.getTheme('colors.light.darkGrey')}
      >
        {displayValue}
      </TextComponent>
    );
  }

  return (
    <InputWithHandlers
      styles={{
        width: 80,
        p: '0 8px',
        border: '1px solid',
      }}
      validate={G.isNotEmpty}
      value={data.payrollNumber}
      submitValidation={submitValidation}
      onChange={(value: string) => onPayrollNumberChange(value, data)}
    />
  );
};

const PayrollNumber = (props: Object) => (
  <Flex
    mr={16}
    width={100}
    fontSize={11}
    ml={G.ifElse(props.editMode, 16, 0)}
  >
    {renderPayrollNumber(props)}
  </Flex>
);

// TODO: check after prod customer using
const renderDates = (props: Object) => {
  const { data, editMode, onDateChange } = props;

  return (
    <TextComponent>{`${data.dateFrom} - ${data.dateTo}`}</TextComponent>
  );

  // if (editMode) {
  //   return (
  //     <TextComponent>{`${data.dateFrom} - ${data.dateTo}`}</TextComponent>
  //   );
  // }

  // return (
  //   <DateRangeMui
  //     width={80}
  //     dateTo={data.dateTo}
  //     dateFrom={data.dateFrom}
  //     popperPlacement='bottom-end'
  //     maxDate={G.momentAddYearsFromCurrent(100)}
  //     minDate={G.momentSubtractYearsFromCurrent(100)}
  //     onSelectDateRange={(dates: Object) => onDateChange(dates, data.driverGuid)}
  //   />
  // );
};

const PayrollDates = (props: Object) => (
  <Flex mr={16} width={200} fontSize={11}>
    {renderDates(props)}
  </Flex>
);

const PayrollDriver = ({
  color,
  driverName,
}: Object) => (
  <Flex mr={16} width={150} fontSize={11}>
    <Box mr='6px'>{I.driver(color)}</Box>
    <TextComponent
      mr='6px'
      width={120}
      title={driverName}
      withEllipsis={true}
    >
      {driverName}
    </TextComponent>
  </Flex>
);

const PayrollTextCell = ({
  mr,
  text,
  width,
  textOptions,
}: Object) => (
  <Flex mr={R.or(mr, 16)} fontSize={11} width={R.or(width, 'auto')}>
    <TextComponent {...textOptions}>
      {text}
    </TextComponent>
  </Flex>
);

const PayrollTableRowHeader = ({
  checkedAllPayroll,
  handleClickAllPayrollCheckbox,
}: Object) => (
  <TableRow px={12} py='0px' justifyContent='center' lightGreyBorderBottom={true}>
    <StickedBox
      left='0px'
      zIndex={11}
      bg={G.getTheme('colors.light.mainLight')}
    >
      <Flex p='0 12px' height='100%'>
        <Checkbox
          ml={11}
          type='checkbox'
          name='select-all'
          checked={checkedAllPayroll}
          onChange={handleClickAllPayrollCheckbox}
        />
      </Flex>
    </StickedBox>
    <PayrollTextCell
      width={100}
      text={G.getWindowLocale('titles:payroll-number', 'Payroll #')}
    />
    <PayrollTextCell
      width={200}
      text={G.getWindowLocale('titles:payroll-date-range', 'Payroll Date Range')}
    />
    <PayrollTextCell
      width={150}
      text={G.getWindowLocale('titles:driver', 'Driver')}
    />
    <PayrollTextCell
      width={80}
      text={G.getWindowLocale('titles:trips', 'Trip(s)')}
    />
    <PayrollTextCell
      width={80}
      text={G.getWindowLocale('titles.invoices', 'Invoice(s)')}
    />
    <PayrollTextCell
      width={180}
      text={G.getWindowLocale('titles:gross-total', 'Gross Total')}
    />
    <PayrollTextCell
      width={150}
      text={G.getWindowLocale('titles:note', 'Note')}
    />
  </TableRow>
);

const PayrollTableRowEdit = enhance(({
  data,
  isVendor,
  editMode,
  onDateChange,
  submitValidation,
  autoPayrollNumber,
  handleClickEditIcon,
  onPayrollNumberChange,
  handleClickInvoiceCheckbox,
}: Object) => (
  <Fragment>
    <TableRow
      lightGreyBorderBottom={true}
      bgColor={
        R.and(
          R.not(data.selected),
          G.getTheme('tables.rows.notSelectedBgColor'),
        )
      }
    >
      <Flex mx={16} fontSize={11}>
        <TextComponent mr='6px' whiteSpace='normal'>
          {`${G.getWindowLocale('titles:payroll-number', 'Payroll #')}:`}
        </TextComponent>
        {renderPayrollNumber({ data, editMode, submitValidation, autoPayrollNumber, onPayrollNumberChange })}
      </Flex>
      <Flex mr={16} fontSize={11}>
        <TextComponent mr='6px' whiteSpace='normal'>
          {`${G.getWindowLocale('titles:payroll-date-range', 'Payroll Date Range')}:`}
        </TextComponent>
        {renderDates({ data, editMode, onDateChange })}
      </Flex>
      <PayrollDriver
        driverName={`${data.fullName}`}
        color={G.getTheme('colors.light.mainDark')}
      />
      <PayrollTextCell
        textOptions={{
          width: 80,
          withEllipsis: true,
          title: `${G.getWindowLocale('titles.invoices')}: ${data.invoicesCount}`,
        }}
        text={`${G.getWindowLocale('titles.invoices', 'Invoice(s)')}: ${data.invoicesCount}`}
      />
      <PayrollTextCell
        width={180}
        textOptions={{
          ml: 15,
          fontSize: 11,
          p: '4px 10px',
          fontWeight: 700,
          borderRadius: 3,
          display: 'inline-block',
          bg: G.getTheme('colors.light.blue'),
          color: G.getTheme('colors.light.mainLight'),
        }}
        text={`${G.getWindowLocale('titles:gross-total', 'Gross Total')}: ${
          G.getCurrencySymbol(R.propOr('', 'currency', data))} ${G.toFixed(data.invoicesTotal)}`}
      />
    </TableRow>
    {
      data.expanded &&
      renderInvoices({
        data,
        editMode,
        isVendor,
        handleClickEditIcon,
        handleClickInvoiceCheckbox,
      })
    }
  </Fragment>
));

const PayrollTableRow = ({
  data,
  toggle,
  isVendor,
  editMode,
  onDateChange,
  onCheckboxClick,
  submitValidation,
  autoPayrollNumber,
  handleClickEditIcon,
  onPayrollNumberChange,
  handleClickInvoiceCheckbox,
}: Object) => (
  <Fragment>
    <TableRow
      lightGreyBorderBottom={true}
      bgColor={
        R.and(
          R.not(data.selected),
          G.getTheme('tables.rows.notSelectedBgColor'),
        )
      }
    >
      <StickedIcons
        data={data}
        toggle={() => toggle(data)}
        onCheckboxClick={() => onCheckboxClick(data)}
      />
      <PayrollNumber
        data={data}
        editMode={editMode}
        submitValidation={submitValidation}
        autoPayrollNumber={autoPayrollNumber}
        onPayrollNumberChange={onPayrollNumberChange}
      />
      <PayrollDates
        data={data}
        editMode={editMode}
        onDateChange={onDateChange}
      />
      <PayrollDriver
        driverName={`${data.fullName}`}
        color={G.getTheme('colors.light.mainDark')}
      />
      <PayrollTextCell
        text={data.telsCount}
        textOptions={{
          width: 80,
          withEllipsis: true,
          title: `${G.getWindowLocale('titles:tels', 'Tel(s)')}: ${data.telsCount}`,
        }}
      />
      <PayrollTextCell
        text={data.invoicesCount}
        textOptions={{
          width: 80,
          withEllipsis: true,
          title: `${G.getWindowLocale('titles.invoices')}: ${data.invoicesCount}`,
        }}
      />
      <PayrollTextCell
        width={150}
        textOptions={{
          fontSize: 11,
          p: '4px 10px',
          fontWeight: 700,
          borderRadius: 3,
          display: 'inline-block',
          bg: G.getTheme('colors.light.blue'),
          color: G.getTheme('colors.light.mainLight'),
        }}
        text={`${G.getCurrencySymbol(R.propOr('', 'currency', data))} ${G.toFixed(data.invoicesTotal)}`}
      />
      {
        R.not(data.selected) &&
        <PayrollTextCell
          textOptions={{
            maxWidth: 150,
            whiteSpace: 'normal',
            color: G.getTheme('colors.light.mainRed'),
          }}
          text={G.ifElse(
            isVendor,
            G.getWindowLocale(
              'titles:vendor-will-not-be-added-to-payroll',
              'This vendor will not be added to payroll',
            ),
            G.getWindowLocale(
              'titles:this-driver-will-not-be-added-to-payroll',
              'This driver will not be added to payroll',
            ),
          )}
        />
      }
    </TableRow>
    {
      data.expanded &&
      renderInvoices({
        data,
        editMode,
        isVendor,
        handleClickEditIcon,
        handleClickInvoiceCheckbox,
      })
    }
  </Fragment>
);

export default enhance(PayrollTableRow);

export {
  PayrollTableRowEdit,
  PayrollTableRowHeader,
};
