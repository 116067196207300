import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

export const defaultReferenceFields = {
  [GC.FIELD_VALUE]: '',
  [GC.FIELD_REFERENCE_TYPE_GUID]: '',
};

export const getFieldsSetting = (allowedValues: Array, disabled: boolean) => [
  {
    disabled,
    width: 410,
    isRequired: true,
    type: 'reactSelect',
    options: 'referenceTypes',
    label: ['titles:name', 'Name'],
    fieldName: GC.FIELD_REFERENCE_TYPE_GUID,
    inputWrapperStyles: { mt: 15, mb: 25, zIndex: 2, width: 270 },
  },
  {
    width: 410,
    isRequired: true,
    autoComplete: 'off',
    options: 'allowedValues',
    fieldName: GC.FIELD_VALUE,
    label: ['titles:value', 'Value'],
    inputWrapperStyles: { mb: 25, zIndex: 1, width: 270 },
    type: G.ifElse(R.isEmpty(allowedValues), 'text', 'reactSelect'),
  },
];

export const validationSchema = Yup.object().shape({
  [GC.FIELD_REFERENCE_TYPE_GUID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_VALUE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(1, G.getShouldBeFromToLocaleTxt(1, 250))
    .max(250, G.getShouldBeFromToLocaleTxt(1, 250)),
});
