import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, RelativeBox, AbsoluteBox } from '../../../ui';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');

const ErrorsComponent = ({ errors, setErrors, globalErrors, setGlobalErrors }: Object) => (
  <RelativeBox
    p={15}
    my={20}
    width={500}
    maxHeight={200}
    overflowY='auto'
    color={whiteColor}
    justifyContent='center'
    bg={G.getTheme('colors.light.mainRed')}
  >
    <AbsoluteBox
      top='10px'
      right='10px'
      cursor='pointer'
      onClick={() => {
        setErrors(null);
        setGlobalErrors(null);
      }}
    >
      {I.closeIcon(whiteColor, 15, 15)}
    </AbsoluteBox>
    {
      G.isNotNilAndNotEmpty(globalErrors) &&
      <Box pr={30}>
        {G.getWindowLocale('titles:global-errors', 'Global Errors')}:
        {
          globalErrors.map((error: string, index: number) => (
            <Box py='4px' key={index}>
              {`${R.inc(index)}. ${error}`}
            </Box>
          ))
        }
      </Box>
    }
    {
      G.isNotNilAndNotEmpty(errors) &&
      <Box pr={30}>
        {G.getWindowLocale('titles:errors', 'Errors')}:
        {
          errors.map(({ index, errorMessage }: Object) => (
            <Box py='4px' key={index}>
              {`${R.inc(index)}. ${errorMessage}`}
            </Box>
          ))
        }
      </Box>
    }
  </RelativeBox>
);

export default ErrorsComponent;
