import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// forms
import { ChangePasswordForm } from '../../../forms/forms/change-password-form';
// helpers/constants
import * as G from '../../../helpers';
// hocs
import { withConnectModalAndLoaderActions } from '../../../hocs';
// ui
import { ActionButton } from '../../../ui';
// feature profile
import { changeUserPasswordRequest } from '../actions';
//////////////////////////////////////////////////

const ChangePassword = ({ buttonStyles, handleOpenChangePasswordForm }: Object) => (
  <ActionButton
    {...buttonStyles}
    type='button'
    onClick={handleOpenChangePasswordForm}
  >
    {G.getWindowLocale('actions:change-password', 'Change Password')}
  </ActionButton>
);

const enhance = compose(
  connect(null, { changeUserPasswordRequest }),
  withConnectModalAndLoaderActions,
  withHandlers({
    handleOpenChangePasswordForm: (props: Object) => () => {
      const { openModal, closeModal, changeUserPasswordRequest } = props;

      const component = (
        <ChangePasswordForm
          closeModal={closeModal}
          changePasswordRequest={changeUserPasswordRequest}
        />
      );

      const modal = G.getDefaultModalOptions(
        component,
        G.getWindowLocale('titles:create-new-password', 'Create New Password'),
      );

      openModal(modal);
    },
  }),
  pure,
);

export default enhance(ChangePassword);
