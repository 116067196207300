import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// features
import { AuthWrapper } from '../../permission';
import { StylingImage } from '../../styling/ui';
import PC from '../../permission/role-permission';
import { Input, HeaderFieldWrapper } from '../../new-do/ui';
import BranchSearch from '../../new-do/components/branch-search';
import AssignDivision from '../../new-do/components/assign-division';
import { CalendarInput } from '../../new-do/forms/formik/calendar-time-input';
import { InputWrapper, InputWrapperWithClickZIndex } from '../../new-do/forms/formik/input-wrapper';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, Text, ActionButton } from '../../../ui';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const blueColor = G.getTheme('colors.dark.blue');

const commonBtnStyles = {
  height: 32,
  p: '4px 8px',
  fontSize: 14,
  fontWeight: 600,
  borderRadius: '5px',
  bgColor: whiteColor,
  textColor: blueColor,
  textTransform: 'uppercase',
  border: `1px solid ${blueColor}`,
};

const QuoteNumber = (props: Object) => {
  const { quoteNumber, handleChangeQuoteNumberInput } = props;

  return (
    <InputWrapper label={G.getWindowLocale('titles:quote-number', 'Quote Number')}>
      <HeaderFieldWrapper>
        <Input
          width={160}
          name='quoteNumber'
          value={quoteNumber}
          onChange={(event: Object) => handleChangeQuoteNumberInput(event.target.value)}
        />
      </HeaderFieldWrapper>
    </InputWrapper>
  );
};

const ExpirationDate = (props: Object) => {
  const { expirationDate, setExpirationDate } = props;

  const customChangeHandler2 = ({ value }: Object) => {
    if (G.isValidMoment(value)) {
      const validDate = G.createLocalDateTimeFromInstanceOrISOString(value, GC.DEFAULT_DATE_FORMAT);

      if (G.isValidMoment(validDate)) return setExpirationDate(validDate);
    }
  };

  const disabled = R.not(G.hasAmousCurrentUserPermissions(PC.ORDER_QUOTE_OVERWRITE_EXECUTE));

  if (disabled) {
    return (
      <InputWrapper label={G.getWindowLocale('titles:expiration-date', 'Expiration Date')}>
        <HeaderFieldWrapper>
          <Input
            width={160}
            disabled={true}
            name='expirationDate'
            value={G.checkAndConvertStringToFormattedDate(expirationDate, GC.DEFAULT_DATE_FORMAT)}
          />
        </HeaderFieldWrapper>
      </InputWrapper>
    );
  }

  return (
    <InputWrapperWithClickZIndex
      inputWrapperStyles={{ zIndex: 16 }}
      label={G.getWindowLocale('titles:expiration-date', 'Expiration Date')}
    >
      <CalendarInput
        width={160}
        value={expirationDate}
        shouldCustomChange={true}
        customChangeHandler2={customChangeHandler2}
      />
    </InputWrapperWithClickZIndex>
  );
};

const Header1 = (props: Object) => {
  const { handleAssignDivision } = props;

  const branchName = R.path(['branchInfo', 'label'], props);
  const divisionName = R.path(['division', GC.FIELD_BRANCH_BRANCH_NAME], props);
  const logoUrl = R.path([GC.SYSTEM_OBJECT_STYLING, GC.FIELD_STYLING_LOGO_URL], props);

  return (
    <Flex
      px={20}
      height={60}
      bg={whiteColor}
      flexWrap='wrap'
      justifyContent='space-between'
    >
      <Box>
        <Flex>
          <Text
            mr={30}
            fontSize={24}
            fontWeight='bold'
            color={blueColor}
          >
            { G.getWindowLocale('titles:quote', 'Quote')}
          </Text>
        </Flex>
      </Box>
      <Box>
        {
          branchName &&
          <Flex alignItems='center' justifyContent='center'>
            <Flex mr={15} flexDirection='column'>
              <Text fontSize={18} color={G.getTheme('colors.titleGrey')}>
                {branchName}
              </Text>
              <AuthWrapper has={[PC.TEL_WRITE]}>
                <Box
                  cursor='pointer'
                  color={blueColor}
                  onClick={handleAssignDivision}
                >
                  {R.or(divisionName, G.getWindowLocale('titles:user-branch', 'User Branch'))}
                </Box>
              </AuthWrapper>
            </Flex>
            {logoUrl && <StylingImage width='100px' height='50px' bgImage={logoUrl} />}
          </Flex>
        }
      </Box>
    </Flex>
  );
};

const wrapperStyles = {
  width: '100%',
  bg: whiteColor,
  flexWrap: 'wrap',
  p: '15px 20px 10px 20px',
  justifyContent: 'space-between',
};

const Header2 = (props: Object) => {
  const { getBranchListRequest, openAddBranchModalRequest } = props;

  return (
    <Flex {...wrapperStyles}>
      <Box>
        <Flex>
          <BranchSearch {...props} />
          <QuoteNumber {...props} />
          <ExpirationDate {...props} />
        </Flex>
      </Box>
      <Box mt='5px'>
        <Flex>
          {
            <AuthWrapper notHas={[PC.ROLE_TYPE_CUSTOMER]}>
              <ActionButton
                {...commonBtnStyles}
                mr={30}
                onClick={() => openAddBranchModalRequest({ callbackAction: getBranchListRequest })}
              >
                {G.getWindowLocale('titles:add-new-customer', 'Add New Customer')}
              </ActionButton>
            </AuthWrapper>
          }
        </Flex>
      </Box>
    </Flex>
  );
};

const Header = (props: Object) => (
  <Box>
    <Header1 {...props} />
    <Header2 {...props} />
  </Box>
);

const enhance = compose(
  withHandlers({
    handleAssignDivision: (props: Object) => () => {
      const { openModal, setDivisionGuid, getBranchConfigsByNamesRequest } = props;

      const component = (
        <AssignDivision
          assignDivision={setDivisionGuid}
          getBranchConfigs={getBranchConfigsByNamesRequest}
        />
      );

      const modal = {
        p: '0 8px',
        component,
        options: {
          width: 'auto',
          height: 'auto',
          title: G.getWindowLocale('titles:assign-division', 'Assign Division'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export default enhance(Header);
