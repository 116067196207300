import React from 'react';
import * as R from 'ramda';
// components
import { Table } from '../../../components/table';
// ui
import { Box } from '../../../ui';
// features
import { fuelDetailsReport, fuelDetailsColumnSettings } from '../../fuel-cards/settings/column-settings';
//////////////////////////////////////////////////

const getTableSettings = (noActions: boolean = false) => ({
  allowSelectItems: R.not(noActions),
  checkBoxCellWidth: noActions ? 0 : 40,
});

const FuelCardDetails = (props: Object) => {
  const { data, parentProps } = props;
  const { guid, noActions, selectFuelCardLine } = parentProps;

  const onOptionClick = (lineGuid: string) => selectFuelCardLine(lineGuid, guid);

  return (
    <Box ml={25}>
      <Table
        itemList={data}
        report={fuelDetailsReport}
        onOptionClick={onOptionClick}
        columnSettings={fuelDetailsColumnSettings}
        tableSettings={getTableSettings(noActions)}
      />
    </Box>
  );
};

export default FuelCardDetails;
