import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withComponentDidUpdatePropCallback } from '../../../hocs';
// forms
import { FieldsetComponent } from '../../../forms';
// ui
import { Flex, ActionButton, CancelButton } from '../../../ui';
// feature new-import
import * as C from '../constants';
import { withAsyncGetMatchedFields } from '../hocs';
import { matchFields, importFileField } from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withState('fileName', 'setFileName', ''),
  withState('matchedOptions', 'setMatchedOptions', []),
  withState('defaultFields', 'setDefaultFields', { [GC.FIELD_DOCUMENT_UPLOAD]: null }),
  withAsyncGetMatchedFields,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ defaultFields, initialValues }: Object) => G.setInitialFormikValues(
      defaultFields,
      initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const {
        source,
        importType,
        submitAction,
        contractGuid,
        selectedRoles,
        matchedFields,
        currentBranchGuid } = props;

      const matchedFieldsFromValues = R.compose(
        R.values,
        R.mapObjIndexed((value: string, key: string) => ({ name: key, matchedField: value })),
      )(values);
      const addIndexToMatchedFields = R.map((field: Object) => {
        let matchedField = field;
        R.forEach((item: Object) => {
          if (R.equals(field.name, item.name)) return matchedField = R.assoc('index', item.index, field);
        }, R.pathOr([], ['headers'], matchedFields));

        return matchedField;
      }, matchedFieldsFromValues);
      let data = {
        contractGuid,
        type: importType,
        uri: matchedFields.uri,
        branchGuid: currentBranchGuid,
        matchFields: addIndexToMatchedFields,
      };
      const roleGuids = R.map((item: Object) => item.value, R.or(selectedRoles, []));
      if (R.and(R.equals(importType, C.IMPORT_TYPE_DRIVER), G.isNotNilAndNotEmpty(roleGuids))) {
        const parameters = { roleGuids };

        data = R.assoc('parameters', parameters, data);
      }
      if (R.includes(importType, [C.IMPORT_TYPE_TOLL_CHARGE, C.IMPORT_TYPE_FUEL_CARD_TRANSACTION])) {
        const parameters = { source };

        data = R.assoc('parameters', parameters, data);
      }

      submitAction(data);
    },
  }),
  withHandlers({
    handleCustomChange: ({ values, setValues }: Object) => (event: Object) => {
      const fieldWithDots = {
        [event.currentTarget.id]: R.path(['currentTarget', 'value'], event),
      };

      setValues(R.mergeRight(values, fieldWithDots));
    },
    handleCloseMapAndImport: ({ matchedFields, removeMapAndImportFileRequest }: Object) => () => {
      const data = { uri: matchedFields.uri };

      removeMapAndImportFileRequest(data);
    },
    handleDisableMatchedOptions: ({ values, matchedOptions, setMatchedOptions }: Object) => () => {
      const filtered = R.filter(G.isNotEmpty, R.values(values));
      const options = R.map((item: Object) => {
        const { value } = item;

        if (R.includes(value, filtered)) return R.assoc('disabled', true, item);

        return R.assoc('disabled', false, item);
      }, matchedOptions);

      return setMatchedOptions(options);
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'values',
    callbackName: 'handleDisableMatchedOptions',
  }),
  pure,
);

export const MapAndImport = (props: Object) => {
  const {
    values,
    fileName,
    closeModal,
    importType,
    setFileName,
    handleSubmit,
    matchedFields,
    matchedOptions,
    handleGetMatchedFields,
    handleCloseMapAndImport } = props;

  const options = R.map(R.prop(GC.FIELD_VALUE), R.or(matchedOptions, []));
  const fieldsWithMatchedOptions = R.compose(
    R.map(({ name }: Object) => matchFields(name)),
    R.filter(({ matchedField }: Object) => G.notContain(matchedField, options)),
    R.pathOr([], ['headers']),
  )(matchedFields);
  const data = {
    type: importType,
    file: R.prop(GC.FIELD_DOCUMENT_UPLOAD, values),
  };

  return (
    <form onSubmit={handleSubmit}>
      <Flex
        alignItems='flex-end'
        flexDirection={G.ifElse(G.isNotNilAndNotEmpty(matchedFields), 'row', 'column')}
      >
        <FieldsetComponent
          {...G.getFormikProps(props)}
          flexDirection='column'
          fields={R.of(Array, importFileField)}
          fileName={R.or(fileName, G.getWindowLocale('titles:select-file', 'Select File'))} />
        <Flex width='100%' justifyContent='space-between'>
          <ActionButton
            m='5px'
            width='45%'
            p='8px 16px'
            type='button'
            fontSize={18}
            mb={G.ifElse(G.isNotNilAndNotEmpty(matchedFields), '9px', '5px')}
            onClick={() => {
              handleGetMatchedFields(data);
              setFileName(R.path([GC.FIELD_DOCUMENT_UPLOAD, GC.FIELD_NAME], values));
            }}
          >
            {G.getWindowLocale('actions:upload', 'Upload')}
          </ActionButton>
          <CancelButton
            m='5px'
            width='45%'
            p='8px 16px'
            type='button'
            fontSize={18}
            onClick={closeModal}
            display={G.ifElse(G.isNotNilAndNotEmpty(matchedFields), 'none', 'block')}
          >
            {G.getWindowLocale('actions:cancel', 'Cancel')}
          </CancelButton>
        </Flex>
      </Flex>
      {
        G.isNotNilAndNotEmpty(matchedFields) &&
        <Flex flexDirection='column'>
          <FieldsetComponent
            {...G.getFormikProps(props)}
            fieldsetWidth='100%'
            flexDirection='column'
            fields={fieldsWithMatchedOptions}
            optionsForSelect={{ matchedOptions }} />
          <FormFooter
            boxStyles={{ mt: 15 }}
            closeModal={handleCloseMapAndImport}
            submitBtnText={G.getWindowLocale('actions:send', 'Send')} />
        </Flex>
      }
    </form>
  );
};

export default enhance(MapAndImport);
