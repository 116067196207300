import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { branch, compose, renderNothing, renderComponent } from 'react-recompose';
// features
import PC from '../permission/role-permission';
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// ui
import { Wrapper } from './ui';
// feature permission
import { makeSelectAuthorities } from './selectors';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => (createStructuredSelector({
  authorities: makeSelectAuthorities(state),
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
}));

const AuthHoc = (permissions: Array) => (
  (WrappedComp: React.ComponentType) => {
    const enhancer = compose(
      branch(
        (props: Object) => R.or(
          R.isEmpty(permissions),
          G.checkStringsContains(permissions, props.authorities),
        ),
        renderComponent(WrappedComp),
        renderNothing,
      ));
    return connect(mapStateToProps)(enhancer(WrappedComp));
  }
);

const Authorities = (props: Object) => {
  const {
    ml,
    mr,
    has,
    width,
    notHas,
    zIndex,
    display,
    children,
    authorities,
    justifyContent,
  } = props;

  let isAccess = true;

  if (R.not(R.includes(PC.ANY, R.or(has, [])))) {
    if (R.and(G.isNotNil(has), G.isNotNil(notHas))) {
      isAccess = R.and(
        G.checkStringsContains(has, authorities),
        R.not(G.checkStringsContains(notHas, authorities)),
      );
    } else if (G.isNotNil(has)) {
      isAccess = G.checkStringsContains(has, authorities);
    } else if (G.isNotNil(notHas)) {
      isAccess = R.not(G.checkStringsContains(notHas, authorities));
    }
  }

  if (G.isTrue(isAccess)) {
    return (
      <Wrapper
        ml={ml}
        mr={mr}
        width={width}
        zIndex={zIndex}
        display={display}
        justifyContent={justifyContent}
      >
        {children}
      </Wrapper>
    );
  }
  return null;
};

const ByCurrentBranch = (props: Object) => {
  const {
    width,
    zIndex,
    display,
    children,
    subCondition,
    justifyContent,
    currentBranchGuid,
    elementBranchGuid } = props;
  const isAccess = G.ifElse(
    G.isNotNil(elementBranchGuid),
    R.equals(elementBranchGuid, currentBranchGuid),
    true,
  );
  if (R.or(R.equals(isAccess, true), R.equals(subCondition, true))) {
    return (
      <Wrapper zIndex={zIndex} width={width} justifyContent={justifyContent} display={display}>{children}</Wrapper>
    );
  }
  return null;
};

const ByMasterBranch = (props: Object) => {
  const { children, branchType, shouldCheck } = props;
  if (shouldCheck) {
    if (R.equals(branchType, GC.BRANCH_TYPE_ENUM_MASTER)) return children;
    return null;
  }
  return children;
};

export const AuthWrapper = connect(mapStateToProps)(Authorities);
export const AuthByMasterBranch = connect(mapStateToProps)(ByMasterBranch);
export const AuthByCurrentBranch = connect(mapStateToProps)(ByCurrentBranch);

export default AuthHoc;
