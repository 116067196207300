import React from 'react';
import * as R from 'ramda';
import { compose, withState, withHandlers } from 'react-recompose';
// components
import { Switcher } from '../../../../components/switcher';
// ui
import { RelativeFlex, AbsoluteWrapper } from '../../../../ui';
// features rate
import FleetMapComponent from './map';
import {
  mapFleets,
  fleetTypeObjectMap,
  groupFleetsByLatLng,
  getMultiswitchOptions,
  getTelMappedEventsForMap,
} from './helpers';
//////////////////////////////////////////////////

const RateFleetMap = (props: Object) => {
  const {
    stops,
    trucks,
    drivers,
    trailers,
    activeMap,
    fleetsLoaded,
    selectedDriver,
    handleChangeMapClick,
  } = props;

  return (
    <RelativeFlex
      width='85vw'
      height='85vh'
    >
      {R.all(R.equals(true), R.values(fleetsLoaded)) &&
        <AbsoluteWrapper top='20px' left='200px' zIndex='11'>
          <Switcher
            version={3}
            selectedOptionIndex={0}
            onSwitch={handleChangeMapClick}
            options={getMultiswitchOptions(props)}
          />
        </AbsoluteWrapper>
      }
      {R.all(R.equals(true), R.values(fleetsLoaded)) &&
        <FleetMapComponent
          fleetType={activeMap}
          activeMap={activeMap}
          selectedDriver={selectedDriver}
          trucks={R.values(mapFleets(trucks))}
          drivers={R.values(mapFleets(drivers))}
          trailers={R.values(mapFleets(trailers))}
          initialFleets={{ drivers, trucks, trailers }}
          mappedEventsForMap={getTelMappedEventsForMap(stops)}
          fleets={groupFleetsByLatLng(props[fleetTypeObjectMap[activeMap]])}
        />
      }
    </RelativeFlex>
  );
};

const enhanceMap = compose(
  withState('activeMap', 'changeMap', 'all'),
  withHandlers({
    handleChangeMapClick: ({ changeMap }: Object) => (value: string) => {
      changeMap(value);
    },
  }),
);

export default enhanceMap(RateFleetMap);
