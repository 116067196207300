// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

const GENERAL_SETTING = 'generalSetting';
const GENERAL_INFORMATION = 'generalInformation';
const CONTACT_INFORMATION = 'contactInformation';

const GROUP_NAMES_MAP = {
  [GENERAL_SETTING]: G.getWindowLocale('titles:general-setting', 'General Setting'),
  [GENERAL_INFORMATION]: G.getWindowLocale('titles:general-information', 'General Information'),
  [CONTACT_INFORMATION]: G.getWindowLocale('titles:contact-information', 'Contact Information'),
};


export {
  GROUP_NAMES_MAP,
  GENERAL_SETTING,
  GENERAL_INFORMATION,
  CONTACT_INFORMATION,
};
