import * as R from 'ramda';
import React from 'react';
import { FieldArray } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, lifecycle, withHandlers } from 'react-recompose';
// features
import { getItemListRequest } from '../../../features/template/container-type/actions';
import { makeSelectItemList } from '../../../features/template/container-type/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { ContainerTemplateSearch } from '../../../forms/forms/template-search/components/container-template-search';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature new-do
import { Fieldset } from './formik/fieldset';
import { pickupContainerFields, getNewContainerFields, containerDefaultFields } from '../settings/fields-settings';
//////////////////////////////////////////////////

const Header = (props: Object) => (
  <Flex px='10px' height='30px' bg={G.getTheme('colors.bgGrey')}>
    <Box fontWeight={600} color={G.getTheme('colors.greyMatterhorn')}>
      {G.getWindowLocale('titles:containers', 'Containers(s)')}
    </Box>
    <Box
      mx='10px'
      height='15px'
      cursor='pointer'
      title={G.getWindowLocale('titles:add', 'Add')}
      onClick={() => props.push(getNewContainerFields())}
    >
      {I.plusRound(G.getTheme('colors.dark.blue'))}
    </Box>
  </Flex>
);

const searchTemplateFieldsOmit = [
  GC.FIELD_GUID,
  GC.FIELD_VERSION,
  GC.FIELD_LOAD_GUID,
  GC.FIELD_CONTAINER_INTERNAL_ID,
];

const enhance = compose(
  withHandlers({
    handleSearchItem: (props: Object) => (item: Object) => {
      const { values, openModal, loadGuid, closeModal, branchGuid, itemIndex, divisionGuid, setFieldValue } = props;

      const searchTemplateRequest = (itemValues: Object) => {
        const currentValues = R.compose(
          R.pick(R.keys(containerDefaultFields)),
          R.path([GC.FIELD_STOP_PICKED_UP_CONTAINERS, itemIndex]),
        )(values);
        const valuesToUse = R.mergeRight(currentValues, itemValues);
        const newItem = R.mergeRight(
          R.pick([GC.FIELD_CONTAINER_INTERNAL_ID], item),
          G.mapObjectNullFieldsToEmptyStrings(R.omit(searchTemplateFieldsOmit, valuesToUse)),
        );
        setFieldValue(`${GC.FIELD_STOP_PICKED_UP_CONTAINERS}.${itemIndex}`, newItem);
        closeModal();
      };
      const modalContent = (
        <ContainerTemplateSearch
          {...G.getLocationTemplateSearchRequestProps({
            loadGuid,
            branchGuid,
            divisionGuid,
            loadType: GC.LOAD_TYPE_CLO,
          })}
          closeModal={closeModal}
          searchTemplateRequest={searchTemplateRequest}
        />
      );
      const modal = G.createCommonModalOptions(modalContent);
      openModal(modal);
    },
  }),
  pure,
);

export const ItemRow = enhance((props: Object) => (
  <Flex my='25px' alignItems='baseline'>
    <Box>
      {
        R.not(props.singleContainerForm) &&
        <Flex
          ml={10}
          display='flex'
          cursor='pointer'
          alignItems='center'
          onClick={() => {
            const { item, remove, itemIndex, removeItemFromStore } = props;
            remove(itemIndex);
            G.callFunctionWithArgs(removeItemFromStore, R.path([GC.FIELD_CONTAINER_INTERNAL_ID], item));
          }}
          title={G.getWindowLocale('titles:remove', 'Remove')}
        >
          {I.trash(G.getTheme('colors.dark.blue'))}
        </Flex>
      }
    </Box>
    <Box>
      <Flex
        ml={10}
        display='flex'
        cursor='pointer'
        alignItems='center'
        onClick={() => props.handleSearchItem(props.item)}
        title={G.getWindowLocale('titles:search', 'Search')}
      >
        {I.globalSearch2(G.getTheme('colors.dark.blue'))}
      </Flex>
    </Box>
    <Fieldset
      {...props}
      fieldsetType='array'
      arrayName={GC.FIELD_STOP_PICKED_UP_CONTAINERS}
      fields={G.ifElse(
        props.singleContainerForm,
        R.assocPath([2, 'shouldCustomBlur'], false, pickupContainerFields),
        pickupContainerFields,
      )}
    />
  </Flex>
));

const ContainersArray = (props: Object) => {
  const { values, containerTypes } = props;

  const containers = R.path([GC.FIELD_STOP_PICKED_UP_CONTAINERS], values);
  const types = G.addEmptyOptionToDropDown(G.mapNameGuidToLabelValue(containerTypes));

  return (
    <Box mt='20px'>
      <FieldArray
        name={GC.FIELD_STOP_PICKED_UP_CONTAINERS}
        render={(arrayHelpers: Object) => (
          <Box>
            <Header {...props} push={arrayHelpers.push} />
            <Box>
              {
                R.gt(R.length(containers), 0)
                && containers.map((item: string, i: number) => (
                  <ItemRow
                    {...props}
                    {...arrayHelpers}
                    key={i}
                    item={item}
                    itemIndex={i}
                    containerTypeOptions={types}
                  />
                ))
              }
            </Box>
          </Box>
        )}
      />
    </Box>
  );
};

const enhanceContainers = compose(
  lifecycle({
    componentDidMount() {
      const { containerTypes, getItemListRequest } = this.props;

      if (G.isNotNilAndNotEmpty(containerTypes)) return;

      getItemListRequest();
    },
  }),
  pure,
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  containerTypes: makeSelectItemList(state),
});

export default connect(mapStateToProps, {
  getItemListRequest,
})(enhanceContainers(ContainersArray));
