import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setReports = createAction('setReports');
export const selectItem = createAction('selectItem');
export const setUsedReport = createAction('setUsedReport');
export const setFilterProps = createAction('setFilterProps');
export const setListLoading = createAction('setListLoading');
export const setInitialState = createAction('setInitialState');
export const setReportPending = createAction('setReportPending');
export const cleanQuickFilter = createAction('cleanQuickFilter');
export const setUsedReportOnly = createAction('setUsedReportOnly');
export const createReportRequest = createAction('createReportRequest');
export const updateReportRequest = createAction('updateReportRequest');
export const setGlobalFilterValue = createAction('setGlobalFilterValue');
export const getReportListSuccess = createAction('getReportListSuccess');
export const setQuickFilterParams = createAction('setQuickFilterParams');
export const getReportListRequest = createAction('getReportListRequest');
export const setIgnorePromptStatus = createAction('setIgnorePromptStatus');
export const deleteListItemRequest = createAction('deleteListItemRequest');
export const deleteListItemSuccess = createAction('deleteListItemSuccess');
export const resetListAndPagination = createAction('resetListAndPagination');
export const setCloLoadFromEDIStatus = createAction('setCloLoadFromEDIStatus');
export const changeDefaultReportRequest = createAction('changeDefaultReportRequest');
