import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Flex, StickedBox, ActionButton, CancelButton } from '../../../ui';
// feature new-do-quote
import { isPageCreateQuote } from '../helpers';
//////////////////////////////////////////////////

const commonBtnStyles = {
  height: 32,
  width: 120,
  p: '4px 8px',
  fontSize: 14,
  bgColor: 'none',
  background: 'none',
  border: '1px solid',
  borderRadius: '5px',
  textTransform: 'uppercase',
};

const cancelStyles = {
  ...commonBtnStyles,
  textColor: G.getTheme('colors.greyMatterhorn'),
  borderColor: G.getTheme('colors.greyMatterhorn'),
};

const submitStyles = {
  ...commonBtnStyles,
  textColor: G.getTheme('colors.white'),
  bgColor: G.getTheme('colors.dark.blue'),
};

const emailStyles = {
  ...commonBtnStyles,
  width: 140,
  fontSize: 12,
  textColor: G.getTheme('colors.white'),
  bgColor: G.getTheme('colors.dark.blue'),
};

const boxStyles = {
  width: '100%',
  p: '14px 20px',
  bg: G.getTheme('colors.white'),
  justifyContent: 'space-between',
};

const Footer = (props: Object) => {
  const {
    pageType,
    branchGuid,
    handleClickSave,
    handleClickCancel,
    handleRateRequest,
    handleSubmitAndEmail,
  } = props;

  const disabled = G.ifElse(G.isNilOrEmpty(branchGuid), true, false);
  const cursor = G.ifElse(disabled, 'not-allowed', 'pointer');

  return (
    <StickedBox bottom='0px' zIndex={13}>
      <Flex {...boxStyles} overflowX='auto'>
        <CancelButton {...cancelStyles} onClick={handleClickCancel}>
          {G.getWindowLocale('actions:cancel', 'Cancel')}
        </CancelButton>
        {
          isPageCreateQuote(pageType) &&
          <ActionButton
            {...emailStyles}
            cursor={cursor}
            disabled={disabled}
            onClick={handleSubmitAndEmail}
          >
            {G.getWindowLocale('titles:submit-and-email', 'Submit And Email')}
          </ActionButton>
        }
        <ActionButton
          {...emailStyles}
          cursor={cursor}
          disabled={disabled}
          onClick={handleRateRequest}
        >
          {G.getWindowLocale('titles:rate-request', 'Rate Request')}
        </ActionButton>
        <Flex>
          <ActionButton
            {...submitStyles}
            cursor={cursor}
            disabled={disabled}
            onClick={handleClickSave}
          >
            {G.getWindowLocale('actions:submit', 'Submit')}
          </ActionButton>
        </Flex>
      </Flex>
    </StickedBox>
  );
};

export default Footer;
