import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withProps } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncConfigs, withAsyncLoadDivisionGuid } from '../../../hocs';
// ui
import { Box, StickedBox } from '../../../ui';
// feature new-do
import FormHeader from '../forms/form-header';
import { enhanceStopForm } from '../hocs';
import ContactsArray from '../forms/contacts-array';
import { Fieldset } from '../forms/formik/fieldset';
import { FORM_BOX_SHADOW } from '../constants';
import ReferencesArray from '../forms/references-array';
import { validationSchemaStopForm} from '../validation';
import SectionDivider from '../components/section-divider';
import { getStopTitle, getStopHeaderActionOptions } from '../helpers';
import BusinessHoursSection from '../components/business-hours-section';
import {
  mainStopFields,
  apptsStopFields,
  addressStopFields,
  dropInfoStopFields,
  pickupInfoStopFields,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

// NOTE: used in dispatch-planner, dispatch-planner-events and withStopForm hoc
// TODO: like we not need all in enhanceStopForm here

const enhance = compose(
  withAsyncConfigs,
  withAsyncLoadDivisionGuid(),
  withProps(({ asyncConfigs }: Object) => ({
    branchConfigs: asyncConfigs,
  })),
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaStopForm,
    mapPropsToValues: ({ initialValues }: Object) => G.setEventEarlyLateTimeFromEarlyLateDate(initialValues),
    handleSubmit: (values: Object, { props }: Object) => {
      props.submitHandler(values);
    },
    displayName: 'StopForm',
  }),
  enhanceStopForm,
  pure,
);

const StopForm = (props: Object) => {
  const {
    values,
    setValues,
    openModal,
    closeModal,
    branchGuid,
    handleSubmit,
    setFieldValue,
    handleChangeTime,
    withoutReferences,
    handleGetLoadOptions,
    handleChangeApptTime,
  } = props;

  const eventInfoFields = G.ifElse(G.isStopDrop(values), dropInfoStopFields, pickupInfoStopFields);

  const eventInfoTitle = G.ifElse(
    G.isStopDrop(values),
    G.getWindowLocale('titles:drop-info', 'Drop Info', { caseAction: 'upperCase' }),
    G.getWindowLocale('titles:pickup-info', 'Pickup Info', { caseAction: 'upperCase' }),
  );

  return (
    <form onSubmit={handleSubmit}>
      <Box boxShadow={FORM_BOX_SHADOW}>
        <StickedBox top='0px' zIndex={16}>
          <FormHeader
            values={values}
            setValues={setValues}
            openModal={openModal}
            closeModal={closeModal}
            setFieldValue={setFieldValue}
            options={getStopHeaderActionOptions(props)}
            currentBranch={{ [GC.FIELD_GUID]: branchGuid }}
          >
            {getStopTitle(R.assoc('order', R.prop(GC.FIELD_TEL_EVENT_INDEX, values), values), values)}
          </FormHeader>
        </StickedBox>
        <Box py={30} overflowY='auto' maxHeight='calc(80vh - 100px)'>
          <Fieldset
            {...props}
            fields={mainStopFields}
            getLoadOptions={handleGetLoadOptions}
          />
          <SectionDivider
            text={G.getWindowLocale('titles:address', 'Address', { caseAction: 'upperCase' })}
          />
          <Fieldset
            {...props}
            fields={addressStopFields}
          />
          <SectionDivider text={eventInfoTitle} />
          <Fieldset
            {...props}
            fields={eventInfoFields}
            // handleChangeInput={handleChangeTime}
          />
          <SectionDivider
            text={G.getWindowLocale('titles:appointment-info', 'Appointment Info', { caseAction: 'upperCase' })}
          />
          <Fieldset
            {...props}
            fields={apptsStopFields}
            handleChangeInput={handleChangeApptTime}
          />
          <ContactsArray contacts={R.pathOr([], [GC.FIELD_CONTACTS], values)} />
          <BusinessHoursSection
            {...props}
            useFormikSetFieldValue={true}
            operationHour={R.path([GC.FIELD_OPERATION_HOUR], values)}
          />
          { R.not(withoutReferences) && <ReferencesArray {...props} /> }
        </Box>
        <FormFooter
          closeModal={closeModal}
          boxStyles={{ p: 10, justifyContent: 'space-between', bg: G.getTheme('colors.bgGrey') }}
        />
      </Box>
    </form>
  );
};

export default enhance(StopForm);
