import React from 'react';
import * as R from 'ramda';
import { OuterClick } from 'react-outer-click';
import { compose, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../../helpers';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex, AbsoluteBox, RelativeBox } from '../../../../ui';
// feature new-do
import { Error, LabelBox, LabelBoxEllipsed } from '../../ui';
//////////////////////////////////////////////////

export const InputWrapper = (props: Object) => {
  const {
    width,
    label,
    error,
    hasError,
    children,
    errorTop,
    isRequired,
    endIconName,
    withCloseIcon,
    onCloseCallback,
    withEllipsedLabel,
    inputWrapperStyles,
  } = props;

  let labelToUse = label;

  const endIcon = R.path([endIconName], I);

  if (G.isFunction(label)) labelToUse = label(props);

  const LabelWrapper = G.ifElse(G.isTrue(withEllipsedLabel), LabelBoxEllipsed, LabelBox);

  return (
    <RelativeBox {...G.spreadUiStyles(inputWrapperStyles)}>
      <LabelWrapper
        top={-13}
        left='5px'
        fontSize={11}
        title={labelToUse}
        color={G.getTheme('colors.darkGrey')}
        className={G.ifElse(isRequired, 'required', 'not-required')}
      >
        {labelToUse}
      </LabelWrapper>
      { withCloseIcon &&
        <AbsoluteBox
          top={12}
          zIndex={11}
          cursor='pointer'
          left={R.subtract(width, 14)}
          onClick={() => G.callFunctionWithArgs(onCloseCallback, null)}
        >
          {I.closeIcon(G.getTheme('colors.dark.blue'))}
        </AbsoluteBox>
      }
      { endIcon &&
        <AbsoluteBox
          top={12}
          zIndex={11}
          cursor='pointer'
          left={R.subtract(width, 18)}
          onClick={() => G.callFunctionWithArgs(onCloseCallback, null)}
        >
          {endIcon(G.getTheme('colors.dark.blue'))}
        </AbsoluteBox>
      }
      {children}
      {hasError && <Error title={error} width={width} top={errorTop}>{error}</Error>}
    </RelativeBox>
  );
};

// TODO: use the same enhancer from Fieldset2 instead after success testing
const enhance = compose(
  withState('zIndex', 'setZIndex', ({ inputWrapperStyles }: Object) => {
    const zIndex = R.pathOr(10, ['zIndex'], inputWrapperStyles);

    return zIndex;
  }),
  withHandlers({
    handleClick: (props: Object) => () => {
      const { setZIndex, inputWrapperStyles } = props;

      const zIndex = R.pathOr(1000, ['zIndexOnClick'], inputWrapperStyles);

      setZIndex(zIndex);
    },
    handleOuterClick: (props: Object) => (event: Object) => {
      const { setZIndex, inputWrapperStyles } = props;

      // NOTE: like not good solution. Check  better one or remove comment after testing
      if (G.isNotNilAndNotEmpty(event)) {
        const { type, srcElement } = event;

        if (G.isAllTrue(
          R.includes('react-datepicker', srcElement.className),
          R.equals(type, 'mousedown'),
        )) return;
      }

      const zIndex = R.pathOr(10, ['zIndex'], inputWrapperStyles);

      setZIndex(zIndex);
    },
  }),
);

export const InputWrapperWithClickZIndex = enhance((props: Object) => {
  const {
    width,
    label,
    error,
    zIndex,
    hasError,
    children,
    errorTop,
    isRequired,
    handleClick,
    endIconName,
    withCloseIcon,
    onCloseCallback,
    handleOuterClick,
    inputWrapperStyles,
  } = props;

  let labelToUse = label;

  const endIcon = R.path([endIconName], I);

  if (G.isFunction(label)) labelToUse = label(props);

  return (
    <OuterClick
      {...G.spreadUiStyles(inputWrapperStyles)}
      zIndex={zIndex}
      as={RelativeBox}
      onClick={handleClick}
      onOuterClick={handleOuterClick}
    >
      <LabelBox
        top={-13}
        left='5px'
        fontSize={11}
        color={G.getTheme('colors.darkGrey')}
        className={G.ifElse(isRequired, 'required', 'not-required')}
      >
        {labelToUse}
      </LabelBox>
      { withCloseIcon &&
        <AbsoluteBox
          top={12}
          zIndex={11}
          cursor='pointer'
          left={R.subtract(width, 14)}
          onClick={() => G.callFunctionWithArgs(onCloseCallback, null)}
        >
          {I.closeIcon(G.getTheme('colors.dark.blue'))}
        </AbsoluteBox>
      }
      { endIcon &&
        <AbsoluteBox
          top={12}
          zIndex={11}
          cursor='pointer'
          left={R.subtract(width, 18)}
          onClick={() => G.callFunctionWithArgs(onCloseCallback, null)}
        >
          {endIcon(G.getTheme('colors.dark.blue'))}
        </AbsoluteBox>
      }
      {children}
      {hasError && <Error title={error} width={width} top={errorTop}>{error}</Error>}
    </OuterClick>
  );
});

export const CheckboxWrapper = ({ width, label, children, inputWrapperStyles }: Object) => (
  <Flex width={width} {...inputWrapperStyles}>
    {children}
    <Box px='10px' fontSize='12px' color={G.getTheme('colors.greyMatterhorn')}>
      {label}
    </Box>
  </Flex>
);
