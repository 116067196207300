import * as R from 'ramda';
import {
  put,
  call,
  race,
  fork,
  take,
  select,
  takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { sendLogOutRequest } from '../auth/actions';
import {
  makeSelectAccessToPagesList,
  makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { initialDataLoadSuccess, initialDataLoadFail } from '../../common/actions';
// sagas
import { crudSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature notification
import * as A from './actions';
import { makeSelectPagination, makeSelectItemList } from './selectors';
//////////////////////////////////////////////////

const mapResponseDataResults = R.map((item: Object) => ({
  ...item.notification,
  read: item.read,
  mainGuid: item.guid,
  createdDate: G.fromNow(item.createdDate),
}));

function* handleGetUnreadListNotifications() {
  try {
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.notificationUnreadCount,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getUnreadCountSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetUnreadListNotifications fail');
      yield put(closeLoader());
    }
  } catch (err) {
    yield call(G.handleException, err, 'handleGetUnreadListNotifications exception');
    yield put(A.setListLoading(false));
  }
}

function* handleGetItemListSaga({ payload }: boolean) {
  try {
    yield fork(handleGetUnreadListNotifications);
    yield put(A.setListLoading(true));
    const pagination = yield select(makeSelectPagination());
    const options = {
      params: {
        onlyUnread: false,
        limit: pagination.limit,
        offset: pagination.offset,
      },
    };
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.notificationList,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess(R.mergeRight(data, { results: mapResponseDataResults(data.results) })));
      yield put(A.setListLoading(false));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
      yield put(A.setListLoading(false));
    }
  } catch (err) {
    yield call(G.handleException, err, 'handleGetItemListSaga exception');
    yield put(A.setListLoading(false));
  }
}

// TODO: check logic for simple event notifications
function* handleReadNotifications({ payload }: boolean) {
  try {
    const list = yield select(makeSelectItemList());
    const isSelected = (item: Object) => R.equals(item.selected, true);
    const guidList = R.values(R.map(
      (item: Object) => item.mainGuid,
      R.filter(isSelected, list),
    ));
    if (R.and(G.isNilOrEmpty(guidList), G.isBoolean(payload))) {
      return yield call(G.showToastrMessage, 'info', 'messages:select-item');
    }
    let options = {
      data: {
        guids: guidList,
        read: payload,
      },
    };
    if (G.isObject(payload)) {
      options = {
        data: payload,
      };
      yield put(A.markAllAsReadSuccess(payload));
    } else {
      yield put(A.markAllAsReadSuccess({ guids: guidList, read: payload }));
    }
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.notificationMark,
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      if (G.isObject(payload)) {
        yield put(A.markAllAsReadSuccess(payload));
        yield call(handleGetUnreadListNotifications);
        yield put(closeLoader());
      } else {
        yield put(A.markAllAsReadSuccess({ guids: guidList, read: payload }));
        yield call(handleGetUnreadListNotifications);
        yield put(closeLoader());
      }
    } else {
      if (G.isObject(payload)) {
        yield put(A.markAllAsReadSuccess({ guids: payload.guids, read: false }));
      }
      yield put(A.markAllAsReadSuccess({ guids: guidList, read: false }));
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
      yield put(A.setListLoading(false));
      yield put(closeLoader());
    }
  } catch (err) {
    yield call(G.handleException, err, 'handleGetItemListSaga exception');
    yield put(A.setListLoading(false));
    yield put(closeLoader());
  }
}

function* handleChangeBranch() {
  try {
    yield put(A.setInitialState());
  } catch (error) {
    yield call(G.handleException, error, 'handleChangeBranch exception');
  }
}

function* deleteSelectedNotificationsSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      data: payload,
    };
    yield call(crudSaga, {
      options,
      method: 'delete',
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      parentSagaName: 'deleteSelectedNotificationsSaga',
      endpoint: endpointsMap.deleteSelectedNotifications,
      successAction: A.resetListAndPaginationAndCallList,
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'deleteSelectedNotificationsSaga exception');
  }
}

function* deleteAllNotificationsSaga() {
  try {
    yield put(openLoader());
    yield call(crudSaga, {
      method: 'delete',
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      parentSagaName: 'deleteAllNotificationsSaga',
      endpoint: endpointsMap.deleteAllNotifications,
      successAction: A.resetListAndPaginationAndCallList,
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'deleteAllNotificationsSaga exception');
  }
}

function* resetListAndPaginationAndCallListSaga() {
  yield put(A.resetListAndPagination());
  yield put(A.getItemListRequest());
}

function* handleVisitNotificationListPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    const route = payload.pathname;
    const isInitialDataLoaded = yield select(makeSelectInitialDataLoadedStatus());
    const accessToPagesList = yield select(makeSelectAccessToPagesList());
    const isAllowed = R.includes(route, accessToPagesList);
    if (R.not(isInitialDataLoaded)) {
      yield race({
        success: take(initialDataLoadSuccess),
        fail: take(initialDataLoadFail),
      });
    }
    if (R.not(isAllowed)) {
      yield put({ type: GC.CHECK_VISIT_NOTIFICATION_LIST_PAGE, payload: route });
      yield take(GC.CHECK_VISIT_NOTIFICATION_LIST_PAGE_ALLOWED);
    }
    const list = yield select(makeSelectItemList());
    if (G.isNilOrEmpty(list)) {
      yield put(A.resetListAndPagination());
      yield call(handleGetItemListSaga, { payload: true });
    }
    break;
  }
}

export function* NotificationWatcherSaga() {
  yield takeLatest(GC.VISIT_NOTIFICATION_LIST_PAGE, handleVisitNotificationListPageSaga);
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.markAllAsReadRequest, handleReadNotifications);
  yield takeLatest(A.deleteAllNotifications, deleteAllNotificationsSaga);
  yield takeLatest(A.getUnreadCountRequest, handleGetUnreadListNotifications);
  yield takeLatest(A.deleteSelectedNotifications, deleteSelectedNotificationsSaga);
  yield takeLatest(A.resetListAndPaginationAndCallList, resetListAndPaginationAndCallListSaga);
  // NOTE: temporary listen to logout here until logout will reset store globally
  yield takeLatest(sendLogOutRequest, handleChangeBranch);
}

export default NotificationWatcherSaga;
