import { createReducer } from 'redux-act';
import { $all, $set, $add } from 'plow-js';
// helpers/constants
import * as GC from '../../constants';
// feature permission
import * as Actions from './actions';
//////////////////////////////////////////////////

const initialState = {
  authorities: [],
  routesAccess: [],
  permissionsList: null,
  requestPending: false,
};

const setAuthoritiesRequest = (state: Object, payload: string) => $set('authorities', payload, state);

const getPermissionsRequest = (state: Object) => $set('requestPending', true, state);

const receivedPermissionsFail = (state: Object) => $set('requestPending', false, state);

const receivedPermissionsSuccess = (state: Object, data: Array) => (
  $all(
    $set('permissionsList', data),
    $set('requestPending', false),
    state,
  )
);

const addAccessToPageRequest = (state: Object, payload: string) => $add('routesAccess', payload, state);

const reinitializeRoutesAccessState = (state: Object) => $set('routesAccess', [], state);

export default createReducer({
  [GC.CLEAR_STORE]: reinitializeRoutesAccessState,
  [Actions.setAuthoritiesRequest]: setAuthoritiesRequest,
  [Actions.getPermissionsRequest]: getPermissionsRequest,
  [Actions.addAccessToPageRequest]: addAccessToPageRequest,
  [Actions.receivedPermissionsFail]: receivedPermissionsFail,
  [Actions.receivedPermissionsSuccess]: receivedPermissionsSuccess,
}, initialState);

