import * as R from 'ramda';
import React, { Component } from 'react';
// TODO: check webpack importing
import { Page, Document } from 'react-pdf';
// import { Page, Document } from 'react-pdf/dist/entry.webpack';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, Flex, Span } from '../../../ui';
//////////////////////////////////////////////////

const isNotIframeRenderMode = (mode: string) => G.notEquals(mode, 'iframe');

class PdfPreview extends Component {
  state = {
    pageNumber: 1,
    numPages: null,
  }

  onDocumentLoadSuccess = ({ numPages }: Object) => {
    this.setState({ numPages });
  }

  onPrevClick = () => {
    const { pageNumber } = this.state;
    if (R.equals(pageNumber, 1)) return;
    this.setState({ pageNumber: R.dec(pageNumber) });
  }

  onNextClick = () => {
    const { numPages, pageNumber } = this.state;
    if (R.equals(numPages, pageNumber)) return;
    this.setState({ pageNumber: R.inc(pageNumber) });
  }

  render() {
    const { file, type, renderMode } = this.props;
    const { numPages, pageNumber } = this.state;

    return (
      <Box p={15}>
        {
          R.equals(renderMode, 'canvas') &&
          <Document
            file={file}
            onLoadSuccess={this.onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        }
        {
          R.equals(renderMode, 'svg') &&
          <Document
            file={file}
            onLoadSuccess={this.onDocumentLoadSuccess}
          >
            <Page scale={2} renderMode='svg' pageNumber={pageNumber} />
          </Document>
        }
        {
          R.equals(renderMode, 'iframe') &&
          <iframe type={type} src={file} width='100%' height='500px' />
        }
        {
          isNotIframeRenderMode(renderMode) &&
          <Flex justifyContent='center'>
            <Box>
              <Span
                mr={10}
                cursor='pointer'
                onClick={this.onPrevClick}
                title={G.getWindowLocale('actions:prev', 'Prev')}
              >
                {'<<'}
              </Span>
              {G.getWindowLocale('titles:page', 'Page')} {pageNumber} {G.getWindowLocale('titles:of', 'of')} {numPages}
              <Span
                ml={10}
                cursor='pointer'
                onClick={this.onNextClick}
                title={G.getWindowLocale('actions:next', 'Next')}
              >
                {'>>'}
              </Span>
            </Box>
          </Flex>
        }
      </Box>
    );
  }
}

export default PdfPreview;
