import * as Yup from 'yup';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature profile
import * as LC from '../constants';
import { getActiveText } from '../helpers';
//////////////////////////////////////////////////

export const validationSchema = Yup.lazy(() => Yup.object().shape({
  [GC.FIELD_USER_FIRST_NAME]: Yup.string()
    .nullable(true)
    .min(2, G.getShouldBeFromToLocaleTxt(2, 50))
    .max(50, G.getShouldBeFromToLocaleTxt(2, 50)),
  [GC.FIELD_USER_LAST_NAME]: Yup.string()
    .nullable(true)
    .min(2, G.getShouldBeFromToLocaleTxt(2, 50))
    .max(50, G.getShouldBeFromToLocaleTxt(2, 50)),
  [GC.FIELD_USER_SIGNATURE]: Yup.string()
    .nullable(true)
    .min(2, G.getShouldBeFromToLocaleTxt(2, 1000))
    .max(1000, G.getShouldBeFromToLocaleTxt(2, 1000)),
  [GC.FIELD_USER_SMS_SENDER_NAME]: Yup.string()
    .nullable(true)
    .min(2, G.getShouldBeFromToLocaleTxt(2, 50))
    .max(50, G.getShouldBeFromToLocaleTxt(2, 50)),
  [GC.FIELD_USER_EMAIL]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .email(G.getFieldIsNotValidEmailTxt()),
  [GC.FIELD_PHONE_NUMBER]: Yup.string()
    .nullable(true)
    .max(100, G.getShouldBeFromToCharLocaleTxt(0, 40))
    .matches(GC.PHONE_NUMBER_REGEXP, G.getShouldBePhoneLocaleTxt()),
  [GC.FIELD_USER_FAX_NUMBER]: Yup.number()
    .nullable(true)
    .typeError(G.getShouldBeNumericLocaleTxt())
    .positive(G.getShouldBePositiveLocaleTxt()),
}));

export const settings = {
  // TODO: remove or uncomment after complete NAL login logic
  // [GC.FIELD_USER_NAL_ID]{
  //   name: 'titles:nal-id',
  // },
  [GC.FIELD_ACTIVE]: {
    type: 'toggle',
    name: 'titles:active',
    shouldCustomChange: true,
    additionalInputWrapperSettings: { minWidth: 'unset' },
  },
  [GC.FIELD_USER_LOGIN_ID]: {
    disabled: true,
    name: 'titles:user-id',
    additionalInputWrapperSettings: {
      width: 170,
    },
  },
  userType: {
    disabled: true,
    name: 'titles:user-type',
    additionalInputWrapperSettings: {
      width: 170,
    },
  },
  [GC.FIELD_USER_FIRST_NAME]: {
    name: 'titles:first-name',
    additionalInputWrapperSettings: {
      width: 170,
    },
  },
  [GC.FIELD_USER_LAST_NAME]: {
    name: 'titles:last-name',
    additionalInputWrapperSettings: {
      width: 170,
    },
  },
  [GC.FIELD_USER_SIGNATURE]: {
    name: 'titles:signature',
  },
  [GC.FIELD_USER_EMAIL]: {
    isRequired: true,
    name: 'titles:email',
    additionalInputWrapperSettings: {
      mb: 10,
    },
  },
  [GC.FIELD_USER_PHONE_NUMBER]: {
    type: 'phoneNumber',
    name: 'titles:phone-number',
    additionalInputWrapperSettings: {
      width: 220,
    },
  },
  [GC.FIELD_USER_FAX_NUMBER]: {
    type: 'text',
    name: 'titles:fax-number',
    additionalInputWrapperSettings: {
      width: 220,
    },
  },
  [GC.FIELD_USER_SMS_SENDER_NAME]: {
    name: 'titles:sms-sender-name',
    additionalInputWrapperSettings: {
      width: 220,
    },
  },
  locale: {
    type: 'reactSelect',
    name: 'titles:locale',
    options: 'localeOptions',
    additionalInputWrapperSettings: {
      width: 220,
    },
  },
  [GC.FIELD_DEFAULT_AFTER_LOGIN_PAGE]: {
    type: 'reactSelect',
    options: 'loginScreenOptions',
    name: 'titles:default-login-screen',
    additionalInputWrapperSettings: {
      width: 220,
    },
  },
  [GC.FIELD_CLO_ROUTE_DEFAULT_GROUP_TYPE]: {
    type: 'reactSelect',
    name: 'titles:clo-default-group-by',
    options: 'cloRouteDefaultGroupByOptions',
    additionalInputWrapperSettings: {
      width: 220,
    },
  },
  [GC.FIELD_TEL_ROUTE_DEFAULT_GROUP_TYPE]: {
    type: 'reactSelect',
    name: 'titles:tel-default-group-by',
    options: 'telRouteDefaultGroupByOptions',
    additionalInputWrapperSettings: {
      width: 220,
    },
  },
  dispatchingGroupGuids: {
    isMulti: true,
    type: 'reactSelect',
    name: 'titles:dispatching-group',
    options: 'dispatchingGroupOptions',
    additionalInputWrapperSettings: {
      width: 220,
    },
  },
  dummyField: {
    additionalInputWrapperSettings: {
      width: 220,
      visibility: () => 'hidden',
    },
  },
};

const fieldsWrapperStyles = { justifyContent: 'space-between' };

const defaultGroups = [
  {
    fields: [
      GC.FIELD_ACTIVE,
    ],
  },
  {
    fieldsWrapperStyles,
    groupName: LC.GENERAL_INFORMATION,
    fields: [
      GC.FIELD_USER_LOGIN_ID,
      'userType',
      GC.FIELD_USER_FIRST_NAME,
      GC.FIELD_USER_LAST_NAME,
      GC.FIELD_USER_SIGNATURE,
    ],
  },
  {
    fieldsWrapperStyles,
    groupName: LC.CONTACT_INFORMATION,
    fields: [
      GC.FIELD_USER_EMAIL,
      GC.FIELD_USER_PHONE_NUMBER,
      GC.FIELD_USER_FAX_NUMBER,
      GC.FIELD_USER_SMS_SENDER_NAME,
      'dummyField',
    ],
  },
  {
    fieldsWrapperStyles,
    groupName: LC.GENERAL_SETTING,
    fields: [
      'locale',
      GC.FIELD_DEFAULT_AFTER_LOGIN_PAGE,
      GC.FIELD_CLO_ROUTE_DEFAULT_GROUP_TYPE,
      GC.FIELD_TEL_ROUTE_DEFAULT_GROUP_TYPE,
      'dispatchingGroupGuids',
      'dummyField',
    ],
  },
];

const carrierGroups = [
  {
    fields: [
      GC.FIELD_ACTIVE,
    ],
  },
  {
    fieldsWrapperStyles,
    groupName: LC.GENERAL_INFORMATION,
    fields: [
      GC.FIELD_USER_LOGIN_ID,
      GC.FIELD_USER_FIRST_NAME,
      GC.FIELD_USER_LAST_NAME,

    ],
  },
  {
    fieldsWrapperStyles,
    groupName: LC.CONTACT_INFORMATION,
    fields: [
      GC.FIELD_USER_EMAIL,
      GC.FIELD_USER_PHONE_NUMBER,
      GC.FIELD_USER_FAX_NUMBER,
    ],
  },
  {
    fieldsWrapperStyles,
    groupName: LC.GENERAL_SETTING,
    fields: [
      'locale',
    ],
  },
];

const defaultInputWrapperStyles = {
  mb: 15,
  flexGrow: 1,
  minWidth: 120,
  display: 'flex',
};

const getMappedGroups = (props: Object) => {
  const {
    status,
    isCarrier,
    isCustomer,
    userTypeDriver,
    userTypeCarrier,
  } = props;

  let groups = defaultGroups;

  if (isCarrier) {
    groups = carrierGroups;
  }

  if (R.or(isCustomer, userTypeDriver)) {
    groups = R.map((group: Object) =>
      R.assoc(
        'fields',
        R.reject(R.equals('dispatchingGroupGuids'), group.fields),
        group,
      ), defaultGroups,
    );
  }

  return R.map((group: Object) => {
    const { fields } = group;

    const fieldSettings = R.map((fieldName: Object) => {
      const field = R.pathOr({}, [fieldName], settings);

      const {
        name,
        inputStyles,
        additionalInputWrapperSettings,
      } = field;

      let nameToUse = name;

      if (R.and(G.isNotNilAndNotEmpty(nameToUse), R.equals(fieldName, 'active'))) {
        nameToUse = getActiveText(status);
      }

      const commonProperties = {
        fieldName,
        inputStyles,
        errorWidth: '100%',
        label: G.ifElse(G.isNotNil(nameToUse), R.of(Array, nameToUse)),
        inputWrapperStyles: { ...defaultInputWrapperStyles, ...additionalInputWrapperSettings },
      };

      if (R.and(
        userTypeCarrier,
        R.includes(fieldName, [GC.FIELD_DEFAULT_ROUTE_TYPE, GC.FIELD_CLO_ROUTE_DEFAULT_GROUP_TYPE]),
      )) {
        const newField = R.assoc('disabled', true, field);

        return {
          ...newField,
          ...commonProperties,
        };
      }

      return {
        ...field,
        ...commonProperties,
      };
    }, fields);

    return R.assoc('fieldSettings', fieldSettings, group);
  }, groups);
};

export const getGeneralDetailsSettings = (props: Object) => {
  const {
    status,
    isCarrier,
    isCustomer,
    userTypeDriver,
    userTypeCarrier,
  } = props;

  return {
    validationSchema,
    firstFields: R.head(getMappedGroups({ status })),
    getFieldsGroups: (values: Object) => R.tail(getMappedGroups({
      values,
      isCarrier,
      isCustomer,
      userTypeDriver,
      userTypeCarrier,
    })),
  };
};
