import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = { mb: 25, width: 310 };

export const defaultValues = {
  [GC.FIELD_NAME]: null,
  [GC.FIELD_DESCRIPTION]: null,
  [GC.FIELD_REFERENCE_SCOPES]: null,
  [GC.FIELD_REFERENCE_ALLOWED_VALUES]: [],
  [GC.FIELD_REFERENCE_INTEGRATION_CODE]: null,
};

export const validationSchema = {
  [GC.FIELD_REFERENCE_SCOPES]: G.yupArrayRequired,
  [GC.FIELD_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_DESCRIPTION]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(250, G.getShouldBeLessOrEqualLocaleTxt(250)),
  [GC.FIELD_REFERENCE_INTEGRATION_CODE]: Yup.string()
    .notRequired()
    .nullable(true)
    .max(40, G.getShouldBeLessOrEqualLocaleTxt(40)),
  [GC.FIELD_REFERENCE_ALLOWED_VALUES]: Yup.array()
    .nullable(true)
    .notRequired()
    .of(Yup.object().shape({
      [GC.FIELD_VALUE]: Yup.string()
        .nullable(true)
        .required(G.getRequiredLocaleTxt())
        .max(40, G.getShouldBeLessOrEqualLocaleTxt(40)),
    })),
};

export const referenceTypeFields = [
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_NAME,
    label: ['titles:name', 'Name'],
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_DESCRIPTION,
    label: ['titles:description', 'Description'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_REFERENCE_INTEGRATION_CODE,
    label: ['titles:integration-code', 'Integration Code'],
  },
  {
    isMulti: true,
    isRequired: true,
    type: 'reactSelect',
    options: 'scopesOptions',
    fieldName: GC.FIELD_REFERENCE_SCOPES,
    label: ['titles:scope-names', 'Scope Names'],
    inputWrapperStyles: { width: 310, zIndex: 11 },
  },
];

export const referenceFieldsSetting = [
  {
    type: 'text',
    fieldName: GC.FIELD_VALUE,
    inputWrapperStyles: { mt: 15, width: 282 },
  },
];
