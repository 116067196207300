import * as R from 'ramda';
import * as Yup from 'yup';
import React, { Fragment } from 'react';
import { useFormik, FieldArray, FormikProvider } from 'formik';
// components
import { TextComponent } from '../../../components/text';
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, RelativeBox, MainActionButton } from '../../../ui';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 250,
};

const fieldSettings = [
  {
    type: 'datePicker',
    inputWrapperStyles,
    fieldName: GC.FIELD_DATE,
    label: ['titles:date', 'Date'],
  },
];

const exchangeRateFieldSettings = [
  {
    inputWrapperStyles,
    type: 'reactSelect',
    fieldName: GC.FIELD_CURRENCY,
    options: 'availableCurrencyOptions',
    label: ['titles:currency', 'Currency'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_VALUE,
    label: ['titles:rate', 'Rate'],
    options: 'availableCurrencyOptions',
  },
];

const validationSchema = Yup.object().shape({
  [GC.FIELD_DATE]: G.yupStringRequired,
  exchangeRatesMap: Yup.array().of(Yup.object().shape({
    [GC.FIELD_VALUE]: G.yupNumberRequired,
    [GC.FIELD_CURRENCY]: G.yupStringRequired,
  })),
});

const defaultFields = () => ({
  [GC.FIELD_VALUE]: null,
  [GC.FIELD_CURRENCY]: null,
  [GC.FIELD_ID]: G.genShortId(),
});

const ExchangeRate = (props: Object) => {
  const { form, push, remove } = props;

  const exchangeRatesMap = R.pathOr([], ['values', 'exchangeRatesMap'], form);
  const currencyOptions = R.drop(1, GC.CURRENCY_OPTIONS);
  const allowRemove = R.gt(R.length(exchangeRatesMap), 1);
  const existentCurrencies = R.map(R.prop(GC.FIELD_CURRENCY), exchangeRatesMap);

  const availableCurrencyOptions = R.compose(
    R.reject(R.propEq(GC.DEFAULT_UI_CURRENCY, GC.FIELD_VALUE)),
    R.map(({ value, label }: Object) => ({
      value,
      label,
      isDisabled: R.includes(value, existentCurrencies),
    })),
  )(currencyOptions);

  const addDisabled = R.equals(R.length(exchangeRatesMap), R.length(availableCurrencyOptions));

  const makeCustomExchangeRateText = (index: number) => {
    const value = R.path([index, GC.FIELD_VALUE], exchangeRatesMap);
    const currency = R.path([index, GC.FIELD_CURRENCY], exchangeRatesMap);

    if (R.or(G.isNilOrEmpty(value), G.isNilOrEmpty(currency))) return null;

    return `1 ${GC.DEFAULT_UI_CURRENCY} = ${value} ${currency}`;
  };

  return (
    <Fragment>
      <Flex pt={15} alignItems='flex-start'>
        <Fieldset2 {...form} fields={fieldSettings} />
        <MainActionButton
          ml={20}
          type='button'
          disabled={addDisabled}
          onClick={() => push(defaultFields())}
          cursor={G.ifElse(addDisabled, 'not-allowed')}
        >
          {G.getWindowLocale('titles:add-exchange-rate', 'Add Exchange Rate')}
        </MainActionButton>
      </Flex>
      {
        exchangeRatesMap.map((_: any, index: number) => (
          <RelativeBox key={index}>
            <Flex>
              {
                allowRemove &&
                <Box
                  mr={10}
                  cursor='pointer'
                  onClick={() => remove(index)}
                >
                  {I.trash()}
                </Box>
              }
              <Fieldset2
                {...form}
                itemIndex={index}
                fieldsetType='array'
                arrayName='exchangeRatesMap'
                fields={exchangeRateFieldSettings}
                availableCurrencyOptions={availableCurrencyOptions}
                fieldsWrapperStyles={{ pt: 15, width: 520, justifyContent: 'space-between' }}
              />
            </Flex>
            <TextComponent
              pl='5px'
              ml='auto'
              right='0'
              bottom='0'
              width={250}
              fontSize={12}
              maxWidth={250}
              display='block'
              position='absolute'
              withEllipsis={true}
              title={makeCustomExchangeRateText(index)}
            >
              {makeCustomExchangeRateText(index)}
            </TextComponent>
          </RelativeBox>
        ))
      }
    </Fragment>
  );
};

const makeInitialValues = (initialValues: Object) => {
  if (R.isNil(initialValues)) return { date: null, exchangeRatesMap: R.of(Array, defaultFields()) };

  const exchangeRatesMap = R.compose(
    R.values,
    R.mapObjIndexed((value: number, currency: string) => ({ value, currency })),
    R.propOr({}, 'exchangeRatesMap'),
  )(initialValues);

  return { ...initialValues, exchangeRatesMap };
};

const CustomExchangeRateForm = (props: Object) => {
  const { submitAction, initialValues } = props;

  const onSubmit = (values: Object) => {
    const exchangeRatesMap = R.compose(
      R.map(({ value }: Object) => G.toNumber(value)),
      R.indexBy(R.prop(GC.FIELD_CURRENCY)),
      R.propOr([], 'exchangeRatesMap'),
    )(values);

    submitAction({ ...values, exchangeRatesMap });
  };

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: makeInitialValues(initialValues),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormikProvider value={formik}>
        <FieldArray render={ExchangeRate} name='exchangeRatesMap' />
      </FormikProvider>
      <FormFooter2 />
    </form>
  );
};

export default CustomExchangeRateForm;
