import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose, lifecycle, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { SectionsDivider } from '../../../ui';
// hocs
import {
  withAsyncReport,
  withAsyncInvoiceStatusConfigs,
} from '../../../hocs';
// feature payroll
import { ReportDriversList } from './report-drivers-list';
import { validationSchemaPayrollObject } from '../validators';
import { ReportVendorsList } from '../vendor-payroll/components/report-vendors-list';
//////////////////////////////////////////////////

const payrollWithoutTripsInitFields = {
  [GC.FIELD_PAYROLL_STATUS]: null,
  [GC.FIELD_PAYROLL_DATE_TO]: null,
  [GC.FIELD_PAYROLL_CURRENCY]: null,
  [GC.FIELD_PAYROLL_DATE_FROM]: null,
};

const payrollWithoutTripsFieldset = [
  {
    type: 'select',
    options: 'payrollStatusOptions',
    inputWrapperStyles: { width: 190 },
    fieldName: 'GC.FIELD_PAYROLL_STATUS',
    label: ['titles:payroll-status', 'Payroll Status'],
  },
  {
    isRequired: true,
    type: 'datePicker',
    inputWrapperStyles: { width: 200 },
    fieldName: GC.FIELD_PAYROLL_DATE_FROM,
    label: ['titles:date-from', 'Date From'],
  },
  {
    isRequired: true,
    type: 'datePicker',
    inputWrapperStyles: { width: 200 },
    fieldName: GC.FIELD_PAYROLL_DATE_TO,
    label: ['titles:date-to', 'Date To'],
  },
  {
    type: 'select',
    isRequired: true,
    options: GC.CURRENCY_OPTIONS,
    inputWrapperStyles: { width: 190 },
    fieldName: GC.FIELD_PAYROLL_CURRENCY,
    label: ['titles:currency', 'Currency'],
  },
];

const setDefaultValues = ({ asyncInitialData }: Object) => R.mergeRight(
  payrollWithoutTripsInitFields,
  G.mapDropdownsObjectInEntity(
    GC.FIELD_CONFIG_GUID,
    [GC.FIELD_PAYROLL_STATUS],
    R.pathOr({}, ['data'], asyncInitialData),
  ),
);

const validationSchema = Yup.object()
  .shape(validationSchemaPayrollObject);

const setOptionsForSelect = (props: Object) => ({
  [GC.FIELD_PAYROLL_STATUS]: G.mapCustomConfigOptionsFromProps('DPStatusConfig', props, true),
  [GC.FIELD_GL_CODE]: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    props.asyncConfigs,
    GC.INVOICE_GL_CODE,
    true,
  ),
});

const PayrollForm = (props: Object) => {
  const {
    isVendor,
    handleSubmit,
    asyncReportListState,
    handleSelectDriverItem,
  } = props;

  const optionsForSelect = setOptionsForSelect(props);

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={payrollWithoutTripsFieldset}
        fieldsWrapperStyles={{ p: '30px 15px 25px', justifyContent: 'space-between' }}
        payrollStatusOptions={R.pathOr([], [GC.FIELD_PAYROLL_STATUS], optionsForSelect)}
      />
      <SectionsDivider />
      {
        isVendor ? (
          <ReportVendorsList
            {...props}
            loading={asyncReportListState.loading}
            selectDriverItem={handleSelectDriverItem}
          />
        ) : (
          <ReportDriversList
            {...props}
            loading={asyncReportListState.loading}
            selectDriverItem={handleSelectDriverItem}
          />
        )
      }
      <FormFooter2 boxStyles={{ p: 15 }} />
    </form>
  );
};

const withReport = compose(
  withAsyncReport,
  lifecycle({
    componentDidMount() {
      this.props.getReportListRequest();
    },
  }),
);

const enhanceCreatePayrollsWithoutTrips = compose(
  withReport,
  withHandlers({
    handleSelectDriverItem: ({ setSelectedItemToAsyncReport }: Object) => (selectedItem: string) => (
      setSelectedItemToAsyncReport(selectedItem)
    ),
  }),
  withFormik({
    validationSchema,
    enableReinitialize: true,
    mapPropsToValues: (props: Object) => setDefaultValues(props),
    handleSubmit: (values: Object, { props }: Object) => {
      const { isVendor, handleCreateDriversPayroll } = props;

      const guids = R.compose(
        R.map((item: Object) => R.prop(GC.FIELD_GUID, item)),
        R.filter((item: Object) => R.propEq(true, 'selected', item)),
        R.pathOr([], ['asyncReportListState', 'data']),
      )(props);

      const propName = G.ifElse(isVendor, 'fleetVendorGuids', 'driverGuids');

      const payroll = R.compose(
        G.mapFormFieldsToNull([GC.FIELD_INVOICE_STATUS]),
        R.map((item: any) => G.checkAndConvertMomentInstanceToFormattedDate(item, GC.DEFAULT_DATE_FORMAT)),
      )(values);

      handleCreateDriversPayroll(R.assoc(propName, guids, payroll));
    },
    displayName: 'CreatePayrollsWithoutTripsForm',
  }),
  withAsyncInvoiceStatusConfigs,
  pure,
);

const CreatePayrollsWithoutTrips = enhanceCreatePayrollsWithoutTrips(PayrollForm);

export default CreatePayrollsWithoutTrips;
