import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportSagas } from '../../report-common';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature rate-shop
import * as A from './actions';
import * as S from './selectors';
import {
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectPageVisited,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

// report
function* handleGetItemListSaga({ payload }: Object) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader({ showDimmer: true }));
    }

    yield put(A.setListLoading(true));

    const availableReports = yield select(makeSelectAvailableReports());
    const reportParams = yield select(makeSelectUsedReport());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.includes('Default', reportParams.guid),
    )) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const guids = R.pathOr(null, ['guids'], payload);

    const { limit, offset } = pagination;

    const reqBody = {
      guids,
      limit,
      offset,
      fields: [],
      orderFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      systemFields: [{ sequence: 100, name: GC.FIELD_GUID }],
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const reqData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const res = yield call(sendRequest, 'post', endpointsMap.excelMassRatingSummaryList, { data: reqData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }
    yield put(A.setListLoading(false));
    yield put(closeLoader());
  } catch (err) {
    yield put(A.setListLoading(false));
    yield put(closeLoader());

    yield call(G.handleException, err, 'handleGetItemListSaga exception');
  }
}

function* importFromFileRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const formData = new window.FormData();

    formData.append('file', payload.file);
    formData.append('data', JSON.stringify(R.assoc(GC.BRANCH_GUID, branchGuid, payload)));

    const options = { data: formData };

    const res = yield call(sendRequest, 'post', endpointsMap.excelMassRatingProcessFile, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'importFromFileRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'importFromFileRequest fail');
  }
}

function* removeExcelMassRatingSummaryRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      data: payload,
    };

    const res = yield call(sendRequest, 'delete', endpointsMap.excelMassRatingMassDelete, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeExcelMassRatingSummarySuccess(payload));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'removeExcelMassRatingSummaryRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeExcelMassRatingSummaryRequest fail');
  }
}

function* getExcelResultsByRowGuidRequest({ payload }: boolean) {
  try {
    const {
      pagination,
      titleOrderFields,
      titleFilterParams,
      excelMassRatingSummaryGuid,
    } = payload;

    yield put(openLoader());
    const reportParams = yield select(makeSelectUsedReport());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const orderFields = G.ifElse(
      G.isNotNilAndNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotNilAndNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const reqBody = {
      ...pagination,
      orderFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      systemFields: [{ sequence: 100, name: GC.FIELD_GUID }],
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const options = {
      data: G.setSearchCriteria({ reqBody }),
      params: {
        [GC.FIELD_EXCEL_MASS_RATING_SUMMARY_GUID]: excelMassRatingSummaryGuid,
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.excelMassRatingResultList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeLoader());
      yield put(A.getExcelResultsByRowGuidSuccess(R.assoc(
        GC.FIELD_EXCEL_MASS_RATING_SUMMARY_GUID,
        excelMassRatingSummaryGuid,
        data,
      )));
    } else {
      yield put(closeLoader());

      yield call(G.handleFailResponse, res, 'getExcelResultsByRowGuidRequest fail');
    }
  } catch (err) {
    yield put(closeLoader());

    yield call(G.handleException, err, 'getExcelResultsByRowGuidRequest exception');
  }
}

function* exportExcelResultReportRequest({ payload }: Object) {
  try {
    const { name, fields, orderFields, showAllPrices, excelMassRatingSummaryGuid } = payload;

    yield put(openLoader({ showDimmer: true }));

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const searchCriteria = transformSearchCriteriaBeforeReportPost(payload.searchCriteria);

    const reqBody = {
      fields,
      orderFields,
      showAllPrices,
      searchCriteria,
      [GC.FIELD_REPORT_NAME]: name,
      summaryGuid: excelMassRatingSummaryGuid,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };

    const options = {
      resType: 'arraybuffer',
      params: { format: 'xlsx' },
      data: G.setSearchCriteria({ reqBody }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.excelMassRatingResultListExport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'exportExcelResultReportRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'exportExcelResultReportRequest exception');
  }
}

function* updatePricesByRowGuidRequest({ payload }: Object) {
  try {
    const { excelRatingResultGuid, excelMassRatingSummaryGuid } = payload;

    yield put(openLoader());

    const options = {
      params: { [GC.FIELD_EXCEL_RATING_RESULT_GUID]: excelRatingResultGuid },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.excelMassRatingResultUpdatePrices, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.resetExcelResultsListAndPaginationByRowGuid(excelMassRatingSummaryGuid));
      yield put(A.getExcelResultsByRowGuidRequest({
        excelMassRatingSummaryGuid,
        pagination: { offset: 0, limit: 20 },
      }));
    } else {
      yield call(G.handleFailResponse, res, 'updatePricesByRowGuidRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updatePricesByRowGuidRequest fail');
  }
}

function* updateAllPricesByRowGuidRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      params: { [GC.FIELD_EXCEL_MASS_RATING_SUMMARY_GUID]: payload },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.excelMassRatingResultUpdateAllPrices, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.resetExcelResultsListAndPaginationByRowGuid(payload));
      yield put(A.getExcelResultsByRowGuidRequest({
        pagination: { offset: 0, limit: 20 },
        [GC.FIELD_EXCEL_MASS_RATING_SUMMARY_GUID]: payload,
      }));
    } else {
      yield call(G.handleFailResponse, res, 'updateAllPricesByRowGuidRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateAllPricesByRowGuidRequest fail');
  }
}

const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(
  GC.EXCEL_RATING_RESULT_REPORT,
  A,
  handleGetItemListSaga,
  { S, createUpdateReportSuccessCallback },
);

function* visitRateShopPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_RATE_SHOP_PAGE);

    const pageVisited = yield select(makeSelectPageVisited());

    if (G.isTrue(pageVisited)) {
      yield put(A.setInitialStateOmitReport(['itemList']));
    } else {
      yield put(A.setInitialState());
    }

    yield put(openLoader());

    yield put(A.setReportPending());
    yield put(A.setIgnorePromptStatus(false));

    yield call(handleAvailableReportsRequest, { payload, notSetUsedReport: pageVisited });
    yield call(handleGetItemListSaga, { payload: { openLoader: true } });

    yield put(closeLoader());
    break;
  }
}

function* rateShopWatcherSaga() {
  // visit page
  yield takeLatest(GC.VISIT_RATE_SHOP_PAGE, visitRateShopPageSaga);
  // report
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.importFromFileRequest, importFromFileRequest);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.getAvailableReportsRequest, handleAvailableReportsRequest);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  // item
  yield takeLatest(A.removeExcelMassRatingSummaryRequest, removeExcelMassRatingSummaryRequest);
  // excel results
  yield takeLatest(A.updatePricesByRowGuidRequest, updatePricesByRowGuidRequest);
  yield takeLatest(A.exportExcelResultReportRequest, exportExcelResultReportRequest);
  yield takeLatest(A.updateAllPricesByRowGuidRequest, updateAllPricesByRowGuidRequest);
  yield takeLatest(A.getExcelResultsByRowGuidRequest, getExcelResultsByRowGuidRequest);
}

export default rateShopWatcherSaga;
