import styled, { css } from 'styled-components';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const FakeLink = styled.p`
  margin: 0;
  cursor: pointer;
  font-size: 14px;
  padding-left: 5px;
  width: max-content;
  color: ${() => G.getTheme('common.linkColor')};
`;

const flexLeft = css`
  display: flex;
  align-items: center;
  justify-content: left;
`;

const itemStyles = css`
  padding: 10px 20px;
`;

export const SectionWrapper = styled.section`
  background-color: ${() => G.getTheme('colors.white')};
`;

export const SectionHeader = styled.section`
  ${flexLeft};
`;

export const SectionLeftItem = styled.div`
  ${itemStyles};
  width: 320px;
`;

export const SectionRightItem = styled.div`
  ${itemStyles};
`;
