import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers } from 'react-recompose';
// components
import { openModal } from '../../../../components/modal/actions';
// features
import AppReleaseComponent from '../../../app-release';
import { makeSelectAppVersionRelease, makeSelectAppReleaseByExternalUrl } from '../../../app-release/selectors';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import { Box, Flex, Text } from '../../../../ui';
// feature navbar-menu
import { ReleaseVersionLink, ReleaseVersionText } from './ui';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleOpenVersionModal: ({ openModal }: Object) => () => {
      const modal = {
        p: 15,
        component: <AppReleaseComponent />,
        options: {
          width: 600,
          type: 'modal',
          position: 'center',
          height: 'max-content',
        },
      };

      openModal(modal);
    },
  }),
);

const VersionRelease = ({
  appRelease,
  iconsColor,
  latestRelease,
  handleOpenVersionModal,
}: Object) => (
  <Box color={iconsColor}>
    <Flex
      p='4px 10px'
      justifyContent='space-between'
      background={G.getTheme('colors.light.navbarToggle')}
    >
      <Text fontSize={12}>{G.getWindowLocale('titles:panel-hide', 'Hide Panel')}</Text>
      {/* TODO: Remove after testing */}
      {/* <ReleaseVersionText
        withHover={true}
        colorHover={true}
        onClick={handleOpenVersionModal}
      >
        {G.getWindowLocale('titles:version', 'Version')} {appRelease}
      </ReleaseVersionText> */}
      {
        latestRelease &&
        <ReleaseVersionLink
          target='_blank'
          withHover={true}
          href={latestRelease.url}
        >
          {G.getWindowLocale('titles:version', 'Version')} {latestRelease.version}
        </ReleaseVersionLink>
      }
    </Flex>
  </Box>
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  appRelease: makeSelectAppVersionRelease(state),
  latestRelease: makeSelectAppReleaseByExternalUrl(state),
}));

export default connect(mapStateToProps, {
  openModal,
})(enhance(VersionRelease));
