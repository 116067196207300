import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../helpers';
// report-common
import { getReportSelectors } from '../../report-common';
//////////////////////////////////////////////////

const {
  store,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectUsedReport,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} = getReportSelectors('quotes');

const makeSelectQuotesList = () => createSelector(
  store,
  ({ itemList }: Object) => R.sortBy(R.prop('index'), R.values(itemList)),
);

const makeSelectRouteTab = () => createSelector(
  store,
  ({ routeTab }: Object) => routeTab,
);

const makeSelectListType = () => createSelector(
  store,
  ({ listType }: Object) => listType,
);

const makeSelectSelectedList = () => createSelector(
  store,
  R.compose(
    R.values,
    R.map(G.getGuidFromObject),
    R.filter(({ selected }: Object) => selected),
    R.pathOr([], ['itemList']),
  ),
);

export {
  makeSelectRouteTab,
  makeSelectListType,
  makeSelectQuotesList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectPageVisited,
  makeSelectListLoading,
  makeSelectSelectedList,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
};
