import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
import { makeSelectStyling } from '../../styling/selectors';
import { makeSelectCurrentBranch } from '../../branch/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { FixedBox } from '../../../ui';
//////////////////////////////////////////////////

const BranchTitle = ({
  styling,
  iconsColor,
  currentBranch,
  handleOpenEntTree,
}: Object) => (
  <AuthWrapper has={[PC.BRANCH_READ, PC.BRANCH_WRITE]}>
    <FixedBox
      top='0'
      left='50%'
      p='2px 15px'
      fontSize={12}
      cursor='pointer'
      fontWeight='bold'
      color={iconsColor}
      width='max-content'
      onClick={handleOpenEntTree}
      transform='translateX(-50%)'
      borderBottomLeftRadius={20}
      borderBottomRightRadius={20}
      boxShadow='0 0 5px 0 rgba(0, 0, 0, 0.3)'
      bg={R.or(styling.backgroundColor, G.getTheme('colors.light.darkRed'))}
    >
      {R.prop(GC.FIELD_BRANCH_NAME, currentBranch)}
    </FixedBox>
  </AuthWrapper>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  styling: makeSelectStyling(state),
  currentBranch: makeSelectCurrentBranch(state),
});

export default connect(mapStateToProps, {})(BranchTitle);
