// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { getReferenceScopeOptions } from '../../../helpers/options';
//////////////////////////////////////////////////

export const getFilterParams = [
  {
    value: 'name',
    type: 'string',
    name: G.getWindowLocale('titles:name', 'Name'),
  },
  {
    type: 'string',
    value: GC.FIELD_BRANCH_DOT_BRANCH_NAME,
    name: G.getWindowLocale('titles:branch', 'Branch'),
  },
  {
    value: 'scopes.name',
    type: 'selectMultiple',
    options: getReferenceScopeOptions(),
    name: G.getWindowLocale('titles:type', 'Type'),
    placeholder: {
      text: 'Choose',
      key: 'titles:choose',
    },
  },
  {
    type: 'string',
    value: 'description',
    name: G.getWindowLocale('titles:description', 'Description'),
  },
];
