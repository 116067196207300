import * as Yup from 'yup';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

export const validationSchemaChargeObject = Yup.object().shape({
  [GC.FIELD_CHARGE_RATE_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CHARGE_RATE_NAME_STORED]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CHARGE_TYPE]: Yup.string()
    .nullable(true)
    .notRequired(),
  [GC.FIELD_CHARGE_RATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CHARGE_RATE_TYPE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CHARGE_RATE_UNIT]: Yup.string()
    .nullable(true)
    .notRequired(),
  [GC.FIELD_CHARGE_QUANTITY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CHARGE_NON_TAXABLE]: Yup.boolean()
    .nullable(true)
    .notRequired(),
  [GC.FIELD_CHARGE_TOTAL]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CHARGE_SEQUENCE_NUM]: Yup.number()
    .nullable(true)
    .notRequired(),
});

export const validationSchemaPayrollObject = {
  [GC.FIELD_PAYROLL_STATUS]: Yup.string()
    .notRequired()
    .nullable(true),
  [GC.FIELD_PAYROLL_CURRENCY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_PAYROLL_DATE_FROM]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_PAYROLL_DATE_TO]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_PAYROLL_NET_DAYS]: Yup.number()
    .nullable(true)
    .notRequired()
    .min(0, G.getShouldBePositiveLocaleTxt())
    .max(365, G.getShouldBeFromToLocaleTxt(0, 365))
    .typeError('Should be a ${type}'), // eslint-disable-line
  [GC.FIELD_PAYROLL_DUE_DATE]: Yup.string()
    .nullable(true)
    .notRequired(),
  [GC.FIELD_PAYROLL_CHECK_NUMBER]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(30, G.getShouldBeFromToLocaleTxt(0, 30)),
  [GC.FIELD_PAYROLL_CHECK_DATE]: Yup.string()
    .nullable(true)
    .notRequired(),
  [GC.FIELD_PAYROLL_CHECK_AMOUNT]: Yup.number()
    .nullable(true)
    .notRequired()
    .positive(G.getShouldBePositiveLocaleTxt())
    .max(12000000, G.getShouldBeFromToLocaleTxt(0, 12000000))
    .typeError('Should be a ${type}'), // eslint-disable-line
  [GC.FIELD_PAYROLL_DEPOSIT_DATE]: Yup.string()
    .nullable(true)
    .notRequired(),
  [GC.FIELD_PAYROLL_CHARGES]: Yup.array()
    .of(validationSchemaChargeObject)
    .nullable(true)
    .notRequired(),
};

export const validationSchemaPayrollObjectNumber = {
  [GC.FIELD_PAYROLL_NUMBER]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(50, G.getShouldBeFromToLocaleTxt(0, 50)),
};
