import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { TextBtn1 } from '../../../components/text-btns';
// features
import CarrierScorecards from '../../carrier-scorecards';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

const ShowScorecards = ({ handleShowScorecards }: Object) => (
  <TextBtn1
    onClickHandler={handleShowScorecards}
    text={G.getWindowLocale('titles:show-scorecards', 'Show Scorecards')}
  />
);

const enhance = compose(
  withHandlers({
    handleShowScorecards: (props: Object) => () => {
      const { openModal, branchGuid, carrierGuid, carrierName } = props;

      const component = (
        <CarrierScorecards carrierGuid={carrierGuid} carrierName={carrierName} divisionGuid={branchGuid} />
      );

      const options = {
        p: '0px',
        component,
        options: {
          width: '90vw',
          maxWidth: 1550,
          minHeight: 600,
          overflow: 'auto',
          maxHeight: '90vh',
          outsideCloseButton: true,
          title: G.getWindowLocale('titles:score-cards', 'Score Cards'),
        },
      };

      return openModal(options);
    },
  }),
  pure,
);

export default enhance(ShowScorecards);
