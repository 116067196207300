import * as Yup from 'yup';
import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withProps, withHandlers } from 'react-recompose';
// components
import { TextBtn1 } from '../../../components/text-btns';
import { ChargeFormFooter } from '../../../components/form-footer';
import PriceSheetItems from '../../../components/price-sheet-items';
import { OrderChargesTotal } from '../../../components/charge/components';
import { DOChargeComponent2 } from '../../../components/charge/formik/charges-array';
import { withRecalculateAllChargesId, getSharedAccessorialsApplyTo } from '../../../components/charge/hocs';
import {
  mapDefaultAccessorials,
  withAsyncChargeAccessorials,
} from '../../../components/charge/hocs/with-async-charge-accessorials';
// features
import SectionDivider from '../../new-do/components/section-divider';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { asyncGetAccessorialsList } from '../../../helpers/api-async';
// hocs
import {
  withAsyncConfigs,
  withConnectModalAndLoaderActions,
  withAsyncGetTransportationModeGrouping,
} from '../../../hocs';
// schemes
import * as VS from '../../../schemes';
// ui
import { Box, Flex, StickedBox } from '../../../ui';
// feature rate
import { setDefaultCustomerRateValues } from '../helpers';
import { withOrderRateSyncWithTrips, withHandleCloRateAvailableRates } from './hocs';
import { withApplyOrderRateUpliftForm } from '../components/apply-order-rate-uplift-form';
import {
  maxTripRateField,
  mainCustomerRateFields,
  totalCustomerRateFields,
  contactCustomerRateFields,
} from './settings';
//////////////////////////////////////////////////

export const validationSchemaCustomerRate = Yup.object()
  .shape(VS.validationSchemaTripServiceObject)
  .shape(VS.validationSchemaTripTotalObject)
  .shape(VS.validationSchemaContactObject);

const CustomerCharges = (props: Object) => {
  const {
    services,
    branchGuid,
    isEditMode,
    isCreateMode,
    stopsQuantity,
    recalculateAllChargesId,
    defaultOrderAccessorials,
    sharedAccessorialsApplyTo,
    defaultOrderFuelAccessorial,
  } = props;

  const commonTotalProps = G.getTotalsFromValues(props);

  const currency = R.pathOr('', ['values', GC.FIELD_CURRENCY], props);
  const serviceDays = R.pathOr('', ['values', GC.FIELD_SERVICE_DAYS], props);
  const normalizedTotal = R.pathOr({}, ['values', GC.SYSTEM_OBJECT_NORMALIZED_TOTAL], props);

  return (
    <div>
      <DOChargeComponent2
        {...commonTotalProps}
        rateProps={props}
        isCustomer={true}
        currency={currency}
        services={services}
        branchGuid={branchGuid}
        loadType={GC.FIELD_CLO}
        isEditMode={isEditMode}
        serviceDays={serviceDays}
        isCreateMode={isCreateMode}
        stopsQuantity={stopsQuantity}
        normalizedTotal={normalizedTotal}
        chargesArrayName={GC.FIELD_CHARGES}
        recalculateAllChargesId={recalculateAllChargesId}
        defaultOrderAccessorials={defaultOrderAccessorials}
        sharedAccessorialsApplyTo={sharedAccessorialsApplyTo}
        defaultOrderFuelAccessorial={defaultOrderFuelAccessorial}
      />
    </div>
  );
};

const fieldsWrapperStyles = { px: 15 };

const CustomerRateForm = (props: Object) => {
  const {
    values,
    closeModal,
    isEditMode,
    handleSubmit,
    asyncConfigs,
    initialValues,
    showSyncWithTripsBtn,
    showAvailableRatesBtn,
    handleOpenAutoRateModal,
    handleApplyOrderRateUpliftForm,
    handleSetRecalculateAllChargesId,
    handleGetSharedAccessorialsApplyTo,
  } = props;

  const charges = G.getChargesFromObject(values);

  const useTripMaxPay = R.or(
    G.getConfigValueFromStore(GC.RATE_ENGINE_USE_TRIP_MAX_PAY, asyncConfigs),
    G.isNotNilAndNotEmpty(G.getPropFromObject(GC.FIELD_TRIP_MAX_PAY, initialValues)),
  );

  return (
    <Box width='755px' bg='white'>
      <StickedBox top='0px' zIndex={15}>
        <Flex
          p='7px 10px'
          color='white'
          bg='dark.darkBlue'
          justifyContent='space-between'
        >
          <Box>
            {G.getWindowLocale('titles:customer-rate', 'Customer Rate')}
          </Box>
          <Flex>
            <TextBtn1
              onClickHandler={handleSetRecalculateAllChargesId}
              text={G.getWindowLocale('titles:recalc-charges', 'Recalc Charges')}
            />
            <TextBtn1
              onClickHandler={handleGetSharedAccessorialsApplyTo}
              text={G.getWindowLocale('titles:update-auto-acc', 'Update Auto Acc.')}
            />
            {showSyncWithTripsBtn && (
              <TextBtn1
                onClickHandler={handleApplyOrderRateUpliftForm}
                text={G.getWindowLocale('titles:sync-with-trips', 'Sync With Trips')}
              />
            )}
            {showAvailableRatesBtn && (
              <TextBtn1
                onClickHandler={handleOpenAutoRateModal}
                text={G.getWindowLocale('titles:show-rates', 'Show Rates')}
              />
            )}
          </Flex>
        </Flex>
      </StickedBox>
      <form onSubmit={handleSubmit}>
        <Box pt={20}>
          <Fieldset2
            {...G.getFormikProps(props)}
            fields={mainCustomerRateFields}
            fieldsWrapperStyles={fieldsWrapperStyles}
            {...G.getModeServiceFromAsyncConfigsForRateSelect(props)}
          />
          <Fieldset2
            {...G.getFormikProps(props)}
            fields={totalCustomerRateFields}
            fieldsWrapperStyles={fieldsWrapperStyles}
          />
          <SectionDivider
            text={G.getWindowLocale('titles:contact-info', 'Contact Info', { caseAction: 'upperCase' })}
          />
          <Fieldset2
            {...G.getFormikProps(props)}
            fields={contactCustomerRateFields}
            fieldsWrapperStyles={fieldsWrapperStyles}
          />
          {
            useTripMaxPay &&
            <SectionDivider />
          }
          {
            useTripMaxPay &&
            <Fieldset2
              {...G.getFormikProps(props)}
              fields={R.of(Array, maxTripRateField)}
              fieldsWrapperStyles={fieldsWrapperStyles}
            />
          }
          {
            isEditMode &&
            <PriceSheetItems
              values={values}
              additionalFormHeaderSectionStyles={{ my: 0, m: '20px 0 10px 0' }}
            />
          }
          <CustomerCharges {...props} />
          <StickedBox bottom='0px' zIndex={13}>
            <OrderChargesTotal {...props} charges={charges} />
            <ChargeFormFooter closeModal={closeModal} />
          </StickedBox>
        </Box>
      </form>
    </Box>
  );
};

const configsNamesArray = [
  GC.GENERAL_MODE_TRANSPORTATION,
  GC.RATE_ENGINE_USE_TRIP_MAX_PAY,
  GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
];

const withSharedAccessorialsApplyTo = withHandlers({
  handleGetSharedAccessorialsApplyTo: (props: Object) => async () => {
    const { values, branchGuid, setValues, handleSetRecalculateAllChargesId } = props;

    const accessorials = await asyncGetAccessorialsList(branchGuid);
    const sharedApplyTo = await getSharedAccessorialsApplyTo(props, branchGuid);

    const mapped = mapDefaultAccessorials(sharedApplyTo, accessorials, true);
    const rateCharges = R.pathOr([], [GC.FIELD_CHARGES], values);
    const charges = G.replaceAutoChargesWithNewSharedAssessorialsApplyTo(rateCharges, mapped);
    const newValues = R.assoc(GC.FIELD_CHARGES, charges, values);

    setValues(newValues);

    handleSetRecalculateAllChargesId();
  },
});

const enhanceCreate = compose(
  withRecalculateAllChargesId,
  withConnectModalAndLoaderActions,
  withProps(() => ({ configsNamesArray, isEditMode: false, showAvailableRatesBtn: true })),
  withAsyncConfigs,
  withAsyncChargeAccessorials({ isCustomer: true, isCreateMode: true }),
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaCustomerRate,
    mapPropsToValues: (props: Object) => setDefaultCustomerRateValues(props),
    handleSubmit: (values: Object, { props }: Object) => {
      const { handleSendCloRate } = props;

      handleSendCloRate(G.omitEmptyChargesFromData(values, GC.FIELD_CHARGES));

      if (G.isNotNilAndNotEmpty(G.getPropFromObject('telCarrierRate', values))) {
        const message = G.getWindowLocale('messages:infoCarrierRatesCanAlsoBeChanged');

        G.showToastrMessageSimple('info', message);
      }
    },
    displayName: 'ADD_CUSTOMER_RATE_FORM',
  }),
  withHandleCloRateAvailableRates,
  withSharedAccessorialsApplyTo,
  pure,
);

const enhanceUpdate = compose(
  withRecalculateAllChargesId,
  withConnectModalAndLoaderActions,
  withProps(() => ({
    isEditMode: true,
    configsNamesArray,
    showSyncWithTripsBtn: true,
    showAvailableRatesBtn: true,
  })),
  withAsyncConfigs,
  withAsyncGetTransportationModeGrouping,
  withAsyncChargeAccessorials({ isCustomer: true, isCreateMode: false }),
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaCustomerRate,
    mapPropsToValues: (props: Object) => setDefaultCustomerRateValues(props),
    handleSubmit: (values: Object, { props }: Object) => {
      const { selected, transportationMode } = values;

      const { initialValues, handleSendCloRate, handleCheckModeGroupingAndShowConfirmMessage } = props;

      const callback = (loadData: Object) => handleSendCloRate(
        G.omitEmptyChargesFromData(values, GC.FIELD_CHARGES),
        loadData,
      );

      if (G.isNotNilAndNotEmpty(G.getPropFromObject('telCarrierRate', values))) {
        const message = G.getWindowLocale('messages:infoCarrierRatesCanAlsoBeChanged');

        G.showToastrMessageSimple('info', message);
      }

      if (R.or(
        G.isFalse(selected),
        R.pathEq(transportationMode, [GC.FIELD_MODE, GC.FIELD_DROPDOWN_OPTION_GUID], initialValues),
      )) return callback();

      handleCheckModeGroupingAndShowConfirmMessage({ callback, transportationMode });
    },
    displayName: 'EDIT_CUSTOMER_RATE_FORM',
  }),
  withHandleCloRateAvailableRates,
  withOrderRateSyncWithTrips({ updateValues: true }),
  withApplyOrderRateUpliftForm({ submitActionName: 'handleSyncWithTrips' }),
  withSharedAccessorialsApplyTo,
  pure,
);

export default enhanceCreate(CustomerRateForm);

export const EditCustomerRateForm = enhanceUpdate(CustomerRateForm);
