import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box, Flex, SectionsDivider } from '../../../../ui';
// feature payroll
import TotalRow from '../../components/total-row';
import { getVendorPayrollTotalsByCurrency } from '../../helpers';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const blueColor = G.getTheme('colors.light.blue');
const greenColor = G.getTheme('colors.light.green');
const redColor = G.getTheme('colors.light.mainRed');
const darkGreyColor = G.getTheme('colors.darkGrey');
const lightGreyColor = G.getTheme('colors.lightGrey');

const PayrollTotals = (props: Object) => {
  const payrollCurrency = G.getCurrencySymbol(R.pathOr(
    GC.DEFAULT_UI_CURRENCY,
    ['values', GC.FIELD_CURRENCY],
    props,
  ));

  const { currencyTotals, availableCurrencies, payrollCurrencyTotals } = getVendorPayrollTotalsByCurrency(props);

  const rowStyles = {
    my: '3px',
    fontSize: 12,
    width: '100%',
    color: G.getTheme('colors.light.grey'),
  };

  const totalStyles = {
    my: '3px',
    fontSize: 12,
    width: '100%',
    borderRadius: '4px',
  };

  return (
    <Flex
      mr={15}
      height={306.5}
      minWidth={450}
      maxWidth={670}
      bg={whiteColor}
      borderRadius={6}
      flexDirection='column'
      alignItems='flex-start'
    >
      <Flex
        p={15}
        height={32}
        width='100%'
        fontSize={11}
        bg={lightGreyColor}
        color={darkGreyColor}
        borderTopLeftRadius='6px'
        borderTopRightRadius='6px'
      >
        <Box width={170}>{G.getWindowLocale('titles:currency', 'Currency')}</Box>
        {
          availableCurrencies.map((currency: string, i: number) => (
            <Box key={i} width={90}>{currency}</Box>
          ))
        }
        <Box ml='auto' width={100}>
          {G.getWindowLocale('titles:payroll-totals', 'Payroll Totals')}
        </Box>
      </Flex>
      <Flex
        px={15}
        py='3px'
        width='100%'
        flexDirection='column'
        alignItems='flex-start'
      >
        <Box mb='3px' fontSize={12} color={greenColor}>
          {G.getWindowLocale('titles:income', 'Income')}:
        </Box>
        <TotalRow
          wrapperStyles={rowStyles}
          currencies={availableCurrencies}
          payrollCurrency={payrollCurrency}
          totals={R.path(['invoicesTotals'], currencyTotals)}
          rowTotal={R.pathOr(0, ['invoicesTotal'], payrollCurrencyTotals)}
          label={`${G.getWindowLocale('titles:total-vendor-trip-charges', 'Total Vendor Trip Charges')}:`}
        />
        <TotalRow
          wrapperStyles={rowStyles}
          currencies={availableCurrencies}
          payrollCurrency={payrollCurrency}
          totals={R.path(['additionalPayrollPayTotals'], currencyTotals)}
          rowTotal={R.pathOr(0, ['additionalPayrollPayTotal'], payrollCurrencyTotals)}
          label={`${G.getWindowLocale('titles:additional-payroll-pay', 'Additional Payroll Pay')}:`}
        />
        <TotalRow
          currencies={availableCurrencies}
          payrollCurrency={payrollCurrency}
          totals={R.path(['incomeSubtotals'], currencyTotals)}
          wrapperStyles={{ ...totalStyles, color: greenColor }}
          label={`${G.getWindowLocale('titles:subtotal', 'subtotal')}:`}
          rowTotal={R.pathOr(0, ['incomeSubtotal'], payrollCurrencyTotals)}
        />
      </Flex>
      <SectionsDivider borderColor={lightGreyColor} />
      <Flex
        px={15}
        py='3px'
        width='100%'
        flexDirection='column'
        alignItems='flex-start'
      >
        <Box my='3px' fontSize={12} color={redColor}>
          {G.getWindowLocale('titles:deductions', 'Deductions')}:
        </Box>
        <TotalRow
          wrapperStyles={rowStyles}
          currencies={availableCurrencies}
          payrollCurrency={payrollCurrency}
          totals={R.path(['vendorDeductionTotals'], currencyTotals)}
          rowTotal={R.pathOr(0, ['vendorDeductionTotal'], payrollCurrencyTotals)}
          label={`${G.getWindowLocale('titles:vendor-deduction', 'Vendor Deduction')}:`}
        />
        <TotalRow
          wrapperStyles={rowStyles}
          currencies={availableCurrencies}
          payrollCurrency={payrollCurrency}
          totals={R.path(['fuelCardsTotals'], currencyTotals)}
          rowTotal={R.pathOr(0, ['fuelCardsTotal'], payrollCurrencyTotals)}
          label={`${G.getWindowLocale('titles:fuel-cards', 'Fuel Cards')}:`}
        />
        <TotalRow
          wrapperStyles={rowStyles}
          currencies={availableCurrencies}
          payrollCurrency={payrollCurrency}
          totals={R.path(['tollChargesTotals'], currencyTotals)}
          rowTotal={R.pathOr(0, ['tollChargesTotal'], payrollCurrencyTotals)}
          label={`${G.getWindowLocale('titles:toll-charges', 'Toll Charges')}:`}
        />
        <TotalRow
          wrapperStyles={rowStyles}
          currencies={availableCurrencies}
          payrollCurrency={payrollCurrency}
          totals={R.path(['driverPayrollsTotals'], currencyTotals)}
          rowTotal={R.pathOr(0, ['driverPayrollsTotal'], payrollCurrencyTotals)}
          label={`${G.getWindowLocale('titles:driver-payrolls', 'Driver Payrolls')}:`}
        />
        <TotalRow
          wrapperStyles={rowStyles}
          currencies={availableCurrencies}
          payrollCurrency={payrollCurrency}
          totals={R.path(['expensesTotals'], currencyTotals)}
          label={`${G.getWindowLocale('titles:expenses', 'Expenses')}:`}
          rowTotal={R.pathOr(0, ['expensesTotal'], payrollCurrencyTotals)}
        />
        <TotalRow
          currencies={availableCurrencies}
          payrollCurrency={payrollCurrency}
          wrapperStyles={{ ...totalStyles, color: redColor }}
          totals={R.path(['deductionsTotals'], currencyTotals)}
          label={`${G.getWindowLocale('titles:subtotal', 'subtotal')}:`}
          rowTotal={R.pathOr(0, ['deductionsTotal'], payrollCurrencyTotals)}
        />
        <TotalRow
          label=''
          rowTotal={null}
          currencies={availableCurrencies}
          payrollCurrency={payrollCurrency}
          totals={R.path(['grandTotals'], currencyTotals)}
          wrapperStyles={{ ...totalStyles, color: blueColor }}
        />
      </Flex>
      <SectionsDivider borderColor={lightGreyColor} />
      <Box
        ml='auto'
        p='4px 15px'
        fontSize={13}
        fontWeight={700}
        color={blueColor}
      >
        {
          `${G.getWindowLocale('titles:gross-total', 'Gross Total')}: ${
            payrollCurrency} ${R.pathOr(0, ['grandTotal'], payrollCurrencyTotals)}`
        }
      </Box>
    </Flex>
  );
};

export {
  PayrollTotals,
};
