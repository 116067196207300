import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

const selectUserSettingsStore = ({ userSettings }: Object) => userSettings;

const makeSelectUserSettings = () => createSelector(
  selectUserSettingsStore,
  ({ settings }: Object) => settings,
);

const makeSelectUserReferences = () => createSelector(
  selectUserSettingsStore,
  (state: Object) => state[GC.UI_FIELD_REFERENCES],
);

const makeSelectUserRoles = () => createSelector(
  selectUserSettingsStore,
  ({ roles }: Object) => roles,
);

const makeSelectUserSettingsFields = () => createSelector(
  selectUserSettingsStore,
  ({ fields }: Object) => fields,
);

const makeSelectCurrentUserSettingsFields = () => createSelector(
  selectUserSettingsStore,
  ({ currentUserSettings }: Object) => currentUserSettings,
);

const makeSelectPinnedBranches = () => createSelector(
  selectUserSettingsStore,
  (state: Object) => R.pathOr([], ['currentUserSettings', GC.PINNED_BRANCHES_GUIDS], state),
);

const makeSelectPinnedMenuItems = () => createSelector(
  selectUserSettingsStore,
  (state: Object) => R.pathOr([], ['currentUserSettings', 'pinnedMenuItems'], state),
);

const makeSelectSortedByNamePinnedBranches = () => createSelector(
  selectUserSettingsStore,
  (state: Object) => R.compose(
    R.sortBy(R.compose(R.toLower, R.prop(GC.BRANCH_NAME))),
    R.filter(G.isNotNil),
    R.map(G.getBranchFromWindowByGuid),
    R.pathOr([], ['currentUserSettings', GC.PINNED_BRANCHES_GUIDS]),
  )(state),
);

const makeSelectAvailableByUserTypeRoleList = () => createSelector(
  selectUserSettingsStore,
  ({ availableByUserTypeRoleList }: Object) => availableByUserTypeRoleList,
);

export {
  makeSelectUserRoles,
  makeSelectUserSettings,
  selectUserSettingsStore,
  makeSelectUserReferences,
  makeSelectPinnedBranches,
  makeSelectPinnedMenuItems,
  makeSelectUserSettingsFields,
  makeSelectCurrentUserSettingsFields,
  makeSelectSortedByNamePinnedBranches,
  makeSelectAvailableByUserTypeRoleList,
};
