import React from 'react';
import { connect } from 'react-redux';
import { OuterClick } from 'react-outer-click';
import Popover from '@mui/material/Popover';
import {
  compose,
  withProps,
  withState,
  withHandlers,
} from 'react-recompose';
// components
import { openModal } from '../../../../components/modal/actions';
import CreateCarrier from '../../../../components/create-carrier';
// features
import { AuthWrapper } from '../../../permission';
import PC from '../../../permission/role-permission';
import { openAddBranchModalRequest } from '../../../branch/actions';
// helpers/constants
import * as G from '../../../../helpers';
// icons
import * as I from '../../../../svgs';
// utilities
import { getOrderEntryRoute } from '../../../../utilities/routes';
// ui
import { Box } from '../../../../ui';
// feature navbar-menu
import NavbarButton from './navbar-button';
import { InnerPopover, WrapperCss, LinksList } from './ui';
//////////////////////////////////////////////////

const NavItem = ({ has, onClick, title }: Object) => (
  <AuthWrapper has={has}>
    <Box p='6px' flex={1} onClick={onClick}>
      {title}
    </Box>
  </AuthWrapper>
);

const enhance = compose(
  withProps(() => ({
    buttonRef: React.createRef(),
  })),
  withState('anchorEl', 'setAnchorEl', null),
  withHandlers({
    onShowPopover: ({ setAnchorEl, buttonRef }: Object) => (e: Event) => {
      G.stopPropagation(e);
      setAnchorEl(buttonRef.current);
    },
    onHidePopover: ({ setAnchorEl }: Object) => () => {
      setAnchorEl(null);
    },
  }),
  withHandlers({
    onHandleClick: ({ onHidePopover }: Object) => (handler: Function) =>
      (e: Object) => {
        handler(e);
        onHidePopover(e);
      },
    handleCreateCarrier: ({ openModal }: Object) => () => {
      const component = <CreateCarrier />;
      const modal = {
        p: 15,
        component,
        options: {
          width: 340,
          version: 2,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
          title: G.getWindowLocale('titles:add-new-carrier', 'Create Carrier'),
        },
      };

      openModal(modal);
    },
  }),
);
const CreateNew = ({
    anchorEl,
    buttonRef,
    iconsColor,
    onShowPopover,
    onHidePopover,
    onHandleClick,
    handleCreateCarrier,
    openAddBranchModalRequest,
  }: Object) => {
  const open = G.isNotNil(anchorEl);

  return (
    <div ref={buttonRef} css={WrapperCss}>
      <NavbarButton
        text='Create'
        iconsColor={iconsColor}
        onMouseDown={onShowPopover}
        icon={I.plusInCircleFilled}
        has={[PC.BRANCH_WRITE, PC.CLO_WRITE, PC.CREATE_CARRIER_EXECUTE]}
      />
      <OuterClick onOuterClick={onHidePopover}>
        <Popover
          open={open}
          id='create-popover'
          hideBackdrop={true}
          anchorEl={anchorEl}
          onClose={onHidePopover}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          elevation={0}
          style={{ pointerEvents: 'none' }}
          PaperProps={{
            style: {
              borderRadius: 0,
              overflow: 'unset',
              boxShadow: 'none',
              pointerEvents: 'all',
              backgroundColor: 'transparent',
            },
          }}
        >
          <InnerPopover>
            <LinksList
              p='0px'
              fontSize='14px'
              fontWeight={500}
              lineHeight='152.34%'
              hoverCursor='pointer'
              hoverBackgroundColor={G.getTheme('colors.light.createPopoverHoverBackground')}
            >
              <NavItem
                has={[PC.BRANCH_WRITE]}
                onClick={onHandleClick(openAddBranchModalRequest)}
                title={G.getWindowLocale('actions:add-customer', 'Add Customer')}
              />
              <NavItem
                has={[PC.CLO_WRITE]}
                title={G.getWindowLocale('actions:new-clo', 'New CLO')}
                onClick={onHandleClick(() => G.goToRoute(getOrderEntryRoute()))}
              />
              <NavItem
                has={[PC.CREATE_CARRIER_EXECUTE]}
                onClick={onHandleClick(handleCreateCarrier)}
                title={G.getWindowLocale('actions:create-carrier', 'Create Carrier')}
              />
            </LinksList>
          </InnerPopover>
        </Popover>
      </OuterClick>
    </div>
  );
};

export default connect(null, {
  openModal,
  openAddBranchModalRequest,
})(enhance(CreateNew));

