import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, lifecycle } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../components/form-footer';
import { closeModal } from '../../../../components/modal/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// forms
import { Fieldset2 } from '../../../../forms';
// feature reference
import { makeSelectAvailableReferenceTypesByScope } from '../../selectors';
import { validationSchema, getFieldsSetting, defaultReferenceFields } from './settings';
import { addNewReferenceRequest, getAllAvailableRefTypesByScopeRequest } from '../../actions';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props }: Object) => {
      props.closeModal();
      props.submitAction(values);
    },
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultReferenceFields,
      initialValues,
    ),
  }),
  lifecycle({
    componentDidMount() {
      this.props.getAllAvailableRefTypesByScopeRequest(this.props.scope);
    },
  }),
  pure,
);

const getAllowedValues = ({values, availableTypes}: Object) => {
  const selectedTypeGuid = R.path([GC.FIELD_REFERENCE_TYPE_GUID], values);
  const selectedType = R.find(R.propEq(selectedTypeGuid, GC.FIELD_GUID), availableTypes);

  return R.pathOr([], ['allowedValues'], selectedType);
};

const getAllowedOptions = (allowedValues: Array) => {
  if (G.isNotNilAndNotEmpty(allowedValues)) {
    return R.map(
      (item: string) => ({ label: item, value: item }),
      allowedValues,
    );
  }

  return [];
};

const ReferenceFormComponent = (props: Object) => {
  const { isEditMode, handleSubmit, availableTypes } = props;

  const allowedValues = getAllowedValues(props);
  const referenceTypes = G.getOptionsFromDataArrayByPath([GC.FIELD_NAME], [GC.FIELD_GUID], availableTypes);

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        referenceTypes={referenceTypes}
        allowedValues={getAllowedOptions(allowedValues)}
        fields={getFieldsSetting(allowedValues, isEditMode)}
      />
      <FormFooter2 />
    </form>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  availableTypes: makeSelectAvailableReferenceTypesByScope(state),
}));

export default connect(mapStateToProps, {
  closeModal,
  addNewReferenceRequest,
  getAllAvailableRefTypesByScopeRequest,
})(enhance(ReferenceFormComponent));
