export const tableSettings = {
  rowHeight: 55,
  cellFontSize: 14,
  titleFontSize: 14,
  titleRowHeight: 55,
  tableRowHeight: 55,
  allowEditBtn: true,
  useMainColors: true,
  allowSelectAll: true,
  moreBtnCellWidth: 50,
  expandableItems: false,
  maxHeight: 'calc(100vh - 130px)',
};
