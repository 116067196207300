import * as R from 'ramda';
import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const store = ({ publicCLO }: Object) => publicCLO;

const makeSelectGroupBy = () => createSelector(
  store,
  ({ groupBy }: Object) => groupBy,
);

const makeSelectReportStatus = () => createSelector(
  store,
  ({ reportPending }: Object) => reportPending,
);

const makeSelectAvailableReports = () => createSelector(
  store,
  ({ availableReports }: Object) => availableReports,
);

const makeSelectUsedReport = () => createSelector(
  store,
  ({ usedReport }: Object) => usedReport,
);

const makeSelectFilterParams = () => createSelector(
  store,
  ({ filterParams }: Object) => filterParams,
);

const makeSelectItemList = () => createSelector(
  store,
  ({ itemList }: Object) => R.sortBy(R.prop('index'), R.values(itemList)),
);


const makeSelectPagination = () => createSelector(
  store,
  ({ pagination }: Object) => pagination,
);

const makeSelectTotalCount = () => createSelector(
  store,
  ({ totalCount }: Object) => totalCount,
);

const makeSelectListLoading = () => createSelector(
  store,
  ({ loading }: Object) => loading,
);

const makeSelectGlobalFilterValue = () => createSelector(
  store,
  ({ globalFilterValue }: Object) => globalFilterValue,
);

const makeSelectFilterProps = () => createSelector(
  store,
  ({ filterProps }: Object) => filterProps,
);

export {
  makeSelectGroupBy,
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectFilterProps,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectAvailableReports,
  makeSelectGlobalFilterValue,
};

