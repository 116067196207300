import * as R from 'ramda';
import { withHandlers } from 'react-recompose';
// components
import { getConfirmModal } from '../../components/confirm';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const withDeleteNotifications = withHandlers({
  handleDeleteAllNotifications: (props: Object) => () => {
    const {
      openModal,
      closeModal,
      deleteAllNotifications } = props;

    const modalContent = getConfirmModal({
      cancelAction: closeModal,
      submitAction: deleteAllNotifications,
      cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
      submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
      text: `${G.getWindowLocale(
        'messages:delete-confirmation-text-double',
        'Are you sure you want to delete',
      )} ${G.getWindowLocale('titles:all-notifications', 'All Notifications')}?`,
    });
    openModal(modalContent);
  },
  handleDeleteSelectedNotifications: (props: Object) => () => {
    const {
      itemList,
      openModal,
      closeModal,
      notificationList,
      deleteSelectedNotifications } = props;
    const listToUse = R.or(itemList, notificationList);
    const selectedList = R.compose(
      R.map(R.prop('mainGuid')),
      R.filter(R.prop('selected')),
    )(listToUse);

    if (R.isEmpty(selectedList)) {
      return G.showToastrMessageSimple(
        'info',
        G.getWindowLocale('messages:items:select-item', 'Please, select an Item'),
      );
    }
    const modalContent = getConfirmModal({
      cancelAction: closeModal,
      cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
      submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
      submitAction: () => deleteSelectedNotifications(selectedList),
      text: `${G.getWindowLocale(
        'messages:delete-confirmation-text-double',
        'Are you sure you want to delete',
      )} ${G.getWindowLocale('titles:selected-notifications', 'Selected Notifications')}?`,
    });
    openModal(modalContent);
  },
});
