import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { OuterClick } from 'react-outer-click';
import { pure, compose, withHandlers} from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncSearchTemplateAutocomplete } from '../../../hocs';
// ui
import { Box } from '../../../ui';
// feature new-do
import FormTab from './form-tab';
import FormHeader from './form-header';
import { Fieldset } from './formik/fieldset';
import PrevNextAction from './validate-action';
import { FORM_BOX_SHADOW } from '../constants';
import { withSearchLocationSetValues } from '../hocs';
import { getBillToHeaderActionOptions } from '../helpers';
import SectionDivider from '../components/section-divider';
import { isValidBillToForm, validationSchemaBillToForm } from '../validation';
import {
  mainBillToFields,
  addressBillToFields,
  defaultBillToFields,
  billToContactFields } from '../settings/fields-settings';
//////////////////////////////////////////////////

const BillToForm = (props: Object) => (
  <OuterClick onOuterClick={props.handleOuterClick}>
    <form>
      <FormTab {...props} />
      <FormHeader
        values={props.values}
        setValues={props.setValues}
        openModal={props.openModal}
        closeModal={props.closeModal}
        setFieldValue={props.setFieldValue}
        options={getBillToHeaderActionOptions(props)}
        currentBranch={{ [GC.FIELD_GUID]: props.branchGuid }}
      >
        {G.concatLocationFields(props.values)}
      </FormHeader>
      <Box boxShadow={FORM_BOX_SHADOW}>
        <Box pt={20}>
          <Fieldset
            {...props}
            fields={mainBillToFields}
            getLoadOptions={props.handleGetLoadOptions} />
          <SectionDivider
            text={G.getWindowLocale('titles:address', 'Address', { caseAction: 'upperCase' })} />
          <Fieldset
            {...props}
            fields={addressBillToFields} />
          <SectionDivider
            text={G.getWindowLocale('titles:contact-info', 'Contact Info', { caseAction: 'upperCase' })} />
          <Fieldset
            {...props}
            fields={billToContactFields} />
          <PrevNextAction
            mt={20}
            showNext={true}
            submitForm={props.submitForm} />
        </Box>
      </Box>
    </form>
  </OuterClick>
);

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaBillToForm,
    mapPropsToValues: ({ initialValues }: Object) => initialValues,
    handleSubmit: (values: Object, { props }: Object) => {
      const { setActiveLeftTab, setFormDataToStore } = props;
      setFormDataToStore({ dataName: 'billTo', formData: R.assoc('isValid', true, values) });
      setActiveLeftTab(1);
    },
    displayName: 'BillToForm',
  }),
  withAsyncSearchTemplateAutocomplete({
    useCurrentBranch: true,
    shouldGetLocationTemplateSearchRequestProps: true,
  }),
  withSearchLocationSetValues,
  withHandlers({
    handleCleanForm: ({ setValues }: Object) => () => setValues(defaultBillToFields),
    onClickTabCallback: (props: Object) => (activeName: Object) => {
      const { values, setActiveLeftTab, setFormDataToStore } = props;
      const isValid = isValidBillToForm(values);
      setFormDataToStore({ dataName: 'billTo', formData: R.assoc('isValid', isValid, values) });
      setActiveLeftTab(activeName);
    },
    handleOuterClick: (props: Object) => () => {
      const { values, setFormDataToStore } = props;
      const isValid = isValidBillToForm(values);
      setFormDataToStore({ dataName: 'billTo', formData: R.assoc('isValid', isValid, values) });
    },
  }),
  pure,
);

export default enhance(BillToForm);
