import * as R from 'ramda';
import React, { Fragment } from 'react';
import { pure, compose, withState, lifecycle, withHandlers } from 'react-recompose';
// components
import { Table } from '../../../components/table';
import { TextBtn1 } from '../../../components/text-btns';
import { FormGroupTitleMultiple } from '../../../components/form-group-title-multiple';
// features
import TotalCard from '../../dashboards/components/total-card';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncConfigs } from '../../../hocs';
// ui
import { Box, Flex } from '../../../ui';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature rate
import { setDefaultCarrierRateValues } from '../helpers';
import { availableRatesAndCarriersTableSettings } from '../settings';
//////////////////////////////////////////////////

const rpmText = () =>
  G.ifElse(R.equals(G.getConfigGeneralUomCalcDefaultUomSystemFromWindow(), GC.METRIC_SYSTEM), 'RPKM', 'RPM');

const settings = {
  lastRateTotal: {
    width: 150,
    name: 'titles:last-rate',
    customComponent: ({ data, callbackData }: Object) => {
      const lastRateTotal = R.prop('lastRateTotal', data);
      const currency = R.prop(GC.FIELD_CURRENCY, callbackData);

      return (
        <Box
          p='4px 5px'
          color='white'
          bg='light.blue'
          fontWeight='bold'
          borderRadius='3px'
          width='max-content'
        >
          {G.getCurrencySymbol(currency)}{G.toFixed(lastRateTotal, 2)} {currency}
        </Box>
      );
    },
  },
  [GC.FIELD_TEL_COUNT]: {
    width: 100,
    name: 'titles:tel-count',
  },
  [GC.FIELD_NAME]: {
    width: 200,
    name: 'titles:carrier-name',
  },
  [GC.FIELD_CARRIER_MC_NUMBER]: {
    width: 150,
    name: 'titles:mc-number',
  },
  [GC.FIELD_US_DOT_NUMBER]: {
    width: 150,
    name: 'titles:usdot-number',
  },
  [GC.FIELD_CARRIER_SCAC]: {
    width: 150,
    name: 'titles:scac',
  },
  [GC.FIELD_MODE]: {
    width: 150,
    name: 'titles:mode',
  },
  [GC.FIELD_SERVICE_TYPE]: {
    width: 150,
    name: 'titles:service-type',
  },
  [GC.FIELD_LOAD_EQUIPMENT]: {
    width: 150,
    name: 'titles:equipment',
  },
  minRateTotal: {
    width: 150,
    name: 'titles:min-rate',
    customComponent: ({ data, callbackData }: Object) => {
      const minRateTotal = R.prop('minRateTotal', data);
      const currency = R.prop(GC.FIELD_CURRENCY, callbackData);

      return `${G.getCurrencySymbol(currency)} ${G.toFixed(minRateTotal, 2)}`;
    },
  },
  averageRateTotal: {
    width: 150,
    name: 'titles:average-rate',
    customComponent: ({ data, callbackData }: Object) => {
      const currency = R.prop(GC.FIELD_CURRENCY, callbackData);
      const averageRateTotal = R.prop('averageRateTotal', data);

      return `${G.getCurrencySymbol(currency)} ${G.toFixed(averageRateTotal, 2)}`;
    },
  },
  maxRateTotal: {
    width: 150,
    name: 'titles:max-rate',
    customComponent: ({ data, callbackData }: Object) => {
      const maxRateTotal = R.prop('maxRateTotal', data);
      const currency = R.prop(GC.FIELD_CURRENCY, callbackData);

      return `${G.getCurrencySymbol(currency)} ${G.toFixed(maxRateTotal, 2)}`;
    },
  },
  minRatePerDistanceUnit: {
    width: 150,
    name: 'titles:min',
    customTitleComponent: () => `${G.getWindowLocale('titles:minimum', 'Minimum')} ${rpmText()}`,
    customComponent: ({ data, callbackData }: Object) => {
      const currency = R.prop(GC.FIELD_CURRENCY, callbackData);
      const minRatePerDistanceUnit = R.prop('minRatePerDistanceUnit', data);

      return `${G.getCurrencySymbol(currency)} ${G.toFixed(minRatePerDistanceUnit, 2)}`;
    },
  },
  averageRatePerDistanceUnit: {
    width: 150,
    customTitleComponent: () => `${G.getWindowLocale('titles:average', 'Average')} ${rpmText()}`,
    customComponent: ({ data, callbackData }: Object) => {
      const currency = R.prop(GC.FIELD_CURRENCY, callbackData);
      const averageRatePerDistanceUnit = R.prop('averageRatePerDistanceUnit', data);

      return `${G.getCurrencySymbol(currency)} ${G.toFixed(averageRatePerDistanceUnit, 2)}`;
    },
  },
  maxRatePerDistanceUnit: {
    width: 150,
    customTitleComponent: () => `${G.getWindowLocale('titles:maximum', 'Maximum')} ${rpmText()}`,
    customComponent: ({ data, callbackData }: Object) => {
      const currency = R.prop(GC.FIELD_CURRENCY, callbackData);
      const maxRatePerDistanceUnit = R.prop('maxRatePerDistanceUnit', data);

      return `${G.getCurrencySymbol(currency)} ${G.toFixed(maxRatePerDistanceUnit, 2)}`;
    },
  },
};

const report = {
  fields: G.mapIndexed((name: string, sequence: number) => ({
    name,
    sequence,
    customTitleComponent: R.path([name, 'customTitleComponent'], settings),
  }), R.keys(settings)),
};

const getItemList = (props: Object) => {
  const {
    serviceType,
    laneHistory,
    asyncConfigs,
    transportationMode,
  } = props;

  const indexedServiceTypes = R.indexBy(R.prop(GC.FIELD_VALUE), R.or(serviceType, []));
  const indexedTransportationModes = R.indexBy(R.prop(GC.FIELD_VALUE), R.or(transportationMode, []));

  const indexedEquipments = R.indexBy(
    G.getParentGuidOrGuidFromObject,
    R.pathOr([], ['dropdowns', GC.GENERAL_EQUIPMENTS, 'options'], asyncConfigs),
  );

  const itemList = R.map((item: Object) => {
    const { carrier, equipmentGuid, serviceTypeGuid, transportationModeGuid } = item;

    return {
      ...item,
      ...carrier,
      selected: false,
      [GC.FIELD_SERVICE_TYPE]: R.path([serviceTypeGuid, GC.FIELD_LABEL], indexedServiceTypes),
      [GC.FIELD_MODE]: R.path([transportationModeGuid, GC.FIELD_LABEL], indexedTransportationModes),
      [GC.FIELD_LOAD_EQUIPMENT]: R.path([equipmentGuid, GC.FIELD_DISPLAYED_VALUE], indexedEquipments),
    };
  }, R.propOr([], 'lanes', laneHistory));

  return itemList;
};

export const renderTable = (props: Object) => {
  const { handleSelectItem } = props;

  const data = {
    report,
    allChecked: false,
    hasSelectable: true,
    columnSettings: settings,
    itemList: getItemList(props),
    tableSettings: availableRatesAndCarriersTableSettings,
    onOptionClick: (guid: string, rate: Object) => handleSelectItem(rate),
    callbackData: { [GC.FIELD_CURRENCY]: R.path(['laneHistory', GC.FIELD_CURRENCY], props) },
  };

  return <Table {...data} />;
};

const laneHistoryEnhance = compose(
  withAsyncConfigs,
  withState('laneHistory', 'setLaneHistory', []),
  withHandlers({
    handleGetLaneHistory: (props: Object) => async () => {
      const {
        stops,
        branchGuid,
        equipmentGuid,
        setLaneHistory,
        serviceTypeGuid,
        transportationModeGuid,
      } = props;

      const { city: originCity, state: originState } = R.pathOr({}, [0, GC.FIELD_LOCATION], stops);

      const { city: destinationCity, state: destinationState } = R.propOr({}, GC.FIELD_LOCATION, R.last(stops));

      const options = {
        data: {
          originCity,
          originState,
          equipmentGuid,
          destinationCity,
          serviceTypeGuid,
          destinationState,
          transportationModeGuid,
          [GC.BRANCH_GUID]: R.or(branchGuid, G.getAmousCurrentBranchGuidFromWindow()),
        },
      };

      const res = await sendRequest('post', endpointsMap.telCarrierRateLaneHistory, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setLaneHistory(data);
      } else {
        G.handleFailResponseSimple(res, true, 'handleGetLaneHistory');
      }
    },
    handleSelectItem: (props: Object) => async ({ lastRateGuid }: Object) => {
      const { setValues, closeModal, openLoader, closeLoader, setSelectedCarrier } = props;

      G.callFunction(openLoader);

      const res = await sendRequest('get', endpointsMap.getTelRateItemEndpoint(lastRateGuid));

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const {
          items,
          cloRateAdjustments,
          carrierAssignment: { carrierSnapshot, ...assignment },
        } = data;

        const carrierRateCharges = R.map((item: Object) => R.assoc(
          GC.FIELD_ID,
          G.getGuidFromObject(item),
          G.omitObjectSystemFields(item),
        ), R.propOr([], GC.FIELD_CARRIER_RATE_CHARGES, data));

        const carrierAssignmentForCreateRate = R.omit(
          R.concat(['terminalSnapshot', GC.FIELD_TRACKING_NUMBER], GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR),
          R.mergeRight(carrierSnapshot, assignment),
        );

        const newValues = {
          ...setDefaultCarrierRateValues({
            initialValues: G.omitObjectSystemFields(data),
          }),
          carrierRateCharges,
          [GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT]: carrierAssignmentForCreateRate,
          [GC.FIELD_LOAD_ITEMS]: R.map(G.omitObjectSystemFields, R.or(items, [])),
          [GC.FIELD_CLO_RATE_ADJUSTMENTS]: R.map(G.omitObjectSystemFields, R.or(cloRateAdjustments, [])),
        };

        closeModal();
        setValues(newValues);
        setSelectedCarrier(R.assoc(
          GC.FIELD_GUID,
          R.prop(GC.FIELD_CARRIER_GUID, assignment),
          carrierAssignmentForCreateRate,
        ));
      } else {
        G.handleFailResponseSimple(res, true, 'handleSelectItem');
      }

      G.callFunction(closeLoader);
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetLaneHistory();
    },
  }),
  pure,
);

const getTotals = ({
  currency,
  minRateTotal,
  maxRateTotal,
  averageRateTotal,
  minRatePerDistanceUnit,
  maxRatePerDistanceUnit,
  averageRatePerDistanceUnit,
}: Object) => {
  const totalPrefix = G.getCurrencySymbol(currency);

  return [
    {
      totalPrefix,
      total: minRateTotal,
      title: G.getWindowLocale('titles:min-rate', 'Min Rate'),
    },
    {
      totalPrefix,
      total: averageRateTotal,
      title: G.getWindowLocale('titles:average-rate', 'Average Total'),
    },
    {
      totalPrefix,
      total: maxRateTotal,
      title: G.getWindowLocale('titles:max-rate', 'Max Rate'),
    },
    {
      totalPrefix,
      total: minRatePerDistanceUnit,
      title: `${G.getWindowLocale('titles:minimum', 'Minimum')} ${rpmText()} `,
    },
    {
      totalPrefix,
      total: averageRatePerDistanceUnit,
      title: `${G.getWindowLocale('titles:average', 'Average')} ${rpmText()} `,
    },
    {
      totalPrefix,
      total: maxRatePerDistanceUnit,
      title: `${G.getWindowLocale('titles:maximum', 'Maximum')} ${rpmText()} `,
    },
  ];
};

const Totals = ({ laneHistory }: Object) => (
  <Box width='100%' overflowX='auto'>
    <Flex width='max-content' p='20px 0px 20px 20px'>
      {
        getTotals(laneHistory).map((item: Object, index: number) => (
          <TotalCard
            {...item}
            key={index}
            width={220}
            boxShadow='cardBoxShadow'
          />
        ))
      }
    </Flex>
  </Box>
);

const LaneHistory = laneHistoryEnhance((props: Object) => (
  <Fragment>
    <FormGroupTitleMultiple
      mb='0'
      jc='space-between'
      showArrowToggle={false}
      title={G.getWindowLocale('titles:lane-history', 'Lane History')}
    >
      <Box
        mx='10px'
        p='3px 10px'
        fontSize='11px'
        cursor='pointer'
        fontWeight='bold'
        borderRadius='2px'
        onClick={props.closeModal}
        bg={G.getTheme('colors.white')}
        color={G.getTheme('colors.light.darkRed')}
      >
        {G.getWindowLocale('actions:close', 'Close')}
      </Box>
    </FormGroupTitleMultiple>
    {G.isNotNilAndNotEmpty(R.path(['laneHistory', 'lanes'], props)) && <Totals laneHistory={props.laneHistory} />}
    {renderTable(props)}
  </Fragment>
));

const enhance = compose(
  withHandlers({
    handleOpenLaneHistory: (props: Object) => () => {
      const { openModal } = props;

      const configsNamesArray = [GC.GENERAL_EQUIPMENTS];

      const component = <LaneHistory {...props} configsNamesArray={configsNamesArray} />;

      const modal = {
        p: '0',
        component,
        options: {
          width: 'auto',
          maxWidth: 1460,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '80vh',
          minHeight: '50vh',
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const ShowLaneHistory = ({ handleOpenLaneHistory }: Object) => (
  <TextBtn1
    onClickHandler={handleOpenLaneHistory}
    text={G.getWindowLocale('titles:lane-history', 'Lane History')}
  />
);

export default enhance(ShowLaneHistory);
