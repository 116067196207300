import React from 'react';
import * as R from 'ramda';
// features
import PC from '../../permission/role-permission';
import TripPricing from '../../new-do/forms/trip-pricing';
import ReferenceForm from '../../new-do/forms/reference-form';
import QuoteDropForm from '../../new-do/outside-use/quote-drop-form';
import QuotePickupForm from '../../new-do/outside-use/quote-pickup-form';
import { LeftSectionEmptyInfo } from '../../new-do/components/left-section';
import CustomerRateForm, { LockedCustomerRateForm } from '../../new-do/forms/customer-rate-form';
import {
  TAB_NAME_PRICING,
  TAB_NAME_REFERENCE,
  TAB_NAME_TRIP_PRICING,
} from '../../new-do/constants';
import {
  makeReqStops,
  getDataFromStops,
  getLeftSectionWidth,
  getItemsForDropOptions,
  getContainersForDropOptions,
  getContainersForPickupItemOptions,
} from '../../new-do/helpers';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
// feature new-do-quote
import { renderLeftRightContent, renderLeftRightEmptyInfo } from '../helpers';
//////////////////////////////////////////////////

const renderForm = (props: Object) => {
  const {
    rate,
    stops,
    loadType,
    branchGuid,
    loadTotals,
    branchConfigs,
    leftActiveTad,
    branchRefTypes,
    referenceFormData,
    recalculateLoadDistancesSuccess,
  } = props;

  const stop = stops[leftActiveTad];
  const referenceTypes = G.mapNameGuidToLabelValue(R.pathOr([], [GC.REF_SCOPE_NAME_CLO], branchRefTypes));
  const services = R.prop(GC.FIELD_LOAD_SERVICES, referenceFormData);

  if (G.isNotNilAndNotEmpty(stop)) {
    const { id, order, formData, eventType } = stop;

    if (R.equals(eventType, GC.EVENT_TYPE_PICKUP)) {
      const dropContainerOptions = getContainersForDropOptions(stops, stop);
      const itemContainerOptions = G.addEmptyOptionToDropDown(getContainersForPickupItemOptions(stops, stop));

      return (
        <QuotePickupForm
          {...props}
          stop={stop}
          stopId={id}
          stopOrder={order}
          eventType={eventType}
          initialValues={formData}
          referenceTypes={referenceTypes}
          dropContainerOptions={dropContainerOptions}
          itemContainerOptions={itemContainerOptions}
        />
      );
    }

    const items = getItemsForDropOptions(stops, formData.items);
    const dropContainerOptions = getContainersForDropOptions(stops, stop);

    return (
      <QuoteDropForm
        {...props}
        stop={stop}
        stopId={id}
        items={items}
        stopOrder={order}
        eventType={eventType}
        initialValues={formData}
        referenceTypes={referenceTypes}
        dropContainerOptions={dropContainerOptions}
      />
    );
  }

  if (R.equals(leftActiveTad, TAB_NAME_REFERENCE)) {
    return (
      <ReferenceForm
        {...props}
        referenceTypes={referenceTypes}
        initialValues={referenceFormData}
      />
    );
  }

  if (R.equals(leftActiveTad, TAB_NAME_PRICING)) {
    const { totalWeight, totalVolume, totalDistance } = loadTotals;

    const { pickedItems, pickedUpContainers } = getDataFromStops(stops);

    const stopsQuantity = R.length(R.values(stops));

    const charges = G.getPropFromObject(GC.FIELD_CHARGES, rate);
    const equipment = R.prop(GC.FIELD_LOAD_EQUIPMENT, referenceFormData);
    const equipments = G.ifElse(G.isNilOrEmpty(equipment), [], R.of(Array, equipment));

    const getAsyncLoadDistancesData = {
      stops, loadType, branchGuid, branchConfigs, asyncLoadDistancesCallback: recalculateLoadDistancesSuccess,
    };

    const totalTripDistance = R.path([GC.FIELD_TOTAL_TRIP_DISTANCE], totalDistance);
    const totalTripDistanceUom = R.path([GC.FIELD_TOTAL_TRIP_DISTANCE_UOM], totalDistance);

    const totalPickupQuantity = G.makeTotalPickupQuantityObjectFromItems(pickedItems);

    const customerRateDefaultProps = {
      ...props,
      equipments,
      totalWeight,
      totalDistance,
      stopsQuantity,
      totalTripDistance,
      totalPickupQuantity,
      totalTripDistanceUom,
      stopCount: stopsQuantity,
      itemsVolume: totalVolume,
      getAsyncLoadDistancesData,
      events: makeReqStops(stops, branchRefTypes, pickedItems, pickedUpContainers, true),
    };

    const customerRateProps = G.ifElse(
      R.or(G.isNilOrEmpty(rate), G.isNilOrEmpty(charges)),
      R.assoc('isCreateMode', true, customerRateDefaultProps),
      R.mergeRight({ isEditMode: true, initialValues: rate }, customerRateDefaultProps),
    );

    if (G.hasNotAmousCurrentUserPermissions(PC.CLO_RATE_WRITE)) {
      return <LockedCustomerRateForm {...customerRateProps} />;
    }

    return <CustomerRateForm {...customerRateProps} />;
  }

  if (R.equals(leftActiveTad, TAB_NAME_TRIP_PRICING)) {
    const { pickedItems, pickedUpContainers } = getDataFromStops(stops);

    const loadData = {
      services,
      cloRate: rate,
      events: makeReqStops(stops, branchRefTypes, pickedItems, pickedUpContainers, true),
    };

    return (
      <TripPricing {...props} loadData={loadData} />
    );
  }

  return null;
};

const LeftSection = (props: Object) => {
  const { leftActiveTad } = props;

  const width = getLeftSectionWidth(leftActiveTad);

  return (
    <Box
      mb={20}
      mr={25}
      width={width}
      flexShrink={0}
      borderRadius='4px'
      bg={G.getTheme('colors.white')}
    >
      {renderLeftRightEmptyInfo(props) && <LeftSectionEmptyInfo />}
      {renderLeftRightContent(props) && renderForm(props)}
    </Box>
  );
};

export default LeftSection;
