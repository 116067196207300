import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers } from 'react-recompose';
// features
import { makeSelectCurrentUserGuid } from '../auth/selectors';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as GC from '../../constants';
// hocs
import { withComponentDidMountCallback, withConnectModalAndLoaderActions } from '../../hocs';
// feature profile
import TabsGroup from './components/tabs-group';
import GeneralDetails from './forms/general-details';
import ProfileWrapper from './components/profile-wrapper';
import { withProfileWrapper } from './hocs/with-profile-wrapper';
import { withGeneralSettings } from './hocs/with-general-details';
import {
  makeSelectUserRoles,
  makeSelectUserSettings,
  makeSelectUserReferences,
  makeSelectUserSettingsFields,
  makeSelectAvailableByUserTypeRoleList,
} from './selectors';
import {
  updateUserSettingsRequest,
  updateUserReferenceRequest,
  deleteUserReferenceRequest,
  createUserReferenceRequest,
  assignUserProfileRolesRequest,
  revokeUserProfileRolesRequest,
  changeUserSuspendedStatusRequest,
} from './actions';
//////////////////////////////////////////////////

const ProfileGeneralDetail = withGeneralSettings(GeneralDetails);

const UserSettingComponent = (props: Object) => {
  const {
    roles,
    update,
    openModal,
    setUpdate,
    closeModal,
    openLoader,
    references,
    closeLoader,
    handleClose,
    saveAndClose,
    initialValues,
    setSaveAndClose,
    handleRemoveReference,
    handleCreateReference,
    handleUpdateReference,
    dispatchingGroupOptions,
    handleUpdateGeneralDetails,
    availableByUserTypeRoleList,
    handleAssignUserProfileRoles,
    handleRevokeUserProfileRoles,
    handleChangeUserSuspendedStatus,
  } = props;

  const defaultProps = {
    openModal,
    closeModal,
    openLoader,
    closeLoader,
  };

  return (
    <ProfileWrapper
      setUpdate={setUpdate}
      handleClose={handleClose}
      setSaveAndClose={setSaveAndClose}
    >
      <ProfileGeneralDetail
        {...defaultProps}
        update={update}
        setUpdate={setUpdate}
        saveAndClose={saveAndClose}
        initialValues={initialValues}
        setSaveAndClose={setSaveAndClose}
        submitAction={handleUpdateGeneralDetails}
        dispatchingGroupOptions={dispatchingGroupOptions}
        handleChangeUserSuspendedStatus={handleChangeUserSuspendedStatus}
      />
      <TabsGroup
        {...defaultProps}
        roles={roles}
        references={references}
        initialValues={initialValues}
        handleRemoveReference={handleRemoveReference}
        handleCreateReference={handleCreateReference}
        handleUpdateReference={handleUpdateReference}
        availableByUserTypeRoleList={availableByUserTypeRoleList}
        handleAssignUserProfileRoles={handleAssignUserProfileRoles}
        handleRevokeUserProfileRoles={handleRevokeUserProfileRoles}
      />
    </ProfileWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  roles: makeSelectUserRoles(state),
  fields: makeSelectUserSettingsFields(state),
  initialValues: makeSelectUserSettings(state),
  currentUserGuid: makeSelectCurrentUserGuid(state),
  [GC.UI_FIELD_REFERENCES]: makeSelectUserReferences(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  availableByUserTypeRoleList: makeSelectAvailableByUserTypeRoleList(state),
});

const enhance = compose(
  connect(mapStateToProps, {
    updateUserSettingsRequest,
    deleteUserReferenceRequest,
    createUserReferenceRequest,
    updateUserReferenceRequest,
    assignUserProfileRolesRequest,
    revokeUserProfileRolesRequest,
    changeUserSuspendedStatusRequest,
  }),
  withConnectModalAndLoaderActions,
  withHandlers({
    handleClose: ({ history }: Object) => () => history.goBack(),
    updateGeneralDetailsRequest: (props: Object) => ({ values, options, handleClose, saveAndClose }: Object) => {
      const { updateUserSettingsRequest } = props;

      const data = {
        values,
        options,
        handleClose,
        saveAndClose,
        [GC.FIELD_GUID]: R.path(['match', 'params', GC.FIELD_GUID], props),
      };

      updateUserSettingsRequest(data);
    },
  }),
  withProfileWrapper,
  withComponentDidMountCallback({ callbackName: 'getAsyncGetDispatchingGroupList' }),
);

export default (enhance(UserSettingComponent));
