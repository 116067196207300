import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const toggleSubmenus = createAction('toggleSubmenus');
export const toggleSidebar = createAction('toggleSidebarRequest');
export const clickOnItemAction = createAction('clickOnItemAction');
export const reorderPinnedItems = createAction('reorderPinnedItems');
export const addPinnedItemToList = createAction('addPinnedItemToList');
export const filteredMenusSuccess = createAction('filteredMenusSuccess');
export const removePinnedItemFromList = createAction('removePinnedItemFromList');
