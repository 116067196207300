import * as R from 'ramda';
import * as P from 'plow-js';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import { getOrderRateInfo } from '../../new-do/hocs/with-trip-rates';
import { getLoadDetailsRequest } from '../../dispatch-board-new/load/actions';
import { getLoadCustomerRateListRequest } from '../../dispatch-details-new/load/actions';
import {
  getOrderCostAllocationsRequest,
  getOrderCustomerRateListRequest,
} from '../../dispatch-details-new/order/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature rate
import AvailableRates from './available-rates';
import { mapCarrierRatesWithProperQuantity } from '../helpers';
import { withShowIntegrationErrorsOnAvailableRates } from '../hocs';
import { getCarrierRateFields, SelectRateFromIntegration } from '../carrier';
//////////////////////////////////////////////////

const getRates = R.map((data: Object) => {
  const carrierSnapshot = R.prop(GC.SYSTEM_OBJECT_CARRIER_SNAPSHOT, data);
  const equipments = R.pathOr([], ['price', GC.FIELD_CARRIER_EQUIPMENTS], data);
  const serviceTypes = R.pathOr([], ['price', GC.FIELD_CARRIER_SERVICE_TYPES], data);
  const mode = R.pathOr('', ['price', GC.FIELD_MODE, GC.FIELD_DISPLAYED_VALUE], data);

  return {
    ...data,
    ...carrierSnapshot,
    mode,
    equipments,
    serviceTypes,
  };
});

class AvailableCarriersHoc extends Component {
  constructor(props: Object) {
    super(props);
    this.state = {
      loading: true,
      rateInfo: null,
      availableRates: [],
    };
    this.getAvailableRatesRequest = this.getAvailableRatesRequest.bind(this);
  }

  componentDidMount() {
    this.getAvailableRatesRequest(this.props);
  }

  async getAvailableRatesRequest(props: Object) {
    const {
      cloGuid,
      loadData,
      equipments,
      branchGuid,
      serviceType,
      transportationMode,
      customerEnterpriseGuid } = props;

    const currentUserBranchGuid = G.getAmousCurrentUserBranchGuidFromWindow();
    const options = {
      data: {
        loadData,
        equipments,
        serviceType,
        loadGuid: cloGuid,
        transportationMode,
        customerEnterpriseGuid,
        [GC.BRANCH_GUID]: R.or(branchGuid, currentUserBranchGuid),
      },
    };

    const res = await sendRequest('post', endpointsMap.cloRateAvailableCarriers, options);

    const { data, status } = res;

    if (R.and(G.isResponseSuccess(status), G.isNotEmpty(data))) {
      const rates = getRates(data.customerRateData);
      const mapped = mapCarrierRatesWithProperQuantity(rates);
      const newState = P.$all(
        P.$set('loading', false),
        P.$set('rateInfo', data),
        P.$set('availableRates', mapped),
        this.state,
      );
      this.setState(newState);
    } else {
      this.setState(P.$set('loading', false, this.state));
      G.handleFailResponseSimple(
        res,
        true,
        'withAsyncCloRateAvailableRates -> getAvailableRatesRequest',
      );
    }
  }

  render() {
    return (
      <div>
        {
          this.props.render(
            this.state,
            this.getAvailableRatesRequest,
          )
        }
      </div>
    );
  }
}

const withAsyncCloRateAvailableRates = (Component: any) => class extends React.Component {
  render() {
    const { cloGuid, loadData, branchGuid, equipments, serviceType, transportationMode, customerEnterpriseGuid } = this.props;

    return (
      <AvailableCarriersHoc
        cloGuid={cloGuid}
        loadData={loadData}
        branchGuid={branchGuid}
        equipments={equipments}
        serviceType={serviceType}
        transportationMode={transportationMode}
        customerEnterpriseGuid={customerEnterpriseGuid}
        render={(
            parentState: Object,
            getAvailableRatesRequest: Function,
          ) => (
            <Component
              {...this.props}
              loading={parentState.loading}
              rateInfo={parentState.rateInfo}
              availableRates={parentState.availableRates}
              getAvailableCarriersRequest={getAvailableRatesRequest}
            />
          )}
      />
    );
  }
  };

const availableRatesEnhance = compose(
  withAsyncCloRateAvailableRates,
  withShowIntegrationErrorsOnAvailableRates,
);

const EnhancedAvailableRates = availableRatesEnhance(AvailableRates);

const mapCharges = (charges: Array) => R.map(
  (item: Object) => R.assoc(GC.FIELD_ID, R.or(G.getGuidFromObject(item), G.genShortId()), item),
  charges,
);

const mapCharges2 = (charges: Array) => R.map(
  (item: Object) => R.assoc(GC.FIELD_ID, R.or(G.getGuidFromObject(item), G.genShortId()), item),
  G.addOneFuelChargeToCharges(charges),
);

const withHandleCloRateAvailableRates = compose(
  connect(
    null,
    {
      openModal,
      closeModal,
      openLoader,
      closeLoader,
    },
  ),
  withHandlers({
    handleSelectRate: (props: Object) => (data: Object, rateInfo: Object) => {
      const {
        values,
        pageType,
        setValues,
        openModal,
        closeModal,
        setCarrierRateToStore,
        handleSetRecalculateAllChargesId,
        handleSetCustomerRateFromAvailableRates,
      } = props;

      const totalTripDistance = R.or(
        R.path([GC.FIELD_TOTAL_TRIP_DISTANCE], rateInfo),
        R.path([GC.FIELD_TOTAL_TRIP_DISTANCE], values),
      );

      const totalTripDistanceUom = R.or(
        R.path([GC.FIELD_TOTAL_TRIP_DISTANCE_UOM], rateInfo),
        R.path([GC.FIELD_TOTAL_TRIP_DISTANCE_UOM], values),
      );

      const totalTripWeight = R.or(
        R.path([GC.FIELD_TOTAL_TRIP_WEIGHT], rateInfo),
        R.path([GC.FIELD_TOTAL_TRIP_WEIGHT], values),
      );

      const totalTripWeightUom = R.or(
        R.path([GC.FIELD_TOTAL_TRIP_WEIGHT_UOM], rateInfo),
        R.path([GC.FIELD_TOTAL_TRIP_WEIGHT_UOM], values),
      );

      const mode = R.or(
        R.path(['price', GC.FIELD_MODE, GC.FIELD_DROPDOWN_OPTION_GUID], data),
        R.path([GC.FIELD_MODE], values),
      );

      const serviceType = R.pathOr(
        '',
        [GC.FIELD_DROPDOWN_OPTION_GUID],
        R.head(R.pathOr([], [GC.FIELD_PRICE, GC.FIELD_CARRIER_SERVICE_TYPES], data)),
      );

      const priceGuid = R.path(['price', 'contractGuid'], data);
      const currency = R.prop(GC.FIELD_CURRENCY, data);
      const serviceDays = R.prop(GC.FIELD_SERVICE_DAYS, data);
      const carrierContractGuid = G.getPropFromObject(GC.FIELD_CONTRACT_GUID, data);
      const originCarrierName = G.getPropFromObject(GC.FIELD_ORIGIN_CARRIER_NAME, data);
      const customerContractGuid = G.getPropFromObject(GC.FIELD_CUSTOMER_CONTRACT_GUID, data);
      const destinationCarrierName = G.getPropFromObject(GC.FIELD_DESTINATION_CARRIER_NAME, data);
      const transportingCarrierName = R.path([GC.FIELD_TRANSPORTING_CARRIER, GC.FIELD_NAME], data);

      let newValues = {
        ...values,
        originCarrierName,
        carrierContractGuid,
        customerContractGuid,
        [GC.FIELD_MODE]: mode,
        destinationCarrierName,
        transportingCarrierName,
        [GC.FIELD_CURRENCY]: currency,
        [GC.FIELD_SERVICE_TYPE]: serviceType,
        [GC.FIELD_SERVICE_DAYS]: serviceDays,
        [GC.FIELD_TOTAL_TRIP_WEIGHT]: totalTripWeight,
        [GC.FIELD_SELECTED_RATE_PRICE_GUID]: priceGuid,
        [GC.FIELD_TOTAL_TRIP_DISTANCE]: totalTripDistance,
        [GC.FIELD_TOTAL_TRIP_WEIGHT_UOM]: totalTripWeightUom,
        [GC.FIELD_TOTAL_TRIP_DISTANCE_UOM]: totalTripDistanceUom,
        [GC.FIELD_CHARGES]: mapCharges2(G.getChargesFromObject(data)),
      };

      // TODO: remove after customer testing
      // if (R.includes(pageType, ['CREATE_DO', 'CREATE_FROM_PUBLIC_CLO']))
      const { id } = data;

      const carrierContractData = R.find(R.propEq(id, 'id'), R.pathOr([], ['rateData'], rateInfo));

      if (G.isNotNilAndNotEmpty(carrierContractData)) {
        // NOTE: remember common logic with CarrierRateForm -> handleSelectAutoRate
        const { charges } = carrierContractData;

        const carrierAssignment = R.omit([GC.FIELD_GUID], getCarrierRateFields(carrierContractData));

        const mode = R.or(
          R.path(['price', GC.FIELD_MODE, GC.FIELD_DROPDOWN_OPTION_GUID], carrierContractData),
          R.path([GC.FIELD_MODE], values),
        );

        const serviceType = R.or(
          R.path(
            [GC.FIELD_DROPDOWN_OPTION_GUID],
            R.head(R.pathOr([], ['price', 'serviceTypes'], carrierContractData)),
          ),
          R.path([GC.FIELD_SERVICE_TYPE], values),
        );

        const carrierContractGuid = R.path(['price', 'contractGuid'], carrierContractData);

        const customerTotal = G.getPropFromObject2('totalCustomerRate', getOrderRateInfo(newValues), null);

        const staticFields = R.pick(
          [
            GC.FIELD_CURRENCY,
            GC.FIELD_SERVICE_DAYS,
            GC.FIELD_REQUESTED_PICKUP_EARLY_DATE,
            GC.FIELD_ESTIMATED_DELIVERY_LATE_DATE,
            GC.FIELD_ESTIMATED_DELIVERY_EARLY_DATE,
          ],
          carrierContractData,
        );

        const telCarrierRate = {
          ...staticFields,
          carrierAssignment,
          carrierContractGuid,
          [GC.FIELD_MODE]: mode,
          [GC.FIELD_SERVICE_TYPE]: serviceType,
          carrierRateCharges: mapCharges(charges),
          [GC.FIELD_CUSTOMER_TOTAL]: customerTotal,
          [GC.FIELD_TOTAL_TRIP_WEIGHT]: totalTripWeight,
          [GC.FIELD_TOTAL_TRIP_DISTANCE]: totalTripDistance,
          [GC.FIELD_TOTAL_TRIP_WEIGHT_UOM]: totalTripWeightUom,
          [GC.FIELD_TOTAL_TRIP_DISTANCE_UOM]: totalTripDistanceUom,
        };

        newValues = R.assoc('telCarrierRate', telCarrierRate, newValues);

        G.callFunctionWithArgs(setCarrierRateToStore, telCarrierRate);
      }

      if (G.isOneNotNilOrNotEmpty([originCarrierName, transportingCarrierName, destinationCarrierName])) {
        const handleSelect = () => {
          setValues(newValues);

          G.callFunction(handleSetRecalculateAllChargesId);
          G.callFunctionWithArgs(handleSetCustomerRateFromAvailableRates, newValues);
          G.callFunctionTwice(closeModal);
        };

        const component = (
          <SelectRateFromIntegration
            closeModal={closeModal}
            handleSelect={handleSelect}
            originCarrierName={originCarrierName}
            destinationCarrierName={destinationCarrierName}
            transportingCarrierName={transportingCarrierName}
          />
        );

        const modal = {
          p: 15,
          component,
          options: {},
        };

        return openModal(modal);
      }

      setValues(newValues);

      G.callFunction(handleSetRecalculateAllChargesId);

      closeModal();
    },
  }),
  withHandlers({
    handleOpenAutoRateModal: (props: Object) => () => {
      const {
        cloGuid,
        loadData,
        openModal,
        branchGuid,
        closeModal,
        equipments,
        divisionGuid,
        handleSelectRate,
    } = props;

      const transportationMode = R.path(['values', GC.FIELD_MODE], props);
      const serviceType = R.path(['values', GC.FIELD_SERVICE_TYPE], props);

      const modalContent = (
        <EnhancedAvailableRates
          cloGuid={cloGuid}
          loadData={loadData}
          openModal={openModal}
          closeModal={closeModal}
          equipments={equipments}
          serviceType={serviceType}
          branchGuid={divisionGuid}
          onSelectRate={handleSelectRate}
          customerEnterpriseGuid={branchGuid}
          transportationMode={transportationMode}
        />
      );

      const modal = {
        p: '0',
        component: modalContent,
        options: {
          width: 'auto',
          height: 'auto',
          maxWidth: '90vw',
          overflow: 'auto',
          maxHeight: '80vh',
          minHeight: '50vh',
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const callOrderRateSyncWithTripsCallback = (fromPage: any, syncData: Object, props: Object) => {
  const { getLoadDetailsRequest, getLoadCustomerRateListRequest } = props;

  if (G.isPageDispatchDetailsNewLoad(fromPage)) {
    const telGuid = G.getPropFromObject(GC.FIELD_TEL_GUID, syncData);
    const args = { [GC.FIELD_LOAD_GUID]: telGuid };

    return G.callFunctionWithArgs(getLoadCustomerRateListRequest, args);
  }

  if (G.isPageDispatchBoardNew(fromPage)) {
    const telGuid = G.getPropFromObject(GC.FIELD_TEL_GUID, syncData);

    return G.callFunctionWithArgs(getLoadDetailsRequest, telGuid);
  }
};

const withOrderRateSyncWithTrips = ({ fromPage, updateValues }: Object) => compose(
  connect(null, {
    openLoader,
    closeLoader,
    getLoadDetailsRequest,
    getOrderCostAllocationsRequest,
    getLoadCustomerRateListRequest,
    getOrderCustomerRateListRequest,
  }),
  withHandlers({
    handleSyncWithTrips: (props: Object) => async (reqData: Object, syncData: Object) => {
      const {
        values,
        setValues,
        openLoader,
        closeLoader,
        initialValues,
      } = props;

      const rateGuid = G.getGuidFromObject(initialValues);
      const telGuid = G.getPropFromObject(GC.FIELD_TEL_GUID, syncData);

      if (R.and(G.isNilOrEmpty(rateGuid), G.isNilOrEmpty(telGuid))) return;

      openLoader();

      const options = {
        data: reqData,
        params: G.ifElse(G.isNotNilAndNotEmpty(telGuid), { telGuid }, { rateGuid }),
      };

      const endpoint = endpointsMap.cloRateSyncEndpoint;

      const res = await sendRequest('put', endpoint, options);

      const { data, status } = res;

      closeLoader();

      if (G.isResponseSuccess(status)) {
        callOrderRateSyncWithTripsCallback(fromPage, syncData, props);

        if (G.isFalsy(updateValues)) return;

        const newValues = R.assoc('charges', G.getChargesFromObject(data), values);

        setValues(newValues);
      } else {
        G.handleFailResponseSimple(res);
      }
    },
  }),
  pure,
);

export {
  withOrderRateSyncWithTrips,
  withAsyncCloRateAvailableRates,
  withHandleCloRateAvailableRates,
};
