import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import { truck, driver, trailer } from '../../../../svgs';
//////////////////////////////////////////////////

const fleetIcon = {
  trucks: truck,
  drivers: driver,
  trailers: trailer,
};

export const getFleetIcon = (fleetType: string) => (
  fleetIcon[fleetType](G.getTheme('colors.dark.blue'))
);

export const joinTrailers = (trailers: Object) => (
  R.join(', ', R.map((trailer: Object) => R.pathOr('unavailable', ['unitId'], trailer), trailers))
);

export const takeArrayByOddOrEven = (array: Array, odd: boolean) => (
  array.filter((_: Object, index: number) => R.equals(R.modulo(R.add(index, 1), 2), G.ifElse(odd, 1, 0)))
);

export const filterTrailers = (trailers: Array, drivers: Array) => {
  const usedTrailers = R.compose(
    R.reduce((prev: Array, guids: Array) => R.concat(prev, guids), []),
    R.filter((trailerGuids: Object) => G.isNotNilAndNotEmpty(trailerGuids)),
    R.map((driver: Object) => R.path(['fleetObj', 'assignment', 'trailerGuids'], driver)),
  )(drivers);
  return trailers.filter((trailer: Object) => G.notContain(trailer.fleetObj.guid, usedTrailers));
};

export const filterTrucks = (trucks: Array, drivers: Array) => {
  const usedTrucks = R.compose(
    R.filter((truckGuid: Object) => G.isNotNil(truckGuid)),
    R.map((driver: Object) => R.path(['fleetObj', 'assignment', 'truckGuid'], driver)),
  )(drivers);
  return trucks.filter((truck: Object) => G.notContain(truck.fleetObj.guid, usedTrucks));
};

export const makeAssignmentInfo = (initialFleets: Object, fleet: Object) => {
  const assignment = {};
  const teamDriverGuid = R.path(['assignment', GC.FIELD_SECONDARY_DRIVER_GUID], fleet);
  const truckGuid = R.path(['assignment', GC.FIELD_TRUCK_GUID], fleet);
  const trailerGuids = R.path(['assignment', GC.FIELD_TRAILER_GUIDS], fleet);
  if (G.isNotNilAndNotEmpty(teamDriverGuid)) {
    const indexedDrivers = R.indexBy(R.prop(GC.FIELD_GUID), initialFleets.drivers);
    assignment.teamDriver = indexedDrivers[teamDriverGuid];
  }
  if (G.isNotNilAndNotEmpty(truckGuid)) {
    const indexedTrucks = R.indexBy(R.prop(GC.FIELD_GUID), initialFleets.trucks);
    assignment.truck = indexedTrucks[truckGuid];
  }
  if (G.isNotNilAndNotEmpty(trailerGuids)) {
    const indexedTrucks = R.indexBy(R.prop(GC.FIELD_GUID), initialFleets.trailers);
    assignment.trailers = trailerGuids.map((guid: string) => indexedTrucks[guid]);
  }
  return assignment;
};

export const sortEvents = (events: any, loadIndexName: string) => (
  R.compose(
    R.sortBy(R.prop(loadIndexName)),
    R.values(),
    R.filter((event: Object) => G.isNotNil(event)),
  )(events)
);

export const getTelMappedEventsForMap = (stops: Object) => {
  const mappedStops = R.map((stop: Object) => {
    const lat = R.path([GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LATITUDE], stop);
    const lng = R.path([GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LONGITUDE], stop);
    if (R.or(R.isNil(lat), R.isNil(lng))) return null;
    return {
      ...stop,
      title: `${G.toTitleCase(stop.eventType)} ${stop[GC.FIELD_TEL_EVENT_INDEX]}`,
      latLng: { lat, lng },
    };
  }, stops);
  return sortEvents(mappedStops, GC.FIELD_TEL_EVENT_INDEX);
};

const getFleetsCount = (fleets: Array) => R.length(R.filter((fleet: Object) => R.or(
  G.isNotNilAndNotEmpty(R.prop('precedingLocation', fleet)),
  G.isNotNilAndNotEmpty(R.prop('currentLocation', fleet)),
), fleets));

const getFleetsLength = (props: Object) => R.sum([
  getFleetsCount(props.drivers),
  getFleetsCount(props.trucks),
  getFleetsCount(props.trailers),
]);

export const getMultiswitchOptions = (props: Object) => [
  {
    width: 50,
    value: 'all',
    name: `${G.getWindowLocale('titles:all', 'All')} (${getFleetsLength(props)})`,
  },
  {
    width: 80,
    value: 'drivers',
    name: `${G.getWindowLocale('titles:drivers', 'Drivers')} (${getFleetsCount(props.drivers)})`,
  },
  {
    width: 80,
    value: 'trucks',
    name: `${G.getWindowLocale('titles:trucks', 'Trucks')} (${getFleetsCount(props.trucks)})`,
  },
  {
    width: 80,
    value: 'trailers',
    name: `${G.getWindowLocale('titles:trailers', 'trailers')} (${getFleetsCount(props.trailers)})`,
  },
];

export const mapFleets = (fleets: Array) => {
  const grouped = {};
  R.forEach((item: Object) => {
    let lnl = R.pathOr({}, ['precedingLocation'], item);
    if (G.isNilOrEmpty(lnl)) {
      lnl = R.pathOr({}, ['currentLocation'], item);
    }
    if (G.isNilOrEmpty(lnl)) return;
    const lat = R.prop(GC.FIELD_LATITUDE, lnl);
    const lng = R.prop(GC.FIELD_LONGITUDE, lnl);
    const resultItem = {
      lnl,
      fleetObj: item,
      latLng: { lat, lng },
      shortid: G.genShortId(),
    };
    let exist = false;
    R.forEachObjIndexed((value: Array, key: string) => {
      const item = R.find(R.propEq(resultItem.latLng, 'latLng'), value);
      if (G.isNotNil(item)) {
        exist = true;
        grouped[key].push(resultItem);
      }
    }, grouped);
    if (G.isFalse(exist)) {
      grouped[G.genShortId()] = R.of(Array, resultItem);
    }
  }, fleets);
  return grouped;
};

export const groupFleetsByLatLng = (fleets: Array) => {
  if (R.isNil(fleets)) return null;
  return R.values(mapFleets(fleets));
};

export const fleetTypeObjectMap = {
  all: 'all',
  trucks: 'trucks',
  drivers: 'drivers',
  trailers: 'trailers',
};
