import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ActionsElement } from '../../../components/actions-element';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// feature rate-shop
import PriceList from './price-list';
import { updatePricesByRowGuidRequest } from '../actions';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.light.blue');

const enhance = compose(
  connect(null, { updatePricesByRowGuidRequest }),
  withHandlers({
    handleOpenPriceList: (props: Object) => () => {
      const { openModal, closeFixedPopup } = props;

      closeFixedPopup();
      const component = <PriceList {...props} />;
      const modal = {
        p: '0px',
        component,
        options: {
          maxWidth: '90vw',
          maxHeight: '90vh',
          outsideCloseButton: true,
          title: G.getWindowLocale('titles:price-list', 'Price List'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

// TODO: check permissions
const getOptions = (props: Object) => {
  const {
    closeFixedPopup,
    handleOpenPriceList,
    excelRatingResultGuid,
    excelMassRatingSummaryGuid,
    updatePricesByRowGuidRequest,
  } = props;

  const options = [
    {
      permissions: null,
      action: handleOpenPriceList,
      frontIcon: I.gear(iconColor),
      text: G.getWindowLocale('actions:show-prices', 'Show Prices'),
    },
    {
      permissions: null,
      frontIcon: I.gear(iconColor),
      text: G.getWindowLocale('actions:update-prices', 'Update Prices'),
      action: () => {
        closeFixedPopup();
        updatePricesByRowGuidRequest({ excelRatingResultGuid, excelMassRatingSummaryGuid });
      },
    },
  ];

  return options;
};

export const ExcelResultRowActions = enhance((props: Object) => (
  <ActionsElement options={getOptions(props)} />
));
