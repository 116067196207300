import React from 'react';
// components
import { ConnectToMailIntegration } from '../../../components/connect-to-mail-integration';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

const ConnectEmail = (props: Object) => {
  const { email, initialEmail, buttonStyles } = props;

  return (
    <ConnectToMailIntegration
      {...buttonStyles}
      currentEmail={email}
      email={initialEmail}
      userGuid={G.getGuidFromObject(props)}
    />
  );
};

export default ConnectEmail;
