import * as R from 'ramda';
import React from 'react';
import { pure, compose, withPropsOnChange } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
import { QuickFilter } from '../../../components/quick-filter';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// hocs
import { withAsyncRequest, withFilteredList, withAsyncInitialDataOnDidMount } from '../../../hocs';
// ui
import { Box, Flex, Span, Text } from '../../../ui';
//////////////////////////////////////////////////

const getTemplateStopsQuantity = (pickedItems: Array) => {
  if (G.isNilOrEmpty(pickedItems)) return G.getWindowLocale('titles:no-items', 'No Items');
  const totalQuantity = G.calculateTotalQuantity(pickedItems);
  return `${R.prop(GC.FIELD_ITEM_QUANTITY, totalQuantity)}
    ${G.getEnumLocale(R.prop(GC.FIELD_ITEM_PACKAGE_TYPE, totalQuantity))}
  `;
};

export const getDataFromStops = (stops: Object) => {
  const stopsArray = R.values(stops);
  const sortedStops = R.sortBy(R.prop(GC.FIELD_ORDER), stopsArray);
  const firstStop = R.head(sortedStops);
  const lastStop = R.last(sortedStops);
  const pickups = R.filter((stop: Object) => R.propEq(GC.EVENT_TYPE_PICKUP, GC.FIELD_EVENT_TYPE, stop), stopsArray);
  const pickedItems = R.reduce(
    (acc: Array, pickup: Array) => R.concat(acc, R.pathOr([], [GC.FIELD_LOAD_ITEMS], pickup)),
    [], pickups,
  );

  return {
    pickups,
    lastStop,
    firstStop,
    pickedItems,
    sortedStops,
  };
};

const getStopInfo = (stop: Object) => {
  const cloIndex = R.prop(GC.FIELD_CLO_EVENT_INDEX, stop);
  const typeSymbol = R.head(R.pathOr('P', [GC.FIELD_EVENT_TYPE], stop));
  return `${typeSymbol} ${cloIndex}`;
};

const TemplateItem = (props: Object) => {
  const {
    rate,
    events,
    branchList,
    closeModal,
    templateName,
    enterpriseGuid,
    copyTemplateAction,
  } = props;

  const { lastStop, firstStop, pickedItems } = getDataFromStops(events);

  const branchName = R.pathOr(
    '',
    [enterpriseGuid, GC.FIELD_NAME],
    R.indexBy(R.prop(GC.FIELD_GUID), R.or(branchList, [])),
  );

  return (
    <Box
      p={15}
      mb={15}
      justifyContent='space-between'
      boxShadow='0 0 10px 0 rgba(215, 215, 215, 0.5)'
    >
      <Flex mb={15} justifyContent='space-between'>
        <TextComponent
          maxWidth={350}
          cursor='pointer'
          withEllipsis={true}
          display='inline-block'
          textDecoration='underline'
          color={G.getTheme('colors.dark.blue')}
          title={`${G.getWindowLocale('titles:copy-template', 'Copy Template')}: ${templateName}`}
          onClick={() => {
            closeModal();
            copyTemplateAction(props);
          }}
        >
          {templateName}
        </TextComponent>
        <Box fontWeight={600}>
          <Span
            mr='5px'
            color={G.getTheme('colors.dark.grey')}
          >
            {`${G.getWindowLocale('titles:branch', 'Branch')}:`}
          </Span>
          <Span color={G.getTheme('colors.dark.blue')}>{branchName}</Span>
        </Box>
      </Flex>
      <Flex justifyContent='space-between'>
        <Box flexGrow={1}>
          <Flex fontSize={12}>
            <Flex>
              <Box mr='5px'>
                <Span m='4px 4px 0 0'>{I.pickupStopIcon()}</Span>
              </Box>
              <Box>
                <Box>{R.prop(GC.FIELD_LOAD_EVENT_EARLY_DATE, firstStop)}</Box>
                <Box>{G.concatLocationFields(firstStop.location)}</Box>
              </Box>
            </Flex>
            <Flex mx={10} flexShrink={0}>
              <Box
                p='3px'
                mr='5px'
                border='1px solid'
                borderRadius='3px'
                color={G.getTheme('colors.dark.grey')}
                borderColor={G.getTheme('colors.dark.grey')}
              >
                {getStopInfo(firstStop)}
              </Box>
              <Box
                p='3px'
                border='1px solid'
                borderRadius='3px'
                color={G.getTheme('colors.lightGreen')}
                borderColor={G.getTheme('colors.lightGreen')}
              >
                {getStopInfo(lastStop)}
              </Box>
            </Flex>
            <Flex>
              <Box mr='5px'>
                <Span m='4px 4px 0 0'>{I.dropStopIcon()}</Span>
              </Box>
              <Box>
                <Box>{R.prop(GC.FIELD_LOAD_EVENT_EARLY_DATE, lastStop)}</Box>
                <Box>{G.concatLocationFields(lastStop.location)}</Box>
              </Box>
            </Flex>
          </Flex>
        </Box>
        <Box
          pl={10}
          ml={10}
          fontSize={12}
          flexShrink={0}
          borderRadius='1px'
          borderLeft='2px solid'
          borderColor={G.getTheme('colors.dark.grey')}
        >
          <Flex alignItems='start' flexDirection='column'>
            <Flex>
              <Span m='4px 4px 0 0'>{I.itemBox()}</Span>
              <Span>{getTemplateStopsQuantity(pickedItems)}</Span>
            </Flex>
            <Flex>
              <Span m='4px 4px 0 0'>{I.pigBank()}</Span>
              <Span>{G.getChargesTotalFromRateWithCurrency(rate)}</Span>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

const TemplateListModal = (props: Object) => (
  <Box
    p={15}
    width={600}
    height={500}
    overflow='auto'
    bg={G.getTheme('colors.light.mainLight')}
  >
    <Flex mb={15} justifyContent='center' color={G.getTheme('colors.greyMatterhorn')}>
      <QuickFilter
        handleSetFilter={props.handleFilterList}
        label={G.getWindowLocale('titles:filter-by-template-name', 'Filter by Template Name')}
      />
    </Flex>
    {
      G.isNilOrEmpty(props.filteredList) &&
      <Box p='50px'>
        <Flex flexDirection='column'>
          <Text fontSize='16px' color={G.getTheme('colors.dark.grey')}>
            {
              G.getWindowLocale(
                'messages:you-do-not-have-any-templates-for-this-branch',
                "You don't have any templates for this Branch",
              )
            }
          </Text>
        </Flex>
      </Box>
    }
    {
      G.isNotNilAndNotEmpty(props.filteredList)
      && (
        props.filteredList.map((item: Object) => (
          <TemplateItem
            key={R.prop(GC.FIELD_TEMPLATE_GUID, item)}
            {...item}
            closeModal={props.closeModal}
            branchList={props.branchList}
            filterString={props.filterString}
            copyTemplateAction={props.copyTemplateAction}
          />
        ))
      )
    }
  </Box>
);

const filterList = (filterString: string, originalList: Array) => R.filter((item: Object) => {
  const { templateName } = item;
  if (R.or(G.isNilOrEmpty(filterString), G.isNilOrEmpty(originalList))) return true;
  const nameLower = R.toLower(templateName);
  const filterLower = R.toLower(filterString);
  const searched = nameLower.indexOf(filterLower);
  return G.notEquals(-1, searched);
}, originalList);

export const enhance = compose(
  withAsyncRequest,
  withAsyncInitialDataOnDidMount,
  withFilteredList({ filter: filterList }),
  withPropsOnChange(['asyncInitialData'], (props: Object) => {
    const { setOriginalList, setFilteredList, asyncInitialData } = props;
    const data = R.path(['data'], asyncInitialData);
    if (G.isNotNilAndNotEmpty(data)) {
      setOriginalList(data);
      setFilteredList(data);
    }
  }),
  pure,
);

export default enhance(TemplateListModal);
