import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const selectItem = createAction('selectItem');
export const setReports = createAction('setReports');
export const addPayrolls = createAction('addPayrolls');
export const setUsedReport = createAction('setUsedReport');
export const setListLoading = createAction('setListLoading');
export const setInitialState = createAction('setInitialState');
export const setReportPending = createAction('setReportPending');
export const cleanQuickFilter = createAction('cleanQuickFilter');
export const setTableTitleSort = createAction('setTableTitleSort');
export const getPayrollDetails = createAction('getPayrollDetails');
export const getItemListRequest = createAction('getItemListRequest');
export const getItemListSuccess = createAction('getItemListSuccess');
export const qbIIFImportRequest = createAction('qbIIFImportRequest');
export const setTableTitleFilter = createAction('setTableTitleFilter');
export const printPayrollRequest = createAction('printPayrollRequest');
export const createReportRequest = createAction('createReportRequest');
export const updateReportRequest = createAction('updateReportRequest');
export const customExportRequest = createAction('customExportRequest');
export const togglePayrollDetails = createAction('togglePayrollDetails');
export const setQuickFilterParams = createAction('setQuickFilterParams');
export const getPayrollXMLRequest = createAction('getPayrollXMLRequest');
export const printByReportRequest = createAction('printByReportRequest');
export const chaseCSVExportRequest = createAction('chaseCSVExportRequest');
export const updatePayrollInvoices = createAction('updatePayrollInvoices');
export const getXMLByReportRequest = createAction('getXMLByReportRequest');
export const getPayrollDetailsError = createAction('getPayrollDetailsError');
export const resetListAndPagination = createAction('resetListAndPagination');
export const sendToQuickBookRequest = createAction('sendToQuickBookRequest');
export const exportReportDataRequest = createAction('exportReportDataRequest');
export const setPayrollDetailsLoading = createAction('setPayrollDetailsLoading');
export const getPayrollDetailsSuccess = createAction('getPayrollDetailsSuccess');
export const removeInvoiceFromPayroll = createAction('removeInvoiceFromPayroll');
export const getConfigsByNamesSuccess = createAction('getConfigsByNamesSuccess');
export const getConfigsByNamesRequest = createAction('getConfigsByNamesRequest');
export const removeDriverPayrollFromUI = createAction('removeDriverPayrollFromUI');
export const updateDriverInvoiceSuccess = createAction('updateDriverInvoiceSuccess');
export const updateDriverInvoiceRequest = createAction('updateDriverInvoiceRequest');
export const handleUpdatePayrollRequest = createAction('handleUpdatePayrollRequest');
export const removePayrollInvoiceFromUI = createAction('removePayrollInvoiceFromUI');
export const changeDefaultReportRequest = createAction('changeDefaultReportRequest');
export const deleteDriverPayrollsRequest = createAction('deleteDriverPayrollsRequest');
export const createDriversPayrollRequest = createAction('createDriversPayrollRequest');
export const collapseAndResetPayrollInvoices = createAction('collapseAndResetPayrollInvoices');
export const getAvailableCustomExportTypesSuccess = createAction('getAvailableCustomExportTypesSuccess');
export const getPayrollByReportAndUpdateOnListRequest = createAction('getPayrollByReportAndUpdateOnListRequest');
