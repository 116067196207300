import React from 'react';
import * as R from 'ramda';
import { compose, lifecycle, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { ReactSelect } from '../../../ui';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature new-do
import { HeaderFieldWrapper } from '../ui';
import { isPageCloTemplate } from '../helpers';
import { InputWrapper } from '../forms/formik/input-wrapper';
//////////////////////////////////////////////////

const enhance = compose(
  withState('templateOptions', 'setTemplateOptions', []),
  withHandlers({
    handleGetTemplates: (props: Object) => async () => {
      const { branchGuid, setTemplateOptions } = props;

      const currentUserBranchGuid = G.getAmousCurrentUserBranchGuidFromWindow();
      const branchGuidToUse = R.or(branchGuid, currentUserBranchGuid);
      const options = {
        params: {
          [GC.FIELD_BRANCH_GUID]: branchGuidToUse,
        },
      };

      const res = await sendRequest('get', endpointsMap.routeTemplateListWithSingleClo, options);

      const { data, status } = res;

      if (R.and(G.isResponseSuccess(status), G.isNotNilAndNotEmpty(data))) {
        setTemplateOptions(G.mapNameGuidToLabelValue(data));
      }
    },
  }),
  lifecycle({
    componentWillMount() {
      this.props.handleGetTemplates();
    },
  }),
);

const ApplyTemplate = (props: Object) => {
  const {
    pageType,
    setValueToStore,
    templateOptions,
    telCreationMode,
    routeTemplateGuid,
  } = props;

  if (R.and(
    R.not(isPageCloTemplate(pageType)),
    R.equals(telCreationMode, GC.TEL_CREATION_MODE_MULTI_TEL),
  )) {
    return (
      <InputWrapper
        inputWrapperStyles={{ zIndex: 16 }}
        label={G.getWindowLocale('titles:apply-route-template', 'Apply Route Template')}
      >
        <HeaderFieldWrapper>
          <ReactSelect
            isClearable={true}
            name='routeTemplateGuid'
            options={templateOptions}
            value={routeTemplateGuid}
            onChange={(item: any) => setValueToStore({ value: R.path(['value'], item), path: 'routeTemplateGuid' })}
          />
        </HeaderFieldWrapper>
      </InputWrapper>
    );
  }

  return null;
};

export default enhance(ApplyTemplate);
