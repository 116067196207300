import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// features
import { setCarrierRateToStore } from '../new-do/reducer';
import { TAB_NAME_CLO_SUMMARY } from '../new-do/constants';
import { isValidCustomerRateForm } from '../new-do/validation';
import {
  getNewItemFields,
  getDefaultDropFields,
  allCustomerRateFields,
  getNewContainerFields,
  getDefaultPickupFields,
} from '../new-do/settings/fields-settings';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature new-do-quote
import * as A from './actions';
import { PAGE_TYPE_CREATE_QUOTE, PAGE_TYPE_UPDATE_QUOTE } from './constants';
//////////////////////////////////////////////////

const referenceFormInitData = {
  isValid: false,
  [GC.FIELD_LOAD_SERVICES]: [],
  [GC.FIELD_LOAD_REFERENCES]: [],
  [GC.FIELD_LOAD_EQUIPMENT]: null,
  [GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS]: null,
};

const staticData = {
  branchList: [],
  quoteNumber: '',
  leftActiveTad: 1,
  branchRefTypes: {},
  divisionGuid: null,
  branchConfigs: null,
  telDriverRate: null,
  telCarrierRate: null,
  expirationDate: null,
  validationErrors: null,
  quoteNumberSequence: null,
  loadType: GC.LOAD_TYPE_CLO,
  pageType: PAGE_TYPE_CREATE_QUOTE,
  branchInfo: GC.EMPTY_OPTION_OBJECT,
  rightActiveTad: TAB_NAME_CLO_SUMMARY,
  primaryReference: GC.EMPTY_OPTION_OBJECT,
  referenceFormData: referenceFormInitData,
};

const createDOQuoteData = {
  rateBackup: null,
  [GC.FIELD_RATE]: null,
  pageType: PAGE_TYPE_CREATE_QUOTE,
  [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_CLO,
};

const createNewPickup = (order: number) => ({
  order,
  isValid: false,
  id: G.generateGuid(),
  [GC.FIELD_DISTANCE_CLO]: null,
  formData: getDefaultPickupFields(),
  [GC.FIELD_EVENT_TYPE]: GC.EVENT_TYPE_PICKUP,
});

const createNewDrop = (order: number) => ({
  order,
  isValid: false,
  id: G.generateGuid(),
  [GC.FIELD_DISTANCE_CLO]: null,
  formData: getDefaultDropFields(),
  [GC.FIELD_EVENT_TYPE]: GC.EVENT_TYPE_DROP,
});

const createNewStop = (stopType: string, stopOrder: string, store: Object) => {
  if (R.equals(stopType, GC.EVENT_TYPE_PICKUP)) {
    const newStop = createNewPickup(stopOrder);
    const withDates = G.getBranchNewStopDateTimeDefaultValues(stopType, store);
    const newFormData = R.mergeRight(newStop.formData, withDates);

    return R.assoc('formData', newFormData, newStop);
  }
  const newStop = createNewDrop(stopOrder);
  const withDates = G.getBranchNewStopDateTimeDefaultValues(stopType, store);
  const newFormData = R.mergeRight(newStop.formData, withDates);

  return R.assoc('formData', newFormData, newStop);
};

const pageTypeToInitialDataMap = {
  [PAGE_TYPE_CREATE_QUOTE]: createDOQuoteData,
};

const getInitialState = (pageType: string) => {
  const pickup1 = createNewPickup(1);
  const drop1 = createNewDrop(2);
  const initial = R.prop(pageType, pageTypeToInitialDataMap);
  const initData = R.mergeRight(staticData, initial);

  return R.mergeRight(
    initData,
    {
      stops: {
        1: pickup1,
        2: drop1,
      },
    },
  );
};

const setValueToStore = (state: Object, { path, value }: Object) => (
  P.$set(path, value, state)
);

const setActiveLeftTab = (state: Object, data: Object) => (
  P.$set('leftActiveTad', data, state)
);

const setActiveRightTab = (state: Object, data: Object) => (
  P.$set('rightActiveTad', data, state)
);

const setItemOrContainer = (useContainers: any, branchConfigs: Object, state: Object) => {
  if (G.isTrue(useContainers)) {
    const withContainers = R.assocPath(
      [GC.FIELD_STOP_PICKED_UP_CONTAINERS, 0],
      getNewContainerFields(),
      state,
    );

    return R.assoc('items', [], withContainers);
  }

  return R.assocPath(
    ['items', 0],
    getNewItemFields(branchConfigs),
    state,
  );
};

const setDefaultLocationTypeToValues = (values: Object, locationType: any) => {
  if (G.isNilOrEmpty(locationType)) return values;

  return R.assoc(GC.FIELD_LOCATION_TYPE, locationType, values);
};

const getBranchConfigsSuccess = (state: Object, data: Object) => {
  const { branchConfigs } = data;

  const expirationDate = G.addMomentTime(
    G.getCurrentDateWithFormat(GC.DEFAULT_DATE_FORMAT),
    R.or(G.getConfigValueFromStore(GC.CLO_QUOTE_EXPIRATION_DAYS, branchConfigs), 0),
    'days',
  );
  const defaultPickupLocationType = G.getConfigValueFromStore(GC.CLO_DEFAULT_PICKUP_LOCATION_TYPE, branchConfigs);
  const defaultDropLocationType = G.getConfigValueFromStore(GC.CLO_DEFAULT_DROP_LOCATION_TYPE, branchConfigs);
  const withPickupDates = R.mergeRight(
    R.pathOr({}, ['stops', 1, 'formData'], state),
    setDefaultLocationTypeToValues(G.getBranchPickupDateTimeDefaultValues(branchConfigs), defaultPickupLocationType),
  );
  const defaultItem = setItemOrContainer(false, branchConfigs, withPickupDates);
  const withDropDates = R.mergeRight(
    R.pathOr({}, ['stops', 2, 'formData'], state),
    setDefaultLocationTypeToValues(G.getBranchDropDateTimeDefaultValues(branchConfigs), defaultDropLocationType),
  );
  G.setItemToWindow('amousNewDoBranchConfigs', branchConfigs);

  return P.$all(
    P.$set('branchConfigs', branchConfigs),
    P.$set('stops.1.formData', defaultItem),
    P.$set('expirationDate', expirationDate),
    P.$set('stops.2.formData', withDropDates),
    state,
  );
};

const stopApptsFields = [
  GC.FIELD_LOAD_FCFS,
  GC.FIELD_STOP_NUMBER,
  GC.FIELD_LOAD_EVENT_LATE_DATE,
  GC.FIELD_LOAD_EVENT_EARLY_DATE,
  GC.FIELD_LOAD_APPOINTMENT_DATE,
  GC.FIELD_LOAD_APPOINTMENT_NUMBER,
  GC.FIELD_LOAD_APPOINTMENT_REQUIRED,
  GC.FIELD_LOAD_APPOINTMENT_LATE_TIME,
  GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME,
];

const mapQuoteEvents = (events: Array, allItems: Object) => R.compose(
  R.indexBy(R.prop(GC.FIELD_ORDER)),
  G.mapIndexed((event: Object, index: number) => {
    const {
      itemIds,
      location,
      eventType,
      references,
      cloEventIndex,
      cloDistanceToNextStop,
    } = event;

    const appts = R.pick(stopApptsFields, event);
    const createStopFunc = G.ifElse(
      G.isEventTypePickup(eventType),
      createNewPickup,
      createNewDrop,
    );
    const itemsToUse = G.ifElse(G.isEventTypePickup(eventType), R.values(R.pick(itemIds, allItems)), itemIds);
    const init = createStopFunc(cloEventIndex);
    const newFormData = {
      ...init.formData,
      ...event,
      ...G.resetLocationTypeFromDropdownOption(location),
      ...appts,
      references,
      order: index,
      items: itemsToUse,
    };

    return {
      ...init,
      cloDistanceToNextStop,
      formData: newFormData,
    };
  }),
)(events);

const getBranchConfigsWithOrderQuoteDataSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(R.path(['orderQuoteData'], data))) return state;

  const { branchList } = state;
  const { branchGuid, branchConfigs, orderQuoteData } = data;
  const {
    rate,
    items,
    events,
    services,
    equipment,
    references,
    divisionGuid,
    telFleetRate,
    telCarrierRate,
    expirationDate,
    orderQuoteNumber,
    specialInstructions,
  } = orderQuoteData;

  const branchInfo = R.find(R.propEq(branchGuid, GC.FIELD_GUID), branchList);
  const branchInfoMapped = {
    value: R.prop(GC.FIELD_GUID, branchInfo),
    label: R.prop(GC.FIELD_NAME, branchInfo),
  };
  const filteredReferences = R.filter(({ primary }: Object) => G.isFalse(primary), references);
  const allItems = R.indexBy(R.prop(GC.FIELD_ITEM_INTERNAL_ID), items);
  G.setItemToWindow('amousNewDoBranchConfigs', branchConfigs);

  return P.$all(
    P.$set('rate', rate),
    P.$set('rateBackup', rate),
    P.$set('divisionGuid', divisionGuid),
    P.$set('telDriverRate', telFleetRate),
    P.$set('branchInfo', branchInfoMapped),
    P.$set('branchConfigs', branchConfigs),
    P.$set('quoteNumber', orderQuoteNumber),
    P.$set('expirationDate', expirationDate),
    P.$set('telCarrierRate', telCarrierRate),
    P.$set('orderQuoteData', orderQuoteData),
    P.$set('pageType', PAGE_TYPE_UPDATE_QUOTE),
    P.$set('stops', mapQuoteEvents(events, allItems)),
    P.$set('referenceFormData.references', filteredReferences),
    P.$set('referenceFormData.specialInstructions', specialInstructions),
    P.$set('referenceFormData.equipment', G.getPropFromObject(GC.FIELD_DROPDOWN_OPTION_GUID, equipment)),
    P.$set('referenceFormData.services', R.map(R.prop(GC.FIELD_DROPDOWN_OPTION_GUID), R.or(services, []))),
    state,
  );
};

const setFormDataToStop = (state: Object, data: Object) => P.$all(
  P.$set(`stops.${data.stopOrder}.isValid`, data.isValid),
  P.$set(`stops.${data.stopOrder}.formData`, data.formData),
  state,
);

const setFormDataToStore = (state: Object, data: Object) => {
  const { dataName, formData } = data;

  if (R.equals(dataName, 'rate')) {
    return P.$all(
      P.$set('rate', formData),
      P.$set('rateBackup', formData),
      state,
    );
  }

  return P.$set(dataName, formData, state);
};

const setCustomerRateToStore = (state: Object, data: Object) => (
  P.$set('rate', data, state)
);

const setCustomerRateChargesToStore = (state: Object, data: Object) => {
  const { rate, telDriverRate, telCarrierRate } = state;

  if (G.isNilOrEmpty(rate)) {
    const fields = R.map(R.prop('fieldName'), allCustomerRateFields);
    const rate = R.assoc(
      GC.FIELD_CHARGES,
      data,
      R.pick(fields, R.or(telDriverRate, telCarrierRate)),
    );

    return P.$set('rate', rate, state);
  }

  return P.$all(
    P.$set('rate.charges', data),
    P.$set('rateBackup.charges', data),
    state,
  );
};

const setDriverRateToStore = (state: Object, data: Object) => (
  P.$set('telDriverRate', data, state)
);

const addNewStopToStore = (state: Object, stopType: Object) => {
  const { stops } = state;

  const stopOrder = R.inc(R.length(R.values(stops)));
  const stop = createNewStop(stopType, stopOrder, state);

  return P.$set(`stops.${stopOrder}`, stop, state);
};

const removeStopFromStore = (state: Object, stopOrder: Object) => {
  const stops = R.compose(
    R.indexBy(R.prop(GC.FIELD_ORDER)),
    G.mapIndexed((item: Object, index: number) => R.assoc(GC.FIELD_ORDER, R.inc(index), item)),
    R.reject((item: Object) => R.equals(R.prop(GC.FIELD_ORDER, item), stopOrder)),
    R.sortBy(R.prop(GC.FIELD_ORDER)),
    R.values(),
  )(state.stops);

  return P.$all(
    P.$set('stops', stops),
    P.$set('leftActiveTad', 1),
    state,
  );
};

const removeItemFromStore = (state: Object, itemInternalId: Object) => {
  const stops = R.compose(
    R.indexBy(R.prop(GC.FIELD_ORDER)),
    R.map((stop: Object) => {
      const { formData: { items }, eventType } = stop;
      if (G.isEventTypeDrop(eventType)) {
        const newItems = R.without(itemInternalId, items);

        return R.assocPath(['formData', GC.FIELD_LOAD_ITEMS], newItems, stop);
      }

      return stop;
    }),
    R.values(),
  )(state.stops);

  return P.$set('stops', stops, state);
};

const setReorderedStops = (state: Object, data: Object) => (
  P.$set('stops', data, state)
);

const recalculateLoadDistancesSuccess = (state: Object, data: Object) => {
  const { distance, mappedStops } = data;
  if (G.isNotNilAndNotEmpty(distance)) {
    const rate = R.mergeRight(state.rateBackup, distance);
    const rateWithValid = R.assoc('isValid', isValidCustomerRateForm(rate), rate);

    return P.$all(
      P.$set('stops', mappedStops),
      P.$set('rate', rateWithValid),
      P.$set('rateBackup', rateWithValid),
      state,
    );
  }

  return P.$set('stops', mappedStops, state);
};

const getBranchListSuccess = (state: Object, data: Array) => (
  P.$set('branchList', data, state)
);

const getBranchStylingSuccess = (state: Object, data: Array) => (
  P.$set('styling', data, state)
);

const getQuoteNumberSequenceSuccess = (state: Object, data: string) => P.$all(
  P.$set('quoteNumberSequence', data),
  P.$set('quoteNumber', G.createSequenceApproxValue(data)),
  state,
);

const getAllAvBranchRefTypesSuccess = (state: Object, { scopeName, data }: Object) => (
  P.$set(`branchRefTypes.${scopeName}`, data, state)
);

const setQuoteNumber = (state: Object, value: string) => (
  P.$set('quoteNumber', value, state)
);

const setExpirationDate = (state: Object, value: string) => (
  P.$set('expirationDate', value, state)
);


const setDivisionGuid = (state: Object, value: string) => (
  P.$set('divisionGuid', value, state)
);

const setBranchInfo = (state: Object, data: object) => G.ifElse(
  R.isNil(data),
  P.$set('branchInfo', GC.EMPTY_OPTION_OBJECT, state),
  P.$set('branchInfo', data, state),
);

const setValidationErrors = (state: Object, data: Object) => {
  if (G.isNotNilAndNotEmpty(data)) {
    return P.$set('validationErrors', data, state);
  }

  return state;
};

const removeValidationErrors = (state: Object) => (
  P.$set('validationErrors', null, state)
);

const setInitialStateToStore = () => (
  getInitialState(PAGE_TYPE_CREATE_QUOTE)
);

const cleanStore = (state: Object, data: string) => {
  const guid = G.getGuidFromObject(data);
  const pageType = R.pathOr(state.pageType, ['pageType'], data);
  let customerInfo = GC.EMPTY_OPTION_OBJECT;

  if (G.isNotNil(guid)) {
    customerInfo = {
      value: guid,
      label: data[GC.FIELD_BRANCH_NAME],
    };
  }

  const branchList = P.$get('branchList', state);

  return P.$all(
    P.$set('branchList', branchList),
    P.$set('branchInfo', customerInfo),
    getInitialState(pageType),
  );
};

export default createReducer({
  [A.cleanStore]: cleanStore,
  [A.setBranchInfo]: setBranchInfo,
  [A.setQuoteNumber]: setQuoteNumber,
  [A.setDivisionGuid]: setDivisionGuid,
  [A.setValueToStore]: setValueToStore,
  [A.setActiveLeftTab]: setActiveLeftTab,
  [A.setActiveRightTab]: setActiveRightTab,
  [A.setReorderedStops]: setReorderedStops,
  [A.setFormDataToStop]: setFormDataToStop,
  [A.addNewStopToStore]: addNewStopToStore,
  [A.setExpirationDate]: setExpirationDate,
  [A.setFormDataToStore]: setFormDataToStore,
  [A.removeItemFromStore]: removeItemFromStore,
  [A.setValidationErrors]: setValidationErrors,
  [A.removeStopFromStore]: removeStopFromStore,
  [A.getBranchListSuccess]: getBranchListSuccess,
  [A.setDriverRateToStore]: setDriverRateToStore,
  [A.setCarrierRateToStore]: setCarrierRateToStore,
  [A.setInitialStateToStore]: setInitialStateToStore,
  [A.removeValidationErrors]: removeValidationErrors,
  [A.setCustomerRateToStore]: setCustomerRateToStore,
  [A.getBranchStylingSuccess]: getBranchStylingSuccess,
  [A.getBranchConfigsSuccess]: getBranchConfigsSuccess,
  [A.getQuoteNumberSequenceSuccess]: getQuoteNumberSequenceSuccess,
  [A.setCustomerRateChargesToStore]: setCustomerRateChargesToStore,
  [A.getAllAvBranchRefTypesSuccess]: getAllAvBranchRefTypesSuccess,
  [A.recalculateLoadDistancesSuccess]: recalculateLoadDistancesSuccess,
  [A.getBranchConfigsWithOrderQuoteDataSuccess]: getBranchConfigsWithOrderQuoteDataSuccess,
}, getInitialState(PAGE_TYPE_CREATE_QUOTE));
