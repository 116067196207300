import * as R from 'ramda';
import React from 'react';
// components
import { Switcher } from '../../../components/switcher';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// feature new-do
import { Input } from '../ui';
import * as C from '../constants';
//////////////////////////////////////////////////

const switchOptions = [
  {
    width: 70,
    value: GC.TEL_CREATION_MODE_SINGLE_TEL,
    name: G.getWindowLocale('titles:single-tel', 'Single TEL'),
  },
  {
    width: 70,
    value: GC.TEL_CREATION_MODE_NO_TEL,
    name: G.getWindowLocale('titles:no-tel', 'No TEL'),
  },
  {
    width: 70,
    value: GC.TEL_CREATION_MODE_MULTI_TEL,
    name: G.getWindowLocale('titles:multi-trip', 'Multi Trip'),
  },
];

const getSelectedIndex = (mode: string) => {
  if (R.equals(mode, GC.TEL_CREATION_MODE_MULTI_TEL)) return 2;

  if (R.equals(mode, GC.TEL_CREATION_MODE_NO_TEL)) return 1;

  return 0;
};

const LoadsData = (props: Object) => (
  <Flex mx='10px' flexShrink={0}>
    <Box color={G.getTheme('colors.dark.grey')}>{G.getWindowLocale('titles:number-of-loads', 'Number Of Loads')}</Box>
    <Input
      pl='5px'
      pr='5px'
      ml='10px'
      mr='20px'
      width='40px'
      height='22px'
      lineHeight='22px'
      name='numberOfLoads'
      value={props.numberOfLoads}
      onChange={(event: Object) => props.setNumberOfLoads(event.target.value)}
    />
    {
      props.showTelMode &&
      <Switcher
        version={2}
        options={switchOptions}
        selectedOptionIndex={getSelectedIndex(props.telCreationMode)}
        onSwitch={(value: string) => {
          props.setTelCreationMode(value);

          if (R.and(
            G.notEquals(value, GC.TEL_CREATION_MODE_SINGLE_TEL),
            R.propEq(C.TAB_NAME_TRIP_PRICING, 'leftActiveTad', props),
          )) props.setActiveLeftTab(C.TAB_NAME_PRICING);
        }}
      />
    }
  </Flex>
);

export default LoadsData;
