import React from 'react';
import * as R from 'ramda';
import * as P from 'plow-js';
import {
  pure,
  compose,
  withState,
  lifecycle,
  withHandlers,
} from 'react-recompose';
// components
import { FormFooter2 } from '../../../../components/form-footer';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { RelativeBox } from '../../../../ui';
// feature payroll
import * as H from '../../helpers';
import { TableInner, TableWrapper2 } from '../../ui';
import PayrollTableRow, { PayrollTableRowHeader } from '../../components/driver-table-row';
//////////////////////////////////////////////////

const initialVendorProps = [
  { name: 'invoices', value: null },
  { name: 'selected', value: true },
  { name: 'expanded', value: false },
  { name: 'allSelected', value: true },
  { name: 'payrollNumber', value: '' },
  { name: 'invoicesError', value: false },
  { name: 'invoicesLoading', value: false },
  { name: 'willNotBeAddedText', value: null },
];

const enhance = compose(
  withState(
    'vendors',
    'updateVendors',
    ({ initialData, payrollDates }: Object) => R.indexBy(
      R.prop(GC.FIELD_FLEET_VENDOR_GUID),
      G.setPropsWithValuesToArrayItems(
        initialData.map((vendor: Object) => (
          R.mergeRight(
            vendor,
            {
              dateTo: payrollDates.dateTo,
              dateFrom: payrollDates.dateFrom,
              fullName: `${R.prop(GC.FIELD_NAME, vendor)}`,
            },
          )
        )),
        initialVendorProps,
      ),
    ),
  ),
  withState('checkedAllPayroll', 'setCheckedAllPayroll', ({ vendors }: Object) => G.isAllChecked(R.values(vendors))),
  withState('submitValidation', 'setSubmitValidation', false),
  withHandlers({
    handleClickPayrollCheckbox: (props: Object) => (vendor: Object) => {
      const { vendors, updateVendors, setCheckedAllPayroll } = props;

      const updatedVendors = H.getPayrollsOnTogglePayrollSelect(vendor, vendors, GC.FIELD_FLEET_VENDOR_GUID);

      setCheckedAllPayroll(G.isAllChecked(R.values(updatedVendors)));

      updateVendors(updatedVendors);
    },
    handleClickAllPayrollCheckbox: (props: Object) => () => {
      const { vendors, updateVendors, checkedAllPayroll, setCheckedAllPayroll } = props;

      setCheckedAllPayroll(R.not(checkedAllPayroll));

      updateVendors(H.getPayrollsOnAllTogglePayrollSelect(
        vendors,
        checkedAllPayroll,
        GC.FIELD_FLEET_VENDOR_GUID,
      ));
    },
    handleTogglePayrollDetails: (props: Object) => (vendor: Object) => {
      const { vendors, updateVendors } = props;

      const vendorState = R.mergeRight(
        vendor,
        {
          invoicesError: false,
          expanded: R.not(vendor.expanded),
          invoicesLoading: G.isNilOrEmpty(vendor.invoices),
        },
      );

      updateVendors(R.assoc(R.prop(GC.FIELD_FLEET_VENDOR_GUID, vendor), vendorState, vendors));

      if (R.and(
        G.isNilOrEmpty(vendor.invoices),
        R.not(vendor.expanded),
      )) H.loadVendorInvoices(props, vendorState);
    },
    handleChangePayrollNumber: (props: Object) => (value: string, vendor: Object) => {
      const { vendors, updateVendors } = props;

      updateVendors(R.assocPath(
        [R.prop(GC.FIELD_FLEET_VENDOR_GUID, vendor), 'payrollNumber'],
        value,
        vendors,
      ));
    },
    handleChangePayrollDateRange: (props: Object) => (dates: string, guid: string) => {
      const { vendors, updateVendors } = props;

      updateVendors(P.$all(
        P.$set(`${guid}.dateTo`, dates.dateTo),
        P.$set(`${guid}.dateFrom`, dates.dateFrom),
        vendors,
      ));
    },
    handleClickInvoiceCheckbox: ({ vendors, updateVendors }: Object) => (vendor: Object) => {
      updateVendors(R.assoc(R.prop(GC.FIELD_FLEET_VENDOR_GUID, vendor), vendor, vendors));
    },
    handleSave: (props: Object) => () => {
      const { vendors, closeModal, addPayrolls, setSubmitValidation } = props;

      const vendorsToUse = R.filter(
        (vendor: Object) => vendor.selected,
        R.values(vendors),
      );

      if (R.equals(vendorsToUse.length, 0)) return closeModal();

      setSubmitValidation(true);

      addPayrolls(vendorsToUse);
    },
  }),
  lifecycle({
    componentWillUpdate(nextProps: Object) {
      if (G.notEquals(this.props.payrollDates, nextProps.payrollDates)) {
        return this.props.updateVendors(R.map(
          (vendor: Object) => R.mergeRight(
            vendor,
            {
              dateTo: nextProps.payrollDates.dateTo,
              dateFrom: nextProps.payrollDates.dateFrom,
            },
          ),
          this.props.vendors,
        ));
      }

      if (R.and(
        G.notEquals(this.props.updatedInvoice, nextProps.updatedInvoice),
        G.isNotNil(nextProps.updatedInvoice),
      )) {
        return this.props.updateVendors(
          H.recalculateDriverInvoices(this.props.vendors, nextProps.updatedInvoice),
        );
      }
    },
  }),
  pure,
);

const VendorWithInvoicesTable = enhance((props: Object) => {
  const {
    vendors,
    handleSave,
    branchGuid,
    openFixedPopup,
    closeFixedPopup,
    submitValidation,
    autoPayrollNumber,
    checkedAllPayroll,
    handleEditVendorInvoice,
    handleChangePayrollNumber,
    handleClickPayrollCheckbox,
    handleTogglePayrollDetails,
    handleClickInvoiceCheckbox,
    updateVendorInvoiceRequest,
    handleChangePayrollDateRange,
    handleClickAllPayrollCheckbox,
  } = props;

  const empty = R.not(R.any(
    (entity: Object) => entity.selected,
    R.values(vendors),
  ));

  return (
    <RelativeBox width='100%' zIndex='0'>
      <TableWrapper2 maxH='calc(100vh - 400px)'>
        <TableInner>
          {
            G.isNotNilAndNotEmpty(vendors) && (
              <PayrollTableRowHeader
                checkedAllPayroll={checkedAllPayroll}
                handleClickAllPayrollCheckbox={handleClickAllPayrollCheckbox}
              />
            )
          }
          {
            R.values(vendors).map((vendor: Object, index: number) => (
              <PayrollTableRow
                key={index}
                data={vendor}
                isVendor={true}
                branchGuid={branchGuid}
                openFixedPopup={openFixedPopup}
                closeFixedPopup={closeFixedPopup}
                toggle={handleTogglePayrollDetails}
                submitValidation={submitValidation}
                autoPayrollNumber={autoPayrollNumber}
                onDateChange={handleChangePayrollDateRange}
                onCheckboxClick={handleClickPayrollCheckbox}
                handleEditDriverInvoice={handleEditVendorInvoice}
                onPayrollNumberChange={handleChangePayrollNumber}
                onInvoiceCheckboxClick={handleClickInvoiceCheckbox}
                updateDriverInvoiceRequest={updateVendorInvoiceRequest}
              />
            ))
          }
        </TableInner>
      </TableWrapper2>
      <FormFooter2
        boxStyles={{ p: 15 }}
        submitDisabled={empty}
        submitAction={G.ifElse(empty, () => {}, handleSave)}
        submitBtnText={G.getWindowLocale('actions:save', 'Save')}
      />
    </RelativeBox>
  );
});

export default VendorWithInvoicesTable;
