import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { Switcher } from '../../../components/switcher';
import { PageReportFilters } from '../../../components/filter/report-and-quick-filters';
// features
import { makeSelectCurrentUserName } from '../../auth/selectors';
import { makeSelectAvailableReportGeoFencingZoneList } from '../../geo-fencing-zone/report/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncPinReport } from '../../../hocs';
// ui
import { Box, Flex, ListWrapper, RelativeFlex } from '../../../ui';
// feature pivot-table
import PivotTable from '../';
import ReportSelector from './report-selector';
import { PinnedReports } from './pinned-reports';
import { filterPropsMap } from '../settings/filter-params';
import { makeSelectUsedReport, makeSelectAvailableReports } from '../selectors';
import {
  setReports,
  setUsedReport,
  updateReportRequest,
  getPivotTableDataRequest,
  changeDefaultReportRequest,
} from '../actions';
//////////////////////////////////////////////////

const switchOptions = [
  {
    width: 70,
    value: 'complex',
    name: G.getWindowLocale('titles:complex', 'Complex'),
  },
  {
    width: 70,
    value: 'simple',
    name: G.getWindowLocale('titles:simple', 'Simple'),
  },
];

const PivotTableWrapper = (props: Object) => {
  const {
    p,
    pl,
    tabs,
    reportList,
    selectedReport,
    listWrapperStyles,
    hideReportFilters,
    handleSetPivotType,
    relativeFlexStyles,
    handleSelectReport,
    pivotTypeFlexStyles,
    handlePinOrUnpinReport,
    getPivotTableDataRequest,
    changeDefaultReportRequest,
  } = props;

  const pinnedReports = R.compose(
    R.sortBy(R.prop(GC.FIELD_PINNED_REPORT_INDEX)),
    R.filter(R.prop('pinnedReport')),
  )(R.or(reportList, []));

  const filterProps = G.getPropFromObject(G.getPropFromObject(GC.FIELD_TYPE, selectedReport), filterPropsMap);

  return (
    <ListWrapper
      pl={R.or(pl, 65)}
      p={R.or(p, 15)}
      bgColor={G.getTheme('pages.layOutBgColor')}
      {...G.spreadUiStyles(listWrapperStyles)}
    >
      <RelativeFlex zIndex={12} alignItems='flex-end' {...G.spreadUiStyles(relativeFlexStyles)}>
        {tabs}
        <Flex ml={50} alignItems='center' {...G.spreadUiStyles(pivotTypeFlexStyles)}>
          <Box mr={10}>{G.getWindowLocale('titles:pivot-type', 'Pivot Type')}</Box>
          <Switcher
            version={2}
            options={switchOptions}
            selectedOptionIndex={0}
            onSwitch={handleSetPivotType}
          />
        </Flex>
        <Box ml='auto' zIndex={11}>
          <ReportSelector {...props} filterProps={filterProps} />
        </Box>
      </RelativeFlex>
      {
        G.isNotEmpty(pinnedReports) &&
        <PinnedReports
          list={pinnedReports}
          selectedReport={selectedReport}
          handleSelectReport={handleSelectReport}
          handlePinOrUnpinReport={handlePinOrUnpinReport}
          changeDefaultReportRequest={changeDefaultReportRequest}
        />
      }
      {
        R.and(R.not(hideReportFilters), G.isNotNilAndNotEmpty(selectedReport)) &&
        <PageReportFilters
          {...props}
          filterProps={filterProps}
          usePortalForFilters={true}
          getItemListRequest={getPivotTableDataRequest}
        />
      }
      <PivotTable {...props} />
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  selectedReport: makeSelectUsedReport(state),
  reportList: makeSelectAvailableReports(state),
  userLoginId: makeSelectCurrentUserName(state),
  geoFencingZones: makeSelectAvailableReportGeoFencingZoneList(state),
});

const enhance = compose(
  connect(mapStateToProps, {
    setReports,
    setUsedReport,
    updateReportRequest,
    getPivotTableDataRequest,
    changeDefaultReportRequest,
  }),
  withState('pivotType', 'setPivotType', 'complex'),
  withHandlers({
    handleSetPivotType: ({ setPivotType }: Object) => (value: string) => setPivotType(value),
    handleSelectReport: ({ reportList, setUsedReport, getPivotTableDataRequest }: Object) => (reportGuid: string) => {
      const selectedReport = R.find(R.propEq(reportGuid, GC.FIELD_GUID), reportList);
      setUsedReport(selectedReport);
      getPivotTableDataRequest();
    },
  }),
  withAsyncPinReport,
  pure,
);

export default enhance(PivotTableWrapper);
