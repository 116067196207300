import React from 'react';
// helpers/constants
import * as G from '../../../../helpers';
// icons
import * as I from '../../../../svgs';
// ui
import { RelativeBox, AbsoluteBox } from '../../../../ui';
// feature navbar-menu
import NavbarButton from './navbar-button';
//////////////////////////////////////////////////

export default ({ count, styling, iconsColor, handleOpenLoadBoardsSideBar }: Object) => (
  <RelativeBox width='100%'>
    <NavbarButton
      icon={I.loadSearch}
      iconsColor={iconsColor}
      onClick={handleOpenLoadBoardsSideBar}
      text={G.getWindowLocale('actions:load-boards', 'Load Boards')}
    />
    <AbsoluteBox
      p='2px'
      top='-4px'
      left='32px'
      zIndex={10}
      height='18px'
      fontSize='9px'
      minWidth='18px'
      border='1px solid'
      color={iconsColor}
      borderRadius='50px'
      width='max-content'
      pointerEvents='none'
      justifyContent='center'
      borderColor={iconsColor}
      bg={styling.backgroundColor}
    >
      {count}
    </AbsoluteBox>
  </RelativeBox>
);

