import React from 'react';
import * as R from 'ramda';
import Plot from 'react-plotly.js';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import TableRenderers from 'react-pivottable/TableRenderers';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import { pure, compose, withState, lifecycle, withHandlers } from 'react-recompose';
// components
import { EmptyList } from '../../components/list';
import DimmerComponent from '../../components/loader/dimmer';
import { FormButtons2 } from '../../components/form-buttons-2';
import { makeSelectLoader } from '../../components/loader/selectors';
import { enhancePivotReport } from '../../components/edit-report/hocs';
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { getColumnSettingsByReportType } from '../fleet-list/settings';
import { chargesColumnSettings } from '../payroll/settings/charges-table-settings';
import { loadColumnSettings, orderColumnSettings } from '../dispatch-report/settings/column-settings';
import { columnSettings as payrollColumnSettings } from '../payroll/settings/payroll-list-table-settings';
import { columnSettings as routeByLoadColumnSettings } from '../dispatch-report/route/route-by-load/settings';
import { getColumnSettings as getCustomerInvoiceColumnSettings } from '../invoice/customer/settings/column-settings';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { apiDomain } from '../../helpers/env';
// ui
import { Box, Flex } from '../../ui';
// feature pivot-table
import { makeSelectUsedReport, makeSelectPivotTableData } from './selectors';
import {
  setUsedReport,
  updateReportRequest,
  createReportRequest,
  getPivotTableDataRequest,
} from './actions';
//////////////////////////////////////////////////

const licenseKeysMap = {
  localDevelopment: 'Z7SC-XJI15T-1X1X06-2H3U17-4I6I39-303H2I-4D1M4U-0G4F48-6A6D1Q-101Z1I-295Z48-4K591G-08',
  development: 'Z7SC-XJI15T-1X1X06-2H3U17-4I6I39-303H2I-4D1M4U-0G4F48-6A6D1Q-101Z1I-295Z48-4K591G-08',
  staging: 'Z7DT-XHG63A-1Q2F4Y-405B4G-224L5E-406M1C-1O3R6A-205A4M-360830-515N3S',
  production: 'Z7FK-XHG13L-155F5G-5J5D2Q-6Y4Q18-5Y3S3Q-1D0C12-2D4B4K-6J6H4D-0C5F',
};

const getColumnSettings = ({ type }: Object) => R.pathOr({}, [type], {
  [GC.PIVOT_TEL_REPORT]: loadColumnSettings,
  [GC.PIVOT_CLO_REPORT]: orderColumnSettings,
  [GC.PIVOT_DRIVER_PAYROLL_REPORT]: payrollColumnSettings,
  [GC.PIVOT_VENDOR_PAYROLL_REPORT]: payrollColumnSettings,
  [GC.PIVOT_PAYROLL_CHARGES_REPORT]: chargesColumnSettings,
  [GC.PIVOT_ROUTE_BY_LOAD_REPORT]: routeByLoadColumnSettings,
  [GC.PIVOT_VENDOR_PAYROLL_CHARGES_REPORT]: chargesColumnSettings,
  [GC.PIVOT_CUSTOMER_INVOICE_REPORT]: getCustomerInvoiceColumnSettings(),
  [GC.PIVOT_FLEET_EQUIPMENT_SERVICE_REPORT]: getColumnSettingsByReportType(GC.FLEET_EQUIPMENT_SERVICE_REPORT),
});

// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);

const getFieldName = (item: Object, columnSettings: Object, reference: boolean = false) => {
  let name = '';

  if (R.includes('Assessorial', item)) {
    name = `CHRG: ${G.getAssessorialName(item)}`;
  } else if (reference) {
    name = G.getReferenceTitleName(item);
  } else {
    name = G.getWindowLocaleArr(R.pathOr(item, [item, GC.FIELD_NAME], columnSettings), item);
  }

  return R.trim(name);
};

const getPivotType = (name: string, columnSettings: Object) => {
  if (R.includes('Assessorial', name)) return 'number';

  return R.pathOr('string', [name, 'pivotType'], columnSettings);
};

const mapDataForRocks = (data: Array, report: Object) => {
  if (G.isNilOrEmpty(data)) return [];

  const { type, fields } = report;

  const columnSettings = getColumnSettings({ type });

  const head = R.compose(
    R.indexBy(R.prop('item')),
    R.map(({ name, reference }: Object) => ({
      item: name,
      type: getPivotType(name, columnSettings),
      caption: getFieldName(name, columnSettings, reference),
    })),
  )(fields);

  return R.prepend(head, data);
};

const getRocksReport = (props: Object) => {
  const { selectedReport } = props;

  const data = R.path(['pivotTableData'], props);
  const pivotSettings = R.path(['pivotSettings'], selectedReport);
  const dataToUse = mapDataForRocks(R.path(['results'], data), selectedReport);

  if (G.isNotNilAndNotEmpty(pivotSettings)) {
    return {
      ...JSON.parse(pivotSettings),
      dataSource: {
        data: dataToUse,
        dataSourceType: 'json',
      },
    };
  }

  return {
    dataSource: {
      data: dataToUse,
      dataSourceType: 'json',
    },
  };
};

const enhancePivotUI = compose(
  lifecycle({
    componentDidMount() {
      const data = R.path(['pivotTableData'], this.props);

      if (G.isNilOrEmpty(data)) return;

      setTimeout(
        () => {
          console.log('licenseKeysMap', apiDomain, licenseKeysMap, licenseKeysMap[apiDomain]);
          const pivot = new Flexmonster({ // eslint-disable-line
            height: '97%',
            toolbar: true,
            container: 'pivot-container',
            report: getRocksReport(this.props),
            licenseKey: licenseKeysMap[apiDomain],
            componentFolder: 'https://cdn.flexmonster.com/',
          });

          window.flexMonsterPivotInstance = pivot;
        },
        500,
      );
    },
  }),
  pure,
);

const FlexMonsterUI = enhancePivotUI(() => null);

const PivotUI = (props: Object) => {
  const { pivotState, selectedReport, pivotTableData, handleChangePivotState } = props;

  const itemListFormatted = [];
  const columnSettings = getColumnSettings(selectedReport);

  const forEachIndexed = R.addIndex(R.forEach);

  const getFields = (item: Object) => {
    const itemToUse = R.or(item, columnSettings);
    const keysToUse = [];
    const keys = R.keys(itemToUse);
    R.forEach((key: string) => {
      let locale;

      if (R.includes('Assessorial', key)) {
        locale = G.getAssessorialName(key);
      } else {
        const localeKey = R.pathOr(key, [key, 'name'], columnSettings);
        locale = R.trim(G.getWindowLocaleArr(localeKey, key));
      }

      keysToUse.push(locale);
    }, keys);

    return keysToUse;
  };

  forEachIndexed(
    (item: Object, index: number) => {
      const zeroIndex = G.isZero(index);

      if (zeroIndex) {
        const fields = getFields(item);
        itemListFormatted.push(fields);
      }

      const values = R.values(item);
      itemListFormatted.push(values);
    },
    R.pathOr([], ['results'], pivotTableData),
  );

  const data = itemListFormatted;

  return (
    <PivotTableUI
      data={data}
      {...pivotState}
      unusedOrientationCutoff={Infinity}
      hiddenAttributes={['guid', 'index', 'selected']}
      onChange={(s: Object) => handleChangePivotState(s)}
      renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
    />
  );
};

const enhance = compose(
  withState('pivotState', 'setPivotState', {}),
  enhancePivotReport,
  withHandlers({
    handleChangePivotState: (props: Object) => (s: Object) => {
      const { openLoader, closeLoader, setPivotState } = props;

      openLoader();
      setTimeout(() => setPivotState(s, closeLoader), 10);
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps: Object) {
      if (G.checkPropNotEqualInObjects('selectedReport', this.props, prevProps)) {
        const { selectedReport } = this.props;

        if (G.isNilOrEmpty(selectedReport)) return;

        const rows = R.pathOr([], ['rows'], selectedReport);
        const cols = R.pathOr([], ['columns'], selectedReport);
        const vals = R.pathOr([], ['aggregatedValues'], selectedReport);
        const rendererName = R.pathOr('Table', ['renderType'], selectedReport);
        const aggregatorName = R.pathOr('Count', ['aggregation'], selectedReport);
        const columnSettings = getColumnSettings(selectedReport);
        const pivotState = {
          vals,
          rendererName,
          aggregatorName,
          cols: cols.map((item: string) => (
            R.trim(G.getWindowLocaleArr(R.pathOr(item, [item, 'name'], columnSettings), item),
          ))),
          rows: rows.map((item: string) => (
            R.trim(G.getWindowLocaleArr(R.pathOr(item, [item, 'name'], columnSettings), item),
          ))),
        };
        this.props.setPivotState(pivotState);
      }
    },
  }),
  pure,
);

export const PivotTable = (props: Object) => {
  const {
    loader,
    pivotType,
    pivotTableData,
    selectedReport,
    handleClickSavePivot,
    handleClickSaveAsNewPivot,
  } = props;

  if (G.isNilOrEmpty(R.path(['results'], pivotTableData))) {
    return (
      <Box>
        {loader.showDimmer && <DimmerComponent tableCount={3} rectangleCount={4} />}
        <EmptyList>{G.getWindowLocale('titles:emptyList', 'Empty List')}</EmptyList>
      </Box>
    );
  }

  const saveDisabled = R.includes('draft', G.getGuidFromObject(selectedReport));

  return (
    <Box>
      {loader.showDimmer && <DimmerComponent tableCount={3} rectangleCount={4} />}
      <Flex
        m={10}
        overflow='scroll'
        flexDirection='column'
        alignItems='flex-start'
        height={G.ifElse(R.equals(pivotType, 'simple'), 'calc(100vh - 141px)', 'calc(100vh - 125px)')}
      >
        { R.equals(pivotType, 'simple') && <PivotUI {...props} /> }
        { R.equals(pivotType, 'complex') && <div id='pivot-container' /> }
        { R.equals(pivotType, 'complex') && <FlexMonsterUI {...props} /> }
      </Flex>
      <FormButtons2
        showCancel={false}
        showSaveAsNew={true}
        saveDisabled={saveDisabled}
        handleClickSave={handleClickSavePivot}
        handleClickSaveAsNew={handleClickSaveAsNewPivot}
      />
    </Box>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  loader: makeSelectLoader(state),
  selectedReport: makeSelectUsedReport(state),
  branchGuid: makeSelectCurrentBranchGuid(state),
  pivotTableData: makeSelectPivotTableData(state),
});

export default connect(mapStateToProps, {
  openLoader,
  closeLoader,
  setUsedReport,
  createReportRequest,
  updateReportRequest,
  getPivotTableDataRequest,
})(enhance(PivotTable));
