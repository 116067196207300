import * as R from 'ramda';
import React from 'react';
import { FieldArray } from 'formik';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature new-do
import { Fieldset } from './formik/fieldset';
import { referenceFields1, referenceFields2, getNewReferenceFields } from '../settings/fields-settings';
//////////////////////////////////////////////////

const Header = (props: Object) => (
  <Flex px='10px' height='30px' bg={G.getTheme('colors.bgGrey')}>
    <Box fontWeight={600} color={G.getTheme('colors.greyMatterhorn')}>
      {G.getWindowLocale('titles:references', 'References')}
    </Box>
    <Box
      mx='10px'
      height='15px'
      cursor='pointer'
      onClick={() => props.push(getNewReferenceFields())}
      title={G.getWindowLocale('titles:add-new-item', 'Add new item')}
    >
      {I.plusRound(G.getTheme('colors.dark.blue'))}
    </Box>
  </Flex>
);

const getAllowedValues = (props: Object) => {
  const { values, itemIndex, branchRefTypes } = props;

  const refTypes = R.pathOr([], [GC.REF_SCOPE_NAME_CLO], branchRefTypes);
  const itemRefTypeGuid = R.path([GC.FIELD_LOAD_REFERENCES, itemIndex, GC.FIELD_REFERENCE_TYPE_GUID], values);

  return R.path(['allowedValues'], R.find(R.propEq(itemRefTypeGuid, GC.FIELD_GUID), refTypes));
};

const getAllowedOptions = (props: Object) => {
  const allowedValues = getAllowedValues(props);

  if (G.isNotNilAndNotEmpty(allowedValues)) {
    return R.map(
      (item: string) => ({ label: item, value: item }),
      getAllowedValues(props),
    );
  }

  return [];
};

const getReferenceFields = (props: Object) => {
  const allowedValues = getAllowedValues(props);
  let fields;

  if (G.isNotNilAndNotEmpty(allowedValues)) {
    fields = referenceFields2;
  } else {
    fields = referenceFields1;
  }

  if (G.isNotNil(R.path(['item', GC.FIELD_GUID], props))) {
    fields = R.assocPath([0, 'disabled'], true, fields);
  }

  return fields;
};

const ItemRow = (props: Object) => (
  <Flex my='25px'>
    <Box>
      <Flex
        mx={10}
        display='flex'
        cursor='pointer'
        alignItems='center'
        onClick={() => props.remove(props.itemIndex)}
        title={G.getWindowLocale('titles:remove', 'Remove')}
      >
        {I.trash(G.getTheme('colors.dark.blue'))}
      </Flex>
    </Box>
    <Fieldset
      {...props}
      calcZIndex={true}
      fieldsetType='array'
      rowMapIndex={props.itemIndex}
      arrayLength={props.arrayLength}
      fields={getReferenceFields(props)}
      arrayName={GC.FIELD_LOAD_REFERENCES}
      allowedValues={getAllowedOptions(props)}
    />
  </Flex>
);

const ReferencesArray = (props: Object) => {
  const items = props.values.references;
  const arrayLength = R.length(items);

  return (
    <Box my={20}>
      <FieldArray
        name={GC.FIELD_LOAD_REFERENCES}
        render={(arrayHelpers: Object) => (
          <Box>
            <Header {...props} push={arrayHelpers.push} />
            <Box>
              {
                R.gt(arrayLength, 0)
                && items.map((item: string, i: number) => (
                  <ItemRow {...props} {...arrayHelpers} key={i} item={item} itemIndex={i} arrayLength={arrayLength} />
                ))
              }
            </Box>
          </Box>
        )}
      />
    </Box>
  );
};

export default ReferencesArray;
