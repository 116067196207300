import * as R from 'ramda';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
// helpers/constants
import * as GC from '../../constants';
//////////////////////////////////////////////////

const selectReferenceStore = (state: Object) => state[GC.UI_FIELD_REFERENCE];

const makeSelectReference = () => createSelector(
  selectReferenceStore,
  (reference: Object) => reference,
);

const makeSelectReferenceById = (_: Object, guid: string) => createSelector(
  selectReferenceStore,
  (reference: Object) => R.path([reference.activeList, 'list', guid], reference)
);

const makeSelectAllReferenceTypesList = () => createSelector(
  selectReferenceStore,
  ({ allReferenceTypesList }: Object) => allReferenceTypesList,
);

const makeSelectAllAvailableReferenceTypesList = () => createSelector(
  selectReferenceStore,
  ({ allAvailableReferenceTypesList }: Object) => allAvailableReferenceTypesList,
);

const makeSelectAllAvailableCloReferenceTypes = () => createSelector(
  selectReferenceStore,
  ({ availableReferenceTypesByScopeName }: Object) => R.pathOr(
    [],
    [GC.REF_SCOPE_NAME_CLO],
    availableReferenceTypesByScopeName,
  ),
);

const makeSelectAllAvailableCloAndCloInvoiceReferenceTypes = () => createSelector(
  selectReferenceStore,
  ({ availableReferenceTypesByScopeName }: Object) => R.concat(
    R.pathOr(
      [],
      [GC.REF_SCOPE_NAME_CUSTOMER_INVOICE],
      availableReferenceTypesByScopeName,
    ),
    R.compose(
      R.map(R.assoc(GC.FIELD_REFERENCE_FIELD_NAME, GC.REFERENCE_FIELD_NAME_CLO)),
      R.pathOr([], [GC.REF_SCOPE_NAME_CLO]),
    )(availableReferenceTypesByScopeName),
  ),
);

const makeSelectAllAvailableClosAndTelsReferenceTypes = () => createSelector(
  selectReferenceStore,
  ({ availableReferenceTypesByScopeName }: Object) => R.concat(
    R.compose(
      R.map(R.assoc(GC.FIELD_REFERENCE_FIELD_NAME, GC.REFERENCE_FIELD_NAME_CLOS)),
      R.pathOr([], [GC.REF_SCOPE_NAME_CLO]),
    )(availableReferenceTypesByScopeName),
    R.compose(
      R.map(R.assoc(GC.FIELD_REFERENCE_FIELD_NAME, GC.REFERENCE_FIELD_NAME_TELS)),
      R.pathOr([], [GC.REF_SCOPE_NAME_TEL]),
    )(availableReferenceTypesByScopeName),
  ),
);

const makeSelectAllAvailableTelReferenceTypes = () => createSelector(
  selectReferenceStore,
  ({ availableReferenceTypesByScopeName }: Object) => R.pathOr(
    [],
    [GC.REF_SCOPE_NAME_TEL],
    availableReferenceTypesByScopeName,
  ),
);

const makeSelectLoadEventRefTypes = () => createSelector(
  selectReferenceStore,
  ({ availableReferenceTypesByScopeName }: Object) => R.pathOr(
    [],
    [GC.REF_SCOPE_NAME_LOAD_EVENT],
    availableReferenceTypesByScopeName,
  ),
);

const makeSelectOwnedReferenceTypesList = () => createSelector(
  selectReferenceStore,
  ({ ownedReferenceTypesList }: Object) => ownedReferenceTypesList,
);

const makeSelectReferenceScopes = () => createSelector(
  selectReferenceStore,
  ({ scopes }: Object) => scopes,
);

const makeSelectAvailableReferenceTypesByScope = () => createSelector(
  selectReferenceStore,
  ({ availableReferenceTypesByScope }: Object) => availableReferenceTypesByScope,
);

const makeSelectScopeByName = createCachedSelector(
    selectReferenceStore,
    (state: Object, name: string) => name,
    (referenceState: Object, name: string) => (
      R.find(R.propEq(name, 'name'), referenceState.scopes)
    ),
)(
  (state: Object, name: string) => name,
);

const makeSelectPagination = () => createSelector(
  selectReferenceStore,
  (state: Object) => R.path([state.activeList, 'pagination'], state),
);

const makeSelectItemList = () => createSelector(
  selectReferenceStore,
  (state: Object) => R.path([state.activeList, 'list'], state),
);

const makeSelectActiveList = () => createSelector(
  selectReferenceStore,
  ({ activeList }: Object) => activeList,
);

const makeSelectItemListSorted = () => createSelector(
  selectReferenceStore,
  (state: Object) => R.sortBy(
    R.prop('index'),
    R.values(R.path([state.activeList, 'list'], state)),
  ),
);

const makeSelectListLoading = () => createSelector(
  selectReferenceStore,
  ({ loading }: Object) => loading,
);

const makeSelectFilterParams = () => createSelector(
  selectReferenceStore,
  ({ filterParams }: Object) => filterParams,
);

const makeSelectFilterProps = () => createSelector(
  selectReferenceStore,
  ({ filterProps }: Object) => filterProps,
);

const makeSelectTotalCount = () => createSelector(
  selectReferenceStore,
  (state: Object) => R.path([state.activeList, 'totalCount'], state),
);

const makeSelectAllAvailableReferenceTypesByScopeName = () => createSelector(
  selectReferenceStore,
  ({ availableReferenceTypesByScopeName }: Object) => availableReferenceTypesByScopeName,
);

export {
  makeSelectItemList,
  makeSelectReference,
  selectReferenceStore,
  makeSelectActiveList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectScopeByName,
  makeSelectListLoading,
  makeSelectFilterProps,
  makeSelectFilterParams,
  makeSelectReferenceById,
  makeSelectItemListSorted,
  makeSelectReferenceScopes,
  makeSelectLoadEventRefTypes,
  makeSelectAllReferenceTypesList,
  makeSelectOwnedReferenceTypesList,
  makeSelectAllAvailableCloReferenceTypes,
  makeSelectAllAvailableTelReferenceTypes,
  makeSelectAvailableReferenceTypesByScope,
  makeSelectAllAvailableReferenceTypesList,
  makeSelectAllAvailableClosAndTelsReferenceTypes,
  makeSelectAllAvailableReferenceTypesByScopeName,
  makeSelectAllAvailableCloAndCloInvoiceReferenceTypes,
};
