import * as R from 'ramda';
import { put, call, takeEvery } from 'redux-saga/effects';
// components
import { closeModal } from '../../components/modal/actions';
import { closeLoader, openLoader } from '../../components/loader/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature rate
import * as A from './actions';
/////////////////////////////////////////////////

function* handleCreateTelRateSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const fleetAssignment = R.pick(GC.GROUPED_FIELDS.FLEET_ASSIGNMENT_PICK_ARR, payload);
    const omittedPayload = R.omit(GC.GROUPED_FIELDS.FLEET_ASSIGNMENT_PICK_ARR, payload);
    const options = {
      data: {
        ...omittedPayload,
        fleetAssignment,
      },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.telFleetRate, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.createTelRateActionSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateTelRateSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateTelRateSaga exception');
  }
}

function* handleСreateCarrierTelRateSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'post', endpointsMap.telCarrierRate, { data: payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.createTelRateActionSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleСreateCarrierTelRateSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleСreateCarrierTelRateSaga exception');
  }
}

function* handleUpdateTetRateSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const fleetAssignmentFields = R.pick(GC.GROUPED_FIELDS.FLEET_ASSIGNMENT_PICK_ARR, payload);
    const fleetAssignment = R.pathOr({}, ['fleetAssignment'], payload);
    const fullFleetAssignment = R.omit('trailers', R.mergeRight(fleetAssignment, fleetAssignmentFields));
    const omittedPayload = R.omit([
      GC.FIELD_FLEET_ASSIGNMENT,
      ...GC.GROUPED_FIELDS.FLEET_ASSIGNMENT_PICK_ARR,
    ], payload);
    const options = {
      data: {
        ...omittedPayload,
        fleetAssignment: fullFleetAssignment,
      },
    };
    const res = yield call(sendRequest, 'put', endpointsMap.telFleetRate, options);
    if (G.isResponseSuccess(res.status)) {
      yield put(A.updateTelRateActionSuccess(res.data));
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateTetRateSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateTetRateSaga exception');
  }
}

function* handleUpdateCarrierTelRateSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    let endpoint = endpointsMap.telCarrierRate;
    if (G.isCurrentUserTypeCarrier()) endpoint = endpointsMap.telCarrierRateForCarrier;
    const res = yield call(sendRequest, 'put', endpoint, { data: payload });
    if (G.isResponseSuccess(res.status)) {
      yield put(A.updateTelRateActionSuccess(res.data));
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCarrierTelRateSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateCarrierTelRateSaga exception');
  }
}

function* rateWatcherSaga() {
  yield takeEvery(A.createTelRateActionRequest, handleCreateTelRateSaga);
  yield takeEvery(A.updateTelRateActionRequest, handleUpdateTetRateSaga);
  yield takeEvery(A.createTelCarrierRateActionRequest, handleСreateCarrierTelRateSaga);
  yield takeEvery(A.updateTelCarrierRateActionRequest, handleUpdateCarrierTelRateSaga);
}

export default rateWatcherSaga;
