import * as R from 'ramda';
import * as P from 'plow-js';
import React, { Component } from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature payroll
import { tollColumnSettings, fuelCardsColumnSettings } from '../settings/charges-reports-settings';
//////////////////////////////////////////////////

export class PayrollCharges extends Component {
  constructor(props: Object) {
    super(props);
    this.state = {
      expenses: [],
      dailyHours: [],
      advancePayments: [],
      payrollInvoices: [],
      vendorFuelCards: [],
      vendorTollCharges: [],
    };
    this.getExpenses = this.getExpenses.bind(this);
    this.getDailyHours = this.getDailyHours.bind(this);
    this.addChargesToList = this.addChargesToList.bind(this);
    this.getAdvancePayments = this.getAdvancePayments.bind(this);
    this.getPayrollInvoices = this.getPayrollInvoices.bind(this);
    this.getVendorFuelCards = this.getVendorFuelCards.bind(this);
    this.getVendorTollCharges = this.getVendorTollCharges.bind(this);
    this.selectChargeListItem = this.selectChargeListItem.bind(this);
    this.toggleVendorFuelCardDetails = this.toggleVendorFuelCardDetails.bind(this);
    this.removeOrRestoreChargeListItem = this.removeOrRestoreChargeListItem.bind(this);
  }

  componentDidMount() {
    this.getExpenses(this.props);
    this.getDailyHours(this.props);
    this.getAdvancePayments(this.props);
    this.getPayrollInvoices(this.props);
    this.getVendorFuelCards(this.props);
    this.getVendorTollCharges(this.props);
  }

  async getVendorFuelCards(props: Object) {
    const {
      dates,
      branchGuid,
      driverGuid,
      payrollGuid,
    } = props;

    if (G.isNilOrEmpty(payrollGuid)) return;

    const options = {
      data: {
        ...dates,
        driverGuid,
        pageable: false,
        [GC.FIELD_CURRENT_BRANCH]: branchGuid,
        fields: R.concat(
          G.getReportFieldsFromColumnSettings(fuelCardsColumnSettings),
          [
            { sequence: 12, name: 'lines', collection: true },
          ],
        ),
      },
    };

    const res = await sendRequest('post', endpointsMap.fuelCardsForPayrollByVendor, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotEmpty(data)) {
        const fuelCards = R.map(
          (charge: Object) => ({
            ...charge,
            expanded: false,
            noActions: true,
            details: charge.lines,
          }),
          R.pathOr([], ['results'], data),
        );

        const newState = P.$set('vendorFuelCards', fuelCards, this.state);

        this.setState(newState);
      }
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncPayrollCharges -> getVendorFuelCards',
      );
    }
  }

  async getVendorTollCharges(props: Object) {
    const {
      dates,
      branchGuid,
      driverGuid,
      payrollGuid,
    } = props;

    if (G.isNilOrEmpty(payrollGuid)) return;

    const options = {
      data: {
        ...dates,
        driverGuid,
        pageable: false,
        [GC.FIELD_CURRENT_BRANCH]: branchGuid,
        fields: G.getReportFieldsFromColumnSettings(tollColumnSettings),
      },
    };

    const res = await sendRequest('post', endpointsMap.tollChargesForPayrollByVendor, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotEmpty(data)) {
        const newState = P.$set('vendorTollCharges', R.pathOr([], ['results'], data), this.state);

        this.setState(newState);
      }
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncPayrollCharges -> getVendorTollCharges',
      );
    }
  }

  async getExpenses(props: Object) {
    const { payrollGuid } = props;

    if (G.isNilOrEmpty(payrollGuid)) return;

    const params = { [GC.FIELD_PAYROLL_GUID]: payrollGuid };

    const res = await sendRequest('get', endpointsMap.driverExpenseList, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotEmpty(data)) {
        const expenses = R.map(
          (charge: Object) => R.mergeRight(charge, {
            removed: false,
            selected: R.not(R.prop(GC.FIELD_EXCLUDED, charge)),
          }),
          data,
        );

        const newState = P.$set('expenses', expenses, this.state);

        this.setState(newState);
      }
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncPayrollCharges -> getExpenses',
      );
    }
  }

  async getDailyHours(props: Object) {
    const { payrollGuid } = props;

    if (G.isNilOrEmpty(payrollGuid)) return;

    const params = { [GC.FIELD_PAYROLL_GUID]: payrollGuid };

    const res = await sendRequest('get', endpointsMap.driverDailyLogList, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotEmpty(data)) {
        const newState = P.$set('dailyHours', data, this.state);

        this.setState(newState);
      }
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncPayrollCharges -> getDailyHours',
      );
    }
  }

  async getAdvancePayments(props: Object) {
    const { payrollGuid } = props;

    if (G.isNilOrEmpty(payrollGuid)) return;

    const params = { [GC.FIELD_PAYROLL_GUID]: payrollGuid };

    const res = await sendRequest('get', endpointsMap.advancePaymentList, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotEmpty(data)) {
        const advancePayments = R.map(
          (charge: Object) => R.mergeRight(charge, {
            removed: false,
            selected: R.not(R.prop(GC.FIELD_EXCLUDED, charge)),
          }),
          data,
        );

        const newState = P.$set('advancePayments', advancePayments, this.state);

        this.setState(newState);
      }
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncPayrollCharges -> getAdvancePayments',
      );
    }
  }


  async getPayrollInvoices(props: Object) {
    const { payrollGuid } = props;

    if (G.isNilOrEmpty(payrollGuid)) return;

    const params = { [GC.FIELD_PAYROLL_GUID]: payrollGuid };

    const res = await sendRequest('get', endpointsMap.driverPayrollInvoices, { params });

    const { data, status } = res;

    if (R.and(G.isResponseSuccess(status), G.isArray(data))) {
      const newState = P.$set('payrollInvoices', data, this.state);

      this.setState(newState);
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncPayrollCharges -> getPayrollInvoices',
      );
    }
  }

  toggleVendorFuelCardDetails({ guid }: Object) {
    const { vendorFuelCards } = this.state;

    const newList = R.map((fuelCard: Object) => {
      if (R.equals(G.getGuidFromObject(fuelCard), guid)) {
        return R.assoc('expanded', R.not(fuelCard.expanded), fuelCard);
      }

      return fuelCard;
    }, vendorFuelCards);

    const newState = P.$set('vendorFuelCards', newList, this.state);

    this.setState(newState);
  }

  selectChargeListItem(guid: string, listName: string) {
    const newList = G.selectItem(R.prop(listName, this.state), guid);
    const newState = P.$set(listName, newList, this.state);

    this.setState(newState);
  }

  removeOrRestoreChargeListItem(guid: string, listName: string) {
    const newList = R.map((item: Object) => {
      if (R.propEq(guid, GC.FIELD_GUID, item)) {
        return R.assoc('removed', R.not(item.removed), item);
      }

      return item;
    }, this.state[listName]);

    const newState = P.$set(listName, newList, this.state);

    this.setState(newState);
  }

  addChargesToList(charges: Array, listName: string) {
    const newList = R.compose(
      R.concat(this.state[listName]),
      R.map(R.mergeRight({ selected: true, expanded: false })),
    )(charges);

    const newState = P.$set(listName, newList, this.state);

    this.setState(newState);
  }

  render() {
    return (
      <div>
        {this.props.render(
          this.state,
          this.getExpenses,
          this.getDailyHours,
          this.addChargesToList,
          this.getAdvancePayments,
          this.getPayrollInvoices,
          this.getVendorFuelCards,
          this.getVendorTollCharges,
          this.selectChargeListItem,
          this.toggleVendorFuelCardDetails,
          this.removeOrRestoreChargeListItem,
        )}
      </div>
    );
  }
}

export const withAsyncPayrollCharges = (Component: any) => {
  return class extends React.Component {
    render() {
      return (
        <PayrollCharges
          branchGuid={this.props.branchGuid}
          driverGuid={this.props.driverGuid}
          payrollGuid={this.props.payrollGuid}
          dates={this.props.initialFilterDates}
          render={(
            parentState: Object,
            getExpenses: Function,
            getDailyHours: Function,
            addChargesToList: Function,
            getAdvancePayments: Function,
            getPayrollInvoices: Function,
            getVendorFuelCards: Function,
            getVendorTollCharges: Function,
            selectChargeListItem: Function,
            toggleVendorFuelCardDetails: Function,
            removeOrRestoreChargeListItem: Function,
          ) => (
            <Component
              {...this.props}
              getExpenses={getExpenses}
              getDailyHours={getDailyHours}
              expenses={parentState.expenses}
              fuelCards={parentState.fuelCards}
              dailyHours={parentState.dailyHours}
              addChargesToList={addChargesToList}
              getAdvancePayments={getAdvancePayments}
              getPayrollInvoices={getPayrollInvoices}
              getVendorFuelCards={getVendorFuelCards}
              selectChargeListItem={selectChargeListItem}
              advancePayments={parentState.advancePayments}
              payrollInvoices={parentState.payrollInvoices}
              vendorFuelCards={parentState.vendorFuelCards}
              vendorTollCharges={parentState.vendorTollCharges}
              toggleVendorFuelCardDetails={toggleVendorFuelCardDetails}
              removeOrRestoreChargeListItem={removeOrRestoreChargeListItem}
            />
          )}
        />
      );
    }
  };
};
