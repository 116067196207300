import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, branch, compose, withProps, withHandlers, renderNothing } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, ReactSelect } from '../../../ui';
// feature new-import
import * as C from '../constants';
import {
  getImportTemplateRequest,
  getImportTemplateSuccess } from '../actions';
import {
  importTypes,
  tollChargeSourceOptions,
  fuelCardTransactionSourceOptions } from '../settings';
//////////////////////////////////////////////////

export const SelectComponent = (props: Object) => (
  <Box width={200} ml={props.ml}>
    <ReactSelect
      value={props.value}
      options={props.options}
      isMulti={props.isMulti}
      onChange={props.handleChange}
      isClearable={props.isClearable}
      placeholder={props.placeholder}
    />
  </Box>
);

const importTypeEnhance = compose(
  connect(null, { getImportTemplateRequest, getImportTemplateSuccess }),
  withHandlers({
    handleChange: (props: Object) => (field: Object) => {
      const { setSourceType, getImportTemplateRequest, getImportTemplateSuccess } = props;

      const type = R.pathOr(null, [GC.FIELD_VALUE], field);
      const sourceTypeMap = {
        [C.IMPORT_TYPE_TOLL_CHARGE]: GC.FLEET_INTEGRATION_TYPE_I_PASS,
        [C.IMPORT_TYPE_FUEL_CARD_TRANSACTION]: GC.FUEL_CARDS_TYPE_EFS,
      };
      const sourceType = R.pathOr(null, [type], sourceTypeMap);
      setSourceType(sourceType);

      if (G.isNilOrEmpty(type)) {
        return getImportTemplateSuccess({ type, data: null });
      }

      return getImportTemplateRequest(type);
    },
  }),
);

export const SelectImportType = importTypeEnhance((props: Object) =>
  <SelectComponent {...props} options={importTypes} />,
);

const importSourceTypeEnhance = compose(
  withProps(({ importType }: Object) => {
    const sourceTypeMap = {
      [C.IMPORT_TYPE_TOLL_CHARGE]: tollChargeSourceOptions,
      [C.IMPORT_TYPE_FUEL_CARD_TRANSACTION]: fuelCardTransactionSourceOptions,
    };

    const options = R.pathOr([], [importType], sourceTypeMap);

    return { ml: 10, options };
  }),
  withHandlers({
    handleChange: ({ setSourceType }: Object) => (field: Object) =>
      setSourceType(R.pathOr(null, [GC.FIELD_VALUE], field)),
  }),
  branch(
    ({ importType }: Object) => G.notContain(
      importType,
      [C.IMPORT_TYPE_TOLL_CHARGE, C.IMPORT_TYPE_FUEL_CARD_TRANSACTION],
    ),
    renderNothing,
  ),
  pure,
);

export const SelectSourceType = importSourceTypeEnhance(SelectComponent);
