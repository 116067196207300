import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// component
import { openModal, closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// forms
import { LocationTemplateSearch } from '../../forms/forms/template-search/components/location-template-search';
// hocs
import { withConnectModalAndLoaderActions, withAsyncSearchTemplateAutocomplete } from '../../hocs';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex } from '../../ui';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature new-do
import * as H from './helpers';
import { TAB_NAME_BILL_TO } from './constants';
import { emptyStopFields } from './settings/fields-settings';
import OrderByVinModal from './components/order-by-vin-modal';
import TemplateListModal from './components/template-list-modal';
import {
  isDropFormValid,
  isPickupFormValid,
  isQuoteDropFormValid,
  isQuotePickupFormValid,
} from './validation';
import {
  getDataFromStops,
  getLoadStopsGeodata,
  isPageLoadStopsEdit,
  mapStopsWithDistances,
} from './helpers';
//////////////////////////////////////////////////

class AsyncLoadDistances extends Component {
  constructor(props: Object) {
    super(props);
    this.state = { asyncLoadDistance: null };
    this.getLoadDistances = this.getLoadDistances.bind(this);
  }

  componentDidMount() {
    this.getLoadDistances(this.props);
  }

  async getLoadDistances({ getAsyncLoadDistancesData }: Object) {
    try {
      const { stops, loadType, branchGuid, stopPointsStrings, asyncLoadDistancesCallback } = getAsyncLoadDistancesData;

      const { sortedStops } = getDataFromStops(stops);

      const stopPoints = getLoadStopsGeodata(sortedStops);

      const newStopPointsStrings = H.getStopPointsLatLonStringsArray(stopPoints);

      const same = R.equals(stopPointsStrings, newStopPointsStrings);

      if (G.isAllTrue(
        G.isNotNilAndNotEmpty(stopPoints),
        G.isArrayOfStrings(newStopPointsStrings),
        R.not(same),
      )) {
        const options = {
          data: {
            stopPoints,
            [GC.FIELD_BRANCH_GUID]: branchGuid,
          },
        };

        const res = await sendRequest('post', endpointsMap.distanceCalculation, options);

        const { data, status } = res;

        if (R.isNil(R.prop(['stopResults'], data))) return;

        if (G.isResponseSuccess(status)) {
          const { storedTotalDistance } = data;

          const toUom = G.getMileOrKmUomByUomSystem();

          const distanceToUse = G.milesKmsFromToAccordingSystemWithNullable(
            toUom,
            GC.UOM_KILOMETER,
            G.mathRoundNumber(storedTotalDistance),
            2,
          );

          const distance = {
            [GC.FIELD_TOTAL_TRIP_DISTANCE_UOM]: toUom,
            [GC.FIELD_TOTAL_TRIP_DISTANCE]: distanceToUse,
          };

          const mappedStops = mapStopsWithDistances(loadType, sortedStops, data);

          const newState = R.assoc('asyncLoadDistance', distance, this.state);

          this.setState(newState);

          if (G.isFunction(asyncLoadDistancesCallback)) {
            asyncLoadDistancesCallback({ distance, stopPoints, mappedStops });
          }
        }
      }
    } catch (error) {
      G.handleException(error, 'getLoadDistances');
    }
  }

  render() {
    return <div>{this.props.render(this.state, this.getConfigsRequest)}</div>;
  }
}

export const withAsyncLoadDistance = (Component: any) => class extends React.Component {
  render() {
    return (
      <AsyncLoadDistances
        getAsyncLoadDistancesData={this.props.getAsyncLoadDistancesData}
        render={(parentState: Object) =>
          <Component
            {...this.props}
            asyncTotalDistance={parentState.asyncLoadDistance}
          />
        }
      />
    );
  }
};

export const withSearchLocationSetValues = compose(
  withHandlers({
    handleSearchLocation: (props: Object) => () => {
      const {
        values,
        loadGuid,
        openModal,
        closeModal,
        setValues,
        branchGuid,
        divisionGuid,
        leftActiveTad,
      } = props;

      const searchTemplateRequest = (location: Object) => {
        let mappedLocation = G.mapSearchedLocationWithContactsArr(location);

        if (R.equals(leftActiveTad, GC.FIELD_BILL_TO)) {
          mappedLocation = G.mapSearchedLocation2(location, { useMultiEmails: true });
        }

        setValues(R.mergeRight(values, mappedLocation));
        closeModal();
      };

      const modalContent = (
        <LocationTemplateSearch
          {...G.getLocationTemplateSearchRequestProps({ loadGuid, branchGuid, divisionGuid, loadType: GC.LOAD_TYPE_CLO })}
          closeModal={closeModal}
          searchTemplateRequest={searchTemplateRequest}
        />
      );

      const modal = G.createCommonModalOptions(modalContent);

      openModal(modal);
    },
  }),
);

export const enhanceStopForm = compose(
  withAsyncSearchTemplateAutocomplete({
    useCurrentBranch: true,
    shouldGetLocationTemplateSearchRequestProps: true,
  }),
  withSearchLocationSetValues,
  withHandlers({
    handleCleanForm: (props: Object) => () => {
      const { fromPage, setValues, initialValues } = props;
      const valuesToSet = G.ifElse(
        R.includes(fromPage, [
          GC.PAGE_DISPATCH_BOARD_NEW,
          GC.PAGE_DISPATCH_DETAILS_NEW_LOAD,
          GC.PAGE_DISPATCH_DETAILS_NEW_ORDER,
        ]),
        R.mergeRight(initialValues, emptyStopFields),
        emptyStopFields,
      );
      setValues(valuesToSet);
    },
    handleLeaveForm: (props: Object) => () => {
      const { stops, values, stopOrder, pageType, eventType, setFormDataToStop } = props;
      if (R.not(isPageLoadStopsEdit(pageType))) return;
      const isPickup = G.isEventTypePickup(eventType);
      const validationFunc = G.ifElse(
        isPickup,
        isPickupFormValid,
        isDropFormValid,
      );
      H.checkSingleToSingleItemsAndDrop({ stops, values, isPickup, setFormDataToStop });
      const isValid = validationFunc(values);
      setFormDataToStop({ isValid, stopOrder, formData: values });
    },
    onClickTabCallback: (props: Object) => (activeName: Object, shouldSave: boolean = false) => {
      const { stops, values, stopOrder, eventType, setActiveLeftTab, setFormDataToStop } = props;

      const isPickup = G.isEventTypePickup(eventType);

      const validationFunc = G.ifElse(
        isPickup,
        isPickupFormValid,
        isDropFormValid,
      );

      H.checkSingleToSingleItemsAndDrop({ stops, values, isPickup, setFormDataToStop });

      const isValid = validationFunc(values, props);

      setFormDataToStop({ isValid, stopOrder, shouldSave, formData: values });
      setActiveLeftTab(activeName);
    },
    handleClickPrev: (props: Object) => () => {
      const { stops, values, eventType, stopOrder, setActiveLeftTab, setFormDataToStop } = props;
      const isPickup = G.isEventTypePickup(eventType);
      const validationFunc = G.ifElse(
        isPickup,
        isPickupFormValid,
        isDropFormValid,
      );
      H.checkSingleToSingleItemsAndDrop({ stops, values, isPickup, setFormDataToStop });
      const isValid = validationFunc(values);
      setFormDataToStop({ isValid, stopOrder, formData: values });
      if (R.equals(1, stopOrder)) {
        return setActiveLeftTab(TAB_NAME_BILL_TO);
      }
      setActiveLeftTab(R.dec(stopOrder));
    },
    handleChangeApptTime: ({ values, initialValues, setFieldValue }: Object) => (data: Object) => {
      if (R.equals(values, initialValues)) return;

      const { value, fieldName } = data;

      const appointmentDate = R.path([GC.FIELD_LOAD_APPOINTMENT_DATE], values);

      if (G.isAllTrue(
        G.isNotNil(appointmentDate),
        R.equals(fieldName, GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME),
      )) {
        const eventEarlyDate = `${appointmentDate} ${value}`;
        const notEqualValues = R.not(R.and(
          R.propEq(value, GC.FIELD_LOAD_EVENT_EARLY_TIME, values),
          R.propEq(eventEarlyDate, GC.FIELD_LOAD_EVENT_EARLY_DATE, values),
        ));

        if (R.and(notEqualValues, G.isValidMoment(eventEarlyDate))) {
          setFieldValue(GC.FIELD_LOAD_EVENT_EARLY_DATE, eventEarlyDate);
          setFieldValue(GC.FIELD_LOAD_EVENT_EARLY_TIME, value);
        }
      }

      if (G.isAllTrue(
        G.isNotNil(appointmentDate),
        R.equals(fieldName, GC.FIELD_LOAD_APPOINTMENT_LATE_TIME),
      )) {
        const eventLateDate = `${appointmentDate} ${value}`;
        const notEqualValues = R.not(R.and(
          R.propEq(value, GC.FIELD_LOAD_EVENT_LATE_TIME, values),
          R.propEq(eventLateDate, GC.FIELD_LOAD_EVENT_LATE_DATE, values),
        ));

        if (R.and(notEqualValues, G.isValidMoment(eventLateDate))) {
          setFieldValue(GC.FIELD_LOAD_EVENT_LATE_DATE, eventLateDate);
          setFieldValue(GC.FIELD_LOAD_EVENT_LATE_TIME, value);
        }
      }
    },
  }),
  pure,
);

export const enhanceQuoteStopForm = compose(
  withAsyncSearchTemplateAutocomplete({
    useCurrentBranch: true,
    shouldGetLocationTemplateSearchRequestProps: true,
  }),
  withSearchLocationSetValues,
  withHandlers({
    handleCleanForm: (props: Object) => () => {
      const { setValues } = props;

      setValues(emptyStopFields);
    },
    handleLeaveForm: (props: Object) => () => {
      const { stops, values, stopOrder, eventType, setFormDataToStop } = props;

      const isPickup = G.isEventTypePickup(eventType);
      const validationFunc = G.ifElse(
        isPickup,
        isQuotePickupFormValid,
        isQuoteDropFormValid,
      );
      H.checkSingleToSingleItemsAndDrop({ stops, values, isPickup, setFormDataToStop });
      const isValid = validationFunc(values, props);
      setFormDataToStop({ isValid, stopOrder, formData: values });
    },
    onClickTabCallback: (props: Object) => (activeName: Object, shouldSave: boolean = false) => {
      const { stops, values, stopOrder, eventType, setActiveLeftTab, setFormDataToStop } = props;

      const isPickup = G.isEventTypePickup(eventType);
      const validationFunc = G.ifElse(
        isPickup,
        isQuotePickupFormValid,
        isQuoteDropFormValid,
      );
      H.checkSingleToSingleItemsAndDrop({ stops, values, isPickup, setFormDataToStop });
      const isValid = validationFunc(values, props);
      setFormDataToStop({ isValid, stopOrder, shouldSave, formData: values });
      setActiveLeftTab(activeName);
    },
    handleClickPrev: (props: Object) => () => {
      const { stops, values, eventType, stopOrder, setActiveLeftTab, setFormDataToStop } = props;

      const isPickup = G.isEventTypePickup(eventType);
      const validationFunc = G.ifElse(
        isPickup,
        isQuotePickupFormValid,
        isQuoteDropFormValid,
      );
      H.checkSingleToSingleItemsAndDrop({ stops, values, isPickup, setFormDataToStop });
      const isValid = validationFunc(values, props);
      setFormDataToStop({ isValid, stopOrder, formData: values });

      if (R.equals(1, stopOrder)) {
        return setActiveLeftTab(TAB_NAME_BILL_TO);
      }

      setActiveLeftTab(R.dec(stopOrder));
    },
  }),
  pure,
);

export const withCopyFromTemplate = compose(
  connect(
    null,
    {
      openModal,
      closeModal,
      openLoader,
      closeLoader,
    },
  ),
  withHandlers({
    handleOpenCopyFromTemplate: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        openLoader,
        branchGuid,
        branchList,
        closeLoader,
        copyTemplateDataToStore,
      } = props;

      if (R.isEmpty(branchGuid)) {
        return G.showToastrMessageFromLocale('info', 'messages:choose-customer');
      }

      const options = {
        params: {
          [GC.FIELD_BRANCH_GUID]: branchGuid,
        },
      };
      const title = G.getWindowLocale('titles:copy-from-template', 'Copy From Template');
      const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

      const modal = {
        p: '0px',
        TitleComponent: () => (
          <Flex p={15} justifyContent='space-between'>
            <Box fontWeight={600} color={greyMatterhornColor}>{title}</Box>
            <Box cursor='pointer' onClick={closeModal}>{I.closeIcon(greyMatterhornColor)}</Box>
          </Flex>
        ),
        component: (
          <TemplateListModal
            showDimmer={false}
            asyncOptions={options}
            branchList={branchList}
            closeModal={closeModal}
            openLoader={openLoader}
            closeLoader={closeLoader}
            copyTemplateAction={copyTemplateDataToStore}
            asyncEndpoint={endpointsMap.cloTemplateListAvailable}
          />
        ),
        options: {
          width: 600,
          height: 'auto',
          movable: false,
          minWidth: 'fit-content',
        },
      };

      openModal(modal);
    },
  }),
);

export const withOrdersByVin = compose(
  withConnectModalAndLoaderActions,
  withHandlers({
    handleOpenOrdersByVin: (props: Object) => (item: Object) => {
      const {
        openModal,
        closeModal,
        openLoader,
        branchGuid,
        branchList,
        closeLoader,
      } = props;

      const { vin } = item;

      if (G.isNilOrEmpty(vin)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:fill-vin', 'Please, fill the vin field'),
        );
      }

      const options = {
        params: {
          vin,
          [GC.FIELD_BRANCH_GUID]: branchGuid,
        },
      };
      const title = G.getWindowLocale('titles:duplicate-vin-number-lookup', 'Duplicate VIN Number Lookup');
      const whiteColor = G.getTheme('colors.white');

      const modal = {
        p: '0px',
        TitleComponent: () => (
          <Flex p={15} justifyContent='space-between' bg={G.getTheme('colors.light.blue')}>
            <Box fontWeight={600} color={whiteColor}>{title}</Box>
            <Box cursor='pointer' onClick={closeModal}>{I.closeIcon(whiteColor)}</Box>
          </Flex>
        ),
        component: (
          <OrderByVinModal
            showDimmer={false}
            asyncOptions={options}
            branchList={branchList}
            closeModal={closeModal}
            openLoader={openLoader}
            closeLoader={closeLoader}
            asyncEndpoint={endpointsMap.cloListByVin}
          />
        ),
        options: {
          width: 400,
          height: 'auto',
          movable: false,
          minWidth: 'fit-content',
        },
      };

      openModal(modal);
    },
    handleGetOrdersByVin: (props: Object) => async (item: Object) => {
      const {
        openModal,
        closeModal,
        openLoader,
        branchGuid,
        branchList,
        closeLoader,
      } = props;

      const { vin } = item;

      if (G.isNilOrEmpty(vin)) return;

      const options = {
        params: {
          vin,
          [GC.FIELD_BRANCH_GUID]: branchGuid,
        },
      };

      const res = await sendRequest('get', endpointsMap.cloListByVin, options);
      const { data, status } = res;


      if (G.isResponseSuccess(status)) {
        if (G.isNotNilAndNotEmpty(data)) {
          const title = G.getWindowLocale('titles:duplicate-vin-number-lookup', 'Duplicate VIN Number Lookup');
          const whiteColor = G.getTheme('colors.white');

          const modal = {
            p: '0px',
            TitleComponent: () => (
              <Flex p={15} justifyContent='space-between' bg={G.getTheme('colors.light.blue')}>
                <Box fontWeight={600} color={whiteColor}>{title}</Box>
                <Box cursor='pointer' onClick={closeModal}>{I.closeIcon(whiteColor)}</Box>
              </Flex>
            ),
            component: (
              <OrderByVinModal
                showDimmer={false}
                asyncOptions={options}
                branchList={branchList}
                closeModal={closeModal}
                openLoader={openLoader}
                closeLoader={closeLoader}
                asyncEndpoint={endpointsMap.cloListByVin}
              />
            ),
            options: {
              width: 400,
              height: 'auto',
              movable: false,
              minWidth: 'fit-content',
            },
          };

          openModal(modal);
        }
      } else {
        G.handleException('error', 'handleGetOrdersByVin exception');
      }
    },
  }),
);
