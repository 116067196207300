import * as R from 'ramda';
import React from 'react';
// components
import { InfoPair } from '../../../components/info-pair';
// features
import Tabs from '../../new-do/components/tabs';
import AdditionalSummary from '../../new-do/components/additional-summary';
import { TAB_NAME_MAP, TAB_NAME_CLO_SUMMARY } from '../../new-do/constants';
import { QuoteStopsSummaryWithDnD } from '../../new-do/components/stops-summary-with-dnd';
import { MapLocations, TransportingCarrier, RightSectionEmptyInfo } from '../../new-do/components/right-section';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// feature new-do-quote
import { renderLeftRightContent, renderLeftRightEmptyInfo } from '../helpers';
//////////////////////////////////////////////////

const tabSettings = [
  {
    name: TAB_NAME_CLO_SUMMARY,
    text: G.getWindowLocale('titles:quote-summary', 'Quote Summary'),
  },
  {
    name: TAB_NAME_MAP,
    text: G.getWindowLocale('titles:map', 'Map'),
  },
];

const TripServiceDays = ({ text }: Object) => (
  <Flex mx={15}>
    <Box>
      {G.getWindowLocale('titles:transit-days', 'Transit Days')}:
    </Box>
    <Box fontWeight='bold' ml={10}>
      {text}
    </Box>
  </Flex>
);

const integrationCarrierStyles = {
  fontSize: 14,
  margin: '0 15px',
  textMaxWidth: 500,
};

const renderTabContent = (props: Object) => {
  const { rightActiveTad, tripServiceDays } = props;

  if (R.equals(rightActiveTad, TAB_NAME_CLO_SUMMARY)) {
    return (
      <Box>
        <QuoteStopsSummaryWithDnD {...props} />
        <InfoPair
          {...integrationCarrierStyles}
          label={G.getWindowLocale('titles:transporting-carrier', 'Transporting Carrier')}
          text={R.path(['integrationCarriers', GC.FIELD_TRANSPORTING_CARRIER_NAME], props)}
        />
        <InfoPair
          {...integrationCarrierStyles}
          label={G.getWindowLocale('titles:origin-carrier-name', 'Origin Carrier Name')}
          text={R.path(['integrationCarriers', GC.FIELD_ORIGIN_CARRIER_NAME], props)}
        />
        <InfoPair
          {...integrationCarrierStyles}
          text={R.path(['integrationCarriers', GC.FIELD_DESTINATION_CARRIER_NAME], props)}
          label={G.getWindowLocale('titles:destination-carrier-name', 'Destination Carrier Name')}
        />
        {
          G.isNotNilAndNotEmpty(tripServiceDays) &&
          <TripServiceDays text={tripServiceDays} />
        }
        <AdditionalSummary {...props} />
      </Box>
    );
  }

  if (R.equals(rightActiveTad, TAB_NAME_MAP)) {
    return <MapLocations {...props} />;
  }

  return null;
};

const RightSection = (props: Object) => (
  <Box
    mb='20px'
    width='662px'
    flexShrink={0}
    borderRadius='4px'
    bg={G.getTheme('colors.white')}
  >
    {
      renderLeftRightContent(props) &&
      <Tabs
        overflow='auto'
        options={tabSettings}
        activeFromParent={props.rightActiveTad}
        defaultActiveName={TAB_NAME_CLO_SUMMARY}
        onClickTabCallback={props.setActiveRightTab}
      />
    }
    {
      renderLeftRightEmptyInfo(props) && <RightSectionEmptyInfo />
    }
    {renderLeftRightContent(props) && renderTabContent(props)}
  </Box>
);

export default RightSection;
