import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// common
import { openModal, closeModal } from '../../../components/modal/actions';
// forms
import { FieldsetComponent } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncRequest } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// utilities
import endpointsMap from '../../../utilities/endpoints';
//////////////////////////////////////////////////

const itemIdField = {
  width: 300,
  fontSize: 12,
  labelPl: '0px',
  errorTop: '103%',
  isRequired: true,
  labelWidth: '100%',
  loc: 'titles:item-id',
  name: GC.FIELD_ITEM_ID,
  flexDirection: 'column',
  inputWrapMargin: '4px 0',
  errorPosition: 'absolute',
  fieldName: GC.FIELD_ITEM_ID,
};

const validationSchema = {
  [GC.FIELD_ITEM_ID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(1, G.getShouldBeFromToLocaleTxt(1, 40))
    .max(40, G.getShouldBeFromToLocaleTxt(1, 40)),
};

const formikEnhancer = compose(
  withFormik({
    mapPropsToValues: ({ initialValue }: Object) => ({ [GC.FIELD_ITEM_ID]: R.or(initialValue, null) }),
    validationSchema: () => Yup.object().shape(validationSchema),
    handleSubmit: (values: Object, { props }: Object) => {
      const { item, branchGuid, makeAsyncRequest } = props;
      const data = {
        ...R.omit([GC.FIELD_GUID, GC.FIELD_VERSION], item),
        [GC.FIELD_ITEM_ID]: R.prop(GC.FIELD_ITEM_ID, values),
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      };
      const options = { data: G.mapObjectEmptyStringFieldsToNull(data) };
      makeAsyncRequest(endpointsMap.item, options);
    },
  }),
  pure,
);

const enhance = compose(
  withAsyncRequest,
  withHandlers({
    handleOpenSaveItemTemplate: (props: Object) => (event: Event) => {
      G.stopPropagation(event);

      const component = (
        <TemplateIdPopperContent
          {...props}
          openModal={props.openModal}
          closeModal={props.closeModal}
        />
      );

      const modal = {
        p: '15px',
        component,
        options: {
          height: 'auto',
          overflow: 'auto',
          width: 'max-content',
          title: G.getWindowLocale('titles:save-item', 'Save Item'),
        },
      };

      props.openModal(modal);
    },
  }),

  pure,
);

const TemplateIdPopperContent = formikEnhancer((props: Object) => (
  <Box>
    <form onSubmit={props.handleSubmit}>
      <FieldsetComponent {...G.getFormikProps(props)} fields={R.of(Array, itemIdField)} />
      <FormFooter closeModal={props.closeModal} boxStyles={{ mt: '10px' }} />
    </form>
  </Box>
));

const SaveItemTmpl = (props: Object) => (
  <Flex
    display='flex'
    cursor='pointer'
    alignItems='center'
    onClick={(event: Object) => props.handleOpenSaveItemTemplate(event)}
    title={G.getWindowLocale('titles:save-as-template', 'Save As Template')}
  >
    {I.box(G.getTheme('colors.dark.blue'))}
  </Flex>
);

export default connect(null, { openModal, closeModal })(enhance(SaveItemTmpl));
