import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as GC from '../../constants';
//////////////////////////////////////////////////

const store = (state: Object) => state.notifications;
const configStore = (state: Object) => state.config;

const makeSelectItemList = () => createSelector(
  store,
  (state: Object) => R.sortBy(
    R.prop('index'),
    R.values(state.itemList),
  ),
);

const makeSelectPagination = () => createSelector(
  store,
  (state: Object) => state.pagination,
);

const makeSelectWsCount = () => createSelector(
  store,
  (state: Object) => state.wsCount,
);

const makeSelectUnreadCount = () => createSelector(
  store,
  (state: Object) => state.unreadCount,
);

const makeSelectTotalCount = () => createSelector(
  store,
  (state: Object) => state.totalCount,
);

const makeSelectListLoading = () => createSelector(
  store,
  ({ loading }: Object) => loading,
);

const makeSelectGeneralConfig = () => createSelector(
  configStore,
  ({ general }: Object) => (R.path(['initialValues', GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM], general)),
);

export {
  makeSelectWsCount,
  makeSelectItemList,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectUnreadCount,
  makeSelectListLoading,
  makeSelectGeneralConfig,
};
