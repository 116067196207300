import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
// forms
import { FieldsetComponent } from '../../../forms';
// feature new-import
import { importCLOFromFileFields } from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    mapPropsToValues: () => ({ file: null, mapper: null }),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    validationSchema: Yup.object().shape({
      file: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
      mapper: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
    }),
  }),
  pure,
);

const CLOMapAndImport = enhance((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent
      {...G.getFormikProps(props)}
      flexDirection='column'
      fields={importCLOFromFileFields}
      optionsForSelect={{ mapperOptions: props.mapperOptions }}
      fileName={G.getWindowLocale('titles:select-file', 'Select File')} />
    <FormFooter boxStyles={{ py: 15 }} closeModal={props.closeModal} />
  </form>
));

export default CLOMapAndImport;
