import * as R from 'ramda';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import React, { useState, useEffect, useCallback } from 'react';
// components
import ImageCropper from '../../../components/image-cropper';
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import { setCurrentUserProfilePhotoUrl } from '../actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Flex, AbsoluteBox, RelativeBox, RelativeFlex } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const darkBlueColor = G.getTheme('colors.dark.blue');

const Img = styled.img`
  cursor: ${({ cursor }: Object) => cursor};
  border-radius: ${({ borderRadius }: Object) => borderRadius || '4px'};
`;

const StyledInput = styled.input`
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
`;

export const Avatar = (props: Object) => {
  const {
    url,
    zIndex,
    action,
    fullText,
    className,
    avatarText,
    iconsColor,
    avatarAdditionalStyles,
  } = props;

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
  }, [url]);

  const handleClick = () => {
    if (G.isFunction(action)) return action();

    G.goToRoute(routesMap.profile);
  };

  return (
    <RelativeFlex
      width={40}
      height={40}
      m='4px 10px'
      cursor='pointer'
      title={fullText}
      border='1px solid'
      borderRadius='50%'
      alignItems='center'
      onClick={handleClick}
      justifyContent='center'
      borderColor={iconsColor}
      background={G.getTheme('colors.light.userBoxBackground')}
      {...avatarAdditionalStyles}
    >
      {avatarText}
      {
        R.isNotNil(url) &&
        <AbsoluteBox width='100%' height='100%' zIndex={R.or(zIndex, 12)}>
          <Img
            src={url}
            alt='profile'
            height={'100%'}
            cursor='pointer'
            borderRadius='50%'
            className={className}
            onLoad={() => setLoaded(true)}
            width={G.ifElse(loaded, '100%', 0)}
          />
        </AbsoluteBox>
      }
    </RelativeFlex>
  );
};

const useActions = (props: Object) => {
  const { guid, endpoints, isProfile, userGuid, photoUrl, handleUserPhoto } = props;

  const [url, setUrl] = useState(photoUrl);

  const dispatch = useDispatch();

  const requestParams = R.pick(
    [
      'userGuid',
      GC.FIELD_GUID,
      GC.FIELD_TRUCK_GUID,
      GC.FIELD_TRAILER_GUID,
    ],
    props,
  );

  const getUserPhotoUrlRequest = useCallback(async () => {
    const options = {
      params: requestParams,
    };

    const endpoint = R.prop('profilePhotoUrl', R.or(endpoints, endpointsMap));

    const res = await sendRequest('get', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      setUrl(data);
    } else {
      G.handleException('getUserPhotoUrl exception');
    }
  }, [setUrl, dispatch, userGuid, isProfile]);

  const addPhotoToUserProfileRequest = useCallback(async (file: Object) => {
    dispatch(openLoader());

    const options = {
      params: requestParams,
      data: G.makeDataForDocument({ file }),
    };

    const endpoint = R.prop('addProfilePhoto', R.or(endpoints, endpointsMap));

    const res = await sendRequest('post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      dispatch(closeModal());

      if (G.isFunction(handleUserPhoto)) {
        handleUserPhoto(data);
      } else {
        setUrl(data);
      }

      if (isProfile) {
        dispatch(setCurrentUserProfilePhotoUrl(data));
      }
    } else {
      G.handleFailResponseSimple('addPhotoToUserProfileRequest');
    }

    dispatch(closeLoader());
  }, [dispatch, isProfile]);

  const handleOpenImageCropper = useCallback((event: Object) => {
    const files = R.path(['currentTarget', 'files'], event);

    if (R.lt(R.length(files), 1)) return;

    const file = R.head(files);

    if (R.gt(R.divide(file.size, 1024), 4000)) {
      const text = `${G.getWindowLocale(
        'messages:file-size-must-not-be-greater-than',
        'File size must not be greater than to',
      )} 4mb`;

      return G.showToastrMessageSimple('info', text);
    }

    const component = <ImageCropper file={R.head(files)} submitAction={addPhotoToUserProfileRequest} />;

    const modal = {
      p: 15,
      component,
      options: {
        title: G.getWindowLocale('titles:change-photo', 'Change Photo'),
      },
    };

    dispatch(openModal(modal));
  }, [guid, dispatch]);

  const removePhotoFromUserProfileRequest = useCallback(async () => {
    dispatch(openLoader());

    const options = {
      params: requestParams,
    };

    const endpoint = R.prop('removeProfilePhoto', R.or(endpoints, endpointsMap));

    const res = await sendRequest('put', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isFunction(handleUserPhoto)) {
        handleUserPhoto(null);
      } else {
        setUrl(null);
      }

      if (isProfile) {
        dispatch(setCurrentUserProfilePhotoUrl(null));
      }
    } else {
      G.handleFailResponseSimple('removePhotoFromUserProfileRequest');
    }

    dispatch(closeLoader());
  }, [dispatch, isProfile]);

  useEffect(() => {
    setUrl(photoUrl);
  }, [setUrl, photoUrl]);

  useEffect(() => {
    if (G.isNilOrEmpty(url)) getUserPhotoUrlRequest();
  }, []);

  return {
    url,
    handleOpenImageCropper,
    removePhotoFromUserProfileRequest,
  };
};

const ActionsOnTop = (props: Object) => {
  const {
    hasPhoto,
    userGuid,
    handleOpenImageCropper,
    removePhotoFromUserProfileRequest,
  } = props;

  return (
    <AbsoluteBox top='-10px' right={G.ifElse(hasPhoto, '-10px', '0')}>
      <RelativeFlex
        fontSize={12}
        cursor='pointer'
        border='1px solid'
        color={whiteColor}
        justifyContent='center'
        borderColor={darkBlueColor}
        width={G.ifElse(hasPhoto, 30, 70)}
        height={G.ifElse(hasPhoto, 30, 20)}
        borderRadius={G.ifElse(hasPhoto, '50%', '3px')}
        bg={G.ifElse(hasPhoto, whiteColor, darkBlueColor)}
      >
        {hasPhoto ? I.pencil() : G.getWindowLocale('titles:add-photo', 'Add Photo')}
        <StyledInput
          type='file'
          accept='.png, .jpg, .jpeg'
          onChange={handleOpenImageCropper}
          onClick={(event: Object) => event.target.value = null}
        />
      </RelativeFlex>
      {
        hasPhoto &&
        <Flex
          ml={10}
          width={30}
          height={30}
          bg={whiteColor}
          cursor='pointer'
          borderRadius='50%'
          border='1px solid'
          justifyContent='center'
          borderColor={darkBlueColor}
          onClick={() => removePhotoFromUserProfileRequest({ userGuid })}
        >
          {I.trash()}
        </Flex>
      }
    </AbsoluteBox>
  );
};

const ActionsOnBottom = (props: Object) => {
  const {
    hasPhoto,
    userGuid,
    handleOpenImageCropper,
    removePhotoFromUserProfileRequest,
  } = props;

  return (
    <AbsoluteBox
      top='110%'
      left='50%'
      transform='translateX(-50%)'
    >
      <RelativeFlex
        fontSize={12}
        cursor='pointer'
        justifyContent='center'
        width={G.ifElse(hasPhoto, 15, 70)}
        height={G.ifElse(hasPhoto, 15, 20)}
      >
        {hasPhoto ? I.pencil() : G.getWindowLocale('titles:add-photo', 'Add Photo')}
        <StyledInput
          type='file'
          accept='.png, .jpg, .jpeg'
          onChange={handleOpenImageCropper}
        />
      </RelativeFlex>
      {
        hasPhoto &&
        <Flex
          ml={10}
          width={15}
          height={15}
          cursor='pointer'
          justifyContent='center'
          onClick={() => removePhotoFromUserProfileRequest({ userGuid })}
        >
          {I.trash()}
        </Flex>
      }
    </AbsoluteBox>
  );
};

const ProfilePhoto = (props: Object) => {
  const {
    userGuid,
    fullText,
    isAvatar,
    avatarText,
    iconsColor,
    withActions,
    width = 150,
    version = 1,
    height = 150,
    withoutUpload,
    defaultPhotoUrl,
    avatarAdditionalStyles,
  } = props;

  const {
    url,
    handleOpenImageCropper,
    removePhotoFromUserProfileRequest,
  } = useActions(props);

  const secondVersion = R.equals(version, 2);

  if (isAvatar) {
    return (
      <Avatar
        url={url}
        fullText={fullText}
        avatarText={avatarText}
        iconsColor={iconsColor}
        avatarAdditionalStyles={avatarAdditionalStyles}
      />
    );
  }

  if (G.isAllNilOrEmpty([url, defaultPhotoUrl])) {
    if (withoutUpload) return null;

    return (
      <RelativeFlex
        cursor='pointer'
        minWidth={width}
        minHeight={height}
        borderRadius='4px'
        textAlign='center'
        border='1px dashed'
        justifyContent='center'
        borderColor={G.getTheme('colors.dark.grey')}
      >
        {G.getWindowLocale('titles:select-photo', 'Select Photo')}
        <StyledInput
          type='file'
          accept='.png, .jpg, .jpeg'
          onChange={handleOpenImageCropper}
          onClick={(event: Object) => event.target.value = null}
        />
      </RelativeFlex>
    );
  }

  const hasPhoto = G.isNotNilAndNotEmpty(url);

  const actionProps = {
    hasPhoto,
    userGuid,
    handleOpenImageCropper,
    removePhotoFromUserProfileRequest,
  };

  return (
    <RelativeBox width={width} height={height}>
      {
        withActions && G.ifElse(
          secondVersion,
          <ActionsOnBottom {...actionProps} />,
          <ActionsOnTop {...actionProps} />,
        )
      }
      <Img
        width={width}
        alt='profile'
        height={height}
        src={G.ifElse(hasPhoto, url, defaultPhotoUrl)}
        borderRadius={G.ifElse(
          secondVersion,
          '50px',
          '4px',
        )
        }
      />
    </RelativeBox>
  );
};

export default ProfilePhoto;
