import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
// features
import { AuthWrapper } from '../../permission';
import { StylingImage } from '../../styling/ui';
import PC from '../../permission/role-permission';
// forms
import { ToggleRed } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, Text, ActionButton } from '../../../ui';
// feature new-do
import BranchSearch from './branch-search';
import ApplyTemplate from './apply-template';
import AssignDivision from './assign-division';
import { withCopyFromTemplate } from '../hocs';
import { isPageCreateDO, isPageCloTemplate } from '../helpers';
import { OrderType, ReferenceType, CustomerReference, CustomerLoadNumber } from './header-references';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const blueColor = G.getTheme('colors.dark.blue');

const commonBtnStyles = {
  height: 32,
  p: '4px 8px',
  fontSize: 14,
  borderRadius: '5px',
  bgColor: whiteColor,
  textColor: blueColor,
  border: `1px solid ${blueColor}`,
};

const Header1 = (props: Object) => {
  const { pageType, templateData, handleAssignDivision } = props;
  const templateName = R.path([GC.FIELD_TEMPLATE_NAME], templateData);
  const branchName = R.path(['branchInfo', 'label'], props);
  const divisionName = R.path(['division', GC.FIELD_BRANCH_BRANCH_NAME], props);
  const logoUrl = R.path([GC.SYSTEM_OBJECT_STYLING, GC.FIELD_STYLING_LOGO_URL], props);
  const title = G.ifElse(
    isPageCloTemplate(pageType),
    G.getWindowLocale('titles:clo-template', 'CLO Template'),
    G.getWindowLocale('actions:create-clo', 'Create CLO'),
  );

  return (
    <Flex
      px={20}
      height={60}
      bg={whiteColor}
      flexWrap='wrap'
      justifyContent='space-between'
    >
      <Box>
        <Flex>
          <Text
            mr={30}
            fontSize={24}
            fontWeight='bold'
            color={blueColor}
          >
            {title}
          </Text>
          {
            templateName &&
            <Flex
              ml={30}
              fontSize={18}
              fontWeight='bold'
              color={blueColor}
              alignItems='stretch'
            >
              {`${G.getWindowLocale('titles:template-name', 'Template Name')}:`}
              <TextComponent
                ml='5px'
                maxWidth={180}
                withEllipsis={true}
                title={templateName}
                display='inline-block'
              >
                {templateName}
              </TextComponent>
            </Flex>
          }
        </Flex>
      </Box>
      <Box>
        {
          branchName &&
          <Flex alignItems='center' justifyContent='center'>
            <Flex mr={15} flexDirection='column'>
              <Text fontSize={18} color={G.getTheme('colors.titleGrey')}>
                {branchName}
              </Text>
              <AuthWrapper has={[PC.TEL_WRITE]}>
                <Box
                  cursor='pointer'
                  color={blueColor}
                  onClick={handleAssignDivision}
                >
                  {R.or(divisionName, G.getWindowLocale('titles:user-branch', 'User Branch'))}
                </Box>
              </AuthWrapper>
            </Flex>
            {logoUrl && <StylingImage width='100px' height='50px' bgImage={logoUrl} />}
          </Flex>
        }
      </Box>
    </Flex>
  );
};

const wrapperStyles = {
  width: '100%',
  bg: whiteColor,
  flexWrap: 'wrap',
  p: '15px 20px 10px 20px',
  justifyContent: 'space-between',
};

const saveStyles = {
  mx: 20,
  ...commonBtnStyles,
};

const Header2 = (props: Object) => {
  const {
    pageType,
    hotOrder,
    branchGuid,
    toggleHotOrder,
    handleSaveAsTemplate,
    getBranchListRequest,
    openAddBranchModalRequest,
    handleOpenCopyFromTemplate,
  } = props;

  const disabled = G.ifElse(G.isNilOrEmpty(branchGuid), true, false);
  const cursor = G.ifElse(G.isNilOrEmpty(branchGuid), 'not-allowed', 'pointer');
  const hotColor = G.ifElse(G.isTrue(hotOrder), G.getTheme('colors.light.mainRed'), G.getTheme('colors.light.darkGrey'));

  return (
    <Flex {...wrapperStyles}>
      <Box>
        <Flex>
          <BranchSearch {...props} />
          <CustomerLoadNumber {...props} />
          <ReferenceType {...props} />
          <CustomerReference {...props} />
          <OrderType {...props} />
          <ApplyTemplate {...props} />
        </Flex>
      </Box>
      <Box mt='5px'>
        <Flex>
          {
            isPageCreateDO(pageType) &&
            <AuthWrapper notHas={[PC.ROLE_TYPE_CUSTOMER]}>
              <ActionButton
                {...commonBtnStyles}
                mr={30}
                onClick={() => openAddBranchModalRequest({ callbackAction: getBranchListRequest })}
              >
                {G.getWindowLocale('titles:add-new-customer', 'Add New Customer')}
              </ActionButton>
            </AuthWrapper>
          }
          <ActionButton
            {...commonBtnStyles}
            onClick={handleOpenCopyFromTemplate}
          >
            {G.getWindowLocale('actions:copy-from-template', 'Copy From Template')}
          </ActionButton>
          <ActionButton
            {...saveStyles}
            cursor={cursor}
            disabled={disabled}
            onClick={handleSaveAsTemplate}
          >
            {G.getWindowLocale('actions:save-as-template', 'Save As Template')}
          </ActionButton>
          <Flex>
            <ToggleRed
              icons={false}
              checked={hotOrder}
              onChange={toggleHotOrder}
            />
            <Box
              ml={10}
              color={hotColor}
              textTransform='uppercase'
            >
              {G.getWindowLocale('titles:hot-order', 'Hot Order')}
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

const Header = (props: Object) => (
  <Box>
    <Header1 {...props} />
    <Header2 {...props} />
  </Box>
);

const enhance = compose(
  withCopyFromTemplate,
  withHandlers({
    handleAssignDivision: (props: Object) => () => {
      const component = (
        <AssignDivision
          assignDivision={props.setDivisionGuid}
          getBranchConfigs={props.getBranchConfigsByNamesRequest}
        />
      );
      const modal = {
        p: '0 8px',
        component,
        options: {
          width: 'auto',
          height: 'auto',
          title: G.getWindowLocale('titles:assign-division', 'Assign Division'),
        },
      };
      props.openModal(modal);
    },
  }),
  pure,
);

export default enhance(Header);
