import * as R from 'ramda';
import React from 'react';
// features
import { AuthWrapper } from '../../../permission';
// helpers/constants
import * as G from '../../../../helpers';
// feature navbar-menu
import { LabelSmall, NavbarButton, ActiveSign } from './ui';
//////////////////////////////////////////////////

export default ({
  has,
  icon,
  text,
  active,
  cursor,
  iconsColor,
  editPinned,
  onClick = () => {},
  onMouseDown = () => {},
}: Object) => (
  <AuthWrapper has={has} width='100%' justifyContent='center'>
    <NavbarButton
      p='4px 0'
      height={46}
      width='100%'
      title={text}
      active={active}
      onClick={onClick}
      flexDirection='column'
      editPinned={editPinned}
      onMouseDown={onMouseDown}
      justifyContent='space-between'
      cursor={R.or(cursor, 'pointer')}
      bgColor={G.getTheme('colors.light.navbarButtonHoverBackground')}
    >
      {active && <ActiveSign background={iconsColor} />}
      {icon(iconsColor, 24, 24)}
      <LabelSmall color={iconsColor}>
        {text}
      </LabelSmall>
    </NavbarButton>
  </AuthWrapper>
);
