import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

export const TitleRow = ({ color, currencies }: Object) => (
  <Flex mb='4px' width='100%' color={color} fontSize={13}>
    <Box width={170}>{G.getWindowLocale('titles:currency', 'Currency')}</Box>
    {
      currencies.map((currency: string, i: number) => (
        <Box key={i} width={90}>{currency}</Box>
      ))
    }
    <Box ml='auto' width={100}>
      {G.getWindowLocale('titles:payroll-totals', 'Payroll Totals')}
    </Box>
  </Flex>
);

const TotalRow = ({ label, totals, rowTotal, currencies, wrapperStyles, payrollCurrency }: Object) => (
  <Flex {...wrapperStyles}>
    <Box width={170}>{label}</Box>
    {
      G.isNotNilAndNotEmpty(totals) &&
      currencies.map((currency: string, i: number) => {
        const data = R.pathOr(null, [currency], totals);

        return (
          <Box key={i} width={90}>
            {G.isNotNil(data) && R.prop(GC.FIELD_TOTAL, data)}
          </Box>
        );
      })
    }
    {
      G.isNotNil(rowTotal) &&
      <Box ml='auto' width={100}>
        {payrollCurrency} {rowTotal}
      </Box>
    }
  </Flex>
);

export default TotalRow;
