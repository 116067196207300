import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { FieldsetComponent } from '../../../forms';
// feature new-import
import * as C from '../constants';
import {
  tollChargeSourceOptions,
  importFromFileFieldSettings,
  defaultImportFromFileFields,
  importFromFileValidationSchema,
  fuelCardTransactionSourceOptions } from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultImportFromFileFields,
      initialValues,
    ),
    validationSchema: importFromFileValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => {
      const { type, file, source, roleGuids, contractGuid, cleanExistentRates } = values;

      let data = { type, file, [GC.FIELD_BRANCH_GUID]: G.getAmousCurrentBranchGuidFromWindow()};

      if (R.equals(type, C.IMPORT_TYPE_DRIVER)) {
        const parameters = { roleGuids };

        data = R.assoc('parameters', parameters, data);
      }

      if (R.includes(type, [C.IMPORT_TYPE_TOLL_CHARGE, C.IMPORT_TYPE_FUEL_CARD_TRANSACTION])) {
        const parameters = { source };

        data = R.assoc('parameters', parameters, data);
      }

      if (R.equals(type, C.IMPORT_TYPE_CARRIER_RATE_PRICE)) {
        const parameters = { contractGuid, cleanExistentRates };

        data = R.assoc('parameters', parameters, data);
      }

      props.submitAction(data);
    },
  }),
  pure,
);

export const ImportFromFile = (props: Object) => {
  const { values, handleSubmit, driverRoleOptions } = props;

  return (
    <form onSubmit={handleSubmit}>
      <FieldsetComponent
        {...G.getFormikProps(props)}
        flexDirection='column'
        fields={importFromFileFieldSettings}
        fileName={G.getWindowLocale('titles:select-file', 'Select File')}
        optionsForSelect={{
          driverRoleOptions,
          sourceOptions: G.ifElse(
            R.propEq(C.IMPORT_TYPE_FUEL_CARD_TRANSACTION, GC.FIELD_TYPE, values),
            fuelCardTransactionSourceOptions,
            tollChargeSourceOptions,
          ),
        }}
      />
      <FormFooter boxStyles={{ mt: 15 }} />
    </form>
  );
};

export default enhance(ImportFromFile);
