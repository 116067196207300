import React from 'react';
import * as R from 'ramda';
// components
import { FormSectionHeader } from '../../../components/form-section-header';
// features
import PC from '../../permission/role-permission';
import SectionDivider from '../../new-do/components/section-divider';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Flex } from '../../../ui';
// feature rate
import { trackingReferenceFields } from './settings';
//////////////////////////////////////////////////

const isOpenedSection = R.compose(
  R.lt(0),
  R.length,
  R.filter(G.isNotNilAndNotEmpty),
  R.values,
  R.pick([
    GC.FIELD_BOL_NUMBER,
    GC.FIELD_QUOTE_NUMBER,
    GC.FIELD_PICKUP_NUMBER,
    GC.FIELD_CARRIER_ACCEPT_TRACKING_NUMBER,
  ]),
  R.pathOr({}, ['initialValues', GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT]),
);

const handleDisableField = (value: string, permissions: string) => {
  if (G.hasAmousCurrentUserPermissions(permissions)) return false;

  return G.isNotNilAndNotEmpty(value);
};

const TrackingReferenceSection = (props: Object) => {
  const { initialValues } = props;

  const getTrackingNumberFromInitialValues = R.path(
    [
      GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT,
      GC.FIELD_CARRIER_ACCEPT_TRACKING_NUMBER,
    ],
    initialValues,
  );

  const getPickupNumberFromInitialValues = R.path(
    [
      GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT,
      GC.FIELD_PICKUP_NUMBER,
    ],
    initialValues,
  );

  const getBOLNumberFromInitialValues = R.path(
    [
      GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT,
      GC.FIELD_PICKUP_NUMBER,
    ],
    initialValues,
  );

  return (
    <FormSectionHeader
      expanded={isOpenedSection(props)}
      title={G.getWindowLocale('titles:tracking-references', 'Tracking/References')}
    >
      <Flex p={15}>
        <Fieldset2
          {...G.getFormikProps(props)}
          flexGrow='unset'
          labelsColor='colors.darkGrey'
          fields={trackingReferenceFields}
          handlers={{
            handleDisableBOLNumberField: () => handleDisableField(
              getBOLNumberFromInitialValues,
              PC.CHANGE_CARRIER_RATE_TRACKING_EXECUTE,
            ),
            handleDisablePickupNumberField: () => handleDisableField(
              getPickupNumberFromInitialValues,
              PC.CHANGE_CARRIER_RATE_PICKUP_NUMBER_EXECUTE,
            ),
            handleDisableTrackingNumberField: () => handleDisableField(
              getTrackingNumberFromInitialValues,
              PC.CHANGE_CARRIER_RATE_TRACKING_EXECUTE,
            ),
          }}
        />
      </Flex>
      <SectionDivider mt='0px' mb='0px' />
    </FormSectionHeader>
  );
};

export default TrackingReferenceSection;
