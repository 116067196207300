import React from 'react';
// components
import { ActionsElement } from '../../../components/actions-element';
// features
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
//////////////////////////////////////////////////

const getOptions = ({ guid, handleDelete }: Object) => [
  {
    permissions: null,
    action: () => handleDelete(guid),
    frontIcon: I.trash(G.getTheme('colors.light.blue')),
    text: G.getWindowLocale('actions:delete', 'Delete'),
  },
];

export const RowActions = (props: Object) => (
  <ActionsElement options={getOptions(props)} />
);
