import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { PopperComponent } from '../../../components/popper';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
//////////////////////////////////////////////////

const ErrorsContent = ({ errors }: Object) => (
  <Flex p={10}>
    {
      errors.map((error: string, index: number) => (
        <Box p='5px' key={index} color={G.getTheme('colors.light.mainRed')}>
          {R.inc(index)}. {error}
        </Box>
      ))
    }
  </Flex>
);

const ErrorsPopper = (props: Object) => {
  const { errors } = props;

  return (
    <PopperComponent
      type='hover'
      position='right'
      content={<ErrorsContent errors={errors} />}
      borderColor={G.getTheme('listActions.borderColor')}
    >
      <Box mr={10}>{I.warningIcon()}</Box>
    </PopperComponent>
  );
};

const enhance = compose(
  withHandlers({
    handleCreateLoad: ({ guid }: Object) => () => {
      const options = {
        guid,
        [GC.FIELD_SOURCE_TYPE]: GC.CREATE_DO_SOURCE_TYPE_API,
      };
      G.goToRoute(routesMap.newDOBySourceType(options));
    },
  }),
  pure,
);

const RequestErrors = ({ status, errors, handleCreateLoad }: Object) => (
  <Flex
    alignItems='center'
    height='max-content'
    color={G.getTheme('colors.dark.blue')}
  >
    {
      R.includes(status, [GC.ORDER_REQUEST_STATUS_PENDING]) &&
      <AuthWrapper has={[PC.PUBLIC_API_ORDER_WRITE]}>
        <Box
          mr={10}
          cursor='pointer'
          onClick={handleCreateLoad}
          title={G.getWindowLocale('actions:create-clo', 'Create CLO')}
        >
          {I.routesLoads(G.getTheme('colors.dark.blue'))}
        </Box>
      </AuthWrapper>
    }
    {
      G.isNotNilAndNotEmpty(errors) && <ErrorsPopper errors={errors} />
    }
  </Flex>
);

export default enhance(RequestErrors);
