const BRANCH_READ_CONSTANT = 'ENTERPRISE_READ';
const BRANCH_WRITE_CONSTANT = 'ENTERPRISE_WRITE';
const ACTIVATE_BRANCH_EXECUTE_CONSTANT = 'ACTIVATE_ENTERPRISE_EXECUTE';

const PC = {
  ANY: 'ANY',
  ROLE_TYPE_GENERAL: 'ROLE_TYPE_GENERAL',
  ROLE_LIUBANCHYK_SUPER_ADMIN: 'ROLE_LIUBANCHYK_SUPER_ADMIN',
  // styling
  STYLING_EXECUTE: 'STYLING_EXECUTE',
  // splash screen
  SCREEN_CONFIGURATION_READ: 'SCREEN_CONFIGURATION_READ',
  SCREEN_CONFIGURATION_WRITE: 'SCREEN_CONFIGURATION_WRITE',
  // user
  USER_READ: 'USER_READ',
  USER_WRITE: 'USER_WRITE',
  USER_PROFILE_READ: 'USER_PROFILE_READ',
  USER_PROFILE_WRITE: 'USER_PROFILE_WRITE',
  SUSPEND_USER_EXECUTE: 'SUSPEND_USER_EXECUTE',
  USER_REFERENCES_READ: 'USER_REFERENCES_READ',
  USER_REFERENCES_WRITE: 'USER_REFERENCES_WRITE',
  // role
  ROLES_READ: 'ROLES_READ',
  ROLES_WRITE: 'ROLES_WRITE',
  // role type
  ROLE_TYPE_CARRIER: 'ROLE_TYPE_CARRIER',
  ROLE_TYPE_CUSTOMER: 'ROLE_TYPE_CUSTOMER',
  // branch
  BRANCH_READ: BRANCH_READ_CONSTANT,
  BRANCH_WRITE: BRANCH_WRITE_CONSTANT,
  CREATE_DIVISION_EXECUTE: 'CREATE_DIVISION_EXECUTE',
  ACTIVATE_BRANCH_EXECUTE: ACTIVATE_BRANCH_EXECUTE_CONSTANT,
  CHANGE_COMMISSION_ASSIGNMENT_EXECUTE: 'CHANGE_COMMISSION_ASSIGNMENT_EXECUTE',
  // item
  ITEM_TEMPLATE_WRITE: 'ITEM_TEMPLATE_WRITE',
  // container template
  CONTAINER_TEMPLATE_WRITE: 'CONTAINER_TEMPLATE_WRITE',
  // location
  LOCATION_TEMPLATE_WRITE: 'LOCATION_TEMPLATE_WRITE',
  // contact-book
  CONTACT_BOOK_WRITE: 'CONTACT_BOOK_WRITE',
  // compensation template
  COMPENSATION_TEMPLATE_WRITE: 'COMPENSATION_TEMPLATE_WRITE',
  // reference
  REFERENCE_TYPE_WRITE: 'REFERENCE_TYPE_WRITE',
  // configs
  CONFIGURATIONS_WRITE: 'CONFIGURATIONS_WRITE',
  DOCUMENT_TEMPLATE_WRITE: 'DOCUMENT_TEMPLATE_WRITE',
  // fleet
  FLEET_TRUCK_READ: 'FLEET_TRUCK_READ',
  TOLL_CHARGE_READ: 'TOLL_CHARGE_READ',
  TOLL_CHARGE_WRITE: 'TOLL_CHARGE_WRITE',
  FLEET_TRUCK_WRITE: 'FLEET_TRUCK_WRITE',
  FLEET_DRIVER_READ: 'FLEET_DRIVER_READ',
  FLEET_VENDOR_READ: 'FLEET_VENDOR_READ',
  FLEET_FUEL_CARD_READ: 'FUEL_CARD_READ',
  FLEET_FUEL_CARD_WRITE: 'FUEL_CARD_WRITE',
  FLEET_TRAILER_READ: 'FLEET_TRAILER_READ',
  FLEET_DRIVER_WRITE: 'FLEET_DRIVER_WRITE',
  FLEET_VENDOR_WRITE: 'FLEET_VENDOR_WRITE',
  FLEET_TRAILER_WRITE: 'FLEET_TRAILER_WRITE',
  FLEET_CHANGE_TRUCK_EXECUTE: 'CHANGE_TRUCK_EXECUTE',
  ENABLE_FUEL_CARD_EXECUTE: 'ENABLE_FUEL_CARD_EXECUTE',
  INSPECTION_TEMPLATE_READ: 'INSPECTION_TEMPLATE_READ',
  INSPECTION_TEMPLATE_WRITE: 'INSPECTION_TEMPLATE_WRITE',
  AVAILABLE_DRIVER_LIST_READ: 'AVAILABLE_DRIVER_LIST_READ',
  AVAILABLE_DRIVER_LIST_WRITE: 'AVAILABLE_DRIVER_LIST_WRITE',
  FLEET_VENDOR_ACCOUNTING_READ: 'FLEET_VENDOR_ACCOUNTING_READ',
  FLEET_DRIVER_ACCOUNTING_READ: 'FLEET_DRIVER_ACCOUNTING_READ',
  FLEET_VENDOR_ACCOUNTING_WRITE: 'FLEET_VENDOR_ACCOUNTING_WRITE',
  FLEET_DRIVER_ACCOUNTING_WRITE: 'FLEET_DRIVER_ACCOUNTING_WRITE',
  FLEET_TRAILER_SHARING_EXECUTE: 'FLEET_TRAILER_SHARING_EXECUTE',
  // carrier
  CARRIER_READ: 'CARRIER_READ',
  CARRIER_WRITE: 'CARRIER_WRITE',
  CREATE_CARRIER_EXECUTE: 'CREATE_CARRIER_EXECUTE',
  ACTIVATE_CARRIER_EXECUTE: 'ACTIVATE_CARRIER_EXECUTE',
  // service vendor
  SERVICE_VENDOR_READ: 'SERVICE_VENDOR_READ',
  SERVICE_VENDOR_WRITE: 'SERVICE_VENDOR_WRITE',
  // tel
  TEL_READ: 'TEL_READ',
  TEL_WRITE: 'TEL_WRITE',
  ITEM_DAMAGE_READ: 'ITEM_DAMAGE_READ',
  TEL_BILL_TO_READ: 'TEL_BILL_TO_READ',
  ITEM_DAMAGE_WRITE: 'ITEM_DAMAGE_WRITE',
  TEL_BILL_TO_WRITE: 'TEL_BILL_TO_WRITE',
  TEL_DOCUMENT_READ: 'TEL_DOCUMENT_READ',
  TEL_DELETE_EXECUTE: 'TEL_DELETE_EXECUTE',
  TEL_DOCUMENT_WRITE: 'TEL_DOCUMENT_WRITE',
  TEL_REFERENCE_READ: 'TEL_REFERENCE_READ',
  TEL_REFERENCE_WRITE: 'TEL_REFERENCE_WRITE',
  TEL_ENTERPRISE_READ: 'TEL_ENTERPRISE_READ',
  TEL_ENTERPRISE_WRITE: 'TEL_ENTERPRISE_WRITE',
  TEL_CHAT_MESSAGE_READ: 'TEL_CHAT_MESSAGE_READ',
  TEL_CHAT_MESSAGE_WRITE: 'TEL_CHAT_MESSAGE_WRITE',
  TEL_FLEET_INVOICE_READ: 'TEL_FLEET_INVOICE_READ',
  TEL_FLEET_INVOICE_WRITE: 'TEL_FLEET_INVOICE_WRITE',
  DISPATCH_ACCEPT_EXECUTE: 'DISPATCH_ACCEPT_EXECUTE',
  TEL_STATUS_MESSAGE_READ: 'TEL_STATUS_MESSAGE_READ',
  TEL_CARRIER_INVOICE_READ: 'TEL_CARRIER_INVOICE_READ',
  TEL_STATUS_MESSAGE_WRITE: 'TEL_STATUS_MESSAGE_WRITE',
  TEL_CARRIER_INVOICE_WRITE: 'TEL_CARRIER_INVOICE_WRITE',
  TEL_ADMINISTRATION_EXECUTE: 'TEL_ADMINISTRATION_EXECUTE',
  TEL_CHANGE_PRIMARY_REFERENCE_EXECUTE: 'TEL_CHANGE_PRIMARY_REFERENCE_EXECUTE',
  TEL_RATE_DISPATCH_BY_OVERWRITE_EXECUTE: 'TEL_RATE_DISPATCH_BY_OVERWRITE_EXECUTE',
  // clo
  CLO_READ: 'CLO_READ',
  CLO_WRITE: 'CLO_WRITE',
  CLO_BILL_TO_READ: 'CLO_BILL_TO_READ',
  ORDER_QUOTE_READ: 'ORDER_QUOTE_READ',
  CARRIER_EDI_READ: 'CARRIER_EDI_READ',
  ORDER_QUOTE_WRITE: 'ORDER_QUOTE_WRITE',
  CARRIER_EDI_WRITE: 'CARRIER_EDI_WRITE',
  CLO_DOCUMENT_READ: 'CLO_DOCUMENT_READ',
  CLO_BILL_TO_WRITE: 'CLO_BILL_TO_WRITE',
  CLO_DELETE_EXECUTE: 'CLO_DELETE_EXECUTE',
  CLO_REFERENCE_READ: 'CLO_REFERENCE_READ',
  CLO_DOCUMENT_WRITE: 'CLO_DOCUMENT_WRITE',
  CLO_REFERENCE_WRITE: 'CLO_REFERENCE_WRITE',
  CLO_APPROVE_EXECUTE: 'CLO_APPROVE_EXECUTE',
  CLO_ENTERPRISE_READ: 'CLO_ENTERPRISE_READ',
  CLO_ENTERPRISE_WRITE: 'CLO_ENTERPRISE_WRITE',
  PUBLIC_API_ORDER_READ: 'PUBLIC_API_ORDER_READ',
  CLO_CHAT_MESSAGE_READ: 'CLO_CHAT_MESSAGE_READ',
  CLO_DUPLICATE_EXECUTE: 'CLO_DUPLICATE_EXECUTE',
  PUBLIC_API_ORDER_WRITE: 'PUBLIC_API_ORDER_WRITE',
  CLO_CHAT_MESSAGE_WRITE: 'CLO_CHAT_MESSAGE_WRITE',
  CLO_STATUS_MESSAGE_READ: 'CLO_STATUS_MESSAGE_READ',
  CLO_STATUS_MESSAGE_WRITE: 'CLO_STATUS_MESSAGE_WRITE',
  CLO_ADMINISTRATION_EXECUTE: 'CLO_ADMINISTRATION_EXECUTE',
  ORDER_QUOTE_OVERWRITE_EXECUTE: 'ORDER_QUOTE_OVERWRITE_EXECUTE',
  CLO_CHANGE_PRIMARY_REFERENCE_EXECUTE: 'CLO_CHANGE_PRIMARY_REFERENCE_EXECUTE',
  // IFTA
  IFTA_EXECUTE: 'IFTA_EXECUTE',
  // financial
  GL_CODE_WRITE: 'GL_CODE_WRITE',
  SHOW_EARNINGS_EXECUTE: 'SHOW_EARNINGS_EXECUTE',
  CUSTOM_EXCHANGE_RATE_READ: 'CUSTOM_EXCHANGE_RATE_READ',
  CUSTOM_EXCHANGE_RATE_WRITE: 'CUSTOM_EXCHANGE_RATE_WRITE',
  // rate
  CLO_RATE_READ: 'CLO_RATE_READ',
  CLO_RATE_WRITE: 'CLO_RATE_WRITE',
  FLEET_RATE_READ: 'FLEET_RATE_READ',
  FLEET_RATE_WRITE: 'FLEET_RATE_WRITE',
  CARRIER_RATE_READ: 'CARRIER_RATE_READ',
  CARRIER_RATE_WRITE: 'CARRIER_RATE_WRITE',
  MARGIN_VIOLATION_APPROVAL_EXECUTE: 'MARGIN_VIOLATION_APPROVAL_EXECUTE',
  CHANGE_CARRIER_RATE_TRACKING_EXECUTE: 'CHANGE_CARRIER_RATE_TRACKING_EXECUTE',
  CARRIER_INTEGRATION_ACCOUNT_NUMBER_READ: 'CARRIER_INTEGRATION_ACCOUNT_NUMBER_READ',
  CHANGE_CARRIER_RATE_PICKUP_NUMBER_EXECUTE: 'CHANGE_CARRIER_RATE_PICKUP_NUMBER_EXECUTE',
  // invoice
  CLO_INVOICE_READ: 'CLO_INVOICE_READ',
  CLO_INVOICE_WRITE: 'CLO_INVOICE_WRITE',
  DRIVER_PAYROLL_READ: 'DRIVER_PAYROLL_READ',
  DRIVER_PAYROLL_WRITE: 'DRIVER_PAYROLL_WRITE',
  CLO_MASTER_INVOICE_READ: 'CLO_MASTER_INVOICE_READ',
  CLO_MASTER_INVOICE_WRITE: 'CLO_MASTER_INVOICE_WRITE',
  FLEET_VENDOR_PAYROLL_READ: 'FLEET_VENDOR_PAYROLL_READ',
  CLO_INVOICE_DELETE_EXECUTE: 'CLO_INVOICE_DELETE_EXECUTE',
  TEL_INVOICE_DELETE_EXECUTE: 'TEL_INVOICE_DELETE_EXECUTE',
  FLEET_VENDOR_PAYROLL_WRITE: 'FLEET_VENDOR_PAYROLL_WRITE',
  CLO_INVOICE_OVERWRITE_EXECUTE: 'CLO_INVOICE_OVERWRITE_EXECUTE',
  TEL_INVOICE_OVERWRITE_EXECUTE: 'TEL_INVOICE_OVERWRITE_EXECUTE',
  DRIVER_PAYROLL_DELETE_EXECUTE: 'DRIVER_PAYROLL_DELETE_EXECUTE',
  DRIVER_PAYROLL_OVERWRITE_EXECUTE: 'DRIVER_PAYROLL_OVERWRITE_EXECUTE',
  CLO_MASTER_INVOICE_DELETE_EXECUTE: 'CLO_MASTER_INVOICE_DELETE_EXECUTE',
  FLEET_VENDOR_PAYROLL_DELETE_EXECUTE: 'FLEET_VENDOR_PAYROLL_DELETE_EXECUTE',
  CLO_MASTER_INVOICE_OVERWRITE_EXECUTE: 'CLO_MASTER_INVOICE_OVERWRITE_EXECUTE',
  FLEET_VENDOR_PAYROLL_OVERWRITE_EXECUTE: 'FLEET_VENDOR_PAYROLL_OVERWRITE_EXECUTE',
  // analytic
  DASHBOARD_READ: 'DASHBOARD_READ',
  DASHBOARD_WRITE: 'DASHBOARD_WRITE',
  // chart
  CHART_READ: 'CHART_READ',
  CHART_WRITE: 'CHART_WRITE',
  // report
  REPORT_WRITE: 'REPORT_WRITE',
  REPORT_CHANGE_OWNER_EXECUTE: 'REPORT_CHANGE_OWNER_EXECUTE',
  // import
  IMPORT_EXECUTE: 'IMPORT_EXECUTE',
  // edi
  CLO_EDI_READ: 'CLO_EDI_READ',
  CLO_EDI_WRITE: 'CLO_EDI_WRITE',
  // external-load-board
  EXTERNAL_LOAD_BOARD_EXECUTE: 'EXTERNAL_LOAD_BOARD_EXECUTE',
  // send-email
  SEND_EMAIL_EXECUTE: 'SEND_EMAIL_EXECUTE',
  // rate-shop
  RATE_SHOP_EXECUTE: 'RATE_SHOP_EXECUTE',
  // TASK MANAGEMENT
  TASK_READ: 'TASK_READ',
  TASK_WRITE: 'TASK_WRITE',
  TASK_BOARD_READ: 'TASK_BOARD_READ',
  TASK_BOARD_WRITE: 'TASK_BOARD_WRITE',
  // claim
  CLAIM_READ: 'CLAIM_READ',
  CLAIM_WRITE: 'CLAIM_WRITE',
};

export default PC;
