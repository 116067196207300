// helpers/constants
import * as GC from '../../constants';
//////////////////////////////////////////////////

const availableRatesAndCarriersTableSettings = {
  allowEditBtn: true,
  titleRowHeight: 30,
  tableRowHeight: 30,
  useMainColors: true,
  moreBtnCellWidth: 50,
  allowSelectAll: false,
  checkBoxCellWidth: 70,
  expandableItems: false,
  maxHeight: 'calc(100vh - 145px)',
};

const generalServiceVendorSectionSettings = [
  {
    type: 'select',
    isRequired: true,
    fieldName: GC.FIELD_CURRENCY,
    options: GC.CURRENCY_OPTIONS,
    label: ['titles:currency', 'Currency'],
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 1,
      flexBasis: 180,
    },
  },
  {
    type: 'select',
    options: GC.FIELD_SERVICE_TYPE,
    fieldName: GC.FIELD_SERVICE_TYPE,
    label: ['titles:service-type', 'Service Type'],
    inputWrapperStyles: {
      mr: 40,
      flexGrow: 1,
      flexBasis: 180,
    },
  },
  {
    type: 'textarea',
    fieldName: GC.FIELD_COMMENTS,
    label: ['titles:comments', 'Comments'],
    inputWrapperStyles: {
      flexGrow: 1,
      flexBasis: 500,
    },
  },
];

const marginViolationFields = [
  {
    type: 'select',
    options: 'marginViolationReasonOptions',
    fieldName: GC.FIELD_MARGIN_VIOLATION_REASON,
    label: ['titles:violation-reason', 'Violation Reason'],
    inputWrapperStyles: {
      mr: 15,
      flexGrow: 1,
      flexBasis: 150,
    },
  },
  {
    type: 'textarea',
    fieldName: GC.FIELD_MARGIN_VIOLATION_NOTE,
    label: ['titles:violation-note', 'Violation Note'],
    inputWrapperStyles: {
      flexGrow: 1,
      flexBasis: 400,
    },
  },
];

export {
  marginViolationFields,
  generalServiceVendorSectionSettings,
  availableRatesAndCarriersTableSettings,
};
