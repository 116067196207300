import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const selectItem = createAction('selectItem');
export const setListLoading = createAction('setListLoading');
export const selectAllItems = createAction('selectAllItems');
export const setInitialState = createAction('setInitialState');
export const getItemListRequest = createAction('getItemListRequest');
export const getItemListSuccess = createAction('getItemListSuccess');
export const markAllAsReadRequest = createAction('markAllAsReadRequest');
export const markAllAsReadSuccess = createAction('markAllAsReadSuccess');
export const getUnreadCountRequest = createAction('getUnreadCountRequest');
export const getUnreadCountSuccess = createAction('getUnreadCountSuccess');
export const resetListAndPagination = createAction('resetListAndPagination');
export const deleteAllNotifications = createAction('deleteAllNotifications');
export const deleteSelectedNotifications = createAction('deleteSelectedNotifications');
export const resetListAndPaginationAndCallList = createAction('resetListAndPaginationAndCallList');
