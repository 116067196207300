// helpers/constants
import * as GC from '../../constants';
// feature permission
import PC from './role-permission';
//////////////////////////////////////////////////

const DISPATCH_BOARD_PERMISSIONS = [
  PC.CLO_READ,
  PC.TEL_READ,
  PC.CLO_WRITE,
  PC.TEL_WRITE,
];

const RoutePermission = {
  // user
  [GC.ROUTE_PATH_USERS_NEW]: [PC.USER_WRITE],
  [GC.ROUTE_PATH_USERS_LIST]: [PC.USER_READ, PC.USER_WRITE],
  [GC.ROUTE_PATH_PROFILE]: [PC.USER_PROFILE_WRITE, PC.USER_PROFILE_READ],
  [GC.ROUTE_PATH_USERS_SETTINGS]: [
    PC.USER_PROFILE_READ,
    PC.USER_PROFILE_WRITE,
  ],
  // role
  [GC.ROUTE_PATH_ROLES_NEW]: [
    PC.ROLES_WRITE,
    PC.ROLE_LIUBANCHYK_SUPER_ADMIN,
  ],
  [GC.ROUTE_PATH_ROLES_EDIT]: [
    PC.ROLES_WRITE,
    PC.ROLE_LIUBANCHYK_SUPER_ADMIN,
  ],
  [GC.ROUTE_PATH_ROLES_LIST]: [
    PC.ROLES_READ,
    PC.ROLES_WRITE,
    PC.ROLE_LIUBANCHYK_SUPER_ADMIN,
  ],
  // branch
  [GC.ROUTE_PATH_BRANCH_NEW]: [PC.BRANCH_WRITE],
  [GC.ROUTE_PATH_BRANCH_EDIT]: [PC.BRANCH_WRITE],
  [GC.ROUTE_PATH_BRANCH_DETAIL]: [PC.BRANCH_WRITE],
  [GC.ROUTE_PATH_BRANCH_LIST]: [PC.BRANCH_READ, PC.BRANCH_WRITE],
  // configs
  [GC.ROUTE_PATH_CONFIG_UI]: [PC.CONFIGURATIONS_WRITE],
  [GC.ROUTE_PATH_CONFIG_TEL]: [PC.CONFIGURATIONS_WRITE],
  [GC.ROUTE_PATH_CONFIG_CLO]: [PC.CONFIGURATIONS_WRITE],
  [GC.ROUTE_PATH_CONFIG_TRUCK]: [PC.CONFIGURATIONS_WRITE],
  [GC.ROUTE_PATH_CONFIG_AUDIT]: [PC.CONFIGURATIONS_WRITE],
  [GC.ROUTE_PATH_CONFIG_RATING]: [PC.CONFIGURATIONS_WRITE],
  [GC.ROUTE_PATH_CONFIG_DRIVER]: [PC.CONFIGURATIONS_WRITE],
  [GC.ROUTE_PATH_CONFIG_CARRIER]: [PC.CONFIGURATIONS_WRITE],
  [GC.ROUTE_PATH_CONFIG_TRAILER]: [PC.CONFIGURATIONS_WRITE],
  [GC.ROUTE_PATH_CONFIG_GENERAL]: [PC.CONFIGURATIONS_WRITE],
  [GC.ROUTE_PATH_CONFIG_PASSWORD]: [PC.CONFIGURATIONS_WRITE],
  [GC.ROUTE_PATH_CONFIG_INVOICES]: [PC.CONFIGURATIONS_WRITE],
  [GC.ROUTE_PATH_CONFIG_TEMPLATES]: [PC.CONFIGURATIONS_WRITE],
  [GC.ROUTE_PATH_CONFIG_INTEGRATION]: [PC.CONFIGURATIONS_WRITE],
  [GC.ROUTE_PATH_CONFIG_FLEET_GENERAL]: [PC.CONFIGURATIONS_WRITE],
  [GC.ROUTE_PATH_CONFIG_COMMUNICATION]: [PC.CONFIGURATIONS_WRITE],
  [GC.ROUTE_PATH_CONFIG_SERVICE_VENDOR]: [PC.CONFIGURATIONS_WRITE],
  [GC.ROUTE_PATH_CONFIG_TASK_MANAGEMENT]: [PC.CONFIGURATIONS_WRITE],
  [GC.ROUTE_PATH_CONFIG_CLAIM_MANAGEMENT]: [PC.CONFIGURATIONS_WRITE],
  // item
  [GC.ROUTE_PATH_ITEMS_LIST]: [PC.ITEM_TEMPLATE_WRITE],
  // location
  [GC.ROUTE_PATH_LOCATIONS_LIST]: [PC.LOCATION_TEMPLATE_WRITE],
  // sequence
  [GC.ROUTE_PATH_SEQUENCES_LIST]: [PC.CONFIGURATIONS_READ, PC.CONFIGURATIONS_WRITE],
  // reference
  [GC.ROUTE_PATH_REFERENCES_LIST]: [PC.REFERENCE_TYPE_WRITE],
  // fleet driver
  [GC.ROUTE_PATH_FLEET_DRIVERS_EDIT]: [PC.FLEET_DRIVER_WRITE],
  [GC.ROUTE_PATH_FLEET_DRIVERS_LIST]: [PC.FLEET_DRIVER_READ, PC.FLEET_DRIVER_WRITE],
  [GC.ROUTE_PATH_FLEET_DRIVER_PAYROLL_ACCESSORIAL_LIST]: [PC.FLEET_DRIVER_READ, PC.FLEET_DRIVER_WRITE],
  // fleet truck
  [GC.ROUTE_PATH_FLEET_TRUCKS_EDIT]: [PC.FLEET_TRUCK_WRITE],
  [GC.ROUTE_PATH_FLEET_TRUCKS_LIST]: [PC.FLEET_TRUCK_READ, PC.FLEET_TRUCK_WRITE],
  // fleet trailer
  [GC.ROUTE_PATH_FLEET_TRAILERS_EDIT]: [PC.FLEET_TRAILER_WRITE],
  [GC.ROUTE_PATH_FLEET_TRAILERS_LIST]: [PC.FLEET_TRAILER_READ, PC.FLEET_TRAILER_WRITE],
  // fleet vendor
  [GC.ROUTE_PATH_FLEET_VENDOR_EDIT]: [PC.FLEET_VENDOR_WRITE],
  [GC.ROUTE_PATH_FLEET_VENDOR_LIST]: [PC.FLEET_VENDOR_READ, PC.FLEET_VENDOR_WRITE],
  [GC.ROUTE_PATH_FLEET_VENDOR_PAYROLL_ACCESSORIAL_LIST]: [PC.FLEET_VENDOR_READ, PC.FLEET_VENDOR_WRITE],
  // fleet-profile
  [GC.ROUTE_PATH_FLEET_PROFILE_TRUCK]: [PC.FLEET_TRUCK_WRITE],
  [GC.ROUTE_PATH_FLEET_PROFILE_DRIVER]: [PC.FLEET_DRIVER_WRITE],
  [GC.ROUTE_PATH_FLEET_PROFILE_VENDOR]: [PC.FLEET_VENDOR_WRITE],
  [GC.ROUTE_PATH_FLEET_PROFILE_TRAILER]: [PC.FLEET_TRAILER_WRITE],
  // TODO: check permissions
  // fleet shared component
  [GC.ROUTE_PATH_FLEET_SHARED_COMPONENT_LIST]: [],
  // fleet equipment service
  [GC.ROUTE_PATH_FLEET_EQUIPMENT_SERVICE_LIST]: [
    PC.FLEET_TRUCK_READ,
    PC.FLEET_TRUCK_WRITE,
    PC.FLEET_TRAILER_READ,
    PC.FLEET_TRAILER_WRITE,
  ],
  // fleet issue
  [GC.ROUTE_PATH_FLEET_SERVICE_ISSUES_LIST]: [
    PC.FLEET_TRUCK_READ,
    PC.FLEET_TRUCK_WRITE,
    PC.FLEET_TRAILER_READ,
    PC.FLEET_TRAILER_WRITE,
  ],
  [GC.ROUTE_PATH_GEO_FENCING_LOCATION]: [
    PC.FLEET_TRAILER_READ, PC.FLEET_TRAILER_WRITE,
  ],
  // Inspection template
  [GC.ROUTE_PATH_TEMPLATES_INSPECTION]: [
    // TODO: change inspection permissions later on
    PC.ROLE_LIUBANCHYK_SUPER_ADMIN,
    // PC.INSPECTION_TEMPLATE_READ,
    // PC.INSPECTION_TEMPLATE_WRITE,
  ],
  // carrier
  [GC.ROUTE_PATH_CARRIER_EDIT]: [PC.CARRIER_WRITE],
  [GC.ROUTE_PATH_CARRIER_PROFILE]: [PC.CARRIER_WRITE],
  [GC.ROUTE_PATH_CARRIERS_LIST]: [PC.CARRIER_READ, PC.CARRIER_WRITE],
  // loads
  [GC.ROUTE_PATH_DO_NEW]: [PC.CLO_WRITE],
  [GC.ROUTE_PATH_LITE_DO_NEW]: [PC.CLO_WRITE],
  [GC.ROUTE_PATH_LOAD_PLANNER]: [PC.TEL_WRITE],
  [GC.ROUTE_PATH_ROUTE_BUILDER]: [PC.TEL_WRITE],
  [GC.ROUTE_PATH_CLO]: [PC.CLO_READ, PC.CLO_WRITE],
  [GC.ROUTE_PATH_TEL]: [PC.TEL_READ, PC.TEL_WRITE],
  [GC.ROUTE_PATH_ROUTE_LOAD]: [PC.TEL_READ, PC.TEL_WRITE],
  [GC.ROUTE_PATH_ROUTE_ORDER]: [PC.CLO_READ, PC.CLO_WRITE],
  [GC.ROUTE_PATH_ROUTES_LIST]: DISPATCH_BOARD_PERMISSIONS,
  [GC.ROUTE_PATH_CLO_REPORT_LIST]: [PC.CLO_READ, PC.CLO_WRITE],
  [GC.ROUTE_PATH_TEL_REPORT_LIST]: [PC.TEL_READ, PC.TEL_WRITE],
  [GC.ROUTE_PATH_EDI_LIST]: [PC.CLO_EDI_READ, PC.CLO_EDI_WRITE],
  [GC.ROUTE_PATH_DISPATCH_BOARD_TEL]: DISPATCH_BOARD_PERMISSIONS,
  [GC.ROUTE_PATH_DISPATCH_BOARD_CLO]: DISPATCH_BOARD_PERMISSIONS,
  // public clo
  [GC.ROUTE_PATH_PUBLIC_CLO_LIST]: [PC.PUBLIC_API_ORDER_READ, PC.PUBLIC_API_ORDER_WRITE],
  // TODO: check permissions
  [GC.ROUTE_PATH_ROUTE_REPORT_LIST]: [],
  // invoice
  [GC.ROUTE_PATH_CUSTOMER_INVOICES_LIST]: [
    PC.CLO_INVOICE_READ,
    PC.CLO_INVOICE_WRITE,
    PC.CLO_INVOICE_OVERWRITE_EXECUTE,
  ],
  [GC.ROUTE_PATH_CARRIER_INVOICES_LIST]: [
    PC.TEL_CARRIER_INVOICE_READ,
    PC.TEL_CARRIER_INVOICE_WRITE,
    PC.TEL_INVOICE_OVERWRITE_EXECUTE,
  ],
  [GC.ROUTE_PATH_DRIVER_INVOICES_LIST]: [
    PC.TEL_FLEET_INVOICE_READ,
    PC.TEL_FLEET_INVOICE_WRITE,
    PC.TEL_INVOICE_OVERWRITE_EXECUTE,
  ],
  [GC.ROUTE_PATH_SERVICE_VENDOR_INVOICE_LIST]: [
    PC.SERVICE_VENDOR_READ,
    PC.SERVICE_VENDOR_WRITE,
  ],
  [GC.ROUTE_PATH_VENDOR_INVOICES_LIST]: [
    PC.TEL_FLEET_INVOICE_READ,
    PC.TEL_FLEET_INVOICE_WRITE,
    PC.TEL_INVOICE_OVERWRITE_EXECUTE,
  ],
  [GC.ROUTE_PATH_DRIVER_PAYROLL_LIST]: [
    PC.DRIVER_PAYROLL_READ,
    PC.DRIVER_PAYROLL_WRITE,
    PC.DRIVER_PAYROLL_OVERWRITE_EXECUTE,
  ],
  [GC.ROUTE_PATH_CUSTOMER_MASTER_INVOICE_LIST]: [
    PC.CLO_MASTER_INVOICE_READ,
    PC.CLO_MASTER_INVOICE_WRITE,
    PC.CLO_MASTER_INVOICE_OVERWRITE_EXECUTE,
  ],
  // analytic
  [GC.ROUTE_PATH_ANALYTICS]: [PC.DASHBOARD_READ, PC.DASHBOARD_WRITE],
  [GC.ROUTE_PATH_FLEET_MAP]: [PC.DASHBOARD_READ, PC.DASHBOARD_WRITE],
  [GC.ROUTE_PATH_DASHBOARDS]: [PC.DASHBOARD_READ, PC.DASHBOARD_WRITE],
  [GC.ROUTE_PATH_DASHBOARDS_PAYROLL]: [PC.DASHBOARD_READ, PC.DASHBOARD_WRITE],
  // chart
  [GC.ROUTE_PATH_CHARTS_NEW]: [PC.CHART_WRITE],
  [GC.ROUTE_PATH_CHARTS_EDIT]: [PC.CHART_WRITE],
  [GC.ROUTE_PATH_CHARTS_LIST]: [PC.CHART_READ, PC.CHART_WRITE],
  // load-board
  [GC.ROUTE_PATH_STYLING]: [PC.STYLING_EXECUTE],
  [GC.ROUTE_PATH_LOAD_BOARD]: [PC.EXTERNAL_LOAD_BOARD_EXECUTE],
  // report
  [GC.ROUTE_PATH_REPORTS_EDIT]: [PC.REPORT_WRITE],
  [GC.ROUTE_PATH_REPORTS_LIST]: [PC.REPORT_WRITE],
  [GC.ROUTE_PATH_REPORTS_ROUTE_EDIT]: [PC.REPORT_WRITE],
  // import
  [GC.ROUTE_PATH_IMPORT]: [PC.IMPORT_EXECUTE],
  // fuel cards
  [GC.ROUTE_PATH_FUEL_CARDS]: [PC.FLEET_FUEL_CARD_READ, PC.FLEET_FUEL_CARD_WRITE],
  // templates
  [GC.ROUTE_PATH_TEMPLATES_ROUTE_LIST]: [PC.TEL_WRITE],
  [GC.ROUTE_PATH_TEMPLATES_DO_LIST]: [PC.CLO_READ, PC.CLO_WRITE],
  [GC.ROUTE_PATH_TEMPLATE_DO_DETAILS]: [PC.CLO_READ, PC.CLO_WRITE],
  [GC.ROUTE_PATH_LITE_TEMPLATE_DO_DETAILS]: [PC.CLO_READ, PC.CLO_WRITE],
  [GC.ROUTE_PATH_CONTAINER_TYPE_LIST]: [PC.ROLE_LIUBANCHYK_SUPER_ADMIN],
  [GC.ROUTE_PATH_TEMPLATES_COMPENSATION_LIST]: [PC.COMPENSATION_TEMPLATE_WRITE],
  // TODO: check
  [GC.ROUTE_PATH_TEMPLATE_CONTAINER_LIST]: [PC.CONTAINER_TEMPLATE_WRITE],
  // load-planner-template
  [GC.ROUTE_PATH_LOAD_PLANNER_TEMPLATE_LIST]: [PC.TEL_WRITE],
  // contact-book
  [GC.ROUTE_PATH_CONTACT_BOOK]: [PC.CONTACT_BOOK_WRITE],
  // TODO: add permissions
  // shared-accessorials
  [GC.ROUTE_PATH_SHARED_ACCESSORIALS]: [],
  // TODO: add permissions
  // average-fuel-price
  [GC.ROUTE_PATH_AVERAGE_FUEL_PRICE]: [],
  // TODO: check permissions
  // carrier contracts
  [GC.ROUTE_PATH_CARRIER_CONTRACT_DETAILS]: [PC.CARRIER_WRITE],
  [GC.ROUTE_PATH_CARRIER_CONTRACTS]: [PC.CARRIER_READ, PC.CARRIER_WRITE],
  // customer contracts
  [GC.ROUTE_PATH_CUSTOMER_CONTRACT_DETAILS]: [PC.CARRIER_WRITE],
  [GC.ROUTE_PATH_CUSTOMER_CONTRACTS]: [PC.CARRIER_READ, PC.CARRIER_WRITE],
  // quotes
  [GC.ROUTE_PATH_QUOTE]: [PC.CLO_READ, PC.CLO_WRITE],
  // quotes
  [GC.ROUTE_PATH_ORDER_QUOTES]: [PC.CLO_READ, PC.CLO_WRITE],
  // rating geo fencing zone
  [GC.ROUTE_PATH_GEO_FENCING_ZONE]: [],
  // report geo fencing zone
  [GC.ROUTE_PATH_GEO_FENCING_ZONE_REPORT]: [],
  // drivers card
  [GC.ROUTE_PATH_DRIVERS_CARD]: [PC.FLEET_DRIVER_READ, PC.FLEET_DRIVER_WRITE],
  // toll charges
  [GC.ROUTE_PATH_TOLL_CHARGES]: [PC.TOLL_CHARGE_READ, PC.TOLL_CHARGE_WRITE],
  // ifta report
  [GC.ROUTE_PATH_IFTA_REPORT]: [PC.IFTA_EXECUTE],
  // TODO: check permissions
  [GC.ROUTE_PATH_NORMALIZE_CURRENCY_LIST]: [],
  // TODO: check permissions
  // carrier assignment
  [GC.ROUTE_PATH_COMMISSION_ASSIGNMENT]: [],
  // carrier edi list
  [GC.ROUTE_PATH_CARRIER_EDI_LIST]: [PC.CARRIER_EDI_READ, PC.CARRIER_EDI_WRITE],
  // carrier rating report
  [GC.ROUTE_PATH_CARRIER_RATING_REPORT]: [], // TODO: clarify
  // customer portal
  [GC.ROUTE_PATH_CUSTOMER_PORTAL]: [PC.CLO_READ, PC.CLO_WRITE],
  // import report
  [GC.ROUTE_PATH_IMPORT_REPORT]: [PC.IMPORT_EXECUTE],
  // inspections
  [GC.ROUTE_PATH_INSPECTIONS]: [],
  // charge report
  [GC.ROUTE_PATH_CLO_RATE_CHARGE_REPORT]: [PC.CLO_RATE_READ, PC.CLO_RATE_WRITE],
  [GC.ROUTE_PATH_TEL_RATE_CHARGE_REPORT]: [
    PC.FLEET_RATE_READ, PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_READ, PC.CARRIER_RATE_WRITE,
  ],
  // order quote
  [GC.ROUTE_PATH_DO_QUOTE]: [PC.ORDER_QUOTE_WRITE],
  // service vendor
  [GC.VISIT_SERVICE_VENDOR_EDIT_PAGE]: [PC.SERVICE_VENDOR_WRITE],
  [GC.VISIT_SERVICE_VENDOR_LIST_PAGE]: [PC.SERVICE_VENDOR_READ, PC.SERVICE_VENDOR_WRITE],
  // rate shop
  [GC.VISIT_RATE_SHOP_PAGE]: [PC.RATE_SHOP_EXECUTE],
  // route mass create report
  [GC.ROUTE_PATH_ROUTE_MASS_CREATE_REPORT]: [PC.TEL_WRITE],
  // los subscription
  [GC.ROUTE_PATH_LOS_SUBCRIPTION_LIST]: [PC.ROLE_LIUBANCHYK_SUPER_ADMIN],
  // TODO: check permissions
  // task management
  [GC.ROUTE_PATH_TASK_MANAGEMENT_BOARD]: [],
  [GC.ROUTE_PATH_TASK_MANAGEMENT_REPORT]: [],
  // available driver
  [GC.ROUTE_PATH_AVAILABLE_DRIVER_LIST]: [PC.AVAILABLE_DRIVER_LIST_READ, PC.AVAILABLE_DRIVER_LIST_WRITE],
  // oauth-2 popup
  [GC.ROUTE_PATH_OAUTH2_POPUP]: [],
  // claim management
  [GC.ROUTE_PATH_CLAIM_MANAGEMENT_LIST]: [PC.CLAIM_READ, PC.CLAIM_WRITE],
  [GC.ROUTE_PATH_CLAIM_MANAGEMENT_CLAIM_DETAILS]: [PC.CLAIM_READ, PC.CLAIM_WRITE],
  // work order
  [GC.ROUTE_PATH_WORK_ORDER_LIST]: [
    PC.FLEET_TRUCK_READ,
    PC.FLEET_TRUCK_WRITE,
    PC.FLEET_TRAILER_READ,
    PC.FLEET_TRAILER_WRITE,
  ],
};

export default RoutePermission;
