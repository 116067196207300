import * as R from 'ramda';
import React from 'react';
// components
import { Table } from '../../../components/table';
import { TextComponent } from '../../../components/text';
import { FormGroupTitleMultiple } from '../../../components/form-group-title-multiple';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// feature rate
import { PulseButton } from '../ui';
import { availableRatesAndCarriersTableSettings } from '../settings';
//////////////////////////////////////////////////

export const availableCarrierReport = {
  fields: [
    {
      width: 150,
      name: GC.FIELD_TOTAL,
    },
    {
      width: 200,
      name: GC.FIELD_CARRIER_CONTRACT_NAME,
    },
    {
      width: 200,
      name: GC.FIELD_CUSTOMER_CONTRACT_NAME,
    },
    {
      width: 150,
      name: GC.FIELD_TRANSPORTING_CARRIER,
    },
    {
      width: 200,
      name: GC.FIELD_NAME,
    },
    {
      width: 150,
      name: GC.FIELD_MODE,
    },
    {
      width: 250,
      name: GC.FIELD_CARRIER_SERVICE_TYPES,
    },
    {
      width: 250,
      name: GC.FIELD_CARRIER_EQUIPMENTS,
    },
    {
      width: 250,
      name: GC.FIELD_TRANSIT_DAYS,
    },
    {
      width: 150,
      name: GC.FIELD_CARRIER_MC_NUMBER,
    },
    {
      width: 150,
      name: GC.FIELD_CARRIER_USDOT_NUMBER,
    },
    {
      width: 150,
      name: GC.FIELD_CARRIER_SCAC,
    },
  ],
};

const CustomTotalComponent = ({ entity }: Object) => {
  const total = R.pathOr('', ['data', GC.SYSTEM_OBJECT_NORMALIZED_TOTAL, GC.FIELD_TOTAL], entity);
  const currency = R.pathOr('', ['data', GC.SYSTEM_OBJECT_NORMALIZED_TOTAL, GC.FIELD_CURRENCY], entity);

  if (G.isNotNilAndNotEmpty(total)) {
    return (
      <Box
        p='4px 5px'
        fontWeight='bold'
        borderRadius='3px'
        width='max-content'
        bg={G.getTheme('common.linkColor')}
        color={G.getTheme('common.whiteTextColor')}
      >
        {G.getCurrencySymbol(currency)}{G.mathRoundNumber(total)} {currency}
      </Box>
    );
  }

  return null;
};

export const availableRatesColumnSettings = {
  [GC.FIELD_TOTAL]: {
    width: 150,
    name: 'titles:total',
    customComponent: (entity: Object) => (
      <CustomTotalComponent entity={entity} />
    ),
  },
  [GC.FIELD_CARRIER_CONTRACT_NAME]: {
    width: 200,
    name: 'titles:contract-name',
  },
  [GC.FIELD_CUSTOMER_CONTRACT_NAME]: {
    width: 200,
    name: 'titles:customer-contract-name',
  },
  [GC.FIELD_NAME]: {
    width: 200,
    name: 'titles:carrier-name',
  },
  [GC.FIELD_MODE]: {
    width: 150,
    name: 'titles:mode',
    customComponent: (entity: Object) => R.path(
      ['data', GC.FIELD_PRICE, GC.FIELD_MODE, GC.FIELD_DISPLAYED_VALUE],
      entity,
    ),
  },
  [GC.FIELD_CARRIER_EQUIPMENTS]: {
    width: 250,
    name: 'titles:equipment',
    customComponent: ({ data }: Object) => {
      const equipments = R.path([GC.FIELD_PRICE, GC.FIELD_CARRIER_EQUIPMENTS], data);

      if (G.isNilOrEmpty(equipments)) return '';

      const text = R.compose(
        R.join(', '),
        R.map(R.prop(GC.FIELD_DISPLAYED_VALUE)),
      )(equipments);

      return (
        <TextComponent title={text} display='block' maxWidth='100%' withEllipsis={true}>
          {text}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_CARRIER_SERVICE_TYPES]: {
    width: 250,
    name: 'titles:service-type',
    customComponent: ({ data }: Object) => {
      const serviceTypes = R.path([GC.FIELD_PRICE, GC.FIELD_CARRIER_SERVICE_TYPES], data);

      if (G.isNilOrEmpty(serviceTypes)) return '';

      const text = R.compose(
        R.join(', '),
        R.map(R.prop(GC.FIELD_DISPLAYED_VALUE)),
      )(serviceTypes);

      return (
        <TextComponent title={text} display='block' maxWidth='100%' withEllipsis={true}>
          {text}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_CARRIER_MC_NUMBER]: {
    width: 150,
    name: 'titles:mc-number',
  },
  [GC.FIELD_CARRIER_USDOT_NUMBER]: {
    width: 150,
    name: 'titles:usdot-number',
  },
  [GC.FIELD_CARRIER_SCAC]: {
    width: 150,
    name: 'titles:scac',
  },
  [GC.FIELD_TRANSPORTING_CARRIER]: {
    width: 150,
    name: 'titles:transporting-carrier',
    customComponent: ({ data }: Object) => R.path([GC.FIELD_TRANSPORTING_CARRIER, GC.FIELD_NAME], data),
  },
  [GC.FIELD_TRANSIT_DAYS]: {
    width: 150,
    name: 'titles:transit-days',
    customComponent: R.pathOr('', ['data', GC.FIELD_PRICE, GC.FIELD_TRANSIT_DAYS]),
  },
};

export const renderTable = (props: Object) => {
  const {
    loading,
    rateInfo,
    onSelectRate,
    availableRates } = props;

  const transportingCarrierFieldIndex = R.findIndex(
    R.propEq(GC.FIELD_TRANSPORTING_CARRIER, [GC.FIELD_NAME]),
    availableCarrierReport.fields,
  );
  const report = G.ifElse(
    G.isFalse(G.showTransportingCarrier()),
    R.dissocPath(['fields', transportingCarrierFieldIndex], availableCarrierReport),
    availableCarrierReport,
  );

  const data = {
    report,
    loading,
    allChecked: false,
    hasSelectable: true,
    totalCount: availableRates.length,
    pagination: { limit: 10, offset: 0 },
    columnSettings: availableRatesColumnSettings,
    tableSettings: availableRatesAndCarriersTableSettings,
    itemList: R.map(R.assoc('selected', false), availableRates),
    onOptionClick: (guid: string, item: Object) => onSelectRate(item, rateInfo),
  };

  return <Table {...data} />;
};

const AvailableRates = (props: Object) => (
  <Box>
    <FormGroupTitleMultiple
      mb='0'
      jc='space-between'
      showArrowToggle={false}
      title={G.getWindowLocale('titles:available-carriers', 'Available Carriers')}
    >
      <Flex>
        {
          G.isNotNilAndNotEmpty(R.path(['rateInfo', 'ratingIntegrationErrors'], props)) &&
          <Box
            mx='10px'
            p='3px 15px'
            fontSize='11px'
            cursor='pointer'
            fontWeight='bold'
            borderRadius='2px'
            bg={G.getTheme('colors.white')}
            color={G.getTheme('colors.light.darkRed')}
            onClick={props.handleShowIntegrationErrors}
          >
            <PulseButton animationDuration={props.animationDuration}>
              {G.getWindowLocale('actions:show-integration-errors', 'Show Integration Errors')}
            </PulseButton>
          </Box>
        }
        <Box
          mx='10px'
          p='3px 10px'
          fontSize='11px'
          cursor='pointer'
          fontWeight='bold'
          borderRadius='2px'
          onClick={props.closeModal}
          bg={G.getTheme('colors.white')}
          color={G.getTheme('colors.light.darkRed')}
        >
          {G.getWindowLocale('actions:close', 'Close')}
        </Box>
      </Flex>
    </FormGroupTitleMultiple>
    {renderTable(props)}
  </Box>
);

export default AvailableRates;
