import styled from 'styled-components';
//////////////////////////////////////////////////

export const HoverWrapper = styled.div`
  margin-right: 10px;
  transition: all 0.8s;
  &:hover {
      cursor: pointer;
      width: max-content;
      border-radius: 30px;
      background-color: ${({ bg }: Object) => bg}
  }
`;
