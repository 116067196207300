// import types
export const IMPORT_TYPE_CLO = 'CLO';
export const IMPORT_TYPE_TRUCK = 'TRUCK';
export const IMPORT_TYPE_DRIVER = 'DRIVER';
export const IMPORT_TYPE_CARRIER = 'CARRIER';
export const IMPORT_TYPE_TRAILER = 'TRAILER';
export const IMPORT_TYPE_LOCATION = 'LOCATION';
export const IMPORT_TYPE_BRANCH = 'ENTERPRISE';
export const IMPORT_TYPE_FUEL_CARDS = 'FUEL_CARDS';
export const IMPORT_TYPE_TOLL_CHARGE = 'TOLL_CHARGE';
export const IMPORT_TYPE_CONTACT_BOOK = 'CONTACT_BOOK';
export const IMPORT_TYPE_CARRIER_INVOICE = 'CARRIER_INVOICE';
export const IMPORT_TYPE_GEO_FENCING_ZONE = 'GEOFENCING_ZONE';
export const IMPORT_TYPE_CARRIER_INSURANCE = 'CARRIER_INSURANCE';
export const IMPORT_TYPE_CARRIER_RATE_PRICE = 'CARRIER_RATE_PRICE';
export const IMPORT_TYPE_GEOFENCING_LOCATION = 'GEOFENCING_LOCATION';
export const IMPORT_TYPE_FUEL_CARD_TRANSACTION = 'FUEL_CARD_TRANSACTION';
export const IMPORT_TYPE_CARRIER_PAY_TO_LOCATION = 'CARRIER_PAY_TO_LOCATION';

// carrier rate price
export const FIELD_CARRIER_RATE_PRICE_ORIGIN_ZONE_NAME = 'originZoneName';
export const FIELD_CARRIER_RATE_PRICE_DESTINATION_ZONE_NAME = 'destinationZoneName';

// toll charges
export const transactionTypeOptions = [
  {
    value: 'TOLL',
    label: 'Toll',
  },
  {
    value: 'VIDEO_TOLL',
    label: 'Video Toll',
  },
];

// fuel card transaction
export const productTypeOptions = [
  {
    value: 'FUEL',
    label: 'Fuel',
  },
  {
    value: 'NON_FUEL',
    label: 'Non Fuel',
  },
  {
    value: 'REEFER',
    label: 'Reefer',
  },
];

export const agencyOptions = [
  {
    value: 'ILTOLL',
    label: 'ILTOLL',
  },
  {
    value: 'DELDOT',
    label: 'DELDOT',
  },
  {
    value: 'ITRCC',
    label: 'ITRCC',
  },
  {
    value: 'MDTA',
    label: 'MDTA',
  },
  {
    value: 'NJTP',
    label: 'NJTP',
  },
  {
    value: 'OTIC',
    label: 'OTIC',
  },
  {
    value: 'PTC',
    label: 'PTC',
  },
  {
    value: 'SCC',
    label: 'SCC',
  },
  {
    value: 'WVPEDTA',
    label: 'WVPEDTA',
  },
];

export const carrierPayTypeOptions = [
  {
    value: 'DIRECT_PAY',
    label: 'Direct Pay',
  },
  {
    value: 'FACTORING_COMPANY',
    label: 'Factoring Company',
  },
];

// CLO ORIGIN
export const FIELD_IMPORT_TYPE_CLO_ORIGIN_ZIP = 'originZip';
export const FIELD_IMPORT_TYPE_CLO_ORIGIN_CITY = 'originCity';
export const FIELD_IMPORT_TYPE_CLO_ORIGIN_STATE = 'originState';
export const FIELD_IMPORT_TYPE_CLO_ORIGIN_COUNTRY = 'originCountry';
export const FIELD_IMPORT_TYPE_CLO_ORIGIN_LATITUDE = 'originLatitude';
export const FIELD_IMPORT_TYPE_CLO_ORIGIN_ADDRESS1 = 'originAddress1';
export const FIELD_IMPORT_TYPE_CLO_ORIGIN_LONGITUDE = 'originLongitude';

export const ORIGIN_GROUPED_FIELDS = [
  FIELD_IMPORT_TYPE_CLO_ORIGIN_ADDRESS1,
  FIELD_IMPORT_TYPE_CLO_ORIGIN_CITY,
  FIELD_IMPORT_TYPE_CLO_ORIGIN_STATE,
  FIELD_IMPORT_TYPE_CLO_ORIGIN_ZIP,
];

// CLO DESTINATION
export const FIELD_IMPORT_TYPE_CLO_DESTINATION_ZIP = 'destinationZip';
export const FIELD_IMPORT_TYPE_CLO_DESTINATION_CITY = 'destinationCity';
export const FIELD_IMPORT_TYPE_CLO_DESTINATION_STATE = 'destinationState';
export const FIELD_IMPORT_TYPE_CLO_DESTINATION_COUNTRY = 'destinationCountry';
export const FIELD_IMPORT_TYPE_CLO_DESTINATION_LATITUDE = 'destinationLatitude';
export const FIELD_IMPORT_TYPE_CLO_DESTINATION_ADDRESS1 = 'destinationAddress1';
export const FIELD_IMPORT_TYPE_CLO_DESTINATION_LONGITUDE = 'destinationLongitude';

export const DESTINATION_GROUPED_FIELDS = [
  FIELD_IMPORT_TYPE_CLO_DESTINATION_ADDRESS1,
  FIELD_IMPORT_TYPE_CLO_DESTINATION_CITY,
  FIELD_IMPORT_TYPE_CLO_DESTINATION_STATE,
  FIELD_IMPORT_TYPE_CLO_DESTINATION_ZIP,
];
