import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { TextComponent } from '../../../../components/text';
// features
import { makeSelectBranchesTree } from '../../../branch/selectors';
import { makeSelectPinnedBranches } from '../../../profile/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex } from '../../../../ui';
// feature navbar-menu
import { BranchDropDownWrapper } from './ui';
//////////////////////////////////////////////////

const getPinnedOptions = (tree: Object, pinnedList: Array) => {
  let branches = [];

  const collect = (branch: Object) => {
    const guid = G.getGuidFromObject(branch);

    if (G.notEquals(pinnedList.indexOf(branch.guid), -1)) {
      branches = R.append({
        guid,
        [GC.FIELD_BRANCH_NAME]: branch[GC.FIELD_BRANCH_NAME],
      }, branches);
    }

    if (G.isNotNilAndNotEmpty(branch.children)) {
      R.forEach((child: Object) => collect(child), branch.children);
    }
  };

  collect(tree);

  return branches;
};

const entOptions = (props: Object) => {
  const {
    branches,
    branchTree,
    pinnedBranches,
    handleOpenEntTree,
    handleSwitchBranch,
  } = props;

  const pinnedOptions = getPinnedOptions(branchTree, pinnedBranches);

  return R.compose(
    R.prepend({
      label: 'wordWide',
      text: branches[GC.FIELD_BRANCH_NAME],
      action: () => handleSwitchBranch(branches.guid),
    }),
    R.append({
      label: 'globalSearch2',
      action: handleOpenEntTree,
      text: G.getWindowLocale('titles:browse-all', 'Browse all...'),
    }),
    R.map((branch: Object) => ({
      label: 'company',
      text: branch[GC.FIELD_BRANCH_NAME],
      action: () => handleSwitchBranch(G.getGuidFromObject(branch)),
    })),
  )(pinnedOptions);
};

export const BranchesDropdownComponent = (props: Object) => (
  <BranchDropDownWrapper color={props.iconsColor}>
    {
      entOptions(R.omit(['iconsColor'], props)).map((option: Object, i: number) => (
        <Flex key={i} onClick={option.action}>
          {
            G.isNotNilAndNotEmpty(option.label)
            && <Box m='0 10px 0 5px'>{I[option.label](props.iconsColor)}</Box>
          }
          <TextComponent
            display='block'
            maxWidth='100%'
            title={option.text}
            withEllipsis={true}
          >
            {option.text}
          </TextComponent>
        </Flex>
      ))
    }
  </BranchDropDownWrapper>
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  branchTree: makeSelectBranchesTree(state),
  pinnedBranches: makeSelectPinnedBranches(state),
}));

export default connect(mapStateToProps, {})(BranchesDropdownComponent);
