import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

const validationSchemaReferenceObject = {
  [GC.FIELD_PRIMARY]: Yup.boolean()
    .nullable(true)
    .notRequired(),
  [GC.FIELD_REFERENCE_TYPE_GUID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_VALUE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(1, G.getShouldBeFromToLocaleTxt(1, 250))
    .max(250, G.getShouldBeFromToLocaleTxt(1, 250)),
};


const validationSchemaReferences = {
  [GC.FIELD_LOAD_REFERENCES]: Yup.array()
    .of(Yup.object(validationSchemaReferenceObject))
    .nullable(true)
    .notRequired(),
};

export const validationSchema = Yup.object()
  .shape(validationSchemaReferences);

export const defaultValues = {
  [GC.FIELD_LOAD_REFERENCES]: null,
};

export const referenceDefaultFields = {
  [GC.FIELD_VALUE]: null,
  [GC.FIELD_PRIMARY]: false,
  [GC.FIELD_REFERENCE_TYPE_GUID]: null,
};

export const getNewReferenceFields = (primaryObjectGuid: string) => R.mergeRight(
  referenceDefaultFields,
  { primaryObjectGuid, uniq: G.generateGuid() },
);

const commonProps = {
  width: 180,
  isRequired: true,
  inputWrapperStyles: {
    mr: '20px',
  },
};

const primaryCheckBoxHandler = (e: Object, item: Object, props: Object) => {
  const { values, itemIndex, initialValues, setFieldValue, useSingleReference } = props;

  const isPrimary = R.path([GC.FIELD_REFERENCES, itemIndex, GC.FIELD_PRIMARY], values);
  const isInitialPrimary = R.path([GC.FIELD_REFERENCES, 0, GC.FIELD_PRIMARY], initialValues);
  const cond = R.or(
    R.and(isInitialPrimary, useSingleReference),
    R.and(isPrimary, R.not(useSingleReference)),
  );

  if (cond) return;

  setFieldValue(
    GC.FIELD_REFERENCES,
    G.mapIndexed((ref: Object, index: number) => {
      if (G.notEquals(index, itemIndex)) return R.assoc(GC.FIELD_PRIMARY, false, ref);

      const value = R.not(R.path([GC.FIELD_REFERENCES, itemIndex, GC.FIELD_PRIMARY], values));

      return R.assoc(GC.FIELD_PRIMARY, value, ref);
    }, R.prop(GC.FIELD_REFERENCES, values)),
  );
};

export const getFields = ({
  index,
  disabled,
  isInvoiceReference,
  isFromCurrentScopeOrNewValue,
}: Object) => [
  [
    {
      ...commonProps,
      disabled,
      options: 'referenceTypes',
      useMenuPortalTarget: true,
      label: G.getWindowLocale('titles:name', 'Name'),
      type: G.ifElse(isFromCurrentScopeOrNewValue, 'reactSelect', 'text'),
      fieldName: G.ifElse(isFromCurrentScopeOrNewValue, GC.FIELD_REFERENCE_TYPE_GUID, GC.FIELD_NAME),
    },
    {
      ...commonProps,
      type: 'text',
      fieldName: GC.FIELD_VALUE,
      disabled: R.or(disabled, isInvoiceReference),
      label: G.getWindowLocale('titles:value', 'Value'),
    },
    {
      type: 'checkbox',
      shouldCustomChange: true,
      fieldName: GC.FIELD_PRIMARY,
      customChangeHandler: primaryCheckBoxHandler,
      label: G.getWindowLocale('titles:primary', 'Primary!'),
    },
  ],
  [
    {
      ...commonProps,
      disabled,
      type: 'reactSelect',
      options: 'referenceTypes',
      useMenuPortalTarget: true,
      fieldName: GC.FIELD_REFERENCE_TYPE_GUID,
      label: G.getWindowLocale('titles:name', 'Name'),
    },
    {
      ...commonProps,
      disabled,
      type: 'reactSelect',
      options: 'allowedValues',
      useMenuPortalTarget: true,
      fieldName: GC.FIELD_VALUE,
      label: G.getWindowLocale('titles:value', 'Value'),
    },
    {
      type: 'checkbox',
      shouldCustomChange: true,
      fieldName: GC.FIELD_PRIMARY,
      customChangeHandler: primaryCheckBoxHandler,
      label: G.getWindowLocale('titles:primary', 'Primary!'),
    },
  ],
][index];
