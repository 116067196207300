/* eslint-disable no-undef */
import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { PlacesAutocomplete } from '../../../../ui';
//////////////////////////////////////////////////

class PlaceAutocomplete extends React.Component {
  handleBlur = () => {
    const { id, setFieldTouched } = this.props;

    setFieldTouched(id, true);
  };

  handleChange = (value: string) => {
    const { id, setFieldValue } = this.props;

    setFieldValue(id, value);
  };

  handleSelect = async (value: string) => {
    const {
      id,
      values,
      setValues,
      omitAddress1,
      customSelectLocationFunction,
    } = this.props;

    const result = await G.geocodeByPlaceAddress(value, 'new-do -> Fieldset');

    const data = {
      ...result,
      label: id,
      [GC.FIELD_LOAD_BOARD_ORIGIN_CITY]: G.getPropFromObject('city', result), // TODO: check why it is here
    };

    const newValues = G.ifElse(
      G.isTrue(omitAddress1),
      R.omit(GC.FIELD_ADDRESS_1, data),
      data,
    );

    if (G.isFunction(customSelectLocationFunction)) {
      return customSelectLocationFunction(newValues);
    }

    setValues(R.mergeRight(R.or(values, {}), newValues), id);
  };

  render() {
    return (
      <PlacesAutocomplete
        id={this.props.id}
        width={this.props.width}
        value={this.props.value}
        handleBlur={this.handleBlur}
        handleSelect={this.handleSelect}
        placeholder={this.props.placeholder}
        setFieldValue={this.props.setFieldValue}
        useMenuPortalTarget={this.props.useMenuPortalTarget}
      />
    );
  }
}

export default PlaceAutocomplete;
