import React from 'react';
// components
import { Fieldset2 } from '../../../forms/formik';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature payroll
import { payrollInfoFieldsetSettings } from '../settings/payroll-with-charges-settings';
//////////////////////////////////////////////////

const setOptionsForSelect = ({ asyncConfigs }: Object) => ({
  [GC.FIELD_GL_CODE]: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    asyncConfigs,
    GC.INVOICE_GL_CODE,
    true,
  ),
});

const PaymentInfo = (props: Object) => {
  const { glDisabled } = props;

  const fieldsWrapperStyles = { p: 15, mt: 15, width: '100%' };

  return (
    <Fieldset2
      {...G.getFormikProps(props)}
      {...setOptionsForSelect(props)}
      fields={payrollInfoFieldsetSettings}
      fieldsWrapperStyles={fieldsWrapperStyles}
      handlers={{
        handleDisableGlCode: () => glDisabled,
      }}
    />
  );
};

export default PaymentInfo;
