import * as R from 'ramda';
import { createLogic } from 'redux-logic';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature dispatch-detail
import {
  createTelRateActionRequest,
  updateTelRateActionRequest,
  createTelCarrierRateActionRequest,
  updateTelCarrierRateActionRequest } from './actions';
//////////////////////////////////////////////////

const isInvalidTelCarrierRate = (values: Object) => {
  const { carrierRateCharges } = values;
  const carrierMainChargeTotal = G.getChargeTotalFromCharges(
    carrierRateCharges,
    GC.CHARGE_TYPE_MAIN,
  );
  if (G.isNilOrEmpty(carrierMainChargeTotal)) {
    G.showToastrMessageSimple(
      'info',
      G.getWindowLocale('messages:provide-main-change-for-carrier', 'Please, provide Main Charge'),
    );
    return true;
  }
  return false;
};

const handleCreateTelRateRequest = createLogic({
  type: createTelRateActionRequest,
  validate({ getState, action }: Object, allow: void, reject: void) {
    const values = action.payload;
    const { secondaryDriverGuid } = values;
    if (G.isInvalidDriverRate(values)) return reject();
    if (R.isNil(secondaryDriverGuid)) {
      return allow(createTelRateActionRequest(R.omit(GC.FIELD_SECONDARY_DRIVER_CHARGES, values)));
    }
    allow(action);
  },
});

const handleUpdateTelRateRequest = createLogic({
  type: updateTelRateActionRequest,
  validate({ getState, action }: Object, allow: void, reject: void) {
    const values = action.payload;
    const { secondaryDriverGuid } = values;
    if (G.isInvalidDriverRate(values)) return reject();
    if (R.isNil(secondaryDriverGuid)) {
      return allow(updateTelRateActionRequest(R.omit(GC.FIELD_SECONDARY_DRIVER_CHARGES, values)));
    }
    allow(action);
  },
});

const handleCreateCarrierTelRateRequest = createLogic({
  type: [
    createTelCarrierRateActionRequest,
    updateTelCarrierRateActionRequest,
  ],
  validate({ getState, action }: Object, allow: void, reject: void) {
    if (isInvalidTelCarrierRate(action.payload)) return reject();
    allow(action);
  },
});

export default [
  handleCreateTelRateRequest,
  handleUpdateTelRateRequest,
  handleCreateCarrierTelRateRequest,
];
