import * as R from 'ramda';
import React, { useMemo, useState } from 'react';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box, Flex, StickedBox, scrollableContainerCss3px } from '../../ui';
// feature notifications2
import { getGroupedByDate } from './helpers';
import NotificationGroup from './components/notification-group';
//////////////////////////////////////////////////

const Notification2 = (props: Object) => {
  const {
    title,
    readAction,
    hideAction,
    withoutGroup,
    notifications,
    closeFixedPopup,
  } = props;

  const [notificationList, setNotificationList] = useState(notifications);

  const notificationItemHeight = 65;
  const notificationTitleGroupHeight = 24;

  const groupedList = useMemo(() => getGroupedByDate(notificationList), [notificationList]);

  const groupedListHeight = R.add(
    R.multiply(R.length(notifications), notificationItemHeight),
    R.multiply(R.length(groupedList), notificationTitleGroupHeight),
  );

  const handleMarkAsReadNotification = (props: Object) => {
    const newNotificationList = R.map((item: Object) => {
      if (R.equals(item.guid, props.guid)) {
        return R.assoc('read', true, item);
      }

      return item;
    }, notificationList);

    readAction(props);
    setNotificationList(newNotificationList);
  };

  const handleHideAction = () => {
    hideAction();
    closeFixedPopup();
  };

  return (
    <Flex
      bg='white'
      width='100%'
      maxHeight={396}
      overflowY='auto'
      height='max-content'
      flexDirection='column'
      alignItems='flex-start'
      css={scrollableContainerCss3px}
    >
      <StickedBox
        p={15}
        top={0}
        bg='white'
        zIndex={2}
        width='100%'
      >
        <Flex justifyContent='space-between'>
          <Box>{G.getWindowLocale('titles:notifications', 'Notifications')}</Box>
          <Box
            cursor='pointer'
            onClick={handleHideAction}
            color={G.getTheme('colors.light.blue')}
          >
            {title}
          </Box>
        </Flex>
      </StickedBox>
      <NotificationGroup
        groupedList={groupedList}
        withoutGroup={withoutGroup}
        groupedListHeight={groupedListHeight}
        notificationItemHeight={notificationItemHeight}
        markAsReadNotification={handleMarkAsReadNotification}
        notificationTitleGroupHeight={notificationTitleGroupHeight}
      />
    </Flex >
  );
};

export default Notification2;
