import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
// feature new-do-quote
import {
  PAGE_TYPE_CREATE_QUOTE,
  PAGE_TYPE_UPDATE_QUOTE,
} from './constants';
//////////////////////////////////////////////////

export const isPageCreateQuote = (page: string) => R.equals(page, PAGE_TYPE_CREATE_QUOTE);

export const isPageUpdateQuote = (page: string) => R.equals(page, PAGE_TYPE_UPDATE_QUOTE);

export const renderLeftRightEmptyInfo = (props: Object) => {
  const { branchGuid } = props;

  return G.isNilOrEmpty(branchGuid);
};

export const renderLeftRightContent = (props: Object) => {
  const { branchGuid } = props;

  return G.isNotNilAndNotEmpty(branchGuid);
};
