import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import { getGuidFromObject } from '../../helpers';
// report-common
import { getReportSelectors } from '../../report-common';
//////////////////////////////////////////////////

const {
  store,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} = getReportSelectors('rateShop');

const makeSelectItemList = () => createSelector(
  store,
  ({ itemList }: Object) => R.sortBy(R.prop('index'), R.values(itemList)),
);

const makeSelectSelectedListGuids = () => createSelector(
  store,
  ({ itemList }: Object) => R.compose(
    R.map(getGuidFromObject),
    R.filter(R.prop('selected')),
    R.values,
  )(itemList),
);

const makeSelectCarrierGuid = () => createSelector(
  store,
  ({ carrierGuid }: Object) => carrierGuid,
);

export {
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectPageVisited,
  makeSelectListLoading,
  makeSelectCarrierGuid,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
  makeSelectSelectedListGuids,
};
