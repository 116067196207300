import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectMenuStore = (state: Object) => state.navbarMenu;

const makeSelectSidebar = () => createSelector(
  selectMenuStore,
  ({ navbarMenuItems }: Object) => navbarMenuItems,
);

const makeSelectOpenedSidebar = () => createSelector(
  selectMenuStore,
  ({ openedSidebar }: Object) => openedSidebar,
);

const makeSelectDropdownItems = () => createSelector(
  selectMenuStore,
  ({ dropdownItems }: Object) => dropdownItems,
);

const makeSelectSubmenusStatus = () => createSelector(
  selectMenuStore,
  ({ subMenus }: Object) => subMenus,
);


export {
  selectMenuStore,
  makeSelectSidebar,
  makeSelectOpenedSidebar,
  makeSelectDropdownItems,
  makeSelectSubmenusStatus,
};
