import React from 'react';
// features
import ProfilePhoto from '../../../profile/components/profile-photo';
// helpers/constants
import * as G from '../../../../helpers';
//////////////////////////////////////////////////

export default ({ iconsColor, currentUserSettings = {} }: Object) => (
  <ProfilePhoto
    {...G.getUserInfo(currentUserSettings)}
    isAvatar={true}
    iconsColor={iconsColor}
    photoUrl={currentUserSettings.profilePhotoUrl}
    userGuid={G.getGuidFromObject(currentUserSettings)}
  />
);
