import * as R from 'ramda';
import {
  put,
  call,
  select,
  takeLatest,
} from 'redux-saga/effects';
// components
import { closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportSagas } from '../../report-common';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature quotes
import * as A from './actions';
import {
  makeSelectListType,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectPageVisited,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

const endpoints = {
  quotes: {
    list: endpointsMap.quoteList,
    remove: endpointsMap.quoteDelete,
  },
  orderQuotes: {
    remove: endpointsMap.orderQuote,
    list: endpointsMap.orderQuoteList,
  },
};

export function* handleRemoveQuotesRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const listType = yield select(makeSelectListType());

    const endpoint = R.path([listType, 'remove'], endpoints);

    const res = yield call(sendRequest, 'delete', endpoint, { data: payload });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeQuotesSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleRemoveQuotesRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleRemoveQuotesRequestSaga exception');
  }
}

function* handleGetQuoteListRequestSaga({ payload }: boolean) {
  try {
    if (G.isTrue(payload)) yield put(openLoader({ showDimmer: true }));

    const pagination = yield select(makeSelectPagination());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: {
        ...pagination,
        [GC.CURRENT_BRANCH]: currentBranchGuid,
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.quoteList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetQuoteListRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());

    yield call(G.handleException, err, 'handleGetQuoteListRequestSaga exception');
  }
}

function* handleVisitQuoteListPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(A.setInitialState());
    yield put(A.setListType(GC.FIELD_QUOTES));

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_QUOTES_LIST_PAGE);

    yield put(openLoader({ showDimmer: true }));

    yield call(handleGetQuoteListRequestSaga, { payload: true });

    yield put(closeLoader());
    break;
  }
}

// order quote
function* acceptOrderQuoteRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.orderQuoteAccept(payload);

    const res = yield call(sendRequest, 'put', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.changeOrderQuoteStatus({
        [GC.FIELD_GUID]: payload,
        [GC.FIELD_STATUS]: GC.ORDER_QUOTE_STATUS_TYPE_ACCEPTED,
      }));
    } else {
      yield call(G.handleFailResponse, res, 'acceptOrderQuoteRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'acceptOrderQuoteRequest exception');
  }
}

function* declineOrderQuoteRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.orderQuoteDecline(G.getGuidFromObject(payload));

    const options = {
      data: R.dissoc(GC.FIELD_GUID, payload),
    };

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.changeOrderQuoteStatus({
        [GC.FIELD_GUID]: G.getGuidFromObject(payload),
        [GC.FIELD_STATUS]: GC.ORDER_QUOTE_STATUS_TYPE_DECLINED,
      }));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'declineOrderQuoteRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'declineOrderQuoteRequest exception');
  }
}

function* cancelOrderQuoteRequest({ payload }: Object) {
  try {
    const { guid, statusReasonCodeDisplayedValue } = payload;

    yield put(openLoader());

    const endpoint = endpointsMap.orderQuoteCancel(guid);

    const options = {
      data: R.omit([GC.FIELD_GUID, 'statusReasonCodeDisplayedValue'], payload),
    };

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.changeOrderQuoteStatus({
        guid,
        statusReasonCodeDisplayedValue,
        [GC.FIELD_STATUS]: GC.ORDER_QUOTE_STATUS_TYPE_CANCELED,
      }));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'cancelOrderQuoteRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'cancelOrderQuoteRequest exception');
  }
}

function* sendOrderQuoteToCustomerRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'put', endpointsMap.orderQuoteSendToCustomer, { data: payload });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.changeOrderQuoteStatus({
        [GC.FIELD_GUID]: G.getGuidFromObject(payload),
        [GC.FIELD_STATUS]: GC.ORDER_QUOTE_STATUS_TYPE_SENT_TO_CUSTOMER,
      }));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'sendOrderQuoteToCustomerRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'sendOrderQuoteToCustomerRequest exception');
  }
}

// report
function* handleGetItemListSaga({ payload }: Object) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader({ showDimmer: true }));
    }

    yield put(A.setListLoading(true));

    const reportParams = yield select(makeSelectUsedReport());
    const availableReports = yield select(makeSelectAvailableReports());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.equals('itemDefault', G.getGuidFromObject(reportParams)),
    )) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const systemFields = [GC.FIELD_GUID, GC.FIELD_STATUS, GC.FIELD_CREATED_CLO];

    const guids = R.pathOr(null, ['guids'], payload);

    const { limit, offset } = pagination;

    const reqBody = {
      guids,
      limit,
      offset,
      orderFields,
      systemFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const reqData = G.addSystemFieldsToTableReport(
      G.setSearchCriteria({ reqBody, filterParams: newFilterParams }),
      systemFields,
    );

    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.orderQuoteList,
      { data: reqData },
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, guids }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }

    yield put(closeLoader());
    yield put(A.setListLoading(false));
  } catch (err) {
    yield put(closeLoader());
    yield put(A.setListLoading(false));

    yield call(G.handleException, err, 'handleGetItemListSaga exception');
  }
}

function* getXMLRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.getOrderQuoteXmlEndpoint(payload);
    const res = yield call(sendRequest, 'get', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res);
    } else {
      yield call(G.handleFailResponse, res, 'getXmlRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getXmlRequest exception');
  }
}

function* printQuoteRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      data: payload,
      resType: 'arraybuffer',
    };
    const res = yield call(sendRequest, 'post', endpointsMap.orderQuoteExport, options);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res);

      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'printQuoteRequest fail');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'printQuoteRequest exception');
  }
}

const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(
  GC.ORDER_QUOTE_REPORT,
  A,
  handleGetItemListSaga,
  { createUpdateReportSuccessCallback },
);

function* visitOrderQuotesListPage({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader({ showDimmer: true }));

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_ORDER_QUOTES_LIST_PAGE);

    const pageVisited = yield select(makeSelectPageVisited());

    if (G.isTrue(pageVisited)) {
      yield put(A.setInitialStateOmitReport());
    } else {
      yield put(A.setInitialState());
    }

    yield put(A.setReportPending());
    yield put(A.setIgnorePromptStatus(false));
    yield put(A.setListType(GC.FIELD_ORDER_QUOTES));

    yield call(handleAvailableReportsRequest, { payload, notSetUsedReport: pageVisited });
    yield call(handleGetItemListSaga, { payload: { openLoader: true } });

    yield put(closeLoader());
    break;
  }
}

function* quotesWatcherSaga() {
  yield takeLatest(A.getXMLRequest, getXMLRequest);
  yield takeLatest(A.printQuoteRequest, printQuoteRequest);
  yield takeLatest(A.removeQuotesRequest, handleRemoveQuotesRequestSaga);
  yield takeLatest(A.getQuotesListRequest, handleGetQuoteListRequestSaga);
  yield takeLatest(GC.VISIT_QUOTES_LIST_PAGE, handleVisitQuoteListPageSaga);
  // order quote
  yield takeLatest(A.cancelOrderQuoteRequest, cancelOrderQuoteRequest);
  yield takeLatest(A.acceptOrderQuoteRequest, acceptOrderQuoteRequest);
  yield takeLatest(A.declineOrderQuoteRequest, declineOrderQuoteRequest);
  yield takeLatest(GC.VISIT_ORDER_QUOTES_LIST_PAGE, visitOrderQuotesListPage);
  yield takeLatest(A.sendOrderQuoteToCustomerRequest, sendOrderQuoteToCustomerRequest);
  // report
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
}

export default quotesWatcherSaga;
