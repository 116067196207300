import * as R from 'ramda';
import { createLogic } from 'redux-logic';
// features
import { getStopTitle, getDataFromStops, isStopsQuantityLessOrEqualTwo } from '../new-do/helpers';
import {
  isQuoteDropFormValid,
  isValidReferencesForm,
  isQuotePickupFormValid,
  isValidCustomerRateForm,
} from '../new-do/validation';
// helpers/constants
import * as G from '../../helpers';
// feature new-do-quote
import * as A from './actions';
//////////////////////////////////////////////////

const handleRemoveStopFromStore = createLogic({
  type: A.removeStopFromStore,
  validate({ action, getState }: Object, allow: void, reject: void) {
    const { payload } = action;

    const { newDOQuote: { stops } } = getState();

    if (isStopsQuantityLessOrEqualTwo(stops)) {
      G.showToastrMessageFromLocale('info', 'messages:min-two-stops-to-create-quote');

      return reject();
    }

    const { firstStop } = getDataFromStops(stops);

    if (R.and(R.equals(payload, 1), G.isStopPickup(firstStop))) {
      G.showToastrMessageFromLocale('info', 'messages:cant-delete-first-pickup');

      return reject();
    }

    allow(action);
  },
});

const getInvalidNames = (invalidName: string, isObjectValid: Object, invalidNames: Array) => {
  if (G.isTrue(isObjectValid)) {
    return invalidNames;
  }

  return R.append(invalidName, invalidNames);
};

const getInvalidFormsMsg = (store: Object) => {
  const { stops, rateBackup, referenceFormData } = store;

  let errorNames = [];
  const { sortedStops } = getDataFromStops(stops);

  const isValidRate = isValidCustomerRateForm(rateBackup);
  const isValidReferences = isValidReferencesForm(referenceFormData);

  errorNames = getInvalidNames('Rate Fields', isValidRate, errorNames);
  errorNames = getInvalidNames('References', isValidReferences, errorNames);

  R.forEach((stop: Object) => {
    const { formData, eventType } = stop;

    const title = getStopTitle(stop);

    const validationFunc = G.ifElse(
      G.isEventTypePickup(eventType),
      isQuotePickupFormValid,
      isQuoteDropFormValid,
    );

    const isValid = validationFunc(formData);

    errorNames = getInvalidNames(title, isValid, errorNames);
  }, sortedStops);

  return errorNames;
};

const handleSendDataToApiRequest = createLogic({
  type: A.sendDataToApiRequest,
  validate({action, getState }: Object, allow: void, reject: void) {
    const { newDOQuote } = getState();

    const errors = getInvalidFormsMsg(newDOQuote);

    if (G.isNotNilAndNotEmpty(errors)) {
      return reject(A.setValidationErrors(errors));
    }

    const { stops } = newDOQuote;

    const { lastStop, firstStop } = getDataFromStops(stops);

    if (G.isStopDrop(firstStop)) {
      G.showToastrMessageFromLocale('info', 'messages:cant-delete-first-pickup');

      return reject();
    }

    if (G.isStopPickup(lastStop)) {
      G.showToastrMessageFromLocale('info', 'messages:last-stop-should-be-drop');

      return reject();
    }

    allow(action);
  },
});

export default [
  handleRemoveStopFromStore,
  handleSendDataToApiRequest,
];
