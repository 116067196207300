import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const getRowOptions = (props: Object) => {
  const currency = G.getCurrencySymbol(props.currency);
  return {
    lineHaul: [
      {
        textProps: { mr: '10px', minWidth: 'fit-content' },
        text: `${G.getWindowLocale('titles:line-haul', 'Line Haul')}:`,
      },
      {
        textProps: { mr: '10px', ml: 'auto', fontWeight: 700, width: 'fit-content' },
        text: G.ifElse(
          G.isNotNilAndNotZero(props.mainChargesTotal),
          `${currency} ${G.toFixed(props.mainChargesTotal)}`,
          '',
        ),
      },
    ],
    fuel: [
      {
        textProps: { mr: '10px', minWidth: 'fit-content' },
        text: `${G.getWindowLocale('titles:fuel', 'Fuel')}:`,
      },
      {
        textProps: { mr: '10px', ml: 'auto', fontWeight: 700, width: 'fit-content' },
        text: G.ifElse(
          G.isNotNilAndNotZero(props.fuelChargesTotal),
          `${currency} ${G.toFixed(props.fuelChargesTotal)}`,
          '',
        ),
      },
    ],
    otherCharges: [
      {
        textProps: { mr: '10px', minWidth: 'fit-content' },
        text: `${G.getWindowLocale('titles:other-charges', 'Other Charges')}:`,
      },
      {
        text: G.ifElse(
          G.isNotNilAndNotZero(props.additionalChargesTotal),
          `${currency} ${G.toFixed(props.additionalChargesTotal)}`,
          '',
        ),
        textProps: { mr: '10px', ml: 'auto', fontWeight: 700, width: 'fit-content' },
      },
    ],
    comments: [
      {
        textProps: { mr: '10px', minWidth: 'fit-content' },
        text: `${G.getWindowLocale('titles:comments', 'Comments')}:`,
      },
      {
        textProps: { mr: '10px', fontWeight: 700, width: 'fit-content' },
        text: G.ifElse(
          G.isNotNilAndNotEmpty(props.comments),
          props.comments,
          '',
        ),
      },
    ],
    invoiceNumber: [
      {
        textProps: { mr: '10px', width: 'calc(45% - 10px)' },
        text: `${G.getWindowLocale('titles:invoice-number', 'Invoice #')}:`,
      },
      {
        text: props.invoiceNumber,
        textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
      },
    ],
    status: [
      {
        textProps: { mr: '10px', width: 'calc(45% - 10px)' },
        text: `${G.getWindowLocale('titles:status', 'Status')}:`,
      },
      {
        text: props.status,
        textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
      },
    ],
    tel: [
      {
        text: `${G.getWindowLocale('titles:tel', 'TEL')}:`,
        textProps: { mr: '10px', width: 'calc(45% - 10px)' },
      },
      {
        text: props.telPrimaryReferenceValue,
        textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
      },
    ],
    clo: [
      {
        text: `${G.getWindowLocale('titles:clo', 'CLO')}:`,
        textProps: { mr: '10px', width: 'calc(45% - 10px)' },
      },
      {
        text: props.cloPrimaryReferenceValue,
        textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
      },
    ],
    invoiceDate: [
      {
        text: `${G.getWindowLocale('titles:date', 'Date')}:`,
        textProps: { mr: '10px', width: 'calc(45% - 10px)' },
      },
      {
        text: props.invoiceDate,
        textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
      },
    ],
    paymentDueDate: [
      {
        textProps: { mr: '10px', width: 'calc(45% - 10px)' },
        text: `${G.getWindowLocale('titles:due-date', 'Due Date')}:`,
      },
      {
        text: props.paymentDueDate,
        textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
      },
    ],
    [GC.FIELD_GL_CODE]: [
      {
        textProps: { mr: '10px', width: 'calc(45% - 10px)' },
        text: `${G.getWindowLocale('titles:gl-code', 'GL Code')}:`,
      },
      {
        text: R.path([GC.FIELD_GL_CODE, GC.FIELD_DISPLAYED_VALUE], props),
        textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
      },
    ],
    transportationMode: [
      {
        text: `${G.getWindowLocale('titles:mode', 'Mode')}:`,
        textProps: { mr: '10px', width: 'calc(45% - 10px)' },
      },
      {
        text: props.transportationMode,
        textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
      },
    ],
    totalTripDistance: [
      {
        textProps: { mr: '10px', width: 'calc(45% - 10px)' },
        text: `${G.getWindowLocale('titles:distance', 'Distance')}:`,
      },
      {
        text: G.ifElse(
          G.isNotNil(props.totalTripDistance),
          `${G.toFixed(props.totalTripDistance)} ${props.totalTripDistanceUom}`,
          '',
        ),
        textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
      },
    ],
    totalTripWeight: [
      {
        textProps: { mr: '10px', width: 'calc(45% - 10px)' },
        text: `${G.getWindowLocale('titles:weight', 'Weight')}:`,
      },
      {
        text: G.ifElse(
          G.isNotNil(props.totalTripWeight),
          `${G.toFixed(props.totalTripWeight)} ${props.totalTripWeightUom}`,
          '',
        ),
        textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
      },
    ],
  };
};
