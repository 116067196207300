export const TAB_NAME_MAP = 'map';
export const TAB_NAME_BILL_TO = 'billTo';
export const TAB_NAME_PRICING = 'pricing';
export const TAB_NAME_REFERENCE = 'reference';
export const TAB_NAME_CLO_SUMMARY = 'cloSummary';
export const TAB_NAME_TRIP_PRICING = 'tripPricing';
export const TAB_NAME_RATE_CONFIRMATION = 'rateConfirmation';
export const FORM_BOX_SHADOW = '0 2px 5px 0 rgba(0, 0, 0, 0.2)';
// TODO remove after testing
// export const WAITING_PICTURE_SRC = 'https://amous-front-dev.s3.amazonaws.com/amousLogo.png';
export const WAITING_PICTURE_SRC = 'https://amous-front-dev.s3.amazonaws.com/newAmousLogo.svg';
// page types
export const PAGE_TYPE_CREATE_DO = 'CREATE_DO';
export const PAGE_TYPE_LOAD_STOPS_EDIT = 'LOAD_STOPS_EDIT';
export const PAGE_TYPE_CREATE_FROM_EDI = 'CREATE_FROM_EDI';
export const PAGE_TYPE_CREATE_FROM_QUOTE = 'CREATE_FROM_QUOTE';
export const PAGE_TYPE_CLO_TEMPLATE = 'PAGE_TYPE_CLO_TEMPLATE';
export const PAGE_TYPE_CREATE_FROM_PUBLIC_CLO = 'CREATE_FROM_PUBLIC_CLO';
export const PAGE_TYPE_CREATE_FROM_ORDER_QUOTE = 'PAGE_TYPE_CREATE_FROM_ORDER_QUOTE';
