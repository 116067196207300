export const tableSettings = {
  cellFontSize: 13,
  titleFontSize: 12,
  titleRowHeight: 45,
  tableRowHeight: 40,
  allowEditBtn: true,
  useMainColors: true,
  moreBtnCellWidth: 50,
  expandableItems: false,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 90px)',
  checkBoxCellJustifyContent: 'unset',
};
