import * as R from 'ramda';
// components
import { recalculateAllCharges } from '../../components/charge/formik/helpers';
// components
import { getRateFromSharedAccessAndFuelPrices } from '../../components/charge/hocs/helpers';
// features
import { cloRateInitFields } from '../rate/customer/settings';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// feature new-do
import {
  TAB_NAME_PRICING,
  PAGE_TYPE_CREATE_DO,
  PAGE_TYPE_CLO_TEMPLATE,
  PAGE_TYPE_LOAD_STOPS_EDIT,
  PAGE_TYPE_CREATE_FROM_EDI,
  TAB_NAME_RATE_CONFIRMATION,
  PAGE_TYPE_CREATE_FROM_QUOTE,
  PAGE_TYPE_CREATE_FROM_PUBLIC_CLO,
  PAGE_TYPE_CREATE_FROM_ORDER_QUOTE,
} from './constants';
import { locationFieldsToPick, stopInfoFieldsToPick } from './settings/fields-settings';
//////////////////////////////////////////////////

export const isPageCreateDO = (page: string) => R.equals(page, PAGE_TYPE_CREATE_DO);

export const isPageCreateFromEdi = (page: string) => R.equals(page, PAGE_TYPE_CREATE_FROM_EDI);

export const isPageCreateFromPublicClo = (page: string) => R.equals(page, PAGE_TYPE_CREATE_FROM_PUBLIC_CLO);

export const isPageCreateFromOrderQuote = (page: string) => R.equals(page, PAGE_TYPE_CREATE_FROM_ORDER_QUOTE);

export const isPageCreateFromQuote = (page: string) => R.equals(page, PAGE_TYPE_CREATE_FROM_QUOTE);

export const isPageCloTemplate = (page: string) => R.equals(page, PAGE_TYPE_CLO_TEMPLATE);

export const isPageLoadStopsEdit = (page: string) => R.equals(page, PAGE_TYPE_LOAD_STOPS_EDIT);

export const isSourceTypeEdi = (source: string) => R.equals(source, GC.CREATE_DO_SOURCE_TYPE_EDI);

export const isSourceTypeManual = (source: string) => R.equals(source, GC.CREATE_DO_SOURCE_TYPE_MANUAL);

export const isSourceTypeEdiOrManual = (source: string) => R.or(
  isSourceTypeEdi(source),
  isSourceTypeManual(source),
);

export const renderLeftRightEmptyInfo = (props: Object) => {
  const { pageType, branchGuid } = props;
  if (isPageCreateFromQuote(pageType)) return false;
  if (G.isNilOrEmpty(branchGuid)) return true;
  return false;
};

export const renderLeftRightContent = (props: Object) => {
  const { pageType, branchGuid } = props;
  if (isPageCreateFromQuote(pageType)) return true;
  if (G.isNotNilAndNotEmpty(branchGuid)) return true;
  return false;
};

const createPrimaryReferenceDisplayValue = (sequence: Object, configPrefix: string, divisionPrefix: string) => {
  const arrayOfValues = [divisionPrefix, configPrefix, sequence.prefix, sequence.approxValue, sequence.suffix];
  return G.createStringFromArray(arrayOfValues, '');
};

export const createCustomerLoadNumber = ({ configs, sequence, divisionPrefix }: Object) => {
  const autogenerated = G.getConfigValueFromStore(
    GC.CLO_PRIMARY_REFERENCE_AUTOGENERATED, configs,
  );
  if (R.and(G.isTrue(autogenerated), G.isNotNil(sequence))) {
    const configPrefix = G.getConfigValueFromStore(
      GC.CLO_PRIMARY_REFERENCE_PREFIX, configs,
    );
    let divPref = divisionPrefix;
    if (G.isNilOrEmpty(divisionPrefix)) {
      divPref = G.getConfigValueFromStore(
        GC.CLO_PRIMARY_REFERENCE_DIVISION_PREFIX,
        configs,
      );
    }
    return createPrimaryReferenceDisplayValue(sequence, configPrefix, divPref);
  }
  return '';
};

export const getDataFromStops = (stops: Object) => {
  const stopsArray = R.values(stops);
  const sortedStops = R.sortBy(R.prop(GC.FIELD_ORDER), stopsArray);
  const firstStop = R.head(sortedStops);
  const lastStop = R.last(sortedStops);
  const pickups = R.filter((stop: Object) => R.propEq(GC.EVENT_TYPE_PICKUP, GC.FIELD_EVENT_TYPE, stop), stopsArray);
  const drops = R.filter((stop: Object) => R.propEq(GC.EVENT_TYPE_DROP, GC.FIELD_EVENT_TYPE, stop), stopsArray);
  const pickedItems = R.reduce(
    (acc: Array, pickup: Array) => R.concat(acc, R.pathOr([], ['formData', GC.FIELD_LOAD_ITEMS], pickup)),
    [],
    pickups,
  );
  const droppedItems = R.reduce(
    (acc: Array, drop: Array) => R.concat(acc, R.pathOr([], ['formData', GC.FIELD_LOAD_ITEMS], drop)),
    [],
    drops,
  );
  const pickedItemsInternalIds = R.map((item: Object) => R.prop(GC.FIELD_ITEM_INTERNAL_ID, item), pickedItems);
  const droppedItemInternalIds = droppedItems;
  const pickedUpContainers = R.reduce(
    (acc: Array, pickup: Array) => R.concat(acc, R.pathOr([], ['formData', GC.FIELD_STOP_PICKED_UP_CONTAINERS], pickup)),
    [],
    stopsArray,
  );
  const droppedContainers = R.reduce(
    (acc: Array, drop: Array) => R.concat(acc, R.pathOr([], ['formData', GC.FIELD_STOP_DROPPED_CONTAINERS], drop)),
    [],
    stopsArray,
  );
  const pickedUpContainerIds = R.map((item: Object) => R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, item), pickedUpContainers);
  const droppedContainerIds = droppedContainers;
  const stopReferencesArr = R.reduce(
    (acc: Array, stop: Array) =>
       R.concat(acc, R.pathOr([], ['formData', GC.FIELD_REFERENCES], stop)),
    [],
    stopsArray,
  );
  const stopReferences = R.assoc(GC.FIELD_REFERENCES, stopReferencesArr, {});

  return {
    drops,
    pickups,
    lastStop,
    firstStop,
    pickedItems,
    sortedStops,
    droppedItems,
    stopReferences,
    droppedContainers,
    pickedUpContainers,
    droppedContainerIds,
    pickedUpContainerIds,
    pickedItemsInternalIds,
    droppedItemInternalIds,
  };
};

export const getDataFromStopsTaken = (stops: Object, order: number) => {
  const stopsArray = R.values(stops);
  const sortedStops = R.sortBy(R.prop(GC.FIELD_ORDER), stopsArray);
  const takenStops = R.take(order, sortedStops);
  const firstStop = R.head(sortedStops);
  const lastStop = R.last(sortedStops);
  const pickups = R.filter((stop: Object) => R.propEq(GC.EVENT_TYPE_PICKUP, GC.FIELD_EVENT_TYPE, stop), takenStops);
  const drops = R.filter((stop: Object) => R.propEq(GC.EVENT_TYPE_DROP, GC.FIELD_EVENT_TYPE, stop), takenStops);
  const pickedItems = R.reduce(
    (acc: Array, pickup: Array) => R.concat(acc, R.pathOr([], ['formData', GC.FIELD_LOAD_ITEMS], pickup)),
    [], pickups,
  );
  const droppedItems = R.reduce(
    (acc: Array, drop: Array) => R.concat(acc, R.pathOr([], ['formData', GC.FIELD_LOAD_ITEMS], drop)),
    [], drops,
  );
  const pickedItemsInternalIds = R.map((item: Object) => R.prop(GC.FIELD_ITEM_INTERNAL_ID, item), pickedItems);
  const droppedItemInternalIds = droppedItems;
  const pickedUpContainers = R.reduce(
    (acc: Array, pickup: Array) => R.concat(acc, R.pathOr([], ['formData', GC.FIELD_STOP_PICKED_UP_CONTAINERS], pickup)),
    [], takenStops,
  );
  const droppedContainers = R.reduce(
    (acc: Array, drop: Array) => R.concat(acc, R.pathOr([], ['formData', GC.FIELD_STOP_DROPPED_CONTAINERS], drop)),
    [], takenStops,
  );
  const pickedUpContainerIds = R.map((item: Object) => R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, item), pickedUpContainers);
  const droppedContainerIds = droppedContainers;

  return {
    drops,
    pickups,
    lastStop,
    firstStop,
    pickedItems,
    sortedStops,
    droppedItems,
    droppedContainers,
    pickedUpContainers,
    droppedContainerIds,
    pickedUpContainerIds,
    pickedItemsInternalIds,
    droppedItemInternalIds,
  };
};

export const isStopsQuantityLessOrEqualTwo = (stops: Object) => R.compose(
  R.lte(R.__, 2),
  R.length(),
  R.values(),
)(stops);

const getItemWeightInfo = (item: Object) => G.createStringFromArray(
  R.values(R.pick([GC.FIELD_ITEM_WEIGHT, GC.FIELD_ITEM_WEIGHT_TYPE], item)),
);

const getItemPackageInfo = (item: Object) => G.createStringFromArray(
  R.values(R.pick([GC.FIELD_ITEM_QUANTITY, GC.FIELD_ITEM_PACKAGE_TYPE], item)),
);

const getItemLabel = (item: Object) => {
  const id = G.ifElse(
    G.isNilOrEmpty(R.prop(GC.FIELD_ITEM_ID, item)),
    R.prop(GC.FIELD_ITEM_INTERNAL_ID, item),
    R.prop(GC.FIELD_ITEM_ID, item),
  );
  const infoArray = [getItemWeightInfo(item), getItemPackageInfo(item)];
  return `${id} (${G.createStringFromArray(infoArray, ', ')})`;
};

export const getItemsForDropOptions = (stops: Object, stopItems: Object) => {
  const { pickedItems, droppedItemInternalIds } = getDataFromStops(stops);
  const itemsForDrop = R.reject(
    (item: Object) => R.and(
      G.notContain(R.prop(GC.FIELD_ITEM_INTERNAL_ID, item), stopItems),
      R.includes(R.prop(GC.FIELD_ITEM_INTERNAL_ID, item), droppedItemInternalIds),
    ),
    pickedItems,
  );
  return R.compose(
    R.map((item: Object) => (
      {
        label: getItemLabel(item),
        value: R.prop(GC.FIELD_ITEM_INTERNAL_ID, item),
      }
    )),
    R.reject((item: Object) => G.isNilOrEmpty(R.prop(GC.FIELD_ITEM_ID, item))),
  )(itemsForDrop);
};

const getContainerLabel = (item: Object) => {
  const containerNumber = G.getPropFromObject(GC.FIELD_CONTAINER_NUMBER, item);
  const containerInitial = G.getPropFromObject(GC.FIELD_CONTAINER_INITIAL, item);
  const containerInternalId = G.getPropFromObject(GC.FIELD_CONTAINER_INTERNAL_ID, item);

  if (R.and(G.isNilOrEmpty(containerNumber), G.isNilOrEmpty(containerInitial))) return containerInternalId;

  return G.createStringFromArray([containerInitial, containerNumber], '');
};

export const getContainersForPickupItemOptions = (stops: Object, stop: Object) => {
  const { order } = stop;

  const { pickedUpContainers } = getDataFromStopsTaken(stops, order);

  return R.compose(
    R.map((item: Object) => (
      {
        label: getContainerLabel(item),
        value: R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, item),
      }
    )),
    R.reject((item: Object) => G.isNilOrEmpty(R.prop(GC.FIELD_CONTAINER_NUMBER, item))),
  )(pickedUpContainers);
};

export const getContainersForDropOptions = (stops: Object, stop: Object) => {
  const { order, formData } = stop;

  const { droppedContainerIds } = getDataFromStops(stops);
  const { pickedUpContainers } = getDataFromStopsTaken(stops, order);

  const stopPickedUpContainers = R.path([GC.FIELD_STOP_PICKED_UP_CONTAINERS], formData);
  const stopPickedUpContainerIds = R.compose(
    R.map((item: Object) => R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, item)),
    R.reject((item: Object) => G.isNilOrEmpty(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, item))),
  )(stopPickedUpContainers);
  const stopDroppedContainerIds = R.path([GC.FIELD_STOP_DROPPED_CONTAINERS], formData);

  return R.compose(
    R.map((item: Object) => (
      {
        label: getContainerLabel(item),
        value: R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, item),
      }
    )),
    R.reject((item: Object) => R.and(
      G.notContain(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, item), stopDroppedContainerIds),
      R.includes(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, item), droppedContainerIds)),
    ),
    R.reject((item: Object) => R.includes(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, item), stopPickedUpContainerIds)),
    R.reject((item: Object) => G.isNilOrEmpty(R.prop(GC.FIELD_CONTAINER_NUMBER, item))),
  )(pickedUpContainers);
};

const dropItemsMapper = R.map((item: Object) => item);
const pickupItemsMapper = R.map((item: Object) => R.prop(GC.FIELD_ITEM_INTERNAL_ID, item));

export const getStopItemsInfo = (stop: Object, stopsData: Object) => {
  const { formData: { items }, eventType } = stop;
  const { pickedItems } = stopsData;
  const itemsMapper = G.ifElse(
    G.isEventTypePickup(eventType),
    pickupItemsMapper,
    dropItemsMapper,
  );
  const mappedStopItems = itemsMapper(R.or(items, []));
  const indexedPickedItems = R.compose(
    R.indexBy(R.prop(GC.FIELD_ITEM_INTERNAL_ID)),
    R.reject((item: Object) => G.isNilOrEmpty(R.prop(GC.FIELD_ITEM_INTERNAL_ID, item))),
    R.reject((item: Object) => G.isNilOrEmpty(R.prop(GC.FIELD_ITEM_ID, item))),
  )(pickedItems);
  const itemsToUse = R.values(R.pick(mappedStopItems, indexedPickedItems));
  return G.getStopItemsInfo(itemsToUse);
};

export const getStopItemsInfoFromStop = (stop: Object, stopsData: Object) => {
  const { items, eventType } = stop;
  const { pickedItems } = stopsData;
  const itemsMapper = G.ifElse(
    G.isEventTypePickup(eventType),
    pickupItemsMapper,
    dropItemsMapper,
  );
  const mappedStopItems = itemsMapper(R.or(items, []));
  const indexedPickedItems = R.compose(
    R.indexBy(R.prop(GC.FIELD_ITEM_INTERNAL_ID)),
    R.reject((item: Object) => G.isNilOrEmpty(R.prop(GC.FIELD_ITEM_INTERNAL_ID, item))),
    R.reject((item: Object) => G.isNilOrEmpty(R.prop(GC.FIELD_ITEM_ID, item))),
  )(pickedItems);
  const itemsToUse = R.values(R.pick(mappedStopItems, indexedPickedItems));
  return G.getStopItemsInfo(itemsToUse);
};

export const getStopTitle = (stop: Object, formValues: Object) => {
  const { order, eventType } = stop;
  const stopTypeLocaleMap = {
    [GC.EVENT_TYPE_DROP]: 'titles:drop',
    [GC.EVENT_TYPE_PICKUP]: 'titles:pickup',
  };
  const address = G.concatLocationFields(formValues);
  return `
    ${G.getWindowLocale(stopTypeLocaleMap[eventType], eventType)}:
    ${G.getWindowLocale('titles:stop', 'stop')} ${order}.
    ${address}
  `;
};

export const getStopTitleFromStop = (stop: Object) => {
  const { order, location, eventType } = stop;
  const stopTypeLocaleMap = {
    [GC.EVENT_TYPE_DROP]: 'titles:drop',
    [GC.EVENT_TYPE_PICKUP]: 'titles:pickup',
  };
  const address = G.concatLocationFields(location);
  return `
    ${G.getWindowLocale(stopTypeLocaleMap[eventType], eventType)}:
    ${G.getWindowLocale('titles:stop', 'stop')} ${order}.
    ${address}
  `;
};

export const getStopTitleFromFormData = (stop: Object) => {
  const { order, formData, eventType } = stop;
  const stopTypeLocaleMap = {
    [GC.EVENT_TYPE_DROP]: 'titles:drop',
    [GC.EVENT_TYPE_PICKUP]: 'titles:pickup',
  };
  const address = G.concatLocationFields(formData);
  return `
    ${G.getWindowLocale(stopTypeLocaleMap[eventType], eventType)}:
    ${G.getWindowLocale('titles:stop', 'stop')} ${order}.
    ${address}
  `;
};

export const getStopIconColor = (isValid: boolean) => G.getTheme(G.ifElse(
  G.isTrue(isValid),
  'createDO.loadStepHeaderLeftIcon.svgsSuccessColor',
  'createDO.loadStepHeaderLeftIcon.svgsColor',
));

export const getStopLocationIcon = (stop: Object) => {
  const { isValid, eventType } = stop;
  const color = getStopIconColor(isValid);
  if (R.equals(eventType, GC.EVENT_TYPE_PICKUP)) {
    return I.pickupStopIcon(null, null, null, color);
  } else if (R.equals(eventType, GC.EVENT_TYPE_DROP)) {
    return I.dropStopIcon(null, null, null, color);
  }
};

export const getStopLocationIconSimple = (stop: Object) => {
  const { eventType } = stop;
  const color = G.getTheme('createDO.loadStepHeaderLeftIcon.svgsSuccessColor');
  if (R.equals(eventType, GC.EVENT_TYPE_PICKUP)) {
    return I.pickupStopIcon(null, null, null, color);
  } else if (R.equals(eventType, GC.EVENT_TYPE_DROP)) {
    return I.dropStopIcon(null, null, null, color);
  }
};

export const getStopDistanceInfo = (manual: string, distance: Object) => {
  if (G.isNotNil(manual)) {
    return `
      ${R.pathOr('', [GC.FIELD_DISTANCE_MANUAL], distance)}
      ${R.pathOr('', [GC.FIELD_DISTANCE_MANUAL_UOM], distance)}
    `;
  }
  return `
    ${R.pathOr('', [GC.FIELD_DISTANCE_SYSTEM], distance)}
    ${R.pathOr('', [GC.FIELD_DISTANCE_SYSTEM_UOM], distance)}
  `;
};

export const getStopHeaderActionOptions = (props: Object) => ([
  {
    Icon: I.globalSearch2,
    action: props.handleSearchLocation,
    title: G.getWindowLocale('titles:search-location', 'Search Location'),
  },
  {
    Icon: I.clean,
    action: () => props.handleCleanForm(props.stopOrder),
    title: G.getWindowLocale('titles:clean-form', 'Clean Form'),
  },
  {
    Icon: I.trash,
    title: G.getWindowLocale('titles:remove-stop', 'Remove Stop'),
    action: () => props.removeStopFromStore(props.stopOrder),
  },
]);

export const getBillToHeaderActionOptions = (props: Object) => ([
  {
    Icon: I.globalSearch2,
    action: props.handleSearchLocation,
    title: G.getWindowLocale('titles:search-location', 'Search Location'),
  },
  {
    Icon: I.clean,
    action: props.handleCleanForm,
    title: G.getWindowLocale('titles:clean-form', 'Clean Form'),
  },
]);

const keysMap = {
  distance: GC.FIELD_DISTANCE_SYSTEM,
  unit: GC.FIELD_DISTANCE_SYSTEM_UOM,
};

const emptyDistance = {
  [GC.FIELD_DISTANCE_SYSTEM]: null,
  [GC.FIELD_DISTANCE_SYSTEM_UOM]: null,
};

export const mapStopsWithDistances = (loadType: string, sortedStops: Array, distanceRes: Object) => {
  const distancesArr = R.compose(
    R.append(emptyDistance),
    R.map((item: Object) => G.renameKeys(keysMap, item)),
    R.map((item: Object) => R.pick(['distance', 'unit'], item)),
    R.prop('stopResults'),
  )(distanceRes);

  const zippedStops = R.zipWith((stop: Object, distance: Object) => {
    const distanceToNextStop = R.prop(G.getDistancePropByLoadType(loadType), stop);
    const newDistanceToNextStop = R.mergeRight(distanceToNextStop, distance);

    return R.assoc(G.getDistancePropByLoadType(loadType), newDistanceToNextStop, stop);
  }, sortedStops, distancesArr);

  return R.indexBy(R.prop(GC.FIELD_ORDER), zippedStops);
};

export const getLoadStopsGeodata = R.reduce((acc: Array, stop: Object) => {
  if (R.isNil(acc)) return null;

  const latitude = R.pathOr(null, ['formData', GC.FIELD_LATITUDE], stop);
  const longitude = R.pathOr(null, ['formData', GC.FIELD_LONGITUDE], stop);
  const city = R.pathOr(null, ['formData', GC.FIELD_CITY], stop);
  const state = R.pathOr(null, ['formData', GC.FIELD_STATE], stop);

  const cityState = { city, state };

  return G.ifElse(
    R.and(G.isNotNil(latitude), G.isNotNil(longitude)),
    R.append({ ...cityState, latitude, longitude }, acc),
    G.ifElse(
      R.and(G.isNotNil(city), G.isNotNil(state)),
      R.append(cityState, acc),
      null,
    ),
  );
}, []);

export const getStopPointsLatLonStringsArray = R.map((stop: Object) => {
  const latitude = G.getPropFromObject(GC.FIELD_LATITUDE, stop);
  const longitude = G.getPropFromObject(GC.FIELD_LONGITUDE, stop);

  if (R.or(G.isNilOrEmpty(latitude), G.isNilOrEmpty(longitude))) return null;

  return `${latitude},${longitude}`;
});

const getIndexesFromDnDResult = (result: Object) => {
  const sourceIndex = R.path(['source', 'index'], result);
  const destinationIndex = R.path(['destination', 'index'], result);
  const destinationIndexChecked = G.ifElse(
    R.equals(destinationIndex, -1),
    0,
    destinationIndex,
  );
  return {
    sourceIndex,
    destinationIndexChecked,
  };
};

export const reorderStopsOnDrugEnd = (sortedStops: Object, result: Object) => {
  const { sourceIndex, destinationIndexChecked } = getIndexesFromDnDResult(result);
  const stops = R.clone(sortedStops);
  const [removedEvent] = stops.splice(sourceIndex, 1);
  stops.splice(destinationIndexChecked, 0, removedEvent);
  return R.compose(
    R.indexBy(R.prop(GC.FIELD_ORDER)),
    G.mapIndexed((stop: Object, index: number) => R.assoc(GC.FIELD_ORDER, R.inc(index), stop)),
  )(stops);
};

export const mapStopOrderFieldToProp = (loadType: string, stops: Array) => R.map(
  (item: Object) => R.assoc(
    G.getEventIndexPropByLoadType(loadType),
    R.path([GC.FIELD_ORDER], item),
    item,
  ),
  stops,
);

export const makeReqBillTo = (billTo: Object) => {
  const locationWithTemplId = G.resetTemplateIdToLocation(billTo);

  return G.resetLocationTypeToLocation(locationWithTemplId);
};

export const makeLocation = (location: Object) => {
  const contacts = R.compose(
    R.filter(R.compose(
      G.isOneNotNilOrNotEmpty,
      R.values,
      R.pick([GC.FIELD_FAX, GC.FIELD_PHONE, GC.FIELD_EMAIL, GC.FIELD_CONTACT_NAME, GC.FIELD_PHONE_EXTENSION]),
    )),
    R.pathOr([], [GC.FIELD_CONTACTS]),
  )(location);

  const locationWithValidContacts = R.assoc(GC.FIELD_CONTACTS, contacts, location);

  return G.resetLocationTypeToLocation(G.resetTemplateIdToLocation(locationWithValidContacts));
};

export const makeLocationForTemplate = (location: Object, branchConfigs: Object) => {
  const validLocation = makeLocation(location);

  const options = G.getFullOptionsFromDropdownConfigStore(branchConfigs, GC.TEMPLATES_LOCATION_TYPE);

  const indexedOptions = G.indexMappedDropdownOptionsByField(
    GC.FIELD_DROPDOWN_OPTION_GUID,
    G.mapDropdownConfigOptionsToObjects,
    options,
  );

  return R.assoc(
    GC.FIELD_LOCATION_TYPE,
    G.getPropFromObject(G.getPropFromObject(GC.FIELD_LOCATION_TYPE, validLocation), indexedOptions),
    validLocation,
  );
};

const makeItemIds = (eventType: Object, items: Array) => {
  if (G.isEventTypePickup(eventType)) {
    return R.map(R.prop(GC.FIELD_ITEM_INTERNAL_ID), items);
  }
  return items;
};

const makeContainerIds = (formData: Object, pickedUpContainers: Array = []) => {
  let droppedContainerIds = R.reject(G.isNilOrEmpty, R.prop(GC.FIELD_STOP_DROPPED_CONTAINERS, formData));

  if (G.isFirstItemObject(droppedContainerIds)) {
    droppedContainerIds = R.map(
      R.prop(GC.FIELD_CONTAINER_INTERNAL_ID),
      droppedContainerIds,
    );
  }

  const orderPickedUpContainers = R.indexBy(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID), pickedUpContainers);
  droppedContainerIds = R.filter(
    (id: string) => G.isNotNilAndNotEmpty(R.prop(id, orderPickedUpContainers)),
  )(droppedContainerIds);
  const pickedUpContainerIds = R.map(
    R.prop(GC.FIELD_CONTAINER_INTERNAL_ID),
    R.prop(GC.FIELD_STOP_PICKED_UP_CONTAINERS, formData),
  );

  return { droppedContainerIds, pickedUpContainerIds };
};

const makeDroppedContainers = (formData: Object, pickedContainers: Array) => {
  const formDataDroppedContainers = R.prop(GC.FIELD_STOP_DROPPED_CONTAINERS, formData);

  if (G.isFirstItemObject(formDataDroppedContainers)) return formDataDroppedContainers;

  const indexed = R.indexBy(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID), pickedContainers);

  return R.map(
    (id: string) => R.prop(id, indexed),
    formDataDroppedContainers,
  );
};

const makeRequestContainers = (formData: Object, picked: Array, isEdit: boolean = false, loadInfo: Object = {}) => {
  const formDataPickedUpContainers = R.prop(GC.FIELD_STOP_PICKED_UP_CONTAINERS, formData);
  let formDataDroppedContainers = R.prop(GC.FIELD_STOP_DROPPED_CONTAINERS, formData);

  if (G.isFirstItemObject(formDataDroppedContainers)) {
    formDataDroppedContainers = R.map(
      R.prop(GC.FIELD_CONTAINER_INTERNAL_ID),
      formDataDroppedContainers,
    );
  }

  const omitArray = G.ifElse(isEdit, [], GC.GROUPED_FIELDS.SYSTEM_OMIT_FULL_ARR);
  const pickedUpContainers = R.map(
    (container: Object) => R.mergeRight(R.omit(omitArray, container), loadInfo),
    formDataPickedUpContainers,
  );
  const droppedContainers = R.compose(
    R.map((container: Object) => R.mergeRight(container, loadInfo)),
    R.filter((item: Object) => R.includes(
      R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, item),
      formDataDroppedContainers,
    )),
    R.map(R.omit(omitArray)),
  )(picked);

  return { droppedContainers, pickedUpContainers };
};

export const mapReferencesWithRefTypes = (references: Array, branchRefTypes: Object) => {
  if (R.or(G.isNilOrEmpty(references), G.isNilOrEmpty(branchRefTypes))) return [];
  const indexedTypes = R.indexBy(R.prop(GC.FIELD_GUID), R.prop(GC.LOAD_TYPE_CLO, branchRefTypes));
  return R.compose(
    R.filter(({ value, referenceTypeGuid }: Object) => R.and(
      G.isNotNilAndNotEmpty(value),
      G.isNotNilAndNotEmpty(referenceTypeGuid),
    )),
    R.map((item: Object) => R.assoc(
      GC.FIELD_NAME,
      R.path([R.prop(GC.FIELD_REFERENCE_TYPE_GUID, item), GC.FIELD_NAME], indexedTypes),
      item,
    )),
  )(references);
};

export const makeReqReferences = (store: Object) => {
  const {
    branchConfigs,
    divisionPrefix,
    branchRefTypes,
    primaryReference,
    referenceFormData,
    primaryRefSequence,
    customerLoadNumber,
    customerReferenceValue,
  } = store;

  const references = mapReferencesWithRefTypes(R.prop(GC.FIELD_LOAD_REFERENCES, referenceFormData), branchRefTypes);

  const primaryRefSequenceValue = createCustomerLoadNumber({
    divisionPrefix,
    configs: branchConfigs,
    sequence: primaryRefSequence,
  });

  const primaryValue = G.ifElse(
    G.notEquals(customerLoadNumber, primaryRefSequenceValue),
    customerLoadNumber,
    null,
  );

  const primaryReferenceTypeGuidConfig = G.getConfigValueFromStore(
    GC.CLO_PRIMARY_REFERENCE_TYPE,
    branchConfigs,
  );

  const primaryReferenceTypeGuid = G.ifElse(
    G.isNotNilAndNotEmpty(primaryReferenceTypeGuidConfig),
    primaryReferenceTypeGuidConfig,
    R.path([GC.FIELD_VALUE], primaryReference),
  );

  const primary = {
    primary: true,
    value: primaryValue,
    [GC.FIELD_REFERENCE_TYPE_GUID]: G.ifElse(
      G.isNotNilAndNotEmpty(primaryRefSequenceValue),
      primaryReferenceTypeGuid,
      R.propOr(null, GC.FIELD_VALUE, primaryReference),
    ),
  };

  if (G.isNotNilAndNotEmpty(customerReferenceValue)) {
    const customerRefTypeGuid = G.getConfigValueFromStore(
      GC.CLO_GENERAL_CUSTOMER_REFERENCE_TYPE,
      branchConfigs,
    );

    const customerRef = {
      value: customerReferenceValue,
      [GC.FIELD_REFERENCE_TYPE_GUID]: customerRefTypeGuid,
    };

    return [
      ...references,
      primary,
      customerRef,
    ];
  }

  return R.append(primary, references);
};

const getMainStopFields = (stop: Object) => {
  const fields = R.pick(stopInfoFieldsToPick, stop);
  const apptEarly = R.path([GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME], fields);
  const apptLate = R.path([GC.FIELD_LOAD_APPOINTMENT_LATE_TIME], fields);
  const apptLateToUse = G.ifElse(
    G.isNilOrEmpty(apptLate),
    apptEarly,
    apptLate,
  );
  const fieldsToUse = R.assoc(GC.FIELD_LOAD_APPOINTMENT_LATE_TIME, apptLateToUse, fields);

  return G.mapObjectEmptyStringFieldsToNull(fieldsToUse);
};

export const makeSingleTelStop = (stopValues: Object, primaryObjectGuid: string) => {
  const stop = G.formatStopDateTimeValues(G.mapObjectEmptyStringFieldsToNull(stopValues));
  const stopFields = getMainStopFields(stop);
  const locationFields = makeLocation(R.pick(locationFieldsToPick, stop));
  const location = R.mergeRight(stop.location, locationFields);
  const fcfs = R.prop(GC.FIELD_LOAD_FCFS, stopFields);
  const eventLateDate = R.prop(GC.FIELD_LOAD_EVENT_LATE_DATE, stopFields);
  const eventLateDateToUse = G.ifElse(G.isFalse(fcfs), null, eventLateDate);

  const stopInfo = R.assoc(
    GC.FIELD_LOAD_EVENT_LATE_DATE,
    eventLateDateToUse,
    stopFields,
  );

  const references = R.compose(
    R.map((ref: Object) => {
      if (R.isNil(G.getGuidFromObject(ref))) {
        const primaryObjectGuidToUse = G.ifElse(
          G.isLoadTypeClo(stop),
          R.prop(GC.FIELD_CLO_GUID, stop),
          primaryObjectGuid,
        );

        return R.assoc(GC.FIELD_PRIMARY_OBJECT_GUID, primaryObjectGuidToUse, ref);
      }

      return ref;
    }),
    R.prop(GC.FIELD_REFERENCES),
  )(stop);

  const data = {
    ...stop,
    location,
    references,
    ...stopInfo,
  };

  return R.omit(locationFieldsToPick, data);
};

// TODO: check with containers logic in load planner and route builder
export const makeStopsForSaveAndUpdate = (
  stops: Object,
  branchRefTypes: Array,
  pickedItems: Array,
  prevEvents: Object,
  pickedUpContainers: Array,
) => R.map(
  (item: Object) => {
    const { order, eventType, formData, cloDistanceToNextStop } = item;

    const eventGuid = R.path([GC.FIELD_GUID], formData);
    const itemIds = makeItemIds(eventType, R.prop(GC.FIELD_LOAD_ITEMS, formData));
    const stopFields = getMainStopFields(formData);

    let location = makeLocation(R.pick(locationFieldsToPick, formData));

    if (G.isNotNil(eventGuid)) {
      location = R.mergeRight(location, R.pick(
        [GC.FIELD_GUID, GC.FIELD_VERSION],
        R.path([eventGuid, GC.FIELD_LOCATION], prevEvents),
      ));
    }

    if (R.pathEq(
      R.prop(GC.FIELD_LOCATION_TYPE, formData),
      [eventGuid, GC.FIELD_LOCATION, GC.FIELD_LOCATION_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID],
      prevEvents,
    )) {
      location = R.assoc(
        GC.FIELD_LOCATION_TYPE,
        R.path([eventGuid, GC.FIELD_LOCATION, GC.FIELD_LOCATION_TYPE], prevEvents),
        location,
      );
    }

    const references = mapReferencesWithRefTypes(R.prop(GC.FIELD_LOAD_REFERENCES, formData), branchRefTypes);

    const fcfs = R.prop(GC.FIELD_LOAD_FCFS, stopFields);
    const eventLateDate = R.prop(GC.FIELD_LOAD_EVENT_LATE_DATE, stopFields);

    const eventLateDateToUse = G.ifElse(
      G.isFalse(fcfs),
      null,
      eventLateDate,
    );

    const stopInfo = R.assoc(GC.FIELD_LOAD_EVENT_LATE_DATE, eventLateDateToUse, stopFields);
    const containerIds = makeContainerIds(formData, pickedUpContainers);
    const droppedContainers = makeDroppedContainers(formData, pickedUpContainers);

    const pickedUpContainersToSave = R.compose(
      R.map((id: string) => R.find(R.propEq(id, GC.FIELD_CONTAINER_INTERNAL_ID), pickedUpContainers)),
      R.prop(GC.FIELD_STOP_PICKED_UP_CONTAINER_IDS),
    )(containerIds);

    let stop = {
      itemIds,
      location,
      ...stopInfo,
      ...containerIds,
      droppedContainers,
      [GC.FIELD_EVENT_TYPE]: eventType,
      [GC.FIELD_CLO_EVENT_INDEX]: order,
      [GC.FIELD_LOAD_REFERENCES]: references,
      [GC.FIELD_DISTANCE_CLO]: cloDistanceToNextStop,
      [GC.FIELD_STOP_PICKED_UP_CONTAINERS]: pickedUpContainersToSave,
    };

    if (G.isNotNil(eventGuid)) {
      stop = R.mergeRight(stop, R.pick([GC.FIELD_GUID, GC.FIELD_VERSION], R.prop(eventGuid, prevEvents)));
    }

    if (G.isEventTypePickup(eventType)) {
      return R.assoc(GC.FIELD_LOAD_ITEMS, R.prop(GC.FIELD_LOAD_ITEMS, formData), stop);
    }

    if (G.isEventTypeDrop(eventType)) {
      const items = R.filter(
        (item: Object) => R.includes(R.prop(GC.FIELD_ITEM_INTERNAL_ID, item), stop.itemIds),
        pickedItems,
      );

      return R.assoc(GC.FIELD_LOAD_ITEMS, items, stop);
    }

    return stop;
  },
  R.values(stops),
);

export const makeReqStops = (
  stops: Object,
  branchRefTypes: Array,
  pickedItems: Array,
  pickedUpContainers: Array,
  forIntegration: boolean = false,
) => R.map(
  (item: Object) => {
    const { order, eventType, formData, cloDistanceToNextStop } = item;

    const formDataToUse = G.formatStopDateTimeValues(G.mapObjectEmptyStringFieldsToNull(formData));

    const { droppedTrailerGuids, pickedUpTrailerGuids } = formDataToUse;

    const internalId = G.getPropFromObject(GC.FIELD_INTERNAL_ID, formData);

    const itemIds = makeItemIds(eventType, R.prop(GC.FIELD_LOAD_ITEMS, formData));

    const containerIds = makeContainerIds(formData, pickedUpContainers);

    const stopFields = getMainStopFields(formDataToUse);

    const location = makeLocation(R.pick(locationFieldsToPick, formDataToUse));

    const references = mapReferencesWithRefTypes(R.prop(GC.FIELD_LOAD_REFERENCES, formData), branchRefTypes);

    const fcfs = R.prop(GC.FIELD_LOAD_FCFS, stopFields);
    const eventLateDate = R.prop(GC.FIELD_LOAD_EVENT_LATE_DATE, stopFields);

    const eventLateDateToUse = G.ifElse(
      G.isFalse(fcfs),
      null,
      eventLateDate,
    );

    const stopInfo = R.assoc(GC.FIELD_LOAD_EVENT_LATE_DATE, eventLateDateToUse, stopFields);

    let stop = {
      itemIds,
      location,
      internalId,
      ...stopInfo,
      ...containerIds,
      droppedTrailerGuids,
      pickedUpTrailerGuids,
      [GC.FIELD_EVENT_TYPE]: eventType,
      [GC.FIELD_CLO_EVENT_INDEX]: order,
      [GC.FIELD_LOAD_REFERENCES]: references,
      [GC.FIELD_DISTANCE_CLO]: cloDistanceToNextStop,
    };

    if (forIntegration) {
      let items = R.pathOr([], [GC.FIELD_LOAD_ITEMS], formData);

      if (G.isStopDrop(item)) {
        items = R.filter(({ itemInternalId }: Object) => R.includes(itemInternalId, items), R.or(pickedItems, []));
      }

      const containers = makeRequestContainers(formData, pickedUpContainers);

      stop = { ...stop, items, ...containers };
    }

    return stop;
  },
  R.values(stops),
);

export const makeReqStopsForTemplate = ({
  stops,
  pickedItems,
  branchConfigs,
  branchRefTypes,
  pickedUpContainers,
}: Object) => R.map(
  (item: Object) => {
    const { order, eventType, formData, cloDistanceToNextStop } = item;

    const formDataToUse = G.formatStopDateTimeValues(G.mapObjectEmptyStringFieldsToNull(formData));

    const { droppedTrailerGuids, pickedUpTrailerGuids } = formDataToUse;

    const internalId = G.getPropFromObject(GC.FIELD_INTERNAL_ID, formData);

    const itemIds = makeItemIds(eventType, R.prop(GC.FIELD_LOAD_ITEMS, formData));

    const containerIds = makeContainerIds(formData, pickedUpContainers);

    const references = mapReferencesWithRefTypes(R.prop(GC.FIELD_LOAD_REFERENCES, formData), branchRefTypes);

    const stopFields = getMainStopFields(formDataToUse);

    const fcfs = R.prop(GC.FIELD_LOAD_FCFS, stopFields);
    const eventLateDate = R.prop(GC.FIELD_LOAD_EVENT_LATE_DATE, stopFields);

    const location = makeLocationForTemplate(R.pick(locationFieldsToPick, formDataToUse), branchConfigs);

    const eventLateDateToUse = G.ifElse(
      G.isFalse(fcfs),
      null,
      eventLateDate,
    );

    const stopInfo = R.assoc(GC.FIELD_LOAD_EVENT_LATE_DATE, eventLateDateToUse, stopFields);

    const stop = {
      itemIds,
      location,
      internalId,
      ...stopInfo,
      ...containerIds,
      droppedTrailerGuids,
      pickedUpTrailerGuids,
      [GC.FIELD_EVENT_TYPE]: eventType,
      [GC.FIELD_CLO_EVENT_INDEX]: order,
      [GC.FIELD_LOAD_REFERENCES]: references,
      [GC.FIELD_DISTANCE_CLO]: cloDistanceToNextStop,
    };

    if (G.isEventTypePickup(eventType)) {
      const items = R.map(
        R.omit(GC.GROUPED_FIELDS.SYSTEM_OMIT_FULL_ARR),
        R.prop(GC.FIELD_LOAD_ITEMS, formData),
      );
      const containers = makeRequestContainers(formData, pickedUpContainers);

      return { ...stop, items, ...containers };
    }

    if (G.isEventTypeDrop(eventType)) {
      const items = R.compose(
        R.filter((item: Object) => R.includes(R.prop(GC.FIELD_ITEM_INTERNAL_ID, item), stop.itemIds)),
        R.map(R.omit(GC.GROUPED_FIELDS.SYSTEM_OMIT_FULL_ARR)),
      )(pickedItems);
      const containers = makeRequestContainers(formData, pickedUpContainers);

      return { ...stop, items, ...containers };
    }

    return stop;
  },
  R.values(stops),
);


export const makeLoadStopsEditReqStops = ({
  stops,
  loadGuid,
  loadType,
  pickedItems,
  branchRefTypes,
  pickedUpContainers,
}: Object) => R.map(
  (item: Object) => {
    const { order, eventType, formData, originEvent } = item;

    const { droppedTrailerGuids, pickedUpTrailerGuids } = formData;

    const orderProp = G.getEventIndexPropByLoadType(loadType);
    const distanceProp = G.getDistancePropByLoadType(loadType);
    const loadDistance = R.path([distanceProp], item);
    const itemIds = makeItemIds(eventType, R.prop(GC.FIELD_LOAD_ITEMS, formData));
    const containerIds = makeContainerIds(formData, pickedUpContainers);
    const stopFields = getMainStopFields(formData);

    let location = makeLocation(R.pick(locationFieldsToPick, formData));

    const references = mapReferencesWithRefTypes(R.prop(GC.FIELD_LOAD_REFERENCES, formData), branchRefTypes);

    const fcfs = R.prop(GC.FIELD_LOAD_FCFS, stopFields);
    const eventLateDate = R.prop(GC.FIELD_LOAD_EVENT_LATE_DATE, stopFields);

    const eventLateDateToUse = G.ifElse(
      G.isFalse(fcfs),
      null,
      eventLateDate,
    );

    const stopInfo = R.assoc(GC.FIELD_LOAD_EVENT_LATE_DATE, eventLateDateToUse, stopFields);

    if (G.isNotNilAndNotEmpty(originEvent)) {
      location = R.mergeRight(location, R.pick(
        [GC.FIELD_GUID, GC.FIELD_VERSION],
        R.path([GC.FIELD_LOCATION], originEvent),
      ));
    }

    const stop = G.formatStopDateTimeValues({
      ...originEvent,
      itemIds,
      location,
      ...stopInfo,
      ...containerIds,
      [orderProp]: order,
      droppedTrailerGuids,
      pickedUpTrailerGuids,
      [distanceProp]: loadDistance,
      [GC.FIELD_EVENT_TYPE]: eventType,
      [GC.FIELD_LOAD_REFERENCES]: references,
    });

    if (G.isEventTypePickup(eventType)) {
      const items = R.map(
        (item: Object) => ({...item, loadGuid, loadType}),
        R.prop(GC.FIELD_LOAD_ITEMS, formData),
      );

      const containers = makeRequestContainers(formData, pickedUpContainers, true, { loadGuid, loadType });

      return { ...stop, items, ...containers };
    }

    if (G.isEventTypeDrop(eventType)) {
      const items = R.compose(
        R.filter((item: Object) => R.includes(R.prop(GC.FIELD_ITEM_INTERNAL_ID, item), stop.itemIds)),
        R.map((item: Object) => ({...item, loadGuid, loadType})),
      )(pickedItems);

      const containers = makeRequestContainers(formData, pickedUpContainers, true, { loadGuid, loadType });

      return { ...stop, items, ...containers };
    }

    return stop;
  },
  R.values(stops),
);

export const getMappedEquipmentFromConfigsByOptionGuid = (optionGuid: string, configs: Object) => {
  if (G.isNilOrEmpty(optionGuid)) return null;

  const options = G.getFullOptionsFromDropdownConfigStore(configs, GC.GENERAL_EQUIPMENTS);
  const mappedOptions = G.mapDropdownConfigOptionsToObjects(options);

  return R.find(R.propEq(optionGuid, GC.FIELD_DROPDOWN_OPTION_GUID), mappedOptions);
};

export const getMappedServicesFromConfigsByOptionGuid = (optionGuids: Array = [], configs: Object) => {
  const options = G.getFullOptionsFromDropdownConfigStore(configs, GC.GENERAL_SERVICES);

  const indexedOptions = G.indexMappedDropdownOptionsByField(
    GC.FIELD_DROPDOWN_OPTION_GUID,
    G.mapDropdownConfigOptionsToObjects,
    options,
  );

  return R.values(R.pick(R.or(optionGuids, []), indexedOptions));
};

export const makeReqEquipmentAndServices = (branchConfigs: Object, referenceFormData: Object) => {
  const equipmentGuid = R.prop(GC.FIELD_LOAD_EQUIPMENT, referenceFormData);
  const servicesGuids = R.prop(GC.FIELD_LOAD_SERVICES, referenceFormData);
  const equipment = getMappedEquipmentFromConfigsByOptionGuid(equipmentGuid, branchConfigs);
  const services = getMappedServicesFromConfigsByOptionGuid(servicesGuids, branchConfigs);

  return { services, equipment };
};

const getInitialValuesWithWeight = (initialValues: Object, totalWeight: Object) => {
  const { totalTripWeight, totalTripWeightUom } = initialValues;

  const totalTripWeightToUse = R.or(totalTripWeight, R.path([GC.FIELD_TOTAL_TRIP_WEIGHT], totalWeight));
  const totalTripWeightUomToUse = R.or(totalTripWeightUom, R.path([GC.FIELD_TOTAL_TRIP_WEIGHT_UOM], totalWeight));

  const totalWeightObject = {
    [GC.FIELD_TOTAL_TRIP_WEIGHT]: totalTripWeightToUse,
    [GC.FIELD_TOTAL_TRIP_WEIGHT_UOM]: totalTripWeightUomToUse,
  };

  return R.mergeRight(initialValues, totalWeightObject);
};

const getCloRateInitFields = (props: Object) => {
  const { isEditMode, defaultOrderAccessorials, sharedAccessorialsApplyTo, defaultOrderFuelAccessorial } = props;

  if (G.isTrue(isEditMode)) return cloRateInitFields;

  const allAccessorials = R.concat(R.or(defaultOrderAccessorials, []), R.or(sharedAccessorialsApplyTo, []));

  const cloRateInitFieldsWithCharges = R.assoc(
    GC.FIELD_CHARGES,
    recalculateAllCharges(G.addMainFuelChargeToCharges2(allAccessorials, defaultOrderFuelAccessorial), props),
    cloRateInitFields,
  );

  return cloRateInitFieldsWithCharges;
};

export const setDefaultCustomerRateValues = (props: Object) => {
  const {
    services,
    equipment,
    totalWeight,
    branchConfigs,
    totalDistance,
    initialValues,
    asyncTotalDistance,
    totalPickupQuantity,
  } = props;

  if (G.isNotNilAndNotEmpty(initialValues)) {
    const initValues = G.mapDropdownsObjectInEntity(
      GC.FIELD_DROPDOWN_OPTION_GUID,
      [GC.FIELD_SERVICE_TYPE, GC.FIELD_MODE],
      initialValues,
    );

    const totalPickupQuantityToUse = {
      [GC.FIELD_PACKAGE_TYPE]: R.or(
        R.prop(GC.FIELD_PACKAGE_TYPE, initialValues),
        R.prop(GC.FIELD_PACKAGE_TYPE, totalPickupQuantity),
      ),
      [GC.FIELD_TOTAL_PICKUP_QUANTITY]: R.or(
        R.prop(GC.FIELD_TOTAL_PICKUP_QUANTITY, initialValues),
        R.prop(GC.FIELD_TOTAL_PICKUP_QUANTITY, totalPickupQuantity),
      ),
    };

    const initValuesWithWeight = getInitialValuesWithWeight(initValues, totalWeight);
    const rateInitWithDist = R.mergeRight(cloRateInitFields, asyncTotalDistance);

    return R.mergeAll([rateInitWithDist, initValuesWithWeight, totalPickupQuantityToUse]);
  }

  return G.setDefaultFieldsToRateInvoiceAsyncFormik({
    services,
    totalWeight,
    totalPickupQuantity,
    formikInitFields: getCloRateInitFields(props),
    defaultUomFields: G.getDefaultUomFields(branchConfigs),
    // TODO: check rounding for asyncTotalDistance and totalDistance should be the same
    totalDistance: R.mergeRight(asyncTotalDistance, totalDistance),
    currency: G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, branchConfigs),
    mode: G.getConfigValueFromStore(GC.CLO_GENERAL_RATE_TRANSPORTATION_MODE, branchConfigs),
    serviceType: G.getConfigValueFromStore(GC.CLO_GENERAL_RATE_SERVICE_TYPE, branchConfigs),
    equipment: R.or(equipment, G.getConfigValueFromStore(GC.CLO_GENERAL_DEFAULT_EQUIPMENT, branchConfigs)),
  });
};

export const getDivisionGuidByBranchConfigs = (configs: Object, branchInfo: Object) => {
  const telDefaultBranch = G.getConfigValueFromStore(
    GC.TEL_GENERAL_DEFAULT_BRANCH,
    configs,
  );
  const customerBranchGuid = R.prop(GC.FIELD_VALUE, branchInfo);
  const currentUserBranchGuid = G.getAmousCurrentUserBranchGuidFromWindow();
  const customerBranchGuidToUse = G.ifElse(
    G.isNotNilAndNotEmpty(customerBranchGuid),
    customerBranchGuid,
    currentUserBranchGuid,
  );
  const currentBranchGuid = G.getAmousCurrentBranchGuidFromWindow();
  const defaultTelToGuidMap = {
    [GC.TEL_DEFAULT_BRANCH_TYPE_CURRENT_BRANCH]: currentBranchGuid,
    [GC.TEL_DEFAULT_BRANCH_TYPE_USER_BRANCH]: currentUserBranchGuid,
    [GC.TEL_DEFAULT_BRANCH_TYPE_CUSTOMER_BRANCH]: customerBranchGuidToUse,
  };

  return R.pathOr(currentBranchGuid, [telDefaultBranch], defaultTelToGuidMap);
};

export const checkSingleToSingleItemsAndDrop = (props: Object) => {
  const { stops, values, isPickup, setFormDataToStop } = props;
  const condition = G.isAllTrue(
    isPickup,
    R.equals(R.length(R.values(stops)), 2),
    G.isEventTypeDrop(R.path([2, GC.FIELD_EVENT_TYPE], stops)),
  );
  if (condition) {
    const itemIds = R.map(
      R.prop(GC.FIELD_ITEM_INTERNAL_ID),
      values.items,
    );
    const formData = R.compose(
      R.assoc('items', itemIds),
      R.path(['stops', 2, 'formData']),
    )(props);
    setFormDataToStop({ isValid: true, stopOrder: 2, formData });
  }
};

const checkItemDimensions = (item: Object, configDimensions: Object) => {
  const { maxWidth, maxHeight, maxLength, uom } = configDimensions;

  const { dimensionsUOM } = item;

  const dimensionConfigsMap = {
    dimensionsW: maxWidth,
    dimensionsH: maxHeight,
    dimensionsL: maxLength,
  };
  const dimensionLocaleMap = {
    dimensionsW: 'titles:width',
    dimensionsH: 'titles:height',
    dimensionsL: 'titles:length',
  };

  const message = R.compose(
    R.join(', '),
    R.filter(G.isNotNil),
    R.map((name: string) => {
      const dimension = R.pathOr(null, [name], item);
      const configDimension = R.path([name], dimensionConfigsMap);
      const conditions = [
        G.isNotNilAndNotEmpty(uom),
        G.isNotNilAndNotEmpty(dimension),
        G.isNotNilAndNotEmpty(dimensionsUOM),
        G.isNotNilAndNotEmpty(configDimension),
      ];

      if (G.isAnyFalse(...conditions)) return null;

      const convertedDimension = G.convertDimensionValueByUoms(dimension, dimensionsUOM, uom);

      if (R.gte(configDimension, convertedDimension)) return null;

      return `${G.getWindowLocale(R.prop(name, dimensionLocaleMap))} ${
        G.getWindowLocale('titles:exceeds', 'Exceeds')} ${configDimension} ${G.getUomLocale(uom)}`;
    }),
  )(['dimensionsH', 'dimensionsL', 'dimensionsW']);

  if (R.isEmpty(message)) return null;

  return `${R.pathOr('', [GC.FIELD_ITEM_ID], item)} - ${message}`;
};

export const getValidationWarnings = (store: Object) => {
  const { pageType, ignoreWarnings } = store;

  if (R.or(ignoreWarnings, R.not(isPageCreateDO(pageType)))) return null;

  const { stops, branchConfigs } = store;

  const { pickups } = getDataFromStops(stops);

  const maxWidth = G.getConfigValueFromStore(GC.CLO_ITEM_MAX_WIDTH, branchConfigs);
  const maxHeight = G.getConfigValueFromStore(GC.CLO_ITEM_MAX_HEIGHT, branchConfigs);
  const maxLength = G.getConfigValueFromStore(GC.CLO_ITEM_MAX_LENGTH, branchConfigs);
  const uom = G.getConfigValueFromStore(GC.CLO_ITEM_DIMENSION_UOM, branchConfigs);

  const stopItemsMessages = R.compose(
    R.filter(G.isNotNil),
    R.map((stop: Object) => {
      const itemWarnings = R.compose(
        R.filter(G.isNotNil),
        R.map((item: Object) => checkItemDimensions(item, {
          uom,
          maxWidth,
          maxHeight,
          maxLength,
        })),
        R.pathOr([], ['formData', 'items']),
      )(stop);

      if (R.isEmpty(itemWarnings)) return null;

      const stopName = getStopTitle(stop);

      return `${stopName}: ${itemWarnings}`;
    }),
  )(pickups);

  return stopItemsMessages;
};

export const mapRateInitChargesWithFuelIndexInfo = (rate: Object, shared: Array) => {
  const charges = G.getChargesFromObject(rate);

  if (G.isNilOrEmpty(charges)) return rate;

  const chargesToUse = R.map(
    (item: Object) => {
      const { fuelIndexInfo, sharedAssessorialGuid } = item;

      const id = R.or(G.getGuidFromObject(item), G.genShortId());

      if (R.or(G.isNilOrEmpty(fuelIndexInfo), G.isNilOrEmpty(sharedAssessorialGuid))) {
        return {
          ...item,
          [GC.FIELD_ID]: id,
          fuelIndexInfo: null,
        };
      }

      const sharedAss = R.find(R.propEq(sharedAssessorialGuid, GC.FIELD_GUID), shared);

      if (G.isNilOrEmpty(sharedAss)) {
        return {
          ...item,
          [GC.FIELD_ID]: id,
          fuelIndexInfo: null,
        };
      }

      const { rate, fuelIndexInfo: fuelIndexInfoToUse } = getRateFromSharedAccessAndFuelPrices(sharedAss);

      return {
        ...item,
        rate,
        [GC.FIELD_ID]: id,
        fuelIndexInfo: fuelIndexInfoToUse,
      };
    },
    charges,
  );

  return R.assoc(GC.FIELD_CHARGES, chargesToUse, rate);
};

export const getRateWithChargesFromData = (
  rate: Object,
  branchConfigs: Object,
  isValid: boolean,
  shared: Array,
  equipmentServices: Object,
) => {
  if (G.isNilOrEmpty(rate)) return null;

  const currency = G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, branchConfigs);
  const mode = G.getConfigValueFromStore(GC.CLO_GENERAL_RATE_TRANSPORTATION_MODE, branchConfigs);
  const defaultEquipment = G.getConfigValueFromStore(GC.CLO_GENERAL_DEFAULT_EQUIPMENT, branchConfigs);

  const equipment = R.pathOr(
    defaultEquipment,
    [GC.FIELD_LOAD_EQUIPMENT, GC.FIELD_DROPDOWN_OPTION_GUID],
    equipmentServices,
  );

  const services = R.map(
    (item: Object) => R.prop(GC.FIELD_DROPDOWN_OPTION_GUID, item),
    R.propOr([], GC.FIELD_LOAD_SERVICES, equipmentServices),
  );

  const init = {
    services,
    equipment,
    [GC.FIELD_MODE]: mode,
    isValid: R.or(isValid, false),
    [GC.FIELD_CURRENCY]: currency,
  };

  return R.mergeRight(init, mapRateInitChargesWithFuelIndexInfo(rate, shared));
};

export const prependCurrentBranchToBranchList = (list: Array, currentBranch: Object) => {
  if (G.isNilOrEmpty(currentBranch)) return list;

  return R.prepend(
    R.compose(
      R.assoc(
        GC.FIELD_ACTIVE,
        R.propOr(true, GC.FIELD_ACTIVE, R.find(R.propEq(G.getGuidFromObject(currentBranch), GC.FIELD_GUID), list)),
      ),
      R.assoc(GC.FIELD_NAME, R.prop(GC.FIELD_BRANCH_NAME, currentBranch)),
    )(currentBranch),
  )(list);
};

export const getLeftSectionWidth = (leftActiveTad: string) => G.ifElse(
  R.equals(leftActiveTad, TAB_NAME_PRICING),
  755,
  662,
);

export const getRightSectionWidth = (leftActiveTad: string, rightActiveTad: string) => {
  const leftSectionWidth = getLeftSectionWidth(leftActiveTad);

  const subtractWidth = R.add(150, leftSectionWidth);

  if (R.equals(rightActiveTad, TAB_NAME_RATE_CONFIRMATION)) {
    return `calc(100vw - ${subtractWidth}px)`;
  }

  return 662;
};

export const getPrimaryReference = (branchConfigs: Object, branchRefTypes: Object = {}) => {
  const primaryReferenceGuid = G.getConfigValueFromStore(GC.CLO_PRIMARY_REFERENCE_TYPE, branchConfigs);

  const primaryReferenceName = R.compose(
    R.pathOr('', [GC.FIELD_NAME]),
    R.find(R.propEq(primaryReferenceGuid, GC.FIELD_GUID)),
    R.pathOr([], [GC.REF_SCOPE_NAME_CLO]),
  )(branchRefTypes);

  return {
    [GC.FIELD_VALUE]: primaryReferenceGuid,
    [GC.FIELD_LABEL]: primaryReferenceName,
  };
};
