import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// feature navbar-menus
import * as A from './actions';
//////////////////////////////////////////////////

const initialState = {
  dropdownItems: [],
  navbarMenuItems: [],
  openedSidebar: false,
  subMenus: {
    'menu:fleet': false,
    'menu:views': false,
    'menu:dispatch': false,
    'menu:invoices': false,
    'menu:templates': false,
    'menu:contracts': false,
    'menu:configurations': false,
    'menu:background-processes': false,
  },
};

const toggleSubmenus = (state: Object, data: Object) => (
  P.$set('subMenus', data, state)
);

const toggleSidebar = (state: Object) => (
  P.$toggle('openedSidebar', state)
);

const filteredMenusSuccess = (state: Object, data: Array) => (
  P.$set('navbarMenuItems', data, state)
);

export default createReducer({
  [A.toggleSidebar]: toggleSidebar,
  [A.toggleSubmenus]: toggleSubmenus,
  [A.filteredMenusSuccess]: filteredMenusSuccess,
}, initialState);
