import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { openModal, closeModal } from '../../../components/modal/actions';
// features
import BranchTree from '../../branch/components/branch-tree';
// helpers/constants
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleAssignDivision: (props: Object) => (branchGuid: string) => {
      const { closeModal, assignDivision, getBranchConfigs } = props;
      closeModal();
      assignDivision(branchGuid);
      getBranchConfigs({ branchGuid, names: GC.CLO_PRIMARY_REFERENCE_DIVISION_PREFIX });
    },
  }),
  pure,
);

const AssignDivision = (props: Object) => {
  const { closeModal, handleAssignDivision } = props;

  return (
    <BranchTree
      width={700}
      cursor='pointer'
      rootDisabled={false}
      closeAction={closeModal}
      branchActiveFilter={true}
      onClickBranch={handleAssignDivision}
      branchTypeFilter={GC.BRANCH_TYPE_ENUM_DIVISION} />
  );
};

export default connect(null, {
  openModal,
  closeModal,
})(enhance(AssignDivision));
