import React from 'react';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import { OuterClick } from 'react-outer-click';
import { createStructuredSelector } from 'reselect';
import {
  compose,
  withState,
  withHandlers,
} from 'react-recompose';
// components
import { openModal, closeModal } from '../../../../components/modal/actions';
// features
import NotificationModal from '../../../notification/components/popup';
import { makeSelectUnreadCount } from '../../../notification/selectors';
// helpers/constants
import * as G from '../../../../helpers';
// icons
import * as I from '../../../../svgs';
// ui
import { Flex, Text } from '../../../../ui';
// feature navbar-menu
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');

const enhance = compose(
  withState('showPopup', 'setShowPopup', false),
  withHandlers({
    onShowPopup: ({ setShowPopup }: Object) => (e: Event) => {
      G.stopPropagation(e);
      setShowPopup(true);
    },
    onHidePopup: ({ setShowPopup }: Object) => (e: Event) => {
      G.stopPropagation(e);
      setShowPopup(false);
    },
  }),
);

const Notifications = ({
  showPopup,
  onShowPopup,
  onHidePopup,
  unreadNotificationsCount,
}: Object) => (
  <React.Fragment>
    <Flex
      gap='6px'
      p='3px 6px'
      cursor='pointer'
      userSelect='none'
      borderRadius='4px'
      onMouseDown={onShowPopup}
      background={G.getTheme('colors.light.notificationsBackground')}
      title={
        G.getWindowLocale('actions:view-all-notifications', 'View all notifications')
      }
    >
      {I.bellIcon(whiteColor, 14, 14)}
      <Text
        fontSize={12}
        color={whiteColor}
      >
        {
          `${unreadNotificationsCount} ${G.getWindowLocale('actions:new-notifications', 'new notifications')}`
        }
      </Text>
    </Flex>
    {
      showPopup && createPortal(
        <OuterClick onOuterClick={onHidePopup}>
          <NotificationModal
            onHidePopup={onHidePopup}
          />
        </OuterClick>,
        document.body,
      )
    }
  </React.Fragment>
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  unreadNotificationsCount: makeSelectUnreadCount(state),
}));

export default connect(mapStateToProps, {
  openModal,
  closeModal,
})(enhance(Notifications));
