import * as R from 'ramda';
import React, { useMemo, useCallback } from 'react';
// components
import { Table } from '../../../components/table';
// helpers/constants
import * as G from '../../../helpers';
// feature payroll
import { A } from '../hooks/use-fuel-cards';
import { getRemoveRestoreColumn } from './active-tab';
import {
  getFuelCardsReport,
  fuelCardsTableSettings,
  fuelCardsColumnSettings,
  fuelCardsNoActionsTableSettings,
} from '../settings/charges-reports-settings';
//////////////////////////////////////////////////

const FuelCardsTab = (props: Object) => {
  const {
    maxHeight,
    noActions,
    selectFuelCard,
    selectFuelCardLine,
    omitReportFields = [],
    toggleFuelCardDetails,
    removeOrRestoreFuelCard,
    handleOpenDriverProfile,
    toggleVendorFuelCardDetails,
    // report
    fuelCards,
    fuelCardsLoading,
    fuelCardsTotalCount,
    fuelCardsReportDispatch,
    fuelCardsTitleSortValues,
    fuelCardsTableTitleFilters,
  } = props;

  const itemList = useMemo(() =>
    R.map(R.assoc('selectFuelCardLine', selectFuelCardLine), fuelCards),
    [fuelCards, selectFuelCardLine],
  );

  const report = useMemo(() =>
    getFuelCardsReport(omitReportFields, noActions),
    [noActions, omitReportFields],
  );


  const columnSettings = useMemo(
    () => {
      const settings = R.omit(omitReportFields, fuelCardsColumnSettings);

      if (noActions) return settings;

      return R.assoc(
        'removeRestoreColumn',
        getRemoveRestoreColumn('fuelCards', removeOrRestoreFuelCard),
        settings,
      );
    },
    [noActions, removeOrRestoreFuelCard],
  );

  const allChecked = useMemo(() => G.isAllChecked(itemList), [itemList]);

  const tableSettings = useMemo(() => {
    if (noActions) return R.assoc('maxHeight', maxHeight, fuelCardsNoActionsTableSettings);

    return R.assoc('maxHeight', maxHeight, fuelCardsTableSettings);
  }, [maxHeight, noActions]);

  const onOptionClick = useCallback(
    (guid: string) => selectFuelCard(guid),
    [selectFuelCard],
  );

  const handleTableTitleFilter = useCallback(
    (data: Object) => G.handleTableTitleFilter2(data, A, fuelCardsReportDispatch),
  );

  return (
    <Table
      report={report}
      itemList={itemList}
      allChecked={allChecked}
      useSearchableColumns={true}
      tableSettings={tableSettings}
      onOptionClick={onOptionClick}
      useNewTitleFilterInputs={true}
      columnSettings={columnSettings}
      totalCount={fuelCardsTotalCount}
      titleSortValues={fuelCardsTitleSortValues}
      callbackData={{ handleOpenDriverProfile }}
      tableTitleFilters={fuelCardsTableTitleFilters}
      handleTableTitleFilter={handleTableTitleFilter}
      loading={R.and(fuelCardsLoading, G.isNilOrEmpty(itemList))}
      toggle={noActions ? toggleVendorFuelCardDetails : toggleFuelCardDetails}
      filterProps={G.getFilterPropsFromColumnSettings(fuelCardsColumnSettings)}
    />
  );
};

export default FuelCardsTab;
