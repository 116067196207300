import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ActionsElement } from '../../../components/actions-element';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleEditInvoice: ({ openEditInvoiceForm, closeFixedPopup }: Object) => () => {
      openEditInvoiceForm();
      closeFixedPopup();
    },
  }),
  pure,
);

const getOptions = (props: Object) => ([
  {
    action: props.handleEditInvoice,
    text: G.getWindowLocale('actions:edit', 'Edit'),
    frontIcon: I.pencil(G.getTheme('icons.iconColor')),
    permissions: G.ifElse(
      G.isTrue(R.path(['invoice', 'completed'], props)),
      [PC.TEL_INVOICE_OVERWRITE_EXECUTE],
      [PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
    ),
  },
]);

export const InvoiceActions = (props: Object) => <ActionsElement options={getOptions(props)} />;

export default enhance(InvoiceActions);
