import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, branch, withHandlers, renderNothing } from 'react-recompose';
// components
import { Tabs } from '../../components/tabs';
import { Table } from '../../components/table';
import { TitlePanel } from '../../components/title-panel';
import { EditReport } from '../../components/edit-report';
import { getConfirmModal } from '../../components/confirm';
import { PageActions } from '../../components/page-actions';
import { withPromptModal } from '../../components/edit-report/hocs';
import { openModal, closeModal } from '../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../components/edit-report/helpers';
// features
import { AuthWrapper } from '../permission';
import { getTabs } from '../dispatch-board-new/helpers';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
import { setExpandedContainerOptions } from '../expanded-container/actions';
import DispatchBoardTileTabs from '../dispatch-board-new/components/title-tabs';
import { tabStyles, countStyles, activeTabStyles } from '../dispatch-board-new/constants';
// forms
import SelectPrintTemplateForm from '../../forms/forms/select-print-template-form';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withFixedPopover } from '../../hocs';
// icons
import * as I from '../../svgs';
// ui
import { Box, PageWrapper, ZOrderWrapper } from '../../ui';
// permission
import PC from '../permission/role-permission';
// report-common
import { reportEnhancer } from '../../report-common';
// feature quotes
import { RowActions } from './components/row-actions';
import {
  quotesReport,
  tableSettings,
  quotesColumnSettings,
  orderQuotesColumnSettings,
  ORDER_QUOTES_FILTER_PARAMS,
} from './settings/column-settings';
import {
  setReports,
  setUsedReport,
  getXMLRequest,
  cleanQuickFilter,
  setTableTitleSort,
  printQuoteRequest,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  selectQuoteListItem,
  removeQuotesRequest,
  getQuotesListRequest,
  setQuickFilterParams,
  resetListAndPagination,
  changeDefaultReportRequest,
} from './actions';
import {
  makeSelectRouteTab,
  makeSelectListType,
  makeSelectPagination,
  makeSelectQuotesList,
  makeSelectTotalCount,
  makeSelectUsedReport,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectSelectedList,
  makeSelectFilterParams,
  makeSelectReportStatus,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
///////////////////////////////////////////////

const enhance = compose(
  withFixedPopover,
  reportEnhancer,
  withHandlers({
    handleDelete: (props: Object) => (guid: any) => {
      const {
        openModal,
        closeModal,
        selectedList,
        closeFixedPopup,
        removeQuotesRequest,
      } = props;

      const guids = G.ifElse(G.isString(guid), R.of(Array, guid), selectedList);

      const textLocaleArr = G.ifElse(
        G.isString(guid),
        [
          'messages:confirm-delete-entity',
          'Are you sure you want to delete this entity?',
        ],
        [
          'messages:confirm-delete-entities',
          'Are you sure you want to delete these entities?',
        ],
      );

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        text: G.getWindowLocale(...textLocaleArr),
        submitAction: () => removeQuotesRequest(guids),
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
      });

      closeFixedPopup();
      openModal(modalContent);
    },
    handleGetXMLRequest: ({ getXMLRequest, closeFixedPopup }: Object) => (guid: string) => {
      closeFixedPopup();
      getXMLRequest(guid);
    },
    handlePrint: (props: Object) => (guid: string) => {
      const { openModal, closeFixedPopup, printQuoteRequest } = props;

      const component = (
        <SelectPrintTemplateForm
          printableSection={GC.DOCUMENT_PRINTABLE_SECTION_ORDER_QUOTE}
          submitAction={(values: Object) => printQuoteRequest(R.assoc(GC.FIELD_GUID, guid, values))}
        />
      );

      const modal = G.getDefaultModalOptions(component, G.getWindowLocale('titles:print-options', 'Print Options'));

      closeFixedPopup();
      openModal(modal);
    },
    handleEdit: (props: Object) => (guid: string) => {
      const { closeFixedPopup, setExpandedContainerOptions } = props;

      closeFixedPopup();

      const componentType = GC.PAGE_NEW_DO_QUOTE_EDIT;

      return setExpandedContainerOptions({ componentType, opened: true, visitPageGuid: guid });
    },
  }),
  withHandlers({
    handleClickEditIcon: (props: Object) => (e: Object, data: Object) => {
      const {
        listType,
        handlePrint,
        handleDelete,
        openFixedPopup,
        closeFixedPopup,
        handleGetXMLRequest,
      } = props;

      return (
        openFixedPopup({
          position: 'right',
          el: e.currentTarget,
          content: (
            <RowActions
              entity={data}
              listType={listType}
              handlePrint={handlePrint}
              handleDelete={handleDelete}
              closeFixedPopup={closeFixedPopup}
              handleGetXmlRequest={handleGetXMLRequest}
            />
          ),
        })
      );
    },
  }),
  withPromptModal(ORDER_QUOTES_FILTER_PARAMS),
  withHandlers({
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          disableSortByFields={true}
          usedReport={selectedReport}
          requestPending={requestPending}
          onReportSet={() => getItemListRequest()}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
        />
      );

      const modal = G.getDefaultReportModal(component, 1150);

      openModal(modal);
    },
  }),
  branch(
    ({ listType, selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      R.and(G.isNilOrEmpty(selectedReport), R.equals(listType, GC.FIELD_ORDER_QUOTES)),
    ),
    renderNothing,
  ),
  pure,
);

const renderElementActions = (data: Object, handleClickEditIcon: Function) => (
  <Box cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, data)}>
    {I.threeDots()}
  </Box>
);

const getQuoteIntegrationListData = (data: Object) => R.compose(
  R.map((item: Object) => ({
    ...item,
    [GC.FIELD_EMAIL]: R.path(['contactInfo', GC.FIELD_EMAIL], item),
    [GC.FIELD_PHONE]: R.path(['contactInfo', GC.FIELD_PHONE], item),
    [GC.FIELD_CONTACT_NAME]: R.path(['contactInfo', GC.FIELD_CONTACT_NAME], item),
  })),
  R.values,
)(R.or(data, []));

const renderTable = (props: Object) => {
  const {
    loading,
    listType,
    itemList,
    totalCount,
    pagination,
    handleEdit,
    handleDelete,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    selectQuoteListItem,
    handleClickEditIcon,
    getQuotesListRequest,
    handleTableTitleFilter,
  } = props;

  if (R.and(R.not(selectedReport), R.equals(listType, GC.FIELD_ORDER_QUOTES))) return null;

  let data = {
    pagination,
    totalCount,
    tableSettings,
    report: quotesReport,
    onOptionClick: selectQuoteListItem,
    columnSettings: quotesColumnSettings,
    allChecked: G.isAllChecked(itemList),
    itemList: getQuoteIntegrationListData(itemList),
    handleLoadMoreEntities: () => getQuotesListRequest(true),
    renderRightStickedComponent: (data: Object) => renderElementActions(data, handleClickEditIcon),
  };

  if (R.equals(listType, GC.FIELD_ORDER_QUOTES)) {
    const freezedField = {
      sequence: 1,
      freezed: true,
      name: 'actions',
      reference: false,
    };
    const allChecked = R.all(R.prop(GC.FIELD_SELECTED), itemList);
    const reportToUse = G.prependFieldToTableReportFields(selectedReport, freezedField);

    const orderQuotesTableSettings = R.mergeRight(
      tableSettings,
      {
        minHeight: 300,
        searchableTitles: true,
        withoutFieldSort: true,
        allowSelectAll: R.gt(R.length(itemList), 0),
      },
    );

    const getPermissions = ({ status }: string) => G.ifElse(
      G.notEquals(status, GC.ORDER_QUOTE_STATUS_TYPE_PROCESSED),
      [PC.ORDER_QUOTE_WRITE],
      [],
    );

    const actionButtons = [
      {
        iconName: 'trash',
        action: handleDelete,
      },
      {
        getPermissions,
        iconName: 'pencil',
        action: handleEdit,
      },
    ];

    data = {
      loading,
      allChecked,
      totalCount,
      pagination,
      actionButtons,
      titleSortValues,
      tableTitleFilters,
      hasSelectable: true,
      report: reportToUse,
      handleTableTitleFilter,
      useSearchableColumns: true,
      itemList: R.values(itemList),
      useNewTitleFilterInputs: true,
      onOptionClick: selectQuoteListItem,
      tableSettings: orderQuotesTableSettings,
      columnSettings: orderQuotesColumnSettings,
      handleLoadMoreEntities: G.ifElse(loading, () => {}, getItemListRequest),
      renderRightStickedComponent: (data: Object) => renderElementActions(data, handleClickEditIcon),
      filterProps: R.indexBy(R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(ORDER_QUOTES_FILTER_PARAMS)),
    };
  }

  return <Table {...data} />;
};

export const QuotesComponent = (props: Object) => {
  const {
    listType,
    totalCount,
    reportList,
    filterParams,
    selectedList,
    handleDelete,
    selectedReport,
  } = props;

  const isQuotesPage = R.equals(listType, GC.FIELD_QUOTES);
  const permissions = G.ifElse(isQuotesPage, [PC.CLO_WRITE], [PC.ORDER_QUOTE_WRITE]);
  const mainLightColor = G.getTheme('colors.light.mainLight');

  const listActions = [
    {
      permissions,
      type: 'massAction',
      action: handleDelete,
      icon: I.trash(mainLightColor, 22, 22),
      text: G.getWindowLocale('actions:delete', 'Delete'),
    },
  ];

  const listActionsOpt = G.ifElse(
    isQuotesPage,
    R.tail(listActions),
    listActions,
  );


  const hasPinned = G.hasPinnedReports(reportList);
  const hasFilters = G.hasSearchCriteria(selectedReport, filterParams);
  const hasBorderBottom = R.or(hasPinned, hasFilters);

  const customTitleComponent = (
    // <Tabs
    //   count={totalCount}
    //   tabStyles={tabStyles}
    //   tabs={getTabs(listType)}
    //   countStyles={countStyles}
    //   activeTabStyles={activeTabStyles}
    // />
    <DispatchBoardTileTabs
      totalCount={totalCount}
      hasBorderBottom={hasBorderBottom}
      marginLeft={G.ifElse(hasBorderBottom, '0px', '1px')}
    />
  );

  return (
    <PageWrapper p={15}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          version={2}
          type={listType}
          withCount={true}
          noExportable={true}
          popperWithCount={true}
          totalCount={totalCount}
          fromDispatchBoard={true}
          withoutQuickFilter={isQuotesPage}
          hiddenRightFilterInfo={isQuotesPage}
          filterProps={ORDER_QUOTES_FILTER_PARAMS}
          customTitleComponent={customTitleComponent}
          hideFilterInfo={G.ifElse(isQuotesPage, true, null)}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex='1'>
        {renderTable(props)}
      </ZOrderWrapper>
      <AuthWrapper has={permissions}>
        <PageActions
          listActions={listActionsOpt}
          count={R.length(selectedList)}
          shadowColor={G.getTheme('createButton.shadowColor')}
          mainAction={{
            permissions: [PC.ORDER_QUOTE_WRITE],
            action: () => G.goToRoute(GC.ROUTE_PATH_DO_QUOTE),
            text: G.getWindowLocale('actions:create-quote', 'Create Quote'),
          }}
        />
      </AuthWrapper>
    </PageWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  listType: makeSelectListType(state),
  routeTab: makeSelectRouteTab(state),
  loading: makeSelectListLoading(state),
  itemList: makeSelectQuotesList(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  pageVisited: makeSelectPageVisited(state),
  selectedList: makeSelectSelectedList(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  setReports,
  getXMLRequest,
  setUsedReport,
  cleanQuickFilter,
  setTableTitleSort,
  printQuoteRequest,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  selectQuoteListItem,
  removeQuotesRequest,
  getQuotesListRequest,
  setQuickFilterParams,
  resetListAndPagination,
  changeDefaultReportRequest,
  setExpandedContainerOptions,
})(enhance(QuotesComponent));
