import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, lifecycle, withHandlers } from 'react-recompose';
// components
import { PageTitle } from '../../components/page-title';
import { FileDownload } from '../../components/file-download';
import { openModal, closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { makeSelectDriverRoleAvailableList } from '../role/selectors';
// forms
import { Label, Toggle } from '../../forms';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex, PageWrapper, ActionButton } from '../../ui';
// feature new-import
import * as C from './constants';
import { AddSequence } from './helpers';
import ImportTable from './components/import-table';
import MapAndImport from './components/map-and-import';
import ErrorsComponent from './components/global-errors';
import ImportFromFile from './components/import-from-file';
import CLOMapAndImport from './components/clo-map-and-import';
import { importTypeField, importTypeSchema } from './settings';
import { SelectComponent, SelectImportType, SelectSourceType } from './components/select';
import {
  makeSelectErrors,
  makeSelectEmptyRow,
  makeSelectSourceType,
  makeSelectImportList,
  makeSelectImportType,
  makeSelectRolesGuids,
  makeSelectGlobalErrors,
  makeSelectImportMappers,
  makeSelectCleanExistentRates,
} from './selectors';
import {
  setErrors,
  setRolesGuids,
  setSourceType,
  setGlobalErrors,
  setContractGuid,
  setInitialState,
  changeImportList,
  toggleCleanExistentRates,
  getImportTemplateRequest,
  uploadCLODocumentRequest,
  createImportFromFileRequest,
  removeMapAndImportFileRequest,
  createMapAndImportFromFileRequest,
} from './actions';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleOpenImportFromFile: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        sourceType,
        importType,
        rolesGuids,
        driverRoles,
        contractGuid,
        rolesOptions,
        cleanExistentRates,
        createImportFromFileRequest,
      } = props;

      const driverRoleOptions = R.map(({ name, guid }: Object) => ({
        [GC.FIELD_LABEL]: name,
        [GC.FIELD_VALUE]: guid,
      }), R.values(driverRoles));
      const initialValues = {
        rolesGuids,
        contractGuid,
        cleanExistentRates,
        [GC.FIELD_SOURCE]: sourceType,
        [GC.FIELD_TYPE]: R.or(importType, C.IMPORT_TYPE_DRIVER),
      };
      const componentProps = {
        openModal,
        closeModal,
        rolesOptions,
        initialValues,
        driverRoleOptions,
        submitAction: createImportFromFileRequest,
      };

      const component = <ImportFromFile {...componentProps} />;
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          maxHeight: '88vh',
          width: 'max-content',
          title: G.getWindowLocale('titles:import-document', 'Import Document'),
        },
      };

      openModal(modal);
    },
    handleOpenMapAndImport: (props: Object) => () => {
      const {
        mappers,
        emptyRow,
        openModal,
        closeModal,
        importType,
        sourceType,
        openLoader,
        closeLoader,
        uploadCLODocumentRequest,
        removeMapAndImportFileRequest,
        createMapAndImportFromFileRequest,
      } = props;

      if (G.isNilOrEmpty(importType)) return;

      const componentProps = {
        emptyRow,
        openModal,
        closeModal,
        importType,
        sourceType,
        openLoader,
        closeLoader,
        removeMapAndImportFileRequest,
        submitAction: createMapAndImportFromFileRequest,
      };

      let component = <MapAndImport {...componentProps} />;

      if (R.equals(importType, C.IMPORT_TYPE_CLO)) {
        const mapperOptions = R.compose(
          G.addEmptyOptionToDropDown,
          R.map((value: string) => ({ value, [GC.FIELD_LABEL]: G.getEnumLocale(value) })),
        )(R.or(mappers, []));
        component = (
          <CLOMapAndImport
            closeModal={closeModal}
            mapperOptions={mapperOptions}
            submitAction={uploadCLODocumentRequest}
          />
        );
      }

      const modal = {
        p: '15px',
        component,
        options: {
          height: 'auto',
          overflow: 'auto',
          maxHeight: '88vh',
          width: 'max-content',
          title: G.getWindowLocale('titles:map-and-import', 'Map and Import'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const ratePriceMapStateToProps = (state: Object) => createStructuredSelector({
  emptyRow: makeSelectEmptyRow(state),
  importType: makeSelectImportType(state),
  importList: makeSelectImportList(state),
  cleanExistentRates: makeSelectCleanExistentRates(state),
});

const ratePriceImportEnhance = compose(
  connect(
    ratePriceMapStateToProps,
    {
      openModal,
      closeModal,
      openLoader,
      closeLoader,
      setInitialState,
      setContractGuid,
      toggleCleanExistentRates,
      getImportTemplateRequest,
      uploadCLODocumentRequest,
      createImportFromFileRequest,
      removeMapAndImportFileRequest,
      createMapAndImportFromFileRequest,
    },
  ),
  enhance,
  withHandlers({
    handleClose: (props: Object) => () => {
      const {
        closeModal,
        contractGuid,
        setInitialState,
        getCarrierContractRatesRequest,
        resetCarrierContractListAndPagination,
      } = props;

      closeModal();
      setInitialState();
      resetCarrierContractListAndPagination();
      getCarrierContractRatesRequest({ contractGuid });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { contractGuid, setContractGuid, getImportTemplateRequest } = this.props;

      setContractGuid(contractGuid);
      getImportTemplateRequest(C.IMPORT_TYPE_CARRIER_RATE_PRICE);
    },
  }),
);

export const RatePriceImport = ratePriceImportEnhance((props: Object) => {
  const {
    handleClose,
    geoFencingZoneList,
    cleanExistentRates,
    handleOpenMapAndImport,
    toggleCleanExistentRates,
    handleOpenImportFromFile,
  } = props;

  return (
    <div>
      <Flex>
        <ActionButton
          px={16}
          mr={30}
          type='button'
          onClick={handleOpenMapAndImport}
        >
          {G.getWindowLocale('titles:import-from-file', 'Map and Import')}
        </ActionButton>
        <Flex cursor='pointer' onClick={handleOpenImportFromFile}>
          <Box mr={15}>
            {G.getWindowLocale('titles:import', 'Import')}
          </Box>
          <div>{I.downloadDocument(G.getTheme('colors.light.black'), 21, 24)}</div>
        </Flex>
        <Flex ml={30}>
          <Label mr={30} cursor='pointer' htmlFor='cleanExistentRates'>
            {G.getWindowLocale('titles:clean-existent-rates', 'Clean Existing Rates')}
          </Label>
          <Toggle
            icons={false}
            id='cleanExistentRates'
            checked={cleanExistentRates}
            onChange={toggleCleanExistentRates}
          />
        </Flex>
      </Flex>
      <ImportTable
        carrierRatePrice={true}
        handleClose={handleClose}
        geoFencingZoneList={geoFencingZoneList}
        importType={G.getWindowLocale('titles:contract-rate', 'Contract Rate')}
      />
    </div>
  );
});

const Import = (props: Object) => {
  const {
    errors,
    setErrors,
    sourceType,
    importType,
    rolesGuids,
    importList,
    driverRoles,
    globalErrors,
    setRolesGuids,
    setSourceType,
    setGlobalErrors,
    changeImportList,
    handleOpenMapAndImport,
    handleOpenImportFromFile,
  } = props;

  const driverRoleOptions = R.map(({ name, guid }: Object) => ({
    [GC.FIELD_LABEL]: name,
    [GC.FIELD_VALUE]: guid,
  }), R.values(driverRoles));

  return (
    <PageWrapper p=' 30px 15px 15px 15px'>
      <Flex justifyContent='space-between'>
        <Flex>
          <PageTitle mr='32px' title={G.getWindowLocale('titles:import', 'Import')} />
          <SelectImportType value={importType} setSourceType={setSourceType} />
          <SelectSourceType importType={importType} value={sourceType} setSourceType={setSourceType} />
          {
            R.equals(importType, C.IMPORT_TYPE_DRIVER) &&
            <SelectComponent
              ml={10}
              isMulti={true}
              value={rolesGuids}
              options={driverRoleOptions}
              placeholder={G.getWindowLocale('titles:select-user-permissions', 'Select User Permissions')}
              handleChange={(field: Object) => setRolesGuids(R.map(R.prop(GC.FIELD_VALUE), R.or(field, [])))}
            />
          }
          {
            R.equals(importType, C.IMPORT_TYPE_LOCATION) &&
            <AddSequence importList={importList} changeImportList={changeImportList} />
          }
        </Flex>
        <Flex width={500} justifyContent='space-between'>
          <ActionButton
            px={16}
            type='button'
            onClick={handleOpenMapAndImport}
          >
            {G.getWindowLocale('titles:import-from-file', 'Map and Import')}
          </ActionButton>
          <FileDownload
            openLoader={props.openLoader}
            fields={R.of(Array, importTypeField)}
            closeLoader={props.closeLoader}
            validationSchema={importTypeSchema}
            defaultFields={{ [GC.FIELD_TYPE]: C.IMPORT_TYPE_DRIVER }}
            icon={I.downloadDocument(G.getTheme('icons.iconColor'), 21, 24)}
            btnText={G.getWindowLocale('actions:download', 'Download')}
            text={G.getWindowLocale('titles:download-document', 'Download Document')}
            downloadOptions={{
              asyncMethod: 'get',
              fileFormat: 'XLSX',
              fileName: 'template.xlsx',
              endpointName: 'importerTemplateFile',
            }}
          />
          <Flex cursor='pointer' onClick={handleOpenImportFromFile}>
            <Box mr={15}>
              {G.getWindowLocale('titles:import', 'Import')}
            </Box>
            <div>{I.downloadDocument(G.getTheme('colors.light.black'), 21, 24)}</div>
          </Flex>
        </Flex>
      </Flex>
      {
        R.or(
          G.isNotNilAndNotEmpty(errors),
          G.isNotNilAndNotEmpty(globalErrors),
        ) &&
        <ErrorsComponent
          errors={errors}
          setErrors={setErrors}
          globalErrors={globalErrors}
          setGlobalErrors={setGlobalErrors} />
      }
      {
        G.isNotNilAndNotEmpty(importType) &&
        <ImportTable
          errors={errors}
          importType={importType}
          carrierRatePrice={false}
          globalErrors={globalErrors}
        />
      }
    </PageWrapper>
  );
};

const mapPropsToValues = (state: Object) => createStructuredSelector({
  errors: makeSelectErrors(state),
  emptyRow: makeSelectEmptyRow(state),
  importType: makeSelectImportType(state),
  importList: makeSelectImportList(state),
  sourceType: makeSelectSourceType(state),
  rolesGuids: makeSelectRolesGuids(state),
  mappers: makeSelectImportMappers(state),
  globalErrors: makeSelectGlobalErrors(state),
  driverRoles: makeSelectDriverRoleAvailableList(state),
});

export default connect(mapPropsToValues, {
  openModal,
  setErrors,
  closeModal,
  openLoader,
  closeLoader,
  setSourceType,
  setRolesGuids,
  setGlobalErrors,
  changeImportList,
  uploadCLODocumentRequest,
  createImportFromFileRequest,
  removeMapAndImportFileRequest,
  createMapAndImportFromFileRequest,
})(enhance(Import));
