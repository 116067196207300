import * as R from 'ramda';
import React from 'react';
import { compose, withState, withHandlers, withPropsOnChange } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Flex } from '../../../ui';
// feature new-do
import { TabBox } from '../ui';
//////////////////////////////////////////////////

const getTextColor = (itemName: string, activeName: string, textColor: string) => {
  if (R.equals(itemName, activeName)) return G.getTheme('colors.dark.blue');

  if (G.isNotNilAndNotEmpty(textColor)) return textColor;

  return G.getTheme('colors.dark.grey');
};

const getBgColor = (itemName: string, activeName: string) => G.getTheme(G.ifElse(
  R.equals(itemName, activeName),
  'colors.light.teaGreen',
  'colors.white',
));

const Tabs = (props: Object) => (
  <Flex width={props.width} overflow={props.overflow} bg={G.getTheme('colors.bgGrey')}>
    {
      props.options.map((item: Object) => (
        <TabBox
          p='7px'
          mr='7px'
          height='30px'
          flexShrink='0'
          key={item.name}
          cursor='pointer'
          bg={getBgColor(item.name, props.activeTab)}
          onClick={() => props.handleClickTab(item.name)}
          color={getTextColor(item.name, props.activeTab, item.textColor)}
        >
          {item.text}
        </TabBox>
      ))
    }
  </Flex>
);

const enhance = compose(
  withState('activeTab', 'setActiveTab', ({ defaultActiveName }: Object) => defaultActiveName),
  withHandlers({
    handleClickTab: (props: Object) => (activeName: Object) => {
      const { setActiveTab, onClickTabCallback } = props;

      if (G.isFunction(onClickTabCallback)) {
        onClickTabCallback(activeName);
      }

      setActiveTab(activeName);
    },
  }),
  withPropsOnChange(['activeFromParent'], (props: Object) => {
    const { setActiveTab, activeFromParent } = props;

    if (G.isNotNilAndNotEmpty(activeFromParent)) {
      setActiveTab(activeFromParent);
    }
  }),
);

export default enhance(Tabs);
