import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

const defaultFieldStyles = {
  width: 150,
  flexDirection: 'row',
  alignItems: 'center',
  labelWidth: 'max-content',
  inputWrapMargin: '5px 10px',
};

const fieldSettings = [
  {
    ...defaultFieldStyles,
    type: 'toggle',
    fieldName: 'showAllPrices',
    loc: 'titles:show-all-prices',
  },
];

const enhance = compose(
  withFormik({
    mapPropsToValues: () => ({ showAllPrices: false }),
    handleSubmit: ({ showAllPrices }: Object, { props }: Object) => props.submitAction(showAllPrices),
  }),
  pure,
);

const ExportExcelResultsReportForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent
      {...G.getFormikProps(props)}
      fields={fieldSettings}
      flexDirection='column'
      fileName={G.getWindowLocale('titles:select-file', 'Select File')}
    />
    <FormFooter boxStyles={{ pt: 10 }} closeModal={props.closeModal} />
  </form>
);

export default enhance(ExportExcelResultsReportForm);
