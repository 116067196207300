import React from 'react';
import { CountryDropdown } from 'react-country-region-selector';
//////////////////////////////////////////////////

class CountrySelect extends React.Component {
  handleBlur = () => {
    const { id, setFieldTouched } = this.props;
    setFieldTouched(id, true);
  };

  handleChange = (value: string) => {
    const { id, setFieldValue } = this.props;
    setFieldValue(id, value);
  };

  render() {
    return (
      <CountryDropdown
        id={this.props.id}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        value={this.props.value || ''} />
    );
  }
}

export default CountrySelect;
