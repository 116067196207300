import * as R from 'ramda';
// helpers/constants
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.FIELD_DATE]: {
    width: 150,
    name: 'titles:date',
  },
  [GC.CURRENCY_TYPE_USD]: {
    width: 200,
    name: 'titles:usd',
    customComponent: ({ data }: Object) => R.path(['exchangeRatesMap', GC.CURRENCY_TYPE_USD], data),
  },
  [GC.CURRENCY_TYPE_CAD]: {
    width: 200,
    name: 'titles:cad',
    customComponent: ({ data }: Object) => R.path(['exchangeRatesMap', GC.CURRENCY_TYPE_CAD], data),
  },
  [GC.CURRENCY_TYPE_EUR]: {
    width: 150,
    name: 'titles:eur',
    customComponent: ({ data }: Object) => R.path(['exchangeRatesMap', GC.CURRENCY_TYPE_EUR], data),
  },
  [GC.CURRENCY_TYPE_MXN]: {
    width: 150,
    name: 'titles:mxn',
    customComponent: ({ data }: Object) => R.path(['exchangeRatesMap', GC.CURRENCY_TYPE_MXN], data),
  },
};

const fields = [
  { sequence: 1, name: GC.FIELD_DATE },
  { sequence: 2, name: GC.CURRENCY_TYPE_USD },
  { sequence: 3, name: GC.CURRENCY_TYPE_CAD },
  { sequence: 4, name: GC.CURRENCY_TYPE_EUR },
  { sequence: 5, name: GC.CURRENCY_TYPE_MXN },
];

export const getReport = (itemList: Array, activeTab: Object) => {
  if (R.equals(activeTab, 1)) {
    return {
      fields: R.reject(R.propEq(GC.CURRENCY_TYPE_USD, GC.FIELD_NAME), fields),
    };
  }

  return {
    fields: R.filter(
      ({ name }: Object) =>
        R.or(R.includes(name, R.keys(R.path([0, 'exchangeRatesMap'], itemList))), R.equals(name, GC.FIELD_DATE)),
      fields,
    ),
  };
};
