import React from 'react';
// features
import ReferencesComponent from '../../reference/components/reference';
//////////////////////////////////////////////////

const UserReferences = (props: Object) => {
  const {
    openModal,
    closeModal,
    references,
    handleCreateReference,
    handleUpdateReference,
    handleRemoveReference,
  } = props;

  return (
    <ReferencesComponent
      scope='user'
      openModal={openModal}
      closeModal={closeModal}
      references={references}
      handleCreateReference={handleCreateReference}
      handleUpdateReference={handleUpdateReference}
      handleRemoveReference={handleRemoveReference}
    />
  );
};

export default UserReferences;
