import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { LIST_OWN, LIST_AVAILABLE, LIST_OWN_AND_DOWN } from './constants';
// feature reference
import * as A from './actions';
//////////////////////////////////////////////////

const initialListProps = {
  list: null,
  totalCount: 0,
  pagination: {
    limit: 20,
    offset: 0,
  },
  entities: {
    referenceTypes: {},
  },
  result: [],
};

export const initialState = {
  scopes: [],
  loading: false,
  filterProps: [],
  usedReport: null,
  filterParams: {},
  currentReference: {},
  requestPending: false,
  [LIST_OWN]: initialListProps,
  activeList: LIST_OWN_AND_DOWN,
  availableReferenceTypesByScope: [],
  availableReferenceTypesByScopeName: {},
  [LIST_AVAILABLE]: initialListProps,
  [LIST_OWN_AND_DOWN]: initialListProps,
};

export const setInitialState = () => initialState;

const mapScopesEnum = (scopes: any) => R.map((item: string) => {
  if (G.isObject(item)) {
    return R.assoc(GC.FIELD_DISPLAYED_VALUE, G.getEnumLocale(R.path([GC.FIELD_NAME], item)), item);
  }
  return item;
}, R.or(scopes, []));

export const getAllAvailableRefTypesByScopeRequestSuccess = (state: Object, data: Array) => (
  P.$set('availableReferenceTypesByScope', data, state)
);

export const setAllAvailableRefTypesByScopeName = (state: Object, { data, scopeName }: Object) => (
  P.$set(`availableReferenceTypesByScopeName.${scopeName}`, data, state)
);

export const getReferenceScopesSuccess = (state: Object, data: Object) => (
  P.$set('scopes', mapScopesEnum(data), state)
);

export const getCurrentReferenceTypeSuccess = (state: Object, data: Object) => (
  P.$set('currentreference', data, state)
);

export const setReferenceTypesFilterParams = (state: Object, params: Object = {}) => (
  P.$set('filterParams', params, state)
);

export const clearReferenceTypesFilterParams = (state: Object) => (
  P.$set('filterParams', {}, state)
);

export const getItemListSuccess = (state: Object, data: Object) => {
  const { activeList } = state;
  const listOpts = state[activeList];
  const { pagination } = listOpts;
  const indexAdditional = G.ifElse(
    R.isNil(listOpts.list),
    0,
    R.length(R.values(listOpts.list)),
  );
  const newItems = data.results.results.map((item: Object, index: number) => R.mergeRight(
    item,
    {
      selected: false,
      index: R.add(index, indexAdditional),
      disabled: G.notEquals(data[GC.FIELD_CURRENT_BRANCH], item[GC.FIELD_BRANCH_GUID]),
    },
  ));
  const list = R.mergeRight(listOpts.list, R.indexBy(R.prop('guid'), newItems));
  const newOffset = R.add(pagination.offset, pagination.limit);

  return P.$all(
    P.$set(`${activeList}.list`, list),
    P.$set(`${activeList}.pagination.limit`, 10),
    P.$set(`${activeList}.totalCount`, data.results.totalCount),
    P.$set(
      `${activeList}.pagination.offset`,
      G.ifElse(
        R.gt(data.results.totalCount, newOffset),
        newOffset,
        data.results.totalCount,
      ),
    ),
    state,
  );
};

export const setActiveList = (state: Object, activeList: string) => (
  P.$set('activeList', activeList, state)
);

export const setListLoading = (state: Object, loading: boolean) => (
  P.$set('loading', loading, state)
);

export const selectItem = (state: Object, guid: Object) => {
  const list = R.path([state.activeList, 'list'], state);
  if (R.equals(guid, 'all')) {
    const value = R.not(R.all(
      (item: Object) => R.or(
        item.selected,
        item.disabled,
      ),
      R.values(list),
    ));
    return P.$set(
      `${state.activeList}.list`,
      R.map(
        (item: Object) => R.assoc(
          'selected',
          G.ifElse(item.disabled, false, value),
          item,
        ),
        list,
      ),
      state,
    );
  }
  return P.$toggle(`${state.activeList}.list.${guid}.selected`, state);
};

export const cleanQuickFilter = (state: Object) => (
  P.$set('filterParams', {}, state)
);

export const setQuickFilterParams = (state: Object, filterParams: Object) => (
  P.$set('filterParams', filterParams, state)
);

export const setFilterProps = (state: Object, data: Array) => (
  P.$set('filterProps', data, state)
);

export const resetListAndPagination = (state: Object) => {
  return (P.$all(
    P.$set(LIST_OWN, initialListProps),
    P.$set(LIST_AVAILABLE, initialListProps),
    P.$set(LIST_OWN_AND_DOWN, initialListProps),
    state,
  ));
};

export default createReducer({
  [A.selectItem]: selectItem,
  [A.setActiveList]: setActiveList,
  [A.setListLoading]: setListLoading,
  [A.setFilterProps]: setFilterProps,
  [A.setInitialState]: setInitialState,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.getReferenceScopesSuccess]: getReferenceScopesSuccess,
  [A.setReferenceTypesFilterParams]: setReferenceTypesFilterParams,
  [A.getCurrentReferenceTypeSuccess]: getCurrentReferenceTypeSuccess,
  [A.clearReferenceTypesFilterParams]: clearReferenceTypesFilterParams,
  [A.setAllAvailableRefTypesByScopeName]: setAllAvailableRefTypesByScopeName,
  [A.getAllAvailableRefTypesByScopeRequestSuccess]: getAllAvailableRefTypesByScopeRequestSuccess,
}, initialState);
