import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

const getGroupedByDate = R.compose(
  R.map(([key, value]: Array) => ({ title: key, list: value })),
  R.toPairs,
  R.groupBy((obj: Object) =>
    G.createLocalDateTimeFromInstanceOrISOString(obj.createdDate, 'MMMM D, YYYY'),
  ),
);

const hidePopup = (action: any, e: Object) => {
  if (G.isFunction(action)) {
    return action(e);
  }
};

const getItemRoute = (type: string, objectGuid: string, typeGroup: Object) => {
  const route = R.path([type, 'route'], typeGroup);

  if (G.isString(route)) return route;

  if (G.isFunction(route)) return route(objectGuid);

  return GC.ROUTE_PATH_HOME;
};

const getBorderStyles = (arr: Array, index: number) =>
  G.ifElse(
    R.equals(index, arr.length - 1),
    'none',
    `1px solid ${G.getTheme('colors.light.middleGrey')}`,
  );

export {
  hidePopup,
  getItemRoute,
  getBorderStyles,
  getGroupedByDate,
};
