import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { Tabs2 } from '../../../components/tabs-mui';
// features
import { startPivotTable } from '../../pivot-table/actions';
import { makeSelectPivotTableTotalCount } from '../../pivot-table/selectors';
import PivotTableWrapper from '../../pivot-table/components/pivot-table-wrapper';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
// feature payroll
import { getTabs } from '../helpers';
import { startVendorPayroll } from '../vendor-payroll/actions';
import { makeSelectVendorPayrollVisited } from '../vendor-payroll/selectors';
//////////////////////////////////////////////////

const PivotPayrolls = (props: Object) => {
  const {
    activeTab,
    totalCount,
    startPivotTable,
    handleSetActiveTab,
    startVendorPayroll,
    vendorPayrollVisited,
  } = props;

  const tabOptions = getTabs(activeTab);

  const tabs = G.ifElse(
    G.isCurrentUserTypeCarrier(),
    R.take(1, tabOptions),
    tabOptions,
  );

  const handleClickTab = (i: number) => {
    if (R.includes(i, [1, 2, 4, 5])) {
      const reportType = {
        1: GC.PIVOT_DRIVER_PAYROLL_REPORT,
        4: GC.PIVOT_VENDOR_PAYROLL_REPORT,
        2: GC.PIVOT_PAYROLL_CHARGES_REPORT,
        5: GC.PIVOT_VENDOR_PAYROLL_CHARGES_REPORT,
      }[i];
      startPivotTable(reportType);
    } else if (R.and(R.not(vendorPayrollVisited), R.equals(i, 3))) {
      startVendorPayroll();
    }

    handleSetActiveTab(i);
  };

  const customTitleComponent = (
    <Tabs2
      tabs={tabs}
      count={totalCount}
      activeTab={activeTab}
      setActiveTab={handleClickTab}
      tabStyles={GC.COMMON_MUI_TAB_STYLES}
      tabsStyles={GC.COMMON_MUI_TABS_STYLES}
    />
  );

  return (
    <PivotTableWrapper
      {...props}
      tabs={customTitleComponent}
      listWrapperStyles={{ height: 'calc(100vh - 60px)' }}
      pivotTypeFlexStyles={{ margin: '0px 15px', flexShrink: 0 }}
      relativeFlexStyles={{ paddingTop: '5px', alignItems: 'normal' }}
    />
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  totalCount: makeSelectPivotTableTotalCount(state),
  vendorPayrollVisited: makeSelectVendorPayrollVisited(state),
});

export default connect(mapStateToProps, {
  startPivotTable,
  startVendorPayroll,
})(PivotPayrolls);
