import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectGlobalStore = (state: Object) => state.global;
const selectPermissionsStore = (state: Object) => state.permissions;

const makeSelectInitialDataLoadingStatus = () => createSelector(
  selectGlobalStore,
  (global: Object) => global.initialDataLoading,
);

const makeSelectInitialDataLoadedStatus = () => createSelector(
  selectGlobalStore,
  (global: Object) => global.initialDataLoaded,
);

const makeSelectAuthorities = () => createSelector(
  selectPermissionsStore,
  (permissionsState: Object) => permissionsState.authorities,
);

const makeSelectPermissionsList = () => createSelector(
  selectPermissionsStore,
  (permissionsState: Object) => permissionsState.permissionsList,
);

const makeSelectAccessToPagesList = () => createSelector(
  selectPermissionsStore,
  (permissionsState: Object) => permissionsState.routesAccess,
);

export {
  makeSelectAuthorities,
  selectPermissionsStore,
  makeSelectPermissionsList,
  makeSelectAccessToPagesList,
  makeSelectInitialDataLoadedStatus,
  makeSelectInitialDataLoadingStatus,
};
