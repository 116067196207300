import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose, withProps, withHandlers } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
import { LocalLoader } from '../../../components/local-loader';
import { openModal, closeModal } from '../../../components/modal/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  FLAT_PERCENT_RATE_UNIT_OPTIONS,
  getCustomerContractUpliftApplyToEnumOptions,
} from '../../../helpers/options';
// forms
import { FieldsetComponent } from '../../../forms';
// hocs
import { withAsyncConfigs } from '../../../hocs';
//////////////////////////////////////////////////

const defaultStyles = {
  width: '250px',
  afterTop: '15px',
  errorTop: '110%',
  errorLeft: '5px',
  errorTitle: true,
  afterRight: '15px',
  errorWidth: '100%',
  errorOverflow: 'hidden',
  flexDirection: 'column',
  inputWrapMargin: '5px 0',
  errorPosition: 'absolute',
  labelWidth: 'max-content',
  errorWhiteSpace: 'nowrap',
  errorTextOverflow: 'ellipsis',
  inputsFlexDirection: 'column',
};

const getApplyToOptions = (values: Object) => {
  const { rateUnit } = values;

  if (R.equals(rateUnit, GC.CHARGE_RATE_UNIT_TYPE_PERCENT)) {
    return getCustomerContractUpliftApplyToEnumOptions();
  }

  return R.tail(getCustomerContractUpliftApplyToEnumOptions());
};

const getFieldSettings = ({ values }: Object) => ([
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:sync-charges',
    fieldName: GC.FIELD_SYNC_CHARGES,
  },
  {
    ...defaultStyles,
    type: 'number',
    isRequired: true,
    loc: 'titles:uplift-value',
    fieldName: GC.FIELD_UPLIFT_VALUE,
  },
  {
    ...defaultStyles,
    type: 'select',
    isRequired: true,
    loc: 'titles:uplift-unit',
    fieldName: GC.FIELD_RATE_UNIT,
    options: G.prependEmptyLabelValueOption(FLAT_PERCENT_RATE_UNIT_OPTIONS),
  },
  {
    ...defaultStyles,
    type: 'select',
    isRequired: true,
    loc: 'titles:apply-to',
    fieldName: GC.FIELD_APPLY_TO,
    options: getApplyToOptions(values),
  },
]);

const defaultFields = {
  [GC.FIELD_APPLY_TO]: null,
  [GC.FIELD_RATE_UNIT]: null,
  [GC.FIELD_UPLIFT_VALUE]: 0,
  [GC.FIELD_SYNC_CHARGES]: false,
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_APPLY_TO]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_RATE_UNIT]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_SYNC_CHARGES]: Yup.boolean().nullable(true).required(G.getRequiredLocaleTxt),
  [GC.FIELD_UPLIFT_VALUE]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .positive(G.getShouldBePositiveLocaleTxt())
    .max(9999999, G.getShouldBeLessOrEqualLocaleTxt(999999))
    .typeError('Should be a ${type}'), // eslint-disable-line
});

const ApplyOrderRateUpliftForm = (props: Object) => (
  <LocalLoader minWidth={250} localLoaderOpen={G.isNilOrEmpty(props.asyncConfigs)}>
    <form onSubmit={props.handleSubmit}>
      <FieldsetComponent {...G.getFormikProps(props)} flexDirection='column' fields={getFieldSettings(props)} />
      <FormFooter boxStyles={{ pt: 10 }} closeModal={props.closeModal} />
    </form>
  </LocalLoader>
);

const enhance = compose(
  withProps({
    configsNamesArray: [
      GC.CLO_RATE_SYNC_UPLIFT_UNIT,
      GC.CLO_RATE_SYNC_CHARGES,
      GC.CLO_RATE_SYNC_UPLIFT_VALUE,
      GC.CLO_RATE_SYNC_UPLIFT_APPLY_TO,
    ],
  }),
  withAsyncConfigs,
  withProps((props: Object) => {
    const { asyncConfigs } = props;

    return {
      initialValues: {
        [GC.FIELD_RATE_UNIT]: G.getConfigValueFromStore(GC.CLO_RATE_SYNC_UPLIFT_UNIT, asyncConfigs),
        [GC.FIELD_APPLY_TO]: G.getConfigValueFromStore(GC.CLO_RATE_SYNC_UPLIFT_APPLY_TO, asyncConfigs),
        [GC.FIELD_SYNC_CHARGES]: G.getConfigValueFromStore(GC.CLO_RATE_SYNC_CHARGES, asyncConfigs),
        [GC.FIELD_UPLIFT_VALUE]: G.getConfigValueFromStore(GC.CLO_RATE_SYNC_UPLIFT_VALUE, asyncConfigs),
      },
    };
  }),
  withFormik({
    validationSchema,
    enableReinitialize: true,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultFields,
      initialValues,
    ),
  }),
  pure,
);

const EnhancedApplyOrderRateUpliftForm = enhance(ApplyOrderRateUpliftForm);

export const withApplyOrderRateUpliftForm = ({ submitActionName }: Object) => compose(
  connect(null, { openModal, closeModal }),
  withHandlers({
    handleApplyOrderRateUpliftForm: (props: Object) => (data: Object) => {
      const { openModal, closeModal } = props;

      const submitAction = (values: Object) => {
        closeModal();
        G.callFunctionWith2Args(props[submitActionName], values, data);
      };

      const component = (
        <EnhancedApplyOrderRateUpliftForm
          closeModal={closeModal}
          submitAction={submitAction}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 'max-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:apply-order-rate-uplift', 'Apply Order Rate Uplift'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export default EnhancedApplyOrderRateUpliftForm;
