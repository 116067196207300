
import * as R from 'ramda';
import React from 'react';
import {
  pure,
  compose,
  withState,
  lifecycle,
  withHandlers,
} from 'react-recompose';
// components
import { Table } from '../../../components/table';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature rate-shop
import {
  priceListReport,
  priceListTableSettings,
  priceListColumnSettings,
} from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withState('itemList', 'setItemList', []),
  withHandlers({
    handleGetItemList: (props: Object) => async () => {
      const { setItemList, openLoader, closeLoader, excelRatingResultGuid } = props;

      openLoader();
      const options = {
        params: { excelRatingResultGuid },
      };
      const endpoint = endpointsMap.excelMassRatingResultPriceList;

      const res = await sendRequest('get', endpoint, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const mapper = R.map(R.prop(GC.FIELD_DISPLAYED_VALUE));
        const itemList = R.map((item: Object) => {
          const { carrierEquipments, carrierServiceTypes } = item;

          return R.mergeRight(
            item,
            {
              [GC.FIELD_RATE_SHOP_CARRIER_EQUIPMENTS]: mapper(R.or(carrierEquipments, [])),
              [GC.FIELD_RATE_SHOP_CARRIER_SERVICE_TYPES]: mapper(R.or(carrierServiceTypes, [])),
              [GC.FIELD_RATE_SHOP_CARRIER_TRANSPORTATION_MODE]: R.path(
                [GC.FIELD_RATE_SHOP_CARRIER_TRANSPORTATION_MODE, GC.FIELD_DISPLAYED_VALUE],
                item,
              ),
            },
          );
        }, R.or(data, []));

        setItemList(itemList);
      } else {
        G.handleFailResponseSimple(res);
      }

      closeLoader();
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetItemList();
    },
  }),
  pure,
);

const PriceList = (props: Object) => (
  <Table
    hasSelectable={false}
    report={priceListReport}
    itemList={props.itemList}
    tableSettings={priceListTableSettings}
    columnSettings={priceListColumnSettings}
  />
);

export default enhance(PriceList);
