import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { getConfirmModal } from '../../../components/confirm';
import { openModal, closeModal } from '../../../components/modal/actions';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature quotes
import CancelOrDeclineOrderQuoteForm from './cancel-or-decline-order-quote-form';
import { cancelOrderQuoteRequest, acceptOrderQuoteRequest, declineOrderQuoteRequest } from '../actions';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');

const enhance = compose(
  connect(
    null,
    {
      openModal,
      closeModal,
      acceptOrderQuoteRequest,
      cancelOrderQuoteRequest,
      declineOrderQuoteRequest,
    },
  ),
  withHandlers({
    handleCreateOrder: ({ entity }: Object) => () => {
      const options = {
        [GC.FIELD_GUID]: G.getGuidFromObject(entity),
        [GC.FIELD_SOURCE_TYPE]: GC.CREATE_DO_SOURCE_TYPE_ORDER_QUOTE,
      };

      G.goToRoute(routesMap.newDOBySourceType(options));
    },
    handleAcceptOrderQuote: (props: Object) => () => {
      const {
        entity,
        openModal,
        closeModal,
        acceptOrderQuoteRequest,
      } = props;

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:accept', 'Accept'),
        submitAction: () => acceptOrderQuoteRequest(G.getGuidFromObject(entity)),
        text: `${G.getWindowLocale(
          'messages:before-accept',
          'Are you sure you want to accept',
        )}?`,
      });

      openModal(modalContent);
    },
    handleCancelOrDeclineOrderQuote: (props: Object) => (type: string) => {
      const {
        entity,
        openModal,
        closeModal,
        cancelOrderQuoteRequest,
        declineOrderQuoteRequest,
      } = props;

      const submitAction = G.ifElse(
        R.equals(type, 'cancel'),
        cancelOrderQuoteRequest,
        declineOrderQuoteRequest,
      );
      const statusReasonCodeConfigName = G.ifElse(
        R.equals(type, 'cancel'),
        GC.CLO_QUOTE_CANCEL_REASON_CODE,
        GC.CLO_QUOTE_DECLINE_REASON_CODE,
      );
      const titleArr = G.ifElse(
        R.equals(type, 'cancel'),
        ['titles:cancel-order-quote', 'Cancel Order Quote'],
        ['titles:decline-order-quote', 'Decline Order Quote'],
      );

      const component = (
        <CancelOrDeclineOrderQuoteForm
          closeModal={closeModal}
          submitAction={submitAction}
          guid={G.getGuidFromObject(entity)}
          configsNamesArray={[statusReasonCodeConfigName]}
          branchGuid={G.getAmousCurrentBranchGuidFromWindow()}
          statusReasonCodeConfigName={statusReasonCodeConfigName}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title: G.getWindowLocale(...titleArr),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const renderAction = (entity: Object) => {
  const { guid, status } = entity;

  let options = {};

  if (R.equals(status, GC.ORDER_QUOTE_STATUS_TYPE_PROCESSED)) {
    options = {
      permissions: [PC.CLO_READ, PC.CLO_WRITE],
      title: G.getWindowLocale('actions:go-to-clo-details', 'Go To CLO Details'),
      action: () => G.goToRoute(routesMap.dispatchDetailsOrder(
        G.getPropFromObject(`${GC.FIELD_CREATED_CLO}.${GC.FIELD_GUID}`, entity),
      )),
    };
  }

  if (R.equals(status, GC.ORDER_QUOTE_STATUS_TYPE_ACCEPTED)) {
    options = {
      permissions: [PC.ORDER_QUOTE_WRITE],
      title: G.getWindowLocale('actions:create-clo', 'Create CLO'),
      action: () => {
        const options = {
          guid,
          [GC.FIELD_SOURCE_TYPE]: GC.CREATE_DO_SOURCE_TYPE_ORDER_QUOTE,
        };

        G.goToRoute(routesMap.newDOBySourceType(options));
      },
    };
  }

  if (R.isEmpty(options)) return null;

  const { title, action, permissions } = options;

  return (
    <AuthWrapper has={permissions}>
      <Box title={title} cursor='pointer' onClick={action}>
        {I.routesLoads(darkBlueColor)}
      </Box>
    </AuthWrapper>
  );
};

const FreezedRowActions = (props: Object) => {
  const {
    entity,
    handleCancelOrDeclineOrderQuote,
  } = props;

  const status = G.getPropFromObject(GC.FIELD_STATUS, entity);
  const showCancel = G.notContain(status, [GC.ORDER_QUOTE_STATUS_TYPE_CANCELED, GC.ORDER_QUOTE_STATUS_TYPE_PROCESSED]);

  return (
    <Flex alignItems='center' height='max-content' color={darkBlueColor}>
      {
        showCancel &&
        <AuthWrapper has={[PC.ORDER_QUOTE_WRITE]}>
          <Box
            mr={10}
            cursor='pointer'
            textDecoration='underline'
            onClick={() => handleCancelOrDeclineOrderQuote('cancel')}
          >
            {G.getWindowLocale('actions:cancel', 'Cancel')}
          </Box>
        </AuthWrapper>
      }
      {renderAction(entity)}
    </Flex>
  );
};

export default enhance(FreezedRowActions);
