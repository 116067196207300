import * as R from 'ramda';
import styled from 'styled-components';
import { space, fontSize } from 'styled-system';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Flex } from '../../ui';
//////////////////////////////////////////////////

const EmptyList = styled.div`
  width: 100%;
  padding: 50px 0;
  font-size: 36px;
  text-align: center;
  color: ${() => G.getTheme('colors.light.lightGrey')};
`;

const CalendarWrapper = styled(Flex)`
  & .react-datepicker__navigation {
    z-index: 11;
  }
  & input {
    color: ${({ color }: Object) => color || 'unset'};
    border-color: ${({ borderColor }: Object) => borderColor || G.getTheme('forms.inputs.borderColor')};
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow: auto;
  max-height: ${({ maxH }: Object) => R.or(maxH, 500)};
  border: 1px solid ${() => G.getTheme('tables.rows.borderColor')};
`;

const TableWrapper2 = styled.div`
  width: 100%;
  overflow: auto;
  max-height: ${({ maxH }: Object) => R.or(maxH, 500)};
  border-top: 1px solid ${() => G.getTheme('tables.rows.borderColor')};
`;

const TableInner = styled.div`
  width: 100%;
  min-width: ${({ minWidth }: Object) => minWidth};
`;

const setTableRowBackground = (props: Object) => R.or(
  props.bgColor,
  G.getTheme(G.ifElse(
    props.active,
    G.ifElse(
      props.mainColors,
      'tables.rows.bgColorActive',
      'tables.rows.additionalBgColor',
    ),
    'tables.rows.bgColor',
  )),
);

const setTableBorderBottom = (props: Object) => {
  const { active, mainColors, lightGreyBorderBottom } = props;

  if (G.isTrue(lightGreyBorderBottom)) return G.getTheme('colors.lightGrey');

  return G.getTheme(G.ifElse(
    active,
    G.ifElse(
      mainColors,
      'tables.rows.borderColorActive',
      'tables.rows.additionalBorderColor',
    ),
    ' ',
  ));
};

const TableRow = styled.div`
  ${space}
  ${fontSize}

  display: flex;
  min-width: 100%;
  width: fit-content;

  min-height: ${({ height }: Object) => R.or(height, 40)}px;
  background-color: ${(props: Object) => setTableRowBackground(props)};
  border-bottom: 1px solid ${(props: Object) => setTableBorderBottom(props)};
`;

const Checkbox = styled.input`
  ${space}

  z-index: 0;
  position: relative;

  &:focus {
    outline: none;
  }

  &:before {
    left: 0;
    top: 50%;
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 2px;
    position: absolute;
    transform: translate(0, -50%);

    background-color: ${() => G.getTheme('forms.checkboxes.bgColor')};
    border: 1px solid ${() => G.getTheme('forms.checkboxes.borderColor')};
  }

  &:checked:before {
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    border-radius: 2px;

    background-color: ${() => G.getTheme('forms.checkboxes.bgColorChecked')};
    border: 1px solid ${() => G.getTheme('forms.checkboxes.borderColorChecked')};
  }

  &:checked:after {
    border: solid ${() => G.getTheme('forms.checkboxes.textColorChecked')};

    left: 0;
    top: 50%;
    width: 3px;
    height: 8px;
    content: '';
    position: absolute;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) translate(0, -90%);
  }
`;

const CreateButtonUi = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  font-size: 26px;
  position: fixed;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  right: ${({ right }: Object) => right};
  color: ${({ color }: Object) => color};
  background-color: ${({ bgColor }: Object) => bgColor};
  bottom: ${({ bottom }: Object) => R.or(bottom, '10px')};
  box-shadow: 1px 1px 26px ${({ shadowColor}: Object) => shadowColor};
`;

export {
  Checkbox,
  TableRow,
  EmptyList,
  TableInner,
  TableWrapper,
  TableWrapper2,
  CreateButtonUi,
  CalendarWrapper,
};
