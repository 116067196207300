import * as R from 'ramda';
import React from 'react';
import { FieldArray } from 'formik';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature new-do
import { Fieldset } from './formik/fieldset';
import { contactFieldSettings, getDefaultContactFields } from '../settings/fields-settings';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');

const Header = ({ push }: Object) => (
  <Flex px='10px' height='30px' bg={G.getTheme('colors.bgGrey')}>
    <Box fontWeight={600} color={G.getTheme('colors.greyMatterhorn')}>
      {G.getWindowLocale('titles:contacts', 'Contacts')}
    </Box>
    <Box
      mx='10px'
      height='15px'
      cursor='pointer'
      onClick={() => push(getDefaultContactFields())}
      title={G.getWindowLocale('titles:add-new-item', 'Add new item')}
    >
      {I.plusRound(darkBlueColor)}
    </Box>
  </Flex>
);

const ContactRow = ({ form, remove, itemIndex, arrayLength }: Object) => (
  <Flex my='25px'>
    <Box>
      <Flex
        mx={10}
        display='flex'
        cursor='pointer'
        alignItems='center'
        onClick={() => remove(itemIndex)}
        title={G.getWindowLocale('titles:remove', 'Remove')}
      >
        {I.trash(darkBlueColor)}
      </Flex>
    </Box>
    <Fieldset
      {...form}
      fieldsetType='array'
      itemIndex={itemIndex}
      arrayLength={arrayLength}
      fields={contactFieldSettings}
      arrayName={GC.FIELD_CONTACTS}
    />
  </Flex>
);

const ContactsArray = ({ contacts }: Object) => (
  <Box mt={20}>
    <FieldArray
      name={GC.FIELD_CONTACTS}
      render={({ form, push, remove }: Object) => (
        <Box>
          <Header push={push} />
          {
            G.isNotNilAndNotEmpty(contacts) &&
            contacts.map((_: any, index: number) => (
              <ContactRow form={form} key={index} remove={remove} itemIndex={index} arrayLength={R.length(contacts)} />
            ))
          }
        </Box>
      )}
    />
  </Box>
);

export default ContactsArray;
