import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature public-clo
import * as A from './actions';
//////////////////////////////////////////////////

const initialState = {
  activeTab: 0,
  totalCount: 0,
  loading: false,
  itemList: null,
  usedReport: {},
  pagination: {
    limit: 20,
    offset: 0,
  },
};

const setInitialState = () => initialState;

const setUsedReport = (state: Object, data: Object) => (
  P.$all(
    P.$set('itemList', {}),
    P.$set('totalCount', 0),
    P.$set('usedReport', data),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const setListLoading = (state: Object, loading: boolean) => P.$set('loading', loading, state);

const resetListAndPagination = (state: Object) => (
  P.$all(
    P.$set('totalCount', 0),
    P.$set('itemList', null),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const getReportListSuccess = (state: Object, data: Object) => {
  const { itemList, pagination } = state;

  if (R.equals(data.totalCount, 0)) return state;
  const indexAdditional = R.length(R.values(itemList));
  const newItems = data.results.map((item: Object, index: number) => R.assoc(
    'index',
    R.add(index, indexAdditional),
    item,
  ));
  const list = R.mergeRight(itemList, R.indexBy(R.prop(GC.FIELD_GUID), newItems));
  const newOffset = R.add(pagination.offset, pagination.limit);

  return P.$all(
    P.$set('itemList', list),
    P.$set('pagination.limit', 15),
    P.$set('totalCount', data.totalCount),
    P.$set(
      'pagination.offset',
      G.ifElse(
        R.gt(data.totalCount, newOffset),
        newOffset,
        data.totalCount,
      ),
    ),
    state,
  );
};

const setActiveTab = (state: Object, activeTab: number) => P.$set('activeTab', activeTab, state);

export default createReducer({
  [A.setActiveTab]: setActiveTab,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.getReportListSuccess]: getReportListSuccess,
  [A.resetListAndPagination]: resetListAndPagination,
}, initialState);
