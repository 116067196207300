import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// common
import { updateReportFromReportListSuccess } from '../../common/actions';
// report-common
import { getReportReducers } from '../../report-common';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature quotes
import * as A from './actions';
//////////////////////////////////////////////////

const initial = {
  listType: null,
};

const {
  setReports,
  initialState,
  setUsedReport,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  setInitialStateOmitReport,
  handleUpdateReportFromReportListSuccess,
} = getReportReducers(initial);

const setListType = (state: Object, data: string) => P.$set('listType', data, state);

const selectQuoteListItem = (state: Object, data: Object) => {
  const { itemList } = state;

  if (R.equals(data, 'all')) {
    const value = R.not(R.all(
      (item: Object) => item.selected,
      R.values(itemList),
    ));

    return P.$set(
      'itemList',
      R.map(
        (item: Object) => R.assoc('selected', value, item),
        itemList,
      ),
      state,
    );
  }

  return P.$toggle(`itemList.${data}.selected`, state);
};

const removeQuotesSuccess = (state: Object, guids: Array) => (
  P.$all(
    P.$set('pagination.offset', 0),
    P.$set('itemList', R.omit(guids, state.itemList)),
    P.$set('totalCount', R.subtract(state.totalCount, R.length(guids))),
    state,
  )
);

// order quotes
const changeOrderQuoteStatus = (state: Object, { guid, status, statusReasonCodeDisplayedValue }: Array) => {
  const statusReasonKey = G.ifElse(
    R.equals(status, GC.ORDER_QUOTE_STATUS_TYPE_CANCELED),
    `${GC.FIELD_CANCEL_REASON_CODE}.${GC.FIELD_DISPLAYED_VALUE}`,
    `${GC.FIELD_DECLINE_REASON_CODE}.${GC.FIELD_DISPLAYED_VALUE}`,
  );

  return P.$merge(`itemList.${guid}`, { status, [statusReasonKey]: statusReasonCodeDisplayedValue }, state);
};

// report
const getItemListSuccess = (state: Object, { data, guids }: Object) => {
  const { results, totalCount } = data;

  const { itemList, pagination } = state;

  if (G.isNilOrEmpty(results)) return state;

  if (R.and(G.isNotNilAndNotEmpty(guids), R.equals(R.length(results), 1))) {
    const guid = R.path([0, GC.FIELD_GUID], results);

    if (G.isNotNil(R.prop(guid, itemList))) {
      return P.$set(`itemList.${guid}`, R.mergeRight(R.prop(guid, itemList), R.head(results)), state);
    }
  }

  const indexAdditional = G.ifElse(
    R.isNil(itemList),
    0,
    R.length(R.values(itemList)),
  );

  const newItems = results.map((item: Object, i: number) => R.mergeRight(
    item,
    {
      selected: false,
      index: R.add(i, indexAdditional),
      ...R.pathOr({}, ['quote'], item),
      ...R.pathOr({}, [GC.FIELD_CLOS], item),
    },
  ));

  const newOffset = R.add(pagination.offset, pagination.limit);
  const list = R.mergeRight(itemList, R.indexBy(R.prop(GC.FIELD_GUID), newItems));

  return P.$all(
    P.$set('itemList', list),
    P.$set('pageVisited', true),
    P.$set('pagination.limit', 10),
    P.$set('totalCount', totalCount),
    P.$set(
      'pagination.offset',
      G.ifElse(
        R.gt(totalCount, newOffset),
        newOffset,
        totalCount,
      ),
    ),
    state,
  );
};

export default createReducer({
  [A.setListType]: setListType,
  [A.removeQuotesSuccess]: removeQuotesSuccess,
  [A.selectQuoteListItem]: selectQuoteListItem,
  [A.changeOrderQuoteStatus]: changeOrderQuoteStatus,
  // report
  [A.setReports]: setReports,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.setInitialStateOmitReport]: setInitialStateOmitReport,
  [updateReportFromReportListSuccess]: handleUpdateReportFromReportListSuccess,
}, initialState);
