import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as GC from '../../constants';
// feature roles
import * as A from './actions';
//////////////////////////////////////////////////

const initialState = {
  roles: [],
  settings: null,
  currentUserSettings: null,
  [GC.UI_FIELD_REFERENCES]: [],
  availableByUserTypeRoleList: null,
};

const setInitialState = () => initialState;

const setCurrentUserProfilePhotoUrl = (state: Object, url: string) =>
  P.$set('currentUserSettings.profilePhotoUrl', url, state);

const getCurrentUserSettingsSuccess = (state: Object, data: Object) => (
  P.$set('currentUserSettings', data, state)
);

const getCurrentUserSettingsFail = (state: Object) => (
  P.$set('currentUserSettings', null, state)
);

const getUserSettingsSuccess = (state: Object, data: Object) => (
  P.$set('settings', data, state)
);

const getUserSettingsFail = (state: Object) => (
  P.$set('settings', null, state)
);

const getUserReferencesSuccess = (state: Object, data: Object) => (
  P.$set(GC.UI_FIELD_REFERENCES, data, state)
);

const getUserReferencesFail = (state: Object) => (
  P.$set(GC.UI_FIELD_REFERENCES, [], state)
);

const createUserReferenceSuccess = (state: Object, data: Object) => (
  P.$add(GC.UI_FIELD_REFERENCES, { [data.guid]: data }, state)
);

const updateUserReferenceSuccess = (state: Object, data: Object) => (
  P.$merge(`${GC.UI_FIELD_REFERENCES}.${data.guid}`, data, state)
);

const deleteUserReferenceSuccess = (state: Object, guid: Object) => (
  P.$drop(`${GC.UI_FIELD_REFERENCES}.${guid}`, state)
);

const getUserProfileRolesSuccess = (state: Object, data: Object) => (
  P.$set('roles', data, state)
);

const getUserProfileRolesFail = (state: Object) => (
  P.$set('roles', [], state)
);

const changeUserSuspendedStatusSuccess = (state: Object) => (
  P.$toggle('settings.active', state)
);

const pinBranchSuccess = (state: Object, data: Array = []) => (
  P.$set(`currentUserSettings.${GC.PINNED_BRANCHES_GUIDS}`, data, state)
);

const changePinnedSuccess = (state: Object, data: Array = []) => (
  P.$set('currentUserSettings.pinnedMenuItems', data, state)
);

const getAvailableByUserTypeRoleListSuccess = (state: Object, data: Object) => (
  P.$set('availableByUserTypeRoleList', data, state)
);

export default createReducer({
  [A.setInitialState]: setInitialState,
  [A.pinBranchSuccess]: pinBranchSuccess,
  [A.getUserSettingsFail]: getUserSettingsFail,
  [A.getUserReferencesFail]: getUserReferencesFail,
  [A.getUserSettingsSuccess]: getUserSettingsSuccess,
  [A.getUserProfileRolesFail]: getUserProfileRolesFail,
  [A.changePinnedMenuItemsSuccess]: changePinnedSuccess,
  [A.getUserReferencesSuccess]: getUserReferencesSuccess,
  [A.getCurrentUserSettingsFail]: getCurrentUserSettingsFail,
  [A.createUserReferenceSuccess]: createUserReferenceSuccess,
  [A.updateUserReferenceSuccess]: updateUserReferenceSuccess,
  [A.deleteUserReferenceSuccess]: deleteUserReferenceSuccess,
  [A.getUserProfileRolesSuccess]: getUserProfileRolesSuccess,
  [A.setCurrentUserProfilePhotoUrl]: setCurrentUserProfilePhotoUrl,
  [A.getCurrentUserSettingsSuccess]: getCurrentUserSettingsSuccess,
  [A.changeUserSuspendedStatusSuccess]: changeUserSuspendedStatusSuccess,
  [A.getAvailableByUserTypeRoleListSuccess]: getAvailableByUserTypeRoleListSuccess,
}, initialState);
