import * as R from 'ramda';
import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const store = (state: Object) => state.imports;

const makeSelectImportList = () => createSelector(
  store,
  ({ importList }: Object) => importList,
);

const makeSelectEmptyRow = () => createSelector(
  store,
  ({ emptyRow }: Object) => emptyRow,
);

const makeSelectImportType = () => createSelector(
  store,
  ({ importType }: Object) => importType,
);

const makeSelectSourceType = () => createSelector(
  store,
  ({ sourceType }: Object) => sourceType,
);

const makeSelectRolesGuids = () => createSelector(
  store,
  ({ rolesGuids }: Object) => rolesGuids,
);

const makeSelectConfigOptions = () => createSelector(
  store,
  ({ configOptions }: Object) => configOptions,
);

const makeSelectConfigByImportType = () => createSelector(
  store,
  ({ importType, configOptions }: Object) => R.pathOr({}, [importType], configOptions),
);

const makeSelectImportMappers = () => createSelector(
  store,
  ({ mappers, importType }: Object) => R.pathOr([], [importType], mappers),
);

const makeSelectCleanExistentRates = () => createSelector(
  store,
  ({ cleanExistentRates }: Object) => cleanExistentRates,
);

const makeSelectContractGuid = () => createSelector(
  store,
  ({ contractGuid }: Object) => contractGuid,
);

const makeSelectGlobalErrors = () => createSelector(
  store,
  ({ globalErrors }: Object) => globalErrors,
);

const makeSelectErrors = () => createSelector(
  store,
  ({ errors }: Object) => errors,
);

export {
  makeSelectErrors,
  makeSelectEmptyRow,
  makeSelectSourceType,
  makeSelectImportType,
  makeSelectImportList,
  makeSelectRolesGuids,
  makeSelectGlobalErrors,
  makeSelectContractGuid,
  makeSelectConfigOptions,
  makeSelectImportMappers,
  makeSelectCleanExistentRates,
  makeSelectConfigByImportType,
};
