import * as R from 'ramda';
import { put, call, fork, takeLatest } from 'redux-saga/effects';
// components
import { getConfirmModal } from '../../components/confirm';
import { openModal, closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature load board
import { loginLoadBoardRequest } from '../load-board/actions';
// feature oauth-popup
import * as A from './actions';
import oAuthPopupWatcherSaga from './popup-sagas';
import {
  AMOUS_MAIN_NAME,
  openLBLoginPopup,
  OAUTH2_CODE_RESPONSE,
  setAuthStateToStorage,
  getLoadBoardOAuth2PopupPath,
} from './helpers';
//////////////////////////////////////////////////

const OPEN_DIALOG_ERROR = 'open-dialog-error';
const CLOSE_POPUP_ERROR = 'close-popup-error';

function* showConfirmModal() {
  let resolveDialog;

  const dialogPromise = new Promise(res => {
    resolveDialog = res;
  });

  const modalContent = getConfirmModal({
    cancelAction: resolveDialog,
    cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
    submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
    text: G.getWindowLocale(
      'messages:popups-are-blocked',
      'Popups are blocked on this page! Please, allow popups to login.',
    ),
    submitAction: () => {
      resolveDialog();
    },
  });

  yield put(openModal(modalContent));

  yield call(() => dialogPromise);

  yield put(closeModal());
}

function getPopupPromise(path) {
  window.name = AMOUS_MAIN_NAME;

  let popupWindow;
  let closeWindowInterval;

  const listenLoginPopup = new Promise((res, rej) => {
    const handlePopupMessageListener = message => {
      const { type, code } = R.pathOr({}, ['data'], message);

      if (R.not(R.equals(type, OAUTH2_CODE_RESPONSE))) return;

      res(code);

      window.removeEventListener('message', handlePopupMessageListener);
    };

    window.addEventListener('message', handlePopupMessageListener);

    popupWindow = openLBLoginPopup(path);

    if (R.isNil(popupWindow)) {
      window.removeEventListener('message', handlePopupMessageListener);

      rej(new Error(OPEN_DIALOG_ERROR));

      return;
    }

    closeWindowInterval = setInterval(() => {
      if (popupWindow?.closed) {
        clearInterval(closeWindowInterval);

        window.removeEventListener('message', handlePopupMessageListener);

        rej(new Error(CLOSE_POPUP_ERROR));
      }
    }, 100);
  });

  return {
    popupWindow,
    listenLoginPopup,
    closeWindowInterval,
  };
}

function* handleOpenPopupSaga(path: string) {
  yield put(openLoader({ showDimmer: true }));

  const {
    popupWindow,
    listenLoginPopup,
    closeWindowInterval,
  } = getPopupPromise(path);

  try {
    const code = yield call(() => listenLoginPopup);

    clearInterval(closeWindowInterval);

    popupWindow?.close();

    yield put(closeLoader());
    yield put(closeModal());

    return code;
  } catch ({ message }) {
    yield put(closeLoader());
    yield put(closeModal());

    if (R.equals(message, OPEN_DIALOG_ERROR)) {
      yield call(showConfirmModal);
      yield call(handleOpenPopupSaga, path);
    }
  }
}

function* handleLoginToLoadBoardUberSaga() {
  try {
    const { path, state } = getLoadBoardOAuth2PopupPath(GC.EXTERNAL_LOAD_BOARD_UBER);

    setAuthStateToStorage(state);

    const code = yield call(handleOpenPopupSaga, path);

    if (code) {
      yield put(loginLoadBoardRequest({
        accountId: code,
        type: GC.EXTERNAL_LOAD_BOARD_UBER,
      }));
    } else {
      yield put(closeModal());
      // TODO: maybe show no code warning
    }
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleLoginToLoadBoardUberSaga exception');
  }
}

function* handleLoginToLoadBoard123Saga() {
  try {
    const { path, state, accountId } = getLoadBoardOAuth2PopupPath(GC.EXTERNAL_LOAD_BOARD_LB123);

    setAuthStateToStorage(state);

    const code = yield call(handleOpenPopupSaga, path);

    if (code) {
      yield put(loginLoadBoardRequest({
        accountId: code,
        type: GC.EXTERNAL_LOAD_BOARD_LB123,
      }));
    } else {
      yield put(closeModal());
      // TODO: maybe show no code warning
    }
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleLoginToLoadBoard123Saga exception');
  }
}

function* oAuthWatcherSaga() {
  yield takeLatest(A.loginLoadBoardUber, handleLoginToLoadBoardUberSaga);
  yield takeLatest(A.loginLoadBoard123, handleLoginToLoadBoard123Saga);
  // oauth popup sagas
  yield fork(oAuthPopupWatcherSaga);
}

export default oAuthWatcherSaga;
