import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// common
import { updateReportFromReportListSuccess } from '../../common/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportReducers } from '../../report-common';
// feature rate-shop
import * as A from './actions';
//////////////////////////////////////////////////

const initial = {
  itemList: {},
  excelResultsPagination: {},
};

const {
  setReports,
  selectItem,
  initialState,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  setInitialStateOmitReport,
  handleUpdateReportFromReportListSuccess,
} = getReportReducers(initial);

const setUsedReport = (state: Object, data: Object) => (
  P.$all(
    P.$set('usedReport', data),
    P.$set('reportPending', false),
    P.$set(
      'itemList',
      R.map(
        (data: Object) => ({
          ...data,
          expanded: false,
          excelResults: null,
        }),
        state.itemList,
      ),
    ),
    state,
  )
);

const getItemListSuccess = (state: Object, { results, totalCount }: Object) => {
  const { itemList, pagination } = state;

  const indexAdditional = G.ifElse(
    R.isNil(itemList),
    0,
    R.length(R.values(itemList)),
  );

  const mappedList = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: number) => R.mergeRight(
      item,
      {
        selected: false,
        expanded: false,
        index: R.add(index, indexAdditional),
      },
    )),
  )(results);

  const list = R.mergeRight(R.or(itemList, {}), mappedList);
  const newOffset = R.add(pagination.offset, pagination.limit);

  return P.$all(
    P.$set('itemList', list),
    P.$set('pageVisited', true),
    P.$set('pagination.limit', 10),
    P.$set('totalCount', totalCount),
    P.$set(
      'pagination.offset',
      G.ifElse(
        R.gt(totalCount, newOffset),
        newOffset,
        totalCount,
      ),
    ),
    state,
  );
};

const removeExcelMassRatingSummarySuccess = (state: Object, guids: Array) => {
  const itemList = R.omit(guids, state.itemList);

  return P.$all(
    P.$set('itemList', itemList),
    P.$set('totalCount', R.subtract(state.totalCount, R.length(guids))),
    state,
  );
};

const toggleExcelResults = (state: Object, guid: Object) => (
  P.$all(
    P.$toggle(`itemList.${guid}.expanded`),
    P.$set(`itemList.${guid}.excelResults`, null),
    state,
  )
);

const resetExcelResultsListAndPaginationByRowGuid = (state: Object, rowGuid: string) => (
  P.$set(
    `itemList.${rowGuid}.excelResults`,
    {
      itemList: [],
      totalCount: 0,
      pagination: initialState.pagination,
    },
    state,
  )
);

const getExcelResultsByRowGuidSuccess = (state: Object, data: Object) => {
  const { results, totalCount, excelMassRatingSummaryGuid } = data;

  if (G.isNilOrEmpty(results)) return state;

  const excelResults = R.path(['itemList', excelMassRatingSummaryGuid, 'excelResults'], state);
  const pagination = R.pathOr(initialState.pagination, ['pagination'], excelResults);
  const itemList = R.concat(R.pathOr([], ['itemList'], excelResults), results);
  const newOffset = R.add(pagination.offset, pagination.limit);

  return P.$set(
    `itemList.${excelMassRatingSummaryGuid}.excelResults`,
    {
      itemList,
      totalCount,
      pagination: {
        limit: 20,
        offset: G.ifElse(R.gt(totalCount, newOffset), newOffset, totalCount),
      },
    },
    state,
  );
};

export default createReducer({
    // report common
  [A.setReports]: setReports,
  [A.selectItem]: selectItem,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.setInitialStateOmitReport]: setInitialStateOmitReport,
  [updateReportFromReportListSuccess]: handleUpdateReportFromReportListSuccess,
  // report common
  [A.setUsedReport]: setUsedReport,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.toggleExcelResults]: toggleExcelResults,
  [A.getExcelResultsByRowGuidSuccess]: getExcelResultsByRowGuidSuccess,
  [A.removeExcelMassRatingSummarySuccess]: removeExcelMassRatingSummarySuccess,
  [A.resetExcelResultsListAndPaginationByRowGuid]: resetExcelResultsListAndPaginationByRowGuid,
}, initialState);
